/* eslint-disable react/prop-types */
import React from 'react';
import isString from 'lodash/isString';
import ReactJson from 'react-json-view';
import { DateInput } from 'lib/acromyrmex';
import { ProgressBar } from '../../../utility/UiComponents';
import { toBoolean } from './DataConversion';
import DateHelper from '../../../shared-helpers/DateHelper';

const getCellBase = column => ({
  Header: column.display || column.key,
  accessor: column.key,
  id: column.id,
  width: column.width || 200,
  toggleable: true
});

const cellFactory = func => r => {
  if (!r || !r.value) {
    return '';
  }

  return func(r);
};

const getLabelCell = (column, cell) => ({
  ...cell,
  ...column,
  Cell: cellFactory(r => r.value.toString())
});

const getArrayCell = (column, cell) => ({
  ...cell,
  ...column,
  Cell: cellFactory(r => r.value.join(', '))
});

const getJsonCell = (column, cell) => ({
  ...cell,
  ...column,
  Cell: cellFactory(r => {
    let obj = null;
    try {
      obj = isString(r.value) ? JSON.parse(r.value) : r.value;
    } catch (e) {
      // nothing
    }
    return (
      obj && (
        <div className="overflow">
          <ReactJson name={null} collapsed src={obj} displayDataTypes={false} />
        </div>
      )
    );
  })
});

const getPercentCell = (column, cell) => ({
  ...cell,
  ...column,
  filterable: false,
  Cell: cellFactory(r => (
    <div>
      <ProgressBar now={r.value * 100} label={`${Math.floor(r.value * 100)}%`} />
    </div>
  )),
  width: 120
});

const getTimeagoCell = (column, cell) => ({
  ...cell,
  ...column,
  width: 200,
  Cell: cellFactory(r => <span>{DateHelper.timeAgo(r.value)}</span>),
  Filter: ({
    filter = {
      id: 'birthday',
      value: { from: '', to: '' }
    },
    onChange
  }) => (
    <div className="date-filter form-inline">
      <div>
        <DateInput
          label="From"
          stripped
          input={{
            value: filter.value.from,
            onChange: value => {
              onChange({ ...filter.value, from: value });
            }
          }}
          dateFormat="L"
          dateTimeFormat="L LT"
          meta={{}}
          getCalendarContainer={trigger => trigger.parentNode}
        />
      </div>
      <div>
        <DateInput
          label="To"
          stripped
          input={{
            value: filter.value.to,
            onChange: value => {
              onChange({ ...filter.value, to: value });
            }
          }}
          dateFormat="L"
          dateTimeFormat="L LT"
          meta={{}}
          getCalendarContainer={trigger => trigger.parentNode}
        />
      </div>
    </div>
  )
});

const getCheckboxCell = (column, cell) => ({
  ...cell,
  ...column,
  sortable: false,
  filterable: false,
  Cell: cellFactory(r => (
    <input type="checkbox" className="checkbox" checked={toBoolean(r.value)} readOnly />
  )),
  width: 150
});

const getExportedCellValue = ({ col, cell }) => {
  const value = cell[col.accessor];

  switch (col.type) {
    case 'array':
      return value.join(', ');
    case 'percent':
      return `${Math.floor(value * 100)}%`;
    case 'date':
    case 'timeago':
      return value ? DateHelper.formatDateTime(value) : value;
    case 'withFormatter':
      return col.csvFormatter(cell);
    default:
      return value;
  }
};

export {
  getCellBase,
  getArrayCell,
  getLabelCell,
  getJsonCell,
  getPercentCell,
  getTimeagoCell,
  getCheckboxCell,
  getExportedCellValue
};
