/* eslint-disable class-methods-use-this */
import {VAULT_MERGE_V2_STEP_TYPE} from '../../../constants';
import VaultMergeV2StepOptions from './components/stepOptions/VaultMergeV2StepOptions';
import BasicUiHelper from './BasicUiHelper';

class VaultMergeV2UiHelper extends BasicUiHelper {
  regex() {
    return VAULT_MERGE_V2_STEP_TYPE;
  }

  name() {
    return 'Vault Merge v2';
  }

  canTryAgain() {
    return true;
  }

  stepOptions() {
    return VaultMergeV2StepOptions;
  }

  getOptionSummary() {
    return [{ key: 'vaultStructure', title: 'Vault Structure', type: 'VaultStructure' }];
  }

  getOutputKeys() {
    return {
      schema: {
        '.': {
          type: 'Table',
          vaultStructureStepOption: 'vaultStructure',
          ignoreColumns: [
            'site',
            'businessUnit',
            'customer',
            '_id',
            'hashChanged',
            'integrations',
            'vaultStructure'
          ]
        }
      }
    };
  }

  getOutputOptions(step, { structureList }) {
    const { vaultStructure } = step.stepOptions;

    const struct = structureList.find(s => s.slug === vaultStructure);

    if (!struct) return;

    return [
      {
        id: JSON.stringify({ step: step._id, key: '.' }),
        name: 'data',
        textValue: `{${step._id}..}`,
        type: 'vault-collection',
        slug: step.stepOptions.vaultStructure,
        properties: struct.attributes.map(attr => ({
          id: JSON.stringify({ step: step._id, field: attr.key }),
          name: `${attr.display}`,
          textValue: `{${step._id}.${attr.key}}`,
          type: attr.type
        }))
      },
      super.getLengthAttribute(step)
    ];
  }
}

export default VaultMergeV2UiHelper;
