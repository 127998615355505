import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { TextInput } from 'lib/acromyrmex';
import { Col } from '../../../utility/UiComponents';
import EndpointInput from './EndpointInput';
import { requiredValidator } from '../../../utility/formValidators';

const GenericCredentialForm = ({ dynamicEndpoint, displayKeys, maskedKeys }) => {
  
  return (
    <div>
      <Col xs={12}>
        {displayKeys.map(key => (
          <Field
            key={key.key}
            name={`authenticationOptions.${key.key}`}
            validate={requiredValidator}
            component={TextInput}
            label={key.display}
            help={key.help}
            type="text"
          />
        ))}
        {maskedKeys.map(key => (
          <Field
            key={key.key}
            name={`authenticationOptions.${key.key}`}
            validate={requiredValidator}
            component={TextInput}
            label={key.display}
            help={key.help}
            type="password"
          />
        ))}
      </Col>
      {dynamicEndpoint && <EndpointInput />}
    </div>
  );
};
GenericCredentialForm.propTypes = {
  dynamicEndpoint: PropTypes.bool,
  displayKeys: PropTypes.arrayOf(PropTypes.shape()),
  maskedKeys: PropTypes.arrayOf(PropTypes.shape())
};
GenericCredentialForm.defaultProps = {
  dynamicEndpoint: false,
  displayKeys: [],
  maskedKeys: []
};
export default GenericCredentialForm;
