import React from 'react';
import ShowDuoTenantAdminForm from './ShowDuoTenantAdminForm';
import ShowDuoLayout from './ShowDuoLayout';

class ShowMarketing extends React.Component {
  render() {
    return (
      <ShowDuoLayout
        explanation={(
          <div>
            <div style={{ textAlign: 'center' }}>
              <video width="320" height="240" controls>
                <source
                  src="https://contuit-assets.s3.us-east-2.amazonaws.com/Docs-Screenshots/Get+started+with+Contuit+today+-+a+message+from+the+Duo+MSP+team.mp4"
                  type="video/mp4"
                />
              </video>
            </div>
            <p>
              Contuit is pleased to offer Duo MSP partners our full PSA Billing Sync and Bypass code
              solutions for a flat rate of $49/month!
            </p>

            <p>
              First create your Contuit account and an Admin to get started, then we will direct you
              on how to set up Duo properly.
            </p>

            <p>
              Have questions on Duo&apos;s partner program? Visit duo.com/msp for more information
              or reach out at
              {' '}
              <a href="mailto:msp@duo.com">msp@duo.com</a>
              .
            </p>
          </div>
        )}
        content={<ShowDuoTenantAdminForm route={{ importPath: 'duo-slack' }} />}
      />
    );
  }
}

// Define property types
ShowMarketing.propTypes = {};

ShowMarketing.defaultProps = {};

export default ShowMarketing;
