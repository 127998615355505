import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reduxForm } from 'redux-form';
import ShowSchedulerHelp from './sections/ShowSchedulerHelp';
import ShowComputationHelp, { computationHelp } from './sections/ShowComputationHelp';
import ShowConditionalHelp from './sections/ShowConditionalHelp';
import ShowApiHelp from './sections/ShowApiHelp';
import PageLayout from '../shared/Layout/PageLayout';

const ShowHelp = props => {
  const { currentUser, routes } = props;

  return (
    <PageLayout
      header="Contuit Help"
      noContentPadding
      content={
        <form>
          <ShowConditionalHelp />
          <ShowComputationHelp />
          <ShowSchedulerHelp />
          <ShowApiHelp />
        </form>
      }
      leftSidebar={
        <ul className="nav nav-pills nav-stacked">
          <li>
            <a href="#conditionals">Conditionals</a>
          </li>
          <li>
            <a href="#vault-computations">Vault Computations</a>
          </li>
          <li>
            <a href="#scheduler">Scheduler</a>
          </li>
          <li>
            <a href="#api">Using the API</a>
          </li>
        </ul>
      }
      routes={routes}
      currentUser={currentUser}
    />
  );
};

ShowHelp.propTypes = {
  currentUser: PropTypes.shape().isRequired,
  routes: PropTypes.arrayOf(PropTypes.shape()).isRequired
};

const values = {};
computationHelp.forEach(c => {
  values[c.name] = c.example;
});

const mapStateToProps = state => ({
  currentUser: state.users.currentUser
});
const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

const form = reduxForm({ form: 'sampleForm', initialValues: values })(ShowHelp);
export default connect(mapStateToProps, mapDispatchToProps)(form);
