import _ from 'underscore'
import StepConditionTypes from './StepConditionTypes'

class ConditionalHelper {
  static calculateCondition(lhs, operation, rhs, conditionFunction, errors) {
    // handle number-in-string case
    if (_.isBoolean(lhs)) {
      lhs = `${lhs}`
    } else if (!isNaN(lhs)) {
      lhs = Number(lhs)
    }

    if (_.isBoolean(rhs)) {
      rhs = `${rhs}`
    } else if (!isNaN(rhs)) {
      rhs = Number(rhs)
    }

    switch (operation) {
      case StepConditionTypes.EQUALS:
        if (lhs !== rhs) {
          conditionFunction()
          errors.push(`${lhs} = ${rhs}`)
        } else {
          conditionFunction(true)
        }
        break
      case StepConditionTypes.NOT_EQUALS:
        if (lhs === rhs) {
          conditionFunction()
          errors.push(`${lhs} != ${rhs}`)
        } else {
          conditionFunction(true)
        }
        break
      case StepConditionTypes.IS_NULL:
        if (lhs) {
          conditionFunction()
          errors.push(`${lhs} is null`)
        } else {
          conditionFunction(true)
        }
        break
      case StepConditionTypes.IS_NOT_NULL:
        if (!lhs || lhs === 'undefined') {
          conditionFunction()
          errors.push(`${lhs} is not null`)
        } else {
          conditionFunction(true)
        }
        break
      case StepConditionTypes.LESS_THAN:
        if (lhs >= rhs) {
          conditionFunction()
          errors.push(`${lhs} < ${rhs}`)
        } else {
          conditionFunction(true)
        }
        break
      case StepConditionTypes.GREATER_THAN:
        if (lhs <= rhs) {
          conditionFunction()
          errors.push(`${lhs} > ${rhs}`)
        } else {
          conditionFunction(true)
        }
        break
      case StepConditionTypes.LESS_THAN_EQUAL:
        if (lhs > rhs) {
          conditionFunction()
          errors.push(`${lhs} <= ${rhs}`)
        } else {
          conditionFunction(true)
        }
        break
      case StepConditionTypes.GREATER_THAN_EQUAL:
        if (lhs < rhs) {
          conditionFunction()
          errors.push(`${lhs} >= ${rhs}`)
        } else {
          conditionFunction(true)
        }
        break
      case StepConditionTypes.CONTAINS:
        if (!_.isString(lhs) || !_.isString(rhs) || lhs.indexOf(rhs) < 0) {
          conditionFunction()
          errors.push(`${lhs} contains ${rhs}`)
        } else {
          conditionFunction(true)
        }
        break
      case StepConditionTypes.DOES_NOT_CONTAIN:
        if (!_.isString(lhs) || !_.isString(rhs) || lhs.indexOf(rhs) >= 0) {
          conditionFunction()
          errors.push(`${lhs} does not contain ${rhs}`)
        } else {
          conditionFunction(true)
        }
        break
      case StepConditionTypes.STARTS_WITH:
        if (!_.isString(lhs) || !_.isString(rhs) || !lhs.startsWith(rhs)) {
          conditionFunction()
          errors.push(`${lhs} starts with ${rhs}`)
        } else {
          conditionFunction(true)
        }
        break
      case StepConditionTypes.ENDS_WITH:
        if (!_.isString(lhs) || !_.isString(rhs) || !lhs.endsWith(rhs)) {
          conditionFunction()
          errors.push(`${lhs} ends with ${rhs}`)
        } else {
          conditionFunction(true)
        }
        break
      default:
        throw new Error(`Unknown condition type ${operation}!`)
    }
  }

  static doesConditionPass(lhs, operation, rhs) {
    // handle number-in-string case
    if (_.isBoolean(lhs)) {
      lhs = `${lhs}`
    } else if (!isNaN(lhs)) {
      lhs = Number(lhs)
    }

    if (_.isBoolean(rhs)) {
      rhs = `${rhs}`
    } else if (!isNaN(rhs)) {
      rhs = Number(rhs)
    }

    switch (operation) {
      case StepConditionTypes.EQUALS:
        return lhs === rhs
      case StepConditionTypes.NOT_EQUALS:
        return lhs !== rhs
      case StepConditionTypes.IS_NULL:
        return !lhs
      case StepConditionTypes.IS_NOT_NULL:
        return !!lhs
      case StepConditionTypes.LESS_THAN:
        return lhs < rhs
      case StepConditionTypes.GREATER_THAN:
        return lhs > rhs
      case StepConditionTypes.LESS_THAN_EQUAL:
        return lhs <= rhs
      case StepConditionTypes.GREATER_THAN_EQUAL:
        return lhs >= rhs
      case StepConditionTypes.CONTAINS:
        return _.isString(lhs) && _.isString(rhs) && lhs.indexOf(rhs) >= 0
      case StepConditionTypes.DOES_NOT_CONTAIN:
        return _.isString(lhs) && _.isString(rhs) && lhs.indexOf(rhs) < 0
      case StepConditionTypes.STARTS_WITH:
        return _.isString(lhs) && _.isString(rhs) && lhs.startsWith(rhs)
      case StepConditionTypes.ENDS_WITH:
        return _.isString(lhs) && _.isString(rhs) && lhs.endsWith(rhs)
      default:
        throw new Error(`Unknown condition type ${operation}!`)
    }
  }
}

export default ConditionalHelper
