import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { LinkContainer } from 'react-router-bootstrap';
import { Button } from '../../utility/UiComponents';
import PageLayout from '../shared/Layout/PageLayout';
import ShowProcessExecutionTable from './ShowProcess/ShowProcessExecutionTable';

const ShowActiveExecutions = ({ routes, currentUser }) => (
  <PageLayout
    header="Active Executions"
    routes={routes}
    fullWidth
    currentUser={currentUser}
    headerButtons={
      <LinkContainer to={{ pathname: '/processes' }}>
        <Button variant="default" size="sm" className="pull-right">
          View Process Playbook
        </Button>
      </LinkContainer>
    }
    noContentPadding
    content={<ShowProcessExecutionTable currentUser={currentUser} />}
  />
);

ShowActiveExecutions.propTypes = {
  currentUser: PropTypes.shape({
    token: PropTypes.string.isRequired
  }).isRequired,
  routes: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

ShowActiveExecutions.defaultProps = {};

const mapStateToProps = state => ({
  currentUser: state.users.currentUser
});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ShowActiveExecutions);
