import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Icon, LoadingButton } from 'lib/acromyrmex';
import { toast } from 'react-toastify';
import { LinkContainer } from 'react-router-bootstrap';
import { Button } from '../../utility/UiComponents';
import ConfirmModal from '../shared/ConfirmModal';
import ExecuteProcessModal from './ExecuteProcessModal';

class ProcessButtons extends React.Component {
  state = { showUnArchiveModal: false, showDisableModal: false, showExecuteModal: false };

  openExecuteModal = () => {
    this.setState({ showExecuteModal: true });
  };

  closeExecuteModal = () => {
    this.setState({ showExecuteModal: false });
  };

  render() {
    const {
      isCreating,
      manager,
      process,
      createExecution,
      pullRight,
      viewDetails,
      isUpdating,
      loadProcess,
      updateProcess,
      onSchedule
    } = this.props;
    const { showDisableModal, showUnArchiveModal, showExecuteModal } = this.state;

    return (
      <div
        className={classNames('buttons-margin', {
          'pull-right': pullRight
        })}
      >
        {viewDetails && (
          <LinkContainer
            to={{
              pathname: `/processes/${process._id}`
            }}
          >
            <Button size="xsmall" variant="default">
              View Process
            </Button>
          </LinkContainer>
        )}
        {manager &&
          process.initiation.initiationType === 'basic' &&
          !process.isDraft &&
          !process.isArchived && (
            <LoadingButton
              variant="success"
              size="xsmall"
              onClick={this.openExecuteModal}
              loading={isCreating}
              loadingLabel="loading.."
              label={
                <span>
                  <i className="fa fa-play" />
                  {' Execute'}
                </span>
              }
            />
          )}
        {manager &&
          process.initiation.initiationType === 'basic' &&
          !process.isDraft &&
          !process.isArchived && (
            <ExecuteProcessModal
              show={showExecuteModal}
              onCancel={this.closeExecuteModal}
              onSchedule={values => onSchedule(values, this.closeExecuteModal)}
              processItem={process}
              onExecute={() => {
                createExecution(process._id);
              }}
            />
          )}
        {manager && (
          <LinkContainer
            to={{
              pathname: `/processes/${process._id}/edit`
            }}
          >
            <Button size="xsmall" variant="primary">
              <Icon edit />
            </Button>
          </LinkContainer>
        )}
        {manager && process.isDraft && (
          <LoadingButton
            variant="primary"
            size="xsmall"
            onClick={() => {
              if (process.steps && process.steps.length === 0) {
                toast.error('You must add at least one step before going live.');
                return;
              }

              updateProcess(process._id, {
                ...process,
                isDraft: false,
                isArchived: false,
                isActive: true
              });
            }}
            loading={isCreating}
            loadingLabel="Going Live"
            label="Go Live"
          />
        )}
        {manager && !process.isDraft && !process.isArchived && (
          <LoadingButton
            variant="primary"
            style={{ marginRight: 5 }}
            size="xsmall"
            loading={isUpdating}
            loadingLabel="Updating Process"
            onClick={() => {
              loadProcess(process._id);
              this.setState({ showDisableModal: true });
            }}
            label="Archive"
          />
        )}
        {manager && process.isArchived && (
          <LoadingButton
            variant="primary"
            style={{ marginRight: 5 }}
            size="xsmall"
            loading={isUpdating}
            loadingLabel="Updating Process"
            onClick={() => {
              loadProcess(process._id);
              this.setState({ showUnArchiveModal: true });
            }}
            label="Return to Draft"
          />
        )}
        <ConfirmModal
          show={showUnArchiveModal}
          title="Confirm Unarchiving this process"
          message="Are you sure you want to Unarchive this process? This will return the process to
          a draft state for you to edit first before making it live again."
          onConfirm={() => {
            updateProcess(process._id, {
              ...process,
              isDraft: true,
              isArchived: false,
              isActive: false,
              dateArchived: null
            });
            this.setState({ showDisableModal: false });
          }}
          onCancel={() => {
            this.setState({ showDisableModal: false });
          }}
        />
        <ConfirmModal
          show={showDisableModal}
          title="Confirm archiving this process"
          message="Are you sure you want to archive this process?"
          onConfirm={() => {
            updateProcess(process._id, {
              ...process,
              isDraft: false,
              isArchived: true,
              isActive: false,
              dateArchived: new Date()
            });
            this.setState({ showDisableModal: false });
          }}
          onCancel={() => {
            this.setState({ showDisableModal: false });
          }}
        />
      </div>
    );
  }
}

// Define property types
ProcessButtons.propTypes = {
  process: PropTypes.shape().isRequired,
  manager: PropTypes.bool,
  pullRight: PropTypes.bool,
  isCreating: PropTypes.bool,
  isUpdating: PropTypes.bool,
  viewDetails: PropTypes.bool,
  loadProcess: PropTypes.func.isRequired,
  updateProcess: PropTypes.func.isRequired,
  createExecution: PropTypes.func.isRequired,
  onSchedule: PropTypes.func.isRequired
};

ProcessButtons.defaultProps = {
  isCreating: false,
  isUpdating: false,
  pullRight: false,
  manager: false,
  viewDetails: true
};

export default ProcessButtons;
