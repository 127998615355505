import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';
import { TextInput, LoadingButton } from 'lib/acromyrmex';
import { Row } from '../../utility/UiComponents';

const validate = values => {
  const errors = {};
  if (!values.name) {
    errors.name = 'Required';
  }

  if (!values.email) {
    errors.email = 'Required';
  } else {
    // verifies @ and .
    const regex = /.+@.+\..+/i;
    if (!regex.exec(values.email)) {
      errors.email = 'Invalid';
    }
  }

  if (!values.password) {
    errors.password = 'Required';
  }

  return errors;
};

class UserForm extends React.Component {
  render() {
    const { handleSubmit, onSubmit, isCreating, code } = this.props;
    return (
      <div className="container">
        <form className="form-horizontal" onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Field name="name" component={TextInput} label="Name:" />
          </Row>
          <Row>
            <Field name="email" component={TextInput} label="Email:" type="email" />
          </Row>
          <Row>
            <Field name="password" component={TextInput} label="Password:" type="password" />
          </Row>
          {!code && (
            <Row>
              <Field name="code" component={TextInput} label="Code:" type="text" />
            </Row>
          )}
          <Row>
            <LoadingButton
              size="large"
              variant="primary"
              label="Create"
              loading={isCreating}
              loadingLabel="Creating User"
              type="submit"
            />
          </Row>
        </form>
      </div>
    );
  }
}

// Define property types
UserForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isCreating: PropTypes.bool.isRequired,
  code: PropTypes.string
};

UserForm.defaultProps = {
  code: ''
};

// for the form
const mapStateToProps = state => ({
  currentUser: state.users.currentUser,
  user: state.users.user,
  isCreating: state.users.isCreating
});

export default connect(mapStateToProps)(
  reduxForm({
    form: 'userForm',
    validate
  })(UserForm)
);
