/* eslint-disable react/prop-types */
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { FieldArray, Field, reduxForm } from 'redux-form'
import {
  HoverHelp,
  TextInput,
  SelectInput,
  LoadingButton,
} from 'lib/acromyrmex'
import { toast } from 'react-toastify'
import AttributeHelper from '../../shared-helpers/AttributeHelper'
import {
  Row,
  Col,Accordion,
  Form,
  Button,
} from '../../utility/UiComponents'
import AddButton from '../shared/form/AddButton'
import { attributeTypes } from '../vaultStructures/attributes/UiAttributeHelper'
import { requiredValidator } from '../../utility/formValidators'
import StringHelper from '../../shared-helpers/StringHelper'
import { updateSettings as updateSettingsAction } from '../../actions/processes'
import TableForm from '../shared/form/TableForm'

class SolutionSettingsForm extends React.Component {
  onFormSubmit = ({ attributes }) => {
    const { updateSettings } = this.props
    const newSettings = attributes.reduce((acc, v) => {
      const slug = StringHelper.slugify(v.name)
      acc[slug] = { ...v, slug }
      return acc
    }, {})

    updateSettings(newSettings)
  }

  renderField = (attr, fields, index) => {
    const attribute = fields.get(index)

    const isSystemDefinedSetting = attribute.isSystemDefinedSetting;

    const valueField = attributeTypes
      .filter((a) => a.id !== 'pickList')
      .find((a) => a.id === attribute.type)
      .getEditField({ key: `${attr}.value` }, null, null, null, {
        noLabel: true,
      })

    return (
      <Row key={attr} className="struct-form-row">
        <Col xs={12} sm={6} md={3} lg={2}>
          <Row>
            <Field
              name={`${attr}.name`}
              component={TextInput}
              validate={requiredValidator}
              noLabel
              disabled={isSystemDefinedSetting}
            />
          </Row>
        </Col>
        <Col xs={12} sm={6} md={3} lg={2}>
          <Row>
            <Col xs={12}>
              <Row>
                <Field
                  name={`${attr}.type`}
                  component={SelectInput}
                  noLabel
                  options={AttributeHelper.getAttributeTypes().filter(
                    (a) => a.id !== 'pickList',
                  )}
                  disabled={isSystemDefinedSetting}
                />
              </Row>
            </Col>
          </Row>
        </Col>
        <Col xs={12} sm={6} md={5} lg={3}>
          <Row>{valueField}</Row>
        </Col>
        <Col xs={12} sm={6} md={3} lg={3}>
          <Row>
            <Col xs={12}>
              <Row>
                <Field
                  name={`${attr}.description`}
                  component={TextInput}
                  noLabel
                  disabled={isSystemDefinedSetting}
                />
              </Row>
            </Col>
          </Row>
        </Col>
        <Col xs={12} sm={6} md={1}>
          <Button
            className="pull-right"
            variant="danger"
            onClick={() => fields.remove(index)}
            disabled={isSystemDefinedSetting}
          >
            <i style={{ margin: 0 }} className="fa fa-trash" />
          </Button>
        </Col>
      </Row>
    )
  }

  renderFields = ({ fields }) => {
    const rows = fields.map((member, index) =>
      this.renderField(member, fields, index),
    )

    return (
      <div>
        {rows}
        <Row>
          <Col xs={12}>
            <AddButton size="sm"
              label="Add Attribute"
              onClick={() =>
                fields.push({
                  type: 'string',
                  name: '',
                  value: '',
                })
              }
            />
          </Col>
        </Row>
      </div>
    )
  }

  render() {
    const { handleSubmit, settingsUpdating } = this.props

    return (
      <Form onSubmit={handleSubmit(this.onFormSubmit)}>
        <Accordion defaultActiveKey="1">
          <Accordion.Item eventKey="1">
            <Accordion.Header>
              {' '}
              <span>
                <strong>Configurations</strong>{' '}
                <HoverHelp help="Add configuration that will be used in the processes" />{' '}
              </span>
            </Accordion.Header>
            <Accordion.Body>
              <Row>
                <Col xs={3} md={3} lg={2} className="text-center">
                  <strong>Name</strong>{' '}
                  <HoverHelp
                    help="What do you want to see the attribute referred to as throughout Contuit?"
                    position="top"
                  />
                </Col>
                <Col xs={2} md={3} lg={2} className="text-center">
                  <strong>Type</strong>{' '}
                  <HoverHelp
                    help="What sort of data do you want to store?"
                    position="top"
                  />
                </Col>
                <Col xs={2} md={5} lg={3} className="text-center">
                  <strong>Value</strong>{' '}
                </Col>
                <Col xs={2} md={5} lg={3} className="text-center">
                  <strong>Description</strong>{' '}
                </Col>
              </Row>
              <FieldArray name="attributes" component={this.renderFields} />
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <LoadingButton
          variant="primary"
          label="Save Changes"
          loading={settingsUpdating}
          loadingLabel="Saving Changes"
          type="submit"
        />
      </Form>
    )
  }
}

export default connect(
  (state) => ({
    formValues: state.form.settingsForm ? state.form.settingsForm.values : [],
    settings: state.processes.settings.value,
    settingsUpdating: state.processes.settings.isUpdating,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        updateSettings: updateSettingsAction,
      },
      dispatch,
    ),
)(reduxForm({ form: 'settingsForm' })(SolutionSettingsForm))
