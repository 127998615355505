import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Table, Button } from '../../../utility/UiComponents';
import DateHelper from '../../../shared-helpers/DateHelper';

class ListApiKeys extends Component {
  static propTypes = {
    apiKeys: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    onDeleteApiKeyClicked: PropTypes.func.isRequired
  };

  static defaultProps = {};

  state = {};

  render() {
    const { apiKeys, onDeleteApiKeyClicked } = this.props;
    return (
      <Table>
        <thead>
          <tr>
            <th>Key</th>
            <th>Created At</th>
          </tr>
        </thead>
        <tbody>
          {apiKeys.map(key => (
            <tr key={key._id}>
              <td>{key.apiKey}</td>
              <td>{DateHelper.formatDateOnly(key.createdAt)}</td>
              <td>
                <Button
                  variant="danger"
                  onClick={() => {
                    onDeleteApiKeyClicked(key._id);
                  }}
                  title="Remove this parameter"
                >
                  <i className="fa fa-trash" />
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  }
}

export default ListApiKeys;
