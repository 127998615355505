import isObject from 'lodash/isObject'
import isFunction from 'lodash/isFunction'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { DragSource, DropTarget } from 'react-dnd'
import {
  Loading,
  Icon,
  TextInput,
  MarkdownInput,
  SelectInput,
} from 'lib/acromyrmex'
import { Field, FieldArray } from 'redux-form'
import { Row, Col, Accordion } from '../../../utility/UiComponents'
import SelectStepType from './SelectStepType'
import {
  requiredValidator,
  selectRequiredValidator,
} from '../../../utility/formValidators'
import StepHelper from '../steps/UiStepHelper'
import StepCondition from './StepCondition'
import AddButton from '../../shared/form/AddButton'
import { filterCollections } from '../../../utility/ProcessFormOptionHelpers'
import StepOptions from '../steps/components/stepOptions/StepOptions'
import StepResults from '../steps/components/stepOptions/StepResults'
import StepToolbar from './StepToolbar'

class ProcessFormStep extends Component {
  constructor(props) {
    super(props)

    this.renderConditions = this.renderConditions.bind(this)

    this.state = {
      showDescription: false,
      showConditions: false,
      showStepOptions: false,
      showResultOptions: false,
    }
  }

  /**
   * Gets the stepOptions view for this particular step type
   */
  getStepOptions() {
    const {
      index,
      fields,
      step,
      responseFields,
      attributes,
      structureList,
      integrationOptions,
      onChangeAttributeNeeded,
      outputOptionsLoaded,
      initiationOutputOptions,
      change,
      array,
    } = this.props
    const outputOptions = this.getOutputOptions()

    const field = fields.get(index)
    let stepOptions = null
    // when switching between sometimes thisStep hasn't loaded yet?
    if (!field) {
      stepOptions = <Loading />
    } else {
      const Type = StepHelper.getStepOptions(field.stepType)

      // if we get back an object, send it to the generic
      if (isObject(Type) && !isFunction(Type.WrappedComponent)) {
        return (
          <StepOptions
            fieldPrefix={step}
            onChangeAttributeNeeded={onChangeAttributeNeeded}
            processStep={field}
            outputOptions={outputOptions}
            outputOptionsLoaded={outputOptionsLoaded}
            title={`${field.name || ''} Step Options`}
            fields={
              isFunction(Type.fields)
                ? Type.fields({
                    structureList,
                    outputOptions,
                    outputOptionsLoaded,
                  })
                : Type.fields
            }
          />
        )
      }

      stepOptions =
        Type === null ? null : (
          <Type
            fieldText={step}
            processStep={field}
            outputOptions={outputOptions}
            outputOptionsLoaded={outputOptionsLoaded}
            executionStep={field}
            integrationOptions={integrationOptions}
            change={change}
            array={array}
            initiationOutputOptions={initiationOutputOptions}
            responseAttributes={{ fields: responseFields, attributes }}
            onChangeAttributeNeeded={onChangeAttributeNeeded}
          />
        )
    }

    return stepOptions
  }

  getOutputOptions() {
    const {
      initiationOutputOptions,
      executionOutputOptions,
      availableStepOutputOptions,
      settingsOptions,
      index,
    } = this.props
    // the concat thing flattens the array from [[1, 2], [3], [4]] to [1, 2, 3, 4]
    return [
      ...initiationOutputOptions,
      ...executionOutputOptions,
      ...settingsOptions,
    ].concat(...availableStepOutputOptions.slice(0, index))
  }

  renderConditions({ fields, step }) {
    const { change } = this.props
    const rows = fields.map((member, index) => (
      <StepCondition
        key={member}
        fields={fields}
        condition={member}
        index={index}
        outputOptions={this.getOutputOptions()}
        change={change}
      />
    ))

    return (
      <Row style={{ marginBottom: 5 }}>
        <Accordion defaultActiveKey="1">
          <Accordion.Item eventKey="1">
            <Accordion.Header>Step Conditions</Accordion.Header>
            <Accordion.Body>
              {rows.length > 0 ? <strong>Run if</strong> : ''}
              {rows}
              <Row>
                <Col xs={6} sm={8}>
                  <Field
                    name={`${step}.conditionLogic`}
                    component={SelectInput}
                    label="Combine Using"
                    validate={requiredValidator}
                    options={[
                      { id: 0, name: 'AND' },
                      { id: 1, name: 'OR' },
                    ]}
                  />
                </Col>
                <Col xs={6} sm={4}>
                  <AddButton
                    size="sm"
                    onClick={() => {
                      fields.push({
                        type: 0,
                      })
                    }}
                    label="Add Condition"
                  />
                </Col>
              </Row>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Row>
    )
  }

  render() {
    const {
      connectDragPreview,
      connectDropTarget,
      isOver,
      connectDragSource,
      isDragging,
      displayGraph,
      index,
      fields,
      step,
      stepTypes,
      integrationOptions,
      onChangeAttributeNeeded,
      addStep,
      removeStep,
      copyStep,
      outputOptionsLoaded,
      integrationsHaveLoaded,
      change,
    } = this.props
    const {
      showDescription,
      showConditions,
      showStepOptions,
      showResultOptions,
    } = this.state
    const field = fields.get(index)
    const stepOptions = this.getStepOptions()
    const outputOptions = this.getOutputOptions()

    const selectedStepType = stepTypes.find((t) => t.id === field.stepType)

    // 

    return connectDropTarget(
      connectDragPreview(
        <div
          className="clearfix process-form-step"
          style={{
            border: isOver ? '1px solid black' : '',
            opacity: isDragging ? 0.5 : 1,
            paddingLeft: '5px',
          }}
        >
          {/* render the children using a function to pass to the handle */}
          <div>
            {/* MARKER AND ICONS */}
            {!displayGraph && (
              <div
                style={{ fontSize: '1.2em', display: 'flex', gap: 15 }}
                className="process-form-step-head"
              >
                {connectDragSource(
                  <div
                    style={{
                      cursor: 'pointer',
                    }}
                  >
                    <Icon drag />
                  </div>,
                )}
                <span>Step {index + 1}:</span>
                <div style={{ flex: '1', marginTop: '-4px' }}>
                  <Field
                    name={`${step}.name`}
                    component={TextInput}
                    noLabel
                    label="Step:"
                    validate={requiredValidator}
                    onChange={(a, value) => {
                      onChangeAttributeNeeded({
                        ...field,
                        name: value,
                      })
                    }}
                  />
                </div>
                <span>Type:</span>
                <div
                  style={{ flex: '1', marginTop: '-4px' }}
                  title={
                    selectedStepType &&
                    `${selectedStepType.brand} - ${selectedStepType.name}`
                  }
                >
                  <Field
                    name={`${step}.stepType`}
                    noLabel
                    component={SelectStepType}
                    label="Type"
                    validate={selectRequiredValidator}
                    integrationOptions={integrationOptions}
                    onChange={(value, newType) => {
                      const isEmptyOrHasEmptyDescription = (opt) =>
                        Object.keys(opt).length === 0 || opt.description === ''

                      const { structureList } = this.props
                      const stepOpt = field?.stepOptions || {}

                      let newStepOptions = {}

                      if (isEmptyOrHasEmptyDescription(stepOpt)) {
                        if (newType.includes('vault')) {
                          const firstStructureSlug = structureList[0]?.slug
                          newStepOptions = {
                            description: '',
                            ...(newType.includes('vault-query')
                              ? { multi: 'multi' }
                              : {}),
                          }
                        }
                      } else {
                        const { description, vaultStructure, slug } = stepOpt
                        const defaultStructure = vaultStructure || slug
                        newStepOptions = {
                          description: description || '',
                          slug: defaultStructure,
                          ...(newType.includes('vault-query')
                            ? { multi: 'multi' }
                            : {}),
                        }
                      }

                      change(`${step}.stepOptions`, newStepOptions)
                      onChangeAttributeNeeded({
                        ...field,
                        stepType: newType,
                        stepOptions: newStepOptions,
                      })
                    }}
                    options={stepTypes}
                  />
                </div>
                <div className="pull-right" style={{ marginTop: '-4px' }}>
                  <StepToolbar
                    removeStep={removeStep}
                    stepType={field.stepType}
                    conditionCount={
                      field.conditions ? field.conditions.length : 0
                    }
                    fields={fields}
                    index={index}
                    addStep={addStep}
                    copyStep={copyStep}
                    onToggleDescription={(value) =>
                      this.setState({ showDescription: value })
                    }
                    onToggleConditions={(value) =>
                      this.setState({ showConditions: value })
                    }
                    onToggleStepOptions={(value) =>
                      this.setState({ showStepOptions: value })
                    }
                    onToggleResults={(value) =>
                      this.setState({ showResultOptions: value })
                    }
                  />
                </div>
              </div>
            )}
            {/* STEP HEADER */}
            <Col xs={6}>{!integrationsHaveLoaded && <Loading />}</Col>
            {showDescription && (
              <Col style={{ padding: '0px' }}>
                {outputOptionsLoaded ? (
                  <Field
                    name={`${step}.stepOptions.description`}
                    component={MarkdownInput}
                    label="Description"
                    help="General information to give background information"
                    options={filterCollections(outputOptions)}
                    vertical
                  />
                ) : (
                  <Loading />
                )}
              </Col>
            )}
            {/* CONDITIONS */}
            {showConditions &&
              outputOptionsLoaded &&
              !StepHelper.disableConditions(field.stepType) && (
                <Col xs={12} className="step-row">
                  <FieldArray
                    name={`${step}.conditions`}
                    component={this.renderConditions}
                    props={{ step }}
                  />
                </Col>
              )}
            {showStepOptions && outputOptionsLoaded && (
              <Col xs={12} className="step-row">
                {stepOptions}
              </Col>
            )}
            {showResultOptions && outputOptionsLoaded && (
              <Col xs={12} className="step-row">
                <StepResults
                  outputOptionsLoaded={outputOptionsLoaded}
                  step={step}
                  index={index}
                  processStep={field}
                  fields={fields}
                  addStep={addStep}
                  onChangeAttributeNeeded={onChangeAttributeNeeded}
                  change={change}
                />
              </Col>
            )}
          </div>
        </div>,
      ),
    )
  }
}

// Define property types
ProcessFormStep.propTypes = {
  step: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  // onDrop: PropTypes.func.isRequired,
  connectDragPreview: PropTypes.func.isRequired,
  connectDropTarget: PropTypes.func.isRequired,
  isOver: PropTypes.bool.isRequired,
  connectDragSource: PropTypes.func.isRequired,
  isDragging: PropTypes.bool.isRequired,
  displayGraph: PropTypes.bool,
  fields: PropTypes.shape().isRequired,
  onChangeAttributeNeeded: PropTypes.func.isRequired,
  array: PropTypes.shape().isRequired,
  change: PropTypes.func.isRequired,
  responseFields: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  attributes: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape()),
    PropTypes.shape(),
  ]).isRequired,
  stepTypes: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  integrationOptions: PropTypes.shape().isRequired,
  availableStepOutputOptions: PropTypes.arrayOf(
    PropTypes.arrayOf(PropTypes.shape()),
  ).isRequired,
  initiationOutputOptions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  executionOutputOptions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  settingsOptions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  integrationsHaveLoaded: PropTypes.bool.isRequired,
  addStep: PropTypes.func.isRequired,
  copyStep: PropTypes.func.isRequired,
  removeStep: PropTypes.func.isRequired,
  structureList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  outputOptionsLoaded: PropTypes.bool.isRequired,
}

ProcessFormStep.defaultProps = {
  // timeline: true,
  displayGraph: false,
}

const stepSource = {
  beginDrag: (props) => ({
    index: props.index,
  }),
}

const stepTarget = {
  drop: (props, monitor) => {
    const item = monitor.getItem()
    props.onDrop(props.index, item.index)
  },
}

const collect = (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  connectDragPreview: connect.dragPreview(),
  isDragging: monitor.isDragging(),
})

const collectTarget = (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
})

const target = DropTarget('step', stepTarget, collectTarget)(ProcessFormStep)

export default DragSource('step', stepSource, collect)(target)
