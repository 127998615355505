import _ from 'underscore'
import get from 'lodash/get'
import StringHelper from '../shared-helpers/StringHelper'

const getProcessStep = (execution, where) =>
  _.isFunction(where)
    ? _.find(execution.process.steps, where)
    : _.findWhere(execution.process.steps, where)

const getProcessStepFromExecutionStep = (execution, executionStep) => {
  if (!executionStep) {
    return null
  }

  return getProcessStep(
    execution,
    (p) =>
      p._id &&
      executionStep.processStep &&
      p._id.toString() === executionStep.processStep.toString(),
  )
}

const getExecutionStepById = (execution, executionStepId = '') =>
  execution.steps.find((s) => s._id.toString() === executionStepId.toString())

const getStep = (execution, where) => {
  if (!execution) return {}

  let step = null
  if (_.isString(where)) {
    step = getExecutionStepById(execution, where)
  } else if (_.isFunction(where)) {
    step = _.find(execution.steps, where)
  } else {
    step = _.findWhere(execution.steps, where)
  }

  return {
    ...step,
    processStep: getProcessStepFromExecutionStep(execution, step),
  }
}
const getExecutionStep = getStep

const getCurrentExecutionStep = (execution) =>
  execution.steps.find((s) => !s.complete)

const getExecutionStepByProcessStep = (execution, processStep) =>
  execution.steps.find(
    (s) => s.processStep.toString() === processStep._id.toString(),
  )

const getExecutionStepByProcessStepId = (execution, processStepId = '') =>
  execution.steps.find(
    (s) => s.processStep.toString() === processStepId.toString(),
  )

const calculateCompletion = (executions, completeOverride) =>
  executions
    .map((c) => {
      if (
        completeOverride &&
        c._id.toString() === completeOverride._id.toString()
      ) {
        return 1
      }

      return (c && c.progress) || 0
    })
    .reduce((sum, value) => sum + value, 0) / executions.length

const wasStepSkipped = (executionStep) =>
  executionStep.skipped || executionStep.skippedByBlock
const isComplete = (execution) => {
  const currentStep = execution.steps.find((s) => !s.complete)
  return (
    !currentStep && execution.steps.length >= execution.process.steps.length
  )
}

const markCompletedBySystem = (step) =>
  Object.assign(step, {
    complete: true,
    timeCompleted: new Date(Date.now()),
    userCompleted: -1,
  })

const getProjectedAssignee = (execution, stepIndex) => {
  let assignee =
    execution.assignee &&
    execution.users.find((a) => a && a._id === execution.assignee)
  if (!execution.assignedUsers || !_.keys(execution.assignedUsers).length) {
    return assignee
  }

  // loop over steps to find the most recent step with an assignee
  for (let i = stepIndex; i >= 0; i -= 1) {
    const step = execution.process.steps[i]
    const userId = execution.assignedUsers[step._id]

    if (userId) {
      assignee = execution.users.find((a) => a && a._id === userId)
      break
    }
  }

  return assignee
}

const getSourceData = (execution, processStep) => {
  const { source } = processStep.stepOptions
  const sourceStepId = StringHelper.getStepIdFromTemplate(source)
  const sourceAttribute = StringHelper.getAttributeFromTemplate(source)
  const sourceStep = getExecutionStep(
    execution,
    (s) =>
      s.processStep &&
      sourceStepId &&
      s.processStep.toString() === sourceStepId.toString(),
  )
  let sourceData = sourceStep.stepOutput

  if (sourceAttribute !== '.') {
    sourceData = get(sourceData, sourceAttribute)
  }

  return { sourceData, sourceStep, sourceStepId, sourceAttribute }
}

/**
 * Will get the current step from an execution. Fills the `processStep` attribute so it does
 * not need to be looked up later. Returns null if no current step.
 * @param {Execution} execution Contutit Execution object
 */
const getCurrentStep = (execution) => {
  if (!execution) return null

  const executionStep = getCurrentExecutionStep(execution)

  if (!executionStep) return null

  return {
    ...executionStep,
    processStep: getProcessStepFromExecutionStep(execution, executionStep),
  }
}

const stepHangingEmailTemplate = (rows) => `
    <html>
    <head>
    <style>

      table {

        font-family: arial, sans-serif;

        border-collapse: collapse;

        width: 100%;

      }


      td, th {

        border: 1px solid #dddddd;

        text-align: left;

        padding: 8px;

      }


      tr:nth-child(even) {

        background-color: #dddddd;

      }

    </style>
    </head>
    <body>
    <h1>Daily Report</h1>
    <p>Hanging steps for the last 5 days</p>
    <table>
      <thead>
        <tr>
          <th>Client</th>
          <th>Process</th>
          <th>Current Step</th>
          <th style="width: 30%">Error</th>
          <th>Updated</th>
          <th>Execution link</th>
        </tr>
      </thead>
      <tbody>
        ${rows.join('')}
      </tbody>
    </table>
    </body>
    </html>
  `

const ExecutionHelper = {
  getCurrentStep,

  getProcessStep,
  getProcessStepFromExecutionStep,

  getCurrentExecutionStep,
  getExecutionStep,
  getExecutionStepById,
  getExecutionStepByProcessStep,
  getExecutionStepByProcessStepId,
  getStep,

  getProjectedAssignee,

  calculateCompletion,
  markCompletedBySystem,

  wasStepSkipped,
  isComplete,
  stepHangingEmailTemplate,
}

export default ExecutionHelper
export {
  getCurrentStep,
  getProcessStep,
  getProcessStepFromExecutionStep,
  getCurrentExecutionStep,
  getExecutionStep,
  getExecutionStepById,
  getExecutionStepByProcessStep,
  getExecutionStepByProcessStepId,
  getStep,
  getProjectedAssignee,
  calculateCompletion,
  markCompletedBySystem,
  wasStepSkipped,
  isComplete,
  stepHangingEmailTemplate,
  getSourceData,
}
