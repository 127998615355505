import React from 'react';
import PropTypes from 'prop-types';
import { Field, FieldArray } from 'redux-form';
import { TextInput, TemplateInput, SelectInput } from 'lib/acromyrmex';
import {
  Button,
  Row,
  Col,
  SearchableSelect,
} from '../../../../../utility/UiComponents';
import {
  requiredValidator,
  selectRequiredValidator,
} from '../../../../../utility/formValidators';
import { formulaOptions } from './computationV2FormulaOptions';

const renderFunctions = ({ fields, outputOptions, hideLabel }) => {
  if (fields.length === 0) {
    fields.push({ functionName: 'trim', parameters: {} });
  }

  return (
    <>
      {fields.map((func, index) => {
        const functionDetails = fields.get(index);
        const selectedFunctionType = formulaOptions.find(
          (f) => f.id === functionDetails.functionType,
        );
        const selectedFunction = selectedFunctionType?.parameters.find(
          (f) => f.id === functionDetails.functionName,
        );

        // handle the empty replacement entry
        functionDetails.parameters = functionDetails.parameters || {};
        if (functionDetails.functionName === 'replace') {
          functionDetails.parameters.replacement =
            functionDetails.parameters.replacement || '';
        }

        return (
          // eslint-disable-next-line react/no-array-index-key
          <Col key={index}>
            <Row style={{ margin: '0 0 10px 0' }} md={6}>
              {index === 0 ? (
                <Col
                  xs={12}
                  md={3}
                  mdOffset={index && 6}
                  style={{ span: index !== 0 ? 3 : undefined }}
                >
                  <Row>
                    <Field
                      name={`${func}.functionType`}
                      component={SelectInput}
                      label="Function Type"
                      validate={selectRequiredValidator}
                      options={formulaOptions}
                      noLabel={hideLabel}
                    />
                  </Row>
                </Col>
              ) : (
                <Col
                  xs={12}
                  md={3}
                  mdOffset={index && 6}
                  style={{ span: index !== 0 ? 3 : undefined }}
                ></Col>
              )}
              <Col
                xs={12}
                md={3}
                mdOffset={index && 6}
                style={{ span: index !== 0 ? 3 : undefined }}
              >
                <Row>
                  <Field
                    name={`${func}.functionName`}
                    component={SelectInput}
                    label="Function Name"
                    validate={selectRequiredValidator}
                    options={
                      selectedFunctionType && selectedFunctionType.parameters
                    }
                    noLabel={hideLabel}
                  />
                </Row>
              </Col>
              <Col xs={12} md={3}>
                <Row>
                  {selectedFunction &&
                    selectedFunction?.parameters.map((param) => {
                      const SelectComponent =
                        param.name === 'format'
                          ? SearchableSelect
                          : SelectInput;
                      return (
                        <Field
                          plainText
                          key={param.name}
                          name={`${func}.parameters.${param.name}`}
                          component={
                            param.options ? SelectComponent : TemplateInput
                          }
                          options={param.options || outputOptions}
                          label={param.name}
                          validate={param.required && requiredValidator}
                          noLabel={hideLabel}
                        />
                      );
                    })}
                </Row>
              </Col>
              <Col
                xs={12}
                sm={12}
                md={3}
                mdOffset={1}
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'baseline',
                  padding: 0,
                }}
              >
                <Button
                  variant="danger"
                  onClick={() => fields.remove(index)}
                  disabled={fields.length <= 1}
                  className="pull-right btn-sm"
                  style={{ marginRight: '20px' }}
                >
                  <i className="fa fa fa-minus-circle" />
                </Button>

                <Button
                  variant="success"
                  className="pull-right btn-sm"
                  onClick={() =>
                    fields.insert(index + 1, {
                      functionType: selectedFunctionType.id || '',
                      functionName: '',
                      parameters: {},
                    })
                  }
                  disabled={selectedFunction && !selectedFunction.chainable}
                >
                  <i className="fa fa-plus" />
                </Button>
              </Col>
            </Row>
          </Col>
        );
      })}
    </>
  );
};

const ComputationV2FormRow = ({
  attr,
  fields,
  index,
  outputOptions,
  change,
  hideLabel,
  onChangeAttributeNeeded,
}) => (
  <Row key={attr} className="struct-form-row">
    <Col xs={12} md={2}>
      <Row>
        <Field
          name={`${attr}.display`}
          component={TextInput}
          label="Key"
          validate={requiredValidator}
          onPaste={(e) => {
            const pastedText = e.clipboardData.getData('Text');
            const rows = pastedText.split('\n');

            if (rows.length > 1) {
              // don't add the pasted text to the value
              e.preventDefault();
              change(`${attr}.display`, rows[0]);
              // remove the first element
              rows.shift();

              // add our other fields
              rows.forEach((r) => {
                fields.push({
                  display: r,
                  value: '',
                });
              });
            }
          }}
          noLabel={hideLabel}
        />
      </Row>
    </Col>
    <Col xs={12} md={2}>
      <Row>
        <Field
          name={`${attr}.input`}
          component={TemplateInput}
          options={outputOptions}
          label="Input"
          validate={requiredValidator}
          enableEmpty
          noLabel={hideLabel}
          onChange={() => onChangeAttributeNeeded()}
          plainText
        />
      </Row>
    </Col>
    <Col xs={12} md={8}>
      <FieldArray
        name={`${attr}.functionList`}
        component={renderFunctions}
        outputOptions={outputOptions}
        hideLabel={hideLabel}
      />
    </Col>

    <Col xs={6} sm={3} md={12} style={{ marginBottom: '10px' }}>
      <Button
        className="pull-right btn-sm"
        variant="danger"
        onClick={() => fields.remove(index)}
      >
        <i className="fa fa-trash" /> Remove Key
      </Button>
    </Col>
  </Row>
);

ComputationV2FormRow.propTypes = {
  attr: PropTypes.string.isRequired,
  fields: PropTypes.shape().isRequired,
  index: PropTypes.number.isRequired,
  change: PropTypes.func.isRequired,
  hideLabel: PropTypes.bool.isRequired,
  outputOptions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onChangeAttributeNeeded: PropTypes.func.isRequired,
};

export default ComputationV2FormRow;

export { formulaOptions };
