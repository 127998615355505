import { SLACK_LISTENER_STEP_TYPE } from '../../../constants'
import BasicUiHelper from './BasicUiHelper'
import SlackListenerStepOptions from './components/stepOptions/SlackListenerStepOptions'

class InputUiHelper extends BasicUiHelper {
  regex() {
    return SLACK_LISTENER_STEP_TYPE
  }

  name() {
    return 'MS Teams Listener'
  }

  stepOptions() {
    return SlackListenerStepOptions
  }

  // what shows in the result
  getOutputKeys() {
    return {
      schema: {
        '.': {
          type: 'Table',
        },
      },
    }
  }

  // this will be the message data
  getOutputOptions(step) {
    return [
      {
        id: JSON.stringify({ step: step._id, key: 'text' }),
        name: 'Message Text',
        textValue: `{${step._id}.text}`,
        type: 'string',
      },
      {
        id: JSON.stringify({ step: step._id, key: 'user' }),
        name: 'User',
        textValue: `{${step._id}.user}`,
        type: 'string',
      },
      {
        id: JSON.stringify({ step: step._id, key: 'channel' }),
        name: 'Channel',
        textValue: `{${step._id}.channel}`,
        type: 'string',
      },
    ]
  }
}

export default InputUiHelper
