import React from 'react'
import PropTypes from 'prop-types'
import { Field, FieldArray } from 'redux-form'
import { TextInput, TemplateInput, SelectInput } from 'lib/acromyrmex'
import { Button, Row, Col } from '../../../../../utility/UiComponents'
import {
  requiredValidator,
  selectRequiredValidator,
} from '../../../../../utility/formValidators'
import { formulaOptions } from './dateFormulaOptions'

const renderFunctions = ({ fields, outputOptions, hideLabel }) => {
  if (fields.length === 0) {
    fields.push({ functionName: 'trim', parameters: {} })
  }

  return (
    <>
      {fields.map((func, index) => {
        const functionDetails = fields.get(index)
        const selectedFunction = formulaOptions.find(
          (f) => f.id === functionDetails.functionName,
        )

        return (
          // eslint-disable-next-line react/no-array-index-key
          <Col key={index} style={{ padding: 0 }}>
            <Row style={{ margin: '0 0 10px 0' }}>
              <Col style={{ span: index !== 0 ? 3 : undefined }}>
                <Row>
                  <Field
                    name={`${func}.functionName`}
                    component={SelectInput}
                    label="Function Name"
                    validate={selectRequiredValidator}
                    options={formulaOptions}
                    noLabel={hideLabel}
                  />
                </Row>
              </Col>
              <Col>
                <Row>
                  {selectedFunction &&
                    selectedFunction.parameters.map((param) => (
                      <Field
                        key={param.name}
                        name={`${func}.parameters.${param.name}`}
                        component={param.options ? SelectInput : TemplateInput}
                        options={param.options || outputOptions}
                        label={param.name}
                        validate={
                          param.required ? requiredValidator : undefined
                        }
                        noLabel={hideLabel}
                      />
                    ))}
                </Row>
              </Col>
              <Col
                style={{
                  display: 'flex',
                  alignItems: 'baseline',
                  justifyContent: 'flex-end',
                }}
              >
                <Button
                  variant="danger"
                  onClick={() => fields.remove(index)}
                  disabled={!fields.length}
                  className="pull-right btn-sm"
                  style={{ marginRight: '20px' }}
                >
                  <i className="fa fa fa-minus-circle" />
                </Button>

                {selectedFunction &&
                  !['greater-than', 'less-than', 'equal'].includes(
                    selectedFunction.id,
                  ) && (
                    <Button
                      variant="success"
                      onClick={() =>
                        fields.push({ functionName: '', parameters: {} })
                      }
                      className="pull-right btn-sm"
                    >
                      <i className="fa fa-plus" />
                    </Button>
                  )}
              </Col>
            </Row>
          </Col>
        )
      })}
    </>
  )
}

const DateFunctionFormRow = ({
  attr,
  fields,
  index,
  outputOptions,
  change,
  hideLabel,
  onChangeAttributeNeeded,
}) => (
  <Row key={attr} className="struct-form-row">
    <Col xs={12} md={3}>
      <Row>
        <Field
          name={`${attr}.display`}
          component={TextInput}
          label="Name"
          validate={requiredValidator}
          onPaste={(e) => {
            const pastedText = e.clipboardData.getData('Text')
            const rows = pastedText.split('\n')

            if (rows.length > 1) {
              // don't add the pasted text to the value
              e.preventDefault()
              change(`${attr}.display`, rows[0])
              // remove the first element
              rows.shift()

              // add our other fields
              rows.forEach((r) => {
                fields.push({
                  display: r,
                  value: '',
                })
              })
            }
          }}
          noLabel={hideLabel}
        />
      </Row>
    </Col>
    <Col xs={12} md={3}>
      <Row>
        <Field
          name={`${attr}.input`}
          component={TemplateInput}
          options={outputOptions}
          label="Input"
          validate={requiredValidator}
          enableEmpty
          noLabel={hideLabel}
          onChange={() => onChangeAttributeNeeded()}
        />
      </Row>
    </Col>
    <Col style={{padding: 0}}>
      <FieldArray
        name={`${attr}.functionList`}
        component={renderFunctions}
        outputOptions={outputOptions}
        hideLabel={hideLabel}
      />
    </Col>
    <Col xs={6} sm={3} md={12} style={{ marginBottom: '10px' }}>
      <Button
        className="pull-right btn-sm"
        variant="danger"
        onClick={() => fields.remove(index)}
      >
        <i className="fa fa-trash" /> Remove Key
      </Button>
    </Col>
  </Row>
)

DateFunctionFormRow.propTypes = {
  attr: PropTypes.string.isRequired,
  fields: PropTypes.shape().isRequired,
  index: PropTypes.number.isRequired,
  change: PropTypes.func.isRequired,
  hideLabel: PropTypes.bool.isRequired,
  outputOptions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onChangeAttributeNeeded: PropTypes.func.isRequired,
}

export default DateFunctionFormRow

export { formulaOptions }
