import each from 'lodash/each';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactJson from 'react-json-view';
import { ListGroup, ListGroupItem } from '../../utility/UiComponents';
import { getSuccessResponseAttributes } from '../../shared-helpers/ApiHelper';

class IntegrationApiDetails extends Component {
  static propTypes = {
    integrationApi: PropTypes.shape().isRequired,
    hideDescription: PropTypes.bool,
    showResponse: PropTypes.bool
  };

  static defaultProps = {
    hideDescription: false,
    showResponse: false
  };

  state = {};

  render() {
    const { integrationApi, hideDescription, showResponse } = this.props;

    // TODO: Show the output data as well

    let responseAttributes;
    try {
      responseAttributes = getSuccessResponseAttributes(integrationApi);
    } catch (responseAttributeError) {
      
    }

    each(responseAttributes, (a, key) => {
      if (key === '.') {
        if (a.type === 'collection') {
          responseAttributes = [{ ...a.properties }];
        } else {
          responseAttributes = { ...a };
        }
      }
    });

    let requiredParams =
      integrationApi.parameters &&
      integrationApi.parameters.length > 0 &&
      integrationApi.parameters
        .filter(param => param.required)
        .map(param => (
          <ListGroupItem key={param.name} header={param.name}>
            {param.display || param.name}
          </ListGroupItem>
        ));
    requiredParams = requiredParams && requiredParams.length > 0 ? requiredParams : undefined;

    let optionalParams =
      integrationApi.parameters &&
      integrationApi.parameters.length > 0 &&
      integrationApi.parameters
        .filter(param => !param.required)
        .map(param => (
          <ListGroupItem key={param.name}>{param.display || param.name}</ListGroupItem>
        ));

    optionalParams = optionalParams && optionalParams.length > 0 ? optionalParams : undefined;

    const filterableParams =
      integrationApi.filterableParameters &&
      integrationApi.filterableParameters.length > 0 &&
      integrationApi.filterableParameters.map(param => (
        <ListGroupItem key={param.name}>{param.display || param.name}</ListGroupItem>
      ));
    return (
      <div>
        <h1>
          {integrationApi.name} <small>{integrationApi.brand}</small>
        </h1>
        {!hideDescription && <h2>Description</h2>}
        {!hideDescription && <p>{integrationApi.description}</p>}
        {requiredParams && <h2>Required Parameters</h2>}
        {requiredParams && <ListGroup>{requiredParams}</ListGroup>}
        {optionalParams && <h2>Optional Parameters</h2>}
        {optionalParams && <ListGroup>{optionalParams}</ListGroup>}
        {filterableParams && <h2>Filterable Parameters</h2>}
        {filterableParams && <ListGroup>{filterableParams}</ListGroup>}
        {showResponse && (
          <ReactJson
            name="Response Attributes"
            collapsed
            src={responseAttributes}
            displayDataTypes={false}
          />
        )}
      </div>
    );
  }
}

export default IntegrationApiDetails;
