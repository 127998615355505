import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Loading } from 'lib/acromyrmex';
import { Alert } from '../../utility/UiComponents';
import EmptyView from '../shared/EmptyView';
import { FlexRow } from '../shared/Flex';
import Vault from '../../utility/vault';
import VaultEntryButtons from './VaultEntryButtons';
import {
  loadVaultStructureData as loadVaultStructureDataAction,
  loadRecentVaultData as loadRecentVaultDataAction,
  loadStructureList as loadStructureListAction
} from '../../actions/vault';
import VaultEntryMention from '../processes/ShowExecution/VaultEntryMention';

class ListRecentVaultUpdates extends Component {
  componentDidMount() {
    const { loadRecentVaultData, loadStructureList, loadVaultStructureData } = this.props;

    loadRecentVaultData();
    loadStructureList();
    loadVaultStructureData('customers');
  }

  getColumns() {
    const { structureList, vault } = this.props;
    const customers = Vault.getData(vault, 'customers');

    return [
      {
        key: 'customer',
        name: 'Customer',
        isPrimary: true,
        formatter: item => {
          if (item.vaultStructure === 'customers') {
            return 'NA';
          }

          if (!item.customer) {
            return 'Not assigned';
          }

          const customer =
            customers && customers.rows && customers.rows.find(c => c._id === item.customer);

          if (!customer) {
            return 'Error: Customer not found';
          }

          return customer.name;
        }
      },
      {
        key: 'name',
        name: 'Name',
        isPrimary: true,
        formatter: item => {
          const struct = structureList.find(s => s.slug === item.vaultStructure);

          if (!struct) {
            return 'Error';
          }

          return Vault.getDisplayFromStruct(struct, item);
        }
      },
      {
        key: 'structure',
        name: 'Structure',
        isPrimary: true,
        formatter: item => {
          const struct = structureList.find(s => s.slug === item.vaultStructure);

          if (!struct) {
            return 'Error';
          }

          return struct.display;
        }
      },
      {
        key: 'dateUpdated',
        name: 'Date Updated',
        type: 'dateTime',
        isPrimary: true
      },
      {
        key: 'detail',
        name: 'View Detail',
        formatter: item => <VaultEntryButtons entry={item} slug={item.vaultStructure} viewDetails />
      }
    ];
  }

  getRows() {
    const { recentVaultData } = this.props;

    if (!recentVaultData) {
      return [];
    }

    return recentVaultData;
  }

  render() {
    const { isLoadingVaultUpdates, isLoadingStructureList, structureList } = this.props;

    if (isLoadingVaultUpdates || isLoadingStructureList) {
      return (
        <Alert variant="info" className="pull-left indented">
          <Loading /> Loading Recent Vault Updates
        </Alert>
      );
    }

    if (this.getRows().length === 0) {
      return (
        <EmptyView
          header="No recent vault updates found."
          content="There are no recent updates to the vault at this time."
          button={{
            pathname: '/vault',
            content: 'View Vault Structures',
            style: 'info'
          }}
        />
      );
    }

    return (
      <div>
        <h4>Recently Updated Vault Entries</h4>
        {this.getRows().map(row => {
          const struct = structureList.find(s => s.slug === row.vaultStructure);
          return (
            <FlexRow key={row._id}>
              <div style={{ flex: 1 }}>
                <VaultEntryMention struct={struct} data={row} />
              </div>
            </FlexRow>
          );
        })}
      </div>
    );
  }
}

ListRecentVaultUpdates.propTypes = {
  loadRecentVaultData: PropTypes.func.isRequired,
  loadStructureList: PropTypes.func.isRequired,
  loadVaultStructureData: PropTypes.func.isRequired,
  isLoadingVaultUpdates: PropTypes.bool.isRequired,
  isLoadingStructureList: PropTypes.bool.isRequired,
  recentVaultData: PropTypes.arrayOf(PropTypes.shape()),
  structureList: PropTypes.arrayOf(PropTypes.shape()),
  vault: PropTypes.shape().isRequired
};

ListRecentVaultUpdates.defaultProps = {
  recentVaultData: [],
  structureList: []
};

const mapStateToProps = state => ({
  isLoadingVaultUpdates: state.vault.recentData.isLoading,
  isLoadingStructureList: state.vault.structureList.isLoading,
  recentVaultData: state.vault.recentData.rows,
  structureList: state.vault.structureList.rows,
  vault: state.vault
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      loadVaultStructureData: loadVaultStructureDataAction,
      loadRecentVaultData: loadRecentVaultDataAction,
      loadStructureList: loadStructureListAction
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ListRecentVaultUpdates);
