import React, { Component } from 'react';
import { Loading } from 'lib/acromyrmex';
import PropTypes from 'prop-types';
import { Alert, Col } from '../../../../../utility/UiComponents';
import ChildProcessList from './ChildProcessList';

class LoopProcessAutomating extends Component {
  static _getChildExecutionList(currentExecutionStep) {
    if (!currentExecutionStep || !currentExecutionStep.stepOutput) {
      return false;
    }

    return (
      currentExecutionStep.stepOutput.executionList || currentExecutionStep.stepOutput.completedList
    );
  }

  state = {};

  // eslint-disable-next-line
  _renderExecutionWaiting() {
    return (
      <Alert variant="info" className="pull-left">
        <Loading text="creating execution..." />
      </Alert>
    );
  }

  // tells you if all child executions are already downloaded (boolean)

  render() {
    const { currentExecutionStep, execution } = this.props;

    const executionList = LoopProcessAutomating._getChildExecutionList(currentExecutionStep);

    if (currentExecutionStep.error) {
      return <div />;
    }

    if (!executionList) {
      return this._renderExecutionWaiting();
    }

    return (
      <Col xs={12} sm={6}>
        <ChildProcessList
          currentExecutionStep={currentExecutionStep}
          execution={execution}
          executionList={executionList}
        />
      </Col>
    );
  }
}

LoopProcessAutomating.propTypes = {
  currentExecutionStep: PropTypes.shape().isRequired,
  execution: PropTypes.shape().isRequired
};

LoopProcessAutomating.defaultProps = {};

export default LoopProcessAutomating;
