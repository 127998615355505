// import _ from 'underscore';
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Row, Accordion} from '../../../../../utility/UiComponents';
import IntegrationSelect from './integration/IntegrationSelect';

// eslint-disable-next-line
class SlackListenerStepOptions extends React.Component {
  // constructor(props) {
  //   super(props);
  // }

  render() {
    const { fieldText, processStep, outputOptions, outputOptionsLoaded } = this.props;

    return (
      <Row key={fieldText}>
          <Accordion defaultActiveKey="1">
          <Accordion.Item eventKey="1">
            <Accordion.Header>{`${processStep.name || ''} Step Options`}</Accordion.Header>
            <Accordion.Body>
            <IntegrationSelect
              name={`${fieldText}.stepOptions.integration`}
              stepType="i(slack)[sendsamessagetoachannel]"
              outputOptions={outputOptions}
              outputOptionsLoaded={outputOptionsLoaded}
            />
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Row>
    );
  }
}

// Define property types
SlackListenerStepOptions.propTypes = {
  fieldText: PropTypes.string.isRequired,
  processStep: PropTypes.shape().isRequired,
  outputOptions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  outputOptionsLoaded: PropTypes.bool.isRequired
};

SlackListenerStepOptions.defaultProps = {};

const mapStateToProps = state => ({
  integrations: state.enabledIntegrations.enabledIntegrations.rows,
  vault: state.vault
});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SlackListenerStepOptions);
