import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Field, FieldArray } from 'redux-form';
import { SelectInput, TemplateInput, Icon } from 'lib/acromyrmex';
import VaultSelectInput from '../../../../shared/form/VaultSelectInput';
import { loadStructureList as loadStructureListAction } from '../../../../../actions/vault';
import { Row, Col, Button, Modal } from '../../../../../utility/UiComponents';
import { getAvailableProcessInputOptions } from '../../UiStepHelper';
import AddButton from '../../../../shared/form/AddButton';
import UiAttributeHelper from '../../../../vaultStructures/attributes/UiAttributeHelper';

class FillModal extends Component {
  static renderFills({ fields, fillableData, outputOptions, change, onlyStatic }) {
    const fills = fields.map((member, index) =>
      FillModal.renderFill(member, fillableData, onlyStatic, outputOptions, change, fields, index)
    );

    const noFillsLeft = fields.length >= fillableData.length;

    

    return (
      <div>
        {fills}
        <Row>
          <Col xs={12}>
            <AddButton size="sm"
              onClick={() => fields.push({ type: 'static', fill: fillableData[0].id })}
              label="Add Fill"
              disabled={noFillsLeft}
            />
            {noFillsLeft && (
              <span
                className="text-info pull-right"
                style={{ marginTop: '5px', marginRight: '5px' }}
              >
                (All data is filled)
              </span>
            )}
          </Col>
        </Row>
      </div>
    );
  }

  static renderFill(fillName, fillableData, onlyStatic, outputOptions, change, fields, index) {
    const fill = fields.get(index);
    let fillDataSelected = fillableData.find(f => f.id === fill.fill);

    if (!fillDataSelected) {
      [fillDataSelected] = fillableData;
    }

    // capture the list of previously used fills so we don't duplicate
    const usedFills = [];
    fields.forEach((n, i, l) => {
      const obj = l.get(i);
      if (i < index) {
        usedFills.push(obj.id);
      }
    });

    return (
      <Row key={fillName}>
        <Col xs={4}>
          <Row>
            <Field
              name={`${fillName}.fill`}
              component={SelectInput}
              noLabel
              onChange={() => {
                change(`${fillName}.static`, '');
                change(`${fillName}.dynamic`, '');
              }}
              options={fillableData
                .map(d => ({ name: `${d.name} (${d.type})`, id: d.id }))
                .filter(d => !usedFills.includes(d.id))}
            />
          </Row>
        </Col>
        <Col xs={3}>
          <Row>
            <Col xs={2}>
              <Field
                name={`${fillName}.type`}
                className="offset-radio"
                component="input"
                type="radio"
                value="static"
              />
            </Col>
            <Col xs={10}>
              {fillDataSelected && FillModal.getStaticField(fillDataSelected, fillName, fill)}
            </Col>
          </Row>
        </Col>
        <Col xs={4}>
          {!onlyStatic && (
            <Row>
              <Col xs={2}>
                <Field
                  name={`${fillName}.type`}
                  className="offset-radio"
                  component="input"
                  type="radio"
                  value="dynamic"
                />
              </Col>
              <Col xs={10}>
                <Field
                  name={`${fillName}.dynamic`}
                  component={TemplateInput}
                  options={outputOptions.filter(
                    opt =>
                      opt.type === fillDataSelected.type ||
                      ((fillDataSelected.type === 'string' || fillDataSelected.type === 'text') &&
                        (opt.type === 'number' || opt.type === 'integer')) ||
                      UiAttributeHelper.extendedTypeMatches(fillDataSelected.type, opt)
                  )}
                  disabled={fill.type !== 'dynamic'}
                  noLabel
                  onTemplateClicked={option => {
                    change(`${fillName}.dynamic`, option.textValue);
                  }}
                />
              </Col>
            </Row>
          )}
        </Col>
        <Col xs={1}>
          <Button variant="danger" className="pull-right" onClick={() => fields.remove(index)}>
            <Icon remove />
          </Button>
        </Col>
      </Row>
    );
  }

  // determine the field to render for static value
  static getStaticField(fillDataSelected, fillName, fill) {
    let field = null;
    if (fillDataSelected && fillDataSelected.type.startsWith('vault')) {
      // the slug begins after 'vault-'
      const slug = fillDataSelected.type.substr(6);
      field = (
        <VaultSelectInput
          slug={slug}
          columns={12}
          noLabel
          name={`${fillName}.static`}
          disabled={fill.type !== 'static'}
          normalize={value => (value === 'none' ? null : value)}
        />
      );
    } else {
      field = UiAttributeHelper.getEditField(
        { ...fillDataSelected, key: `${fillName}.static` },
        '',
        null,
        {
          noLabel: true,
          disabled: fill.type !== 'static'
        }
      );
    }

    return field;
  }

  constructor(props) {
    super(props);
    this.state = {
      showModal: false
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  componentWillMount() {
    const { loadStructureList } = this.props;

    loadStructureList();
  }

  openModal() {
    this.setState({ showModal: true });
  }

  closeModal() {
    this.setState({ showModal: false });
  }

  render() {
    const {
      change,
      integrations,
      structureList,
      processStep,
      processes,
      fieldText,
      onlyStatic,
      fillProcess,
      outputOptions,
      nameOverride
    } = this.props;
    const { showModal } = this.state;

    if (!processes) {
      return (
        <div>
          <Button variant="primary" className="form-pad-label" disabled>
            Fill Data
          </Button>
        </div>
      );
    }

    const selectedProcess =
      fillProcess || processes.find(p => p._id === processStep.stepOptions.processId);

    const fillableData = selectedProcess
      ? getAvailableProcessInputOptions(selectedProcess, integrations, structureList)
      : [];

    return (
      <div>
        <Button variant="primary" className="form-pad-label" onClick={this.openModal}>
          Fill Data
        </Button>
        <Modal
          show={showModal}
          onHide={this.closeModal}
          size="large"
          className="process-pre-fill"
        >
          <Modal.Header closeButton>
            <Modal.Title>Fill Data for process</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Enter any data that you would like to propagate to the sub-process</p>
            <Row>
              <Col xs={4}>
                <strong>Fill</strong>
              </Col>
              <Col xs={3}>
                <strong>Static Value</strong>
              </Col>
              {!onlyStatic && (
                <Col xs={4}>
                  <strong>Dynamic Value</strong>
                </Col>
              )}
            </Row>
            <FieldArray
              name={nameOverride || `${fieldText}.stepOptions.fills`}
              component={FillModal.renderFills}
              props={{
                fillableData,
                outputOptions,
                change,
                onlyStatic
              }}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" onClick={this.closeModal}>
              Done
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

FillModal.propTypes = {
  change: PropTypes.func.isRequired,
  nameOverride: PropTypes.string,
  fillProcess: PropTypes.shape(),
  integrations: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  processStep: PropTypes.shape(),
  processes: PropTypes.arrayOf(PropTypes.shape()),
  fieldText: PropTypes.string,
  outputOptions: PropTypes.arrayOf(PropTypes.shape()),
  onlyStatic: PropTypes.bool,

  // from redux
  loadStructureList: PropTypes.func.isRequired,
  structureList: PropTypes.arrayOf(PropTypes.shape()).isRequired
};

FillModal.defaultProps = {
  processes: [],
  onlyStatic: false,
  fillProcess: null,
  nameOverride: null,
  processStep: null,
  fieldText: null,
  outputOptions: []
};

const mapStateToProps = state => ({
  structureList: state.vault.structureList.rows,
  integrations: state.enabledIntegrations.enabledIntegrations.rows
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      loadStructureList: loadStructureListAction
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(FillModal);
