import _ from 'lodash'
import { TRIGGER_STEP_TYPE } from '../../../constants'
import BasicUiHelper from './BasicUiHelper'
import TriggerStepOptions from './components/stepOptions/trigger'
import TriggerStep from './components/stepExecutions/trigger'
import {
  getActionFromStepType,
  getIntegrationFromStepType,
} from 'utility/integrationHelpers'
import MopHelper from '../../../shared-helpers/MopHelper'

class IntegrationTriggerUiHelper extends BasicUiHelper {
  regex() {
    return TRIGGER_STEP_TYPE
  }

  name() {
    return 'Integration Trigger'
  }

  canTryAgain() {
    return false
  }

  stepOptions() {
    return TriggerStepOptions
  }

  stepExecution() {
    return TriggerStep
  }

  getOutputKeys() {
    return {
      schema: {
        '.': {
          type: 'Table',
        },
      },
    }
  }

  getOutputOptions(step, { integrations }) {
    const integrationName = getIntegrationFromStepType(step.stepType)

    const integration =
      integrations &&
      integrations.find(
        (item) => item.spec['x-integration-slug'] === integrationName,
      )

    if (!integration) {
      return
    }

    const action = getActionFromStepType(step.stepType)
    const operation = MopHelper.getOperation(integration.spec, action)
    return IntegrationTriggerUiHelper.getResponseAttributesForOperation(
      step,
      operation,
    )
  }

  static getResponseAttributesForOperation(step, operation) {
    const responseAttributes = MopHelper.getSuccessResponseAttributes(operation)
    if (!responseAttributes) {
      return
    }

    const result = []

    // prefix should be the format of '.attr' where starts with dot and finishes without
    const propertiesToArray = (properties, prefix = '', namePrefix = '') =>
      _.each(properties, (prop, k) => {
        if (prop.type === 'object') {
          return propertiesToArray(
            prop.properties,
            `${prefix}.${k}`,
            `${namePrefix}${prop.display || k} -> `,
          )
        }

        result.push({
          id: JSON.stringify({
            step: step._id,
            field: prop,
            k: `${prefix}.${k}`,
          }),
          name: `${namePrefix}${prop.display || k}`,
          textValue: `{${step._id}${prefix}.${k}}`,
          type: prop.type,
          properties:
            prop.type === 'array' && prop.items
              ? propertiesToArray(prop.items.properties, `${prefix}.${k}`)
              : undefined,
        })
      })

    // how it was handled before
    const oldp2A = (properties, prefix = '', namePrefix = '') =>
      _.map(properties, (prop, k) => ({
        id: JSON.stringify({
          step: step._id,
          field: prop,
          k: `${prefix}.${k}`,
        }),
        name: `${namePrefix}${prop.display || k}`,
        textValue: `{${step._id}${prefix}.${k}}`,
        type: prop.type,
        properties:
          prop.type === 'array' && prop.items
            ? oldp2A(prop.items.properties, `${prefix}.${k}`)
            : undefined,
      }))

    _.forEach(responseAttributes, (attr, key) => {
      if (attr.type === 'object') {
        propertiesToArray(
          attr.properties,
          `.${key}`,
          `${attr.display || key} -> `,
        )
      } else {
        result.push({
          id: JSON.stringify({ step: step._id, field: attr, key }),
          name: `${attr.display || key}`,
          textValue: `{${step._id}.${key}}`,
          properties: oldp2A(attr.properties),
          type: attr.type,
        })
      }
    })

    if (result.length === 1 && result[0].type === 'collection') {
      result.push(BasicUiHelper.getLengthAttribute(step))
    }

    return result
  }
}

export default IntegrationTriggerUiHelper
