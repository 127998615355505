/* eslint-disable class-methods-use-this */
import React from 'react';
import {VAULT_QUERY_V2_STEP_TYPE} from '../../../constants';
import VaultQueryV2StepOptions from './components/stepOptions/VaultQueryV2StepOptions';
import BasicUiHelper from './BasicUiHelper';
import VaultInputUiHelper from './VaultInputUiHelper';
import useTemplateString from '../../../hooks/useTemplateString';

class VaultQueryV2UiHelper extends BasicUiHelper {
  regex() {
    return VAULT_QUERY_V2_STEP_TYPE;
  }

  name() {
    return 'Vault Query V2';
  }

  canTryAgain() {
    return true;
  }

  stepOptions() {
    return VaultQueryV2StepOptions;
  }

  getOptionSummary() {
    return [
      { key: 'vaultStructure', title: 'Vault Structure', type: 'VaultStructure' },
      {
        key: 'queryFields',
        title: 'Query Fields',
        default: [],
        formatter: (value, currentUser, execution) => (
          <table className="parameter-table">
            <tbody>
              {value.map(param => {
                const [outputString, error] = useTemplateString(
                  param.query,
                  currentUser,
                  execution,
                  'loading...'
                );
                return (
                  <tr>
                    <td>{param.attribute}</td>
                    <td>{outputString || error}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )
      }
    ];
  }

  getOutputKeys() {
    return {
      schema: {
        '.': {
          type: 'Table',
          vaultStructureStepOption: 'vaultStructure',
          ignoreColumns: [
            'site',
            'businessUnit',
            'customer',
            '_id',
            'hashChanged',
            'integrations',
            'vaultStructure'
          ]
        }
      }
    };
  }

  getInputOptions(step, { structureList }) {
    const { multi } = step.stepOptions;

    // when we just have a single item, we treat it like a vault input
    if (multi !== 'multi') {
      return VaultInputUiHelper.getInputOptions(step, { structureList });
    }
  }

  getOutputOptions(step, { structureList }) {
    const { vaultStructure, multi } = step.stepOptions;

    const struct = structureList.find(s => s.slug === vaultStructure);

    if (!struct) return;

    // when we just have a single item, we treat it like a vault input
    if (multi !== 'multi') {
      // define this since we use it twice
      const mapFunc = obj => ({
        id: JSON.stringify({ step: step._id, field: obj.key }),
        name: `${obj.display}`,
        textValue: `{${step._id}.${obj.key}}`,
        type: obj.type
      });

      return [
        ...struct.attributes.map(mapFunc),
        {
          id: JSON.stringify({ step: step._id, field: '_id' }),
          name: 'Vault ID',
          textValue: `{${step._id}._id}`,
          type: 'string',
          vault: true,
          slug: struct.slug
        }
      ];
    }

    return [
      {
        id: JSON.stringify({ step: step._id, key: '.' }),
        name: 'data',
        textValue: `{${step._id}..}`,
        type: 'vault-collection',
        slug: step.stepOptions.vaultStructure,
        properties: struct.attributes.map(attr => ({
          id: JSON.stringify({ step: step._id, field: attr.key }),
          name: `${attr.display}`,
          textValue: `{${step._id}.${attr.key}}`,
          type: attr.type
        }))
      },
      super.getLengthAttribute(step)
    ];
  }
}

export default VaultQueryV2UiHelper;
