import React, { useState } from 'react';
import PropTypes from 'prop-types';
import isObject from 'lodash/isObject';
import {
  FormGroup, InputGroup, FormControl, Button,
} from '../../../utility/UiComponents';
import useAsyncAction from '../../../hooks/useAsyncAction';
import request from '../../../utility/request';
import AddButton from '../../shared/form/AddButton';
import FileUploadModal from '../../shared/FileUpload/FileUploadModal';

/**
 * Helper function to run a method if the enter key was pressed
 * @param {Object} event React event usually from a keyPress
 * @param {Function} action Function called if the Enter key was pressed
 */
const ifEnterPressed = (event, action) => {
  if (event.key === 'Enter') {
    action(event.target.value);
  }
};

const postComment = (execution, token, comment) => {
  comment = isObject(comment) ? comment : { text: comment };
  return request
    .post(`/api/processes/executions/${execution._id}/comments`)
    .set({ 'x-access-token': token })
    .send(comment)
    .then(({ body }) => body);
};

const postFiles = (execution, token, files, commentText = 'Uploaded Files:') => {
  
  return postComment(execution, token, { text: commentText, files });
};

function CommentSection({ execution, currentUser: { token } }) {
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [commentText, setCommentText] = useState('');
  const [, loading, error, submitComment] = useAsyncAction(
    postComment.bind(null, execution, token),
  );
  const [, , , submitFiles] = useAsyncAction(postFiles.bind(null, execution, token));

  return (
    <div className="comment-wrapper">
      <div className="comment-box">
        <FormGroup>
          <InputGroup>
          <AddButton size="md" onClick={() => setShowUploadModal(true)}   />
            <FormControl
              type="text"
              placeholder="Add comment..."
              value={commentText}
              disabled={loading}
              error={error}
              onKeyPress={(event) => ifEnterPressed(event, (value) => submitComment(value).then(() => setCommentText('')))}
              onChange={({ target: { value } }) => setCommentText(value)}
            />
          </InputGroup>
          <FileUploadModal
            onSubmitFiles={(files) => submitFiles(files)}
            show={showUploadModal}
            onHide={() => setShowUploadModal(false)}
          />
        </FormGroup>
        <div className="formatting-hints clearfix">
          <strong>**bold**</strong>
          <i>*italics*</i>
          <code>`code`</code>
          <blockquote>&gt; quote</blockquote>
        </div>
      </div>
    </div>
  );
}

CommentSection.propTypes = {
  execution: PropTypes.shape().isRequired,
  currentUser: PropTypes.shape().isRequired,
};

export default CommentSection;
