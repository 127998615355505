import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PageLayout from './Layout/PageLayout';

class ErrorBoundary extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired
  };

  static defaultProps = {};

  constructor() {
    super();
    this.state = {
      error: null
    };
  }

  componentDidCatch(error) {
    this.setState({ error });
  }

  render() {
    const { children } = this.props;
    const { error } = this.state;

    if (error) {
      
    }

    if (error) {
      return (
        <PageLayout
          header="We're sorry - something's gone wrong."
          content="Our team has been notified, but please refresh to try again"
        />
      );
    }

    // when there's not an error, render children untouched
    return children;
  }
}

export default ErrorBoundary;
