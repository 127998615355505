// import _ from 'underscore';
import React from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { reduxForm, Field } from 'redux-form'
import { connect } from 'react-redux'
import {
  TextInput,
  LoadingButton,
  CheckboxInput,
  StaticField,
} from 'lib/acromyrmex'
import { Form, Row, Col, Button } from '../../utility/UiComponents'
import { requiredValidator } from '../../utility/formValidators'
import UserThumbnail from './UserThumbnail'

// eslint-disable-next-line
class UserSettingsForm extends React.Component {
  render() {
    const { handleSubmit, onSubmit, isCreating, user, formValues } = this.props
    return (
      <div className="">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col xs={12} style={{ display: 'flex' }}>
              <Col xs={12} sm={4}>
                <strong>Avatar:</strong>
              </Col>
              <Col className="portrait user-portrait" xs={12} sm={4}>
                <Row className="user-portrait-wrapper">
                  <UserThumbnail user={user} radius="150px" />
                </Row>
                <div className="text-center">
                  <Button
                    variant="primary"
                    size="xsmall"
                    href="https://gravatar.com"
                    target="gravatar"
                  >
                    <i className="fa fa-external-link" />
                    {' Upload image on gravatar'}
                  </Button>
                </div>
              </Col>
            </Col>
            <Col xs={12}>
              <Field name="userRoles" component={StaticField} label="Roles:" />
            </Col>
            <Col xs={12}>
              <Field
                name="name"
                component={TextInput}
                label="Name:"
                type="name"
                validate={requiredValidator}
              />
            </Col>
            <Col xs={12}>
              <Field
                name="email"
                component={TextInput}
                label="Email:"
                type="email"
                validate={requiredValidator}
              />
            </Col>
            <Col xs={12}>
              <Field
                name="oldPassword"
                component={TextInput}
                label="Old Password:"
                type="password"
                validate={formValues.newPassword && requiredValidator}
              />
            </Col>
            <Col xs={12}>
              <Field
                name="newPassword"
                component={TextInput}
                label="New Password:"
                type="password"
                validate={formValues.oldPassword && requiredValidator}
              />
            </Col>
            <Col xs={8} className="offset-md-4">
              <Field
                name="emailUpdates"
                component={CheckboxInput}
                label="Receive Contuit updates via email?"
                help="Would you like to receive periodic email on Contuit news and updates?"
              />
            </Col>
            <Col xs={8} className="offset-md-4">
              <Field
                name="showSolutionDetails"
                component={CheckboxInput}
                label="Show the details of all processes within solutions?"
                help="Enable this if you want to see all processes and executions that are running in your Contuit environment. Disabling will hide them and only show you the relevant configuration and initiation processes."
              />
            </Col>
            <Col xs={8} className="offset-md-4">
              <Field
                name="showDeveloperDetails"
                component={CheckboxInput}
                label="Show details that would be useful for Solution Developers?"
                help="Enable this if you want to see more detailed information in certain instances. Could be confusing or distracting to a normal user."
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} style={{ marginTop: 10 }}>
              <LoadingButton
                variant="primary"
                label="Save Changes"
                loading={isCreating}
                loadingLabel="Saving changes"
                type="submit"
              />
            </Col>
          </Row>
        </Form>
      </div>
    )
  }
}

UserSettingsForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  isCreating: PropTypes.bool.isRequired,
  user: PropTypes.shape().isRequired,
  formValues: PropTypes.shape(),
}

UserSettingsForm.defaultProps = {
  onSubmit: () => {},
  formValues: {},
}

const mapStateToProps = (state) => ({
  isCreating: state.users.user.isCreating || state.users.user.isUpdating,
  formValues: state.form.userSettingsForm
    ? state.form.userSettingsForm.values
    : {},
})

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

const form = reduxForm({ form: 'userSettingsForm' })(UserSettingsForm)
export default connect(mapStateToProps, mapDispatchToProps)(form)
