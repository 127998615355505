import React from 'react';
import ShowDuoMfaForm from '../user/ShowDuoMfaForm';
import ShowDuoLayout from "./ShowDuoLayout";

class ShowDuoMultiFactorAuth extends React.Component {
  render() {
    return (
      <ShowDuoLayout
        explanation={(
          <div>
            <ol>
              <li>
                Log in to your Duo account (If you do not have one,
                {' '}
                <a
                  href="https://duo.com/msp-program-contuit"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  sign up here
                </a>
                {' '}
                to get your free MSP NFR licenses)
              </li>
              <li>
                Log in to the
                {' '}
                <a href="https://admin.duosecurity.com/" target="_blank" rel="noopener noreferrer">
                  Duo Admin Panel
                </a>
                {' '}
                and navigate to your
                {' '}
                <strong>internal use account</strong>
                . Within your internal use
                account, navigate to
                {' '}
                <strong>Applications</strong>
                .
              </li>
              <li>
                Click
                {' '}
                <strong>Protect an Application</strong>
                {' '}
                and locate
                {' '}
                <strong>Web SDK</strong>
                {' '}
                (not &quot;Partner Web SDK&quot;) in the applications list. Click
                {' '}
                <strong>Protect this Application</strong>
                {' '}
                to get your
                {' '}
                <strong>integration key, secret key, </strong>
                and
                <strong>API hostname.</strong>
                {' '}
                (See
                {' '}
                <a
                  href="https://duo.com/docs/getting-started"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Getting Started
                </a>
                {' '}
                for help)
              </li>
              <li>
                In the protected application&apos;s settings find
                {' '}
                <strong>Name</strong>
                {' '}
                and set it
                to
                {' '}
                <strong>Contuit MFA</strong>
                .
              </li>
              <li>
                In the protected application&apos;s settings find
                {' '}
                <strong>Username normalization</strong>
                {' '}
                and set it to
                <strong>Simple</strong>
              </li>
              <li>
                Save the application settings and enter the proper information in the fields to the
                right.
              </li>
            </ol>
          </div>
        )}
        content={<ShowDuoMfaForm />}
      />
    );
  }
}

// Define property types
ShowDuoMultiFactorAuth.propTypes = {};

ShowDuoMultiFactorAuth.defaultProps = {};

export default ShowDuoMultiFactorAuth;
