/* eslint-disable react/state-in-constructor */
/* eslint-disable react/static-property-placement */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button } from '../../../utility/UiComponents';

class AddButton extends Component {
  static propTypes = {
    label: PropTypes.string,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    displayAsLink: PropTypes.bool,
  };

  static defaultProps = {
    onClick: () => {},
    disabled: false,
    size: 'small',
    displayAsLink: false,
    label: '',
  };

  state = {};

  // <AddButton size="sm" onClick={} label="" />
  render() {
    const {
      disabled, onClick, label, size, displayAsLink, ...buttonProps
    } = this.props;
    const addedProps = {};
    if (size) {
      addedProps.size = size;
    }

    return (
      <Button
        className="pull-right"
        variant={displayAsLink ? 'link' : 'success'}
        disabled={disabled}
        onClick={onClick}
        {...addedProps}
        {...buttonProps}
      >
        <i className={classNames('fa', 'fa-plus', { 'text-success': displayAsLink })} />
        {' '}
        {label}
      </Button>
    );
  }
}

export default AddButton;
