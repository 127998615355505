import _ from 'underscore';
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { ProcessServer } from '@contuit-otf/sdk';
import { Field } from 'redux-form';
import { SelectInput, Loading, Icon } from 'lib/acromyrmex';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Accordion,
  Alert,
} from '../../../../../utility/UiComponents';
import FillModal from './FillModal';
import { findCollections } from '../../../../../utility/ProcessFormOptionHelpers';
import request from '../../../../../utility/request';

const processServer = new ProcessServer('/api/processes', request);

// eslint-disable-next-line
class LoopProcessStepOptions extends React.Component {
  static propTypes = {
    fieldText: PropTypes.string.isRequired,
    processStep: PropTypes.shape().isRequired,
    outputOptions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    outputOptionsLoaded: PropTypes.bool.isRequired,

    change: PropTypes.func.isRequired,

    // from redux
    currentUser: PropTypes.shape({
      token: PropTypes.string.isRequired,
    }).isRequired,
  };

  static defaultProps = {};

  constructor() {
    super();

    this.state = {
      processes: null,
      processesLoading: false,
      processesLoadError: '',
      selectedSourceId: null,
    };
  }

  componentDidMount() {
    const { processStep } = this.props;

    this.setState({ selectedSourceId: processStep?.stepOptions?.source });
    this.loadProcesses();
  }

  componentWillUpdate(nextProps) {
    const sourceOptions = this.getSourceOptions();
    const nextSourceOptions = this.getSourceOptions(nextProps.outputOptions);
    if (sourceOptions.length !== nextSourceOptions.length) {
      this.loadProcesses(
        nextProps.processStep.stepOptions.source,
        nextSourceOptions,
      );
    }
  }

  /**
   * Gets the slug based on a selected source step
   */
  getSourceSlug(value, overrideOutputOptions) {
    const { processStep } = this.props;
    const existingSource =
      processStep && processStep.stepOptions && processStep.stepOptions.source;
    const sourceOptions = this.getSourceOptions(overrideOutputOptions);
    const selectedOption = sourceOptions.find(
      (s) => s.textValue === (value || existingSource),
    );

    if (!selectedOption) {
      return null;
    }

    const query = {
      showDrafts: false,
      showActive: true,
      showArchived: false,
    };

    // check for the selected option type to be 'list'
    if (selectedOption.type === 'list') {
      query.fillString = true;
    }
    //

    if (selectedOption.slug) {
      query.fillVault = selectedOption.slug;
    }

    return query;
  }

  getSourceOptions(overrideOutputOptions) {
    const { outputOptions } = this.props;
    return findCollections(overrideOutputOptions || outputOptions, {
      loopProcessOnly: false,
    }).map((o) => ({
      ...o,
      name: `Loop over ${o.name}`,
    }));
  }

  loadProcesses(sourceStepId, overrideOutputOptions) {
    const {
      currentUser: { token },
    } = this.props;

    const query =
      sourceStepId && this.getSourceSlug(sourceStepId, overrideOutputOptions);

    // we want to fetch all active processes, so we skill the fillVault condition
    if (query) {
      delete query.fillVault;
    }

    this.setState({ processesLoading: true });
    processServer
      .findProcesses({
        token,
        query,
      })
      .then((data) => {
        // also comes with totalItemCount
        this.setState({ processes: data });
      })
      .catch(() => {
        this.setState({
          processesLoadError: 'Unknown error loading processes.',
        });
      })
      .then(() => {
        this.setState({ processesLoading: false });
      });
  }

  render() {
    const { fieldText, processStep, change, outputOptions } = this.props;
    const {
      processes,
      processesLoading,
      processesLoadError,
      selectedSourceId,
    } = this.state;

    const sourceOptions = this.getSourceOptions();
    const sourceSlug = this.getSourceSlug();
    const selectedProcess =
      processes &&
      processes.find((p) => p._id === processStep.stepOptions.processId);
    const selectedStep =
      selectedProcess &&
      selectedProcess.steps.find(
        (s) => s._id === processStep.stepOptions.fillStepId,
      );

    const fillOptions = selectedProcess
      ? selectedProcess.steps
          .filter((s) => {
            if (sourceSlug && sourceSlug.fillString) {
              return s.stepType === 'input';
            }

            if (s.stepOptions.slug) {
              return (
                s.stepOptions.slug === sourceSlug.fillVault ||
                s.stepType === 'input'
              );
            }

            // For newly VQ2
            if (s.stepOptions.vaultStructure) {
              return (
                s.stepOptions.vaultStructure === sourceSlug.fillVault ||
                s.stepType === 'input'
              );
            }

            return s.stepType === 'input';
          })
          .map((s) => ({ id: s._id, name: s.name }))
      : [];

    const loopOptions = findCollections(outputOptions)
      .map((o) => ({
        ...o, // add other attributes o has
        stepName: `Loop over ${o.stepName}`,
      }))
      .filter((p) => p.textValue === selectedSourceId);

    return (
      <Row style={{ marginBottom: 10 }}>
        <Accordion defaultActiveKey="1">
          <Accordion.Item eventKey="1">
            <Accordion.Header>{`${processStep.name || ''} Step Options`}</Accordion.Header>
            <Accordion.Body>
              <Col xs={12} style={{ marginBottom: 10 }}>
                <Field
                  name={`${fieldText}.stepOptions.source`}
                  component={SelectInput}
                  label="Which data to use as a source"
                  templateOptions={sourceOptions}
                  onChange={(e, value) => {
                    this.setState({ selectedSourceId: value });
                    this.loadProcesses(value);
                  }}
                />
              </Col>
              <Col xs={12}>
                {processesLoading && <Loading text="loading processes" />}
                {processesLoadError && (
                  <Alert variant="error" className="pull-left indented">
                    <Icon error />
                    {` Error loading processes: ${processesLoadError}`}
                  </Alert>
                )}
                {!processesLoading && !processesLoadError && (
                  <div className="select-addon-after">
                    <Field
                      name={`${fieldText}.stepOptions.processId`}
                      component={SelectInput}
                      label="Process"
                      options={_.map(processes, (proc) => ({
                        id: proc._id,
                        name: proc.name,
                      }))}
                      addonAfter={
                        selectedProcess && (
                          <Link
                            to={{
                              pathname: `/processes/${selectedProcess._id}/edit`,
                            }}
                            target="_blank"
                          >
                            <i className="fa fa-external-link" />
                          </Link>
                        )
                      }
                    />
                  </div>
                )}
              </Col>
              <Col xs={12}>
                {processesLoading && <Loading text="loading processes" />}
                {!processesLoading && selectedProcess && (
                  <Field
                    name={`${fieldText}.stepOptions.fillStepId`}
                    component={SelectInput}
                    label="Step to fill"
                    options={fillOptions}
                  />
                )}
                {!processesLoading &&
                  selectedStep &&
                  sourceSlug &&
                  sourceSlug.fillString && (
                    <Field
                      name={`${fieldText}.stepOptions.fillStepAttribute`}
                      component={SelectInput}
                      label="Step attribute to fill"
                      options={selectedStep.stepOptions.fields.map((field) => ({
                        id: field.slug,
                        name: field.label,
                      }))}
                    />
                  )}
              </Col>
              <Col xs={12}>
                <Row>
                  <Col xs={4}>
                    <strong>Static Fill</strong>
                  </Col>
                  <Col xs={8}>
                    <FillModal
                      fieldText={fieldText}
                      change={change}
                      processStep={processStep}
                      outputOptions={[
                        ...outputOptions,
                        ...loopOptions.map((l) => l.properties).flat(),
                      ]}
                      processes={processes}
                    />
                  </Col>
                </Row>
              </Col>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Row>
    );
  }
}

const mapStateToProps = (state) => ({
  currentUser: state.users.currentUser,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LoopProcessStepOptions);
