import _ from 'underscore'
import {
  VAULT_CREATE_STEP_TYPE,
  AUTO_VAULT_CREATE_STEP_TYPE,
  VAULT_DELETE_STEP_TYPE,
  MANUAL_SELECT_STEP_TYPE,
  VAULT_SAVE_STEP_TYPE,
  VAULT_MERGE_STEP_TYPE,
  COMPUTATION_STEP_TYPE,
  WEBEX_ACTION_LISTENER_STEP_TYPE,
  VAULT_REPLACE_STEP_TYPE,
  VAULT_QUERY_STEP_TYPE,
  BLOCK_BEGIN_STEP_TYPE,
  BLOCK_END_STEP_TYPE,
  BASIC_STEP_TYPE,
  INTEGRATION_STEP_TYPE,
  TRIGGER_STEP_TYPE,
  EXECUTE_PROCESS_STEP_TYPE,
  LOOP_PROCESS_STEP_TYPE,
  VAULT_INPUT_STEP_TYPE,
  INPUT_STEP_TYPE,
  NARRATIVE_STEP_TYPE,
  SPECIAL_KASEYA_STEP_TYPE,
  UPDATE_SOLUTION_SETTINGS_STEP_TYPE,
  DELAY_STEP_TYPE,
  BLOCK_BEGIN_V2_STEP_TYPE,
  BLOCK_END_V2_STEP_TYPE,
  STRING_FUNCTION_STEP_TYPE,
  DATE_FUNCTION_STEP_TYPE,
  COMPUTATION_V2_STEP_TYPE,
  JSON_PARSE_STEP_TYPE,
  CREATE_PDF_STEP_TYPE,
  VAULT_MERGE_V2_STEP_TYPE,
  VAULT_QUERY_V2_STEP_TYPE,
} from '../constants'

const steps = [
  {
    id: 'vault-create',
    name: 'Vault Create',
    isAutomatedStep: false,
    isLiveVault: true,
    regex: VAULT_CREATE_STEP_TYPE,
  },
  {
    id: 'auto-vault-create',
    name: 'Auto Vault Create',
    isAutomatedStep: true,
    isLiveVault: true,
    regex: AUTO_VAULT_CREATE_STEP_TYPE,
  },
  {
    id: 'vault-delete',
    name: 'Vault Delete',
    isAutomatedStep: true,
    isLiveVault: true,
    regex: VAULT_DELETE_STEP_TYPE,
  },
  {
    id: 'basic',
    name: 'Basic',
    isAutomatedStep: false,
    regex: BASIC_STEP_TYPE,
  },
  {
    id: 'delay',
    name: 'Delay',
    isAutomatedStep: true,
    regex: DELAY_STEP_TYPE,
  },
  {
    id: 'block-begin',
    name: 'Begin Block',
    isAutomatedStep: true,
    regex: BLOCK_BEGIN_STEP_TYPE,
  },
  {
    id: 'block-end',
    name: 'End Block',
    isAutomatedStep: true,
    regex: BLOCK_END_STEP_TYPE,
  },
  {
    id: 'block-begin-v2',
    name: 'Begin Block V2',
    isAutomatedStep: true,
    regex: BLOCK_BEGIN_V2_STEP_TYPE,
    isQueueable: true,
  },
  {
    id: 'block-end-v2',
    name: 'End Block V2',
    isAutomatedStep: true,
    regex: BLOCK_END_V2_STEP_TYPE,
    isQueueable: true,
  },
  {
    id: 'manual-select',
    name: 'Manual Select',
    isAutomatedStep: false,
    regex: MANUAL_SELECT_STEP_TYPE,
  },
  {
    id: 'narrative',
    name: 'Narrative',
    isAutomatedStep: true,
    regex: NARRATIVE_STEP_TYPE,
  },
  {
    id: 'input',
    name: 'Input',
    isAutomatedStep: false,
    regex: INPUT_STEP_TYPE,
  },
  {
    id: 'vault-input',
    name: 'Vault Input',
    isAutomatedStep: false,
    isLiveVault: true,
    regex: VAULT_INPUT_STEP_TYPE,
  },
  {
    id: 'trigger',
    name: 'Integration Trigger',
    isAutomatedStep: true,
    regex: TRIGGER_STEP_TYPE,
  },
  {
    id: 'webex-action-listener',
    name: 'Webex Action Listener',
    isAutomatedStep: true,
    regex: WEBEX_ACTION_LISTENER_STEP_TYPE,
  },
  {
    id: 'vault-save',
    name: 'Vault Save',
    regex: VAULT_SAVE_STEP_TYPE,
    isAutomatedStep: true,
  },
  // Special Kaseya step type
  {
    regex: SPECIAL_KASEYA_STEP_TYPE,
    isAutomatedStep: true,
  },
  // String function
  {
    id: 'string-function',
    name: 'String Function',
    regex: STRING_FUNCTION_STEP_TYPE,
    isAutomatedStep: true,
    isQueueable: true,
  },
  // Date function
  {
    id: 'date-function',
    name: 'Date Function',
    regex: DATE_FUNCTION_STEP_TYPE,
    isAutomatedStep: true,
    isQueueable: true,
  },
  // computation
  {
    id: 'computation',
    name: 'Computation',
    regex: COMPUTATION_STEP_TYPE,
    isAutomatedStep: true,
  },
  {
    id: 'computation-v2',
    name: 'Computation V2',
    regex: COMPUTATION_V2_STEP_TYPE,
    isAutomatedStep: true,
    isQueueable: true,
  },
  {
    id: 'json-parse',
    name: 'JSON Parse',
    regex: JSON_PARSE_STEP_TYPE,
    isAutomatedStep: true,
  },
  {
    id: 'create-pdf',
    name: 'Create PDF',
    regex: CREATE_PDF_STEP_TYPE,
    isAutomatedStep: true,
    isQueueable: true,
  },
  {
    regex: VAULT_REPLACE_STEP_TYPE,
    isAutomatedStep: true,
  },
  {
    regex: VAULT_MERGE_STEP_TYPE,
    isAutomatedStep: true,
  },
  {
    id: 'vault-merge-v2',
    name: 'Vault Merge V2',
    regex: VAULT_MERGE_V2_STEP_TYPE,
    isAutomatedStep: true,
    isQueueable: true,
  },
  {
    regex: VAULT_QUERY_STEP_TYPE,
    isAutomatedStep: true,
  },
  {
    id: 'vault-query-v2',
    name: 'Vault Query V2',
    regex: VAULT_QUERY_V2_STEP_TYPE,
    isAutomatedStep: true,
    isQueueable: true,
  },
  {
    regex: INTEGRATION_STEP_TYPE,
    isAutomatedStep: true,
  },
  {
    regex: EXECUTE_PROCESS_STEP_TYPE,
    isAutomatedStep: true,
  },
  {
    regex: LOOP_PROCESS_STEP_TYPE,
    isAutomatedStep: true,
  },
  {
    id: 'update-solution-settings',
    name: 'Update Solution Settings',
    regex: UPDATE_SOLUTION_SETTINGS_STEP_TYPE,
    isAutomatedStep: true,
  },
]

export default class {
  static isAutomatedStep(stepType) {
    let isAutomatedStep = false

    steps.forEach((step) => {
      if (new RegExp(step.regex).test(stepType)) {
        ;({ isAutomatedStep } = step)
      }
    })

    return isAutomatedStep
  }

  static isQueueable(stepType) {
    let isQueueable = false

    steps.forEach((step) => {
      if (new RegExp(step.regex).test(stepType)) {
        ;({ isQueueable } = step)
      }
    })

    return isQueueable
  }

  static isLiveVault(step) {
    let liveVault = false
    steps.forEach((stepType) => {
      if (
        stepType.isLiveVault &&
        new RegExp(stepType.regex).test(step.stepType) &&
        _.isBoolean(stepType.isLiveVault)
      ) {
        liveVault = stepType.isLiveVault
      }
    })

    return liveVault
  }
}
