import React, { Component } from 'react'
import { Field } from 'redux-form'
import { SelectInput } from 'lib/acromyrmex'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { loadVaultStructureData as loadVaultStructureDataAction } from '../../../../../../actions/vault'
import { requiredValidator } from '../../../../../../utility/formValidators'
import { getIntegrationFromStepType } from '../../../../../../utility/integrationHelpers'

class IntegrationSelect extends Component {
  componentWillMount() {
    const { loadVaultStructureData } = this.props

    loadVaultStructureData('customers', {}, false, { limit: -1 })
  }

  render() {
    const {
      name,
      display,
      integrations,
      stepType,
      integrationTypes,
      outputOptions,
      outputOptionsLoaded,
      vertical,
    } = this.props

    const matchingEnabled = integrations.filter((i) => {
      if (stepType) {
        return (
          i.integrationName === getIntegrationFromStepType(stepType)
        )
      }

      return integrationTypes.includes(i.integrationName)
    })

    return (
      <div>
        {matchingEnabled.length > 0 && outputOptionsLoaded && (
          <Field
            name={name}
            component={SelectInput}
            vertical={vertical}
            label={display || 'Select Credentials'}
            validate={requiredValidator}
            options={matchingEnabled.map((m) => ({
              id: m._id,
              name: m.name,
            }))}
            templateOptions={outputOptions.filter((o) => o.vault)}
          />
        )}
      </div>
    )
  }
}

IntegrationSelect.propTypes = {
  loadVaultStructureData: PropTypes.func.isRequired,
  integrations: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  outputOptions: PropTypes.arrayOf(PropTypes.shape()),
  vertical: PropTypes.bool,
  stepType: PropTypes.string,
  integrationTypes: PropTypes.arrayOf(PropTypes.string),
  name: PropTypes.string.isRequired,
  display: PropTypes.string,
  outputOptionsLoaded: PropTypes.bool.isRequired,
}

IntegrationSelect.defaultProps = {
  display: null,
  outputOptions: [],
  vertical: false,
  stepType: null,
  integrationTypes: null,
}

const mapStateToProps = (state) => ({
  integrations: state.enabledIntegrations.enabledIntegrations.rows,
  vault: state.vault,
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      loadVaultStructureData: loadVaultStructureDataAction,
    },
    dispatch,
  )

export default connect(mapStateToProps, mapDispatchToProps)(IntegrationSelect)
