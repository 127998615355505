// import _ from 'underscore';
import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Field, FieldArray } from 'redux-form'
import { SelectInput, MarkdownInput, Loading } from 'lib/acromyrmex'
import PropTypes from 'prop-types'
import {
  Row,
  Col,
  Button,Accordion,
} from '../../../../../../utility/UiComponents'
import AddButton from '../../../../../shared/form/AddButton'
import {
  findCollections,
  filterCollections,
} from '../../../../../../utility/ProcessFormOptionHelpers'

// eslint-disable-next-line react/prefer-stateless-function
class NarrativeStepOptions extends React.Component {
  constructor(props) {
    super(props)

    this.renderBlocks = this.renderBlocks.bind(this)
  }

  renderBlock(block, fields, index) {
    const { outputOptions, outputOptionsLoaded } = this.props

    if (!outputOptionsLoaded) {
      return <Loading key={block} />
    }

    const field = fields.get(index)
    // 
    const loopOptions = findCollections(outputOptions).map((o) => ({
      ...o, // add other attributes o has
      stepName: `Loop over ${o.stepName}`,
    }))

    // now, to handle nested arrays
    outputOptions.forEach((opt) => {
      // 
      if (opt.type === 'collection') {
        opt.properties.forEach((prop) => {
          if (prop.type !== 'array') {
            return
          }

          // add the prop to the loop option list
          loopOptions.push({
            ...prop,
            stepName: opt.stepName,
            name: `Loop over ${opt.name}.${prop.name}`,
          })
        })
      }
    })

    const selectedOption = loopOptions.find(
      (l) => l.textValue === field.blockType,
    )

    return (
      <Row key={block}>
        <Col xs={10}>
          <Row>
            <Field
              name={`${block}.blockType`}
              label={`Block ${index + 1}`}
              component={SelectInput}
              options={[{ id: 'static', name: 'Static' }]}
              templateOptions={loopOptions}
            />
          </Row>
        </Col>
        <Col xs={2}>
          <Button
            className="pull-right"
            variant="danger"
            onClick={() => fields.remove(index)}
            title="Remove this block"
          >
            <i className="fa fa-trash" />
          </Button>
        </Col>
        <Col xs={10}>
          <Row>
            <Field
              name={`${block}.value`}
              label="Value"
              component={MarkdownInput}
              plainText
              options={
                selectedOption && selectedOption.properties
                  ? selectedOption.properties.filter(
                      (p) => p.type !== 'array' && p.type !== 'object',
                    )
                  : filterCollections(outputOptions)
              }
              noLabel
            />
          </Row>
        </Col>
      </Row>
    )
  }

  renderBlocks({ fields }) {
    // const rows = fields.map((member, index) => this.renderBlock(member, fields, index));
    const rows = fields.map((member, index) =>
      this.renderBlock(member, fields, index),
    )

    return [
      ...rows,
      <Row>
        <Col xs={12}>
          <AddButton size="sm"
            onClick={() => {
              fields.push({ blockType: 'static' })
            }}
            label="Add Block"
          />
        </Col>
      </Row>,
    ]
  }

  render() {
    const { fieldText, onChangeAttributeNeeded, outputOptions } = this.props

    return (
      <Row key={fieldText} style={{ marginBottom: 10 }}>
        <Accordion defaultActiveKey="1">
          <Accordion.Item eventKey="1">
            <Accordion.Header>Narrative Step Options</Accordion.Header>
            <Accordion.Body>
              <FieldArray
                name={`${fieldText}.stepOptions.blocks`}
                component={this.renderBlocks}
                onChangeAttributeNeeded={() => onChangeAttributeNeeded(null)}
                props={{ outputOptions }}
              />
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Row>
    )
  }
}

// Define property types
NarrativeStepOptions.propTypes = {
  fieldText: PropTypes.string.isRequired,
  outputOptions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  // executionStep: PropTypes.shape().isRequired,
  // formValues: PropTypes.shape(),
  onChangeAttributeNeeded: PropTypes.func.isRequired,
  outputOptionsLoaded: PropTypes.bool.isRequired,
}

NarrativeStepOptions.defaultProps = {
  // formValues: {},
}

const mapStateToProps = (state) => ({
  structureList: state.vault.structureList.rows,
  formValues: state.form.processForm ? state.form.processForm.values : {},
})

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NarrativeStepOptions)
