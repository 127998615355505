import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Loading } from 'lib/acromyrmex';
import { getFormValues } from 'redux-form';
import VaultStructureForm from './VaultStructureForm';
import {
  loadVaultStructure,
  updateStructure,
} from '../../actions/vault';
import PageLayout from '../shared/Layout/PageLayout';

function EditVaultStructure() {
  const dispatch = useDispatch();
  const { vaultStructureId: slug } = useParams();
  const struct = useSelector((state) => state.vault.structures[slug]);
  const isLoading = useSelector(
    (state) => (state.vault.structures[slug] ? state.vault.structures[slug].isLoading : false),
  );
  const formValues = useSelector((state) => getFormValues('vaultStructureForm')(state));

  useEffect(() => {
    dispatch(loadVaultStructure(slug));
  }, []);

  const onFormSubmit = () => {
    dispatch(updateStructure(slug, formValues));
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <PageLayout
      header={`Edit ${struct ? struct.display : '...'} Structure`}
      content={(
        <VaultStructureForm
          slug={slug}
          initialValues={struct}
          onSubmit={onFormSubmit}
          edit
        />
        )}
    />
  );
}

export default EditVaultStructure;
