import { React, PropTypes } from '../../../utility/ReactStuff'
import { Col } from '../../../utility/UiComponents'
import ProcessButtons from '../ConnectedProcessButtons'

const getInitiationType = (contuitProcess) => {
  const {
    initiation: { initiationType },
  } = contuitProcess

  if (initiationType === 'basic') {
    return 'Manual Initiation'
  }

  return initiationType
}

function ShowProcessHeader(props) {
  const { contuitProcess, onProcessScheduled } = props
  return [
    <Col
      className="d-flex justify-content-between align-items-center flex-wrap"
      key="header-name"
    >
      <h3 style={{ margin: '0' }}>
        {contuitProcess.name}
        <span className="text-muted small">{` (${getInitiationType(contuitProcess)})`}</span>
      </h3>
      <ProcessButtons
        contuitProcess={contuitProcess}
        onProcessScheduled={onProcessScheduled}
      />
    </Col>,
  ]
}

ShowProcessHeader.propTypes = {
  contuitProcess: PropTypes.shape().isRequired,
  onProcessScheduled: PropTypes.func.isRequired,
}

export default ShowProcessHeader
