/* eslint-disable react/no-find-dom-node */
import _ from 'underscore'
import get from 'lodash/get'
import React from 'react'
import PropTypes from 'prop-types'
import CommentSection from './CommentSection'
import { isComplete } from '../../../shared-helpers/execution'
import {
  ShowCurrentStepEvent,
  TimelineSpacer,
  ShowPastStepEvent,
  ShowProcessCompletedEvent,
  ShowProcessInitiationEvent,
  ShowLogEvent,
} from './ExecutionTimelineViews'
import ShowCommentEvent from './ShowCommentEvent'

const ExecutionHistoryView = ({
  execution,
  parentExecution,
  vault,
  currentUser,
  integrationOptions,
}) => {
  const { steps } = execution

  const events = [
    ...steps
      .filter((s) => s.complete && !s.skippedByBlock)
      .map((s) => ({
        timestamp: new Date(s.timeCompleted).getTime(),
        eventView: (
          <ShowPastStepEvent
            key={s._id}
            execution={execution}
            executionStep={s}
            vault={vault}
            currentUser={currentUser}
            integrationOptions={integrationOptions}
          />
        ),
      })),
    ...get(execution, 'comments', []).map((c) => ({
      timestamp: new Date(c.timestamp).getTime(),
      eventView: (
        <ShowCommentEvent
          key={c._id}
          execution={execution}
          comment={c}
          currentUser={currentUser}
        />
      ),
    })),
    ...get(execution, 'logEntries', []).map((l) => ({
      timestamp: new Date(l.createdAt).getTime(),
      eventView: (
        <ShowLogEvent
          key={l._id}
          execution={execution}
          logItem={l}
          currentUser={currentUser}
        />
      ),
    })),
  ]

  // Sort events in reverse order
  events.sort((a, b) => b.timestamp - a.timestamp)

  return (
    <div className="timeline">
      {!isComplete(execution) && [
        <ShowCurrentStepEvent key="current-step" execution={execution} />,
        <TimelineSpacer key="spacer-1" />,
        <CommentSection
          key="comment"
          execution={execution}
          currentUser={currentUser}
        />,
        <TimelineSpacer key="spacer-2" />,
      ]}
      {isComplete(execution) && (
        <ShowProcessCompletedEvent
          key="a"
          execution={execution}
          parentExecution={parentExecution}
        />
      )}
      {events.map((e) => e.eventView)}
      <ShowProcessInitiationEvent execution={execution} key="b" />
    </div>
  )
}

ExecutionHistoryView.propTypes = {
  parentExecution: PropTypes.shape(),
  execution: PropTypes.shape().isRequired,
  currentUser: PropTypes.shape().isRequired,
  vault: PropTypes.shape().isRequired,
  integrationOptions: PropTypes.shape().isRequired,
}

ExecutionHistoryView.defaultProps = {
  parentExecution: null,
}

export default ExecutionHistoryView
