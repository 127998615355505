import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { MD5 } from 'crypto-js'
import { Image, OverlayTrigger, Popover } from '../../utility/UiComponents'
import StringHelper from '../../shared-helpers/StringHelper'
import ConditionalWrapper from '../shared/ConditionalWrapper'

const OverlayWrapper = ({ children, user, radius }) => (
  <OverlayTrigger
    trigger="click"
    placement="bottom"
    overlay={
      <Popover id="user-thumbnail" className="user-thumbnail-popover">
        <Popover.Body>
          <UserThumbnail user={user} radius={radius} popover={false} />
          <div className="popover-info">
            <p>{user.name}</p>
            <p>{user.email}</p>
            {user.userRoles && (
              <p>
                <span className="info-label">Roles: </span>
                {user.userRoles.length === 0
                  ? 'No roles'
                  : user.userRoles.join(', ')}
              </p>
            )}
            {user.groups && (
              <p>
                <span className="info-label">Groups: </span>
                {user.groups.length === 0
                  ? 'No groups'
                  : user.groups.join(', ')}
              </p>
            )}
          </div>
        </Popover.Body>
      </Popover>
    }
    rootClose
  >
    {children}
  </OverlayTrigger>
)

OverlayWrapper.propTypes = {
  children: PropTypes.node.isRequired,
}

const UserThumbnail = ({ user, radius, small, popover }) => {
  const [avatarErrored, setAvatarErrored] = useState(false)
  const [avatarLoaded, setAvatarLoaded] = useState(false)
  if (!user) {
    return null
  }

  const initials = StringHelper.getInitials(user)
  const emailHash = MD5(user.email)
  const imageUrl = `https://www.gravatar.com/avatar/${emailHash}?d=404&r=g`

  return (
    <ConditionalWrapper
      condition={popover}
      wrapper={(children) => (
        <OverlayWrapper user={user} radius={radius}>
          {children}
        </OverlayWrapper>
      )}
    >
      <div
        className={classNames('user-thumbnail-wrapper', {
          'user-thumbnail-small': small,
          clickable: popover,
        })}
      >
        <div
          className="backup-user-thumbnail"
          style={{
            width: radius,
            height: radius,
            lineHeight: radius,
            display: avatarErrored ? 'inline-block' : 'none',
          }}
        >
          {initials}
        </div>
        <Image
          src={imageUrl}
          alt="portrait"
          circle
          className="user-thumbnail"
          onError={() => setAvatarErrored(true)}
          onLoad={() => setAvatarLoaded(true)}
          style={{
            width: radius,
            display: avatarLoaded ? 'inline-block' : 'none',
          }}
        />
      </div>
    </ConditionalWrapper>
  )
}

UserThumbnail.propTypes = {
  user: PropTypes.shape(),
  radius: PropTypes.string,
  small: PropTypes.bool,
  popover: PropTypes.bool,
}

UserThumbnail.defaultProps = {
  radius: '35px',
  small: false,
  popover: false,
  user: null,
}

export default UserThumbnail
