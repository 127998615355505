import React from 'react';
import { useParams } from 'react-router-dom';
import ShowSolutionGroup from '../../modules/solutions/ShowSolutionGroup';

function ShowSolutionGroupsPage() {
  const params = useParams();

  return <ShowSolutionGroup params={params} />;
}

export default ShowSolutionGroupsPage;
