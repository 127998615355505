import { VAULT_SAVE_STEP_TYPE } from '../../../constants'
import BasicUiHelper from './BasicUiHelper'
import VaultSaveStepOptions from './components/stepOptions/vaultSave/vaultSave'

class VaultSaveUiHelper extends BasicUiHelper {
  regex() {
    return VAULT_SAVE_STEP_TYPE
  }

  name() {
    return 'Vault Save'
  }

  canTryAgain() {
    return true
  }

  stepOptions() {
    return VaultSaveStepOptions
  }
}

export default VaultSaveUiHelper
