import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { LinkContainer } from 'react-router-bootstrap';
import { ProcessServer } from '@contuit-otf/sdk';
import Loading from 'lib/acromyrmex/Loading';
import request from '../../utility/request';
import PageLayout from '../shared/Layout/PageLayout';
import { Well, Button } from '../../utility/UiComponents';
import Alert from '../shared/Alert';
import EmptyView from '../shared/EmptyView';

const processServer = new ProcessServer('/api/processes', request);

class ShowSolutionGroups extends Component {
  static propTypes = {
    currentUser: PropTypes.shape().isRequired,
  };

  static defaultProps = {};

  state = {
    solutionGroups: null,
    solutionGroupsLoading: false,
    solutionGroupsError: '',
  };

  componentDidMount() {
    const {
      currentUser: { token },
    } = this.props;

    this.setState({ solutionGroupsLoading: true, solutionGroupsError: '' });
    processServer
      .getSolutionGroups({ token })
      .then((solutionGroups) => {
        this.setState({ solutionGroups });
      })
      .catch((e) => {
        this.setState({ solutionGroupsError: `Unknown error: ${e.message}` });
      })
      .then(() => this.setState({ solutionGroupsLoading: false }));
  }

  render() {
    const { solutionGroups, solutionGroupsLoading, solutionGroupsError } =
      this.state;

    return (
      <PageLayout
        header="Solution Groups"
        content={
          <div>
            {solutionGroupsError && (
              <Alert danger message={solutionGroupsError} />
            )}
            {solutionGroupsLoading && (
              <Loading message="Loading solution groups" />
            )}
            {solutionGroups && solutionGroups.length < 1 && (
              <EmptyView
                header="No solutions found."
                content="It looks like you haven't imported any solutions yet!"
                button={{
                  pathname: '/marketplace',
                  content: 'See All Available Solutions',
                  style: 'info',
                }}
              />
            )}
            {solutionGroups &&
              solutionGroups.map((solutionGroup) => (
                <Well
                  style={{ maxWidth: '500px', float: 'left' }}
                  key={solutionGroup._id}
                >
                  <div className="clearfix" style={{ display: 'flex' }}>
                    <div style={{ float: 'left', flex: 0 }}>
                      <img
                        src={solutionGroup.image}
                        alt={solutionGroup.name}
                        style={{ width: '100px' }}
                      />
                    </div>
                    <div style={{ flex: 1, paddingLeft: '10px' }}>
                      <h4 style={{ marginTop: 0 }}>{solutionGroup.name}</h4>
                      <ul
                        style={{ listStyleType: 'none', paddingLeft: '10px' }}
                      >
                        <li style={{ fontSize: '0.9em' }}>
                          {solutionGroup.marketingDescription}
                        </li>
                        <li style={{ paddingTop: '5px' }}>
                          <LinkContainer
                            to={{ pathname: `/solutions/${solutionGroup._id}` }}
                          >
                            <Button variant="primary" size="xs">
                              More Details
                            </Button>
                          </LinkContainer>
                        </li>
                      </ul>
                    </div>
                  </div>
                </Well>
              ))}
          </div>
        }
      />
    );
  }
}

const mapStateToProps = (state) => ({
  currentUser: state.users.currentUser,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ShowSolutionGroups);
