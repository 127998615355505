/**
 * This is used for forms where collections are involved
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FieldArray } from 'redux-form';
import { Col, Row } from '../../../utility/UiComponents';

class TableForm extends Component {
  render() {
    const { showHeader, headers, fieldArrayComponent, fieldArrayProps, name } = this.props;

    const headerComponent = (
      <Row style={{ marginBottom: 10 }}>
        {headers.map(header => (
          <Col xs={header.xs} key={header.title}>
            <strong>{header.title}</strong>
          </Col>
        ))}
      </Row>
    );

    return (
      <div>
        {showHeader && headerComponent}
        <FieldArray name={name} component={fieldArrayComponent} props={fieldArrayProps} />
      </div>
    );
  }
}

TableForm.propTypes = {
  name: PropTypes.string.isRequired,
  showHeader: PropTypes.bool,
  headers: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  fieldArrayComponent: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  fieldArrayProps: PropTypes.shape()
};

TableForm.defaultProps = {
  showHeader: true,
  fieldArrayProps: {}
};

export default TableForm;
