import map from 'lodash/map'
import { SelectInput } from 'lib/acromyrmex'
import { VAULT_INPUT_STEP_TYPE } from '../../../constants'
import VaultInputStep from './components/stepExecutions/VaultInputStep'
import BasicUiHelper from './BasicUiHelper'

class VaultInputUiHelper extends BasicUiHelper {
  regex() {
    return VAULT_INPUT_STEP_TYPE
  }

  name() {
    return 'Vault Input'
  }

  canTryAgain() {
    return true
  }

  liveVault() {
    return true
  }

  stepOptions() {
    return {
      title: 'Vault Input Step Options',
      fields: ({ structureList }) => [
        {
          name: 'slug',
          component: SelectInput,
          label: 'Structure',
          options: map(structureList, (struct) => ({
            id: struct.slug,
            name: struct.display,
          })),
        },
      ],
    }
  }

  stepExecution() {
    return VaultInputStep
  }

  static getInputOptions(step, { structureList }) {
    const { slug } = step.stepOptions

    const struct = structureList.find((s) => s.slug === slug)

    if (!struct) return

    return [
      {
        id: JSON.stringify({ step: step._id, field: 'id' }),
        name: `${struct.display}`,
        textValue: `{${step._id}.id}`,
        type: `vault-${struct.slug}`,
      },
    ]
  }

  getOutputKeys() {
    return {
      schema: {
        id: { type: 'LiveVault', vaultStructureStepOption: 'slug' },
      },
    }
  }

  getInputOptions(step, { structureList }) {
    return VaultInputUiHelper.getInputOptions(step, { structureList })
  }

  /**
   * Gets which values can be used by the process in futures steps for a given step
   * @param {object} step The step these options refer to
   * @param {array} options.structureList All structures for the current tenant
   */
  static getOutputOptions(step, { structureList }) {
    const { slug } = step.stepOptions

    const struct = structureList.find((s) => s.slug === slug)

    if (!struct) return []

    // define this since we use it twice
    const mapFunc = (obj) => ({
      id: JSON.stringify({ step: step._id, field: obj.key }),
      name: `${obj.display}`,
      textValue: `{${step._id}.${obj.key}}`,
      type: obj.type,
    })

    return [
      ...struct.attributes.map(mapFunc),
      {
        id: JSON.stringify({ step: step._id, field: '_id' }),
        name: 'Vault ID',
        textValue: `{${step._id}._id}`,
        type: 'string',
        vault: true,
        slug: struct.slug,
      },
    ]
  }

  getOutputOptions(step, { structureList }) {
    return VaultInputUiHelper.getOutputOptions(step, { structureList })
  }
}

export default VaultInputUiHelper
