import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field } from 'redux-form';
import { bindActionCreators } from 'redux';
import { Loading, LoadingButton, TextInput } from 'lib/acromyrmex';
import { VaultServer } from '@contuit-otf/sdk';
import { Modal } from '../../../../../utility/UiComponents';
import VaultEntryForm from '../../../../vaultStructures/VaultEntryForm';
import {
  loadVaultStructure as loadVaultStructureAction,
  createEntry as createEntryAction,
} from '../../../../../actions/vault';
import { requiredValidator } from '../../../../../utility/formValidators';
import request from '../../../../../utility/request';

class VaultCreateStep extends React.Component {
  state = {
    showModal: false,
    entryCreating: false,
    entryCreateError: null,
    // createdEntry: null,
  };

  openModal = this.openModal.bind(this);

  closeModal = this.closeModal.bind(this);

  onFormSubmit = this.onFormSubmit.bind(this);

  componentWillMount() {
    const { step, loadVaultStructure } = this.props;
    const { slug } = step.stepOptions;

    loadVaultStructure(slug, true);
  }

  onFormSubmit(values) {
    this.createEntry(values);

    this.closeModal();
  }

  createEntry(values) {
    const {
      currentUser: { token, contuitId },
      struct: { slug },
      completeStep,
    } = this.props;

    this.setState({ entryCreating: true });
    VaultServer.createEntry(
      '/api/vault',
      {
        token,
        slug,
        entry: values,
        contuitId,
      },
      request,
    )
      .then((data) => {
        // this.setState({ createdEntry: data });
        completeStep({ id: data[0]._id });
      })
      .catch(() => {
        this.setState({
          entryCreateError: 'Unknown error creating vault structure data.',
        });
      })
      .then(() => {
        this.setState({ entryCreating: false });
      });
  }

  openModal() {
    this.setState({ showModal: true });
  }

  closeModal() {
    this.setState({ showModal: false });
  }

  render() {
    const { struct } = this.props;
    const { showModal, entryCreating, entryCreateError } = this.state;

    return (
      <div>
        {!struct && <Loading />}
        {struct && (
          <div>
            <p>{`Create a(n) ${struct.display}.`}</p>
            <LoadingButton
              variant="success"
              style={{ marginRight: 5 }}
              loading={entryCreating}
              loadingLabel={`Creating ${struct.display}...`}
              onClick={this.openModal}
              label={`Create ${struct.display}`}
            />
            {!entryCreating && entryCreateError && (
              <p className="text-danger">{entryCreateError}</p>
            )}
            {/* this field gets filled by an action, but we have the hidden input b/c required */}
            <Field
              name="id"
              component={TextInput}
              label="Created"
              validate={requiredValidator}
              type="hidden"
              noLabel
            />
            <Modal show={showModal} onHide={this.closeModal} size="large">
              <Modal.Header closeButton>
                <Modal.Title>{`Create ${struct.singular}`}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <VaultEntryForm
                  slug={struct.slug}
                  onSubmit={this.onFormSubmit}
                />
              </Modal.Body>
            </Modal>
          </div>
        )}
      </div>
    );
  }
}

VaultCreateStep.propTypes = {
  struct: PropTypes.shape(),
  step: PropTypes.shape().isRequired,
  loadVaultStructure: PropTypes.func.isRequired,
  currentUser: PropTypes.shape().isRequired,
  completeStep: PropTypes.func.isRequired,
};

VaultCreateStep.defaultProps = {
  struct: null,
};

const mapStateToProps = (state, props) => ({
  structureList: state.vault.structureList.rows,
  struct: state.vault.structures[props.step.stepOptions.slug],
  formValues: state.form.executeStep ? state.form.executeStep.values : {},
  currentUser: state.users.currentUser,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      loadVaultStructure: loadVaultStructureAction,
      createEntry: createEntryAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(VaultCreateStep);
