import _ from 'underscore';
import React from 'react';
import PropTypes from 'prop-types';
import { Loading, FormField } from 'lib/acromyrmex';
import { FormControl } from '../../../utility/UiComponents';
import StepTypeModal from './StepTypeModal';

class SelectStepType extends React.Component {
  // Takes a list of options and makes what we need for display
  static getOptions(options = []) {
    const allOptions = options.map(item => ({
      ...item,
      value: item.id,
      label: item.name
    }));

    return allOptions;
  }

  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      allOptions: SelectStepType.getOptions(props.options)
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.onSubmitStepType = this.onSubmitStepType.bind(this);
    this.renderValue = this.renderValue.bind(this);
  }

  componentDidMount() {
    const { allOptions } = this.state;
    const myOptions = allOptions;

    if (!this.valueInOptions() && myOptions[0]) {
      this.handleChange(myOptions[0].value);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { allOptions } = this.state;
    // we have some new step types, recalculate
    if (allOptions.length !== nextProps.options.length) {
      const newAllOptions = SelectStepType.getOptions(nextProps.options);

      this.setState({ allOptions: newAllOptions });
    }
  }

  onSubmitStepType(val) {
    this.handleChange(val);
    this.closeModal();
  }

  getValue() {
    const { input } = this.props;
    return input.value;
  }

  valueInOptions() {
    const { allOptions: myOptions } = this.state;
    const value = this.getValue();

    let valFound = false;
    _.each(myOptions, option => {
      valFound = valFound || option.value === value || JSON.stringify(option.value) === value;
    });

    return valFound;
  }

  handleChange(val) {
    const { input } = this.props;
    input.onChange(val);
  }

  openModal() {
    this.setState({ showModal: true });
  }

  closeModal() {
    this.setState({ showModal: false });
  }

  renderValue() {
    const { allOptions } = this.state;
    const opt = allOptions.find(option => this.getValue() === option.id);
    return opt && opt.name ? `${opt.brand} - ${opt.name}` : '';
  }

  render() {
    const { showModal } = this.state;
    const {
      label,
      help,
      loading,
      meta,
      noLabel,
      disabled,
      vertical,
      options,
      input,
      integrationOptions
    } = this.props;
    const { allOptions: myOptions } = this.state;
    const selectInput = loading ? (
      <Loading />
    ) : (
      <FormControl
        readOnly
        type="input"
        name={input.name}
        onClick={this.openModal}
        value={this.renderValue()}
        className="select-step-type-field"
        disabled={myOptions.length === 0 || disabled}
      />
    );

    return (
      <FormField
        meta={meta}
        help={help}
        label={label}
        noLabel={noLabel}
        loading={loading}
        vertical={vertical}
      >
        {selectInput}
        <StepTypeModal
          options={options}
          showModal={showModal}
          onCloseModal={this.closeModal}
          onSubmitStepType={this.onSubmitStepType}
          integrationOptions={integrationOptions}
        />
      </FormField>
    );
  }
}

SelectStepType.propTypes = {
  meta: PropTypes.shape().isRequired,
  input: PropTypes.shape({
    name: PropTypes.string
  }).isRequired,
  help: PropTypes.string,
  label: PropTypes.string,
  loading: PropTypes.bool,
  noLabel: PropTypes.bool,
  disabled: PropTypes.bool,
  vertical: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.shape()),
  integrationOptions: PropTypes.shape()
};

SelectStepType.defaultProps = {
  help: '',
  label: '',
  noLabel: false,
  loading: false,
  disabled: false,
  vertical: false,
  options: [],
  integrationOptions: {}
};

export default SelectStepType;
