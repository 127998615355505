// import _ from 'underscore';
import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';
import { TextInput, LoadingButton } from 'lib/acromyrmex';
import { requiredValidator } from '../../utility/formValidators';
import { Form, Row, Col } from '../../utility/UiComponents';
import { loadStructureList as loadStructureListAction } from '../../actions/vault';

// eslint-disable-next-line
class CreateTenantCodeForm extends React.Component {
  render() {
    const { handleSubmit, onSubmit, isCreating } = this.props;
    return (
      <div className="content-row content-row-padded process-form box-rest">
        <div className="container">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col xs={12}>
                <p>
                  Check your email, we have sent you a confirmation code that looks like
                  {' '}
                  <strong>###-###</strong>
                  . Enter it below to continue
                </p>
              </Col>
              <Col xs={12}>
                <Field
                  name="code"
                  component={TextInput}
                  label="Code"
                  validate={requiredValidator}
                  type="text"
                />
              </Col>
            </Row>
            <hr />
            <Row>
              <Col xs={12} style={{ marginTop: 10 }}>
                <LoadingButton
                  variant="primary"
                  label="Verify"
                  loading={isCreating}
                  loadingLabel="Veifying Code..."
                  type="submit"
                />
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    );
  }
}

CreateTenantCodeForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  isCreating: PropTypes.bool.isRequired,
  // formValues: PropTypes.shape(),
};

CreateTenantCodeForm.defaultProps = {
  onSubmit: () => {},
};

const mapStateToProps = (state) => ({
  structureList: state.vault.structureList.rows,
  isCreating:
    state.enabledIntegrations.enabledIntegrations.isCreating
    || state.enabledIntegrations.enabledIntegrations.isUpdating,
  formValues: state.form.onboardingCodeForm ? state.form.onboardingCodeForm.values : {},
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    loadStructureList: loadStructureListAction,
  },
  dispatch,
);

const form = reduxForm({
  form: 'onboardingCodeForm',
})(CreateTenantCodeForm);
export default connect(mapStateToProps, mapDispatchToProps)(form);
