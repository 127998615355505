import _ from 'underscore'
import React from 'react'
import { bindActionCreators } from 'redux'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { Field, FieldArray } from 'redux-form'
import { SelectInput, Loading } from 'lib/acromyrmex'
import PropTypes from 'prop-types'
import StructureAttributeSelectInput from '../../../../shared/form/StructureAttributeSelectInput'
import {
  Row,
  Col,Accordion,
  Button,
} from '../../../../../utility/UiComponents'
import VaultQueryStepOptions from './VaultQueryStepOptions'
import AddButton from '../../../../shared/form/AddButton'

// eslint-disable-next-line
class AutoVaultCreateStepOptions extends React.Component {
  static propTypes = {
    fieldText: PropTypes.string.isRequired,
    processStep: PropTypes.shape().isRequired,
    onChangeAttributeNeeded: PropTypes.func.isRequired,
    outputOptionsLoaded: PropTypes.bool.isRequired,
    change: PropTypes.func.isRequired,
    outputOptions: PropTypes.arrayOf(PropTypes.shape()).isRequired,

    // specific to this step type
    structureList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  }

  static defaultProps = {}

  renderAttribute = this.renderAttribute.bind(this)

  renderAttributes = this.renderAttributes.bind(this)

  renderAttributes({ fields }) {
    const { structureList, processStep } = this.props
    const struct = structureList.find(
      (s) => s.slug === processStep.stepOptions.slug,
    )
    const rows = fields.map((member, index) =>
      this.renderAttribute(member, fields, index, struct),
    )

    return (
      <div>
        {rows}
        <Row className="step-option-buttons">
          <Col xs={12}>
            <AddButton size="sm" onClick={() => fields.push({})} label="Add Attribute" />
          </Col>
        </Row>
      </div>
    )
  }

  renderAttribute(member, fields, index, struct) {
    const { processStep, change, outputOptions } = this.props
    const field = fields.get(index)

    if (!struct) {
      return <Loading key={member} />
    }

    const selectedAttribute = struct.attributes.find(
      (a) => a.key === field.attribute,
    )

    return (
      <Row style={{ marginBottom: 10 }} key={member}>
        <Col xs={5}>
          <Field
            name={`${member}.attribute`}
            label="Attribute"
            component={StructureAttributeSelectInput}
            slug={processStep.stepOptions.slug}
            onChange={() => {
              // reset the query when we change types
              change(`${member}.query`, '')
            }}
            noLabel
          />
        </Col>
        <Col xs={6}>
          {!selectedAttribute && <Loading />}
          {selectedAttribute &&
            VaultQueryStepOptions.renderEditField(
              outputOptions,
              member,
              selectedAttribute,
              false,
              'value',
            )}
        </Col>
        <Col xs={1}>
          <Button
            variant="danger"
            onClick={() => fields.remove(index)}
            title="Remove this parameter"
          >
            <i className="fa fa-trash" />
          </Button>
        </Col>
      </Row>
    )
  }

  render() {
    const {
      fieldText,
      processStep,
      structureList,
      onChangeAttributeNeeded,
      outputOptionsLoaded,
      outputOptions,
    } = this.props

    const {
      stepOptions: { slug },
    } = processStep

    return (
      <Row key={fieldText} style={{ marginBottom: 10 }}>
        <Accordion defaultActiveKey="1">
          <Accordion.Item eventKey="1">
            <Accordion.Header>{`${processStep.name || ''} Step Options`}</Accordion.Header>
            <Accordion.Body>
              <Col xs={12}>
                <div className="select-addon-after">
                  <Field
                    name={`${fieldText}.stepOptions.slug`}
                    component={SelectInput}
                    label="Structure"
                    options={_.map(structureList, (struct) => ({
                      id: struct.slug,
                      name: struct.display,
                    }))}
                    onChange={(a, value) => {
                      onChangeAttributeNeeded({
                        ...processStep,
                        stepOptions: {
                          ...processStep.stepOptions,
                          slug: value,
                        },
                      })
                    }}
                    addonAfter={
                      slug && (
                        <Link
                          to={{
                            pathname: `/vault/${slug}`,
                          }}
                          target="_blank"
                        >
                          <i className="fa fa-external-link" />
                        </Link>
                      )
                    }
                  />
                </div>
              </Col>
              <Row style={{ marginBottom: 10 }}>
                <Col xs={5}>
                  <strong>Attribute</strong>
                </Col>
                <Col xs={6}>
                  <strong>Value</strong>
                </Col>
              </Row>
              {outputOptionsLoaded && (
                <FieldArray
                  name={`${fieldText}.stepOptions.attributes`}
                  component={this.renderAttributes}
                  structureList={structureList}
                  props={{ outputOptions }}
                />
              )}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Row>
    )
  }
}

const mapStateToProps = (state) => ({
  structureList: state.vault.structureList.rows,
})

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AutoVaultCreateStepOptions)
