import React from 'react';
import PropTypes from 'prop-types';
import { FormControl } from '../../../utility/UiComponents';

const BasicSearch = props => {
  const { onStateChange, placeholder, onKeyUp } = props;

  return (
    <div>
      <FormControl
        type="input"
        placeholder={placeholder}
        onChange={onStateChange}
        autoFocus
        onKeyUp={onKeyUp}
      />
    </div>
  );
};

BasicSearch.propTypes = {
  onStateChange: PropTypes.func,
  placeholder: PropTypes.string,
  onKeyUp: PropTypes.func
};

BasicSearch.defaultProps = {
  onStateChange: () => {},
  placeholder: 'Search...',
  onKeyUp: () => {}
};

export default BasicSearch;
