import { toast } from 'react-toastify';
import get from 'lodash/get';
import React, { useRef, useState } from 'react';
import {
  LoadingButton,
  TextAreaInput,
  SelectInput,
  Loading,
} from 'lib/acromyrmex';
import { UploadServer } from '@contuit-otf/sdk';
import { Modal, Form, Button } from '../../../utility/UiComponents';
import request from '../../../utility/request';
import { API_BASE_URL } from '../../../constants';
import {
  FileRemoveThumbnail,
  getS3Url,
} from '../../shared/FileUpload/FileThumbnail';

const uploadServer = new UploadServer(`${API_BASE_URL}/api/upload`);

const ReportBugModal = ({ showModal, onHide, currentUser, execution }) => {
  const [bugDescription, setBugDescription] = useState('');
  const [reporting, setReporting] = useState(false);
  const [step, setStep] = useState();
  const [uploadingFile, setUploadingFile] = useState(false);
  const [file, setFile] = useState();

  const fileInputRef = useRef();

  const handleReport = async () => {
    try {
      setReporting(true);

      const stepId = step;
      const stepName =
        stepId && execution.process.steps.find((s) => s._id === stepId)?.name;

      const payload = {
        text: `*Bug Reported by:* ${currentUser.name}, ${currentUser.contuitId}, ${execution._id}, *Description:* ${bugDescription}`,
        blocks: [
          {
            type: 'section',
            text: {
              type: 'mrkdwn',
              text: ':large_red_square: Bug Reported',
            },
          },
          {
            type: 'section',
            text: {
              type: 'mrkdwn',
              text: `*Reported by:* ${currentUser.name}`,
            },
          },
          {
            type: 'section',
            text: {
              type: 'mrkdwn',
              text: `*Tenant:* ${currentUser.contuitClient.name}`,
            },
          },
          {
            type: 'section',
            text: {
              type: 'mrkdwn',
              text: `*Contuit ID:* ${currentUser.contuitId}`,
            },
          },
          {
            type: 'section',
            text: {
              type: 'mrkdwn',
              text: `*Process:* ${execution.process.name}`,
            },
          },
          {
            type: 'section',
            text: {
              type: 'mrkdwn',
              text: `*Execution ID:* ${execution._id}`,
            },
          },
          ...(step
            ? [
                {
                  type: 'section',
                  text: {
                    type: 'mrkdwn',
                    text: `*Step Causing Issue:* ${stepName} - ${step}`,
                  },
                },
              ]
            : []),
          {
            type: 'section',
            text: {
              type: 'mrkdwn',
              text: `*Issue:* ${bugDescription}`,
            },
          },
          ...(file
            ? [
                {
                  type: 'image',
                  image_url: getS3Url(file.s3Key),
                  alt_text: 'screenshot',
                },
              ]
            : []),
        ],
      };

      await request
        .post('/api/processes/collaboration/slack/reports')
        .set('x-access-token', currentUser.token)
        .send({
          ...payload,
          contuitId: currentUser.contuitId,
        });

      toast.success(
        'Bug Report Submitted Successfully. Our team will review it and take the necessary actions.',
      );
      setReporting(false);
      setBugDescription('');
      onHide();
    } catch (err) {
      const { response } = err;
      const message = `Bug Report Submission Failed: ${get(
        response,
        'body.message',
        'Unknown error',
      )}`;

      toast.error(message);
      toast.error(
        'We encountered an error while submitting your bug report. Please try again later or contact support if the issue persists.',
      );
      setReporting(false);
      onHide();
    }
  };

  const handleOpenFileHandler = () => {
    fileInputRef.current.click();
  };

  const handleSelectFile = async (e) => {
    const file = e.target.files[0];

    if (file) {
      try {
        setUploadingFile(true);
        const result = await uploadServer.uploadFile({
          token: currentUser.token,
          file,
        });

        setFile({ ...result, name: file.name });
        setUploadingFile(false);
      } catch (err) {
        toast.error(
          'We encountered an error while uploading your file. Please try again later or contact support if the issue persists.',
        );
        setUploadingFile(false);
      }
    }
  };

  return (
    <Modal show={showModal} onHide={onHide} size="large">
      <Modal.Header closeButton>
        <Modal.Title>Report Bug</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="content-row content-row-padded process-form box-rest">
          <div className="container">
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                handleReport();
              }}
            >
              <TextAreaInput
                label="Description"
                type="text"
                input={{
                  value: bugDescription,
                  onChange: ({ target: { value } }) => setBugDescription(value),
                  onBlur: () => {},
                }}
              />
              <SelectInput
                name="step"
                label="Step Causing Issue"
                meta={{}}
                input={{
                  onChange: (v) => setStep(v),
                  value: step,
                  onBlur: () => {},
                }}
                options={execution.process.steps
                  .slice(0, execution.steps.length)
                  .map((s) => ({ id: s._id, name: s.name }))}
              />
              <div className="clearfix form-group" style={{ marginTop: 15 }}>
                <label className="col-sm-4 col-xs-12">Screenshot</label>
                <div className="col-sm-8 col-xs-12" style={{ display: 'flex' }}>
                  {file ? (
                    <FileRemoveThumbnail
                      file={file}
                      removeFile={() => setFile(null)}
                    />
                  ) : (
                    <Button
                      variant="primary"
                      onClick={handleOpenFileHandler}
                      style={{
                        marginLeft: 'left',
                        color: '#fff',
                        background: '#5cb85c',
                        borderColor: '#4cae4c',
                      }}
                      disabled={uploadingFile}
                    >
                      <i
                        className="fa fa-plus"
                        style={{ marginRight: '3px' }}
                      />{' '}
                      Upload
                      <input
                        type="file"
                        onChange={handleSelectFile}
                        style={{ display: 'none' }}
                        ref={fileInputRef}
                        accept="image/*"
                      />
                      {uploadingFile && <Loading inline delay={false} />}
                    </Button>
                  )}
                </div>
              </div>
            </Form>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <LoadingButton
            variant="primary"
            label="Report"
            loading={reporting}
            className="pull-right"
            loadingLabel="Reporting"
            onClick={handleReport}
            type="button"
          />
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ReportBugModal;
