import _ from 'underscore';
import io from 'socket.io-client';
import { historyPush } from '../history';
import { reset, change } from 'redux-form';
import { toast } from 'react-toastify';
import { ProcessServer } from '@contuit-otf/sdk';
import { addProcesses } from './processes';
import request from '../utility/request';
import { SEND_SOCKETS } from '../constants';

const processServer = new ProcessServer('/api/processes', request);

export function setSocketOpen(socket, open = true) {
  return {
    type: 'EXECUTION_SOCKET_OPEN',
    socket,
    open,
  };
}

/** LOADING LIST OF EXECUTIONS * */
export function setExecutionsLoading(loading = true) {
  return {
    type: 'EXECUTIONS_LOADING',
    loading,
  };
}

export function addExecutions(executions, totalItemCount) {
  return {
    type: 'ADD_EXECUTIONS',
    executions,
    totalItemCount,
  };
}

/**
 * Saves the execution object into a child dictionary. Uses the child's id as a key.
 * @param {string} child id of the child execution
 * @param {Object} execution execution object to save
 */
export function addChildExecution(child, execution) {
  return { type: 'ADD_CHILD_EXECUTION', execution, child };
}

/**
 * @param  {String} process    Id of the process to get executions from
 * @param  {Bool} hideActive   Hide active executions
 * @param  {Bool} hideCompleted     Hide completed exeuctions
 */
export function loadExecutions(
  query = {
    processId: null,
    showCompleted: false,
    showActive: false,
  },
  paging = {},
  options = {},
) {
  return (dispatch, getState) => {
    const { token } = getState().users.currentUser;
    const { limit = 40, offset = 0, sort = '-dateUpdated' } = paging;
    const { child = false } = options;
    const myQuery = {
      ...query,
      cp_limit: limit,
      cp_offset: offset,
      cp_sort: sort,
    };
    dispatch(setExecutionsLoading());
    processServer
      .findExecutions({ token, query: myQuery })
      .then(({ data, totalItemCount }) => {
        // use obj here to filter data
        if (child) {
          data.forEach((item) => {
            dispatch(addChildExecution(item._id, item));
          });
        } else {
          dispatch(addExecutions(data, totalItemCount));
        }
        dispatch(setExecutionsLoading(false));
      });
  };
}

export function loadVaultExecutions(slug, id) {
  return (dispatch, getState) => {
    const { token } = getState().users.currentUser;
    dispatch(setExecutionsLoading());

    processServer
      .getVaultExecutions({ token, slug, id })
      .then(data => {
        dispatch(addExecutions(data.executions));
        dispatch(addProcesses(data.processes));
      })
      .catch((xhr) => {
        const msg =
          xhr.response && xhr.response.body && xhr.response.body.message
            ? xhr.response.body.message
            : 'Problem loading executions. Please try again later.';
        toast.error(msg);
      })
      .then(() => {
        dispatch(setExecutionsLoading(false));
      });
  };
}
/** END LOADING LIST OF EXECUTIONS * */

/** LOADING 1 EXECUTION * */
export function setExecutionLoading(loading = true) {
  return { type: 'EXECUTION_LOADING', loading };
}

export function setParentExecutionLoading(loading = true) {
  return { type: 'EXECUTION_PARENT_LOADING', loading };
}

export function setChildExecutionLoading(loading = true) {
  return { type: 'EXECUTION_CHILD_LOADING', loading };
}

/**
 * Saves an execution to the store
 * @param {Object} execution execution object to save
 */
export function addExecution(execution) {
  return { type: 'ADD_EXECUTION', execution };
}

/**
 * Saves a parent execution to the store
 * @param {Object} execution execution object to save
 */
export function addParentExecution(execution) {
  return { type: 'ADD_PARENT_EXECUTION', execution };
}

export function addExecutionStepOutputWithoutForm(stepId, output) {
  return { type: 'ADD_EXECUTION_OUTPUT', stepId, output };
}

export function addExecutionStepOutput(stepId, output) {
  return (dispatch, getState) => {
    _.each(output, (value, key) => {
      let newVal = value;
      if (key === 'id') {
        const lastId =
          getState().form.executeStep &&
          getState().form.executeStep.values &&
          getState().form.executeStep.values.id;
        newVal = lastId ? [...lastId, value] : [value];
      }
      dispatch(change('executeStep', key, newVal));
    });

    dispatch(addExecutionStepOutputWithoutForm(output));
  };
}

/**
 * Loads an execution from the server
 * @param {string} id The id of the execuiton you want to load
 * @param {boolean} options.parent Is this a parent execution? Saves it elsewhere on the store
 * @param {boolean} options.child Is this a child execution? Saves it elsewhere on the store
 */
export function loadExecution(
  id,
  { parent = false, child = null } = { parent: false, child: null },
) {
  return (dispatch, getState) => {
    const { token } = getState().users.currentUser;
    const funcSets = {
      parent: { loading: setParentExecutionLoading, add: addParentExecution },
      child: {
        loading: setChildExecutionLoading,
        add: addChildExecution.bind(null, id),
      },
      default: { loading: setExecutionLoading, add: addExecution },
    };
    let funcs = child ? funcSets.child : funcSets.default;
    funcs = parent ? funcSets.parent : funcs;

    dispatch(funcs.loading());

    return processServer
      .findExecution({ token, executionId: id })
      .then((data) => {
        dispatch(funcs.add(data));

        return data;
      })
      .catch((err) => {
        dispatch(addExecutionError(err.message));
      })
      .then((data) => {
        dispatch(funcs.loading(false));

        return data;
      });
  };
}
/** END LOADING 1 EXECUTION * */

/** UPDATING AN EXECUTION * */
export function setExecutionUpdating(updating = true) {
  return {
    type: 'EXECUTION_UPDATING',
    updating,
  };
}

export function setExecutionDeleting(deleting = true) {
  return {
    type: 'EXECUTION_DELETING',
    deleting,
  };
}

export function removeExecution(id) {
  return {
    type: 'REMOVE_EXECUTION',
    id,
  };
}

export function addExecutionError(error) {
  return {
    type: 'ADD_EXECUTION_ERROR',
    error,
  };
}

export function updateExecution(execution, clearStepOnSuccess = false) {
  return (dispatch, getState) => {
    const { token } = getState().users.currentUser;
    dispatch(setExecutionUpdating());
    processServer
      .updateExecution({ token, execution })
      .then((data) => {
        if (clearStepOnSuccess) {
          dispatch(reset('executeStep'));
        }

        dispatch(addExecution(data));
        // setTimeout(() => { dispatch(addExecution(data)); }, 5000);
      })
      .catch((err) => {
        dispatch(addExecutionError(err.message));
      })
      .then(() => {
        dispatch(setExecutionUpdating(false));
        // setTimeout(() => { dispatch(setExecutionUpdating(false)); }, 5000);
      });
  };
}

export function tryStepAgain(execution) {
  return (dispatch, getState) => {
    const { token } = getState().users.currentUser;
    dispatch(setExecutionUpdating());
    processServer
      .tryStepAgain({ token, execution })
      .then((newExecution) => {
        dispatch(addExecution(newExecution));
      })
      .catch((err) => {
        dispatch(addExecutionError(err.message));
      })
      .then(() => {
        dispatch(setExecutionUpdating(false));
        // setTimeout(() => { dispatch(setExecutionUpdating(false)); }, 5000);
      });
  };
}

export function deleteExecution(executionId, cb) {
  return (dispatch, getState) => {
    const { token } = getState().users.currentUser;
    dispatch(setExecutionDeleting());
    processServer
      .deleteExecution({ token, executionId })
      .then(() => {
        dispatch(removeExecution(executionId));
        cb();
      })
      .then(() => {
        dispatch(setExecutionDeleting(false));
      });
  };
}
/** END UPDATING AN EXECUTION * */

/** SOCKED COMMUNICATION * */
export function openSocketConnection(executionId) {
  return (dispatch, getState) => {
    if (!SEND_SOCKETS) {
      return;
    }

    const { socketOpen } = getState().processes.execution;

    if (socketOpen) {
      return;
    }

    const socket = io('/processes');
    socket.connect();
    dispatch(setSocketOpen(socket));
    socket.emit('set execution', { executionId });

    socket.on('update', (data) => {
      const { value: execution } = getState().processes.execution;
      if (
        execution &&
        execution._id === data._id &&
        execution.dateUpdated < data.dateUpdated
      ) {
        dispatch(addExecution(data));
      }
    });
    socket.on('delete', () => dispatch(removeExecution(executionId)));
  };
}

export function closeSocketConnection() {
  return (dispatch, getState) => {
    const { socket } = getState().processes.execution;

    if (!socket) {
      return;
    }

    socket.disconnect();
    dispatch(setSocketOpen(null, false));
  };
}
/** END SOCKED COMMUNICATION * */

/** CREATING AN EXECUTION * */
export function setExecutionCreating(creating = true) {
  return {
    type: 'EXECUTION_CREATING',
    creating,
  };
}

/**
 * Creates an execution on the server
 * @param  {string} processId    Id of the process to Execute
 * @param  {Object} [options.preFill={}] [description]
 * @return {thunk}
 */
export function createExecution(processId, { preFill } = { preFill: {} }) {
  return (dispatch, getState) => {
    const { token } = getState().users.currentUser;
    dispatch(setExecutionCreating());
    dispatch(setExecutionLoading());

    request
      .post(`/api/processes/executions?process=${processId}`)
      .set({ 'x-access-token': token })
      .send({ preFill })
      .then(({ body: data }) => {
        dispatch(addExecution(data));
        dispatch(setSocketOpen(null, false));
        toast.success(`Execution #${data.execution} started`);
        historyPush(`/processes/${processId}/executions/${data._id}`);
      })
      .catch((e) => {
        if (
          e &&
          e.response &&
          e.response.body &&
          e.response.body.message === 'Process invalid'
        ) {
          return dispatch(
            toast.error(
              'Process currently invalid! Please edit to see and resolve issues.',
            ),
          );
        }
        toast.error('Unknown error, please try again later.');
      })
      .then(() => {
        dispatch(setExecutionLoading(false));
        dispatch(setExecutionCreating(false));
      });
  };
}
/** END CREATING AN EXECUTION * */

/** EXECUTION OVERVIEW * */
export function setExecutionOverviewState(overviewShow) {
  return {
    type: 'SET_EXECUTION_OVERVIEW_STATE',
    overviewShow,
  };
}
/** END EXECUTION OVERVIEW * */
