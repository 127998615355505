import React from 'react'
import PropTypes from 'prop-types'
import { Loading, LoadingButton } from 'lib/acromyrmex'
import {
  Button,
  OverlayTrigger,
  Popover,
  FormControl,
} from '../../../utility/UiComponents'
import UserThumbnail from '../../user/UserThumbnail'

class AssignAdmin extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      userFilter: '',
      showPopover: false, // Add state to control popover visibility
    }

    this.togglePopover = this.togglePopover.bind(this)
    this.hidePopover = this.hidePopover.bind(this)
  }

  togglePopover(visible) {
    this.setState({ showPopover: visible })
  }

  hidePopover() {
    this.setState({ showPopover: false })
  }

  getAssignmentList(filteredList) {
    const { onAssignUser } = this.props
    if (filteredList.length < 1) {
      return <Loading />
    }

    return filteredList.map((user) => (
      <Button
        className="user-option"
        key={user._id}
        href="#"
        onClick={() => {
          this.hidePopover() // Use state to hide the popover
          onAssignUser(user)
        }}
        variant='light'
      >
        <UserThumbnail user={user} /> {user.name}
      </Button>
    ))
  }

  getFilteredList() {
    const { userList } = this.props
    const { userFilter } = this.state

    if (!userList || !userList.length) {
      return []
    }

    return userList.filter((user) => {
      const filter = userFilter
      if (filter === '') {
        return true
      }

      if (user.name.toLowerCase().indexOf(filter.toLowerCase()) > -1) {
        return true
      }

      return false
    })
  }

  renderPopover(filteredList) {
    const { userFilter } = this.state
    const assignmentList = this.getAssignmentList(filteredList)
    const title = (
      <div>
        Select user
        <span className="pull-right">{`(${filteredList.length})`}</span>
      </div>
    )

    return (
      <Popover id="assign-user-popover" className="assign-user">
        <Popover.Header as="h3">{title}</Popover.Header>
        <div className="assign-user-filter" style={{ padding: 3 }}>
          <FormControl
            type="text"
            placeholder="Search users"
            value={userFilter}
            onChange={(e) => {
              const searchVal = e.target.value
              this.setState({ userFilter: searchVal })
            }}
          />
        </div>
        <hr />
        <div className="assign-user-list">{assignmentList}</div>
      </Popover>
    )
  }

  render() {
    const { isUpdating, children, placement } = this.props

    const filteredList = this.getFilteredList()
    const { showPopover } = this.state

    return (
      <OverlayTrigger
        trigger="click"
        placement={placement}
        rootClose
        show={showPopover} // Control visibility with state
        onToggle={this.togglePopover} // Toggle visibility
        overlay={this.renderPopover(filteredList)}
      >
        {children || (
          <LoadingButton
            variant="primary"
            size="xsmall"
            loading={isUpdating}
            loadingLabel="loading.."
            label={
              <span>
                <i className="fa fa-users" />
                {' Assign'}
              </span>
            }
          />
        )}
      </OverlayTrigger>
    )
  }
}

AssignAdmin.propTypes = {
  userList: PropTypes.arrayOf(PropTypes.shape()),
  onAssignUser: PropTypes.func.isRequired,
  isUpdating: PropTypes.bool,
  children: PropTypes.node,
  placement: PropTypes.string,
}

AssignAdmin.defaultProps = {
  userList: [],
  isUpdating: false,
  children: null,
  placement: 'top',
}

export default AssignAdmin
