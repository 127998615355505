import React, { Component } from 'react';
import PageLayout from '../shared/Layout/PageLayout';

class ShowTerms extends Component {
  static propTypes = {};

  static defaultProps = {};

  state = {};

  render() {
    return (
      <PageLayout
        header="Contuit Privacy Policy"
        content={
          <iframe
            title="Contuit Privacy Policy"
            src="https://contuit-assets.s3.us-east-2.amazonaws.com/Contuit+Privacy+Policy.pdf"
            style={{ width: '100%', height: '100%' }}
          />
        }
      />
    );
  }
}

export default ShowTerms;
