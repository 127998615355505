// from: https://gist.github.com/insin/bbf116e8ea10ef38447b
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Loading, SelectInput } from 'lib/acromyrmex';
import { loadVaultStructure as loadVaultStructureAction } from '../../../actions/vault';

// import FormField from './FormField';

class StructureAttributeSelectInput extends React.Component {
  componentWillMount() {
    const { slug, loadVaultStructure } = this.props;

    if (slug) {
      loadVaultStructure(slug);
    }
  }

  componentWillUpdate(nextProps) {
    const { slug, loadVaultStructure, struct } = nextProps;

    if (slug && (!struct || !struct.attributes)) {
      loadVaultStructure(slug);
    }
  }

  // eslint-disable-next-line
  // shouldFormFieldUpdate(nextProps) {
  //   FormField.shouldFormFieldUpdate(nextProps);
  // }

  render() {
    const {
      label,
      name,
      help,
      loading,
      enableAll,
      enableEmpty,
      struct,
      meta,
      noLabel,
      disabled,
      vertical,
      optionRenderer,
      valueRenderer,
      input,
      dataType,
      addedOptions
    } = this.props;

    if (!addedOptions && (!struct || !struct.attributes)) {
      return <Loading />;
    }

    const myOptions =
      !struct || !struct.attributes
        ? []
        : struct.attributes
            .filter(item => {
              // first filter out just our type (if supplied)
              if (dataType) {
                return item.type === dataType;
              }

              return true;
            })
            .map(item => ({
              id: item.key,
              name: `${struct.display}.${item.display}`
            }));

    return myOptions.length > 0 ? (
      <SelectInput
        label={label}
        vertical={vertical}
        name={name}
        meta={meta}
        help={help}
        noLabel={noLabel}
        loading={loading}
        input={input}
        enableAll={enableAll}
        enableEmpty={enableEmpty}
        optionRenderer={optionRenderer}
        valueRenderer={valueRenderer}
        disabled={disabled}
        options={[...addedOptions, ...myOptions]}
      />
    ) : (
      <Loading />
    );
  }
}

StructureAttributeSelectInput.propTypes = {
  dataType: PropTypes.string,
  struct: PropTypes.shape(),
  slug: PropTypes.string.isRequired,
  name: PropTypes.string,
  meta: PropTypes.shape().isRequired,
  help: PropTypes.string,
  label: PropTypes.string,
  loading: PropTypes.bool,
  enableAll: PropTypes.bool,
  enableEmpty: PropTypes.bool,
  input: PropTypes.shape().isRequired,
  noLabel: PropTypes.bool,
  disabled: PropTypes.bool,
  vertical: PropTypes.bool,
  optionRenderer: PropTypes.func,
  valueRenderer: PropTypes.func,
  loadVaultStructure: PropTypes.func.isRequired,
  addedOptions: PropTypes.arrayOf(PropTypes.shape())
};

StructureAttributeSelectInput.defaultProps = {
  dataType: null,
  struct: null,
  name: '',
  help: '',
  label: '',
  noLabel: false,
  loading: false,
  disabled: false,
  enableAll: false,
  enableEmpty: false,
  vertical: false,
  optionRenderer: undefined,
  valueRenderer: undefined,
  addedOptions: []
};

const mapStateToProps = (state, props) => ({
  ui: state.ui,
  vault: state.vault,
  struct: state.vault.structures[props.slug],
  loading: state.vault.structures[props.slug] && state.vault.structures[props.slug].isLoading
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      loadVaultStructure: loadVaultStructureAction
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(StructureAttributeSelectInput);
