import get from 'lodash/get'

const VAULT_INITIAL = {
  structures: {},
  data: {},
  recentData: {
    isLoading: false,
    rows: [],
  },
  structureList: {
    isLoading: false,
    rows: [],
  },
  customers: {
    isLoading: false,
    rows: [],
  },
  mappings: {
    isLoading: false,
    rows: [],
  },
}

export default (state = VAULT_INITIAL, action = {}) => {
  switch (action.type) {
    case 'ADD_STRUCTURE': {
      const nextState = Object.assign({}, state)
      const newStruct = nextState.structures[action.structure.slug]
        ? {
            ...nextState.structures[action.structure.slug],
            ...action.structure,
          }
        : action.structure

      nextState.structures[action.structure.slug] = newStruct
      return nextState
    }
    case 'ADD_DATA': {
      const nextState = Object.assign({}, state)
      if (!action.structure) return state
      nextState.data[action.structure.slug] = Object.assign(
        {},
        nextState.data[action.structure.slug],
        {
          rows: action.data,
          hasLoaded: true,
          totalItemCount: action.totalItemCount,
        },
      )
      return nextState
    }
    case 'UPDATE_DATA': {
      const nextState = Object.assign({}, state)
      if (
        !nextState.data[action.structure.slug] ||
        !nextState.data[action.structure.slug].rows
      ) {
        return state
      }
      const updateObj = nextState.data[action.structure.slug].rows.find(
        (u) => u._id === action.data._id,
      )
      if (!action.structure || !updateObj) {
        return state
      }
      const index =
        nextState.data[action.structure.slug].rows.indexOf(updateObj)
      nextState.data[action.structure.slug].rows[index] = action.data
      return nextState
    }
    case 'ADD_RECENT_DATA': {
      return {
        ...state,
        recentData: {
          ...state.recentData,
          rows: action.data,
        },
      }
    }
    case 'ADD_STRUCTURE_LIST': {
      const nextState = Object.assign({}, state)

      nextState.structureList.rows = action.list

      return nextState
    }
    case 'ADD_CUSTOMER_COUNTS': {
      return {
        ...state,
        customers: {
          ...state.customers,
          rows: action.data,
        },
      }
    }
    case 'SET_QUERY': {
      const nextState = Object.assign({}, state)

      if (!nextState.data[action.structure.slug]) {
        nextState.data[action.structure.slug] = {}
      }

      nextState.data[action.structure.slug].query = action.query
      return nextState
    }
    case 'DATA_LOADING': {
      const nextState = Object.assign({}, state)

      if (!nextState.data[action.structure.slug]) {
        nextState.data[action.structure.slug] = {}
      }
      nextState.data[action.structure.slug].isLoading = action.loading
      return nextState
    }
    case 'RECENT_DATA_LOADING': {
      return {
        ...state,
        recentData: {
          ...state.recentData,
          isLoading: action.loading,
        },
      }
    }
    case 'ENTRY_DELETING': {
      const nextState = Object.assign({}, state)

      if (!nextState.data[action.structure.slug]) {
        nextState.data[action.structure.slug] = {}
      }

      nextState.data[action.structure.slug].isDeleting = action.deleting
      return nextState
    }
    case 'ENTRY_CREATING': {
      const nextState = Object.assign({}, state)

      if (!nextState.data[action.structure.slug]) {
        nextState.data[action.structure.slug] = {}
      }

      nextState.data[action.structure.slug].isCreating = action.creating
      return nextState
    }
    case 'STRUCTURE_CREATING': {
      const nextState = Object.assign({}, state)

      if (!nextState.structures[action.structure.slug]) {
        nextState.structures[action.structure.slug] = {}
      }

      nextState.structures[action.structure.slug].isCreating = action.creating
      return nextState
    }
    case 'STRUCTURE_DELETING': {
      const nextState = Object.assign({}, state)

      if (!nextState.structures[action.structure.slug]) {
        nextState.structures[action.structure.slug] = {}
      }

      nextState.structures[action.structure.slug].isDeleting = action.deleting
      return nextState
    }
    case 'REMOVE_ENTRY': {
      const nextState = Object.assign({}, state)

      if (!nextState.data[action.structure.slug]) {
        return nextState
      }

      const entries = get(nextState, `data.${action.structure.slug}.rows`, [])

      for (let i = 0; i < entries.length; i++) {
        if (entries[i]._id === action.id) {
          entries.splice(i, 1)
          break
        }
      }

      return nextState
    }
    case 'REMOVE_STRUCTURE': {
      const nextState = Object.assign({}, state)

      if (!nextState.structures[action.structure.slug]) {
        return nextState
      }

      const struct = nextState.structures[action.structure.slug]

      // Since isLoading etc are attached to the structure, we can't just
      // delete it. Instead lets keep those but nothing else.
      const newStruct = {
        isLoading: struct.isLoading,
        isDeleting: struct.isDeleting,
        isUpdating: struct.isUpdating,
        isCreating: struct.isCreating,
      }

      nextState.structures[action.structure.slug] = newStruct

      const list = nextState.structureList.rows
      for (let i = 0; i < list.length; i++) {
        if (list[i].slug === action.structure.slug) {
          list.splice(i, 1)
          break
        }
      }

      return nextState
    }
    case 'STRUCT_LOADING': {
      const nextState = Object.assign({}, state)
      const { slug } = action.structure

      if (!nextState.structures[slug]) {
        nextState.structures[slug] = {}
      }

      nextState.structures[slug].isLoading = action.loading
      return nextState
    }
    case 'STRUCT_LIST_LOADING': {
      const nextState = Object.assign({}, state)

      nextState.structureList.isLoading = action.loading
      return nextState
    }
    case 'CUSTOMER_COUNTS_LOADING': {
      const nextState = Object.assign({}, state)

      nextState.customers.isLoading = action.loading
      return nextState
    }
    case 'MAPPINGS_LOADING': {
      return {
        ...state,
        mappings: {
          ...state.mappings,
          isLoading: action.loading,
        },
      }
    }
    case 'ADD_MAPPINGS': {
      return {
        ...state,
        mappings: {
          ...state.mappings,
          rows: action.data,
        },
      }
    }
    default:
      return state
  }
}
