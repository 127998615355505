import React from 'react';

function ShowDuoSyncVideo() {
  return (
    <div style={{ height: '100%' }}>
      <div id="home-header" style={{ height: '100%' }}>
        <div className="container">
          <div id="home-toolbar">
            <div className="marketing-brand">
              <img src="/images/logo_white.png" alt="logo" />
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '25px',
              height: '100%',
              flexDirection: 'column',
            }}
          >
            <video style={{ maxWidth: '100%', maxHeight: '100%' }} controls>
              <source
                src="https://contuit-assets.s3.us-east-2.amazonaws.com/docs/Duo+CW+Manage+Sync+V4.mp4"
                type="video/mp4"
              />
            </video>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ShowDuoSyncVideo;
