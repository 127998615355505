/* eslint-disable class-methods-use-this */
import React from 'react'
import { VAULT_QUERY_STEP_TYPE } from '../../../constants'
import VaultQueryStepOptions from './components/stepOptions/VaultQueryStepOptions'
import BasicUiHelper from './BasicUiHelper'
import VaultInputUiHelper from './VaultInputUiHelper'
import useTemplateString from '../../../hooks/useTemplateString'

class VaultQueryUiHelper extends BasicUiHelper {
  regex() {
    return VAULT_QUERY_STEP_TYPE
  }

  name() {
    return 'Vault Query'
  }

  canTryAgain() {
    return true
  }

  stepOptions() {
    return VaultQueryStepOptions
  }

  getOptionSummary() {
    return [
      { key: 'slug', title: 'Vault Structure', type: 'VaultStructure' },
      {
        key: 'queryFields',
        title: 'Query Fields',
        default: [],
        formatter: (value, currentUser, execution) => (
          <table className="parameter-table">
            <tbody>
              {value.map((param) => {
                const [outputString, error] = useTemplateString(
                  param.query,
                  currentUser,
                  execution,
                  'loading...',
                )
                return (
                  <tr>
                    <td>{param.attribute}</td>
                    <td>{outputString || error}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        ),
      },
    ]
  }

  getOutputKeys() {
    return {
      schema: {
        '.': {
          type: 'Table',
          vaultStructureStepOption: 'slug',
          ignoreColumns: [
            'site',
            'businessUnit',
            'customer',
            '_id',
            'hashChanged',
            'integrations',
            'vaultStructure',
          ],
        },
      },
    }
  }

  getInputOptions(step, { structureList }) {
    const { multi } = step.stepOptions

    // when we just have a single item, we treat it like a vault input
    if (multi !== 'multi') {
      return VaultInputUiHelper.getInputOptions(step, { structureList })
    }
  }

  getOutputOptions(step, { structureList }) {
    const { slug, multi } = step.stepOptions

    // when we just have a single item, we treat it like a vault input
    if (multi !== 'multi') {
      return VaultInputUiHelper.getOutputOptions(step, { structureList })
    }

    const struct = structureList.find((s) => s.slug === slug)

    if (!struct) return

    return [
      {
        id: JSON.stringify({ step: step._id, key: '.' }),
        name: 'data',
        textValue: `{${step._id}..}`,
        type: 'vault-collection',
        slug: step.stepOptions.slug,
        properties: struct.attributes.map((attr) => ({
          id: JSON.stringify({ step: step._id, field: attr.key }),
          name: `${attr.display}`,
          textValue: `{${step._id}.${attr.key}}`,
          type: attr.type,
        })),
      },
      super.getLengthAttribute(step),
    ]
  }
}

export default VaultQueryUiHelper
