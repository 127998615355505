import React from 'react';
import { Field } from 'redux-form';
import { TextInput } from 'lib/acromyrmex';
import EndpointInput from './EndpointInput';
import { Col } from '../../../utility/UiComponents';
import { requiredValidator } from '../../../utility/formValidators';

const LogicMonitorCredentialForm = () => (
  <div>
    <EndpointInput />
    <Col xs={12}>
      <Field
        name="authenticationOptions.accessId"
        validate={requiredValidator}
        component={TextInput}
        label="Acess ID"
        type="password"
      />
      <Field
        name="authenticationOptions.accessKey"
        validate={requiredValidator}
        component={TextInput}
        label="Access Key"
        type="password"
      />
    </Col>
  </div>
);

LogicMonitorCredentialForm.propTypes = {};

LogicMonitorCredentialForm.defaultProps = {};

export default LogicMonitorCredentialForm;
