import React, { Component } from 'react';
import { Image } from '../../../utility/UiComponents';

class ShowApiHelp extends Component {
  static propTypes = {};

  static defaultProps = {};

  state = {};

  render() {
    return (
      <div className="content-col clearfix" id="api">
        <h3>Using the Api</h3>
        <hr />
        <h4>Using user login</h4>
        <p>To log in as a user, use the following API to get a token.</p>
        <Image
          src="https://s3.us-east-2.amazonaws.com/contuit-assets/help/authenticate.png"
          alt="get_data_1"
          thumbnail
          responsive
        />
        <h4>Using an API Key</h4>
        <p>To create an API key, click on your username and then Api Keys.</p>
        <Image
          src="https://s3.us-east-2.amazonaws.com/contuit-assets/help/Get_API_Key.png"
          alt="get_data_2"
          thumbnail
          responsive
        />
        <h4>Using the API</h4>
        <p>Use the token from either user login or an API key as your access token.</p>
        <p>For example, get structures for a particular contuitId</p>
        <Image
          src="https://s3.us-east-2.amazonaws.com/contuit-assets/help/get_structures.png"
          alt="get_data_2"
          thumbnail
          responsive
        />
        <p>Then get data for a given structure (customers in this case)</p>
        <Image
          src="https://s3.us-east-2.amazonaws.com/contuit-assets/help/get_data_1.png"
          alt="get_data_1"
          thumbnail
          responsive
        />
        <Image
          src="https://s3.us-east-2.amazonaws.com/contuit-assets/help/get_data_2.png"
          alt="get_data_2"
          thumbnail
          responsive
        />
      </div>
    );
  }
}

export default ShowApiHelp;
