import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { FieldArray, Field } from 'redux-form';
import PropTypes from 'prop-types';
import { SelectInput } from 'lib/acromyrmex';
import { Row, Col, Accordion, Alert } from '../../../../../../utility/UiComponents';
import VaultSaveFieldArray from './vaultSaveFieldArray';

class VaultSaveStepOptions extends React.Component {
  render() {
    const {
      fieldText,
      outputOptions,
      change,
      outputOptionsLoaded,
      formValues: { steps }
    } = this.props;
    // gets the index based on field name. Not the best way but works?
    const stepIndex = fieldText.substring(fieldText.indexOf('[') + 1, fieldText.indexOf(']'));

    if (!steps) {
      return null;
    }

    const thisStep = steps[stepIndex];
    const stepOptions = outputOptions.filter(o => o.vault);

    if (!stepOptions || stepOptions.length === 0) {
      return (
        <Row key={fieldText} style={{ marginBottom: 10 }}>
          <Alert variant="danger">
            <i className="fa fa-exclamation-circle" />
            {' Vault save step type requires a previous vault input step!'}
          </Alert>
        </Row>
      );
    }

    return (
      <Row key={fieldText} style={{ marginBottom: 10 }}>
        <Accordion defaultActiveKey="1">
          <Accordion.Item eventKey="1">
            <Accordion.Header>Vault Save Step Options</Accordion.Header>
            <Accordion.Body>
            <Row>
              <Field
                name={`${fieldText}.stepOptions.destination`}
                component={SelectInput}
                label="Structure to save to"
                templateOptions={stepOptions}
              />
            </Row>
            {(!thisStep.stepOptions.fields || !thisStep.stepOptions.fields.length) && (
              <Alert variant="danger">
                <i className="fa fa-exclamation-circle" />
                {
                  ' Vault save step requires at least one field. Add a field with the button to the right!'
                }
              </Alert>
            )}
            <Row style={{ marginBottom: 10 }}>
              <Col xs={5}>
                <strong>Source Data</strong>
              </Col>
              <Col xs={6}>
                <strong>Destination Field</strong>
              </Col>
            </Row>
            {outputOptionsLoaded && (
              <FieldArray
                name={`${fieldText}.stepOptions.fields`}
                component={VaultSaveFieldArray}
                props={{
                  outputOptions,
                  change
                }}
              />
            )}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Row>
    );
  }
}

// Define property types
VaultSaveStepOptions.propTypes = {
  fieldText: PropTypes.string.isRequired,
  outputOptions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  formValues: PropTypes.shape(),
  change: PropTypes.func.isRequired,
  outputOptionsLoaded: PropTypes.bool.isRequired
};

VaultSaveStepOptions.defaultProps = {
  formValues: {}
};

const mapStateToProps = state => ({
  structureList: state.vault.structureList.rows,
  formValues: state.form.processForm ? state.form.processForm.values : {}
});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(VaultSaveStepOptions);
