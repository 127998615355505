import { useEffect } from 'react'
import { useLocation, useNavigate, useRoutes } from 'react-router-dom'
import ReactGA from 'react-ga'
import { useDispatch, useSelector } from 'react-redux'
import { ThemeRoutes } from './ ThemeRoutes'
import scheduler from './Scheduler'
import { historyRef } from './history'
import cookie from 'react-cookie'

ReactGA.initialize('UA-102378530-1', { debug: true })

function App() {
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const routing = useRoutes(ThemeRoutes)
  const currentUser = useSelector((state) => state?.users?.currentUser)

  historyRef.current = navigate

  useEffect(() => {
    ReactGA.set({ page: location.pathname })
    ReactGA.pageview(location.pathname)
  }, [location])

  useEffect(() => {
    scheduler(dispatch)
  }, [])

  useEffect(() => {
    // Detects when visiting a tab and checks if the window needs to be reloaded to refresh the auth state
    const onTabVisited = (e) => {
      // If no user is logged in no need to reload the window and other tabs
      if(currentUser == false) {
        return;
      }

      const { visibilityState } = e.target
      const isTabVisited = visibilityState === 'visible'

      const currentUserTenant = currentUser?.contuitId
      const storedTenant = cookie.load('contuitId')

      // after switching the tenant, we need to refresh the other tabs
      if (isTabVisited && currentUserTenant !== storedTenant) {
        window.location = window.location.origin
      }
    }

    window.addEventListener('visibilitychange', onTabVisited)
    return () => window.removeEventListener('visibilitychange', onTabVisited)
  }, [currentUser])

  return routing
}

export default App
