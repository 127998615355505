import React from 'react'
import PropTypes from 'prop-types'
import { marked } from 'marked'
import { Button, Modal, Image } from '../../utility/UiComponents'

class DocumentationMarkdown extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      showModal: false,
      src: '',
    }

    this.openModal = this.openModal.bind(this)
    this.closeModal = this.closeModal.bind(this)
  }

  getMarkdown() {
    let { source } = this.props

    if (source === null) {
      source = ''
    }

    const renderer = new marked.Renderer()

    renderer.image = (href, title, text) =>
      `<div>
          <img
            src="${href}"
            alt="${text}"
            title="${title}"
          >
        </div>
      `

    renderer.link = (href, title, text) =>
      `<a href="${href}" target="_blank">${text}</a>`

    return marked(source, {
      // Sanitize the output. Ignore any HTML that has been input.
      sanitize: true,
      renderer,
    })
  }

  openModal(src) {
    this.setState({ showModal: true, src })
  }

  closeModal() {
    this.setState({ showModal: false })
  }

  render() {
    const { showModal, src } = this.state
    return (
      <div className="documentation-markdown">
        <div
          // disabling the linter here because we know that we are getting
          // sanitized html here. No worries about scripting attacks.
          //
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: this.getMarkdown() }}
        />
        <Modal
          show={showModal}
          onHide={this.closeModal}
          className="image-preview"
          size="large"
        >
          <Modal.Header closeButton>
            <Modal.Title>Submit action</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Image src={src} alt="preview" />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={this.closeModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    )
  }
}

DocumentationMarkdown.propTypes = {
  source: PropTypes.string.isRequired,
}

DocumentationMarkdown.defaultProps = {}

export default DocumentationMarkdown
