import React from 'react';
import { Field } from 'redux-form';
import { TextInput } from 'lib/acromyrmex';
import EndpointInput from './EndpointInput';
import { Col } from '../../../utility/UiComponents';
import { requiredValidator } from '../../../utility/formValidators';

const UserPassCredentialForm = () => (
  <div>
    <EndpointInput />
    <Col xs={12}>
      <Field
        name="authenticationOptions.username"
        validate={requiredValidator}
        component={TextInput}
        label="Username"
        help="This user will be used for system level API calls. We recommend setting up a specific user for Contuit."
        type="text"
      />
      <Field
        name="authenticationOptions.password"
        validate={requiredValidator}
        component={TextInput}
        label="Password"
        type="password"
      />
    </Col>
  </div>
);
UserPassCredentialForm.propTypes = {};
UserPassCredentialForm.defaultProps = {};
export default UserPassCredentialForm;
