import _ from 'underscore';
import moment from 'moment';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'lib/acromyrmex';
import { Button } from 'react-bootstrap';
import ExecutionMarkdown from '../ExecutionMarkdown';
import StringHelper from '../../../shared-helpers/StringHelper';
import ShowProcessStepInfo from './ShowProcessStepInfo';

const descriptionProcessor = (description, steps) => {
  const templateMatches = _.isString(description)
    ? StringHelper.getTemplateValues(description)
    : [];

  let newValue = description;

  templateMatches.forEach(match => {
    const split = match.split('.');
    const sourceStep = steps.find(s => s._id === split[0]);

    newValue = newValue.replace(
      `{${match}}`,
      `~${sourceStep ? sourceStep.name : split[0]} -> ${split[1]}~`
    );
  });

  return newValue;
};

const printDuration = ms => {
  if (!ms) {
    return 'no average available';
  }
  const time = moment.duration(ms);
  if (time.asSeconds() < 60) {
    return `${Math.floor(time.asSeconds())} second(s)`;
  }
  if (time.asMinutes() < 60) {
    return `${Math.floor(time.asMinutes())} minunte(s)`;
  }
  return `${time.asHours().toFixed(1)} hour(s)`;
};

const ShowProcessStep = props => {
  const { processStep, index, steps, durations } = props;
  const [showDescription, setShowDescription] = useState(false);
  const [showTiming, setShowTiming] = useState(false);

  return (
    <div style={{ textAlign: 'left' }}>
      <div className="clearfix">
        <div className="pull-left" style={{ marginTop: '2px' }}>
          Step {index + 1}. {processStep.name}
        </div>
        <div className="pull-right">
          {processStep.stepOptions.description && (
            <Button variant="link" size="xs" onClick={() => setShowDescription(!showDescription)}>
              <Icon info />
            </Button>
          )}
          {durations && (
            <Button variant="link" size="xs" onClick={() => setShowTiming(!showTiming)}>
              <i className="fa fa-clock-o" />
            </Button>
          )}
        </div>
      </div>
      {durations && showTiming && (
        <ShowProcessStepInfo
          icon={<i className="fa fa-clock-o" />}
          body={`Avg. Duration: ${printDuration(durations[index])}`}
        />
      )}
      {showDescription && (
        <ShowProcessStepInfo
          icon={<Icon info />}
          body={
            <ExecutionMarkdown
              source={descriptionProcessor(processStep.stepOptions.description, steps)}
            />
          }
        />
      )}
    </div>
  );
};

ShowProcessStep.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  durations: PropTypes.arrayOf(PropTypes.number),
  processStep: PropTypes.shape().isRequired,
  index: PropTypes.number.isRequired
};

ShowProcessStep.defaultProps = {
  durations: undefined
};

export default ShowProcessStep;
