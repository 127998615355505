export const formulaOptions = [
  { id: 'trim', name: 'Trim', parameters: [] },
  { id: 'htmlToMarkdown', name: 'Html To Markdown', parameters: [] },
  {
    id: 'replace',
    name: 'Replace',
    parameters: [
      { name: 'pattern', required: true },
      { name: 'replacement', required: false },
    ],
  },
  {
    id: 'substring',
    name: 'Substring',
    parameters: [
      { name: 'start', required: true },
      { name: 'end', required: false },
    ],
  },
  {
    id: 'slice',
    name: 'Slice',
    parameters: [
      { name: 'start', required: true },
      { name: 'end', required: false },
    ],
  },
  { id: 'toUpperCase', name: 'To Upper Case', parameters: [] },
  { id: 'toLowerCase', name: 'To Lower Case', parameters: [] },
  {
    id: 'concat',
    name: 'Concat',
    parameters: [{ name: 'otherString', required: true }],
  },
  {
    id: 'endsWith',
    name: 'Ends With',
    parameters: [{ name: 'searchString', required: true }],
  },
  {
    id: 'charAt',
    name: 'Char At',
    parameters: [{ name: 'index', required: true }],
  },
  {
    id: 'match',
    name: 'Match',
    parameters: [{ name: 'pattern', required: true }],
  },
  {
    id: 'includes',
    name: 'Contains',
    parameters: [{ name: 'searchString', required: true }],
  },
]
