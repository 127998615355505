import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { PropTypes, connect, bindActionCreators } from '../../utility/ReactStuff';
import ShowDuoLoginForm from '../user/ShowDuoLoginForm';
import { logoutCurrentUser as logoutCurrentUserAction } from '../../actions/users';
import ShowDuoLayout from './ShowDuoLayout';

function ShowMarketing({ logoutCurrentUser }) {
  const { message } = new URLSearchParams(useLocation().search);

  useEffect(() => {
    logoutCurrentUser(false);
  }, []);

  return (
    <ShowDuoLayout
      explanation={(
        <div>
          <p>Great!</p>
          <p>
            Now that you have configured Duo as your multi factor authentication provider, please
            log in to continue.
          </p>
        </div>
        )}
      content={<ShowDuoLoginForm message={message || ''} />}
    />
  );
}

// Define property types
ShowMarketing.propTypes = {
  logoutCurrentUser: PropTypes.func.isRequired,
};

ShowMarketing.defaultProps = {};

const mapStateToProps = (state) => ({
  currentUser: state.users.currentUser,
  browser: state.browser,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    logoutCurrentUser: logoutCurrentUserAction,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(ShowMarketing);
