import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import PageLayout from '../shared/Layout/PageLayout';
import ShowConditionalHelp from '../help/sections/ShowConditionalHelp';

class ShowDocsBuildConditional extends Component {
  static propTypes = {};

  static defaultProps = {};

  state = {};

  render() {
    return <PageLayout header="Step Conditions" content={<ShowConditionalHelp />} />;
  }
}

export default reduxForm({ form: 'sampleForm' })(ShowDocsBuildConditional);
