import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import StepCondition from '../../../processes/ProcessForm/StepCondition';
import ConditionalHelper from '../../../../process/helpers/ConditionalHelper';

class ConditionalExample extends Component {
  state = {
    'condition-1.lhv': '',
    'condition-1.rhv': '',
    'condition-1.conditionType': 0
  };

  render() {
    const {
      'condition-1.lhv': lhs,
      'condition-1.rhv': rhs,
      'condition-1.conditionType': operation
    } = this.state;
    const errors = [];

    ConditionalHelper.calculateCondition(lhs, operation, rhs, () => {}, errors);

    return (
      <div>
        <StepCondition
          condition="condition-1"
          outputOptions={[]}
          fields={{ get: () => ({ lhv: lhs, rhv: rhs, conditionType: operation }) }}
          index={0}
          change={() => {}}
          onChangeAction={(value, key) => {
            this.setState({ [key]: value });
          }}
          hideDelete
        />
        <div className="indented">
          {errors.map(failedCondition => (
            <div key={failedCondition}>
              <span role="img" aria-label="failed">
                ❌
              </span>{' '}
              {failedCondition}
            </div>
          ))}
          {(!errors || !errors.length) && (
            <p>
              <span role="img" aria-label="check">
                ✅
              </span>
              {' Passed!'}
            </p>
          )}
        </div>
      </div>
    );
  }
}

export default ConditionalExample;
