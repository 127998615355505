import React from 'react';
import PropTypes from 'prop-types';

const ShowProcessStepInfo = ({ body, icon }) => {
  return (
    <div className="clearfix" style={{ marginBottom: '3px' }}>
      <div
        style={{
          width: '25px',
          float: 'left',
          fontSize: '0.8em',
          textAlign: 'center',
          paddingTop: '3px'
        }}
      >
        {icon}
      </div>
      <div
        className="process-step-info"
        style={{
          width: 'calc(100% - 25px)',
          float: 'left',
          paddingLeft: '3px',
          borderLeft: '1px solid #8c8c8c'
        }}
      >
        {body}
      </div>
    </div>
  );
};

ShowProcessStepInfo.propTypes = {
  body: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired
};

ShowProcessStepInfo.defaultProps = {};

export default ShowProcessStepInfo;
