import React, { useState } from 'react';
import { AuthenticationServer } from '@contuit-otf/sdk';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { Row } from '../../utility/UiComponents';
import ResetPasswordForm from '../../modules/user/ResetPasswordForm';
import MarketingFormLayout from '../../modules/shared/Layout/MarketingFormLayout';
import request from '../../utility/request';

const authServer = new AuthenticationServer('/api/auth', request);

function ShowResetPassword() {
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = (values) => {
    const { email, code, newPassword } = values;
    authServer
      .checkPasswordCode({ email, code, newPassword })
      .then(({ success, message }) => {
        if (success) {
          navigate('/login');
          toast.success('Password has been reset, you may now log in.');
        } else {
          toast.error(message);
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  return (
    <MarketingFormLayout
      explanation={
        <div>
          <h3>Reset Password</h3>
          <p>Please check your email for a confirmation code to enter below.</p>
        </div>
      }
      content={
        <Row className="content-row">
          <h3 style={{ margin: '5px 0' }}>Create your new password.</h3>
          <ResetPasswordForm onSubmit={onSubmit} isSubmitting={isSubmitting} />
        </Row>
      }
    />
  );
}

export default ShowResetPassword;
