const UPLOAD_INITIAL = {
  uploadModalOpen: false,
  uploading: false,
  attaching: false,
  uploadedFiles: [],
}

export default (state = UPLOAD_INITIAL, action = {}) => {
  switch (action.type) {
    case 'FILE_UPLOADING':
      return { ...state, uploading: action.loading }
    case 'UPLOAD_ATTACHING':
      return { ...state, attaching: action.loading }
    case 'SET_UPLOAD_MODAL':
      return { ...state, uploadModalOpen: action.open }
    case 'ADD_UPLOADED_FILE':
      return { ...state, uploadedFiles: [...state.uploadedFiles, action.file] }
    case 'CLEAR_UPLOADED_FILES':
      return { ...state, uploadedFiles: [] }
    case 'REMOVE_UPLOADED_FILE': {
      const newUploadedFiles = [...state.uploadedFiles]
      // if a string is passed, it is the s3Key
      if (typeof action.file === 'string') {
        const file = state.uploadedFiles.find((f) => f.s3Key === action.file)

        // no file found, can't remove
        if (!file) {
          return state
        }
      }
      newUploadedFiles.splice(newUploadedFiles.indexOf(action.file), 1)
      return { ...state, uploadedFiles: newUploadedFiles }
    }
    default:
      return state
  }
}
