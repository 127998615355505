import isString from 'lodash/isString'
import format from 'date-fns/format'
import {
  startOfMonth,
  formatDistanceToNow,
  differenceInDays,
  compareDesc,
  parseISO,
} from 'date-fns'
import { DATE_FORMAT, DATE_FORMAT_NO_TIME, TIME_FORMAT } from '../constants'

class DateHelper {
  static withinMinutes(date, numberOfMinutes) {
    const ms = 60 * 1000 * numberOfMinutes
    return new Date() - date < ms
  }

  static withinDays(date, numberOfDays) {
    const daysInMs = numberOfDays * 24 * 60 * 60 * 1000
    return new Date(date - daysInMs)
  }

  static formatDateTime(date, formatString = DATE_FORMAT) {
    const parsedDate = isString(date) ? parseISO(date) : date
    return format(parsedDate, formatString)
  }

  static timeAgo(date) {
    let parsedDate = isString(date) ? parseISO(date) : date

    if (/^\d{10}$/.test(date)) {
      parsedDate = new Date(Number(date))
    }

    // Math.abs to consider future dates
    if (Math.abs(differenceInDays(parsedDate, new Date())) >= 1) {
      return DateHelper.formatDateTime(parsedDate)
    }

    const distanceInWords = formatDistanceToNow(parsedDate, {
      addSuffix: false,
    })

    return compareDesc(new Date(), parsedDate) === 1
      ? `in ${distanceInWords}`
      : `${distanceInWords} ago`
  }

  static formatDateOnly(date, formatString = DATE_FORMAT_NO_TIME) {
    const parsedDate = isString(date) ? parseISO(date) : date
    return format(parsedDate, formatString)
  }

  static formatTime(date, formatString = TIME_FORMAT) {
    const parsedDate = isString(date) ? parseISO(date) : date
    return format(parsedDate, formatString)
  }

  static startOfMonth(date = new Date(), formatString = DATE_FORMAT) {
    return format(startOfMonth(date), formatString)
  }
}

export default DateHelper
