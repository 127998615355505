import { EXECUTE_PROCESS_STEP_TYPE } from '../../../constants'
import ExecuteProcessStepOptions from './components/stepOptions/ExecuteProcessStepOptions'
import ExecuteProcessStepAutomating from './components/stepAutomating/executeProcess'
import BasicUiHelper from './BasicUiHelper'

class ExecuteProcessUiHelper extends BasicUiHelper {
  regex() {
    return EXECUTE_PROCESS_STEP_TYPE
  }

  name() {
    return 'Execute Process'
  }

  canTryAgain() {
    return false
  }

  stepOptions() {
    return ExecuteProcessStepOptions
  }

  // we don't want a submit button
  getStepButtons() {
    return []
  }

  getOutputKeys() {
    return {
      title: 'Child Execution',
      schema: {
        executionId: {
          type: 'Execution',
          processIdStepOption: 'processId',
        },
      },
    }
  }

  stepAutomating() {
    return ExecuteProcessStepAutomating
  }

  getOutputOptions(step) {
    return [
      {
        id: JSON.stringify({ step: step._id, key: '$c_executionUrl' }),
        name: 'Execution URL',
        textValue: `{${step._id}.$c_executionUrl}`,
        type: 'string',
      },
    ]
  }
}

export default ExecuteProcessUiHelper
