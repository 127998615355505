import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reduxForm, Field } from 'redux-form';
import { DateInput, SelectInput, LoadingButton } from 'lib/acromyrmex';
import { Row, Col, Form, Button } from '../../utility/UiComponents';
import { requiredValidator } from '../../utility/formValidators';
import FillModal from './steps/components/stepOptions/FillModal';

class ScheduleProcessForm extends React.Component {
  static propTypes = {
    processItem: PropTypes.shape().isRequired,
    onSubmit: PropTypes.func.isRequired,
    isScheduling: PropTypes.bool,
    onCancel: PropTypes.func.isRequired,

    // from redux form
    handleSubmit: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired,
    valid: PropTypes.bool.isRequired
  };

  static defaultProps = {
    isScheduling: false
  };

  getCalendarContainer = this.d || document.getElementById('d');

  render() {
    const {
      handleSubmit,
      onSubmit,
      isScheduling,
      valid,
      onCancel,
      change,
      processItem
    } = this.props;

    return (
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col xs={12}>
            <div
              id="d"
              ref={n => {
                this.d = n;
              }}
            />
            <Field
              name="startDate"
              validate={requiredValidator}
              component={DateInput}
              label="Start Date"
              getCalendarContainer={this.getCalendarContainer}
            />
            <Field
              name="frequency"
              validate={requiredValidator}
              component={SelectInput}
              label="Frequency"
              options={[
                { id: 'once', name: 'Once' },
                { id: 'minute', name: 'Every Minute' },
                { id: 'minute10', name: '10 Minutes' },
                { id: 'minute15', name: '15 Minutes' },
                { id: 'minute30', name: '30 Minutes' },
                { id: 'daily', name: 'Daily' },
                { id: 'weekly', name: 'Weekly' },
                { id: 'monthly', name: 'Monthly' }
              ]}
            />
            <Col xs={12} sm={8} smOffset={4}>
              <FillModal
                nameOverride="fills"
                change={change}
                fillProcess={processItem}
                onlyStatic
              />
            </Col>
          </Col>
        </Row>
        <Row>
          <Col xs={12} style={{ marginTop: 10 }}>
            <LoadingButton
              variant="primary"
              disabled={!valid}
              label="Schedule"
              loading={isScheduling}
              className="pull-right"
              loadingLabel="Scheduling"
              type="submit"
            />
            <Button
              variant="danger"
              onClick={onCancel}
              className="pull-right"
              style={{ marginRight: '5px' }}
            >
              Cancel
            </Button>
            {!valid && (
              <span
                className="text-danger pull-right"
                style={{ marginRight: '5px', marginTop: '7px' }}
              >
                Check form validity!
              </span>
            )}
          </Col>
        </Row>
      </Form>
    );
  }
}

const mapStateToProps = state => ({
  structureList: state.vault.structureList.rows,
  isCreating:
    state.enabledIntegrations.enabledIntegrations.isCreating ||
    state.enabledIntegrations.enabledIntegrations.isUpdating,
  formValues: state.form.enableIntegrationForm ? state.form.enableIntegrationForm.values : {},
  currentUser: state.users.currentUser
});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

const form = reduxForm({ form: 'enableIntegrationForm' })(ScheduleProcessForm);
export default connect(mapStateToProps, mapDispatchToProps)(form);
