import _ from 'underscore';
import get from 'lodash/get';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from '../../../utility/UiComponents';
import ApiParameter from './ApiParameter';
import AddButton from '../../shared/form/AddButton';

class ApiParameterList extends Component {
  static attrToOptionReq(details, key, prefix = '') {
    const newPrefix = `${prefix}${prefix.length > 0 ? '.' : ''}${key}`;

    if (details.type === 'object') {
      const temp = [];
      const { properties } = details;

      const req = details.required;

      // nothing required on the object but the whole object required: add all
      if (!req) {
        _.each(
          properties,
          (p, k) => !p.readOnly && temp.push(...ApiParameterList.attrToOption(p, k, newPrefix))
        );

        return temp;
      }

      req.forEach(r => {
        const attr = details.properties[r];

        temp.push(...ApiParameterList.attrToOption(attr, r, newPrefix));
      });

      return temp;
    }

    return [{ id: newPrefix, name: newPrefix }];
  }

  static getDefaultParameters({ stepType, integrationOptions }) {
    const integrationOption = integrationOptions[stepType];
    const params = [];
    const action = integrationOption && (integrationOption.action || integrationOption.trigger);

    if (action && action.parameters) {
      action.parameters
        .filter(p => ApiParameterList.paramFilter(action, p) && p.required)
        .forEach(p => {
          // for objects, we check their children?
          if (p.schema && p.schema.type === 'object') {
            // for arrays we just treat it like an object for the required portion
            const req = p.schema.required;

            if (!req) return;

            req.forEach(r => {
              const attr = get(p.schema.properties, r);

              params.push(...ApiParameterList.attrToOptionReq(attr, r, p.name));
            });

            return;
          }

          if (p.schema && p.schema.type === 'array') {
            const req = p.schema.items.required;
            if (!req) return;

            req.forEach(r => {
              const attr = p.schema.items.properties[r];

              params.push(...ApiParameterList.attrToOptionReq(attr, r, `${p.name}.0`));
            });

            return;
          }

          params.push(...ApiParameterList.paramToOption(p));
        });
    }

    return params;
  }

  static paramToOption(param, prefix = '', usedNames = []) {
    const newPrefix = `${prefix}${prefix.length > 0 ? '.' : ''}${param.name}`;
    // If the parameter is an object we need to handle that..
    // 
    if (param.schema && param.schema.type === 'object') {
      const temp = [];
      _.each(param.schema.properties, (p, k) =>
        temp.push(...ApiParameterList.attrToOption(p, k, newPrefix, usedNames))
      );
      return temp;
    }

    // If the parameter is an array we need to handle that..
    if (param.schema && param.schema.type === 'array') {
      const temp = [];
      _.each(param.schema.items.properties, (p, k) => {
        temp.push(...ApiParameterList.attrToOption(p, k, `${newPrefix}.0`, usedNames));

        // For arrays, we need to let people keep adding them. To do this, we check
        // if any attributes from the array have been added for this index, if so
        // we add the next index as an option
        let i = 0;
        const map = n => n && n.startsWith(`${newPrefix}.${i}`);
        const reduce = (a, c) => a || c;
        while (usedNames.map(map).reduce(reduce, false)) {
          i += 1;
          temp.push(...ApiParameterList.attrToOption(p, k, `${newPrefix}.${i}`, usedNames));
        }
      });
      return temp;
    }

    return [{ id: newPrefix, name: param.display || newPrefix }];
  }

  static attrToOption(details, key, prefix = '', usedNames = []) {
    const newPrefix = `${prefix}${prefix.length > 0 ? '.' : ''}${key}`;
    // 

    if (details.type === 'object') {
      const temp = [];
      const { properties } = details;

      const req = details.required;

      // nothing required: add all
      if (!req) {
        _.each(
          properties,
          (p, k) =>
            !p.readOnly && temp.push(...ApiParameterList.attrToOption(p, k, newPrefix, usedNames))
        );

        return temp;
      }

      // nothing required: add non required
      _.each(properties, (p, k) => {
        // if (req.includes(k)) {
        //   return;
        // }

        temp.push(...ApiParameterList.attrToOption(p, k, newPrefix, usedNames));
      });

      return temp;
    }
    if (details.type === 'array') {
      const temp = [];

      // For arrays, we need to let people keep adding them. To do this, we check
      // if any attributes from the array have been added for this index, if so
      // we add the next index as an option
      const arrayRepeat = callback => {
        // call for the first one
        callback.call(this, 0);
        let i = 0;
        const map = n => n && n.startsWith(`${newPrefix}.${i}`);
        const reduce = (a, c) => a || c;
        while (usedNames.map(map).reduce(reduce, false)) {
          i += 1;
          callback.call(this, i);
        }
      };

      if (details.items.properties) {
        _.each(details.items.properties, (p, k) => {
          arrayRepeat(i => {
            temp.push(...ApiParameterList.attrToOption(p, k, `${newPrefix}.${i}`, usedNames));
          });
        });
      } else {
        arrayRepeat(i => {
          temp.push({ id: `${newPrefix}.${i}`, name: `${newPrefix}.${i}` });
        });
      }
      return temp;
    }

    return [{ id: newPrefix, name: details.display || newPrefix }];
  }

  static getAttribute(integrationOption, name) {
    const attribute = integrationOption.action || integrationOption.trigger;
    const successResponse = attribute.responses[200];

    if (!successResponse) {
      throw new Error('Could not find the success response condition for action.');
    }

    const { schema } = successResponse;

    const result = _.find(schema.properties, val => val['x-result-content']);

    if (!result) {
      throw new Error('Could not find the result content for action.');
    }

    const attrs = result.type === 'array' ? result.items.properties : result.properties;

    if (!attrs) {
      return null;
    }

    const attr = attrs[name];

    if (!attr) {
      return null;
    }

    return attr;
  }

  static paramFilter(action, p) {
    return (
      !p['x-ignore'] &&
      !p.readOnly &&
      (!action.fixedParameters || !action.fixedParameters.find(f => f.name === p.name))
    );
  }

  static getParameterOptions({ stepType, integrationOptions, usedNames }) {
    const integrationOption = integrationOptions[stepType];
    const params = [];
    const action = integrationOption && (integrationOption.action || integrationOption.trigger);

    if (action && action.parameters) {
      action.parameters
        .filter(ApiParameterList.paramFilter.bind(null, action))
        .forEach(p => params.push(...ApiParameterList.paramToOption(p, '', usedNames)));
    }

    if (action && action['x-filterable-parameters']) {
      action['x-filterable-parameters'].forEach(p => {
        const attribute = ApiParameterList.getAttribute(integrationOption, p);
        params.push({
          id: p,
          name: p,
          filter: true,
          attrType: attribute.format === 'date-time' ? 'date-time' : attribute.type
        });
      });
    }

    if (action && action['x-patch-operation']) {
      const patchable = action['x-patch-operation'];
      _.each(patchable.properties, (value, key) => {
        params.push({
          id: key,
          name: key,
          patch: true,
          attrType: value.type,
          properties: value.properties || (value.items && value.items.properties)
        });
      });
    }

    return params;
  }

  componentWillMount() {
    // do the parameter stuff
    this.componentWillUpdate();
  }

  componentWillUpdate() {
    const { currentOptions, array, fieldText } = this.props;
    const defaultProps = this.getDefaultParameters();
    const { parameters } = currentOptions;

    if ((!parameters || parameters.length < 1) && defaultProps && defaultProps.length > 0) {
      const name = `${fieldText}.parameters`;
      array.removeAll(name);
      defaultProps.forEach(prop => {
        array.push(name, { name: prop.id, required: true });
      });
    }
  }

  /**
   * Gets a list of required parameters to show in the view. The rest will be added by the user
   * @return {[type]} [description]
   */
  getDefaultParameters() {
    const { stepType, integrationOptions } = this.props;
    return ApiParameterList.getDefaultParameters({ stepType, integrationOptions });
  }

  getParameterOptions(usedNames) {
    const { stepType, integrationOptions } = this.props;
    return ApiParameterList.getParameterOptions({
      stepType,
      integrationOptions,
      usedNames
    });
  }

  render() {
    const { change, outputOptions, fields } = this.props;

    const usedNames = [];
    fields.forEach((n, i, l) => usedNames.push(l.get(i).name));
    const paramOptions = this.getParameterOptions(usedNames);
    const noParamsLeft = fields.length >= paramOptions.length;
    const rows = fields.map((member, index) => (
      <ApiParameter
        key={member}
        fieldText={member}
        fields={fields}
        index={index}
        paramOptions={paramOptions}
        change={change}
        outputOptions={outputOptions}
      />
    ));

    return (
      <div>
        {rows}
        <Row className="step-option-buttons">
          <Col xs={12}>
            <AddButton size="sm"
              label="Add Parameter"
              disabled={noParamsLeft}
              onClick={() => fields.push({})}
            />
            {noParamsLeft && (
              <span
                className="text-info pull-right"
                style={{ marginTop: '5px', marginRight: '5px' }}
              >
                (All parameters are filled)
              </span>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

ApiParameterList.propTypes = {
  // form stuff
  fieldText: PropTypes.string.isRequired,
  fields: PropTypes.shape().isRequired,
  change: PropTypes.func.isRequired,
  array: PropTypes.shape().isRequired,

  // for checking whats changed
  currentOptions: PropTypes.shape(),

  // what type of api are we calling here
  stepType: PropTypes.string.isRequired,

  // bunch of integration options that can be used
  integrationOptions: PropTypes.shape().isRequired,

  // the options that can be used for a template
  outputOptions: PropTypes.arrayOf(PropTypes.shape())
};

ApiParameterList.defaultProps = {
  outputOptions: [],
  currentOptions: {}
};

export default ApiParameterList;
