import { historyPush } from '../history';
import _ from 'underscore';
import { VaultServer } from '@contuit-otf/sdk';
import { toast } from 'react-toastify';
import Vault from '../utility/vault';
import StringHelper from '../shared-helpers/StringHelper';
import { addExecutionStepOutput } from './executions';
import request from '../utility/request';

const vaultServer = new VaultServer('/api/vault', request);

export function addStructure(structure) {
  return {
    type: 'ADD_STRUCTURE',
    structure,
  };
}

export function addStructureList(list) {
  return {
    type: 'ADD_STRUCTURE_LIST',
    list,
  };
}

export function addData(structure, data, totalItemCount) {
  return {
    type: 'ADD_DATA',
    structure,
    data,
    totalItemCount,
  };
}

export function updateData(structure, data) {
  return {
    type: 'UPDATE_DATA',
    structure,
    data,
  };
}

export function addRecentData(data) {
  return {
    type: 'ADD_RECENT_DATA',
    data,
  };
}

export function addCustomerCounts(data) {
  return {
    type: 'ADD_CUSTOMER_COUNTS',
    data,
  };
}

export function setDataLoading(structure, loading = true) {
  return {
    type: 'DATA_LOADING',
    structure,
    loading,
  };
}

export function setMappingLoading(structure, loading = true) {
  return {
    type: 'MAPPINGS_LOADING',
    structure,
    loading,
  };
}

export function addMappings(data) {
  return {
    type: 'ADD_MAPPINGS',
    data,
  };
}

export function setRecentDataLoading(loading = true) {
  return {
    type: 'RECENT_DATA_LOADING',
    loading,
  };
}

export function setStructLoading(structure, loading = true) {
  return {
    type: 'STRUCT_LOADING',
    structure,
    loading,
  };
}

export function setStructListLoading(loading = true) {
  return {
    type: 'STRUCT_LIST_LOADING',
    loading,
  };
}

export function setEntryDeleting(structure, deleting = true) {
  return {
    type: 'ENTRY_DELETING',
    structure,
    deleting,
  };
}

export function setEntryCreating(structure, creating = true) {
  return {
    type: 'ENTRY_CREATING',
    structure,
    creating,
  };
}

export function setStructureCreating(structure, creating = true) {
  return {
    type: 'STRUCTURE_CREATING',
    structure,
    creating,
  };
}

export function setStructureDeleting(structure, deleting = true) {
  return {
    type: 'STRUCTURE_DELETING',
    structure,
    deleting,
  };
}

export function setCustomerCountsLoading(loading = true) {
  return {
    type: 'CUSTOMER_COUNTS_LOADING',
    loading,
  };
}

export function removeEntry(id, slug) {
  return {
    type: 'REMOVE_ENTRY',
    structure: { slug },
    id,
  };
}

export function removeStructure(slug) {
  return {
    type: 'REMOVE_STRUCTURE',
    structure: { slug },
  };
}

export function updateEntry(id, slug, entry) {
  return (dispatch, getState) => {
    const { token } = getState().users.currentUser;
    if (!id) {
      return false;
    }

    dispatch(setDataLoading({ slug }));
    vaultServer
      .updateEntry(token, slug, entry)
      .then((updatedEntry) => {
        dispatch(updateData({ slug }, updatedEntry));
        dispatch(setDataLoading({ slug }, false));

        // go back to detail page
        const url = `/vault/${slug}/${updatedEntry._id}`;
        historyPush(url);

        toast.success('Entry updated!');
      })
      .catch((err) => {
        toast.error(
          err?.message || 'Problem updating entry. Please try again later.',
        );
        dispatch(setDataLoading({ slug }, false));
      });
  };
}

export function createEntry(slug, entry, options = {}) {
  options = { redirect: true, ...options };
  return (dispatch, getState) => {
    const { token } = getState().users.currentUser;

    dispatch(setEntryCreating({ slug }));
    request
      .post(`/api/vault/structures/${slug}`)
      .set({ 'x-access-token': token })
      .send(entry)
      .then(({ body: createdEntries }) => {
        const createdEntry =
          createdEntries && createdEntries.length && createdEntries[0];
        const data = getState().vault.data[slug]
          ? [...(getState().vault.data[slug].rows || [])]
          : [];
        data.push(createdEntry);

        dispatch(addData({ slug }, data));

        if (options.redirect) {
          // go back to detail page
          const url = `/vault/${slug}/${createdEntry._id}`;
          historyPush(url);
        }

        if (options.executionStep) {
          dispatch(
            addExecutionStepOutput(options.executionStep, {
              id: createdEntry._id,
            }),
          );
        }

        toast.success('Entry created!');
      })
      .catch(() => {
        toast.error('Problem creating entry. Please try again later.');
      })
      .then(() => {
        dispatch(setEntryCreating({ slug }, false));
      });
  };
}

export function deleteEntry(id, slug) {
  return (dispatch, getState) => {
    const { token, contuitId } = getState().users.currentUser;
    if (!id) {
      return false;
    }

    dispatch(setEntryDeleting({ slug }));
    return vaultServer
      .deleteEntry({ token, slug, entryId: id, contuitId })
      .then(() => {
        dispatch(removeEntry(id, slug));

        // go back to detail page
        historyPush(`/vault/${slug}`);

        toast.success('Entry removed!');
      })
      .catch(() => {
        toast.error('Problem deleting entry. Please try again later.');
      })
      .then(() => {
        dispatch(setEntryDeleting({ slug }, false));
      });
  };
}

export function deleteStructure(slug) {
  return (dispatch, getState) => {
    const { token } = getState().users.currentUser;
    if (!slug) {
      return false;
    }

    dispatch(setStructureDeleting({ slug }));
    return (
      vaultServer
        .deleteStructure({ slug, token })
        // { success: true }
        .then((data) => {
          if (data.success) {
            dispatch(removeStructure(slug));

            // go back to list
            historyPush('/vault');
          }
        })
        .catch((e) => {
          toast.error(`Error deleting structure: ${e.message}`);
        })
        .then(() => {
          dispatch(setStructureDeleting({ slug }, false));
        })
    );
  };
}

export function setDataQuery(structure, query) {
  return {
    type: 'SET_QUERY',
    structure,
    query,
  };
}

export function loadVaultStructure(slug, forceUpdate = false) {
  return (dispatch, getState) => {
    const { token } = getState().users.currentUser;
    const struct = Vault.getStructure(getState().vault, slug);

    // if already loading, skip
    if (!forceUpdate && struct && struct.isLoading) {
      return;
    }

    if (!forceUpdate && struct && struct.attributes) {
      return;
    }

    dispatch(setStructLoading({ slug }));
    vaultServer
      .loadVaultStructure({ slug, token })
      .then((data) => {
        dispatch(addStructure({ ...data, slug }));
      })
      .catch((err) => {
        const msg =
          err?.message || 'Problem loading structure. Please try again later.';
        toast.error(msg);
      })
      .finally(() => {
        dispatch(setStructLoading({ slug }, false));
      });
  };
}

export function updateStructure(slug, structure) {
  return (dispatch, getState) => {
    const { token } = getState().users.currentUser;
    dispatch(setStructLoading({ slug }));
    vaultServer
      .updateStructure({ slug, token, newStructure: structure })
      .then(() => {
        dispatch(loadVaultStructure(slug, true));

        // go back to detail page
        const url = `/vault/${slug}`;
        historyPush(url);

        toast.success('Structure updated!');
      })
      .catch(() => {
        const msg = 'Problem updating structure. Please try again later.';
        toast.error(msg);
      })
      .then(() => {
        dispatch(setStructLoading({ slug }, false));
      });
  };
}

export function createStructure(structure) {
  return (dispatch, getState) => {
    const { token } = getState().users.currentUser;
    const tempStruct = { ...structure };

    if (!tempStruct.slug) {
      tempStruct.slug = StringHelper.slugify(tempStruct.display);
    }

    dispatch(setStructureCreating({ slug: tempStruct.slug }));

    request
      .post('/api/vault/structures')
      .set({ 'x-access-token': token })
      .send(tempStruct)
      .then(({ body: createdStructure }) => {
        dispatch(
          addStructure(createdStructure),
          toast.success('Structure created!'),
        );
      })
      .then(() => historyPush(`/vault/${tempStruct.slug}`))
      .catch(() => {
        toast.error('Problem creating structure. Please try again later.');
      })
      .then(() => {
        dispatch(setStructureCreating({ slug: tempStruct.slug }, false));
      });
  };
}

export function loadStructureList() {
  return (dispatch, getState) => {
    const { token } = getState().users.currentUser;

    dispatch(setStructListLoading());
    return vaultServer
      .loadVaultStructures({ token })
      .then((data) => {
        dispatch(addStructureList(data));
        dispatch(setStructListLoading(false));

        return data;
      })
      .catch((err) => {
        toast.error(
          err?.message || 'Problem loading structures. Please try again later.',
        );
      });
  };
}

const queryEquals = (a, b) => {
  if (!a) {
    return false;
  }

  let isEqual = true;
  let bKeys = _.keys(b);

  _.each(a, (value, key) => {
    if (_.isObject(value)) {
      _.each(value, (subValue, subKey) => {
        isEqual = isEqual && b[key] && b[key][subKey] === subValue;
      });
    } else {
      isEqual = isEqual && b[key] === value;
    }
    bKeys = _.without(bKeys, key);
  });

  return isEqual && bKeys.length === 0;
};

export function loadVaultStructureData(
  slug,
  query = {},
  force = false,
  paging = {},
) {
  return (dispatch, getState) => {
    const state = getState();
    const { token } = getState().users.currentUser;
    const struct = Vault.getStructure(state.vault, slug);
    const lastQuery = Vault.getDataQuery(state.vault, slug);
    // sort will default on the server
    const { limit = 40, offset = 0, sort } = paging;

    const myQuery = {
      ...query,
      cp_limit: limit,
      cp_offset: offset,
      cp_sort: sort,
    };

    if (
      (struct && Vault.isLoading(state.vault.data, slug)) ||
      (!force && queryEquals(lastQuery, myQuery))
    ) {
      return;
    }

    dispatch(setDataQuery({ slug }, myQuery));
    dispatch(setDataLoading({ slug }));
    return vaultServer
      .loadVaultEntries({ token, slug, query: myQuery })
      .then(({ items: data, totalItemCount }) => {
        dispatch(addData({ slug }, data, totalItemCount));
      })
      .catch(() => {
        toast.error('Unknown error loading vault structure data.');
      })
      .then(() => {
        dispatch(setDataLoading({ slug }, false));
      });
  };
}

export function loadRecentVaultData() {
  return (dispatch, getState) => {
    const state = getState();
    const { token } = state.users.currentUser;

    dispatch(setRecentDataLoading());
    vaultServer
      .loadRecentVaultEntries({ token })
      .then((data) => {
        dispatch(addRecentData(data));
      })
      .catch(() => {
        toast.error('Unknown error loading vault structure data.');
      })
      .then(() => {
        dispatch(setRecentDataLoading(false));
      });
  };
}

// used to find any mapped community structures
export function loadMappings(communityProcessId) {
  return (dispatch, getState) => {
    const { token } = getState().users.currentUser;

    dispatch(setMappingLoading());
    vaultServer.loadMappings({ token, communityProcessId }).then((data) => {
      dispatch(addMappings(data));
      dispatch(setMappingLoading(false));
    });
  };
}
