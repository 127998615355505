/* eslint-disable class-methods-use-this */
import {UPDATE_SOLUTION_SETTINGS_STEP_TYPE} from '../../../constants';
import UpdateSolutionSettingsStepOptions from './components/stepOptions/UpdateSolutionSettingsStepOptions';
import BasicUiHelper from './BasicUiHelper';

class UpdateSolutionSettingsUiHelper extends BasicUiHelper {
  regex() {
    return UPDATE_SOLUTION_SETTINGS_STEP_TYPE;
  }

  name() {
    return 'Update Solution Settings';
  }

  canTryAgain() {
    return true;
  }

  stepOptions() {
    return UpdateSolutionSettingsStepOptions;
  }
}

export default UpdateSolutionSettingsUiHelper;
