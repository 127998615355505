import _ from 'underscore'
import isFunction from 'lodash/isFunction'
import BaseStepHelper from '../../../shared-helpers/BaseStepHelper'
import BasicUiHelper from './BasicUiHelper'
import DelayUiHelper from './DelayUiHelper'
import JSONParseUiHelper from './JSONParseUiHelper'
import InputUiHelper from './InputUiHelper'
import VaultInputUiHelper from './VaultInputUiHelper'
import VaultCreateUiHelper from './VaultCreateUiHelper'
import AutoVaultCreateUiHelper from './AutoVaultCreateUiHelper'
import VaultDeleteUiHelper from './VaultDeleteUiHelper'
import VaultQueryUiHelper from './VaultQueryUiHelper'
import VaultQueryV2UiHelper from './VaultQueryV2UiHelper'
import DateFunctionUiHelper from './DateFunctionUiHelper'
import StringFunctionUiHelper from './StringFunctionUiHelper'
import ManualSelectUiHelper from './ManualSelectUiHelper'
import MsTeamsListenerUiHelper from './MsTeamsListenerUiHelper'
import MsTeamsActionListenerUiHelper from './MsTeamsActionListenerUiHelper'
import WebexActionListenerUiHelper from './WebexActionListenerUiHelper'
import SlackListenerUiHelper from './SlackListenerUiHelper'
import ExecuteProcessUiHelper from './ExecuteProcessUiHelper'
import LoopProcessUiHelper from './LoopProcessUiHelper'
import IntegrationUiHelper from './IntegrationUiHelper'
import BlockBeginUiHelper from './BlockBeginUiHelper'
import BlockEndUiHelper from './BlockEndUiHelper'
import BlockBeginV2UiHelper from './BlockBeginV2UiHelper'
import BlockEndV2UiHelper from './BlockEndV2UiHelper'
import ComputationUiHelper from './ComputationUiHelper'
import ComputationV2UiHelper from './components/computationV2/ComputationV2UiHelper'
import SpecialKaseyaUiHelper from './SpecialKaseyaUiHelper'
import UpdateSolutionSettingsUiHelper from './UpdateSolutionSettingsUiHelper'
import CreatePdfUiHelper from './CreatePdfUiHelper'
import VaultMergeV2UiHelper from './VaultMergeV2UiHelper'
import NarrativeUiHelper from './NarrativeUiHelper'
import IntegrationTriggerUiHelper from './IntegrationTriggerUiHelper'
import VaultMergeUiHelper from './VaultMergeUiHelper'
import VaultReplaceUiHelper from './VaultReplaceUiHelper'
import VaultSaveUiHelper from './VaultSaveUiHelper'

/**
 * The steps array contains each step type and some describing attributes/functions
 *   regex: used to identify the step by its step type
 *   stepOptions: react view to be used for step options
 *   stepExecution: react view to be used when the step is being executed
 *   getOutputOptions: function that gets the data output by the given step
 *   getInputOptions: function that gets what information can be input or prefilled for a step
 */
const steps = [
  new BasicUiHelper().getStepObject(),
  new DelayUiHelper().getStepObject(),
  new JSONParseUiHelper().getStepObject(),
  new InputUiHelper().getStepObject(),
  new VaultCreateUiHelper().getStepObject(),
  new AutoVaultCreateUiHelper().getStepObject(),
  new VaultDeleteUiHelper().getStepObject(),
  new VaultInputUiHelper().getStepObject(),
  new VaultQueryUiHelper().getStepObject(),
  new VaultQueryV2UiHelper().getStepObject(),
  new ManualSelectUiHelper().getStepObject(),
  new ExecuteProcessUiHelper().getStepObject(),
  new LoopProcessUiHelper().getStepObject(),
  new IntegrationUiHelper().getStepObject(),
  new BlockBeginUiHelper().getStepObject(),
  new BlockEndUiHelper().getStepObject(),
  new BlockBeginV2UiHelper().getStepObject(),
  new BlockEndV2UiHelper().getStepObject(),
  new ComputationUiHelper().getStepObject(),
  new ComputationV2UiHelper().getStepObject(),
  new StringFunctionUiHelper().getStepObject(),
  new DateFunctionUiHelper().getStepObject(),
  new SpecialKaseyaUiHelper().getStepObject(),
  new MsTeamsListenerUiHelper().getStepObject(),
  new MsTeamsActionListenerUiHelper().getStepObject(),
  new WebexActionListenerUiHelper().getStepObject(),
  new SlackListenerUiHelper().getStepObject(),
  new UpdateSolutionSettingsUiHelper().getStepObject(),
  new CreatePdfUiHelper().getStepObject(),
  new NarrativeUiHelper().getStepObject(),
  new IntegrationTriggerUiHelper().getStepObject(),
  new VaultSaveUiHelper().getStepObject(),
  new VaultReplaceUiHelper().getStepObject(),
  new VaultMergeUiHelper().getStepObject(),
  new VaultMergeV2UiHelper().getStepObject(),
]

const getAvailableProcessInputOptions = (
  process,
  integrations,
  structureList,
  beforeStep,
) => {
  const returnList = []

  let pastOurStep = false
  _.each(process.steps, (loopStep) => {
    if (pastOurStep) return

    if (beforeStep && beforeStep._id === loopStep._id) {
      pastOurStep = true
      return
    }

    steps.forEach((step) => {
      if (
        step.getInputOptions &&
        new RegExp(step.regex).test(loopStep.stepType)
      ) {
        const options = step.getInputOptions(loopStep, {
          structureList,
          integrations,
        })

        if (options) {
          returnList.push(...options)
        }
      }
    })
  })

  return returnList
}

class UiStepHelper extends BaseStepHelper {
  static getStepOptions(stepType) {
    return UiStepHelper.stepAttributeHelper(stepType, 'stepOptions', '')
  }

  static getStepAutomating(stepType) {
    return UiStepHelper.stepAttributeHelper(stepType, 'stepAutomating', '')
  }

  static getStepExecution(stepType) {
    return UiStepHelper.stepAttributeHelper(stepType, 'stepExecution', '')
  }

  static getStepSkipped(stepType) {
    return UiStepHelper.stepAttributeHelper(stepType, 'stepSkipped', '')
  }

  static getStepTypeName(stepType) {
    return UiStepHelper.stepAttributeHelper(stepType, 'name', stepType)
  }

  static canTryAgain(stepType) {
    return UiStepHelper.stepAttributeHelper(stepType, 'canTryAgain', false)
  }

  static disableConditions(stepType) {
    return UiStepHelper.stepAttributeHelper(
      stepType,
      'disableConditions',
      false,
    )
  }

  static stepAttributeHelper(stepType, attribute, defaultReturn = '') {
    let returnValue = defaultReturn

    steps.forEach((step) => {
      if (new RegExp(step.regex).test(stepType)) {
        returnValue = step[attribute]
      }
    })

    return returnValue
  }

  static getStepButtons(stepType) {
    let stepButtons = []

    steps.forEach((step) => {
      if (
        new RegExp(step.regex).test(stepType) &&
        _.isFunction(step.getStepButtons)
      ) {
        stepButtons = step.getStepButtons()
      }
    })

    return stepButtons
  }

  // used for the step type modal
  static getStepTypes() {
    return [
      {
        id: 'basic',
        name: 'Basic',
        brand: 'Contuit',
        description:
          'A simple step that can be completed by the user. Any instructions should be included in the description.',
      },
      {
        id: 'delay',
        name: 'Delay',
        brand: 'Contuit',
        description:
          'A simple step that causes the execution to delay a certain amount of time.',
      },
      {
        id: 'block-begin',
        name: 'Begin Block',
        brand: 'Contuit',
        description:
          'Starts a new block. Blocks are used to section off code that should run under the same conditions. The can also be used to prevent sections of a process from running at the same time.',
      },
      {
        id: 'block-end',
        name: 'End Block',
        brand: 'Contuit',
        description: 'Marks the ending of a block.',
      },
      {
        id: 'block-begin-v2',
        name: 'Begin Block V2',
        brand: 'Contuit',
        description:
          'Starts a new block. Blocks are used to section off code that should run under the same conditions. The can also be used to prevent sections of a process from running at the same time.',
      },
      {
        id: 'block-end-v2',
        name: 'End Block V2',
        brand: 'Contuit',
        description: 'Marks the ending of a block.',
      },
      {
        id: 'input',
        name: 'Input',
        brand: 'Contuit',
        description:
          'Input data into the process. This data can then be used in future steps to perform operations.',
      },
      {
        id: 'computation',
        name: 'Computation',
        brand: 'Contuit',
        description:
          'Perform a computation on a previous piece of data. For more details please visit the help page where examples are given.',
      },
      {
        id: 'date-function',
        name: 'Date Function',
        brand: 'Contuit',
        description:
          'Perform a series of functions on a given input date, outputs the result of the chained functions.',
      },
      {
        id: 'string-function',
        name: 'String Function',
        brand: 'Contuit',
        description:
          'Perform a series of functions on a given input string, outputs the result of the final function.',
      },
      {
        id: 'computation-v2',
        name: 'Computation V2',
        brand: 'Contuit',
        description:
          'Perform a series of functions on a given input, outputs the result of the final function.',
      },
      {
        id: 'json-parse',
        name: 'JSON Parse',
        brand: 'Contuit',
        description:
          'Parse a JSON string into a usable object, which can then be used in future steps to perform operations.',
      },
      {
        id: 'narrative',
        name: 'Narrative',
        brand: 'Contuit',
        description:
          'Build a narrative based on different pieces of data that have been acquired throughout the process.',
      },
      {
        id: 'create-pdf',
        name: 'Create PDF',
        brand: 'Contuit',
        description: 'Create a PDF from a string of markdown.',
      },
      {
        id: 'vault-create',
        name: 'Vault Create',
        brand: 'Contuit',
        description: 'Create a new entry for a vault structure.',
      },
      {
        id: 'auto-vault-create',
        name: 'Auto Vault Create',
        brand: 'Contuit',
        description:
          'Create a new entry for a vault structure without user interaction.',
      },
      {
        id: 'vault-delete',
        name: 'Vault Delete',
        brand: 'Contuit',
        description: 'Delete an entry from a vault structure.',
      },
      {
        id: 'vault-query',
        name: 'Vault Query',
        brand: 'Contuit',
        description: 'Query for a list of entries coming from the vault.',
      },
      {
        id: 'vault-query-v2',
        name: 'Vault Query V2',
        brand: 'Contuit',
        description: 'Query for a list of entries coming from the vault.',
      },
      {
        id: 'vault-input',
        name: 'Vault Input',
        brand: 'Contuit',
        description:
          'Select an entry from a vault structure. Filters of customer/site/business unit will be presented.',
      },
      {
        id: 'vault-save',
        name: 'Vault Save',
        brand: 'Contuit',
        description:
          'Save data to a vault entry that has been brought into the process via Vault Input or Vault Create.',
      },
      {
        id: 'vault-merge',
        name: 'Vault Merge',
        brand: 'Contuit',
        description:
          'Merge a selected list of data with data in a vault structure. A matcher will be chosen that will be used to identify unique items.',
      },
      {
        id: 'vault-merge-v2',
        name: 'Vault Merge V2',
        brand: 'Contuit',
        description:
          'Merge a selected list of data with data in a vault structure. A matcher will be chosen that will be used to identify unique items.',
      },
      {
        id: 'vault-replace',
        name: 'Vault Replace',
        brand: 'Contuit',
        description:
          'Replace a vault structure with a selected source of data. Mapping of attributes determines what new data is saved to the vault.',
      },
      {
        id: 'execute-process',
        name: 'Run Process',
        brand: 'Contuit',
        description:
          'Choose a process to execute. The step will complete once the execution is complete.',
      },
      {
        id: 'loop-process',
        name: 'Run Process for List',
        brand: 'Contuit',
        description:
          'Choose a process to execute for each entry in a list. The step will complete once each execution is complete.',
      },
      {
        id: 'manual-select',
        name: 'Manual Select',
        brand: 'Contuit',
        description:
          'Manually select entries from a source of data generated in a prior step.',
      },
      {
        id: 'ai-text-moderation',
        name: 'AI Text Moderation',
        brand: 'Contuit',
        description:
          'Scan text content. Profanity terms and personal data are returned. Use this information to publish, reject, or review the content in your post-moderation workflow.',
      },
      {
        id: 'ai-semantic-scoring',
        name: 'AI Semantic Scoring',
        brand: 'Contuit',
        description:
          'Provide input values to semantically score the success of a process or action.',
      },
      {
        id: 'ms-teams-listener',
        name: 'MS Teams Listener',
        brand: 'Contuit',
        description: 'Listens for MS Teams messages',
      },
      {
        id: 'ms-teams-action-listener',
        name: 'MS Teams Action Listener',
        brand: 'Contuit',
        description: 'Listens for MS Teams actions',
      },
      {
        id: 'webex-action-listener',
        name: 'Webex Action Listener',
        brand: 'Contuit',
        description: 'Listens for Webex actions',
      },
      {
        id: 'slack-listener',
        name: 'Slack Listener',
        brand: 'Contuit',
        description: 'Listens for Slack messages',
      },
      {
        id: 'special-kaseya',
        name: 'Get Kaseya Org Summary',
        brand: 'Contuit',
        description:
          'Calculates summary level information for a Kaseya organization and any agents it contains.',
      },
      {
        id: 'update-solution-settings',
        name: 'Update Solution Settings',
        brand: 'Contuit',
        description: 'Updates solution settings.',
      },
    ]
  }

  static stepNameMap(opts, step) {
    return opts.map((o) => ({
      ...o,
      stepName: step.name,
      properties:
        o.properties &&
        o.properties.map((p) => ({
          ...p,
          stepName: step.name,
          properties:
            p.properties &&
            p.properties.map((p2) => ({ ...p2, stepName: step.name })),
        })),
    }))
  }

  /**
   *
   * @param {Object} process
   * @param {Array} integrations List of the enabled integrations. Used to find proper information
   * @param {Array} structureList List of vault structures in the system.
   * @param {Object} beforeStep The step you want to stop at. Usually we want all output data up
   * until the current step
   */
  static getAvailableProcessOutputOptions(
    process,
    integrations,
    structureList,
    beforeStep,
  ) {
    const returnList = []

    let pastOurStep = false
    _.each(process.steps, (loopStep) => {
      if (pastOurStep) return

      if (beforeStep && beforeStep._id === loopStep._id) {
        pastOurStep = true
        return
      }

      steps.forEach((step) => {
        if (
          step.getOutputOptions &&
          new RegExp(step.regex).test(loopStep.stepType)
        ) {
          const opts = step.getOutputOptions(loopStep, {
            structureList,
            integrations,
            process,
            steps,
          })
          if (opts) {
            returnList.push(...UiStepHelper.stepNameMap(opts, loopStep))
          }
        }
      })
    })

    return returnList
  }

  /**
   * Gets the output options for a process step
   * @param {Object} step The process step to get output options for
   * @param {Array} integrations List of the enabled integrations. Used to find proper information
   * @param {Array} structureList List of vault structures in the system.
   */
  static getStepOutputOptions(step, integrations, structureList, process) {
    const returnList = []

    //

    steps.forEach((stepType) => {
      if (
        stepType.getOutputOptions &&
        new RegExp(stepType.regex).test(step.stepType)
      ) {
        let opts = []

        try {
          opts =
            stepType.getOutputOptions(step, {
              structureList,
              integrations,
              process,
              steps,
            }) || []

          if (step.stepOptions.continueOnError) {
            opts.push(
              {
                id: JSON.stringify({ step: step._id, key: '$c_errorStatus' }),
                name: 'Error Status',
                textValue: `{${step._id}.$c_errorStatus}`,
                type: 'string',
              },
              {
                id: JSON.stringify({ step: step._id, key: '$c_errorMessage' }),
                name: 'Error Message',
                textValue: `{${step._id}.$c_errorMessage}`,
                type: 'string',
              },
              {
                id: JSON.stringify({ step: step._id, key: '$c_errorDetail' }),
                name: 'Error Detail',
                textValue: `{${step._id}.$c_errorDetail}`,
                type: 'string',
              },
            )
          }

          if (step.stepOptions.continueOnTimeout) {
            opts.push({
              id: JSON.stringify({ step: step._id, key: '$c_timedOut' }),
              name: 'Timed Out',
              textValue: `{${step._id}.$c_timedOut}`,
              type: 'string',
            })
          }
        } catch (e) {}

        if (opts) {
          returnList.push(...UiStepHelper.stepNameMap(opts, step))
        }
      }
    })

    return returnList
  }

  static getOutputKeys(step, options) {
    const stepObj = steps.find((s) => new RegExp(s.regex).test(step.stepType))

    if (!stepObj || !stepObj.getOutputKeys) {
      return {}
    }

    if (isFunction(stepObj.getOutputKeys)) {
      return stepObj.getOutputKeys(options)
    }

    return stepObj.getOutputKeys
  }

  static getOptionSummary(step) {
    const stepObj = steps.find((s) => new RegExp(s.regex).test(step.stepType))

    if (!stepObj || !stepObj.getOptionSummary) {
      return []
    }

    if (isFunction(stepObj.getOptionSummary)) {
      return stepObj.getOptionSummary()
    }

    return stepObj.getOptionSummary
  }
}

export const EXECUTE_PROCESS_STEP_TYPE = /^execute-process$/

export { getAvailableProcessInputOptions }

export default UiStepHelper
