import React from 'react';
import { Field } from 'redux-form';
import { TextInput } from 'lib/acromyrmex';
import { Col } from '../../../utility/UiComponents';
import { requiredValidator } from '../../../utility/formValidators';

const UmbrellaCredentialForm = () => (
  <div>
    <Col xs={12}>
      <Field
        name="authenticationOptions.mspId"
        validate={requiredValidator}
        component={TextInput}
        label="MSP ID"
        help="This value can be found in the url of your ubrella portal."
        type="text"
      />
      <Field
        name="authenticationOptions.apiKey"
        validate={requiredValidator}
        component={TextInput}
        label="Api Key"
        type="text"
      />
      <Field
        name="authenticationOptions.apiSecret"
        validate={requiredValidator}
        component={TextInput}
        label="Api Secret"
        type="password"
      />
      <Field
        name="endpoint"
        component={TextInput}
        value="management.api.umbrella.com"
        type="hidden"
        noLabel
      />
    </Col>
  </div>
);

UmbrellaCredentialForm.propTypes = {};

UmbrellaCredentialForm.defaultProps = {};

export default UmbrellaCredentialForm;
