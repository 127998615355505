import isNull from 'lodash/isNull'
import isUndefined from 'lodash/isUndefined'
// From Redux form
// If current value of field is valid, should return undefined
// Otherwise, return an error

export const requiredValidator = (value) => {
  if (isNull(value) || isUndefined(value) || value === '') {
    return 'Required'
  }

  return undefined
}

export const selectRequiredValidator = (value) => {
  if (value && value !== 'none') {
    return undefined
  }

  return 'Required'
}
