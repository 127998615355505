import superagent from '../../../utility/request';
import React, { useState } from 'react';
import { useLocalStorage } from 'react-use';
import PropTypes from 'prop-types';
import { Loading, Icon } from 'lib/acromyrmex';
import { ProcessServer } from '@contuit-otf/sdk';
import { toast } from 'react-toastify';
import request from '../../../utility/request';
import { LinkContainer } from 'react-router-bootstrap';
import get from 'lodash/get';
import { Button, ButtonGroup } from '../../../utility/UiComponents';
import PagedDataTable from '../../shared/DataTable/PagedDataTable';
import useFetch from '../../../hooks/useFetch';
import ExecutionButtons from '../ConnectedExecutionButtons';
import {
  getCurrentExecutionStep,
  getProcessStepFromExecutionStep,
} from '../../../shared-helpers/execution';

function formatDuration(milliseconds) {
  const seconds = (milliseconds / 1000).toFixed(2);
  const minutes = (seconds / 60).toFixed(2);

  if (minutes > 1) {
    return `${minutes} minute${minutes >= 2 ? 's' : ''}`;
  }
  return `${seconds} second${seconds >= 2 ? 's' : ''}`;
}

const processServer = new ProcessServer('/api/processes', request);

const findExecutions = (
  token,
  processId,
  {
    currentPage = 1,
    pageSize,
    filter,
    sort = '-dateUpdated',
    showCompletedExecutions,
    showOnlyParentExecutions = false,
    showOnlyStuckExecutions = false,
    vaultStructure,
    vaultEntry,
  },
) => {
  const query = {
    hideCompleted: !showCompletedExecutions,
    cp_limit: pageSize,
    cp_offset: (currentPage - 1) * pageSize,
    cp_sort: sort,
    ...filter,
  };

  if (processId !== null) {
    query.processId = processId;
  }

  if (showOnlyParentExecutions) {
    query.isParent = true;
  }

  if (vaultStructure) {
    query.vaultStructure = vaultStructure;
  }

  if (vaultEntry) {
    query.vaultEntry = vaultEntry;
  }

  return showOnlyStuckExecutions
    ? processServer.findStuckExecutions({ token, query })
    : processServer.findExecutions({ token, query });
};

const ShowProcessExecutionTable = (props) => {
  const {
    currentUser: { token },
    processId,
    vaultStructure,
    vaultEntry,
  } = props;

  const [pageState, setPageState] = useState({});
  const [showCompletedExecutions, setShowCompletedExecutions] = useLocalStorage(
    'execution-completed-executions',
    false,
  );
  const [showOnlyStuckExecutions, setShowOnlyStuckExecutions] = useLocalStorage(
    'stuck-executions',
    false,
  );
  const [showOnlyParentExecutions, setShowOnlyParentExecutions] =
    useLocalStorage('execution-parent-executions', false);
  const [deleteExecutionsLoading, setDeleteExecutionsLoading] = useState(false);
  const { currentPage, pageSize, filter, sort } = pageState;

  const [executions, executionsLoading, , executionReloadFn] = useFetch(
    // async fetch function
    findExecutions.bind(this, token, processId),
    // query, sent as the first parameter (third in this case) to the async fetch
    {
      currentPage,
      pageSize: pageSize || 20,
      filter,
      sort,
      showCompletedExecutions,
      showOnlyParentExecutions,
      showOnlyStuckExecutions,
      vaultStructure,
      vaultEntry,
    },
    // default value
    { items: [], totalItemCount: 0 },
  );

  return (
    <PagedDataTable
      title="Process Executions"
      csvTitle="process executions"
      fetchCSVData={() =>
        findExecutions(token, processId, {
          currentPage,
          filter,
          sort,
          showCompletedExecutions,
          showOnlyParentExecutions,
          vaultStructure,
          vaultEntry,
        })
      }
      selectable
      idAttribute="_id"
      data={executions.items}
      actions={[
        {
          key: 'reload',
          style: 'primary',
          alwaysEnabled: true,
          display: (
            <span>
              <i className="fa fa-refresh" /> Reload
            </span>
          ),
          onClick: () => executionReloadFn(),
        },
        {
          key: 'delete',
          style: 'danger',
          display: (
            <span>
              <Icon remove /> Delete
            </span>
          ),
          onClick: (selected) => {
            setDeleteExecutionsLoading(true);
            processServer
              .deleteExecutions({ token, selectionList: selected })
              .then(
                () => executionReloadFn() && setDeleteExecutionsLoading(false),
              )
              .catch((err) => {
                const msg =
                  err?.message ||
                  'Unknown error deleting executions, please try again later.';
                toast.error(msg);
                setDeleteExecutionsLoading(false);
              });
          },
        },
      ]}
      toolbar={() => (
        <div className="clearfix">
          <ButtonGroup style={{ marginRight: '5px' }}>
            <Button
              size="xs"
              variant="primary"
              active={!showCompletedExecutions && !showOnlyStuckExecutions}
              onClick={() => {
                setShowCompletedExecutions(false);
                setShowOnlyStuckExecutions(false);
              }}
            >
              Active
            </Button>
            <Button
              size="xs"
              variant="primary"
              active={showOnlyStuckExecutions}
              onClick={() => setShowOnlyStuckExecutions(true)}
            >
              Stuck
            </Button>
            <Button
              size="xs"
              variant="primary"
              active={showCompletedExecutions && !showOnlyStuckExecutions}
              onClick={() => {
                setShowCompletedExecutions(true);
                setShowOnlyStuckExecutions(false);
              }}
            >
              All Executions
            </Button>
          </ButtonGroup>
          <ButtonGroup style={{ marginRight: '5px' }}>
            <Button
              size="xs"
              variant="primary"
              active={!showOnlyParentExecutions}
              onClick={() => setShowOnlyParentExecutions(false)}
            >
              All
            </Button>
            <Button
              size="xs"
              variant="primary"
              active={showOnlyParentExecutions}
              onClick={() => setShowOnlyParentExecutions(true)}
            >
              Only Parent Executions
            </Button>
          </ButtonGroup>
        </div>
      )}
      onFetchData={(newPageState) => {
        if (executionsLoading) {
          return;
        }

        setPageState({ ...pageState, ...newPageState });
      }}
      loading={executionsLoading || deleteExecutionsLoading}
      totalCount={executions ? executions.totalItemCount : 0}
      columns={[
        {
          key: 'progress',
          display: 'Progress',
          type: 'percent',
          filterable: false,
          width: 100,
        },
        { key: 'execution', display: '#', width: 50 },
        ...(processId === null
          ? [
              {
                key: 'process.name',
                display: 'Process',
                width: 350,
                type: 'withFormatter',
                csvFormatter: (item) => get(item, 'process.name'),
                formatter: (row) => {
                  const { original } = row;
                  const currentExecutionStep =
                    getCurrentExecutionStep(original);

                  const currentProcessStep =
                    currentExecutionStep &&
                    getProcessStepFromExecutionStep(
                      original,
                      currentExecutionStep,
                    );

                  return (
                    <LinkContainer
                      to={{
                        pathname: `/processes/${original.process._id}/executions/${original._id}`,
                      }}
                    >
                      <Button size="xsmall" variant="link">
                        {original.process.name}
                        {currentProcessStep && ` (${currentProcessStep.name})`}
                      </Button>
                    </LinkContainer>
                  );
                },
              },
            ]
          : [
              {
                key: 'process.name',
                display: 'Current Step',
                width: 200,
                type: 'withFormatter',
                csvFormatter: (item) => get(item, 'process.name'),
                formatter: (row) => {
                  const { original } = row;
                  const currentExecutionStep =
                    getCurrentExecutionStep(original);

                  const currentProcessStep =
                    currentExecutionStep &&
                    getProcessStepFromExecutionStep(
                      original,
                      currentExecutionStep,
                    );

                  return (
                    <LinkContainer
                      to={{
                        pathname: `/processes/${original.process._id}/executions/${original._id}`,
                      }}
                    >
                      <Button size="xsmall" variant="link">
                        {currentProcessStep
                          ? ` (${currentProcessStep.name})`
                          : 'Finished'}
                      </Button>
                    </LinkContainer>
                  );
                },
              },
            ]),
        { key: 'dateUpdated', display: 'Date Updated', type: 'timeago' },
        { key: 'dateCreated', display: 'Date Created', type: 'timeago' },
        {
          key: 'duration',
          display: 'Duration',
          width: 125,
          formatter: (row) =>
            formatDuration(
              new Date(row.original.dateUpdated) -
                new Date(row.original.dateCreated),
            ),
        },
        {
          id: 'actions',
          display: 'Actions',
          width: 65,
          formatter: (row) => {
            const { original: execution } = row;

            return (
              <ExecutionButtons
                execution={execution}
                onDelete={executionReloadFn}
              />
            );
          },
        },
      ]}
    />
  );
};

ShowProcessExecutionTable.propTypes = {
  processId: PropTypes.string,
  currentUser: PropTypes.shape().isRequired,
  vaultStructure: PropTypes.string,
  vaultEntry: PropTypes.string,
};

ShowProcessExecutionTable.defaultProps = {
  processId: null,
  vaultStructure: null,
  vaultEntry: null,
};

export default ShowProcessExecutionTable;
