import get from 'lodash/get';
import { historyPush } from '../../history';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Loading } from 'lib/acromyrmex';
import { bindActionCreators } from 'redux';
import { Col, Row, Alert, Button } from '../../utility/UiComponents';
import ContuitAlert from '../shared/Alert';
import EnableIntegrationFormRow from './EnableIntegrationFormRow';
import request from '../../utility/request';
import { loadEnabledIntegrations as loadEnabledIntegrationsAction } from '../../actions/enabledIntegrations';
import { loadAvailableIntegrations as loadAvailableIntegrationsAction } from '../../actions/integrations';

class ImportCommunityIntegrations extends Component {
  static groupIntegrations(integrations) {
    const outputMap = integrations.reduce((map, item) => {
      if (!map[item.slug]) {
        map[item.slug] = { slug: item.slug, integrations: [] };
      }
      map[item.slug].integrations.push({
        integrationId: item.integrationId,
        integrationName: item.integrationName
      });
      return map;
    }, {});

    const transformedArray = Object.values(outputMap);
    return transformedArray;
  }

  static propTypes = {
    // from redux
    loadAvailableIntegrations: PropTypes.func.isRequired,
    loadEnabledIntegrations: PropTypes.func.isRequired,
    addedIntegrations: PropTypes.arrayOf(PropTypes.shape()).isRequired,

    currentUser: PropTypes.shape().isRequired,
    onIntegrationEnabled: PropTypes.func.isRequired,
    solutionGroup: PropTypes.shape().isRequired,
    availableIntegrations: PropTypes.arrayOf(PropTypes.shape()),
    availableIntegrationsLoading: PropTypes.bool,
    duplicateProcessAction: PropTypes.string
  };

  static defaultProps = {
    availableIntegrations: [],
    availableIntegrationsLoading: false,
    duplicateProcessAction: ''
  };

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: ''
    };
  }

  componentDidMount() {
    const { loadAvailableIntegrations, loadEnabledIntegrations } = this.props;

    loadAvailableIntegrations();
    loadEnabledIntegrations();
  }

  onEditFormSubmit = (enabledIntegration, bundleIntegrationId) => {
    const { onIntegrationEnabled, loadEnabledIntegrations } = this.props;
    loadEnabledIntegrations();
    onIntegrationEnabled(enabledIntegration, bundleIntegrationId);
  };

  // TODO: this should be in ShowAssistedImport
  onContinueClicked = () => {
    const { solutionGroup } = this.props;
    const {
      addedIntegrations,
      currentUser: { token },
      duplicateProcessAction
    } = this.props;

    this.setState({ loading: true, error: '' });
    request
      .post('/api/processes/import-defaults')
      .set('x-access-token', token)
      .send({
        integrationTranslations: addedIntegrations,
        project: solutionGroup,
        duplicateProcessAction
      })
      .then(({ body }) => {
        const { solutionGroup: createdGroup } = body;
        if (createdGroup) {
          historyPush(`/solutions/${createdGroup._id}`);
        } else {
          historyPush(`/processes`);
        }
      })
      .catch(e => {
        
        const error = get(e, 'response.body.message', e.message);
        return this.setState({ error: `Error: ${error}` });
      })
      .then(() => this.setState({ loading: false }));
  };

  render() {
    const {
      availableIntegrations,
      availableIntegrationsLoading,
      solutionGroup,
      addedIntegrations
    } = this.props;

    const { error, loading } = this.state;

    if (availableIntegrationsLoading || availableIntegrations.length === 0) {
      return (
        <div style={{ height: '100%' }}>
          <Row className="content-row">
            <Col xs={12}>
              <Loading text="Loading Required Integrations" />
            </Col>
          </Row>
        </div>
      );
    }

    // older bundles has integrations, newer bundles has enabledIntegrations
    // we have some conditions to make the import work for all versions
    const isMultipleIntegrations = solutionGroup.integrations.every(
      item => typeof item === 'object'
    );
    const integrations = isMultipleIntegrations
      ? solutionGroup.integrations
      : solutionGroup.integrations.map(i => ({
          slug: i,
          integrationId: null,
          integrationName: null
        }));

    const incompleteIntegrations = integrations.filter(
      integration =>
        !addedIntegrations.find(
          i =>
            i.importKey === integration.slug &&
            (!i.bundleIntegrationId || i.bundleIntegrationId === integration.integrationId)
        )
    );

    const addedIntegrationsCount = addedIntegrations.length;
    const totalIntegrationsCount = integrations.length;

    return (
      <div style={{ height: '100%' }}>
        <Row className="content-row">
          <Col xs={12} sm={8}>
            <p style={{ marginBottom: 0, marginTop: '5px' }}>
              In order for these solutions to work, you first need to enable the required
              integrations. For each integration, find instructions on how to configure them to the
              left. For more details the link at the bottom of each section will bring you to the
              full documentation page including pictures. For support, contact{' '}
              <a href="mailto:support@contuit.com">support@contuit.com</a>.
            </p>
          </Col>
          <Col xs={12} sm={4}>
            {addedIntegrationsCount < totalIntegrationsCount && (
              <h4 className="pull-right">
                {addedIntegrationsCount} of {totalIntegrationsCount} Complete
              </h4>
            )}
            {error && <ContuitAlert danger message={error} />}
            {addedIntegrationsCount >= totalIntegrationsCount && (
              <Alert variant="success" className="pull-left indented" style={{ marginBottom: 0 }}>
                <i className="fa fa-check" />
                All integrations have been added
                <Button
                  variant="success"
                  size="xs"
                  style={{ marginLeft: '15px' }}
                  onClick={this.onContinueClicked}
                  disabled={loading}
                  autoFocus
                >
                  {loading ? <Loading text="Importing" /> : 'Continue'}
                </Button>
              </Alert>
            )}
          </Col>
        </Row>
        {ImportCommunityIntegrations.groupIntegrations(incompleteIntegrations).map(
          ({ slug, integrations: groupedIntegrations }) => (
            <EnableIntegrationFormRow
              key={slug}
              onEditFormSubmit={this.onEditFormSubmit}
              availableIntegration={availableIntegrations.find(
                i => i['x-integration-slug'] === slug
              )}
              groupedIntegrations={groupedIntegrations}
            />
          )
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.users.currentUser,
  availableIntegrations: state.integrations.availableIntegrations.rows,
  availableIntegrationsLoading: state.integrations.availableIntegration.isLoading
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      loadAvailableIntegrations: loadAvailableIntegrationsAction,
      loadEnabledIntegrations: loadEnabledIntegrationsAction
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ImportCommunityIntegrations);
