import React, { Component } from 'react';
import PageLayout from '../shared/Layout/PageLayout';
import ShowApiHelp from '../help/sections/ShowApiHelp';

class ShowDocsRestApi extends Component {
  static propTypes = {};

  static defaultProps = {};

  state = {};

  render() {
    return <PageLayout header="REST API" content={<ShowApiHelp />} />;
  }
}

export default ShowDocsRestApi;
