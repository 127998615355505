"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _superagent = _interopRequireDefault(require("superagent"));
class ContuitClientServer {
  static getContuitClients(serverUri, _ref) {
    var {
      token,
      ids = []
    } = _ref;
    var request = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _superagent.default;
    if (!token) {
      throw new Error('ContuitClientServer.getContuitClients requires a token');
    }
    var query = ids.length < 1 ? {} : {
      _id: ids
    };
    return request.get("".concat(serverUri, "/")).query(query).set('x-access-token', token).then(res => res.body).catch(err => {
      throw new Error("Error fetching tenants: ".concat(err));
    });
  }
  static getContuitClient(serverUri, _ref2) {
    var {
      token,
      contuitClientId
    } = _ref2;
    var request = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _superagent.default;
    if (!token) {
      throw new Error('ContuitClientServer.getContuitClient requires a token');
    }
    if (!contuitClientId) {
      throw new Error('ContuitClientServer.getContuitClient requires a contuitClientId');
    }
    return request.get("".concat(serverUri, "/").concat(contuitClientId)).set('x-access-token', token).then(res => res.body);
  }
  static deleteContuitClient(serverUri, _ref3) {
    var {
      token,
      contuitClientId
    } = _ref3;
    var request = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _superagent.default;
    if (!contuitClientId) {
      throw new Error('ContuitClientServer.deleteContuitClient requires a contuitClientId');
    }
    if (!token) {
      throw new Error('ContuitClientServer.deleteContuitClient requires a token');
    }
    return request.delete("".concat(serverUri, "/").concat(contuitClientId)).set('x-access-token', token).then(res => res.body);
  }
  static createContuitClient(serverUri, _ref4) {
    var {
      token,
      contuitClient
    } = _ref4;
    var request = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _superagent.default;
    if (!contuitClient) {
      throw new Error('ContuitClientServer.createContuitClient requires a contuitClient');
    }
    if (!token) {
      throw new Error('ContuitClientServer.createContuitClient requires a token');
    }
    return request.post("".concat(serverUri, "/")).send(contuitClient).set('x-access-token', token).then(res => res.body);
  }
  static updateContuitClient(serverUri, _ref5) {
    var {
      token,
      contuitClient,
      contuitClientId
    } = _ref5;
    var request = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _superagent.default;
    if (!contuitClient) {
      throw new Error('ContuitClientServer.updateContuitClient requires a contuitClient');
    }
    if (!contuitClientId) {
      throw new Error('ContuitClientServer.updateContuitClient requires a contuitClientId');
    }
    if (!token) {
      throw new Error('ContuitClientServer.updateContuitClient requires a token');
    }
    return request.put("".concat(serverUri, "/").concat(contuitClientId)).send(contuitClient).set('x-access-token', token).then(res => res.body);
  }
  static verifyOnboardingEmail(serverUri, _ref6) {
    var {
      email
    } = _ref6;
    var request = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _superagent.default;
    if (!email) {
      throw new Error('ContuitClientServer.verifyOnboardingEmail requires an email');
    }
    return request.post("".concat(serverUri, "/onboard/email")).send({
      email
    }).then(res => res.body);
  }
  static verifyOnboardingCode(serverUri, _ref7) {
    var {
      code,
      email
    } = _ref7;
    var request = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _superagent.default;
    if (!email) {
      throw new Error('ContuitClientServer.verifyOnboardingCode requires an email');
    }
    if (!code) {
      throw new Error('ContuitClientServer.verifyOnboardingCode requires an code');
    }
    return request.post("".concat(serverUri, "/onboard/code")).send({
      code,
      email
    }).then(res => res.body);
  }
  static createFirstUser(serverUri, _ref8) {
    var {
      user,
      contuitClient
    } = _ref8;
    var request = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _superagent.default;
    if (!user) {
      throw new Error('ContuitClientServer.createFirstUser requires an user');
    }
    if (!contuitClient) {
      throw new Error('ContuitClientServer.createFirstUser requires an contuitClient');
    }
    return request.post("".concat(serverUri, "/onboard/user")).send({
      user,
      contuitClient
    }).then(res => res.body);
  }
  constructor(serverUri) {
    var request = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : _superagent.default;
    this._serverUri = serverUri;
    this._request = request;
  }
  getContuitClients() {
    var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return ContuitClientServer.getContuitClients(this._serverUri, options, this._request);
  }
  getContuitClient() {
    var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return ContuitClientServer.getContuitClient(this._serverUri, options, this._request);
  }
  deleteContuitClient(options) {
    return ContuitClientServer.deleteContuitClient(this._serverUri, options, this._request);
  }
  createContuitClient(options) {
    return ContuitClientServer.createContuitClient(this._serverUri, options, this._request);
  }
  updateContuitClient(options) {
    return ContuitClientServer.updateContuitClient(this._serverUri, options, this._request);
  }
  verifyOnboardingEmail(options) {
    return ContuitClientServer.verifyOnboardingEmail(this._serverUri, options, this._request);
  }
  verifyOnboardingCode(options) {
    return ContuitClientServer.verifyOnboardingCode(this._serverUri, options, this._request);
  }
  createFirstUser(options) {
    return ContuitClientServer.createFirstUser(this._serverUri, options, this._request);
  }
}
var _default = exports.default = ContuitClientServer;