import _ from 'underscore';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { SelectInput, TemplateInput, Icon } from 'lib/acromyrmex';
import { Field } from 'redux-form';
import { Row, Col, Button } from '../../../utility/UiComponents';
import { requiredValidator } from '../../../utility/formValidators';
import PatchOption from '../steps/components/stepOptions/integration/PatchOption';
import { filterCollections } from '../../../utility/ProcessFormOptionHelpers';

const numberOptions = [
  { id: '0', name: '=' },
  { id: '1', name: '<' },
  { id: '2', name: '>' },
  { id: '3', name: '<=' },
  { id: '4', name: '>=' }
];

const stringOptions = [
  { id: '0', name: 'equals' },
  { id: '1', name: 'starts w/' },
  { id: '2', name: 'ends w/' },
  { id: '3', name: 'contains' }
];

const dateOptions = [
  { id: '1', name: '<' },
  { id: '2', name: '>' },
  { id: '3', name: '<=' },
  { id: '4', name: '>=' }
];

// eslint-disable-next-line react/prefer-stateless-function
class ApiParameter extends Component {
  render() {
    const { change, outputOptions, fields, index, fieldText, paramOptions } = this.props;

    const name = `${fieldText}.value`;
    const param = fields.get(index);
    const usedNames = [];
    fields.forEach((n, i, l) => {
      const obj = l.get(i);
      if (i < index) {
        usedNames.push(obj.name);
      }
    });

    // `selection` holds the parameter option object passed to the select input
    // for example: {id: "OrgId", name: "OrgId", filter: true}
    const selection = paramOptions.find(o => o.id === param.name);

    const optionRenderer = option => (
      <span>
        {option.filter && <Icon filter />}
        {option.filter && ' '}
        {option.label}
      </span>
    );
    const req = _.isString(param.required) ? param.required === 'true' : param.required;

    if (selection && selection.patch) {
      return (
        <PatchOption
          key={fieldText}
          optionRenderer={optionRenderer}
          paramOptions={paramOptions}
          outputOptions={outputOptions}
          usedNames={usedNames}
          field={fieldText}
          req={req}
          fields={fields}
          index={index}
          selection={selection}
          change={change}
        />
      );
    }

    

    let options = stringOptions;
    if (selection && selection.attrType === 'integer') {
      options = numberOptions;
    } else if (selection && selection.attrType === 'date-time') {
      options = dateOptions;
    }

    return (
      <Row style={{ marginBottom: 10 }} key={fieldText}>
        <Col xs={5}>
          <Field
            name={`${fieldText}.name`}
            component={SelectInput}
            options={paramOptions.filter(o => !usedNames.includes(o.name))}
            optionRenderer={optionRenderer}
            valueRenderer={optionRenderer}
            noLabel
            label="Label"
            disabled={req}
            validate={requiredValidator}
          />
        </Col>
        <Col xs={6}>
          {selection && selection.filter && (
            <div className="select-addon-before">
              <Field
                name={name}
                component={TemplateInput}
                noLabel
                label="Value"
                options={filterCollections(outputOptions)}
                onTemplateClicked={option => {
                  change(name, option.textValue);
                }}
                plainText
                addonCustomBefore={
                  <Field
                    name={`${fieldText}.type`}
                    component={SelectInput}
                    label="Type of filter"
                    options={options}
                    addon
                  />
                }
                type="text"
              />
            </div>
          )}
          {(!selection || !selection.filter) && (
            <Field
              name={name}
              component={TemplateInput}
              noLabel
              plainText
              label="Value"
              options={filterCollections(outputOptions)}
              validate={req && requiredValidator}
              onTemplateClicked={option => {
                change(name, option.textValue);
              }}
            />
          )}
        </Col>
        <Col xs={1}>
          {!req && (
            <Button
              variant="danger"
              onClick={() => fields.remove(index)}
              title="Remove this parameter"
            >
              <i className="fa fa-trash" />
            </Button>
          )}
        </Col>
      </Row>
    );
  }
}

ApiParameter.propTypes = {
  fieldText: PropTypes.string.isRequired,
  fields: PropTypes.shape().isRequired,
  outputOptions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  paramOptions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  change: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired
};

export default ApiParameter;

// for use elsewhere
export { numberOptions, stringOptions };
