import _ from 'underscore';
import React from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Field, FieldArray } from 'redux-form';
import { SelectInput, TemplateInput, Loading } from 'lib/acromyrmex';
import PropTypes from 'prop-types';
// import { requiredValidator } from '../../../../../utility/formValidators';
import { Row, Col,Accordion,Button } from '../../../../../utility/UiComponents';
import StructureAttributeSelectInput from '../../../../shared/form/StructureAttributeSelectInput';
import { stringOptions as apiStringOptions } from '../../../ProcessForm/ApiParameter';
import AddButton from '../../../../shared/form/AddButton';
import UiAttributeHelper from '../../../../vaultStructures/attributes/UiAttributeHelper';

const stringOptions = [
  ...(apiStringOptions || []),
  { id: '6', name: 'does not contain' },
  { id: '4', name: 'not equals' },
  { id: '5', name: 'is null' },
  { id: '7', name: 'is not null' }
];

// eslint-disable-next-line
class VaultQueryStepOptions extends React.Component {
  static renderEditField(
    outputOptions,
    member,
    selectedAttribute,
    includeAddon = true,
    name = 'query'
  ) {
    const optionsToUse = outputOptions.filter(
      o =>
        o.type === selectedAttribute.type ||
        UiAttributeHelper.extendedTypeMatches(selectedAttribute.type, o)
    );

    return (
      <div className={classnames({ 'select-addon-before': includeAddon })}>
        <Field
          name={`${member}.${name}`}
          component={TemplateInput}
          noLabel
          label="Value"
          options={optionsToUse}
          addonCustomBefore={
            includeAddon ? (
              <div>
              <Field
                name={`${member}.queryType`}
                component={SelectInput}
                label="Type of filter"
                options={stringOptions}
                addon
              />
              </div>
            ) : (
              undefined
            )
          }
          type="text"
        />
      </div>
    );
    // nothing
  }

  constructor(props) {
    super(props);

    this.renderQueryFields = this.renderQueryFields.bind(this);
  }

  renderEditField(member, selectedAttribute) {
    const { outputOptions } = this.props;
    return VaultQueryStepOptions.renderEditField(outputOptions, member, selectedAttribute);
  }

  renderQueryField(member, fields, index, struct) {
    const { processStep, change } = this.props;
    const field = fields.get(index);

    if (!struct) {
      return <Loading key={member} />;
    }

    const selectedAttribute = struct.attributes.find(a => a.key === field.attribute);

    return (
      <Row style={{ marginBottom: 10 }} key={member}>
        <Col xs={5}>
          <Field
            name={`${member}.attribute`}
            label="Attribute"
            component={StructureAttributeSelectInput}
            slug={processStep.stepOptions.vaultStructure}
            onChange={() => {
              // reset the query when we change types
              change(`${member}.query`, '');
            }}
            noLabel
          />
        </Col>
        <Col xs={6}>
          {!selectedAttribute && <Loading />}
          {selectedAttribute && this.renderEditField(member, selectedAttribute)}
        </Col>
        <Col xs={1}>
          <Button
            variant="danger"
            onClick={() => fields.remove(index)}
            title="Remove this parameter"
          >
            <i className="fa fa-trash" />
          </Button>
        </Col>
      </Row>
    );
  }

  renderQueryFields({ fields }) {
    const { structureList, processStep } = this.props;
    const struct = structureList.find(s => s.slug === processStep.stepOptions.vaultStructure);
    const rows = fields.map((member, index) =>
      this.renderQueryField(member, fields, index, struct)
    );

    return (
      <div>
        {rows}
        <Row className="step-option-buttons">
          <Col xs={12}>
            <AddButton size="sm"
              onClick={() => {
                fields.push({});
              }}
              label="Add Field"
            />
          </Col>
        </Row>
      </div>
    );
  }

  render() {
    const {
      fieldText,
      processStep,
      structureList,
      onChangeAttributeNeeded,
      outputOptions,
      outputOptionsLoaded
    } = this.props;

    const {
      stepOptions: { vaultStructure, multi }
    } = processStep;

    const handleAttributeChange = key => (a, value) => {
      onChangeAttributeNeeded({
        ...processStep,
        stepOptions: {
          ...processStep.stepOptions,
          [key]: value
        }
      });
    };

    return (
      <Row key={fieldText} style={{ marginBottom: 10 }}>
          <Accordion defaultActiveKey="1">
          <Accordion.Item eventKey="1">
            <Accordion.Header>{`${processStep.name || ''} Step Options`}</Accordion.Header>
            <Accordion.Body>
            <Row style={{ marginBottom: 10 }}>
              <Col lg={4} xs={12}>
                <div className="select-addon-after">
                  <Field
                    vertical
                    name={`${fieldText}.stepOptions.vaultStructure`}
                    component={SelectInput}
                    label="Structure"
                    options={_.map(structureList, struct => ({
                      id: struct.slug,
                      name: struct.display
                    }))}
                    onChange={handleAttributeChange('vaultStructure')}
                    addonAfter={
                      vaultStructure && (
                        <Link
                          to={{
                            pathname: `/vault/${vaultStructure}`
                          }}
                          target="_blank"
                        >
                          <i className="fa fa-external-link" />
                        </Link>
                      )
                    }
                  />
                </div>
              </Col>
              <Col lg={4} xs={12}>
                <Field
                  vertical
                  name={`${fieldText}.stepOptions.condition`}
                  component={SelectInput}
                  label="And/Or"
                  options={[
                    { id: 'and', name: 'And' },
                    { id: 'or', name: 'Or' }
                  ]}
                  onChange={handleAttributeChange('condition')}
                />
              </Col>
              <Col lg={4} xs={12}>
                <Field
                  vertical
                  name={`${fieldText}.stepOptions.multi`}
                  component={SelectInput}
                  label="Selection amount"
                  options={[
                    { id: 'multi', name: 'Multiple' },
                    { id: 'single', name: 'Single' }
                  ]}
                  onChange={(a, value) =>
                    onChangeAttributeNeeded({
                      ...processStep,
                      stepOptions: {
                        ...processStep.stepOptions,
                        multi: value
                      }
                    })
                  }
                />
              </Col>
              {multi === 'multi' && [
                <Col xs={6}>
                  <Field
                    name={`${fieldText}.stepOptions.currentPage`}
                    component={TemplateInput}
                    options={outputOptions}
                    // type="number"
                    help="Defaults to 1. Which set of entries to retrieve."
                    label="Current Page"
                    initialValue="1"
                  />
                </Col>,
                <Col xs={6}>
                  <Field
                    name={`${fieldText}.stepOptions.pageSize`}
                    component={TemplateInput}
                    options={outputOptions}
                    help="Defaults to -1 which will attempt to fetch all."
                    // type="number"
                    placeholder={-1}
                    label="Page Size"
                  />
                </Col>
              ]}
            </Row>
            <Row style={{ marginBottom: 10 }}>
              <Col xs={5}>
                <strong>Attribute</strong>
              </Col>
              <Col xs={6}>
                <strong>Query</strong>
              </Col>
            </Row>
            {outputOptionsLoaded && (
              <FieldArray
                name={`${fieldText}.stepOptions.queryFields`}
                component={this.renderQueryFields}
                structureList={structureList}
                props={{ outputOptions }}
              />
            )}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Row>
    );
  }
}

// Define property types
VaultQueryStepOptions.propTypes = {
  fieldText: PropTypes.string.isRequired,
  processStep: PropTypes.shape().isRequired,
  onChangeAttributeNeeded: PropTypes.func.isRequired,
  outputOptions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  change: PropTypes.func.isRequired,
  outputOptionsLoaded: PropTypes.bool.isRequired,

  // specific to this step type
  structureList: PropTypes.arrayOf(PropTypes.shape()).isRequired
};

VaultQueryStepOptions.defaultProps = {};

const mapStateToProps = state => ({
  structureList: state.vault.structureList.rows
});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export { stringOptions };
export default connect(mapStateToProps, mapDispatchToProps)(VaultQueryStepOptions);
