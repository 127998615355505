import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { getFormValues } from 'redux-form';
import { Loading } from 'lib/acromyrmex';
import VaultEntryForm from './VaultEntryForm';
import {
  loadVaultStructure,
  createEntry,
} from '../../actions/vault';
import PageLayout from '../shared/Layout/PageLayout';

function EditVaultEntry({ entry }) {
  const dispatch = useDispatch();
  const { vaultStructureId: slug } = useParams();
  const formValues = useSelector((state) => getFormValues('vaultEntryForm')(state)) || {};
  const struct = useSelector((state) => state.vault.structures[slug]);
  const isLoading = useSelector(
    (state) => (state.vault.data[slug] ? state.vault.data[slug].isLoading : false),
  );

  useEffect(() => {
    dispatch(loadVaultStructure(slug, true));
  }, []);

  const onFormSubmit = () => {
    dispatch(createEntry(slug, formValues));
  };

  return (
    <PageLayout
      header={`Create new ${struct && struct.singular ? struct.singular : '...'}`}
      noContentPadding
      content={
          isLoading ? (
            <Loading />
          ) : (
            <VaultEntryForm slug={slug} initialValues={entry} onSubmit={onFormSubmit} />
          )
      }
    />
  );
}

// Define property types
EditVaultEntry.propTypes = {
  entry: PropTypes.shape(),
};

EditVaultEntry.defaultProps = {
  entry: null,
};

export default EditVaultEntry;
