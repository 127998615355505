import React, { Component } from 'react';

class BlockBeginStepAutomating extends Component {
  static propTypes = {};

  static defaultProps = {};

  state = {};

  render() {
    return <div>It is currently held up by another block running simultaneously</div>;
  }
}

export default BlockBeginStepAutomating;
