// import _ from 'underscore';
import React from 'react'
import PropTypes from 'prop-types'
import {
  Row,
  Col,
  Button,
  Modal,
  Nav,
  NavItem,
} from '../../../utility/UiComponents'
import BasicSearch from '../../shared/form/BasicSearch'
import IntegrationApiDetails from '../../shared/IntegrationApiDetails'

class StepTypeModal extends React.Component {
  static getFilteredOptions(options, activeIntegrationKey, searchValue) {
    return options
      .filter(
        (option) =>
          activeIntegrationKey === 'All Integrations' ||
          activeIntegrationKey === option.brand,
      )
      .filter(
        (option) =>
          option.name.toLowerCase().includes(searchValue.toLowerCase()) ||
          option.brand.toLowerCase().includes(searchValue.toLowerCase()),
      )
  }

  static getFirstOptionId(filteredOptions) {
    return !filteredOptions || filteredOptions.length < 1
      ? ''
      : filteredOptions[0].id
  }

  constructor(props) {
    super(props)

    this.state = {
      activeIntegrationKey: 'All Integrations',
      activeStepTypeKey: 'basic',
      searchValue: '',
      filteredOptions: props.options,
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // Called when modal opens and closes
    const { options } = this.props

    if (options !== nextProps.options) {
      this.setState({ filteredOptions: nextProps.options })
    }
  }

  getSelectedOption() {
    // Returns a 'standardized' object of the selected step type
    const { options, integrationOptions } = this.props
    const { activeStepTypeKey } = this.state

    // Find option and integration details among lists
    const selectedOption = options.find(
      (option) => option.id === activeStepTypeKey,
    )
    const optionDetails = { parameters: [], filterableParameters: [] }
    const selectedIntOptions = integrationOptions[activeStepTypeKey]

    if (selectedIntOptions) {
      const obj = selectedIntOptions.action
        ? selectedIntOptions.action
        : selectedIntOptions.trigger
      optionDetails.description = obj.description

      // Filterable parameters
      if (obj['x-filterable-parameters']) {
        optionDetails.filterableParameters = obj['x-filterable-parameters'].map(
          (param) => ({
            name: param,
          }),
        )
      }

      // Parameters
      if (obj.parameters) {
        optionDetails.parameters = obj.parameters
          .filter((param) => !param['x-ignore'])
          .map((param) => ({
            name: param.name,
            required: !param.required ? '' : param.required,
            description: !param.description ? '' : param.description,
          }))
      }
    }

    return { ...selectedOption, ...optionDetails }
  }

  disableSubmit() {
    const { activeStepTypeKey } = this.state
    return !activeStepTypeKey || activeStepTypeKey === ''
  }

  handleSelectIntegration(selectedKey) {
    const { options } = this.props
    const { searchValue } = this.state
    const filteredOptions = StepTypeModal.getFilteredOptions(
      options,
      selectedKey,
      searchValue,
    )

    this.setState({
      activeIntegrationKey: selectedKey,
      filteredOptions,
      activeStepTypeKey: StepTypeModal.getFirstOptionId(filteredOptions),
    })
  }

  handleSelectStepType(selectedKey) {
    this.setState({ activeStepTypeKey: selectedKey })
  }

  handleSearchChange(searchValue) {
    const { options } = this.props
    const { activeIntegrationKey } = this.state
    const filteredOptions = StepTypeModal.getFilteredOptions(
      options,
      activeIntegrationKey,
      searchValue,
    )
    searchValue = !searchValue ? '' : searchValue

    this.setState({
      searchValue,
      filteredOptions,
      activeStepTypeKey: StepTypeModal.getFirstOptionId(filteredOptions),
    })
  }

  handleDoubleClick() {
    const { onSubmitStepType } = this.props
    const { activeStepTypeKey } = this.state

    // Just exit the modal since the option is already selected on first click
    onSubmitStepType(activeStepTypeKey)
  }

  handleKeyUp(event) {
    const { onSubmitStepType } = this.props
    const { activeStepTypeKey } = this.state

    // Make sure something is selected before submitting
    if (!this.disableSubmit() && event.key === 'Enter') {
      onSubmitStepType(activeStepTypeKey)
    }
  }

  renderDetails() {
    const selectedOption = this.getSelectedOption()

    return <IntegrationApiDetails integrationApi={selectedOption} />
  }

  renderStepTypes() {
    const { filteredOptions } = this.state

    return filteredOptions.map((option) => (
      <Nav.Item key={option.id} onDoubleClick={() => this.handleDoubleClick()}>
        <Nav.Link eventKey={option.id}>
          {option.brand} - {option.name}
        </Nav.Link>
      </Nav.Item>
    ))
  }

  renderIntegrations() {
    const { options } = this.props

    let brands = options.map((option) => option.brand)
    brands = ['All Integrations', ...new Set(brands)] // Get all unique brand names

    return brands.map((brand) => (
      <Nav.Item key={brand}>
        <Nav.Link eventKey={brand}>{brand}</Nav.Link>
      </Nav.Item>
    ))
  }

  render() {
    const { onCloseModal, onSubmitStepType, showModal } = this.props
    const { activeIntegrationKey, activeStepTypeKey } = this.state

    return (
      <Modal
        show={showModal}
        onHide={onCloseModal}
        size="large"
        className="step-type-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Choose Step Type</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="search-row">
            <Col xs={12}>
              <BasicSearch
                onStateChange={(event) =>
                  this.handleSearchChange(event.target.value)
                }
                placeholder="Search all integration step types"
                onKeyUp={(event) => this.handleKeyUp(event)}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} className="my-3">
              <h1>Integrations</h1>
              <Nav
                variant="pills"
                activeKey={activeIntegrationKey}
                onSelect={(selectedKey) =>
                  this.handleSelectIntegration(selectedKey)
                }
              >
                {this.renderIntegrations()}
              </Nav>
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={6} md={6} className="my-2">
              <h1>Step Types</h1>
              <div className="step-type-col">
                <Nav
                  variant="pills"
                  className="flex-column"
                  activeKey={activeStepTypeKey}
                  onSelect={(selectedKey) =>
                    this.handleSelectStepType(selectedKey)
                  }
                >
                  {this.renderStepTypes()}
                </Nav>
              </div>
            </Col>
            <Col xs={12} sm={6} md={6}>
              <div className="step-details-col">{this.renderDetails()}</div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={onCloseModal}>
            Cancel
          </Button>
          <Button
            variant="success"
            disabled={this.disableSubmit()}
            onClick={() => onSubmitStepType(activeStepTypeKey)}
          >
            Select Type
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

StepTypeModal.propTypes = {
  showModal: PropTypes.bool,
  onSubmitStepType: PropTypes.func,
  onCloseModal: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape()),
  integrationOptions: PropTypes.shape(),
}

StepTypeModal.defaultProps = {
  showModal: false,
  onSubmitStepType: () => {},
  onCloseModal: () => {},
  options: [],
  integrationOptions: {},
}

export default StepTypeModal
