import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ShowDuoJumbotron from './ShowDuoJumbotron';
import { Jumbotron } from '../../utility/UiComponents';

class ShowDuoLayout extends Component {
  render() {
    const {
      browser, explanation, content, banner,
    } = this.props;

    return (
      <div className="duo-onboarding">
        <div className="container">
          <ShowDuoJumbotron />

          {banner && (
            <Jumbotron
              id="duo-jumbotron"
              style={{
                backgroundColor: '#272a2f',
                color: 'white',
                marginBottom: '10px',
                padding: '10px',
                textAlign: 'center',
              }}
            >
              {banner}
            </Jumbotron>
          )}

          <div
            className="content-row"
            style={{
              padding: 0,
              display: 'flex',
              flexDirection: browser.lessThan.medium ? 'column' : 'row',
              borderRadius: '6px',
              overflow: 'hidden',
            }}
          >
            <div
              id="duo-explanation"
              style={{
                backgroundColor: 'rgba(239, 0, 127, 1)',
                color: 'white',
                padding: '30px',
                fontWeight: '400',
                fontSize: '1.1em',
                flexBasis: !content || browser.lessThan.medium ? '100%' : '35%',
              }}
            >
              {explanation}
            </div>
            {content && (
              <div
                className="content"
                style={{
                  flexBasis: browser.lessThan.medium ? '100%' : '65%',
                }}
              >
                {content}
              </div>
            )}
          </div>

          {/* FOOTER */}
          <div
            className="content-row"
            style={{
              borderRadius: '6px',
              backgroundColor: '#272a2f',
              color: 'white',
              marginTop: '10px',
            }}
          >
            Need help getting set up?
            {' '}
            <a href="mailto:msp@duo.com">msp@duo.com</a>
          </div>
        </div>
      </div>
    );
  }
}

ShowDuoLayout.propTypes = {
  browser: PropTypes.shape().isRequired,
  explanation: PropTypes.node.isRequired,
  content: PropTypes.node,
  banner: PropTypes.node,
};

ShowDuoLayout.defaultProps = {
  content: null,
  banner: null,
};

// Define property types
const mapStateToProps = (state) => ({
  currentUser: state.users.currentUser,
  browser: state.browser,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ShowDuoLayout);
