import { useState } from 'react'

function useAsyncAction(asyncFetch, defaultValue) {
  const [response, setResponse] = useState(defaultValue)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)

  const execute = (executeParams) => {
    setLoading(true)
    return asyncFetch(executeParams)
      .then((res) => {
        setResponse(res)

        return res
      })
      .catch((err) => setError(err))
      .then((res) => {
        setLoading(false)
        return res
      })
  }

  return [response, loading, error, execute]
}

export default useAsyncAction
