export const formulaOptions = [
  {
    id: 'add',
    name: 'Add',
    parameters: [
      { name: 'value', required: true },
      {
        name: 'unit',
        required: true,
        options: [
          { id: 'days', name: 'days' },
          { id: 'months', name: 'months' },
          { id: 'years', name: 'years' },
        ],
      },
    ],
  },
  {
    id: 'subtract',
    name: 'Subtract',
    parameters: [
      { name: 'value', required: true },
      {
        name: 'unit',
        required: true,
        options: [
          { id: 'days', name: 'days' },
          { id: 'months', name: 'months' },
          { id: 'years', name: 'years' },
        ],
      },
    ],
  },
  {
    id: 'format',
    name: 'Format',
    parameters: [
      {
        name: 'format',
        required: true,
        options: [
          { id: 'YYYY-MM-DD', name: 'YYYY-MM-DD' },
          { id: 'MM-DD-YYYY', name: 'MM-DD-YYYY' },
          { id: 'DD-MM-YYYY', name: 'DD-MM-YYYY' },
        ],
      },
    ],
  },
  {
    id: 'greater-than',
    name: 'Greater Than',
    parameters: [{ name: 'date', required: true }],
  },
  {
    id: 'less-than',
    name: 'Less Than',
    parameters: [{ name: 'date', required: true }],
  },
  {
    id: 'equal',
    name: 'Equal',
    parameters: [{ name: 'date', required: true }],
  },
]
