import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { LinkContainer } from 'react-router-bootstrap';
import { bindActionCreators } from 'redux';
import { Loading } from 'lib/acromyrmex';
import { VaultServer } from '@contuit-otf/sdk';
import { Well, Button, Alert } from '../../utility/UiComponents';
import { createExecution as createExecutionAction } from '../../actions/executions';
import request from '../../utility/request';

const vaultServer = new VaultServer('/api/vault', request);

class ShowSolutionTile extends Component {
  static propTypes = {
    currentUser: PropTypes.shape().isRequired,
    solution: PropTypes.shape().isRequired,
    createExecution: PropTypes.func.isRequired,
  };

  static defaultProps = {};

  state = {
    configurationData: null,
    configurationDataLoading: false,
    configurationDataError: '',
  };

  componentDidMount() {
    const {
      solution,
      currentUser: { token, contuitId },
    } = this.props;

    if (!solution.configurationStructure) return;

    this.setState({
      configurationDataLoading: true,
      configurationDataError: '',
    });
    vaultServer
      .loadVaultEntries({
        token,
        contuitId,
        slug: solution.configurationStructure,
      })
      .then(({ items: configurationData }) => {
        this.setState({ configurationData }, this.onconfigurationDataAdded);
      })
      .catch((e) =>
        this.setState({
          configurationDataError: `Unknown error: ${e.message}`,
        }),
      )
      .then(() => this.setState({ configurationDataLoading: false }));
  }

  render() {
    const { solution, createExecution } = this.props;
    const {
      configurationData,
      configurationDataLoading,
      configurationDataError,
    } = this.state;

    return (
      <Well style={{ maxWidth: '300px', float: 'left', marginRight: '10px' }}>
        <h5 style={{ fontWeight: 'bold' }}>{solution.name}</h5>
        {configurationDataLoading && <Loading text="Configurations loading" />}
        {configurationDataError && (
          <Alert danger message={configurationDataError} />
        )}
        {configurationData && (
          <div>
            {configurationData.length ? (
              <p>
                To initiate this solution, please click{' '}
                <strong>initiate</strong> below. For assistance with this
                solution,{' '}
                <a href={solution.documentation}>
                  please see the documentation
                </a>
                .
              </p>
            ) : (
              <p>
                It looks like you haven&apos;t configured this yet. If you are
                ready to do so now, place click <strong>Configure</strong>{' '}
                below. For assistance through the configuration process,{' '}
                <a href={solution.documentation}>
                  please see the documentation
                </a>
                .
              </p>
            )}
            <Button
              variant={configurationData.length ? 'default' : 'primary'}
              style={{ marginRight: '5px' }}
              onClick={() => createExecution(solution.configurationProcess)}
            >
              {configurationData.length ? 'Reconfigure' : 'Configure'}
            </Button>
            {configurationData.length > 0 && (
              <Button
                variant="primary"
                onClick={() => createExecution(solution.initiationProcess)}
              >
                Initiate
              </Button>
            )}
            {configurationData.length > 0 && (
              <LinkContainer
                to={{ pathname: `/processes/${solution.initiationProcess}` }}
              >
                <Button variant="link" size="xs" style={{ marginTop: '5px' }}>
                  View Initiation Process Details
                </Button>
              </LinkContainer>
            )}
          </div>
        )}
      </Well>
    );
  }
}

const mapStateToProps = (state) => ({
  currentUser: state.users.currentUser,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createExecution: createExecutionAction,
    },
    dispatch,
  );
export default connect(mapStateToProps, mapDispatchToProps)(ShowSolutionTile);
