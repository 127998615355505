import React from 'react';
import PropTypes from 'prop-types';
import { LinkContainer } from 'react-router-bootstrap';
import { Jumbotron, Button } from '../../utility/UiComponents';

const EmptyView = props => {
  const { header, content, button } = props;
  return (
    <div className="container">
      <Jumbotron>
        <h2>{header}</h2>
        <p>{content}</p>
        <br />
        {button && (
          <p>
            <LinkContainer to={{ pathname: button.pathname }}>
              <Button variant={button.style ? button.style : 'primary'}>
                {button.icon && <i className={button.icon} />} {button.content}
              </Button>
            </LinkContainer>
          </p>
        )}
      </Jumbotron>
    </div>
  );
};

EmptyView.propTypes = {
  header: PropTypes.string,
  content: PropTypes.string,
  button: PropTypes.shape()
};

EmptyView.defaultProps = {
  header: 'There is nothing here.',
  content: '',
  button: null
};

export default EmptyView;
