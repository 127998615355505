/* eslint-disable react/prop-types */
import PropTypes from 'prop-types';
import { ProcessServer } from '@contuit-otf/sdk';
import { toast } from 'react-toastify';
import { Icon } from 'lib/acromyrmex';
import { React, connect, bindActionCreators } from '../../utility/ReactStuff';
import PageLayout from '../shared/Layout/PageLayout';
import DataTable from '../shared/DataTable/DataTable';
import request from '../../utility/request';
import superagent from 'superagent';
import SchedulesButtons from './SchedulesButtons';
import { SCHEDULER_SERVER } from '../../constants';

const processServer = new ProcessServer('/api/processes', request);

class ListSchedules extends React.Component {
  constructor() {
    super();

    this.state = {
      loading: true,
      error: '',
      schedules: [],
      isDeleting: false,
      isUpdating: false,
    };
  }

  componentDidMount() {
    document.title = 'Contuit | List Schedules';

    this.loadSchedules();
  }

  componentWillUnmount() {
    document.title = 'Contuit';
  }

  loadSchedules = async () => {
    try {
      const {
        currentUser: { token },
      } = this.props;

      this.setState({ loading: true });

      const { body: data } = await superagent
        .get(SCHEDULER_SERVER)
        .set('x-access-token', token);

      const filteredData = data.filter((job) => job.data && job.data.processId);
      const processes = await processServer.findProcesses({
        token,
        query: {
          processId: [
            ...new Set(
              filteredData.map(({ data: { processId } }) => processId),
            ),
          ],
        },
      });
      const processesMap = processes.reduce((acc, v) => {
        acc[v._id] = v;
        return acc;
      }, {});

      this.setState({
        schedules: filteredData.map((schedule) => {
          const {
            _id,
            nextRunAt,
            repeatInterval,
            data: { processId },
          } = schedule;
          return {
            _id,
            nextRunAt,
            repeatInterval: repeatInterval || 'once',
            process: processesMap[processId] || {},
            originalSchedule: schedule,
          };
        }),
      });
    } catch (err) {
      this.setState({ error: 'Unknown error loading schedules.' });
    } finally {
      this.setState({ loading: false });
    }
  };

  handleError = (err, customMsg) => {
    const {
      status,
      response: { body },
    } = err;

    const msg =
      status === 500 ? 'Unknown error, please try again later.' : body.message;
    toast.error(`${customMsg} ${msg}`);
  };

  deleteSchedule = async (id) => {
    const {
      currentUser: { token },
    } = this.props;

    try {
      this.setState({ isDeleting: true });
      await superagent
        .delete(`${SCHEDULER_SERVER}/${id}`)
        .set({ 'x-access-token': token });
    } catch (err) {
      return this.handleError(err, 'Deleting schedule failed.');
    } finally {
      this.setState({ isDeleting: false });
    }

    this.loadSchedules();
  };

  updateSchedule = async (id, values, process) => {
    const {
      currentUser: { token, _id: userId },
    } = this.props;

    try {
      this.setState({ isUpdating: true });
      await superagent
        .put(`${SCHEDULER_SERVER}/${id}`)
        .send({ ...values, processId: process._id, userId })
        .set({ 'x-access-token': token });
    } catch (err) {
      return this.handleError(err, 'Updating schedule failed.');
    } finally {
      this.setState({ isUpdating: false });
    }

    this.loadSchedules();
  };

  bulkDeleteSchedules = async (schedulesIds) => {
    const {
      currentUser: { token },
    } = this.props;

    try {
      this.setState({ isDeleting: true });
      await superagent
        .delete(SCHEDULER_SERVER)
        .send({ schedulesIds })
        .set({ 'x-access-token': token });
    } catch (err) {
      return this.handleError(err, 'Deleting schedules failed.');
    } finally {
      this.setState({ isDeleting: false });
    }

    this.loadSchedules();
  };

  render() {
    const { routes } = this.props;
    const { schedules, loading, error, isDeleting, isUpdating } = this.state;

    return (
      <PageLayout
        header="Schedules"
        routes={routes}
        headerButtons={[]}
        noContentPadding
        content={
          error ? (
            <h4 style={{ padding: 20 }}>{error}</h4>
          ) : (
            <DataTable
              selectable
              title={false}
              csvTitle="schedules"
              fetchCSVData={() => ({
                items: schedules,
              })}
              data={schedules}
              loading={loading}
              totalCount={schedules.length}
              columns={[
                { key: 'process.name', display: 'Process Name', width: 400 },
                {
                  key: 'repeatInterval',
                  display: 'Repeat Interval',
                  sortMethod: (a, b) => {
                    const frequencyMap = {
                      once: 0,
                      '1 minute': 1,
                      '10 minute': 2,
                      '15 minute': 3,
                      '30 minute': 4,
                      '1 day': 5,
                      '1 week': 6,
                      '1 month': 7,
                    };

                    return frequencyMap[a] - frequencyMap[b];
                  },
                },
                { key: 'nextRunAt', display: 'Next Run At', type: 'date' },
                {
                  id: 'actions',
                  display: 'Actions',
                  formatter: (row) => {
                    const {
                      original: { process, _id, originalSchedule },
                    } = row;

                    return (
                      <SchedulesButtons
                        process={process}
                        schedule={originalSchedule}
                        deleteSchedule={() => this.deleteSchedule(_id)}
                        updateSchedule={(values) =>
                          this.updateSchedule(_id, values, process)
                        }
                        isDeleting={isDeleting}
                        isUpdating={isUpdating}
                      />
                    );
                  },
                },
              ]}
              actions={[
                {
                  key: 'reload',
                  style: 'primary',
                  alwaysEnabled: true,
                  display: (
                    <span>
                      <i className="fa fa-refresh" /> Reload
                    </span>
                  ),
                  onClick: () => this.loadSchedules(),
                },
                {
                  key: 'delete',
                  style: 'danger',
                  display: (
                    <span>
                      <Icon remove /> Delete
                    </span>
                  ),
                  onClick: this.bulkDeleteSchedules,
                },
              ]}
            />
          )
        }
      />
    );
  }
}

ListSchedules.propTypes = {
  currentUser: PropTypes.shape().isRequired,
  routes: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

const mapStateToProps = (state) => ({
  currentUser: state.users.currentUser,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ListSchedules);
