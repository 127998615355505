import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from '../../../utility/UiComponents';
import ComputationFormRow from './ComputationFormRow';
import AddButton from '../form/AddButton';

const ComputationFormList = ({
  fields,
  templateOptions,
  attributeOptions,
  change,
  hideLabel,
  hideCollectionFeatures,
  onChangeAttributeNeeded
}) => {
  const rows = fields.map((attr, index) => (
    <ComputationFormRow
      attr={attr}
      key={attr}
      fields={fields}
      index={index}
      templateOptions={templateOptions}
      attributeOptions={attributeOptions}
      hideCollectionFeatures={hideCollectionFeatures}
      onChangeAttributeNeeded={onChangeAttributeNeeded}
      change={change}
      hideLabel={hideLabel}
    />
  ));

  return (
    <div>
      {rows}
      <Row>
        <Col xs={12}>
          <AddButton size="sm" onClick={() => fields.push({})} label="Add Computation" />
        </Col>
      </Row>
    </div>
  );
};

ComputationFormList.propTypes = {
  fields: PropTypes.shape().isRequired,
  change: PropTypes.func.isRequired,
  hideLabel: PropTypes.bool.isRequired,
  attributeOptions: PropTypes.arrayOf(PropTypes.shape()),
  templateOptions: PropTypes.arrayOf(PropTypes.shape()),
  hideCollectionFeatures: PropTypes.bool,
  onChangeAttributeNeeded: PropTypes.func.isRequired
};

ComputationFormList.defaultProps = {
  hideCollectionFeatures: false,
  templateOptions: [],
  attributeOptions: []
};

export default ComputationFormList;
