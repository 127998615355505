import superagent from 'superagent';
import moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';
import { useAsync } from 'react-use';
import { Loading } from 'lib/acromyrmex';
import { ProcessServer } from '@contuit-otf/sdk';
import request from '../../../utility/request';
import ShowProcessAttribute from './ShowProcessAttribute';
import ExecutionMarkdown from '../ExecutionMarkdown';
import ShowProcessStep from './ShowProcessStep';
import { DATE_FORMAT } from '../../../constants';
import useFetch from '../../../hooks/useFetch';
import ScheduleItem from '../ListProcesses/ScheduleItem';
import { SCHEDULER_SERVER } from '../../../constants';

const processServer = new ProcessServer('/api/processes', request);

const getDurations = (token, processId) =>
  processServer.getAverageStepDurations({ token, id: processId });

const getSchedules = (token, processId) =>
  superagent
    .get(SCHEDULER_SERVER)
    .query({ processId })
    .set({ 'x-access-token': token })
    .then(({ body }) => body);

const ShowProcessSidebar = ({ contuitProcess, currentUser, scheduleCount }) => {
  const { token } = currentUser;
  const durationState = useAsync(
    getDurations.bind(this, token, contuitProcess._id),
  );
  const [schedules, schedulesLoading, schedulesError, scheduleReloadFn] =
    useFetch(
      getSchedules.bind(this, token, contuitProcess._id),
      {},
      [],
      [scheduleCount],
    );

  return [
    <ShowProcessAttribute
      contuitProcess={contuitProcess}
      attribute="status"
      display="Status"
      key="Status"
      formatter={() => {
        if (contuitProcess.isActive) {
          return 'Active';
        }
        if (contuitProcess.isArchived) {
          return 'Archived';
        }
        if (contuitProcess.isDraft) {
          return 'Draft';
        }
        return 'Unknown';
      }}
    />,
    <ShowProcessAttribute
      contuitProcess={contuitProcess}
      key="description"
      attribute="description"
      display="Description"
      formatter={(value) => <ExecutionMarkdown source={value} />}
    />,
    <ShowProcessAttribute
      contuitProcess={contuitProcess}
      key="category"
      attribute="category"
      display="Category"
    />,
    <ShowProcessAttribute
      contuitProcess={contuitProcess}
      key="subCategory"
      attribute="subCategory"
      display="Sub Category"
    />,
    <ShowProcessAttribute
      contuitProcess={contuitProcess}
      key="dateCreated"
      attribute="dateCreated"
      display="Created"
      formatter={(value) => moment(value).format(DATE_FORMAT)}
    />,
    <ShowProcessAttribute
      contuitProcess={contuitProcess}
      key="dateUpdated"
      attribute="dateUpdated"
      display="Updated"
      formatter={(value) => moment(value).format(DATE_FORMAT)}
    />,
    <ShowProcessAttribute
      contuitProcess={contuitProcess}
      key="schedules"
      display="Scheduled Executions"
      formatter={() => (
        <div>
          {schedulesLoading && <Loading text="Loading schedules" />}
          {!schedulesLoading && schedulesError && <div>Error...</div>}
          {!schedulesLoading &&
            !schedulesError &&
            schedules &&
            schedules.length > 0 &&
            schedules.map((schedule) => (
              <ScheduleItem
                schedule={schedule}
                key={schedule._id}
                processItem={contuitProcess}
                loadSchedules={() => scheduleReloadFn()}
                buttonsRight
              />
            ))}
          {!schedulesLoading &&
            !schedulesError &&
            schedules &&
            !schedules.length && <span>Nothing scheduled</span>}
        </div>
      )}
    />,
    <ShowProcessAttribute
      contuitProcess={contuitProcess}
      key="steps"
      attribute="steps"
      display="Steps"
      formatter={(steps) => (
        <div>
          {durationState.loading && <Loading text="Loading step info" />}
          {!durationState.loading && durationState.error && <div>Error...</div>}
          <div style={{ paddingLeft: '20px' }}>
            {!durationState.loading &&
              !durationState.error &&
              steps.map((step, index) => (
                <ShowProcessStep
                  processStep={step}
                  steps={steps}
                  durations={durationState.value}
                  index={index}
                  key={step._id}
                />
              ))}
          </div>
        </div>
      )}
    />,
  ];
};

ShowProcessSidebar.propTypes = {
  contuitProcess: PropTypes.shape().isRequired,
  currentUser: PropTypes.shape().isRequired,
  scheduleCount: PropTypes.number.isRequired,
};

ShowProcessSidebar.defaultProps = {};

export default ShowProcessSidebar;
