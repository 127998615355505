/**
 * The request object is our wrapper for superagent.
 *
 * Currently, its only task is to redirect to login if unauthorized/forbidden
 */
import superagent from 'superagent'
import prefix from 'superagent-prefix'

import { API_BASE_URL } from '../constants'

const request = superagent
  .agent()
  .use(prefix(API_BASE_URL))
  .use((req) => {
    req.on('response', (res) => {
      if (res.status === 401 && !req.url.includes('/api/auth/authenticate')) {
        const message = 'You are no longer logged in.'
        window.location.href = `/login?message=${message}`
      }
    })
  })

export default request
