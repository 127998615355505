import classNames from 'classnames';
import React, { Component } from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import PageLayout from '../shared/Layout/PageLayout';
import { Button } from '../../utility/UiComponents';

class ShowDocs extends Component {
  static propTypes = {};

  static defaultProps = {};

  constructor() {
    super();

    this.state = {
      docTree: [
        {
          title: 'Introduction to Contuit',
          articles: [
            {
              title: 'Process Scheduler',
              path: '/docs/scheduler'
            },
            {
              title: 'REST API',
              path: '/docs/api'
            },
            {
              title: 'Security Policy for SaaS offering',
              path: '/docs/security-policy'
            },
            {
              title: 'SaaS License and Master Services Agreement',
              path: '/docs/saas-license'
            },
            {
              title: 'Privacy Policy',
              path: '/docs/privacy-policy'
            }
          ]
        },
        {
          title: 'Solutions',
          articles: [
            {
              title: 'Duo / Connectwise',
              subTitle: 'Step-by-Step Setup Guide',
              path: '/docs/solutions/duo'
            }
          ]
        },
        {
          title: 'Integrations',
          description: 'Learn how to configure your applications and services to work with contuit',
          articles: [
            {
              image: 'https://duo.com/assets/img/duoLogo-web.png',
              alt: 'duo',
              path: '/docs/solutions/duo'
            },
            {
              image:
                'https://www.connectwise.com/-/media/logos/company/product/manage/manage-horiz-master.ashx',
              alt: 'Connectwise',
              path: '/docs/integrations/connectwise'
            }
          ]
        },
        {
          title: 'Building Processes',
          articles: [
            {
              title: 'Step Conditionals',
              path: '/docs/build/conditional'
            },
            {
              title: 'Computations',
              path: '/docs/build/computation'
            }
          ]
        }
      ]
    };
  }

  componentDidMount() {
    document.title = 'Contuit | Documentation';
  }

  componentWillUnmount() {
    document.title = 'Contuit';
  }

  render() {
    const { docTree } = this.state;

    return (
      <PageLayout
        header="Documentation"
        description="The Contuit platform can help you solve your most time-consuming activities by connecting applications and automating processes. Read below to get started and learn about a few of our pre-built integrations. Keep in mind that these only scratch the surface as the true value will be the integrations that you create!"
        content={
          <div id="docs-content">
            {docTree.map(section => (
              <div className="docs-section">
                <h3>{section.title}</h3>
                {section.description && <p>{section.description}</p>}
                <div>
                  {section.articles.map(article => (
                    <LinkContainer to={{ pathname: article.path }}>
                      <Button
                        className={classNames('docs-link', { 'docs-link-w-img': article.image })}
                        href="#"
                        variant="link"
                      >
                        {article.image && <img src={article.image} alt={article.alt} />}
                        <strong>{article.title}</strong> {article.subTitle}
                      </Button>
                    </LinkContainer>
                  ))}
                </div>
              </div>
            ))}
          </div>
        }
      />
    );
  }
}

export default ShowDocs;
