import React from 'react'
import ObjectId from 'bson-objectid'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Loading } from 'lib/acromyrmex'
import { useParams } from 'react-router';
import ProcessForm from './ProcessForm/ProcessForm'
import { createProcess as createProcessAction } from '../../actions/processes'

class CreateProcess extends React.Component {
  constructor(props) {
    super(props)

    this.onFormSubmit = this.onFormSubmit.bind(this)
    this.hexObjId = ObjectId().toHexString()
  }

  onFormSubmit(options = {}) {
    const { formValues, createProcess } = this.props

    formValues.isDraft = options.isDraft === true
    formValues.isActive = options.isActive === true

    createProcess(formValues)
  }

  render() {
    const { isLoading, routes } = this.props

    if (isLoading) {
      return <Loading />
    }

    return (
      <ProcessForm
        initialValues={{
          _id: this.hexObjId,
          steps: [],
          initiation: {},
        }}
        onSubmit={this.onFormSubmit}
        routes={routes}
      />
    )
  }
}

// Define property types
CreateProcess.propTypes = {
  isLoading: PropTypes.bool,
  createProcess: PropTypes.func.isRequired,
  routes: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  formValues: PropTypes.shape(),
}

CreateProcess.defaultProps = {
  formValues: null,
  isLoading: null,
}

const mapStateToProps = (state) => {
  return {
    processObject: state.processes.process.value,
    isLoading: state.processes.process.isLoading,
    formValues: state.form.processForm ? state.form.processForm.values : {},
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createProcess: createProcessAction,
    },
    dispatch,
  )

const CreateProcessWithState = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreateProcess)

const CreateProcessWrapper = (props) => {
  const { processId } = useParams()

  return <CreateProcessWithState processId={processId} {...props} />
}

export default CreateProcessWrapper
