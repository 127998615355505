import React from 'react';
import PropTypes from 'prop-types';
import { Loading } from 'lib/acromyrmex';
import { Col, Row } from '../../utility/UiComponents';

const UserDetails = ({ user = null }) => {
  if (!user) {
    return <Loading />;
  }

  return (
    <div>
      <Row>
        <h2>{user.name}</h2>
      </Row>
      <Row>
        <Col xs={12}>
          <strong>Email:</strong> {user.email}
        </Col>
        <Col xs={12}>
          <strong>Roles:</strong> {user.userRoles.join(',')}
        </Col>
      </Row>
    </div>
  );
};

UserDetails.propTypes = {
  user: PropTypes.shape({
    name: PropTypes.string
  })
};

UserDetails.defaultProps = {
  user: null
};

export default UserDetails;
