import _ from 'underscore'
import React from 'react'
import classNames from 'classnames'
import {
  TextInput,
  SelectInput,
  CheckboxInput,
  TextAreaInput,
  DateInput,
} from 'lib/acromyrmex'
import { Field, FieldArray } from 'redux-form'
import AttributeHelper from '../../../shared-helpers/AttributeHelper'
import { Col, Row } from '../../../utility/UiComponents'
import ExecutionMarkdown from '../../processes/ExecutionMarkdown'
import Ui from '../../../utility/UiUtility'
import { requiredValidator } from '../../../utility/formValidators'
import FileInput from '../../shared/FileUpload/FileInput'
import { FileThumbnail } from '../../shared/FileUpload/FileThumbnail'

import ListArray from './ListArray'

const booleanEditField = (attr, label, prefix, validation, options) => (
  <Field
    name={attr.key}
    component={CheckboxInput}
    prefix={prefix}
    // getting rid of validate because we don't want to require a true
    // validate={validation}
    help={attr.description}
    label={label}
    key={attr.key}
    {...options}
  />
)

const booleanValueComponent = (value) => {
  const val =
    (_.isString(value) && value === 'true') || (_.isBoolean(value) && value)
  return (
    <i
      className={classNames('fa', {
        'fa-check-square-o': val,
        'fa-square-o': !val,
      })}
    />
  )
}

// Note: missing getRenderComponent defaults to just rendering the value
export const attributeTypes = [
  {
    id: 'string',
    getEditField: (attr, label, prefix, validation, options) => (
      <Field
        name={attr.key}
        component={TextInput}
        prefix={prefix}
        validate={validation}
        help={attr.description}
        label={label}
        key={attr.key}
        {...options}
      />
    ),
  },
  {
    id: 'text',
    getEditField: (attr, label, prefix, validation, options) => (
      <Field
        name={attr.key}
        component={TextAreaInput}
        label={label}
        prefix={prefix}
        validate={validation}
        help={attr.description}
        key={attr.key}
        {...options}
      />
    ),
    getRenderComponent: (value) => <ExecutionMarkdown source={value} />,
  },
  {
    id: 'dateTime',
    getEditField: (attr, label, prefix, validation, options) => (
      <Field
        name={attr.key}
        component={DateInput}
        label={label}
        prefix={prefix}
        validate={validation}
        help={attr.description}
        key={attr.key}
        getCalendarContainer={(trigger) => trigger.parentNode}
        {...options}
      />
    ),
    getRenderComponent: (value) => Ui._formatDateWithString(value, 'lll'),
  },
  {
    id: 'number',
    getEditField: (attr, label, prefix, validation, options) => (
      <Field
        name={attr.key}
        component={TextInput}
        label={label}
        prefix={prefix}
        validate={validation}
        help={attr.description}
        key={attr.key}
        type="number"
        {...options}
      />
    ),
  },
  {
    id: 'email',
    getEditField: (attr, label, prefix, validation, options) => (
      <Field
        name={attr.key}
        component={TextInput}
        prefix={prefix}
        validate={validation}
        help={attr.description}
        label={label}
        key={attr.key}
        type="email"
        {...options}
      />
    ),
    getRenderComponent: (value) => (
      <a href={`mailto:${value}`} target="_top">
        {value}
      </a>
    ),
  },
  {
    id: 'tel',
    getEditField: (attr, label, prefix, validation, options) => (
      <Field
        name={attr.key}
        component={TextInput}
        prefix={prefix}
        validate={validation}
        help={attr.description}
        label={label}
        key={attr.key}
        type="tel"
        {...options}
      />
    ),
    getRenderComponent: (value) => <a href={`tel:+${value}`}>{value}</a>,
  },
  {
    id: 'url',
    getEditField: (attr, label, prefix, validation, options) => (
      <Field
        name={attr.key}
        component={TextInput}
        prefix={prefix}
        validate={validation}
        help={attr.description}
        label={label}
        key={attr.key}
        type="url"
        {...options}
      />
    ),
    getRenderComponent: (value) => (
      <a href={value} target="_blank" rel="noopener noreferrer">
        {value}
      </a>
    ),
  },
  {
    id: 'date',
    getEditField: (attr, label, prefix, validation, options) => (
      <Field
        name={attr.key}
        component={DateInput}
        prefix={prefix}
        validate={validation}
        help={attr.description}
        label={label}
        key={attr.key}
        showTimeSelect={false}
        getCalendarContainer={(trigger) => trigger.parentNode}
        {...options}
      />
    ),
    getRenderComponent: (value) => Ui._formatDateWithString(value, 'l'),
  },
  {
    id: 'time',
    getEditField: (attr, label, prefix, validation, options) => (
      <Field
        name={attr.key}
        component={DateInput}
        prefix={prefix}
        validate={validation}
        help={attr.description}
        label={label}
        key={attr.key}
        showDateSelect={false}
        getCalendarContainer={(trigger) => trigger.parentNode}
        {...options}
      />
    ),
    getRenderComponent: (value) => Ui._formatDateWithString(value, 'LT'),
  },
  {
    id: 'boolean',
    getEditField: booleanEditField,
    getRenderComponent: booleanValueComponent,
  },
  // value would be an array of something
  {
    id: 'list',
    getEditField: (attr, label) => (
      <Row>
        <Col md={4} xs={12}>
          <strong>{label}</strong>
        </Col>
        <Col md={8} xs={12} style={{ marginBottom: '5px' }}>
          <FieldArray name={attr.key} component={ListArray} />
        </Col>
      </Row>
    ),
    getRenderComponent: (value) => {
      if (!_.isArray(value)) {
        return value
      }

      return (
        <ul>
          {value.map((item) => (
            <li key={item}>{item}</li>
          ))}
        </ul>
      )
    },
  },
  // value would be selected from list of options
  {
    id: 'pickList',
    getEditField: (attr, label, prefix, validation, options) => (
      <Field
        name={attr.key}
        component={SelectInput}
        label={label}
        prefix={prefix}
        validate={validation}
        help={attr.description}
        key={attr.key}
        options={
          (attr &&
            attr.options &&
            attr.options.map &&
            attr.options.map((opt) => ({ id: opt, name: opt }))) ||
          []
        }
        {...options}
      />
    ),
  },
  {
    id: 'file',
    getEditField: (attr, label, prefix, validation, options) => (
      <Field
        name={attr.key}
        component={FileInput}
        label={label}
        prefix={prefix}
        validate={validation}
        help={attr.description}
        key={attr.key}
        multiFiles={false}
        {...options}
      />
    ),
    getRenderComponent: (file) => (
      <div>{file && <FileThumbnail file={JSON.parse(file)} key={file} />}</div>
    ),
  },
]

class UiAttributeHelper extends AttributeHelper {
  static getEditField(attribute, label, prefix, options) {
    const myAttributeType = attributeTypes.find((a) => a.id === attribute.type)

    const validation = []
    if (attribute.required && attribute.required !== 'false') {
      validation.push(requiredValidator)
    }

    return myAttributeType.getEditField(
      attribute,
      label,
      prefix,
      validation,
      options,
    )
  }

  static getRenderComponent(attribute, value) {
    const myAttributeType = attributeTypes.find((a) => a.id === attribute.type)

    const defaultValue = attribute.type === 'file' ? '' : '-'
    const myValue = value || defaultValue

    if (!myAttributeType || !_.isFunction(myAttributeType.getRenderComponent)) {
      return myValue
    }

    return myAttributeType.getRenderComponent(myValue)
  }

  // returns true if type is included in match's type match
  static extendedTypeMatches = (match, option) => {
    const { type } = option || {}
    const extendedTypeMatch = {
      number: ['integer', 'decimal'],
      text: ['string'],
      pickList: ['string'],
      string: ['pickList'],
      dateTime: ['date', 'time', 'dateTime', 'date-time'],
      date: ['date', 'dateTime', 'date-time'],
      time: ['time', 'dateTime', 'date-time'],
    }

    if (
      match.indexOf('vault-') === 0 &&
      option.slug &&
      option.vault &&
      match.substr('vault-'.length) === option.slug
    ) {
      return true
    }

    return (
      extendedTypeMatch[match] && extendedTypeMatch[match].indexOf(type) > -1
    )
  }
}

export default UiAttributeHelper
