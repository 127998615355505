import get from 'lodash/get'
import set from 'lodash/set'
import { COMPUTATION_V2_STEP_TYPE } from '../../../../../constants'
import ComputationV2StepOptions from '../stepOptions/ComputationV2StepOptions'
import BasicUiHelper from '../../BasicUiHelper'
import StringHelper from '../../../../../shared-helpers/StringHelper'

class ComputationV2UiHelper extends BasicUiHelper {
  regex() {
    return COMPUTATION_V2_STEP_TYPE
  }

  name() {
    return 'Computation V2'
  }

  canTryAgain() {
    return true
  }

  stepOptions() {
    return ComputationV2StepOptions
  }

  getOutputKeys({ stepOptions }) {
    const computations = get(stepOptions, 'computations', [])
    const schema = {}

    // We need to loop over the computations defined and add them to our data
    computations.forEach((computation) => {
      set(schema, computation.key, {
        type: 'Label',
        title: computation.display,
      })
    })

    return { schema }
  }

  getOutputOptions(step) {
    return (step.stepOptions.computations || []).map((field) => ({
      id: JSON.stringify({
        step: step._id,
        field: field.key ? field.key : StringHelper.slugify(field.display),
      }),
      name: `${field.display}`,
      textValue: `{${step._id}.${field.key ? field.key : StringHelper.slugify(field.display)}}`,
      type:
        field.functionList[0]?.functionType === 'numberFunctions'
          ? 'number'
          : 'string',
    }))
  }

  getInputOptions(step) {
    return (step.stepOptions.computations || []).map((field) => ({
      id: JSON.stringify({
        step: step._id,
        field: field.key ? field.key : StringHelper.slugify(field.display),
      }),
      name: `${step.name} - ${field.display}`,
      textValue: `{${step._id}.${field.key ? field.key : StringHelper.slugify(field.display)}}`,
      type:
        field.functionList[0]?.functionType === 'numberFunctions'
          ? 'number'
          : 'string',
    }))
  }
}

export default ComputationV2UiHelper
