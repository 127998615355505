import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { LinkContainer } from 'react-router-bootstrap';
import { LoadingButton } from 'lib/acromyrmex';
import { Button } from '../../utility/UiComponents';
import ConfirmModal from '../shared/ConfirmModal';

class ExecutionButtons extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { isDeleting, deleteExecution, execution, pullRight, viewDetails } = this.props;
    const { showDelete } = this.state;
    return (
      <div
        className={classNames('buttons-margin', {
          'pull-right': pullRight
        })}
      >
        {viewDetails && (
          <LinkContainer
            to={{
              pathname: `/processes/${execution.process._id}/executions/${execution._id}`
            }}
          >
            <Button size="xsmall" variant="default">
              View Execution
            </Button>
          </LinkContainer>
        )}
        <LoadingButton
          variant="danger"
          style={{ marginRight: 5 }}
          size="xsmall"
          loading={isDeleting}
          onClick={() => {
            this.setState({ showDelete: true });
          }}
          loadingLabel=" "
          label="Delete"
        />
        <ConfirmModal
          show={showDelete}
          title={`Confirm deleting ${execution.process.name} ${execution.execution}`}
          message={`Are you sure you want to delete ${execution.process.name} ${execution.execution}?`}
          onConfirm={() => {
            deleteExecution(execution._id);
            this.setState({ showDelete: false });
          }}
          onCancel={() => {
            this.setState({ showDelete: false });
          }}
        />
      </div>
    );
  }
}

// Define property types
ExecutionButtons.propTypes = {
  // options to the user
  execution: PropTypes.shape().isRequired,
  deleteExecution: PropTypes.func.isRequired,
  isDeleting: PropTypes.bool,
  pullRight: PropTypes.bool,
  viewDetails: PropTypes.bool
};

ExecutionButtons.defaultProps = {
  isDeleting: false,
  pullRight: false,
  viewDetails: false
};

export default ExecutionButtons;
