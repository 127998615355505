import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Field } from 'redux-form'
import PropTypes from 'prop-types'
import { SelectInput } from 'lib/acromyrmex'
import {
  Row,Accordion,
  Alert,
} from '../../../../../utility/UiComponents'

class VaultDeleteStepOptions extends React.Component {
  render() {
    const {
      fieldText,
      outputOptions,
      formValues: { steps },
    } = this.props

    if (!steps) {
      return null
    }

    const stepOptions = outputOptions.filter((o) => o.vault)

    if (!stepOptions || stepOptions.length === 0) {
      return (
        <Row key={fieldText} style={{ marginBottom: 10 }}>
          <Alert variant="danger">
            <i className="fa fa-exclamation-circle" />
            {
              ' Vault delete step type requires a previous vault input or single vault query step!'
            }
          </Alert>
        </Row>
      )
    }

    return (
      <Row key={fieldText} style={{ marginBottom: 10 }}>
        <Accordion defaultActiveKey="1">
          <Accordion.Item eventKey="1">
            <Accordion.Header>Vault Delete Step Options</Accordion.Header>
            <Accordion.Body>
              <Row>
                <Field
                  name={`${fieldText}.stepOptions.destination`}
                  component={SelectInput}
                  label="Structure to delete from"
                  templateOptions={stepOptions}
                />
              </Row>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Row>
    )
  }
}

// Define property types
VaultDeleteStepOptions.propTypes = {
  fieldText: PropTypes.string.isRequired,
  outputOptions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  formValues: PropTypes.shape(),
}

VaultDeleteStepOptions.defaultProps = {
  formValues: {},
}

const mapStateToProps = (state) => ({
  structureList: state.vault.structureList.rows,
  formValues: state.form.processForm ? state.form.processForm.values : {},
})

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VaultDeleteStepOptions)
