import { MANUAL_SELECT_STEP_TYPE } from '../../../constants'
import ManualSelectStepOptions from './components/stepOptions/ManualSelectStepOptions'
import ManualSelectStepExecution from './components/stepExecutions/manualSelect'
import BasicUiHelper from './BasicUiHelper'
import StringHelper from '../../../shared-helpers/StringHelper'

class ManualSelectUiHelper extends BasicUiHelper {
  regex() {
    return MANUAL_SELECT_STEP_TYPE
  }

  name() {
    return 'Manual Select'
  }

  canTryAgain() {
    return false
  }

  stepOptions() {
    return ManualSelectStepOptions
  }

  getOutputKeys() {
    return {
      schema: {
        '.': {
          type: 'Table',
        },
      },
    }
  }

  stepExecution() {
    return ManualSelectStepExecution
  }

  // we don't want a submit button
  getStepButtons() {
    return []
  }

  getOutputOptions(step, options) {
    const { process, steps } = options
    const sourceStepId = StringHelper.getStepIdFromTemplate(
      step.stepOptions.sourceData,
    )

    const sourceStep = process.steps.find(
      (s) => s._id && s._id.toString() === sourceStepId.toString(),
    )
    if (!sourceStep) {
      return
    }

    const stepDef = steps.find((s) =>
      new RegExp(s.regex).test(sourceStep.stepType),
    )

    if (!stepDef || !stepDef.getOutputOptions) {
      return
    }

    const recursiveFix = (opt) => ({
      ...opt,
      id: opt.id.replace(
        new RegExp(sourceStepId.toString(), 'g'),
        step._id.toString(),
      ),
      textValue: opt.textValue.replace(
        new RegExp(sourceStepId.toString(), 'g'),
        step._id.toString(),
      ),
      properties: opt.properties ? opt.properties.map(recursiveFix) : undefined,
    })

    // change the stepId to this steps, not last step
    const outputOptions = stepDef.getOutputOptions(sourceStep, options)
    return outputOptions.map(recursiveFix)
  }
}

export default ManualSelectUiHelper
