import React from 'react';
import PropTypes from 'prop-types';
import ListItem from './ListItem';
import AddButton from '../../shared/form/AddButton';

const ListArray = ({ fields }) => {
  const rows = fields.map((member, index) => (
    <ListItem item={member} fields={fields} index={index} key={member} />
  ));
  return (
    <div>
      {rows}
      <AddButton size="sm" className="pull-left" onClick={() => fields.push('')} label="Add List Item" />
    </div>
  );
};

ListArray.propTypes = {
  fields: PropTypes.shape()
};
ListArray.defaultProps = {
  fields: {}
};
export default ListArray;
