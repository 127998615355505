import React from 'react';
import PropTypes from 'prop-types';
import ExecutionOverviewStep from './ExecutionOverviewStep';
import SidebarAttribute from '../../shared/Layout/SidebarAttribute';
import DateHelper from '../../../shared-helpers/DateHelper';

const ExecutionOverview = ({
  execution,
  execution: {
    process: { steps }
  }
}) => (
  <div className="execution-overview">
    <SidebarAttribute
      display="Date Created"
      value={DateHelper.timeAgo(new Date(execution.dateCreated))}
    />
    <SidebarAttribute
      display="Date Updated"
      value={DateHelper.timeAgo(new Date(execution.dateUpdated))}
    />
    <SidebarAttribute
      display="Steps"
      formatter={() =>
        steps.map((step, stepIndex) => (
          <ExecutionOverviewStep
            execution={execution}
            steps={steps}
            step={step}
            stepIndex={stepIndex}
            key={step._id}
          />
        ))
      }
    />
  </div>
);

ExecutionOverview.propTypes = {
  execution: PropTypes.shape().isRequired
};

export default ExecutionOverview;
