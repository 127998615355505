import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Field } from 'redux-form';
import { CheckboxInput } from 'lib/acromyrmex';
import PropTypes from 'prop-types';
import { Col } from '../../../../../utility/UiComponents';
import StepOptionWrapper from './StepOptionWrapper';

// eslint-disable-next-line
class BlockBeginStepOptions extends React.Component {
  render() {
    const { fieldText, processStep } = this.props;

    return (
      <StepOptionWrapper fieldText={fieldText} title={processStep.name}>
        <Col xs={12}>
          <Field
            name={`${fieldText}.stepOptions.nonConcurrent`}
            component={CheckboxInput}
            label="Non-concurrent"
            help="Check this if you only want one of these blocks to execute at a time. This can be helpful when you experience rapid triggers from an external system."
          />
        </Col>
      </StepOptionWrapper>
    );
  }
}

// Define property types
BlockBeginStepOptions.propTypes = {
  fieldText: PropTypes.string.isRequired,
  processStep: PropTypes.shape().isRequired
};

BlockBeginStepOptions.defaultProps = {};

const mapStateToProps = state => ({
  structureList: state.vault.structureList.rows
});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(BlockBeginStepOptions);
