/* eslint-disable react/prop-types */
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Field, FieldArray } from 'redux-form';
import { SelectInput, TemplateInput, DateInput, Loading } from 'lib/acromyrmex';
import { Row, Col, Accordion, Button } from '../../../../../utility/UiComponents';
import AddButton from '../../../../shared/form/AddButton';
import UiAttributeHelper from '../../../../vaultStructures/attributes/UiAttributeHelper';

class UpdateSolutionSettingsStepOptions extends React.Component {
  static renderEditField(outputOptions, member, selectedAttribute, name = 'value') {
    const optionsToUse = outputOptions.filter(
      o =>
        o.type === selectedAttribute.type ||
        UiAttributeHelper.extendedTypeMatches(selectedAttribute.type, o)
    );

    switch (selectedAttribute.type) {
      case 'dateTime':
      case 'date':
      case 'time':
        return (
          <div>
            <Field
              name={`${member}.${name}`}
              component={DateInput}
              noLabel
              label="Value"
              options={optionsToUse}
              showTimeSelect={selectedAttribute.type !== 'date'}
              showDateSelect={selectedAttribute.type !== 'time'}
              getCalendarContainer={trigger => trigger.parentNode}
            />
          </div>
        );
      case 'boolean':
        return (
          <div>
            <Field
              name={`${member}.${name}`}
              component={SelectInput}
              noLabel
              label="Value"
              options={[
                { id: 'true', name: 'true' },
                { id: 'false', name: 'false' }
              ]}
              templateOptions={optionsToUse}
            />
          </div>
        );
      case 'number':
      case 'integer':
      case 'string':
      case 'text':
      case 'email':
      case 'tel':
      case 'url':
      default:
        return (
          <div>
            <Field
              name={`${member}.${name}`}
              component={TemplateInput}
              noLabel
              label="Value"
              options={optionsToUse}
              type="text"
            />
          </div>
        );
    }
  }

  constructor(props) {
    super(props);

    this.renderQueryFields = this.renderQueryFields.bind(this);
  }

  renderEditField(member, selectedAttribute) {
    const { outputOptions } = this.props;
    return UpdateSolutionSettingsStepOptions.renderEditField(
      outputOptions,
      member,
      selectedAttribute
    );
  }

  renderQueryField(member, fields, index, settings) {
    const { change } = this.props;
    const field = fields.get(index);
    const selectedAttribute = settings[field.key];
    const selectOptions = Object.keys(settings).map(k => ({ id: k, name: settings[k].name }));

    return (
      <Row style={{ marginBottom: 10 }} key={member}>
        <Col xs={5}>
          <Field
            name={`${member}.key`}
            label="Solution Setting"
            component={SelectInput}
            onChange={() => {
              change(`${member}.value`, null);
            }}
            options={selectOptions}
            noLabel
          />
        </Col>
        <Col xs={6}>
          {!selectedAttribute && <Loading />}
          {selectedAttribute && this.renderEditField(member, selectedAttribute)}
        </Col>
        <Col xs={1}>
          <Button
            variant="danger"
            onClick={() => fields.remove(index)}
            title="Remove this parameter"
          >
            <i className="fa fa-trash" />
          </Button>
        </Col>
      </Row>
    );
  }

  renderQueryFields({ fields }) {
    const { settings } = this.props;
    const rows = fields.map((member, index) =>
      this.renderQueryField(member, fields, index, settings)
    );
    const noParamsLeft = fields.length >= Object.keys(settings).length;

    return (
      <div>
        {rows}
        <Row className="step-option-buttons">
          <Col xs={12}>
            <AddButton size="sm"
              disabled={noParamsLeft}
              onClick={() => {
                fields.push({});
              }}
              label="Add Field"
            />
            {noParamsLeft && (
              <span
                className="text-info pull-right"
                style={{ marginTop: '5px', marginRight: '5px' }}
              >
                (All parameters are filled)
              </span>
            )}
          </Col>
        </Row>
      </div>
    );
  }

  render() {
    const { fieldText, processStep, outputOptionsLoaded, settings } = this.props;

    return (
      <Row key={fieldText} style={{ marginBottom: 10 }}>
          <Accordion defaultActiveKey="1">
          <Accordion.Item eventKey="1">
            <Accordion.Header>{`${processStep.name || ''} Step Options`}</Accordion.Header>
            <Accordion.Body>
            <Row style={{ marginBottom: 10 }}>
              <Col xs={5}>
                <strong>Solution Setting</strong>
              </Col>
              <Col xs={6}>
                <strong>Value</strong>
              </Col>
            </Row>
            {outputOptionsLoaded && (
              <FieldArray
                name={`${fieldText}.stepOptions.queryFields`}
                component={this.renderQueryFields}
                settings={settings}
              />
            )}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Row>
    );
  }
}

const mapStateToProps = state => ({
  settings: state.processes.settings.value,
  formValues: state.form.processForm ? state.form.processForm.values : {}
});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(UpdateSolutionSettingsStepOptions);
