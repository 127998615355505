import React from 'react';
import PropTypes from 'prop-types';
import SidebarAttribute from '../../shared/Layout/SidebarAttribute';

const ShowProcessAttribute = ({ contuitProcess, attribute, ...attrOptions }) => {
  const val = attribute ? contuitProcess[attribute] : 'no-attribute';

  if (!val) {
    return null;
  }

  return <SidebarAttribute value={val} {...attrOptions} />;
};

ShowProcessAttribute.propTypes = {
  contuitProcess: PropTypes.shape().isRequired,
  attribute: PropTypes.string.isRequired
};

ShowProcessAttribute.defaultProps = {};

export default ShowProcessAttribute;
