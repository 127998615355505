// from: https://gist.github.com/insin/bbf116e8ea10ef38447b
import _ from 'underscore';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormField } from 'lib/acromyrmex';
import { Row, Col } from '../../../utility/UiComponents';
import FileUploadModal from './FileUploadModal';
import { FileRemoveThumbnail } from './FileThumbnail';
import {
  openModal as openModalAction,
  closeModal as closeModalAction,
  clearUploadedFiles as clearUploadedFilesAction
} from '../../../actions/upload';
import AddButton from '../form/AddButton';

class FileInput extends Component {
  constructor(props) {
    super(props);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.onSubmitFiles = this.onSubmitFiles.bind(this);
    this.removeFile = this.removeFile.bind(this);

    this.state = {
      showUploadModal: false
    };
  }

  onSubmitFiles() {
    const {
      input: { value, onChange },
      uploadedFiles,
      clearUploadedFiles,
      multiFiles
    } = this.props;

    // add the new files to the value
    const files = multiFiles ? value : [value].filter(Boolean);
    const newInputValue = [...files, ...uploadedFiles];

    onChange(multiFiles ? newInputValue : newInputValue[0]);

    clearUploadedFiles();
    this.closeModal();
  }

  removeFile(file) {
    const {
      input: { value, onChange },
      multiFiles
    } = this.props;

    const files = multiFiles ? value : [value].filter(Boolean);

    const newInputValue = files.filter(f => f.s3Key !== file.s3Key);

    onChange(multiFiles ? newInputValue : newInputValue[0]);
  }

  openModal() {
    const { openModal } = this.props;
    this.setState({ showUploadModal: true });
    openModal();
  }

  closeModal() {
    const { closeModal } = this.props;
    closeModal();
  }

  render() {
    const {
      help,
      label,
      prefix,
      noLabel,
      meta,
      input: { value },
      multiFiles
    } = this.props;

    const files = multiFiles ? value : [value].filter(Boolean);

    return (
      <FormField label={label} prefix={prefix} meta={meta} help={help} noLabel={noLabel}>
        <Row>
          {files &&
            _.isFunction(files.map) &&
            files.map(file => (
              <Col xs={12} sm={6} md={4} key={file.name}>
                <FileRemoveThumbnail file={file} removeFile={this.removeFile} />
              </Col>
            ))}
        </Row>
        <AddButton size="sm" onClick={this.openModal} label="Upload File" />
        <FileUploadModal
          onSubmitFiles={this.onSubmitFiles}
          show={this.state.showUploadModal}
          onHide={() => this.setState({ showUploadModal: false })}
        />
      </FormField>
    );
  }
}

FileInput.shouldComponentUpdate = FormField.shouldFormFieldUpdate;

FileInput.propTypes = {
  meta: PropTypes.shape().isRequired,
  help: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  prefix: PropTypes.node,
  input: PropTypes.shape().isRequired,
  noLabel: PropTypes.bool,
  closeModal: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  clearUploadedFiles: PropTypes.func.isRequired,
  uploadedFiles: PropTypes.arrayOf(PropTypes.shape()),
  multiFiles: PropTypes.bool
};

FileInput.defaultProps = {
  help: '',
  label: '',
  prefix: null,
  noLabel: false,
  uploadedFiles: [],
  multiFiles: true
};

const mapStateToProps = state => ({
  uploadedFiles: state.upload.uploadedFiles
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      openModal: openModalAction,
      closeModal: closeModalAction,
      clearUploadedFiles: clearUploadedFilesAction
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps, null, { withRef: true })(FileInput);
