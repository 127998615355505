import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from '../../utility/UiComponents';
import ScheduleProcessForm from './ScheduleProcessForm';

class ExecuteProcessModal extends Component {
  static propTypes = {
    hideExecute: PropTypes.bool,
    processItem: PropTypes.shape().isRequired,
    initialValues: PropTypes.shape(),
    show: PropTypes.bool,
    onCancel: PropTypes.func.isRequired,
    onSchedule: PropTypes.func.isRequired,
    onExecute: PropTypes.func
  };

  static defaultProps = {
    show: false,
    hideExecute: false,
    onExecute: () => {},
    initialValues: null
  };

  render() {
    const {
      onCancel,
      onSchedule,
      onExecute,
      show,
      processItem,
      hideExecute,
      initialValues
    } = this.props;

    return (
      <Modal show={show} onHide={onCancel} className="image-preview" size="large">
        <Modal.Header closeButton>
          <Modal.Title>Execute or Schedule Process</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!hideExecute && (
            <div className="clearfix">
              <h4>Run Now</h4>
              <p>If you want to run the process right now, select this option</p>
              <Button variant="success" onClick={onExecute} className="pull-right">
                Execute
              </Button>
            </div>
          )}
          {!hideExecute && <hr />}
          <div className="clearfix">
            <h4>Schedule</h4>
            <p>You may also schedule the process to run in the future or on an interval</p>
            <ScheduleProcessForm
              onCancel={onCancel}
              onSubmit={onSchedule}
              processItem={processItem}
              initialValues={initialValues}
            />
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

export default ExecuteProcessModal;
