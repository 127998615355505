import React from 'react';
import PropTypes from 'prop-types';
import HTML5Backend from 'react-dnd-html5-backend';
import { DragDropContext } from 'react-dnd';

const ProcessFormStepWrapper = props => {
  const { children } = props;

  return <div>{children}</div>;
};

// Define property types
ProcessFormStepWrapper.propTypes = {
  children: PropTypes.node
};

ProcessFormStepWrapper.defaultProps = {
  children: null
};

export default DragDropContext(HTML5Backend)(ProcessFormStepWrapper);
