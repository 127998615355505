import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { LicenseServer } from '@contuit-otf/sdk';
import { toast } from 'react-toastify';
import { CheckboxInput } from 'lib/acromyrmex';
import {
  Button,
  Alert,
  Checkbox,
  FormControl,
  Row,
  Col,
} from '../../utility/UiComponents';
import request from '../../utility/request';

const licenseServer = new LicenseServer('/api/license', request);

class DeleteTenantSection extends Component {
  static propTypes = {
    tenant: PropTypes.object.isRequired,
    token: PropTypes.string.isRequired,
    onDeleteSuccess: PropTypes.func.isRequired,
  };
  constructor(props) {
    super(props);
    this.state = {
      showDeleteModal: false,
      isDeleteChecked: false,
      tenantNameInput: '',
      stats: null,
    };
  }

  componentDidMount() {
    this.loadStats();
  }

  async loadStats() {
    const response = await request
      .get(`/api/license/${this.props.tenant._id}/stats`)
      .set({ 'x-access-token': this.props.token });

    this.setState({ stats: response.body });
  }

  openDeleteModal = () => {
    this.setState({ showDeleteModal: true });
  };

  closeDeleteModal = () => {
    this.setState({ showDeleteModal: false, isDeleteChecked: false });
  };

  handleCheckboxChange = (e) => {
    this.setState((prevState) => ({
      isDeleteChecked: e.target.checked,
    }));
  };

  handleTenantNameChange = (event) => {
    this.setState({ tenantNameInput: event.target.value });
  };

  handleDelete = async () => {
    const { tenant, token, onDeleteSuccess } = this.props;

    try {
      await licenseServer.deleteContuitClient({
        token,
        contuitClientId: tenant._id,
      });
      toast.success('Tenant deleted successfully.');
      toast.success('Hang Tight! You will be redirected to another tenant');
      setTimeout(() => {
        onDeleteSuccess();
      }, 4000);
    } catch (err) {
      const errorMessage =
        err.response?.body?.message || err.message || 'Unknown error occurred';

      if (errorMessage.includes('the only tenant for the user')) {
        toast.error(
          'Cannot delete tenant: It is the only tenant for the user.',
        );
      } else {
        toast.error(`Error deleting tenant: ${err.message}`);
      }
    }
  };

  renderListItem = (label, stat) => (
    <li>
      {label}
      {stat !== undefined ? `: ${stat}` : ''}
    </li>
  );

  render() {
    const { tenant } = this.props;
    const { isDeleteChecked, tenantNameInput, stats } = this.state;
    const isDeleteEnabled = isDeleteChecked && tenantNameInput === tenant.name;
    return (
      <div>
        <Alert variant="danger">
          <h2>Delete Tenant</h2>
          <p>
            <strong>
              Warning: Deleting this tenant is a permanent action and cannot be
              undone.
            </strong>
          </p>
          <p>
            Before you proceed, please ensure you have resolved all associated
            items with this tenant. This includes, but is not limited to, the
            following:
          </p>
          <ul>
            {this.renderListItem('Executions', stats?.executions)}
            {this.renderListItem('Processes', stats?.processes)}
            {this.renderListItem('Users', stats?.users)}
            {this.renderListItem(
              'Enabled Integrations',
              stats?.enabledIntegrations,
            )}
            {this.renderListItem('Vault Structures', stats?.vaultStructures)}
          </ul>
          <p>
            Failure to resolve these items may result in data loss or system
            inconsistencies. If you are certain you want to delete this tenant,
            please re-enter the tenant name below to confirm your action.
          </p>
          <CheckboxInput
            label={
              <strong>I understand that this action cannot be undone</strong>
            }
            input={{
              value: isDeleteChecked,
              onChange: (e) => e && this.handleCheckboxChange(e),
              onBlur: () => {},
            }}
            meta={{}}
          />

          <Row>
            <Col lg="3" className="mb-2">
              <FormControl
                type="text"
                placeholder="Enter tenant name"
                value={tenantNameInput}
                onChange={this.handleTenantNameChange}
                disabled={!isDeleteChecked}
              />
            </Col>
            <Col md="1">
              <Button
                onClick={this.handleDelete}
                variant="danger"
                disabled={!isDeleteEnabled}
              >
                Permanently Delete Tenant
              </Button>
            </Col>
          </Row>
        </Alert>
      </div>
    );
  }
}

export default DeleteTenantSection;
