import React from 'react';
import PropTypes from 'prop-types';
import { Col } from '../../../../../utility/UiComponents';
import AttributeHelper from '../../../../vaultStructures/attributes/UiAttributeHelper';

const InputStep = ({ step }) => {
  const { fields } = step.stepOptions;
  return (
    <div>
      {fields &&
        fields.length &&
        fields.map(field => {
          const attr = {
            key: field.slug,
            required: field.required,
            type: field.type,
            ...field
          };

          const editField = AttributeHelper.getEditField(attr, field.label);
          return (
            <Col
              xs={12}
              key={field.slug}
              className="clearfix input-step-row"
              style={{ marginBottom: 5 }}
            >
              {editField}
            </Col>
          );
        })}
    </div>
  );
};

InputStep.propTypes = {
  step: PropTypes.shape().isRequired
};

InputStep.defaultProps = {};

export default InputStep;
