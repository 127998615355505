"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _superagent = _interopRequireDefault(require("superagent"));
class UploadServer {
  static uploadFile(serverUri, _ref) {
    var {
      token,
      file,
      contuitId = null
    } = _ref;
    if (!file) {
      throw new Error('UploadServer.uploadFile requires a file');
    }
    if (!token) {
      throw new Error('UploadServer.uploadFile requires a token');
    }
    return _superagent.default.post("".concat(serverUri, "/upload")).attach('file', file).query({
      contuitId
    }).set('x-access-token', token).then(res => res.body).catch(err => {
      var {
        status,
        response: {
          body
        }
      } = err;
      var msg = status === 500 ? 'Unknown error uploading file, please try again later.' : body.message;
      throw new Error(msg);
    });
  }
  static deleteFile(serverUri, _ref2) {
    var {
      token,
      s3Key
    } = _ref2;
    if (!s3Key) {
      throw new Error('UploadServer.deleteFile requires an s3Key');
    }
    if (!token) {
      throw new Error('UploadServer.deleteFile requires a token');
    }
    return _superagent.default.delete("".concat(serverUri)).query({
      s3Key
    }).set('x-access-token', token).then(res => res.body).catch(err => {
      var {
        status,
        response: {
          body
        }
      } = err;
      var msg = status === 500 ? 'Unknown error deleting file, please try again later.' : body.message;
      throw new Error(msg);
    });
  }
  constructor(serverUri) {
    this._serverUri = serverUri;
  }
  uploadFile(options) {
    return UploadServer.uploadFile(this._serverUri, options);
  }
  deleteFile(options) {
    return UploadServer.deleteFile(this._serverUri, options);
  }
}
var _default = exports.default = UploadServer;