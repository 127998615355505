import { VAULT_REPLACE_STEP_TYPE } from '../../../constants'
import BasicUiHelper from './BasicUiHelper'
import VaultReplaceStepOptions from './components/stepOptions/vaultReplace/vaultReplace'

class VaultReplaceUiHelper extends BasicUiHelper {
  regex() {
    return VAULT_REPLACE_STEP_TYPE
  }

  name() {
    return 'Vault Replace'
  }

  canTryAgain() {
    return true
  }

  stepOptions() {
    return VaultReplaceStepOptions
  }
}

export default VaultReplaceUiHelper
