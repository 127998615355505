import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { toast } from 'react-toastify';
import { ProcessServer } from '@contuit-otf/sdk';
import request from '../../utility/request';
import ExecutionButtons from './ExecutionButtons';

const processServer = new ProcessServer('/api/processes', request);

const ConnectedExecutionButtons = ({
  currentUser: { token },
  execution,
  onDelete,
  ...otherProps
}) => {
  const [isDeleting, setIsDeleting] = useState(false);

  return (
    <ExecutionButtons
      {...otherProps}
      execution={execution}
      isDeleting={isDeleting}
      deleteExecution={() => {
        setIsDeleting(true);
        processServer
          .deleteExecution({
            token,
            executionId: execution._id,
          })
          .then(() => onDelete())
          .catch((err) => {
            toast.error('Unknown error deleting execution.');
          })
          .then(() => {
            setIsDeleting(false);
          });
      }}
    />
  );
};

ConnectedExecutionButtons.propTypes = {
  execution: PropTypes.shape().isRequired,
  onDelete: PropTypes.func,
  currentUser: PropTypes.shape({
    token: PropTypes.string.isRequired,
  }).isRequired,
};

ConnectedExecutionButtons.defaultProps = {
  onDelete: () => {},
};

const mapStateToProps = (state) => ({
  currentUser: state.users.currentUser,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ConnectedExecutionButtons);
