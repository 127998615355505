import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { TextInput, SelectInput, CheckboxInput, FormulaInput } from 'lib/acromyrmex';
import { Button, Row, Col } from '../../../utility/UiComponents';
import { requiredValidator, selectRequiredValidator } from '../../../utility/formValidators';
import AttributeHelper from '../../vaultStructures/attributes/UiAttributeHelper';

const formulaOptions = [
  { id: 'COUNT', name: 'COUNT', textValue: '$COUNT$', collectionOnly: true },
  { id: 'SUM', name: 'SUM', textValue: '$SUM$', collectionOnly: true },
  { id: 'AVERAGE', name: 'AVERAGE', textValue: '$AVERAGE$', collectionOnly: true },
  { id: 'MIN', name: 'MIN', textValue: '$MIN$', collectionOnly: true },
  { id: 'MAX', name: 'MAX', textValue: '$MAX$', collectionOnly: true },
  { id: 'AND', name: 'AND', textValue: '$AND$' },
  { id: 'OR', name: 'OR', textValue: '$OR$' },
  { id: 'CONCAT', name: 'CONCAT', textValue: '$CONCAT$' },
  { id: 'DATEADD', name: 'DATEADD', textValue: '$DATEADD$' },
  { id: 'DATESUBTRACT', name: 'DATESUBTRACT', textValue: '$DATESUBTRACT$' },
  { id: 'DATEFORMAT', name: 'DATEFORMAT', textValue: '$DATEFORMAT$' },
  { id: 'SUBSTRING', name: 'SUBSTRING', textValue: '$SUBSTRING$' },
  { id: 'REPLACE', name: 'REPLACE', textValue: '$REPLACE$' }
];

const ComputationFormRow = ({
  attr,
  fields,
  index,
  templateOptions,
  attributeOptions,
  change,
  hideCollectionFeatures,
  onChangeAttributeNeeded,
  hideLabel
}) => {
  const finalFormulaOptions = hideCollectionFeatures
    ? formulaOptions.filter(o => !o.collectionOnly)
    : formulaOptions;

  return (
    <Row key={attr} className="struct-form-row">
      <Col xs={12} sm={6} md={3}>
        <Row>
          <Field
            name={`${attr}.display`}
            component={TextInput}
            label="Name"
            validate={requiredValidator}
            onPaste={e => {
              const pastedText = e.clipboardData.getData('Text');
              const rows = pastedText.split('\n');

              if (rows.length > 1) {
                // don't add the pasted text to the value
                e.preventDefault();
                change(`${attr}.display`, rows[0]);
                // remove the first element
                rows.shift();

                // add our other fields
                rows.forEach(r => {
                  fields.push({
                    display: r,
                    value: ''
                  });
                });
              }
            }}
            noLabel={hideLabel}
          />
        </Row>
      </Col>
      <Col xs={12} sm={6} md={3}>
        <Row>
          <Field
            name={`${attr}.type`}
            component={SelectInput}
            label="Type"
            validate={selectRequiredValidator}
            enableEmpty
            noLabel={hideLabel}
            options={AttributeHelper.getComputationTypes()}
          />
        </Row>
      </Col>
      {(hideCollectionFeatures || (attributeOptions && attributeOptions.length > 0)) && (
        <Col xs={12} sm={6} md={4}>
          <Row>
            <Field
              name={`${attr}.value`}
              component={FormulaInput}
              label="Value"
              validate={selectRequiredValidator}
              enableEmpty
              noLabel={hideLabel}
              options={templateOptions}
              attributes={attributeOptions}
              formulas={finalFormulaOptions}
              onChange={() => onChangeAttributeNeeded()}
            />
          </Row>
        </Col>
      )}
      <Col xs={6} sm={3} md={1}>
        <Row>
          <Field
            name={`${attr}.isPrimary`}
            component={CheckboxInput}
            noLabel={hideLabel}
            label="Primary"
          />
        </Row>
      </Col>
      <Col xs={6} sm={3} md={1}>
        <Button className="pull-right" variant="danger" onClick={() => fields.remove(index)}>
          <i className="fa fa-trash" />
        </Button>
      </Col>
    </Row>
  );
};

ComputationFormRow.propTypes = {
  attr: PropTypes.string.isRequired,
  fields: PropTypes.shape().isRequired,
  index: PropTypes.number.isRequired,
  change: PropTypes.func.isRequired,
  hideLabel: PropTypes.bool.isRequired,
  attributeOptions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  hideCollectionFeatures: PropTypes.bool,
  templateOptions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onChangeAttributeNeeded: PropTypes.func.isRequired
};

ComputationFormRow.defaultProps = {
  hideCollectionFeatures: false
};

export default ComputationFormRow;

export { formulaOptions };
