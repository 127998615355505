import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Collapse } from '../../../../../utility/UiComponents';

class BlockBeginStepSkipped extends Component {
  static propTypes = {
    execution: PropTypes.shape().isRequired,
    currentExecutionStep: PropTypes.shape().isRequired
  };

  static defaultProps = {};

  state = {
    showHiddenSteps: false
  };

  render() {
    const { execution, currentExecutionStep } = this.props;
    const { showHiddenSteps } = this.state;
    // count the steps that were skipped
    const skipped = [];
    let foundStep = false;
    let extraBlocks = 0;

    for (let i = 0; i < execution.process.steps.length; i += 1) {
      const loopProcessStep = execution.process.steps[i];

      if (!foundStep && i < execution.steps.length - 1) {
        const loopExecutionStep = execution.steps[i];
        if (currentExecutionStep._id === loopExecutionStep._id) {
          foundStep = true;
        }
      } else if (foundStep) {
        if (
          extraBlocks === 0 &&
          (loopProcessStep.stepType === 'block-end' || loopProcessStep.stepType === 'block-end-v2')
        ) {
          break;
        }

        // count any additional blocks we encounter
        if (
          loopProcessStep.stepType === 'block-begin' ||
          loopProcessStep.stepType === 'block-begin-v2'
        ) {
          extraBlocks += 1;
        } else if (
          loopProcessStep.stepType === 'block-end' ||
          loopProcessStep.stepType === 'block-end-v2'
        ) {
          extraBlocks -= 1;
        }

        // now, we are in the middle.
        skipped.push(loopProcessStep);
      }
    }

    return (
      <div>
        <div style={{ marginTop: '3px' }}>
          <Button
            size="xs"
            variant="primary"
            onClick={() => {
              this.setState({ showHiddenSteps: !showHiddenSteps });
            }}
          >
            {showHiddenSteps ? 'Hide' : 'Show'} {skipped.length} skipped step
            {skipped.length > 1 ? 's' : ''}
          </Button>
        </div>
        <div>
          <Collapse in={showHiddenSteps}>
            <div>
              {skipped.map((skippedStep, i) => (
                <div key={skippedStep._id} className="indented" style={{ marginTop: '3px' }}>
                  {i + 1}. {skippedStep.name}
                </div>
              ))}
            </div>
          </Collapse>
        </div>
      </div>
    );
  }
}

export default BlockBeginStepSkipped;
