import { LOOP_PROCESS_STEP_TYPE } from '../../../constants'
import LoopProcessStepOptions from './components/stepOptions/LoopProcessStepOptions'
import LoopProcessAutomating from './components/stepAutomating/LoopProcessAutomating'
import BasicUiHelper from './BasicUiHelper'

class LoopProcessUiHelper extends BasicUiHelper {
  regex() {
    return LOOP_PROCESS_STEP_TYPE
  }

  name() {
    return 'Run Process for List'
  }

  canTryAgain() {
    return false
  }

  stepOptions() {
    return LoopProcessStepOptions
  }

  // we don't want a submit button
  getStepButtons() {
    return []
  }

  getOutputKeys() {
    return {
      schema: {
        executionList: {
          type: 'ExecutionList',
        },
      },
    }
  }

  stepAutomating() {
    return LoopProcessAutomating
  }
}

export default LoopProcessUiHelper
