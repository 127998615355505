"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _isArray = _interopRequireDefault(require("lodash/isArray"));
var _superagent = _interopRequireDefault(require("superagent"));
class LogServer {
  static getLogs(serverUri) {
    return _superagent.default.get("".concat(serverUri, "/")).then(res => res.body);
  }
  static getLog(serverUri, logId) {
    return _superagent.default.get("".concat(serverUri, "/").concat(logId)).then(res => res.body);
  }
  static findLogEntries(serverUri, logName) {
    var query = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    return _superagent.default.get("".concat(serverUri, "/name/").concat(logName, "/entries")).query(query).then(res => res.body);
  }
  static createLog(serverUri, name) {
    return _superagent.default.post("".concat(serverUri, "/")).send({
      name
    }).then(res => res.body);
  }

  // CAN ALSO PASS AN ARRAY OF LOG OBJECTS UNDER LOGNAME
  static log(serverUri, logName, text, user) {
    var parameter = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : null;
    var field = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : null;
    var from = arguments.length > 6 && arguments[6] !== undefined ? arguments[6] : null;
    var to = arguments.length > 7 && arguments[7] !== undefined ? arguments[7] : null;
    var contuitId = arguments.length > 8 && arguments[8] !== undefined ? arguments[8] : null;
    if ((0, _isArray.default)(logName)) {
      return _superagent.default.post("".concat(serverUri, "/named")).send(logName).then(res => res.body);
    }
    return _superagent.default.post("".concat(serverUri, "/named")).query({
      contuitId
    }).send({
      logName,
      text,
      parameter,
      field,
      from,
      to,
      user
    }).then(res => res.body);
  }
  static _logFilterFunction(oldObj, newObj, field) {
    return field !== 'computationCalculated' && oldObj.data[field] !== newObj[field];
  }
  static logChangeList(logList) {
    var masterLogs = [];
    var serverUri = null;
    logList.forEach(options => {
      var {
        logName,
        userId,
        fields,
        newObj
      } = options;
      serverUri = options.serverUri;
      var oldObj = options.oldObj.toObject ? options.oldObj.toObject() : options.oldObj;

      // oldObj is the entire entry (not just data)
      masterLogs.push(...fields.filter(LogServer._logFilterFunction.bind(null, oldObj, newObj)).map(field => ({
        logName,
        text: '!defaultUpdate',
        parameter: oldObj._id,
        field,
        from: oldObj.data[field],
        to: newObj[field],
        user: userId
      })));
    });
    LogServer.log(serverUri, masterLogs).catch(err => {
      process.stderr.write("Could not log: ".concat(err));
    });
  }

  // goes through to find changes for the specified fields on the object
  static logChanges(options) {
    var {
      serverUri,
      logName,
      userId,
      fields,
      newObj
    } = options;
    var oldObj = options.oldObj.toObject ? options.oldObj.toObject() : options.oldObj;

    // oldObj is the entire entry (not just data)
    var logs = fields.filter(LogServer._logFilterFunction.bind(null, oldObj, newObj)).map(field => ({
      logName,
      text: '!defaultUpdate',
      parameter: oldObj._id,
      field,
      from: oldObj.data[field],
      to: newObj[field],
      user: userId
    }));
    LogServer.log(serverUri, logs).catch(err => {
      process.stderr.write("Could not log: ".concat(err));
    });
  }
  constructor(serverUri) {
    this._serverUri = serverUri;
  }
  getLogs() {
    return LogServer.getLogs(this._serverUri);
  }
  log(logName, text, user) {
    var parameter = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;
    var field = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : null;
    var from = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : null;
    var to = arguments.length > 6 && arguments[6] !== undefined ? arguments[6] : null;
    var contuitId = arguments.length > 7 && arguments[7] !== undefined ? arguments[7] : null;
    return LogServer.log(this._serverUri, logName, text, user, parameter, field, from, to, contuitId);
  }
  findLogEntries(logName) {
    var query = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    return LogServer.findLogEntries(this._serverUri, logName, query);
  }
  logUpdate(options) {
    var logs = (0, _isArray.default)(options) ? options.map(o => ({
      logName: o.logName,
      text: '!defaultUpdate',
      user: o.user,
      parameter: o.parameter,
      field: o.field,
      from: o.from,
      to: o.to
    })) : [{
      logName: options.logName,
      text: '!defaultUpdate',
      user: options.user,
      parameter: options.parameter,
      field: options.field,
      from: options.from,
      to: options.to
    }];
    return LogServer.log(this._serverUri, logs);
  }
}
var _default = exports.default = LogServer;