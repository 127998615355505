// import _ from 'underscore';
import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Row } from '../../../../../utility/UiComponents';
import VaultSelectInput from '../../../../shared/form/VaultSelectInput';
import {
  loadVaultStructureData as loadVaultStructureDataAction,
  loadVaultStructure as loadVaultStructureAction
} from '../../../../../actions/vault';

const selectRequiredValidator = value => (value && value !== 'none' ? undefined : 'Required');

class VaultInputStep extends React.Component {
  render() {
    const { step } = this.props;

    // console

    return (
      <div className="vault-select">
        <Row>
          <VaultSelectInput
            name="id"
            slug={step.stepOptions.slug}
            columns={6}
            validate={selectRequiredValidator}
            normalize={value => (value === 'none' ? null : value)}
            enableEmpty
          />
        </Row>
      </div>
    );
  }
}

VaultInputStep.propTypes = {
  step: PropTypes.shape().isRequired
};

VaultInputStep.defaultProps = {};

// for the form
const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      loadVaultStructureData: loadVaultStructureDataAction,
      loadVaultStructure: loadVaultStructureAction
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(VaultInputStep);
