import superagent from 'superagent';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import get from 'lodash/get';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { LinkContainer } from 'react-router-bootstrap';
import { Button, Well } from '../../utility/UiComponents';
import PageLayout from '../shared/Layout/PageLayout';
import Alert from '../shared/Alert';
import marketplace from '../../config/marketplace.json';
import { useParams } from 'react-router';

const ShowMarketplaceSolution = ({ params }) => {
  const [solutionGroup, setSolutionGroup] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const { solutionId } = params;

  useEffect(() => {
    loadProject();

    // Clean up the document title on component unmount
    return () => {
      document.title = 'Contuit';
    };
  }, []);

  const getSolutionPath = () => {
    const solution = marketplace.find((s) => s.id === solutionId);
    return solution?.path;
  };

  const loadProject = () => {
    setLoading(true);
    setError('');

    const solutionPath = getSolutionPath();

    superagent
      .get(
        `https://contuit-assets.s3.us-east-2.amazonaws.com/${
          process.env.NODE_ENV === 'development' ? 'dev_' : ''
        }SolutionGroups/${solutionPath}.json`,
      )
      .then((res) => {
        const solutionGroupData = JSON.parse(res.text);
        setSolutionGroup(solutionGroupData);
        document.title = `Contuit | ${solutionGroupData.name}`;
      })
      .catch((e) =>
        setError(`Unknown error loading solutionGroup: ${e.message}`),
      )
      .finally(() => setLoading(false));
  };

  return (
    <PageLayout
      header={
        !loading && solutionGroup
          ? solutionGroup.name
          : 'Loading solution group...'
      }
      description={get(solutionGroup, 'marketingDescription')}
      headerButtons={
        !loading && solutionGroup ? (
          <LinkContainer
            style={{ marginTop: 13 }}
            to={{ pathname: `/marketplace/${solutionGroup._id}/import` }}
          >
            <Button size="xs" variant="primary" className="pull-right">
              Import
            </Button>
          </LinkContainer>
        ) : (
          ''
        )
      }
      loading={loading}
      content={
        <div>
          {error && <Alert error message={error} />}

          {!error && getSolutionPath() === 'duo' && (
            <div>
              <h4>Included Solutions</h4>
              <Well
                style={{
                  maxWidth: '300px',
                  float: 'left',
                  marginRight: '10px',
                }}
              >
                <h5 style={{ fontWeight: 'bold' }}>
                  Automated Connectwise Billing Sync
                </h5>
                <ul>
                  <li>Auto-sync from Duo to ConnectWise Agreements</li>
                  <li>Pre-built solution</li>
                </ul>
              </Well>
              <Well
                style={{
                  maxWidth: '300px',
                  float: 'left',
                  marginRight: '10px',
                }}
              >
                <h5 style={{ fontWeight: 'bold' }}>Bypass Code Generation</h5>
                <ul>
                  <li>Simplify the top help desk requirement for Duo users</li>
                  <li>Utilize slack bots</li>
                  <li>
                    Solves for MFA + shared credentials for admin accounts
                  </li>
                </ul>
              </Well>
            </div>
          )}
          {!error && getSolutionPath() === 'kvsa-cwm' && (
            <div>
              <h4>Included Solutions</h4>
              <Well
                style={{
                  maxWidth: '300px',
                  float: 'left',
                  marginRight: '10px',
                }}
              >
                <h5 style={{ fontWeight: 'bold' }}>KVSA / CWM Sync</h5>
                <ul>
                  <li>Auto-sync from Kaseya to ConnectWise Agreements</li>
                </ul>
              </Well>
            </div>
          )}
          {!error && getSolutionPath() === 'Uberscan' && (
            <div>
              <h4>Included Solutions</h4>
              <Well
                style={{
                  maxWidth: '300px',
                  float: 'left',
                  marginRight: '10px',
                }}
              >
                <h5 style={{ fontWeight: 'bold' }}>Uberscan</h5>
                <ul>
                  <li />
                </ul>
              </Well>
            </div>
          )}
        </div>
      }
    />
  );
};

const mapStateToProps = (state) => ({
  browser: state.browser,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ShowMarketplaceSolution);
