import React from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import ShowDuoLayout from './ShowDuoLayout';

class ShowMarketing extends React.Component {
  render() {
    return (
      <ShowDuoLayout
        explanation={(
          <div style={{ textAlign: 'center', maxWidth: '500px', margin: '0 auto' }}>
            <p>Thank you for signing up for Contuit!</p>
            <p>
              We take security very seriously at Contuit. The next step is to protect your Contuit
              Console with Duo MFA. Note you&apos;ll need to be a Duo Admin to complete this step.
              If you aren&apos;t already a Duo MSP partner,
              {' '}
              <a
                href="https://duo.com/msp-program-contuit"
                target="_blank"
                rel="noopener noreferrer"
              >
                sign up here
              </a>
              {' '}
              to get your first 50 licenses for Free AND start at the 20% off tier! (In order to
              fully set up the Contuit Duo integrations you&apos;ll also need to be a ConnectWise
              Admin as well)
            </p>
            <p>
              If you&apos;re ready to proceed
              {' '}
              <LinkContainer to={{ pathname: '/partners/duo/mfa' }}>
                <button type="button">START HERE</button>
              </LinkContainer>
            </p>
            <p>
              You&apos;ll find the full documentation to the process
              {' '}
              <a href="/docs/duo-sign-up" target="_blank" rel="noopener noreferrer">
                here
              </a>
              .
            </p>
            <p>
              If you have any questions about the Duo/Contuit partnership, email
              {' '}
              <a href="mailto:msp@duo.com" target="_blank" rel="noopener noreferrer">
                msp@duo.com
              </a>
              {' '}
              for support.
            </p>
            <p>
              For Contuit support, please email
              {' '}
              <a href="mailto:support@contuit.com">support@contuit.com</a>
              .
            </p>
          </div>
        )}
      />
    );
  }
}

// Define property types
ShowMarketing.propTypes = {};

ShowMarketing.defaultProps = {};

export default ShowMarketing;
