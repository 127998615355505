import React from 'react'
import PropTypes from 'prop-types'
import DateHelper from '../../../shared-helpers/DateHelper'
import UserThumbnail from '../../user/UserThumbnail'
import ShowTemplateValue from '../ShowTemplateValue'
import TrundleRow from '../../shared/TrundleRow'

const TimelineMarker = ({ icon }) => (
  <div className="marker">
    <i className={`fa fa-fw fa-${icon}`} />
  </div>
)

TimelineMarker.propTypes = {
  icon: PropTypes.string.isRequired,
}

const TimelineTimestamp = ({ timestamp }) => (
  <div className="timeline-timestamp">
    <div>{timestamp && DateHelper.formatDateOnly(timestamp, 'M/d/yy')}</div>
    <div>{timestamp && DateHelper.formatTime(timestamp)}</div>
  </div>
)

TimelineTimestamp.propTypes = {
  timestamp: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

TimelineTimestamp.defaultProps = {
  timestamp: null,
}

/**
 * Renders a single event on the execution timeline. Timeline event uses a TrundleRow
 * with the following possible trundles:
 *  - `Table` holds any results for this timeline event
 *  - `Info` holds the description for this event
 * @param props
 * @param props.execution - the execution this timeline belongs to
 * @param props.timestamp - the time that this event happened
 * @param props.name - the main text to show for this event
 * @param props.results - any results that should be displayed for this event
 * @param props.conditions - any conditions that this event passed or failed
 * @param props.description - any description that should be shown for this event
 */
const TimelineEvent = ({
  faded,
  execution,
  executionStepId,
  timestamp,
  name,
  results,
  stepOptions,
  conditions,
  description,
  icon,
  content,
  user,
  showLogLink = false,
}) => {
  const trundles = []
  const actions = []

  if (description) {
    trundles.push({
      icon: 'info',
      body: (
        <ShowTemplateValue
          currentUser={user}
          input={description}
          execution={execution}
          skipCollapse
        />
      ),
    })
  }

  if (stepOptions) {
    trundles.push({
      icon: 'cog',
      body: stepOptions,
    })
  }

  if (conditions) {
    trundles.push({
      icon: 'code-fork',
      body: conditions,
    })
  }

  if (results) {
    trundles.push({
      icon: 'table',
      body: results,
    })
  }

  if (showLogLink) {
    actions.push({
      icon: 'list',
      title: 'grafana logs',
      onClick: () => {
        const encodedLauncher = btoa(
          JSON.stringify({
            isEntitled: true,
            query: executionStepId,
            eventTypes: ['Log'],
          }),
        )

        window.open(
          `https://one.newrelic.com/launcher/logger.log-launcher?platform[accountId]=1&launcher=${encodedLauncher}`,
          '_blank',
        )
      },
    })
  }

  const suffix = []

  if (user) {
    suffix.push(<UserThumbnail user={user} inline popover radius="25px" />)
  }

  return (
    <TrundleRow
      className={`timeline-event ${faded && 'faded'}`}
      prefix={[
        <TimelineTimestamp timestamp={timestamp} />,
        <TimelineMarker icon={icon} />,
      ]}
      content={
        <div className="timeline-title" style={{ display: 'flex' }}>
          {name}
        </div>
      }
      actions={actions}
      trundles={trundles}
      suffix={suffix}
      alwaysOn={content}
    />
  )
}

TimelineEvent.propTypes = {
  faded: PropTypes.bool,
  timestamp: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  execution: PropTypes.shape().isRequired,
  user: PropTypes.shape(),
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  content: PropTypes.node,
  results: PropTypes.node,
  conditions: PropTypes.node,
  stepOptions: PropTypes.node,
  description: PropTypes.node,
  icon: PropTypes.string,
}

TimelineEvent.defaultProps = {
  faded: false,
  timestamp: null,
  results: null,
  conditions: null,
  stepOptions: null,
  description: null,
  content: null,
  name: null,
  user: null,
  icon: 'check-square-o',
}

export default TimelineEvent

export { TimelineMarker, TimelineTimestamp }
