import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { LinkContainer } from 'react-router-bootstrap';
import { Col, Row, ProgressBar, Button } from '../../../../../utility/UiComponents';

class ChildProcessItem extends Component {
  render() {
    const { execution, title, hideProgress } = this.props;
    return (
      <Row key={execution._id} style={{ marginBottom: '5px' }} className="indented">
        <Col xs={hideProgress ? 6 : 4}>
          <span>{title}</span>
        </Col>
        {!hideProgress && (
          <Col xs={hideProgress ? 6 : 4}>
            <ProgressBar
              now={execution.progress * 100}
              label={`${Math.floor(execution.progress * 100)}%`}
            />
          </Col>
        )}
        <Col xs={hideProgress ? 6 : 4}>
          <LinkContainer
            to={{
              pathname: `/processes/${execution.process._id}/executions/${execution._id}`
            }}
          >
            <Button size="xsmall" variant="primary">
              View Execution
            </Button>
          </LinkContainer>
        </Col>
      </Row>
    );
  }
}

ChildProcessItem.propTypes = {
  execution: PropTypes.shape().isRequired,
  title: PropTypes.string.isRequired,
  hideProgress: PropTypes.bool.isRequired
};

export default ChildProcessItem;
