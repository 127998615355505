export const formulaOptions = [
  {
    id: 'stringFunctions',
    name: 'String Functions',
    parameters: [
      { id: 'htmlToMarkdown', name: 'Html To Markdown', chainable: true, parameters: [] },
      { id: 'trim', name: 'Trim', chainable: true, parameters: [] },
      {
        id: 'replace',
        name: 'Replace',
        chainable: true,
        parameters: [
          { name: 'pattern', required: true },
          { name: 'replacement', required: false }
        ]
      },
      {
        id: 'substring',
        name: 'Substring',
        chainable: true,
        parameters: [
          { name: 'start', required: true },
          { name: 'end', required: false }
        ]
      },
      {
        id: 'slice',
        name: 'Slice',
        chainable: true,
        parameters: [
          { name: 'start', required: true },
          { name: 'end', required: false }
        ]
      },
      { id: 'toUpperCase', name: 'To Upper Case', chainable: true, parameters: [] },
      { id: 'toLowerCase', name: 'To Lower Case', chainable: true, parameters: [] },
      {
        id: 'concat',
        name: 'Concat',
        chainable: true,
        parameters: [{ name: 'otherString', required: true }]
      },
      {
        id: 'startsWith',
        name: 'Starts With',
        chainable: false,
        parameters: [{ name: 'searchString', required: true }]
      },
      {
        id: 'endsWith',
        name: 'Ends With',
        chainable: false,
        parameters: [{ name: 'searchString', required: true }]
      },
      {
        id: 'charAt',
        name: 'Char At',
        chainable: true,
        parameters: [{ name: 'index', required: true }]
      },
      {
        id: 'match',
        name: 'Match',
        chainable: true,
        parameters: [{ name: 'pattern', required: true }]
      },
      {
        id: 'includes',
        name: 'Contains',
        chainable: false,
        parameters: [{ name: 'searchString', required: true }]
      },
      {
        id: 'greater-than',
        name: 'Greater Than',
        chainable: false,
        parameters: [{ name: 'otherString', required: true }]
      },
      {
        id: 'less-than',
        name: 'Less Than',
        chainable: false,
        parameters: [{ name: 'otherString', required: true }]
      },
      {
        id: 'equal',
        name: 'Equal',
        chainable: false,
        parameters: [{ name: 'otherString', required: true }]
      }
    ]
  },
  {
    id: 'dateFunctions',
    name: 'Date Functions',
    parameters: [
      {
        id: 'add',
        name: 'Add',
        chainable: true,
        parameters: [
          { name: 'value', required: true },
          {
            name: 'unit',
            required: true,
            options: [
              { id: 'milliseconds', name: 'milliseconds' },
              { id: 'seconds', name: 'seconds' },
              { id: 'minutes', name: 'minutes' },
              { id: 'hours', name: 'hours' },
              { id: 'days', name: 'days' },
              { id: 'weeks', name: 'weeks' },
              { id: 'months', name: 'months' },
              { id: 'quarters', name: 'quarters' },
              { id: 'years', name: 'years' }
            ]
          }
        ]
      },
      {
        id: 'subtract',
        name: 'Subtract',
        chainable: true,
        parameters: [
          { name: 'value', required: true },
          {
            name: 'unit',
            required: true,
            options: [
              { id: 'milliseconds', name: 'milliseconds' },
              { id: 'seconds', name: 'seconds' },
              { id: 'minutes', name: 'minutes' },
              { id: 'hours', name: 'hours' },
              { id: 'days', name: 'days' },
              { id: 'weeks', name: 'weeks' },
              { id: 'months', name: 'months' },
              { id: 'quarters', name: 'quarters' },
              { id: 'years', name: 'years' }
            ]
          }
        ]
      },
      {
        id: 'format',
        name: 'Format',
        chainable: true,
        parameters: [
          {
            name: 'format',
            required: true,
            options: [
              { id: 'YYYY-MM-DD', name: 'YYYY-MM-DD' },
              { id: 'MM-DD-YYYY', name: 'MM-DD-YYYY' },
              { id: 'DD-MM-YYYY', name: 'DD-MM-YYYY' },
              { id: 'YYYY-MM-DD hh:mm:ss A', name: 'YYYY-MM-DD hh:mm:ss A' },
              { id: 'MM-DD-YYYY hh:mm:ss A', name: 'MM-DD-YYYY hh:mm:ss A' },
              { id: 'DD-MM-YYYY hh:mm:ss A', name: 'DD-MM-YYYY hh:mm:ss A' },
              { id: 'YYYY-MM-DD HH:mm:ss', name: 'YYYY-MM-DD HH:mm:ss' },
              { id: 'MM-DD-YYYY HH:mm:ss', name: 'MM-DD-YYYY HH:mm:ss' },
              { id: 'DD-MM-YYYY HH:mm:ss', name: 'DD-MM-YYYY HH:mm:ss' }
            ]
          }
        ]
      },
      {
        id: 'diff',
        name: 'Diff',
        chainable: true,
        parameters: [
          { name: 'date', required: true },
          {
            name: 'unit',
            required: true,
            options: [
              { id: 'milliseconds', name: 'milliseconds' },
              { id: 'seconds', name: 'seconds' },
              { id: 'minutes', name: 'minutes' },
              { id: 'hours', name: 'hours' },
              { id: 'days', name: 'days' },
              { id: 'weeks', name: 'weeks' },
              { id: 'months', name: 'months' },
              { id: 'quarters', name: 'quarters' },
              { id: 'years', name: 'years' }
            ]
          }
        ]
      },
      {
        id: 'is-same',
        name: 'Is Same',
        chainable: false,
        parameters: [{ name: 'date', required: true }]
      },
      {
        id: 'is-after',
        name: 'Is After',
        chainable: false,
        parameters: [{ name: 'date', required: true }]
      },
      {
        id: 'is-before',
        name: 'Is Before',
        chainable: false,
        parameters: [{ name: 'date', required: true }]
      },
      {
        id: 'is-between',
        name: 'Is Between',
        chainable: false,
        parameters: [
          { name: 'start', required: true },
          { name: 'end', required: true }
        ]
      },
      {
        id: 'is-start-of-day',
        name: 'Is Start Of Day',
        chainable: false,
        parameters: []
      },
      {
        id: 'is-end-of-day',
        name: 'Is End Of Day',
        chainable: false,
        parameters: []
      },
      {
        id: 'is-start-of-week',
        name: 'Is Start Of Week',
        chainable: false,
        parameters: []
      },
      {
        id: 'is-end-of-week',
        name: 'Is End Of Week',
        chainable: false,
        parameters: []
      },
      {
        id: 'is-start-of-month',
        name: 'Is Start Of Month',
        chainable: false,
        parameters: []
      },
      {
        id: 'is-end-of-month',
        name: 'Is End Of Month',
        chainable: false,
        parameters: []
      },
      {
        id: 'is-start-of-quarter',
        name: 'Is Start Of Quarter',
        chainable: false,
        parameters: []
      },
      {
        id: 'is-end-of-quarter',
        name: 'Is End Of Quarter',
        chainable: false,
        parameters: []
      },
      {
        id: 'is-start-of-year',
        name: 'Is Start Of Year',
        chainable: false,
        parameters: []
      },
      {
        id: 'is-end-of-year',
        name: 'Is End Of Year',
        chainable: false,
        parameters: []
      },
      {
        id: 'is-leap-year',
        name: 'Is Leap Year',
        chainable: false,
        parameters: []
      },
      {
        id: 'is-weekend',
        name: 'Is Weekend',
        chainable: false,
        parameters: []
      },
      {
        id: 'is-weekday',
        name: 'Is Weekday',
        chainable: false,
        parameters: []
      },
      {
        id: 'date-to-timestamp',
        name: 'Date To Timestamp',
        chainable: false,
        parameters: []
      },
      {
        id: 'timestamp-to-date',
        name: 'Timestamp To Date',
        chainable: true,
        parameters: []
      }
    ]
  },
  {
    id: 'numberFunctions',
    name: 'Number Functions',
    parameters: [
      {
        id: 'add',
        name: 'Add',
        chainable: true,
        parameters: [{ name: 'value', required: true }]
      },
      {
        id: 'subtract',
        name: 'Subtract',
        chainable: true,
        parameters: [{ name: 'value', required: true }]
      },
      {
        id: 'multiply',
        name: 'Multiply',
        chainable: true,
        parameters: [{ name: 'value', required: true }]
      },
      {
        id: 'divide',
        name: 'Divide',
        chainable: true,
        parameters: [{ name: 'value', required: true }]
      },
      {
        id: 'remainder',
        name: 'Remainder',
        chainable: true,
        parameters: [{ name: 'value', required: true }]
      },
      {
        id: 'power',
        name: 'Power',
        chainable: true,
        parameters: [{ name: 'value', required: true }]
      },
      {
        id: 'greater-than',
        name: 'Greater Than',
        chainable: false,
        parameters: [{ name: 'value', required: true }]
      },
      {
        id: 'less-than',
        name: 'Less Than',
        chainable: false,
        parameters: [{ name: 'value', required: true }]
      },
      {
        id: 'equal',
        name: 'Equal',
        chainable: false,
        parameters: [{ name: 'value', required: true }]
      },
      {
        id: 'less-than-or-equal',
        name: 'Less Than Or Equal',
        chainable: false,
        parameters: [{ name: 'value', required: true }]
      },
      {
        id: 'greater-than-or-equal',
        name: 'Greater Than Or Equal',
        chainable: false,
        parameters: [{ name: 'value', required: true }]
      },
      {
        id: 'round',
        name: 'Round',
        chainable: true,
        parameters: []
      },
      {
        id: 'ceil',
        name: 'Ceil',
        chainable: true,
        parameters: []
      },
      {
        id: 'floor',
        name: 'Floor',
        chainable: true,
        parameters: []
      },
      {
        id: 'abs',
        name: 'Abs',
        chainable: true,
        parameters: []
      },
      {
        id: 'sqrt',
        name: 'Sqrt',
        chainable: true,
        parameters: []
      },
      {
        id: 'min',
        name: 'Min',
        chainable: true,
        parameters: [{ name: 'value', required: true }]
      },
      {
        id: 'max',
        name: 'Max',
        chainable: true,
        parameters: [{ name: 'value', required: true }]
      }
    ]
  },
  {
    id: 'logicalFunctions',
    name: 'Logical Functions',
    parameters: [
      {
        id: 'isTrue',
        name: 'Is True',
        chainable: true,
        parameters: []
      },
      {
        id: 'isFalse',
        name: 'Is False',
        chainable: true,
        parameters: []
      },
      {
        id: 'not',
        name: 'Not',
        chainable: true,
        parameters: []
      },
      {
        id: 'and',
        name: 'And',
        chainable: true,
        parameters: [{ name: 'value', required: true }]
      },
      {
        id: 'or',
        name: 'Or',
        chainable: true,
        parameters: [{ name: 'value', required: true }]
      },
      {
        id: 'exclusive-or',
        name: 'Exclusive Or',
        chainable: true,
        parameters: [{ name: 'value', required: true }]
      },
      {
        id: 'equal',
        name: 'Equal',
        chainable: true,
        parameters: [{ name: 'value', required: true }]
      },
      {
        id: 'ternary',
        name: 'Ternary',
        chainable: false,
        parameters: [
          { name: 'trueValue', required: true },
          { name: 'falseValue', required: true }
        ]
      }
    ]
  }
];
