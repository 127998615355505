import { TemplateInput } from 'lib/acromyrmex'
import BasicUiHelper from './BasicUiHelper'
import { DELAY_STEP_TYPE } from '../../../constants'

class DelayUiHelper extends BasicUiHelper {
  stepOptions() {
    return {
      title: 'Delay Step Options',
      fields: ({ outputOptions }) => [
        {
          name: 'delay',
          component: TemplateInput,
          label: 'Delay in seconds',
          options: outputOptions,
        },
      ],
    }
  }

  canTryAgain() {
    return false
  }

  regex() {
    return DELAY_STEP_TYPE
  }

  getOutputKeys() {
    return {}
  }

  getInputOptions() {
    return []
  }

  getOutputOptions() {
    return []
  }

  getOptionSummary() {
    return []
  }
}

export default DelayUiHelper
