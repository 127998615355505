import moment from 'moment'

class UiUtility {
  // takes the data array within vault state and determines if the slug has been loaded yet
  static getTableSort(uiState, name) {
    const table = uiState.tables[name]
    return table && table.sort ? table.sort : {}
  }

  // Gets filter objects for a table. `filterKey` is optional, if omitted
  // it will return entire object
  static getTableFilter(uiState, name, filterKey) {
    const table = uiState.tables[name]

    // if we have a filter key
    if (filterKey && table && table.filters) {
      return table.filters[filterKey]
    }

    return table && table.filters ? table.filters : {}
  }

  static getTableNewData(uiState, name) {
    const table = uiState.tables[name]
    return table && table.newData ? table.newData : false
  }

  static getActiveTab(uiState, name) {
    return (uiState.tabs && uiState.tabs[name]) || 0
  }

  static getActiveSecondaryTab(uiState, name) {
    const secondaryName = `${name}-secondary`

    return (uiState.tabs && uiState.tabs[secondaryName]) || 0
  }

  static _formatDateWithString(date, string) {
    const toParse = isNaN(date) ? date : Number(date)
    let tryFormat
    if (typeof toParse === 'number') {
      tryFormat = moment.unix(toParse).format(string)
    } else {
      tryFormat = moment(toParse).format(string)
    }

    if (tryFormat === 'Invalid date') {
      return date
    }

    return tryFormat
  }

  static formatDate(ui, date) {
    const { dateFormat } = ui

    return UiUtility._formatDateWithString(date, dateFormat)
  }

  static formatDateTime(ui, date) {
    const { dateTimeFormat } = ui

    return UiUtility._formatDateWithString(date, dateTimeFormat)
  }
}

export default UiUtility
