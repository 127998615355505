import { thunk } from 'redux-thunk'
import { applyMiddleware, createStore, combineReducers, compose } from 'redux'
import { reducer as formReducer } from 'redux-form'
import {
  responsiveStateReducer,
  responsiveStoreEnhancer,
} from 'redux-responsive'

import ui from './reducers/ui'
import vault from './reducers/vault'
import processes from './reducers/processes'
import integrations from './reducers/integrations'
import enabledIntegrations from './reducers/enabledIntegrations'
import log from './reducers/log'
import onboarding from './reducers/onboarding'
import upload from './reducers/upload'
import users from './reducers/users'

const reducer = combineReducers({
  ui,
  users,
  vault,
  processes,
  integrations,
  enabledIntegrations,
  onboarding,
  upload,
  log,
  form: formReducer,
  browser: responsiveStateReducer,
})

const logStateMiddleware = () => (next) => (action) => {
  if (action.type.indexOf('@@redux-form/') < 0) {
    // log the action type
  }

  // now we must call next(action) to propagate and finally dispatch the action object
  next(action)
}

const store = createStore(
  reducer,
  compose(responsiveStoreEnhancer, applyMiddleware(thunk, logStateMiddleware)),
)

export default store
