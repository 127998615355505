import { BASIC_STEP_TYPE } from '../../../constants'

class BasicUiHelper {
  static getLengthAttribute(step) {
    return {
      id: JSON.stringify({ step: step._id, key: '$c_length' }),
      name: 'length',
      textValue: `{${step._id}.$c_length}`,
      type: 'number',
    }
  }

  static getStepOutputAttribute(step) {
    return [
      {
        id: JSON.stringify({ step: step._id, key: '$c_stepOutput' }),
        name: 'Step Output',
        textValue: `{${step._id}.$c_stepOutput}`,
        type: 'string',
      },
      {
        id: JSON.stringify({ step: step._id, key: '$c_stepJson' }),
        name: 'Step JSON',
        textValue: `{${step._id}.$c_stepJson}`,
        type: 'string',
      },
    ]
  }

  liveVault() {
    return false
  }

  stepOptions() {
    return {
      title: 'Basic Step Options',
    }
  }

  stepSkipped() {
    return () => null
  }

  stepExecution() {
    return null
  }

  stepAutomating() {
    return null
  }

  getStepButtons() {
    return ['submit']
  }

  regex() {
    return BASIC_STEP_TYPE
  }

  name() {
    return 'Basic'
  }

  canTryAgain() {
    return true
  }

  disableConditions() {
    return false
  }

  getOutputKeys() {
    return {}
  }

  getInputOptions() {
    return []
  }

  getOutputOptions() {
    return []
  }

  getOptionSummary() {
    return []
  }

  /**
   * Returns an object representing the length for a collection
   * @param {object}  step  the step object the collection is returned from
   */
  getLengthAttribute(step) {
    return BasicUiHelper.getLengthAttribute(step)
  }

  getStepOutputAttribute(step) {
    return BasicUiHelper.getStepOutputAttribute(step)
  }

  getStepObject() {
    return {
      regex: this.regex(),
      name: this.name(),
      canTryAgain: this.canTryAgain(),
      disableConditions: this.disableConditions(),
      liveVault: this.liveVault(),
      stepOptions: this.stepOptions(),
      stepSkipped: this.stepSkipped(),
      stepExecution: this.stepExecution(),
      stepAutomating: this.stepAutomating(),
      getOutputKeys: this.getOutputKeys,
      getOptionSummary: this.getOptionSummary(),
      getStepButtons: this.getStepButtons,
      getInputOptions: this.getInputOptions,
      getOutputOptions: this.getOutputOptions,
    }
  }
}

export default BasicUiHelper
