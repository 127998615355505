export function setNavState(navHide = true) {
  return {
    type: 'SET_NAV_STATE',
    navHide,
  }
}

export function loadingChanged(isLoading) {
  return {
    type: 'IS_LOADING',
    isLoading,
  }
}

export function submittingChanged(isSubmitting) {
  return {
    type: 'IS_SUBMITTING',
    isSubmitting,
  }
}

export function setOpenTab(name, tab) {
  return {
    type: 'SET_TAB',
    name,
    tab,
  }
}

export function setTableFilter(name, filter) {
  return {
    type: 'SET_TABLE_FILTER',
    name,
    filter,
  }
}

// filter is form { key, value }
export function clearTableFilter(name, filter) {
  return {
    type: 'CLEAR_TABLE_FILTER',
    name,
    filter,
  }
}

export function clearTableFilters(name) {
  return {
    type: 'CLEAR_TABLE_FILTERS',
    name,
  }
}

export function setTableSort(name, sortBy, sortDirection) {
  return {
    type: 'SET_TABLE_SORT',
    name,
    sortBy,
    sortDirection,
  }
}

export function setTableNewData(name, newData = true) {
  return {
    type: 'SET_TABLE_NEW_DATA',
    name,
    newData,
  }
}
