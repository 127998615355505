import React from 'react';
import PropTypes from 'prop-types';

const FlexBox = ({ children, style }) => {
  return <div style={{ display: 'flex', ...style }}>{children}</div>;
};

FlexBox.propTypes = {
  children: PropTypes.node.isRequired,
  style: PropTypes.shape()
};

FlexBox.defaultProps = {
  style: {}
};

const FlexRow = ({ children, style }) => {
  return <FlexBox style={{ flexDirection: 'row', ...style }}>{children}</FlexBox>;
};

FlexRow.propTypes = {
  children: PropTypes.node.isRequired,
  style: PropTypes.shape()
};

FlexRow.defaultProps = {
  style: {}
};

const FlexColumn = ({ children, style }) => {
  return <FlexBox style={{ flexDirection: 'column', ...style }}>{children}</FlexBox>;
};

FlexColumn.propTypes = {
  children: PropTypes.node.isRequired,
  style: PropTypes.shape()
};

FlexColumn.defaultProps = {
  style: {}
};

const FlexFill = ({ children, style }) => {
  return <div style={{ flex: 1, ...style }}>{children}</div>;
};

FlexFill.propTypes = {
  children: PropTypes.node.isRequired,
  style: PropTypes.shape()
};

FlexFill.defaultProps = {
  style: {}
};

export { FlexBox, FlexRow, FlexColumn, FlexFill };
