import React from 'react';
import PropTypes from 'prop-types';
import PickListItem from './PickListItem';
import AddButton from '../../shared/form/AddButton';

const PickListArray = ({ fields }) => {
  const rows = fields.map((member, index) => (
    <PickListItem item={member} fields={fields} index={index} key={member} />
  ));

  return (
    <div className="clearfix">
      {rows}
      <AddButton size="sm" onClick={() => fields.push('')} label="Add Option" />
    </div>
  );
};

PickListArray.propTypes = {
  fields: PropTypes.shape()
};

PickListArray.defaultProps = {
  fields: {}
};

export default PickListArray;
