import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import StripeCheckout from 'react-stripe-checkout';
import {
  TextInput, CheckboxInput, Loading,
} from 'lib/acromyrmex';
import { historyPush } from '../../history';
import { Col, Row, Button } from '../../utility/UiComponents';
import {
  setCurrentUser as setCurrentUserAction,
  logoutCurrentUser as logoutCurrentUserAction,
} from '../../actions/users';
import request from '../../utility/request';
import Alert from '../shared/Alert';

class ShowDuoTenantAdminForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      companyName: '',
      pendingPackages: '',
      adminName: '',
      adminPassword: '',
      confirmPassword: '',
      adminEmail: '',

      loading: false,
      error: null,
    };
  }

  UNSAFE_componentWillMount() {
    const { logoutCurrentUser } = this.props;

    logoutCurrentUser(false);
  }

  onAddBillingLaterClicked = () => {
    const {
      companyName, adminPassword, adminEmail, adminName, pendingPackages,
    } = this.state;

    this.fnApiWrapper(
      request
        .post('/api/license/onboard/assisted-signup')
        .send({
          companyName, adminPassword, adminEmail, adminName, pendingPackages,
        }),
    );
  };

  onToken = (token, addresses) => {
    const {
      companyName, adminPassword, adminEmail, adminName, pendingPackages,
    } = this.state;

    this.fnApiWrapper(
      request.post('/api/license/onboard/stripe-token').send({
        token,
        addresses,
        companyName,
        adminPassword,
        adminEmail,
        adminName,
        pendingPackages,
      }),
    );
  };

  fnApiWrapper(apiFunction) {
    const { setCurrentUser } = this.props;
    this.setState({ loading: true, error: '' });
    apiFunction
      .then(({ body }) => {
        if (body.success) {
          setCurrentUser(body.user);
          historyPush('/partners/duo/thank-you');
        } else {
          let { message } = body;

          if (message.code === 'email_invalid') {
            message = 'Please enter a valid email';
          }

          this.setState({ error: `Error: ${message}` });
        }
      })
      .catch((e) => this.setState({ error: `Error: ${e.message}` }))
      .then(() => this.setState({ loading: false }));
  }

  render() {
    const {
      companyName,
      adminPassword,
      confirmPassword,
      adminEmail,
      adminName,
      pendingPackages,
      terms,
      loading,
      error,
    } = this.state;

    const buttonsEnabled = !loading
      && companyName
      && adminPassword
      && confirmPassword === adminPassword
      && adminEmail
      && adminName
      && terms;
    return (
      <div>
        <Row className="content-row">
          <Col xs={12}>
            <h3 style={{ margin: '5px 0' }}>Ready to Get Started?</h3>
          </Col>
          <Col xs={12}>{error && <Alert danger message={error} />}</Col>
          <TextInput
            label="Company Name"
            type="text"
            input={{
              value: companyName,
              onChange: ({ target: { value } }) => this.setState({ companyName: value }),
              onBlur: () => {},
            }}
            meta={{}}
          />
          <TextInput
            label="New Admin Name"
            type="text"
            input={{
              value: adminName,
              onChange: ({ target: { value } }) => this.setState({ adminName: value }),
              onBlur: () => {},
            }}
            meta={{}}
          />
          <TextInput
            label="New Admin Email"
            type="email"
            input={{
              value: adminEmail,
              onChange: ({ target: { value } }) => this.setState({ adminEmail: value }),
              onBlur: () => {},
            }}
            meta={{}}
          />
          <TextInput
            label="New Admin password"
            type="password"
            input={{
              value: adminPassword,
              onChange: ({ target: { value } }) => this.setState({ adminPassword: value }),
              onBlur: () => {},
            }}
            meta={{}}
          />
          <TextInput
            label="Confirm password"
            type="password"
            input={{
              value: confirmPassword,
              onChange: ({ target: { value } }) => this.setState({ confirmPassword: value }),
              onBlur: () => {},
            }}
            meta={{
              error: adminPassword === confirmPassword ? null : 'Passwords do not match',
            }}
          />
          <CheckboxInput
            label={(
              <span>
                I agree to
                {' '}
                <a href="/terms-and-conditions" target="_blank">
                  Contuit Terms and Conditions
                </a>
              </span>
            )}
            input={{
              value: terms,
              onChange: ({ target }) => target && this.setState({ terms: target.checked }),
              onBlur: () => {},
            }}
            meta={{}}
          />
          <Col xs={12} style={{ textAlign: 'right' }}>
            <StripeCheckout
              stripeKey="pk_test_yMFgn9JVw1AgwEw6Dkx6sMRb00kixTKB1c"
              token={this.onToken}
              allowRememberMe={false}
              email={adminEmail}
              billingAddress
              locale="auto"
              label="Set up billing"
              panelLabel="Submit"
            >
              <Button disabled={!buttonsEnabled} variant="primary" style={{ marginRight: '5px' }}>
                {loading ? <Loading text="Saving" /> : 'Set up billing'}
              </Button>
            </StripeCheckout>
            <Button
              disabled={!buttonsEnabled}
              variant="primary"
              onClick={this.onAddBillingLaterClicked}
            >
              {loading ? <Loading text="Saving" /> : 'Add billing later'}
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}

ShowDuoTenantAdminForm.propTypes = {
  setCurrentUser: PropTypes.func.isRequired,
  logoutCurrentUser: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});
const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    logoutCurrentUser: logoutCurrentUserAction,
    setCurrentUser: setCurrentUserAction,
  },
  dispatch,
);
export default connect(mapStateToProps, mapDispatchToProps)(ShowDuoTenantAdminForm);
