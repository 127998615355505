import React, { Component } from 'react';
import ConditionalExample from './computationHelp/ConditionalExample';

class ShowConditionalHelp extends Component {
  static propTypes = {};

  static defaultProps = {};

  state = {};

  render() {
    return (
      <div className="content-col clearfix" id="conditionals">
        <h3>Conditionals</h3>
        <hr />
        <p>
          Conditionals are made up of a left hand side, an operation and a right hand side. If the
          conditionals pass, the step will run. If not, it will be skipped.
        </p>
        <p>Below, you can play with the conditionals to try different results.</p>
        <ConditionalExample />
      </div>
    );
  }
}

export default ShowConditionalHelp;
