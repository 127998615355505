import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Field } from 'redux-form'
import { SelectInput, TextInput, HoverHelp } from 'lib/acromyrmex'
import PropTypes from 'prop-types'
import TableForm from '../../../shared/form/TableForm'
import { Col, Row, Button, Accordion } from '../../../../utility/UiComponents'
import {
  requiredValidator,
  selectRequiredValidator,
} from '../../../../utility/formValidators'
import ApiParameterList from '../ApiParameterList'
import IntegrationSelect from '../../steps/components/stepOptions/integration/IntegrationSelect'
import AttributeHelper from '../../../../shared-helpers/AttributeHelper'
import AddButton from '../../../shared/form/AddButton'
import { MOP } from '../../../../constants'

const callbackServer = MOP || ''

class TriggerInitiationOptions extends React.Component {
  constructor(props) {
    super(props)

    this.renderParams = this.renderParams.bind(this)
  }

  renderParams({ fields }) {
    const { change, integrationOptions, settingsOptions, initiation, array } =
      this.props
    return (
      <ApiParameterList
        fields={fields}
        change={change}
        integrationOptions={integrationOptions}
        outputOptions={settingsOptions}
        stepType={initiation.initiationOptions.triggerType}
        currentOptions={initiation.initiationOptions}
        array={array}
        fieldText="initiation.stepOptions"
      />
    )
  }

  renderDataList = ({ fields }) => {
    const rows = fields.map((fieldText, index) =>
      this.renderDataOption(fieldText, fields, index),
    )

    return (
      <div>
        {rows}
        <Row className="step-option-buttons">
          <Col xs={12}>
            <AddButton size="sm" onClick={() => fields.push({})} label="Add Data Field" />
          </Col>
        </Row>
      </div>
    )
  }

  renderDataOption(fieldText, fields, index) {
    const { updateOutputOptions, formValues } = this.props

    const onChangeFactory = (replaceField) => (a, newValue) => {
      const newArray = [...formValues.initiation.initiationOptions.incomingData]

      newArray[index] = { ...newArray[index], [replaceField]: newValue }

      updateOutputOptions({
        initiationOptionsOverride: {
          ...formValues.initiation.initiationOptions,
          incomingData: newArray,
        },
      })
    }

    return (
      <Row style={{ marginBottom: 10 }} key={fieldText}>
        <Col xs={5}>
          <Field
            name={`${fieldText}.key`}
            component={TextInput}
            noLabel
            label="Label"
            validate={requiredValidator}
            onChange={onChangeFactory('key')}
          />
        </Col>
        <Col xs={6}>
          <Field
            name={`${fieldText}.type`}
            component={SelectInput}
            noLabel
            label="Type"
            validate={selectRequiredValidator}
            options={AttributeHelper.getComputationTypes()}
            onChange={onChangeFactory('type')}
          />
        </Col>
        <Col xs={1}>
          <Button
            variant="danger"
            onClick={() => fields.remove(index)}
            title="Remove this parameter"
          >
            <i className="fa fa-trash" />
          </Button>
        </Col>
      </Row>
    )
  }

  render() {
    const {
      triggerTypes,
      change,
      updateOutputOptions,
      formValues,
      outputOptionsLoaded,
    } = this.props

    const triggerType =
      formValues.initiation &&
      formValues.initiation.initiationOptions &&
      formValues.initiation.initiationOptions.triggerType

    return (
      <Accordion defaultActiveKey="1">
        <Accordion.Item eventKey="1">
          <Accordion.Header>Trigger Initiation Options</Accordion.Header>
          <Accordion.Body>
            <Field
              name="initiation.initiationOptions.triggerType"
              component={SelectInput}
              label="Trigger Type"
              validate={requiredValidator}
              options={[
                { id: 'generic', name: 'Generic Trigger' },
                ...triggerTypes,
              ]}
              onChange={(a, newValue) => {
                // To not lose the description when step type changes
                change('initiation.initiationOptions.parameters', [])
                updateOutputOptions({ triggerType: newValue })
              }}
            />
            {triggerType && (
              <IntegrationSelect
                name="initiation.initiationOptions.integration"
                stepType={triggerType}
                outputOptionsLoaded={outputOptionsLoaded}
              />
            )}
            {triggerType && triggerType !== 'generic' && (
              <TableForm
                name="initiation.initiationOptions.parameters"
                headers={[
                  { title: 'Label', xs: 5 },
                  { title: 'Value', xs: 6 },
                ]}
                fieldArrayComponent={this.renderParams}
              />
            )}
            {triggerType && triggerType === 'generic' && (
              <div>
                <div className="clearfix" style={{ marginBottom: '10px' }}>
                  <Col xs={4}>
                    <strong className="">Trigger URL</strong>{' '}
                    <HoverHelp
                      help="Use this url to register the webhook on an external system"
                      position="top"
                    />
                  </Col>
                  <Col
                    xs={8}
                  >{`${callbackServer}/trigger/generic/process/${formValues._id}?id=`}</Col>
                </div>
                <Col style={{ display: 'flex' }}>
                  <Col xs={4}>
                    <strong className="">Incoming Data</strong>
                  </Col>
                  <Col xs={8}>
                    <TableForm
                      name="initiation.initiationOptions.incomingData"
                      headers={[
                        { title: 'Key', xs: 5 },
                        { title: 'Type', xs: 6 },
                      ]}
                      fieldArrayComponent={this.renderDataList}
                    />
                  </Col>
                </Col>
              </div>
            )}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    )
  }
}

// Define property types
TriggerInitiationOptions.propTypes = {
  triggerTypes: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  change: PropTypes.func.isRequired,
  integrationOptions: PropTypes.shape().isRequired,
  settingsOptions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  initiation: PropTypes.shape(),
  array: PropTypes.shape().isRequired,
  formValues: PropTypes.shape().isRequired,
  updateOutputOptions: PropTypes.func.isRequired,
  outputOptionsLoaded: PropTypes.bool.isRequired,
}

TriggerInitiationOptions.defaultProps = {
  initiation: {
    initiationOptions: {},
  },
  settingsOptions: [],
  triggerTypes: [],
}

const mapStateToProps = (state) => ({
  structureList: state.vault.structureList.rows,
  formValues: state.form.processForm ? state.form.processForm.values : {},
})

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TriggerInitiationOptions)
