import { TemplateInput } from 'lib/acromyrmex'
import BasicUiHelper from './BasicUiHelper'
import { CREATE_PDF_STEP_TYPE } from '../../../constants'

class CreatePdfUiHelper extends BasicUiHelper {
  regex() {
    return CREATE_PDF_STEP_TYPE
  }

  name() {
    return 'Create PDF'
  }

  stepOptions() {
    return {
      title: 'Create PDF Step Options',
      fields: ({ outputOptions }) => [
        {
          name: 'fileName',
          component: TemplateInput,
          label: 'File Name',
          options: outputOptions,
          plainText: true,
        },
        {
          name: 'content',
          component: TemplateInput,
          label: 'File content',
          options: outputOptions,
          plainText: true,
        },
      ],
    }
  }

  getOutputKeys() {
    const schema = {
      file: {
        type: 'File',
        title: 'File',
      },
    }

    return { schema }
  }

  getOutputOptions(step) {
    return [
      {
        id: JSON.stringify({
          step: step._id,
          field: 'file',
        }),
        name: `File`,
        textValue: `{${step._id}.file}`,
        type: 'file',
      },
    ]
  }
}

export default CreatePdfUiHelper
