import _ from 'underscore';
import React, { Component, createRef } from 'react';
import { bindActionCreators } from 'redux';
import { Loading } from 'lib/acromyrmex';
import { connect } from 'react-redux';
import { ProcessServer } from '@contuit-otf/sdk';
import PropTypes from 'prop-types';
import { Row, Alert, ProgressBar } from '../../../../../utility/UiComponents';
import ChildProcessItem from './ChildProcessItem';
import StringHelper from '../../../../../shared-helpers/StringHelper';
import EmptyView from '../../../../shared/EmptyView';
import Collapsible from '../../../../shared/Collapsible';
import request from '../../../../../utility/request';
import {
  getExecutionStepByProcessStepId,
  getProcessStepFromExecutionStep,
} from '../../../../../shared-helpers/execution';

const processServer = new ProcessServer('/api/processes', request);

class ChildProcessList extends Component {
  static propTypes = {
    currentExecutionStep: PropTypes.shape().isRequired,
    execution: PropTypes.shape().isRequired,
    hideProgress: PropTypes.bool,
    skipCollapse: PropTypes.bool,
    executionList: PropTypes.arrayOf(PropTypes.string).isRequired,
    currentUser: PropTypes.shape({
      token: PropTypes.string.isRequired,
    }).isRequired,
  };

  static defaultProps = {
    skipCollapse: false,
    hideProgress: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      childExecutions: [],
      childExecutionsLoading: false,
      childExecutionLoadError: '',
      offset: 0,
      hasMore: true,
    };
    this.scrollRef = createRef();
    this.handleScroll = _.debounce(this.handleScroll.bind(this), 100);
  }

  componentDidMount() {
    this.loadExecutions();
    setTimeout(() => {
      if (this.scrollRef.current) {
        this.scrollRef.current.addEventListener('scroll', this.handleScroll);
      }
    }, 100);
  }

  componentDidUpdate(prevProps) {
    const { executionList } = this.props;
    if (executionList.length !== prevProps.executionList.length) {
      this.setState(
        { offset: 0, hasMore: true, childExecutions: [] },
        this.loadExecutions,
      );
    }
  }

  componentWillUnmount() {
    if (this.scrollRef.current) {
      this.scrollRef.current.removeEventListener('scroll', this.handleScroll);
    }
  }

  handleScroll() {
    const { offset, hasMore, childExecutionsLoading } = this.state;
    if (!this.scrollRef.current || childExecutionsLoading || !hasMore) return;

    const { scrollTop, scrollHeight, clientHeight } = this.scrollRef.current;
    if (scrollHeight - scrollTop <= clientHeight + 10) {
      this.setState({ offset: offset + 10 }, this.loadExecutions);
    }
  }

  getExecutionBatch() {
    const { executionList } = this.props;
    const { offset } = this.state;
    return executionList.slice(offset, offset + 10); // 10 is the batch size, this is adjustable
  }

  getSourceData() {
    const { execution, currentExecutionStep } = this.props;

    if (!execution) {
      return [];
    }

    // process step for the current step
    const processStep = getProcessStepFromExecutionStep(
      execution,
      currentExecutionStep,
    );

    if (!processStep) {
      return [];
    }

    const { source } = processStep.stepOptions;
    const sourceStepId = StringHelper.getStepIdFromTemplate(source);

    if (!sourceStepId) {
      return [];
    }

    const sourceStep = getExecutionStepByProcessStepId(execution, sourceStepId);

    if (!sourceStep) {
      return [];
    }

    return sourceStep.stepOutput;
  }

  loadExecutions() {
    const {
      executionList,
      currentUser: { token },
    } = this.props;

    const executionBatch = this.getExecutionBatch();

    if (!executionList || !executionList.length) {
      return;
    }

    this.setState({ childExecutionsLoading: true });
    processServer
      .findExecutions({
        token,
        query: {
          cp_limit: -1,
          cp_offset: 0,
          cp_sort: '-progress',
          _id: executionBatch,
        },
        shell: true,
      })
      .then(({ items: data, totalItemCount }) => {
        // also comes with totalItemCount
        this.setState((prevState) => ({
          childExecutions: prevState.childExecutions.concat(data),
          offset: prevState.offset + totalItemCount,
          hasMore:
            prevState.offset + totalItemCount < this.props.executionList.length,
        }));
      })
      .catch(() => {
        this.setState({
          childExecutionLoadError: 'Unknown error loading child executions.',
        });
      })
      .then(() => {
        this.setState({ childExecutionsLoading: false });
      });
  }

  calculateOverallProgress() {
    const { childExecutions } = this.state;

    return (
      (childExecutions
        .map((c) => (c && c.progress) || 0)
        .reduce((sum, value) => sum + value, 0) /
        childExecutions.length) *
      100
    );
  }

  render() {
    const { hideProgress, skipCollapse } = this.props;

    const { childExecutionsLoading, childExecutionLoadError, childExecutions } =
      this.state;

    if (childExecutionsLoading && !childExecutions.length) {
      return <Loading text="Loading child executions..." />;
    }

    if (!childExecutionsLoading && !childExecutions.length) {
      return <EmptyView header="No child processes found" />;
    }

    if (!childExecutions.length) {
      return <Loading text="Creating child executions..." />;
    }

    if (childExecutionLoadError) {
      return (
        <div>
          <Row className="content-row">
            <Alert variant="danger" className="pull-left indented">
              <i className="fa fa-exclamation-circle" />
              {` Error loading child executions. Please try again soon. ${
                childExecutionLoadError ? 'Error details: ' : ''
              }${childExecutionLoadError}`}
            </Alert>
          </Row>
        </div>
      );
    }

    const sourceData = this.getSourceData();

    if (!sourceData || !sourceData.map) {
      return (
        <div>
          <Row className="content-row">
            <EmptyView header="Cannot find the loop's source" />
          </Row>
        </div>
      );
    }

    const totalProgress = this.calculateOverallProgress();

    const body = (
      <div className="child-processes" ref={this.scrollRef}>
        {sourceData
          .map((obj) => {
            // we want to find the child with a matching id preFilled (into any step)
            const thisChild = childExecutions.find((c) => {
              if (!c) {
                return false;
              }

              let found = false;
              _.forEach(c.preFill, (value) => {
                if (value.id === obj._id) {
                  found = true;
                }
              });
              return found;
            });

            return { obj, thisChild };
          })
          .sort()
          .map(({ obj, thisChild }) => {
            return (
              thisChild && (
                <ChildProcessItem
                  execution={thisChild}
                  key={thisChild._id}
                  title={obj.name}
                  hideProgress={hideProgress}
                />
              )
            );
          })}
      </div>
    );

    return (
      <div>
        {!hideProgress && (
          <div>
            <strong>Sub-process progress</strong>
            <ProgressBar
              now={totalProgress}
              label={`${Math.floor(totalProgress)}%`}
            />
          </div>
        )}
        {skipCollapse ? (
          body
        ) : (
          <Collapsible message="child processes" pullLeft={false}>
            <div>{body}</div>
          </Collapsible>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ currentUser: state.users.currentUser });

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ChildProcessList);
