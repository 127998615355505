import React, { useState, useRef, useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { LinkContainer } from 'react-router-bootstrap';
import { AuthenticationServer } from '@contuit-otf/sdk';
import { toast } from 'react-toastify';
import 'file-icons-js/css/style.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'font-awesome/scss/font-awesome.scss';

import { Navbar, Nav, Button, Container } from '../../utility/UiComponents';
import Navigation from './Navigation';
import { setNavState } from '../../actions/ui';
import { setCurrentUser } from '../../actions/users';
import UserThumbnail from '../user/UserThumbnail';
import request from '../../utility/request';
import { historyPush } from '../../history';
import NewTenantModal from './NewTenantModal';

// using webpack's sass-loader we can import scss file here
import '../../sass/style.scss';
import ErrorBoundary from '../shared/ErrorBoundary';

const authServer = new AuthenticationServer('/api/auth', request);

function DropdownMenu({ trigger, children, isActive, setIsActive }) {
  const dropdownRef = useRef(null);
  const triggerRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      const isOutsideClick = (ref) =>
        ref.current && !ref.current.contains(event.target);

      if (isOutsideClick(dropdownRef) && isOutsideClick(triggerRef)) {
        setIsActive(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef, triggerRef, setIsActive]);

  const handleTriggerClick = () => {
    setIsActive(!isActive);
  };

  return (
    <div className="menu-container">
      <div ref={triggerRef} onClick={handleTriggerClick}>
        {trigger}
      </div>
      <nav
        ref={dropdownRef}
        className={`menu ${isActive ? 'active' : 'inactive'}`}
      >
        {children}
      </nav>
    </div>
  );
}

DropdownMenu.propTypes = {
  trigger: PropTypes.element.isRequired,
  children: PropTypes.element.isRequired,
  isActive: PropTypes.bool.isRequired,
};

const TenantsList = ({ tenants, contuitId, onBackClick, switchTenant, closeMenu }) => {
  const isSelected = (id) => contuitId === id;
  const [expandedFolders, setExpandedFolders] = useState({});

  const handleFolderClick = (folderName) => {
    setExpandedFolders({
      ...expandedFolders,
      [folderName]: !expandedFolders[folderName],
    });
  };

  const handleSettingsClick = () => {
    closeMenu();
    historyPush(`/admin/system-settings`);
  };

  const renderTenants = (tenantList, parentKey = '', firstGroup = false) => {
    const groupedTenants = tenantList.reduce((acc, tenant) => {
      const parts = tenant.name.split('/');
      const folder = parts[0];
      const subtenant = parts.slice(1).join('/');
      const key = parentKey ? `${parentKey}/${folder}` : folder;
      if (!acc[key]) {
        acc[key] = [];
      }
      if (subtenant) {
        acc[key].push({ ...tenant, name: subtenant });
      } else {
        acc[key].push(tenant);
      }
      return acc;
    }, {});

    const singleTenants = [];

    if (firstGroup) {
      Object.keys(groupedTenants).forEach((t) => {
        if (groupedTenants[t].length === 1 && t !== groupedTenants[t][0].name) {
          singleTenants.push({ [t]: groupedTenants[t] });
          delete groupedTenants[t];
        }
      });
    }

    const renderTenant = (tenant) => (
      <li key={tenant._id} style={{ padding: '0' }}>
        <div
          style={{
            color: isSelected(tenant._id) ? '#337ab7' : '#000',
            padding: '4px 8px',
            backgroundColor: isSelected(tenant._id) ? '#f5f5f5' : 'transparent',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <button
            onClick={() => switchTenant(tenant._id)}
            type="button"
            style={{
              padding: '0',
              border: 'none',
              background: 'transparent',
              color: 'inherit',
              cursor: 'pointer',
              display: 'flex',
              justifyContent: 'space-between',
              // width: '100%'
              flex: 1,
              marginRight: '5%',
            }}
          >
            {tenant.name}{' '}
            {isSelected(tenant._id) && <i className="fa fa-check" />}
          </button>
          {isSelected(tenant._id) && (
            <Button
              variant="link"
              onClick={handleSettingsClick}
              style={{ paddingTop: '0px' }}
            >
              <i className="fa fa-cog" />
            </Button>
          )}
        </div>
      </li>
    );

    const renderFolder = (folder, t, isSingleTenant = false) => (
      <li
        key={folder}
        style={{
          padding: '0px 3px',
          borderBottom: '1px solid #ccc',
        }}
      >
        <div
          onClick={() => handleFolderClick(folder)}
          style={{
            cursor: 'pointer',
            color: '#000',
            fontWeight: 'bold',
            display: 'flex',
            justifyContent: 'space-between',
            padding: '4px 5px',
          }}
        >
          {folder.split('/').pop()} {expandedFolders[folder] ? '▲' : '▼'}
        </div>
        {expandedFolders[folder] && (
          <ul
            style={{
              listStyleType: 'none',
              padding: '0',
              margin: '0 0 0 10px',
            }}
          >
            {isSingleTenant ? renderTenant(t[0]) : renderTenants(t, folder)}
          </ul>
        )}
      </li>
    );

    return (
      <>
        {firstGroup &&
          singleTenants.map((singleTenant) => {
            const [folder, tenants] = Object.entries(singleTenant)[0];
            return renderFolder(folder, tenants, true);
          })}
        {Object.keys(groupedTenants)
          .sort()
          .map((folder) =>
            groupedTenants[folder].length === 1 &&
            !groupedTenants[folder][0].name.includes('/')
              ? renderTenant(groupedTenants[folder][0])
              : renderFolder(folder, groupedTenants[folder]),
          )}
      </>
    );
  };

  return (
    <div
      style={{
        borderRadius: '5px',
        backgroundColor: '#fff',
        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
      }}
    >
      <div
        style={{
          display: 'flex',
          padding: '10px',
          alignItems: 'center',
        }}
      >
        <button
          onClick={onBackClick}
          type="button"
          style={{
            padding: 0,
            border: 'none',
            background: 'transparent',
            color: '#000',
            marginRight: '10px',
            cursor: 'pointer',
          }}
        >
          <i className="fa fa-arrow-left" />
        </button>
        <h5
          style={{
            textAlign: 'center',
            color: '#000',
            fontSize: '1.125rem',
            fontWeight: 'bold',
            margin: 0,
            flex: 1,
          }}
        >
          Switch tenant
        </h5>
      </div>
      <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
        <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
          {renderTenants(tenants, '', true)}
        </ul>
      </div>
    </div>
  );
};

TenantsList.propTypes = {
  tenants: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  contuitId: PropTypes.string.isRequired,
  onBackClick: PropTypes.func.isRequired,
  switchTenant: PropTypes.func.isRequired,
};

function Layout() {
  const dispatch = useDispatch();
  const {
    users: { currentUser },
    ui: { navHide },
  } = useSelector((state) => state);
  const customer = currentUser.contuitClient;
  const singleStepUser =
    currentUser && currentUser.userRoles.indexOf('single-step') > -1;
  const onNavButtonClicked = () => dispatch(setNavState(!navHide));

  const [isActive, setIsActive] = useState(false);
  const [showTenants, setShowTenants] = useState(false);
  const [showNewTenant, setShowNewTenant] = useState(false);
  const [switchingTenants, setSwitchingTenants] = useState(false);

  const switchTenant = async (tenantId) => {
    try {
      if(tenantId === customer._id) {
        return;
      }

      setSwitchingTenants(true);

      setIsActive(true);
      await authServer.updateUser({
        token: currentUser.token,
        user: { ...currentUser, contuitId: tenantId },
      });
      window.location.href = '/dashboards/personal';
    } catch (err) {
      switchTenant(false);
      toast.error(`Error switching tenants: ${err.message}`);
    }
  };

  return (
    <div
      className={classNames('body', {
        'single-step': singleStepUser,
        'logged-in': currentUser,
        'nav-hide': navHide,
      })}
    >
      {/* if logged in, show menu */}
      {currentUser && <Navigation />}
      <div className="main">
        <Navbar>
          <Container fluid>
            <div className="pull-left">
              <form className="navbar-form">
                {currentUser && (
                  <Button className="nav-button" onClick={onNavButtonClicked}>
                    <i className="fa fa-bars" />
                  </Button>
                )}
              </form>
            </div>
            {customer && (
              <div
                className={`${
                  navHide ? '' : 'hide-customer'
                } navbar-customer customer-name`}
              >
                {customer.name}
              </div>
            )}
            {!currentUser && (
              <div className="container" style={{ paddingTop: 0 }}>
                <LinkContainer to={{ pathname: '/' }}>
                  <div className="marketing-brand">
                    <img src="/images/logo_white.png" alt="logo" />
                  </div>
                </LinkContainer>
                <Nav className="justify-content-end">
                  <LinkContainer to={{ pathname: '/login' }}>
                    <Nav.Item eventkey={1}>
                      <i className="fa fa-sign-in" />
                      Sign In
                    </Nav.Item>
                  </LinkContainer>
                </Nav>
              </div>
            )}
            <Nav
              className={navHide ? '' : 'hide-on-mobile'}
              as="ul"
              style={{ alignItems: 'center' }}
            >
              {currentUser && (
                <Nav.Item eventKey={1} as="li">
                  <DropdownMenu
                    isActive={isActive}
                    setIsActive={setIsActive}
                    trigger={
                      <Nav.Item
                        as="li"
                        style={{
                          cursor: 'pointer',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                        onClick={() => setIsActive(!isActive)}
                        className="user"
                      >
                        <div className="portrait">
                          <UserThumbnail user={currentUser} radius="50px" />
                        </div>
                        <div className="name">{currentUser.name}</div>
                      </Nav.Item>
                    }
                  >
                    {showTenants ? (
                      <ul>
                        <TenantsList
                          switchingTenants={switchingTenants}
                          tenants={currentUser.tenants}
                          contuitId={currentUser.contuitId}
                          onBackClick={() => setShowTenants(!showTenants)}
                          closeMenu={() => setIsActive(!isActive)}
                          switchTenant={switchTenant}
                        />
                      </ul>
                    ) : (
                      <ul>
                        <li>
                          <button
                            style={{
                              width: '100%',
                              background: 'none',
                              border: 'none',
                              textAlign: 'left',
                              color: '#000',
                            }}
                            type="button"
                            onClick={() => {
                              historyPush(`/admin/users/${currentUser._id}`);
                              setIsActive(!isActive);
                            }}
                          >
                            <LinkContainer
                              to={{
                                pathname: `/admin/users/${currentUser._id}`,
                              }}
                            >
                              <>
                                <i className="fa fa-fw fa-cog" /> Settings
                              </>
                            </LinkContainer>
                          </button>
                        </li>
                        <li>
                          <button
                            style={{
                              width: '100%',
                              background: 'none',
                              border: 'none',
                              textAlign: 'left',
                              color: '#000',
                            }}
                            type="button"
                            onClick={() => setShowTenants(!showTenants)}
                          >
                            <i className="fa fa-retweet" /> Switch Tenant
                          </button>
                        </li>
                        <li>
                          <button
                            style={{
                              width: '100%',
                              background: 'none',
                              border: 'none',
                              textAlign: 'left',
                              color: '#000',
                            }}
                            type="button"
                            onClick={() => setShowNewTenant(true)}
                          >
                            <>
                              <i className="fa fa-plus" /> Create New Tenant
                            </>
                          </button>
                        </li>
                      </ul>
                    )}
                  </DropdownMenu>
                </Nav.Item>
              )}
              {currentUser && (
                <Nav.Item eventkey={2} as="li">
                  <a
                    style={{ display: 'block', padding: '15px' }}
                    href="/logout"
                  >
                    <i className="fa fa-sign-out" />
                    <span className="sign-out-text"> Sign Out</span>
                  </a>
                </Nav.Item>
              )}
            </Nav>
          </Container>
        </Navbar>
        <div className="content">
          <ErrorBoundary>
            <Outlet />
          </ErrorBoundary>
        </div>
        <NewTenantModal
          showModal={showNewTenant}
          onHide={() => setShowNewTenant(false)}
          currentUser={currentUser}
          setCurrentUser={setCurrentUser}
        />
      </div>
    </div>
  );
}

export default Layout;
