const INTEGRATION_INITIAL = {
  availableIntegrations: {
    rows: [],
    isLoading: false,
  },
  availableIntegration: {
    value: null,
    isLoading: false,
  },
}

export default (state = INTEGRATION_INITIAL, action = {}) => {
  switch (action.type) {
    case 'ADD_AVAILABLE_INTEGRATIONS':
      return {
        ...state,
        availableIntegrations: {
          ...state.availableIntegrations,
          rows: action.data,
        },
      }
    case 'AVAILABLE_INTEGRATIONS_LOADING': {
      const nextState = Object.assign({}, state)

      nextState.availableIntegrations.isLoading = action.loading

      return nextState
    }
    case 'ADD_AVAILABLE_INTEGRATION':
      return {
        ...state,
        availableIntegration: {
          ...state.availableIntegrations,
          value: action.data,
        },
      }
    case 'AVAILABLE_INTEGRATION_LOADING': {
      const nextState = Object.assign({}, state)

      nextState.availableIntegration.isLoading = action.loading

      return nextState
    }
    default:
      return state
  }
}
