import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row, Accordion} from '../../../../../utility/UiComponents'

class StepOptionWrapper extends Component {
  static propTypes = {
    children: PropTypes.node,
    fieldText: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }

  static defaultProps = {
    children: null,
  }

  state = {}

  render() {
    const { children, fieldText, title } = this.props

    return (
      <Row key={fieldText} style={{ marginBottom: 10 }}>
        <Accordion defaultActiveKey="1">
          <Accordion.Item eventKey="1">
            <Accordion.Header>{`${title} Step Options`}</Accordion.Header>
            <Accordion.Body>{children}</Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Row>
    )
  }
}

export default StepOptionWrapper
