import React from 'react'
import classNames from 'classnames'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { LinkContainer } from 'react-router-bootstrap'
import { Icon, LoadingButton } from 'lib/acromyrmex'
import {
  Button,
  Table,
  OverlayTrigger,
  Tooltip,
} from '../../utility/UiComponents'
import { deleteEntry as deleteEntryAction } from '../../actions/vault'
import ConfirmModal from '../shared/ConfirmModal'

class VaultEntryButtons extends React.Component {
  constructor(props) {
    super(props)

    this.state = { showDelete: false }
  }

  renderDeleteEntryMessage() {
    const { entry } = this.props

    return (
      <div>
        <p>Are you sure you want to delete the following Vault Entry?</p>
        <Table striped bordered condensed hover>
          <thead>
            <tr>
              <th>Customer</th>
              <th>Name</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{entry.customer}</td>
              <td>{entry.name}</td>
            </tr>
          </tbody>
        </Table>
      </div>
    )
  }

  render() {
    const { isDeleting, deleteEntry, entry, slug, viewDetails, pullRight } =
      this.props
    const { showDelete } = this.state

    return (
      <div
        className={classNames({
          'pull-right': pullRight,
        })}
      >
        {viewDetails && (
          <div style={{ marginRight: 5, display: 'inline' }}>
            <LinkContainer
              to={{
                pathname: `/vault/${slug}/${entry._id}`,
              }}
            >
              <Button variant="primary" style={{ marginRight: 5 }} size="xs">
                View Details
              </Button>
            </LinkContainer>
          </div>
        )}
        <div style={{ marginRight: 5, display: 'inline' }}>
          <LinkContainer
            to={{
              pathname: `/vault/${slug}/${entry._id}/edit`,
            }}
          >
            <Button variant="primary" size="xs">
              <Icon edit />
            </Button>
          </LinkContainer>
        </div>
        {entry.$c_system && (
          <div
            style={{
              paddingTop: '3px',
              display: pullRight ? 'block' : 'inline',
            }}
            className={classNames({
              'pull-right': pullRight,
              //   'pull-left': !pullRight
            })}
          >
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id="tooltip">Contuit Core Entry</Tooltip>}
            >
              <i className="fa fa-lock" style={{ marginLeft: '3px' }} />
            </OverlayTrigger>
          </div>
        )}
        {!entry.$c_system && (
          <LoadingButton
            variant="danger"
            style={{ marginRight: 5 }}
            size="xs"
            loading={isDeleting}
            loadingLabel=""
            onClick={() => {
              this.setState({ showDelete: true })
            }}
            label={<i className="fa fa-trash" />}
          />
        )}
        <ConfirmModal
          show={showDelete}
          title="Confirm Deleting Vault Entry"
          message={`Are you sure you want to delete the vault entry: ${entry.name}?`}
          onConfirm={() => {
            deleteEntry(entry._id, slug)
            this.setState({ showDelete: false })
          }}
          onCancel={() => {
            this.setState({ showDelete: false })
          }}
        />
      </div>
    )
  }
}

VaultEntryButtons.propTypes = {
  slug: PropTypes.string.isRequired,
  entry: PropTypes.shape().isRequired,
  isDeleting: PropTypes.bool,
  deleteEntry: PropTypes.func.isRequired,
  viewDetails: PropTypes.bool,
  pullRight: PropTypes.bool,
}

VaultEntryButtons.defaultProps = {
  isDeleting: false,
  viewDetails: false,
  pullRight: false,
}

const mapStateToProps = (state, props) => {
  const { slug } = props
  return {
    isDeleting: state.vault.data[slug]
      ? state.vault.data[slug].isDeleting
      : false,
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      deleteEntry: deleteEntryAction,
    },
    dispatch,
  )

export default connect(mapStateToProps, mapDispatchToProps)(VaultEntryButtons)
