import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useAsync } from 'react-use';
import { ProcessServer } from '@contuit-otf/sdk';
import request from '../../../utility/request';
import ShowProcessHeader from './ShowProcessHeader';
import ShowProcessSidebar from './ShowProcessSidebar';
import ShowProcessExecutionTable from './ShowProcessExecutionTable';
import PageLayout from '../../shared/Layout/PageLayout';
import { useParams } from 'react-router';

const processServer = new ProcessServer('/api/processes', request);

// Returns a Promise that resolves after one second.
const findProcess = (token, processId) =>
  processServer.findProcess({ token, processId });

const ShowProcess = (props) => {
  const {
    currentUser: { token },
    currentUser,
    routes,
  } = props;
  const { processId } = useParams();

  const state = useAsync(findProcess.bind(this, token, processId));
  const [scheduleCount, setScheduleCount] = useState(0);

  if (state.loading) {
    return <div>Loading...</div>;
  }

  if (state.error) {
    return <div>Error...</div>;
  }

  const contuitProcess = state.value;

  return (
    <PageLayout
      currentUser={currentUser}
      routes={routes}
      header={
        <ShowProcessHeader
          contuitProcess={contuitProcess}
          onProcessScheduled={() => setScheduleCount(scheduleCount + 1)}
        />
      }
      content={
        <ShowProcessExecutionTable
          currentUser={currentUser}
          processId={processId}
        />
      }
      noContentPadding
      rightSidebar={
        <ShowProcessSidebar
          contuitProcess={contuitProcess}
          currentUser={currentUser}
          scheduleCount={scheduleCount}
        />
      }
    />
  );
};

ShowProcess.propTypes = {
  currentUser: PropTypes.shape().isRequired,
  routes: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

const mapStateToProps = (state) => ({
  currentUser: state.users.currentUser,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ShowProcess);
