import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { HoverHelp } from 'lib/acromyrmex'
import { FieldArray } from 'redux-form'
import PropTypes from 'prop-types'
import {
  Row,
  Col,Accordion,
  Alert,
} from '../../../../../utility/UiComponents'
import DateFunctionFormList from '../dateFunction/DateFunctionFormList'

// eslint-disable-next-line
class DateFunctionStepOptions extends React.Component {
  renderFunctions = ({ fields }) => {
    const { change, browser, outputOptions, onChangeAttributeNeeded } =
      this.props

    return (
      <DateFunctionFormList
        fields={fields}
        outputOptions={outputOptions}
        change={change}
        hideLabel={browser.greaterThan.medium}
        onChangeAttributeNeeded={onChangeAttributeNeeded}
        hideCollectionFeatures
      />
    )
  }

  render() {
    const { fieldText, processStep } = this.props

    return (
      <Row key={fieldText} style={{ marginBottom: 10 }}>
        <Accordion defaultActiveKey="1">
          <Accordion.Item eventKey="1">
            <Accordion.Header>Date Function Step Options</Accordion.Header>
            <Accordion.Body>
              {(!processStep.stepOptions ||
                !processStep.stepOptions.functions ||
                !processStep.stepOptions.functions.length) && (
                <div className="clearfix">
                  <Alert variant="danger" className="pull-left">
                    <i className="fa fa-exclamation-circle" /> Date Function
                    step requires at least one function. Add one with the button
                    to the right!
                  </Alert>
                </div>
              )}
              <Row>
                <Col md={3}>
                  <strong>Key</strong>{' '}
                  <HoverHelp
                    help="What name do you want to use for this computation?"
                    position="top"
                  />
                </Col>
                <Col md={3}>
                  <strong>Input</strong>{' '}
                  <HoverHelp
                    help="What sort of data will the computation result in?"
                    position="top"
                  />
                </Col>
                <Col md={2}>
                  <strong>Function Name</strong>{' '}
                  <HoverHelp
                    help="Do you want this attribute to be shown in lists, overview information?"
                    position="top"
                  />
                </Col>
                <Col>
                  <strong>Parameters</strong>{' '}
                  <HoverHelp
                    help="Do you want this attribute to be shown in lists, overview information?"
                    position="top"
                  />
                </Col>
              </Row>
              <FieldArray
                name={`${fieldText}.stepOptions.functions`}
                component={this.renderFunctions}
              />
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Row>
    )
  }
}

// Define property types
DateFunctionStepOptions.propTypes = {
  browser: PropTypes.shape().isRequired,
  fieldText: PropTypes.string.isRequired,
  processStep: PropTypes.shape().isRequired,
  outputOptions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  change: PropTypes.func.isRequired,
  onChangeAttributeNeeded: PropTypes.func.isRequired,
}

DateFunctionStepOptions.defaultProps = {}

const mapStateToProps = (state) => ({
  browser: state.browser,
  structureList: state.vault.structureList.rows,
})

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DateFunctionStepOptions)
