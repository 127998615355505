import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// import { Button, Modal } from '../../utility/UiComponents';

class TriggerStep extends React.PureComponent {
  render() {
    const { step } = this.props;
    if (!step) {
      return null;
    }

    return (
      <div>
        <p>Trigger execution!.</p>
      </div>
    );
  }
}

TriggerStep.propTypes = {
  step: PropTypes.shape().isRequired
};

TriggerStep.defaultProps = {};

// const mapStateToProps = (state, props) => ({});
const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TriggerStep);
