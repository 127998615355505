import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from '../../utility/UiComponents';
import AutoDataTable from './DataTable/AutoDataTable';

// eslint-disable-next-line
class DataModalNew extends React.Component {
  // eslint-disable-next-line
  constructor(props) {
    super(props);

    this.handleSelect = this.handleSelect.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);
    this.state = {
      selectedData: props.selectedData
    };
  }

  handleConfirm() {
    const { onConfirm, onCancel } = this.props;
    const { selectedData } = this.state;

    onConfirm(selectedData);
    onCancel();
  }

  handleSelect(selected) {
    this.setState({
      selectedData: selected
    });
  }

  render() {
    const { onCancel, title, show, data } = this.props;
    const { selectedData } = this.state;

    return (
      <div>
        <Modal
          show={show}
          onHide={onCancel}
          className="image-preview"
          size="large"
          dialogClassName="data-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>{title || 'Confirm?'}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div
              style={{
                maxWidth: '100%',
                maxHeight: '100%',
                overflow: 'auto'
              }}
            >
              <AutoDataTable
                selectable
                title={false}
                idAttribute={false}
                data={data}
                onSelect={selected => {
                  this.handleSelect(selected.map(string => JSON.parse(string)));
                }}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={this.handleConfirm}>
              Finish Selection ({selectedData.length}
              {' Selected)'}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

DataModalNew.propTypes = {
  data: PropTypes.oneOfType([PropTypes.shape(), PropTypes.arrayOf(PropTypes.shape())]).isRequired,
  title: PropTypes.string,
  show: PropTypes.bool,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  selectedData: PropTypes.arrayOf(PropTypes.shape())
};

DataModalNew.defaultProps = {
  title: null,
  show: false,
  onCancel: () => {},
  onConfirm: () => {},
  selectedData: []
};

export default DataModalNew;
