import React, { useEffect } from 'react';
import { logoutCurrentUser } from '../../actions/users';

function ShowLogout() {
  useEffect(() => {
    logoutCurrentUser();
  }, []);

  return <h1>Logging out...</h1>;
}

export default ShowLogout;
