import superagent from 'superagent';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { toast } from 'react-toastify';
import ProcessButtons from './ProcessButtons';
import {
  deleteProcess as deleteProcessAction,
  loadProcess as loadProcessAction,
  openDisableProcessModal as openDisableProcessModalAction,
  openEnableProcessModal as openEnableProcessModalAction,
  updateProcess as updateProcessAction
} from '../../actions/processes';
import { createExecution as createExecutionAction } from '../../actions/executions';
import {SCHEDULER_SERVER} from "../../constants"

const onScheduleProcess = (
  { currentUser: { token, _id: userId }, contuitProcess, onProcessScheduled = () => {} },
  setScheduling
) => (values, closeModal) => {
  setScheduling(true);
  superagent
    .post(SCHEDULER_SERVER)
    .send({ ...values, processId: contuitProcess._id, userId, contuitId: contuitProcess.contuitId })
    .set({ 'x-access-token': token })
    .then(() => {
      toast.success('Schedule created!');
      closeModal();
      onProcessScheduled();
    })
    .catch()
    .then(() => setScheduling(false));
};

const ConnectedProcessButtons = props => {
  const {
    contuitProcess,
    createExecution,
    currentUser,
    onProcessScheduled,
    openEnableProcessModal,
    loadProcess,
    updateProcess,
    deleteProcess,
    isCreating,
    viewDetails,
    openDisableProcessModal
  } = props;

  const manager =
    currentUser.userRoles.indexOf('manager') > -1 || currentUser.userRoles.indexOf('admin') > -1;

  const [isScheduling, setScheduling] = useState(false);

  return (
    <ProcessButtons
      process={contuitProcess}
      manager={manager}
      createExecution={createExecution}
      isCreating={isCreating}
      deleteProcess={deleteProcess}
      viewDetails={viewDetails}
      pullRight
      loadProcess={loadProcess}
      openEnableProcessModal={openEnableProcessModal}
      openDisableProcessModal={openDisableProcessModal}
      updateProcess={updateProcess}
      isScheduling={isScheduling}
      onSchedule={onScheduleProcess(
        { contuitProcess, currentUser, onProcessScheduled },
        setScheduling
      )}
    />
  );
};

ConnectedProcessButtons.propTypes = {
  contuitProcess: PropTypes.shape().isRequired,
  onProcessScheduled: PropTypes.func,
  viewDetails: PropTypes.bool,

  // From redux state
  currentUser: PropTypes.shape().isRequired,
  isCreating: PropTypes.bool.isRequired,

  // From redux dispatch
  createExecution: PropTypes.func.isRequired,
  openDisableProcessModal: PropTypes.func.isRequired,
  openEnableProcessModal: PropTypes.func.isRequired,
  deleteProcess: PropTypes.func.isRequired,
  updateProcess: PropTypes.func.isRequired,
  loadProcess: PropTypes.func.isRequired
};

ConnectedProcessButtons.defaultProps = {
  viewDetails: false,
  onProcessScheduled: () => {}
};

const mapStateToProps = state => ({
  ui: state.ui,
  currentUser: state.users.currentUser,
  isCreating: state.processes.execution.isCreating
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      createExecution: createExecutionAction,
      openDisableProcessModal: openDisableProcessModalAction,
      openEnableProcessModal: openEnableProcessModalAction,
      deleteProcess: deleteProcessAction,
      updateProcess: updateProcessAction,
      loadProcess: loadProcessAction
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ConnectedProcessButtons);
