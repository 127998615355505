import { historyPush } from '../history';
import { startSubmit, stopSubmit } from 'redux-form';
import { ProcessServer } from '@contuit-otf/sdk';
import { toast } from 'react-toastify';
import { loadStructureList } from './vault';
import request from '../utility/request';

const processServer = new ProcessServer('/api/processes', request);

export function setProcessesLoading(loading = true) {
  return {
    type: 'PROCESSES_LOADING',
    loading,
  };
}

export function addProcesses(processes) {
  return {
    type: 'ADD_PROCESSES',
    processes,
  };
}

export function setProcessLoading(loading = true) {
  return {
    type: 'PROCESS_LOADING',
    loading,
  };
}

export function setProcessValidating(loading = true) {
  return {
    type: 'PROCESS_VALIDATION_LOADING',
    loading,
  };
}

export function addProcess(process) {
  return {
    type: 'ADD_PROCESS',
    process,
  };
}

export function addProcessValidation(validation) {
  return {
    type: 'ADD_PROCESS',
    validation,
  };
}

export function setProcessUpdating(updating = true) {
  return {
    type: 'PROCESS_UPDATING',
    updating,
  };
}

export function setProcessCreating(creating = true) {
  return {
    type: 'PROCESS_CREATING',
    creating,
  };
}

export function setProcessError(error) {
  return {
    type: 'SET_PROCESS_ERROR',
    error,
  };
}

export function setDisableProcessModal(open = true) {
  return {
    type: 'SET_DISABLE_PROCESS_MODAL',
    open,
  };
}

export function setSettingsLoading(loading = true) {
  return {
    type: 'SET_SETTINGS_LOADING',
    loading,
  };
}

export function setSettingsUpdating(loading = true) {
  return {
    type: 'SET_SETTINGS_UPDATING',
    loading,
  };
}

export function setSettingsError(error = null) {
  return {
    type: 'SET_SETTINGS_ERROR',
    error,
  };
}

export function addSettings(settings) {
  return {
    type: 'ADD_SETTINGS',
    settings,
  };
}

export function openDisableProcessModal() {
  return (dispatch) => {
    dispatch(setDisableProcessModal(true));
  };
}

export function closeDisableProcessModal() {
  return (dispatch) => {
    dispatch(setDisableProcessModal(false));
  };
}

export function setEnableProcessModal(open = true) {
  return {
    type: 'SET_ENABLE_PROCESS_MODAL',
    open,
  };
}

export function openEnableProcessModal() {
  return (dispatch) => {
    dispatch(setEnableProcessModal(true));
  };
}

export function closeEnableProcessModal() {
  return (dispatch) => {
    dispatch(setEnableProcessModal(false));
  };
}

export function loadProcesses(
  query = { showDrafts: true, showActive: true, showArchived: true },
) {
  return (dispatch, getState) => {
    const { token } = getState().users.currentUser;
    dispatch(setProcessesLoading());
    processServer.findProcesses({ token, query }).then((data) => {
      dispatch(addProcesses(data));
      dispatch(setProcessesLoading(false));
    });
  };
}

export function getAverageStepDurations(durations) {
  return {
    type: 'GET_DURATIONS',
    durations,
  };
}

export function loadAverageStepDurations(id) {
  return (dispatch) => {
    processServer.getAverageStepDurations({ id }).then((data) => {
      dispatch(getAverageStepDurations(data));
    });
  };
}

export function loadProcess(id) {
  return (dispatch, getState) => {
    const { token } = getState().users.currentUser;
    dispatch(setProcessLoading());

    processServer
      .findProcess({ token, processId: id })
      .then((data) => {
        dispatch(addProcess(data));
      })
      .catch(() => {
        const msg = 'Problem loading process. Please try again later.';
        toast.error(msg);
        dispatch(setProcessError(msg));
      })
      .finally(() => {
        dispatch(setProcessLoading(false));
      });
  };
}

export function loadProcessValidation(processId) {
  return (dispatch, getState) => {
    const { token } = getState().users.currentUser;
    dispatch(setProcessValidating());
    dispatch(startSubmit('processForm'));
    return processServer
      .validateProcess({ token, processId })
      .catch((err) => {
        if (err.validationErrors) {
          toast.error(err.message);
          dispatch(stopSubmit('processForm', err.validationErrors));
          return err.validationErrors;
        }

        toast.error(`Error validating process: ${err}`);
      })
      .then((maybeErr) => {
        dispatch(setProcessValidating(false));
        return maybeErr;
      });
  };
}

export function updateProcess(id, newProcess) {
  return (dispatch, getState) => {
    const { token } = getState().users.currentUser;
    dispatch(setProcessUpdating());
    return processServer
      .updateProcess({ token, process: newProcess })
      .then((data) => dispatch(loadStructureList()).then(() => data))
      .then((data) => {
        dispatch(addProcess(data));
        toast.success('Successfully updated process.');
      })
      .catch((err) => {
        if (err.validationErrors) {
          toast.error(err.message);
          return err.validationErrors;
        }

        const msg =
          err && err.message
            ? err.message
            : 'Unknown error updating process, please try again later.';
        toast.error(msg);
      })
      .then((maybeErr) => {
        dispatch(setProcessUpdating(false));
        return maybeErr;
      });
  };
}

export function createProcess(newProcess) {
  return (dispatch, getState) => {
    const { token } = getState().users.currentUser;
    dispatch(setProcessLoading());

    request
      .post('/api/processes/')
      .set({ 'x-access-token': token })
      .send(newProcess)
      .then(({ body: data }) => {
        dispatch(addProcess(data));

        // go back to detail page
        const url = `/processes/${data._id}`;
        historyPush(url);
      })
      .catch((xhr) => {
        const msg =
          xhr.responseJSON && xhr.responseJSON.message
            ? xhr.responseJSON.message
            : 'Unknown error creating process, please try again later.';
        toast.error(msg);
      })
      .then(() => {
        dispatch(setProcessLoading(false));
      });
  };
}

export function removeProcess(id) {
  return {
    type: 'REMOVE_PROCESS',
    id,
  };
}

export function setProcessDeleting(deleting = true) {
  return {
    type: 'PROCESS_DELETING',
    deleting,
  };
}

export function deleteProcess(processId) {
  return (dispatch, getState) => {
    const { token } = getState().users.currentUser;
    dispatch(setProcessDeleting());
    processServer
      .deleteProcess({ token, processId })
      .then(() => {
        dispatch(removeProcess(processId));
      })
      .then(() => {
        dispatch(setProcessDeleting(false));
      });
  };
}

export function loadSettings() {
  return (dispatch, getState) => {
    const { token } = getState().users.currentUser;

    dispatch(setSettingsLoading());
    return processServer
      .getSettings({ token })
      .then((settings) => {
        dispatch(addSettings(settings));
      })
      .catch((xhr) => {
        const msg =
          xhr.responseJSON && xhr.responseJSON.message
            ? xhr.responseJSON.message
            : 'Error loading settings, please try again later.';
        toast.error(msg);
        dispatch(setSettingsLoading(false));
        dispatch(setSettingsError(msg));

        return msg;
      })
      .finally(() => {
        dispatch(setSettingsLoading(false));
      });
  };
}

export function updateSettings(data) {
  return (dispatch, getState) => {
    const { token } = getState().users.currentUser;

    dispatch(setSettingsUpdating());
    return processServer
      .updateSettings({ token, data })
      .then((settings) => {
        toast.success('Settings Saved Successfully');
      })
      .catch((xhr) => {
        const msg =
          xhr.responseJSON && xhr.responseJSON.message
            ? xhr.responseJSON.message
            : 'Error updating your settings, please try again later.';
        toast.error(msg);
        dispatch(setSettingsLoading(false));
        return msg;
      })
      .finally(() => {
        dispatch(setSettingsUpdating(false));
      });
  };
}
