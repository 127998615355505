import superagent from 'superagent';
import get from 'lodash/get';
import without from 'lodash/without';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import io from 'socket.io-client';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ImportCommunityIntegrations from './ImportCommunityIntegrations';
import Alert from '../shared/Alert';
import PageLayout from '../shared/Layout/PageLayout';
import { useParams } from 'react-router';

const ShowMiniImport = ({ currentUser, routes }) => {
  const [integrations, setIntegrations] = useState([]);
  const [solutionGroup, setSolutionGroup] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const {miniBundle} = useParams()

  useEffect(() => {
    const socket = io('/integrations');
    socket.connect();
    socket.emit('set contuitId', { contuitId: currentUser.contuitId });
    socket.on('created', onIntegrationAdded);

    loadProject();

    // Clean up the socket connection on component unmount
    return () => {
      socket.disconnect();
    };
  }, []);

  const onIntegrationAdded = (enabledIntegration, bundleIntegrationId) => {
    const existing = integrations.find(i => {
      if (bundleIntegrationId) {
        return (
          i.importKey === enabledIntegration.integrationName &&
          i.bundleIntegrationId === bundleIntegrationId
        );
      }
      return i.importKey === enabledIntegration.integrationName;
    });

    if (enabledIntegration._id === '$c_create') {
      if (existing) {
        setIntegrations(prevIntegrations => without(prevIntegrations, existing));
      }
      return;
    }

    if (existing) {
      return;
    }

    if (bundleIntegrationId) {
      setIntegrations(prevIntegrations => [
        ...prevIntegrations,
        {
          importKey: enabledIntegration.integrationName,
          integrationId: enabledIntegration._id,
          bundleIntegrationId,
        },
      ]);
    } else {
      setIntegrations(prevIntegrations => [
        ...prevIntegrations,
        { importKey: enabledIntegration.integrationName, integrationId: enabledIntegration._id },
      ]);
    }
  };

  const loadProject = () => {
    setLoading(true);
    setError('');

    const solutionPath = miniBundle;

    superagent
      .get(
        `https://contuit-assets.s3.us-east-2.amazonaws.com/${
          process.env.NODE_ENV === 'development' ? 'dev_' : ''
        }SolutionGroups/mini/${solutionPath}.json`
      )
      .then(res => {
        const solutionGroupData = JSON.parse(res.text);
        setSolutionGroup(solutionGroupData);
        document.title = `Contuit | ${solutionGroupData.name}`;
      })
      .catch(e => setError(`Unknown error loading solutionGroup: ${e.message}`))
      .finally(() => setLoading(false));
  };

  return (
    <PageLayout
      header={`Import ${get(solutionGroup, 'name', '...')} - Enable Required Integrations`}
      noContentPadding
      loading={loading}
      content={
        <div>
          {error && <Alert error message={error} />}

          {solutionGroup && (
            <ImportCommunityIntegrations
              addedIntegrations={integrations}
              onIntegrationEnabled={onIntegrationAdded}
              solutionGroup={solutionGroup}
            />
          )}
        </div>
      }
      routes={routes}
      currentUser={currentUser}
    />
  );
};

ShowMiniImport.propTypes = {
  currentUser: PropTypes.shape().isRequired,
  routes: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

const mapStateToProps = state => ({
  currentUser: state.users.currentUser,
});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ShowMiniImport);
