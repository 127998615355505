import { BLOCK_BEGIN_V2_STEP_TYPE } from '../../../constants'
import BasicUiHelper from './BasicUiHelper'
import BlockBeginStepOptions from './components/stepOptions/BlockBeginStepOptions'
import BlockBeginStepSkipped from './components/stepSkipped/BlockBeginStepSkipped'
import BlockBeginStepAutomating from './components/stepAutomating/BlockBeginStepAutomating'

class BlockBeginV2UiHelper extends BasicUiHelper {
  regex() {
    return BLOCK_BEGIN_V2_STEP_TYPE
  }

  name() {
    return 'Begin Block V2'
  }

  canTryAgain() {
    return true
  }

  stepOptions() {
    return BlockBeginStepOptions
  }

  stepSkipped() {
    return BlockBeginStepSkipped
  }

  stepAutomating() {
    return BlockBeginStepAutomating
  }
}

export default BlockBeginV2UiHelper
