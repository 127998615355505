import superagent from 'superagent';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Loading } from 'lib/acromyrmex';
import PageLayout from '../shared/Layout/PageLayout';
import DocumentationMarkdown from './DocumentationMarkdown';

class ShowRemoteDocsPage extends Component {
  static propTypes = {
    path: PropTypes.string.isRequired,
    header: PropTypes.string.isRequired
  };

  static defaultProps = {};

  state = {
    md: '',
    loadingMarkdown: false
  };

  componentDidMount() {
    const { path } = this.props;

    this.setState({ loadingMarkdown: true });
    superagent
      .get(`https://contuit-assets.s3.us-east-2.amazonaws.com/docs/${path}.md`)
      .then(response => {
        this.setState({ md: response.text, loadingMarkdown: false });
      });
  }

  render() {
    const { header } = this.props;
    const { loadingMarkdown, md } = this.state;
    return (
      <PageLayout
        header={header}
        load
        content={
          loadingMarkdown ? (
            <div>
              <Loading text="loading documentation" />
            </div>
          ) : (
            <DocumentationMarkdown source={md} />
          )
        }
      />
    );
  }
}

export default ShowRemoteDocsPage;
