import React, { Component } from 'react';
import PageLayout from '../shared/Layout/PageLayout';

class ShowTerms extends Component {
  render() {
    return (
      <PageLayout
        header="Terms and Conditions"
        description="Please be aware that this integration is provided and used on an at-will basis. No guarantees for billing accuracy come from using the integration.  It is the responsibility of the Partner to ensure the accuracy of your billing. Please read all Caveats (hyperlink to caveats in docs) and to adjust accordingly."
        content={(
          <iframe
            title="Click Through License"
            src="https://contuit-assets.s3.us-east-2.amazonaws.com/Click+Through+License.pdf"
            style={{ width: '100%', height: '100%' }}
          />
        )}
      />
    );
  }
}

export default ShowTerms;
