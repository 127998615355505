"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _superagent = _interopRequireDefault(require("superagent"));
class IntegrationServer {
  static getAvailableIntegrations(serverUri, _ref) {
    var {
      token
    } = _ref;
    var request = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _superagent.default;
    if (!token) {
      throw new Error('IntegrationServer.getAvailableIntegrations requires a token');
    }
    return request.get("".concat(serverUri, "/availableIntegrations")).set('x-access-token', token).then(res => res.body);
  }
  static getAvailableIntegration(serverUri, _ref2) {
    var {
      availableIntegrationId,
      token
    } = _ref2;
    var request = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _superagent.default;
    if (!availableIntegrationId) {
      throw new Error('IntegrationServer.getAvailableIntegration requires a availableIntegrationId');
    }
    if (!token) {
      throw new Error('IntegrationServer.getAvailableIntegration requires a token');
    }
    return request.get("".concat(serverUri, "/availableIntegration")).query({
      availableIntegrationId
    }).set('x-access-token', token).then(res => res.body);
  }
  static getEnabledIntegrations(serverUri, _ref3) {
    var {
      token,
      query = {}
    } = _ref3;
    var request = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _superagent.default;
    if (!token) {
      throw new Error('IntegrationServer.getEnabledIntegrations requires a token');
    }
    return request.get("".concat(serverUri, "/")).query(query).set('x-access-token', token).then(res => res.body);
  }
  static getSimpleEnabledIntegrations(serverUri, _ref4) {
    var {
      token,
      query = {}
    } = _ref4;
    var request = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _superagent.default;
    if (!token) {
      throw new Error('IntegrationServer.getEnabledIntegrations requires a token');
    }
    return request.get("".concat(serverUri, "/simple")).query(query).set('x-access-token', token).then(res => res.body);
  }
  static getEnabledIntegration(serverUri, _ref5) {
    var {
      token,
      enabledIntegrationId
    } = _ref5;
    var request = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _superagent.default;
    if (!token) {
      throw new Error('IntegrationServer.getEnabledIntegration requires a token');
    }
    if (!enabledIntegrationId) {
      throw new Error('IntegrationServer.getEnabledIntegration requires a enabledIntegrationId');
    }
    return request.get("".concat(serverUri, "/").concat(enabledIntegrationId)).set('x-access-token', token).then(res => res.body);
  }
  static deleteEnabledIntegration(serverUri, _ref6) {
    var {
      token,
      enabledIntegrationId
    } = _ref6;
    var request = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _superagent.default;
    if (!enabledIntegrationId) {
      throw new Error('IntegrationServer.deleteEnabledIntegration requires a enabledIntegrationId');
    }
    if (!token) {
      throw new Error('IntegrationServer.deleteEnabledIntegration requires a token');
    }
    return request.delete("".concat(serverUri, "/").concat(enabledIntegrationId)).set('x-access-token', token).then(res => res.body).catch(err => {
      var {
        status,
        response: {
          body
        }
      } = err;
      var msg = status === 500 ? 'Unknown error deleting service area, please try again later.' : body.message;
      throw new Error(msg);
    });
  }
  static createEnabledIntegration(serverUri, _ref7) {
    var {
      token,
      enabledIntegration
    } = _ref7;
    var request = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _superagent.default;
    if (!enabledIntegration) {
      throw new Error('IntegrationServer.createEnabledIntegration requires a enabledIntegration');
    }
    if (!token) {
      throw new Error('IntegrationServer.createEnabledIntegration requires a token');
    }
    return request.post("".concat(serverUri, "/")).send(enabledIntegration).set('x-access-token', token).then(res => res.body).catch(err => {
      var {
        status,
        response: {
          body
        }
      } = err;
      var msg = status === 500 ? 'Unknown error creating service area, please try again later.' : body.message;
      throw new Error(msg);
    });
  }
  static updateEnabledIntegration(serverUri, _ref8) {
    var {
      token,
      enabledIntegration,
      enabledIntegrationId
    } = _ref8;
    var request = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _superagent.default;
    if (!enabledIntegration) {
      throw new Error('IntegrationServer.updateEnabledIntegration requires a enabledIntegration');
    }
    if (!enabledIntegrationId) {
      throw new Error('IntegrationServer.updateEnabledIntegration requires a enabledIntegrationId');
    }
    if (!token) {
      throw new Error('IntegrationServer.updateEnabledIntegration requires a token');
    }
    return request.put("".concat(serverUri, "/").concat(enabledIntegrationId)).send(enabledIntegration).set('x-access-token', token).then(res => res.body).catch(err => {
      var {
        status,
        response: {
          body
        }
      } = err;
      var msg = status === 500 ? 'Unknown error updating service area, please try again later.' : body.message;
      throw new Error(msg);
    });
  }

  // REGISTERED CALLBACKS

  static createRegisteredCallback(serverUri, _ref9) {
    var {
      token,
      registeredCallback
    } = _ref9;
    var request = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _superagent.default;
    if (!registeredCallback) {
      throw new Error('IntegrationServer.createEnabledIntegration requires a registeredCallback');
    }
    if (!token) {
      throw new Error('IntegrationServer.createEnabledIntegration requires a token');
    }
    return request.post("".concat(serverUri, "/registeredCallbacks/")).send(registeredCallback).set('x-access-token', token).then(res => res.body).catch(err => {
      var {
        status,
        response: {
          body
        }
      } = err;
      var msg = status === 500 ? 'Unknown error creating registered callback, please try again later.' : body.message;
      throw new Error(msg);
    });
  }
  static updateRegisteredCallback(serverUri, _ref10) {
    var {
      token,
      registeredCallback,
      registeredCallbackId
    } = _ref10;
    var request = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _superagent.default;
    if (!registeredCallback) {
      throw new Error('ReupdateRegisteredCallbackServer.updateRegisteredCallback requires a registeredCallback');
    }
    if (!token) {
      throw new Error('ReupdateRegisteredCallbackServer.updateRegisteredCallback requires a token');
    }
    return request.put("".concat(serverUri, "/registeredCallbacks/").concat(registeredCallbackId || registeredCallback._id)).send(registeredCallback).set('x-access-token', token).then(res => res.body).catch(err => {
      var {
        status,
        response
      } = err;
      console.log(err);
      var msg = status === 500 ? 'Unknown error updating service area, please try again later.' : response && response.body.message;
      throw new Error(msg);
    });
  }
  static deleteRegisteredCallback(serverUri, _ref11) {
    var {
      token,
      registeredCallbackId
    } = _ref11;
    var request = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _superagent.default;
    if (!registeredCallbackId) {
      throw new Error('ReupdateRegisteredCallbackServer.deleteRegisteredCallback requires a registeredCallbackId');
    }
    if (!token) {
      throw new Error('ReupdateRegisteredCallbackServer.deleteRegisteredCallback requires a token');
    }
    return request.delete("".concat(serverUri, "/registeredCallbacks/").concat(registeredCallbackId)).set('x-access-token', token).then(res => res.body).catch(err => {
      var {
        status,
        response
      } = err;
      console.log(err);
      var msg = status === 500 ? 'Unknown error deleting registered callback, please try again later.' : response && response.body.message;
      throw new Error(msg);
    });
  }
  static getRegisteredCallback(serverUri, _ref12) {
    var {
      token,
      query = {}
    } = _ref12;
    var request = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _superagent.default;
    if (!token) {
      throw new Error('IntegrationServer.getRegisteredCallback requires a token');
    }
    return request.get("".concat(serverUri, "/registeredCallbacks/find")).query(query).set('x-access-token', token).then(res => res.body).catch(err => {
      var {
        response
      } = err;
      var msg = response && response.body && response.body.message ? response.body.message : 'Unknown error getting registered callback, please try again later.';
      throw new Error(msg);
    });
  }

  // /REGISTERED CALLBACKS

  constructor(serverUri) {
    var request = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : _superagent.default;
    this._serverUri = serverUri;
    this._request = request;
  }
  getEnabledIntegrations() {
    var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return IntegrationServer.getEnabledIntegrations(this._serverUri, options, this._request);
  }
  getSimpleEnabledIntegrations() {
    var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return IntegrationServer.getSimpleEnabledIntegrations(this._serverUri, options, this._request);
  }
  getEnabledIntegration() {
    var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return IntegrationServer.getEnabledIntegration(this._serverUri, options, this._request);
  }
  deleteEnabledIntegration(options) {
    return IntegrationServer.deleteEnabledIntegration(this._serverUri, options, this._request);
  }
  createEnabledIntegration(options) {
    return IntegrationServer.createEnabledIntegration(this._serverUri, options, this._request);
  }
  updateEnabledIntegration(options) {
    return IntegrationServer.updateEnabledIntegration(this._serverUri, options, this._request);
  }
  getAvailableIntegrations() {
    var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return IntegrationServer.getAvailableIntegrations(this._serverUri, options, this._request);
  }
  getAvailableIntegration() {
    var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return IntegrationServer.getAvailableIntegration(this._serverUri, options, this._request);
  }

  // REGISTERED CALLBACKS

  createRegisteredCallback() {
    var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return IntegrationServer.createRegisteredCallback(this._serverUri, options, this._request);
  }
  updateRegisteredCallback() {
    var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return IntegrationServer.updateRegisteredCallback(this._serverUri, options, this._request);
  }
  deleteRegisteredCallback() {
    var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return IntegrationServer.deleteRegisteredCallback(this._serverUri, options, this._request);
  }
  getRegisteredCallback() {
    var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return IntegrationServer.getRegisteredCallback(this._serverUri, options, this._request);
  }

  // /REGISTERED CALLBACKS
}
var _default = exports.default = IntegrationServer;