"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _superagent = _interopRequireDefault(require("superagent"));
class NotificationServer {
  static getNotifications(serverUri, _ref) {
    var {
      token,
      query = {}
    } = _ref;
    var request = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _superagent.default;
    if (!token) {
      throw new Error('NotificationServer.getNotifications requires a token');
    }
    return request.get("".concat(serverUri, "/")).query(query).set('x-access-token', token).then(res => res.body);
  }
  static getNotification(serverUri, _ref2) {
    var {
      token,
      notificationId
    } = _ref2;
    var request = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _superagent.default;
    if (!token) {
      throw new Error('NotificationServer.getNotification requires a token');
    }
    if (!notificationId) {
      throw new Error('NotificationServer.getNotification requires a notificationId');
    }
    return request.get("".concat(serverUri, "/").concat(notificationId)).set('x-access-token', token).then(res => res.body);
  }
  static deleteNotification(serverUri, _ref3) {
    var {
      token,
      notificationId
    } = _ref3;
    var request = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _superagent.default;
    if (!notificationId) {
      throw new Error('NotificationServer.deleteNotification requires a notificationId');
    }
    if (!token) {
      throw new Error('NotificationServer.deleteNotification requires a token');
    }
    return request.delete("".concat(serverUri, "/").concat(notificationId)).set('x-access-token', token).then(res => res.body).catch(err => {
      var {
        status
      } = err;
      var body = err.response && err.response.body;
      var msg = status === 500 ? 'Unknown error deleting notification, please try again later.' : body && body.message;
      throw new Error(msg);
    });
  }
  static createNotification(serverUri, _ref4) {
    var {
      token,
      notification,
      user
    } = _ref4;
    var request = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _superagent.default;
    if (!notification) {
      throw new Error('NotificationServer.createNotification requires a notification');
    }
    if (!token) {
      throw new Error('NotificationServer.createNotification requires a token');
    }
    return request.post("".concat(serverUri, "/")).send({
      notification,
      user
    }).set('x-access-token', token).then(res => res.body).catch(err => {
      var {
        status
      } = err;
      var body = err.response && err.response.body;
      var msg = status === 500 ? 'Unknown error creating notification, please try again later.' : body && body.message;
      throw new Error(msg);
    });
  }
  static updateNotification(serverUri, _ref5) {
    var {
      token,
      notification,
      notificationId
    } = _ref5;
    var request = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _superagent.default;
    if (!notification) {
      throw new Error('NotificationServer.updateNotification requires a notification');
    }
    if (!notificationId) {
      throw new Error('NotificationServer.updateNotification requires a notificationId');
    }
    if (!token) {
      throw new Error('NotificationServer.updateNotification requires a token');
    }
    return request.put("".concat(serverUri, "/").concat(notificationId)).send(notification).set('x-access-token', token).then(res => res.body).catch(err => {
      var {
        status
      } = err;
      var body = err.response && err.response.body;
      var msg = status === 500 ? 'Unknown error updating notification, please try again later.' : body && body.message;
      throw new Error(msg);
    });
  }
  constructor(serverUri) {
    var request = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : _superagent.default;
    this._serverUri = serverUri;
    this._request = request;
  }
  getNotifications() {
    var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return NotificationServer.getNotifications(this._serverUri, options, this._request);
  }
  getNotification() {
    var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return NotificationServer.getNotification(this._serverUri, options, this._request);
  }
  deleteNotification(options) {
    return NotificationServer.deleteNotification(this._serverUri, options, this._request);
  }
  createNotification(options) {
    return NotificationServer.createNotification(this._serverUri, options, this._request);
  }
  updateNotification(options) {
    return NotificationServer.updateNotification(this._serverUri, options, this._request);
  }
}
var _default = exports.default = NotificationServer;