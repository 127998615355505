import _ from 'underscore';
import AttributeHelper from '../modules/vaultStructures/attributes/UiAttributeHelper';
import StringHelper from '../shared-helpers/StringHelper';

class Vault {
  // takes the data array within vault state and determines if the slug has been loaded yet
  static hasLoaded(vaultData, slug) {
    return vaultData[slug] && vaultData[slug].hasLoaded;
  }

  static hasStructureLoaded(vaultState, slug) {
    return vaultState && vaultState.structures && vaultState.structures[slug];
  }

  static isLoading(vaultData, slug) {
    return vaultData[slug] && vaultData[slug].isLoading;
  }

  static getStructDisplay(vaultState, slug) {
    if (!vaultState || !vaultState.structures[slug]) {
      return '';
    }

    return vaultState.structures[slug].display;
  }

  static getDisplayFromStruct(struct, item) {
    const LABEL_TEMPLATE_REGEX = /{([0-9a-zA-Z-.$_]+)}/g;

    if (!struct) return '-';

    return StringHelper.template(struct.label, item, LABEL_TEMPLATE_REGEX);
  }

  static getCounts(vaultState, item, countsFor) {
    // Get the customer from vault state who matches this slug
    const customer = _.first(vaultState.customers.rows.filter(c => c._id === item._id));

    if (!customer) {
      return 0;
    }

    // Get the counts made available by the vault state
    const counts = _.first(customer.counts.filter(c => c._id === countsFor));

    return counts && counts.count ? counts.count : 0;
  }

  static getDisplay(vaultState, slug, item) {
    if (!vaultState || !vaultState.structures[slug]) {
      return '';
    }

    const struct = Vault.getStructure(vaultState, slug);

    // Build the display string
    return Vault.getDisplayFromStruct(struct, item);
  }

  // takes the vaultState and
  static getIdNamePairs(vaultState, slug, entries) {
    if (!entries && (!vaultState.data[slug] || !vaultState.data[slug].rows)) {
      return [];
    }

    const data = entries || (vaultState.data[slug] && vaultState.data[slug].rows);

    return data.map(item => ({
      id: item._id,
      name: Vault.getDisplay(vaultState, slug, item)
    }));
  }

  static getTableColumns(vaultState, slug) {
    const struct = Vault.getStructure(vaultState, slug);
    if (!struct || !struct.attributes) {
      return [];
    }

    return struct.attributes
      .filter(attr => attr.isPrimary)
      .map(attr => {
        const obj = {
          key: attr.key,
          name: attr.display,
          type: attr.type,
          filterable: attr.type !== 'file',
          sortable: attr.type !== 'file'
        };

        obj.formatter = item => {
          const temp = AttributeHelper.getRenderComponent(attr, item[attr.key]);
          return temp;
        };

        return obj;
      });
  }

  /**
   * This function gets a structure by slug from the state object.
   * @param {obect} vaultState any state
   * @param {obect} slug unique key of vault object
   * @returns {obect} the structure object or null if not found
   */
  static getStructure(vaultState, slug) {
    if (!vaultState || !vaultState.structures[slug]) {
      return null;
    }

    return vaultState.structures[slug];
  }

  static getDataById(vaultState, slug, id = null) {
    const data = vaultState.data[slug];

    if (!data || !data.rows) {
      return id === null ? [] : null;
    }

    if (id === null) {
      return data;
    }

    // either return the data set, or data with a certain id
    return _.findWhere(data.rows, { _id: id });
  }

  static getData(vaultState, slug, index = null) {
    const data = vaultState.data[slug];

    if (!data || (index !== null && !data.rows)) {
      return null;
    }

    // either return the data set, or data at a certain index
    return index === null ? data : data.rows[index];
  }

  static getTotalItemCount(vaultState, slug) {
    const data = vaultState.data[slug];

    if (!data || !data.totalItemCount) {
      return -1;
    }

    // either return the data set, or data at a certain index
    return data.totalItemCount;
  }

  static getDataQuery(vaultState, slug) {
    const data = Vault.getData(vaultState, slug);

    if (data === null) {
      return null;
    }

    return data.query;
  }
}

export default Vault;
