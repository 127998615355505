// import _ from 'underscore';
import React from 'react'
import { bindActionCreators } from 'redux'
import { TextInput, SelectInput, TemplateInput } from 'lib/acromyrmex'
import { connect } from 'react-redux'
import { FieldArray, Field } from 'redux-form'
import PropTypes from 'prop-types'
import {
  Row,
  Col,Accordion,
  Button,
} from '../../../../../utility/UiComponents'
import ApiParameterList from '../../../ProcessForm/ApiParameterList'
import IntegrationSelect from './integration/IntegrationSelect'
import TableForm from '../../../../shared/form/TableForm'
import AddButton from '../../../../shared/form/AddButton'
import {
  requiredValidator,
  selectRequiredValidator,
} from '../../../../../utility/formValidators'
import AttributeHelper from '../../../../../shared-helpers/AttributeHelper'

// eslint-disable-next-line
class IntegrationStepOptions extends React.Component {
  constructor(props) {
    super(props)

    this.renderParams = this.renderParams.bind(this)
  }

  /**
   * Gets a list of required parameters to show in the view. The rest will be added by the user
   * @return {[type]} [description]
   */
  getDefaultParameters() {
    const { processStep, integrationOptions } = this.props
    return IntegrationStepOptions.getDefaultParameters({
      processStep,
      integrationOptions,
    })
  }

  renderParams({ fields }) {
    const {
      outputOptions,
      change,
      integrationOptions,
      processStep,
      executionStep,
      array,
      fieldText,
    } = this.props

    return (
      <ApiParameterList
        fields={fields}
        outputOptions={outputOptions}
        change={change}
        integrationOptions={integrationOptions}
        stepType={processStep.stepType}
        currentOptions={executionStep.stepOptions}
        array={array}
        fieldText={`${fieldText}.stepOptions`}
      />
    )
  }

  renderPropertiesList = ({ fields }) => {
    const rows = fields.map((fieldText, index) =>
      this.renderPropertyOption(fieldText, fields, index),
    )

    return (
      <div>
        {rows}
        <Row className="step-option-buttons">
          <Col xs={12}>
            <AddButton size="sm"
              onClick={() => fields.push({})}
              label="Add Dynamic Property"
            />
          </Col>
        </Row>
      </div>
    )
  }

  renderPropertyOption(fieldText, fields, index) {
    const { onChangeAttributeNeeded, formValues, processStep } = this.props

    const onChangeFactory = (replaceField) => (a, newValue) => {
      const newArray = [...processStep.stepOptions.dynamicProperties]

      newArray[index] = { ...newArray[index], [replaceField]: newValue }

      onChangeAttributeNeeded({
        ...processStep,
        stepOptions: {
          ...processStep.stepOptions,
          dynamicProperties: newArray,
        },
      })
    }

    const onRemove = () => {
      const newArray = [...processStep.stepOptions.dynamicProperties]

      newArray.splice(index, 1)

      fields.remove(index)

      onChangeAttributeNeeded({
        ...processStep,
        stepOptions: {
          ...processStep.stepOptions,
          dynamicProperties: newArray,
        },
      })
    }

    return (
      <Row style={{ marginBottom: 10 }} key={fieldText}>
        <Col xs={5}>
          <Field
            name={`${fieldText}.key`}
            component={TextInput}
            noLabel
            validate={requiredValidator}
            onChange={onChangeFactory('key')}
          />
        </Col>
        <Col xs={6}>
          <Field
            name={`${fieldText}.type`}
            component={SelectInput}
            noLabel
            validate={selectRequiredValidator}
            options={AttributeHelper.getComputationTypes()}
            onChange={onChangeFactory('type')}
          />
        </Col>
        <Col xs={1}>
          <Button
            variant="danger"
            onClick={onRemove}
            title="Remove this parameter"
          >
            <i className="fa fa-trash" />
          </Button>
        </Col>
      </Row>
    )
  }

  renderParametersList = ({ fields }) => {
    const rows = fields.map((fieldText, index) =>
      this.renderParameterOption(fieldText, fields, index),
    )

    return (
      <div>
        {rows}
        <Row className="step-option-buttons">
          <Col xs={12}>
            <AddButton size="sm"
              onClick={() => fields.push({})}
              label="Add Dynamic Parameter"
            />
          </Col>
        </Row>
      </div>
    )
  }

  renderParameterOption(fieldText, fields, index) {
    const { outputOptions } = this.props

    return (
      <Row style={{ marginBottom: 10 }} key={fieldText}>
        <Col xs={5}>
          <Field
            name={`${fieldText}.name`}
            component={TextInput}
            noLabel
            validate={requiredValidator}
            // onChange={onChangeFactory('name')}
          />
        </Col>
        <Col xs={6}>
          <Field
            name={`${fieldText}.value`}
            component={TemplateInput}
            noLabel
            validate={requiredValidator}
            options={outputOptions}
            // onChange={onChangeFactory('value')}
          />
        </Col>
        <Col xs={1}>
          <Button
            variant="danger"
            onClick={() => fields.remove(index)}
            title="Remove this parameter"
          >
            <i className="fa fa-trash" />
          </Button>
        </Col>
      </Row>
    )
  }

  render() {
    const {
      fieldText,
      processStep,
      outputOptions,
      outputOptionsLoaded,
      integrationOptions,
    } = this.props


    const integrationSpec = integrationOptions[processStep.stepType]
    const isDynamicProperties = Boolean(
      integrationSpec.action['x-dynamic-properties'],
    )
    const isDynamicParameters = Boolean(
      integrationSpec.action['x-dynamic-parameters'],
    )

    return (
      <Row key={fieldText}>
        <Accordion defaultActiveKey="1">
          <Accordion.Item eventKey="1">
            <Accordion.Header>{`${processStep.name || ''} Step Options`}</Accordion.Header>
            <Accordion.Body>
              <IntegrationSelect
                name={`${fieldText}.stepOptions.integration`}
                stepType={processStep.stepType}
                outputOptions={outputOptions}
                outputOptionsLoaded={outputOptionsLoaded}
              />
              <Row style={{ marginBottom: 10 }}>
                <Col xs={5}>
                  <strong>Label</strong>
                </Col>
                <Col xs={6}>
                  <strong>Value</strong>
                </Col>
              </Row>
              {outputOptionsLoaded && (
                <FieldArray
                  name={`${fieldText}.stepOptions.parameters`}
                  component={this.renderParams}
                />
              )}
              {isDynamicProperties && (
                <Col>
                  <Col>
                    <TableForm
                      name={`${fieldText}.stepOptions.dynamicProperties`}
                      headers={[
                        { title: 'Key', xs: 5 },
                        { title: 'Type', xs: 6 },
                      ]}
                      fieldArrayComponent={this.renderPropertiesList}
                    />
                  </Col>
                </Col>
              )}
              {isDynamicParameters && (
                <Col>
                  <Col>
                    <TableForm
                      name={`${fieldText}.stepOptions.dynamicParameters`}
                      headers={[
                        { title: 'Key', xs: 5 },
                        { title: 'Value', xs: 6 },
                      ]}
                      fieldArrayComponent={this.renderParametersList}
                    />
                  </Col>
                </Col>
              )}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Row>
    )
  }
}

// Define property types
IntegrationStepOptions.propTypes = {
  fieldText: PropTypes.string.isRequired,
  processStep: PropTypes.shape().isRequired,
  executionStep: PropTypes.shape().isRequired,
  integrationOptions: PropTypes.shape().isRequired,
  outputOptions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  outputOptionsLoaded: PropTypes.bool.isRequired,
  change: PropTypes.func.isRequired,
  onChangeAttributeNeeded: PropTypes.func.isRequired,
  array: PropTypes.shape().isRequired,
}

IntegrationStepOptions.defaultProps = {}

const mapStateToProps = (state) => ({
  integrations: state.enabledIntegrations.enabledIntegrations.rows,
  vault: state.vault,
})

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(IntegrationStepOptions)
