import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ShowMarketingFormJumbotron from './ShowMarketingFormJumbotron';

class ShowDuoLayout extends Component {
  static propTypes = {
    browser: PropTypes.shape().isRequired,
    explanation: PropTypes.node.isRequired,
    content: PropTypes.node
  };

  static defaultProps = {
    content: null
  };

  state = {};

  render() {
    const { browser, explanation, content } = this.props;

    return (
      <div className="duo-onboarding">
        <div className="container">
          <ShowMarketingFormJumbotron />

          <div
            className="content-row"
            style={{ padding: 0, display: 'flex', borderRadius: '6px', overflow: 'hidden' }}
          >
            <div
              id="duo-explanation"
              style={{
                backgroundColor: 'rgba(239, 0, 127, 1)',
                color: 'white',
                padding: '30px',
                fontWeight: '400',
                fontSize: '1.1em',
                flexBasis: !content || browser.lessThan.large ? '100%' : '35%'
              }}
            >
              {explanation}
            </div>
            {content && (
              <div
                className="content"
                style={{
                  flexBasis: browser.lessThan.large ? '100%' : '65%'
                }}
              >
                {content}
              </div>
            )}
          </div>

          {/* FOOTER */}
          <div
            className="content-row"
            style={{
              borderRadius: '6px',
              backgroundColor: '#272a2f',
              color: 'white',
              marginTop: '10px'
            }}
          >
            Need help? <a href="mailto:support@contuit.com">support@contuit.com</a>
          </div>
        </div>
      </div>
    );
  }
}

// Define property types
const mapStateToProps = state => ({
  currentUser: state.users.currentUser,
  browser: state.browser
});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ShowDuoLayout);
