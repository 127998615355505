import _ from 'underscore'
import { useState, useEffect } from 'react'

function useFetch(asyncFetch, query, defaultValue) {
  const [data, setData] = useState(defaultValue)
  const [retryCount, setRetryCount] = useState(0)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
    asyncFetch(query)
      .then((resultData) => {
        setData(resultData)
      })
      .catch((err) => setError(err))
      .then(() => setLoading(false))
  }, [retryCount, ..._.map(query, (value) => value)])

  const retry = () => {
    setRetryCount(retryCount + 1)
    return true
  }

  return [data, loading, error, retry]
}

export default useFetch
