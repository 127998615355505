import _ from 'underscore';
import React from 'react';
import PropTypes from 'prop-types';
import { forceSimulation, forceLink, forceCollide, forceX, forceY, forceManyBody } from 'd3-force';

class ProcessGraph extends React.Component {
  constructor(props) {
    super(props);

    // calculate links
    const links = [];
    props.nodes.forEach(n => {
      if (!n.dependsOn) {
        return;
      }

      n.dependsOn.forEach(index => {
        links.push({ source: index, target: n.id });
      });
    });

    this.state = {
      nodes: props.nodes,
      links
    };

    const calcHeight = this._getMaxPath() * 150;
    this.state.height = calcHeight + 100;
  }

  componentDidMount() {
    const { radius } = this.props;
    const { nodes, links } = this.state;

    this.simulation = forceSimulation(nodes)
      .force(
        'link',
        forceLink()
          .id(d => d.id)
          .links(links)
          .distance(100)
          .strength(0.9)
      )
      .force('x', forceX(200).strength(0.1))
      .force('charge', forceManyBody().strength(-1500))
      .force(
        'y',
        forceY()
          .y(node => {
            const calcHeight = this._calcPath(node) * 150;
            return calcHeight - 75;
          })
          .strength(3)
      )
      .force('collide', forceCollide(radius));

    this.simulation.on('tick', () => {
      const { nodes: tickNodes, links: tickLinks } = this.state;
      this.setState({
        links: tickLinks,
        nodes: tickNodes
      });
    });
  }

  componentWillUnmount() {
    if (!this.simulation) {
      return;
    }

    this.simulation.stop();
  }

  _nodeDependedOn(node) {
    const { nodes } = this.state;
    let dependedOn = false;

    nodes.forEach(n => {
      dependedOn = dependedOn || n.dependsOn.includes(node.id);
    });

    return dependedOn;
  }

  _getMaxPath() {
    const { nodes } = this.state;

    const terminations = [];
    nodes.forEach(node => {
      if (!this._nodeDependedOn(node)) {
        terminations.push(node);
      }
    });

    return Math.max(...terminations.map(node => this._calcPath(node)));
  }

  /**
   * Recursively calculates the **longest** path in our tree
   */
  _calcPath(node, length = 1) {
    const { nodes } = this.state;

    // end case
    if (!node.dependsOn || node.dependsOn.length < 1) {
      return length;
    }

    return Math.max(
      ...node.dependsOn.map(id =>
        this._calcPath(
          nodes.find(n => n.id === id),
          length + 1
        )
      )
    );
  }

  render() {
    const { width, radius, activeStep, onActiveStepChanged } = this.props;
    const { nodes, links, height } = this.state;

    return (
      <svg height={height} width={width}>
        <defs>
          <marker
            id="suit"
            viewBox="0 -5 10 10"
            refX={12}
            refY={0}
            markerWidth={12}
            markerHeight={12}
            orient="auto"
          >
            <path d="M0,-5L10,0L0,5 L10,0 L0, -5" stroke="#000" opacity={0.6} />
          </marker>
        </defs>
        {/* Our visualization should go here. */}
        <g>
          {nodes.map(n => (
            <g key={n.id}>
              <circle
                cx={n.x}
                cy={n.y}
                r={radius}
                fill="#FFF"
                strokeWidth="2px"
                stroke={n.id === activeStep ? '#4679BD' : '#000'}
                onClick={() => {
                  onActiveStepChanged(n.id);
                }}
              />
              <text
                textAnchor="middle"
                x={n.x}
                y={n.y + 10}
                fill={n.id === activeStep ? '#4679BD' : '#000'}
                stroke={n.id === activeStep ? '#4679BD' : '#000'}
                fontSize="36px"
                onClick={() => {
                  onActiveStepChanged(n.id);
                }}
              >
                {n.name}
              </text>
            </g>
          ))}
          {links.map(link => {
            if (!_.isObject(link.source)) {
              return null;
            }

            return (
              <line
                x1={link.source.x}
                y1={link.source.y + radius}
                x2={link.target.x}
                y2={link.target.y - radius}
                key={`line-${link.source.id}-${link.target.id}`}
                stroke="#4679BD"
                markerEnd="url(#suit)"
              />
            );
          })}
        </g>
      </svg>
    );
  }
}

ProcessGraph.propTypes = {
  nodes: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  radius: PropTypes.number,
  activeStep: PropTypes.string.isRequired,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onActiveStepChanged: PropTypes.func.isRequired
};

ProcessGraph.defaultProps = {
  radius: 50,
  width: '100%'
};

export default ProcessGraph;
