import _ from 'underscore';
import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { SelectInput, TemplateInput } from 'lib/acromyrmex';
import { Row, Col, Button } from '../../../../../../utility/UiComponents';
import { requiredValidator } from '../../../../../../utility/formValidators';
import { filterCollections } from '../../../../../../utility/ProcessFormOptionHelpers';

const basicTypes = ['string', 'int32', 'boolean', 'integer', 'number'];

const PatchOption = ({
  optionRenderer,
  paramOptions,
  field,
  usedNames,
  req,
  fields,
  index,
  selection,
  outputOptions
}) => {
  const isBasic = selection && basicTypes.includes(selection.attrType);
  const options = isBasic
    ? [{ id: 'replace', name: 'replace' }]
    : [
        { id: 'add', name: 'add' },
        { id: 'replace', name: 'replace' },
        { id: 'remove', name: 'remove' }
      ];

  let objProperties = [];
  if (!isBasic) {
    objProperties = _.map(selection.properties, (prop, key) => ({ key }));
  }

  return (
    <Row style={{ marginBottom: 10 }}>
      <Col xs={isBasic ? 5 : 3} style={{ marginTop: '25px' }}>
        <Field
          name={`${field}.name`}
          component={SelectInput}
          options={paramOptions.filter(o => !usedNames.includes(o.name))}
          optionRenderer={optionRenderer}
          valueRenderer={optionRenderer}
          noLabel
          label="Label"
          disabled={req}
          validate={requiredValidator}
        />
      </Col>
      <Col xs={isBasic ? 6 : 8}>
        <Row>
          <Col xs={isBasic ? 6 : 4} md={4}>
            <Field
              vertical
              name={`${field}.op`}
              component={SelectInput}
              label="Operation"
              options={options}
            />
          </Col>
          {!isBasic && objProperties.length > 0 && (
            <Col xs={4} md={4}>
              <span className="control-label">Field</span>
              <br />
              <span style={{ marginTop: '5px' }}>{objProperties[0].key}</span>
            </Col>
          )}
          <Col xs={isBasic ? 6 : 4} md={isBasic ? 8 : 4}>
            <Field
              vertical
              name={isBasic ? `${field}.value` : `${field}.f-${objProperties[0].key}`}
              component={TemplateInput}
              options={filterCollections(outputOptions)}
            />
          </Col>
        </Row>
      </Col>
      <Col xs={1}>
        {!req && (
          <Button
            variant="danger"
            onClick={() => fields.remove(index)}
            title="Remove this parameter"
            style={{ marginTop: '25px' }}
          >
            <i className="fa fa-trash" />
          </Button>
        )}
      </Col>
      {!isBasic &&
        selection &&
        objProperties.slice(1).map(prop => (
          <Col xs={isBasic ? 6 : 8} xsOffset={3} key={prop.key}>
            <Row>
              <Col xs={4} md={4} xsOffset={4} style={{ paddingTop: '5px' }}>
                <span>{prop.key}</span>
              </Col>
              <Col xs={4} md={4}>
                <Field
                  vertical
                  name={`${field}.f-${prop.key}`}
                  component={TemplateInput}
                  label="Value"
                  noLabel
                />
              </Col>
            </Row>
          </Col>
        ))}
    </Row>
  );
};

PatchOption.propTypes = {
  field: PropTypes.string.isRequired,
  optionRenderer: PropTypes.func.isRequired,
  paramOptions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  usedNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  outputOptions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  req: PropTypes.bool,
  fields: PropTypes.shape().isRequired,
  index: PropTypes.number.isRequired,
  selection: PropTypes.shape().isRequired
};

PatchOption.defaultProps = {
  req: false
};

export default PatchOption;
