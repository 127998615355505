import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'lib/acromyrmex';
import FileIcons from 'file-icons-js';
import { Button } from '../../../utility/UiComponents';

const S3URL = 'https://s3.us-east-2.amazonaws.com/contuit-uploads/';

const getFileType = fileName => {
  const afterIndex = fileName && fileName.lastIndexOf('.');

  return fileName && fileName.substr(afterIndex + 1);
};

const getS3Url = key => `${S3URL}${key}`;

const FileThumbnailHeader = ({ file }) => (
  <div>
    {['png', 'jpg', 'jpeg'].includes(getFileType(file.name)) && (
      <div className="upload-thumbnail">
        <img src={getS3Url(file.s3Key)} alt={file.name} />
      </div>
    )}
    <p>
      <a href={getS3Url(file.s3Key)}>
        <i className={FileIcons.getClassWithColor(file.name)} /> {file.name}
      </a>
    </p>
  </div>
);

FileThumbnailHeader.propTypes = {
  file: PropTypes.shape()
};

FileThumbnailHeader.defaultProps = {
  file: {}
};

const FileRemoveThumbnail = ({ file, removeFile }) =>
  file && (
    <div className="upload">
      <FileThumbnailHeader file={typeof file === 'string' ? JSON.parse(file) : file} />
      <Button
        variant="danger"
        onClick={() => {
          removeFile(file);
        }}
      >
        <Icon close />
      </Button>
    </div>
  );

FileRemoveThumbnail.propTypes = {
  file: PropTypes.shape(),
  removeFile: PropTypes.func.isRequired
};

FileRemoveThumbnail.defaultProps = {
  file: {}
};

const FileThumbnail = ({ file }) => (
  <div className="upload">
    <FileThumbnailHeader file={file} />
    <Button variant="primary" href={getS3Url(file.s3Key)} target="_blank">
      <Icon download />
    </Button>
  </div>
);

FileThumbnail.propTypes = {
  file: PropTypes.shape()
};

FileThumbnail.defaultProps = {
  file: {}
};

export { FileThumbnail, FileRemoveThumbnail, FileThumbnailHeader, getS3Url };
