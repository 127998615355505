import _ from 'lodash'

class MopHelper {
  static getOperation(spec, operationId) {
    let foundOperation = null

    _.each(spec.paths, (pathObj) => {
      _.each(pathObj, (actionObj) => {
        if (actionObj.operationId === operationId) {
          foundOperation = actionObj
        }
      })
    })

    _.each(spec['x-triggers'], (triggerObj) => {
      if (triggerObj.operationId === operationId) {
        foundOperation = triggerObj
      }
    })

    return foundOperation
  }

  static getSuccessResponseAttributes(operation) {
    const successResponse =
      operation.responses &&
      (operation.responses[200] || operation.responses[201])

    if (!successResponse) {
      return null
    }

    const { schema } = successResponse

    const result = schema['x-result-content']
      ? schema
      : _.find(schema.properties, (val) => val['x-result-content'])

    let attrs = []
    if (!result) {
      return attrs
    }

    switch (result.type) {
      case 'array':
        attrs = {
          '.': {
            type: 'collection',
            display: 'data',
            properties:
              result.items.type === 'object'
                ? result.items.properties
                : {
                    '.': result.items,
                  },
          },
        }
        break
      case 'object':
        attrs = result.properties
        break
      default:
        attrs = { '.': result }
        break
    }

    return attrs
  }
}

export default MopHelper
