import React from 'react';
import { useParams } from 'react-router';
import ShowMarketplaceSolution from '../../modules/marketplace/ShowMarketplaceSolution';

function ShowMarketplaceSolutionPage() {
  const params = useParams();

  return <ShowMarketplaceSolution params={params} />;
}

export default ShowMarketplaceSolutionPage;
