import get from 'lodash/get';
import each from 'lodash/each';
import isObject from 'lodash/isObject';
import isArray from 'lodash/isArray';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { LinkContainer } from 'react-router-bootstrap';
import { getProcessStepFromExecutionStep } from '../../../shared-helpers/execution';
import UiStepHelper from '../steps/UiStepHelper';
import VaultEntryMention from './VaultEntryMention';
import AutoDataTable from '../../shared/DataTable/AutoDataTable';
import { FlexRow } from '../../shared/Flex';
import { Button } from '../../../utility/UiComponents';
import ChildProcessList from '../steps/components/stepAutomating/ChildProcessList';
import { FileThumbnailHeader } from '../../shared/FileUpload/FileThumbnail';

class ShowStepOutput extends Component {
  render() {
    const { execution, executionStep, vault, currentUser } = this.props;
    const processStep = getProcessStepFromExecutionStep(execution, executionStep);
    const outputKeys = UiStepHelper.getOutputKeys(processStep, {
      stepOptions: processStep.stepOptions
    });

    // For certain output key types we want to show some output
    const outputViews = [];
    const schema = get(outputKeys, 'schema', {});

    each(schema, (outputTypeDef, key) => {
      // outputTypeDef will be either a string type or an object with a `type` key and other options
      let outputType = outputTypeDef;
      if (isObject(outputType)) {
        // this will grab the actual type
        outputType = outputType.type;
      }

      // First, lets handle any live vault entries
      if (outputType === 'LiveVault') {
        outputViews.push(
          <VaultEntryMention
            id={get(executionStep, `stepOutput.${key}`)}
            slug={get(processStep.stepOptions, outputTypeDef.vaultStructureStepOption)}
            vault={vault}
            currentUser={currentUser}
          />
        );

        // nothing else for this attribute
        return;
      }

      // Generic response shown in a data table
      if (outputType === 'Table') {
        let data = get(executionStep, 'stepOutput', []);
        if (data === null) {
          data = [];
        }

        if (!isArray(data)) {
          data = [data];
        }

        outputViews.push(
          <div style={{ display: 'flex' }}>
            <AutoDataTable
              smallTitle={<strong style={{ marginRight: 5 }}>Results ({data.length})</strong>}
              data={data}
              ignoreColumns={get(outputTypeDef, 'ignoreColumns', [])}
              maxHeight="300px"
            />
          </div>
        );

        // nothing else for this attribute
        return;
      }

      if (outputType === 'ExecutionList') {
        let data = get(executionStep, `stepOutput.${key}`);
        if (!isArray(data)) {
          data = [data];
        }

        outputViews.push(
          <ChildProcessList
            skipCollapse
            execution={execution}
            currentExecutionStep={executionStep}
            executionList={data}
            hideProgress
          />
        );

        // nothing else for this output type
        return;
      }

      if (outputType === 'Execution') {
        const data = get(executionStep, `stepOutput.${key}`);

        outputViews.push(
          <div>
            <LinkContainer
              to={{
                pathname: `/processes/${get(
                  processStep.stepOptions,
                  outputTypeDef.processIdStepOption,
                  'generic-process'
                )}/executions/${data}`
              }}
            >
              <Button size="xsmall" variant="primary">
                View Execution
              </Button>
            </LinkContainer>
          </div>
        );

        // nothing else for this output type
        return;
      }

      if (outputType === 'File') {
        const file = get(executionStep, `stepOutput.${key}`);
        outputViews.push(<FileThumbnailHeader file={file} />);

        return;
      }

      // by default return a normal label type
      const title = get(outputTypeDef, 'title', false);
      outputViews.push(
        <div>
          {title && <strong>{title}: </strong>}
          {`${get(executionStep, `stepOutput.${key}`)}`}
        </div>
      );
    });

    const title = get(outputKeys, 'title', false);
    return (
      <FlexRow className="step" style={{ overflow: 'auto' }}>
        {title && (
          <div
            style={{ marginRight: '5px', paddingRight: '10px', borderRight: '1px solid #8c8c8c' }}
          >
            <strong>{title}</strong>
          </div>
        )}
        <div style={{ height: '100%', width: '100%' }}>{outputViews}</div>
      </FlexRow>
    );
  }
}

ShowStepOutput.propTypes = {
  vault: PropTypes.shape().isRequired,
  executionStep: PropTypes.shape().isRequired,
  execution: PropTypes.shape().isRequired,
  currentUser: PropTypes.shape().isRequired
};

const mapStateToProps = state => ({
  vault: state.vault,
  currentUser: state.users.currentUser
});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ShowStepOutput);
