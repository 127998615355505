import { VAULT_CREATE_STEP_TYPE } from '../../../constants'
import VaultCreateStepOptions from './components/stepOptions/vaultCreate'
import VaultCreateStep from './components/stepExecutions/vaultCreate'
import BasicUiHelper from './BasicUiHelper'
import VaultInputUiHelper from './VaultInputUiHelper'

class VaultCreateUiHelper extends BasicUiHelper {
  regex() {
    return VAULT_CREATE_STEP_TYPE
  }

  name() {
    return 'Vault Create'
  }

  canTryAgain() {
    return true
  }

  liveVault() {
    return true
  }

  stepOptions() {
    return VaultCreateStepOptions
  }

  // we don't want a submit button
  getStepButtons() {
    return []
  }

  getOutputKeys() {
    return {
      schema: {
        id: { type: 'LiveVault', vaultStructureStepOption: 'slug' },
      },
    }
  }

  stepExecution() {
    return VaultCreateStep
  }

  getInputOptions(step, { structureList }) {
    return VaultInputUiHelper.getInputOptions(step, { structureList })
  }

  getOutputOptions(step, { structureList }) {
    return VaultInputUiHelper.getOutputOptions(step, { structureList })
  }
}

export default VaultCreateUiHelper
