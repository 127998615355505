import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FieldArray } from 'redux-form';
import { Button, Modal } from '../../../utility/UiComponents';
import PickListArray from './PickListArray';

class PickListEditor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false
    };
  }

  render() {
    const { fieldName } = this.props;
    const { showModal } = this.state;
    return (
      <div>
        <Button
          variant="primary"
          className="pull-right"
          onClick={() => {
            this.setState({ showModal: true });
          }}
        >
          <id className="fa-ellipsis-h fa" />
        </Button>
        <Modal
          show={showModal}
          onHide={() => {
            this.setState({ showModal: false });
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Add options to choose from</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FieldArray name={`${fieldName}.options`} component={PickListArray} />
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={() => {
                this.setState({ showModal: false });
              }}
            >
              Done
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

PickListEditor.propTypes = {
  fieldName: PropTypes.string.isRequired
};

export default PickListEditor;
