import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { LoadingButton } from 'lib/acromyrmex';
import TextInput from 'lib/acromyrmex/TextInput';
import exportFromJSON from 'export-from-json';
import { toast } from 'react-toastify';
import get from 'lodash/get';
import request from '../../../utility/request';
import Alert from '../../shared/Alert';
import { Modal, Form } from '../../../utility/UiComponents';

const CreateBundleModal = ({ showModal, onHide, selectedProcesses, currentUser }) => {
  const [bundleName, setBundleName] = useState('');
  const [bundleError, setBundleError] = useState(null);
  const [isCreating, setIsCreating] = useState(false);

  const handleCreateBundle = async () => {
    try {
      setIsCreating(true);

      const res = await request
        .post('/api/processes/export')
        .set('x-access-token', currentUser.token)
        .send({ processesIdList: selectedProcesses, bundleName });
      const { bundle } = res.body;

      exportFromJSON({
        data: bundle,
        fileName: bundle.name,
        exportType: 'json'
      });
      toast.success('Successfully created bundle');
      setIsCreating(false);
      setBundleName('');
      onHide();
    } catch (err) {
      const { response } = err;
      const message = `Error creating bundle: ${get(response, 'body.message', 'Unknown error')}`;

      setBundleError(message);
      setIsCreating(false);
    }
  };

  return (
    <Modal show={showModal} onHide={onHide} size="large">
      <Modal.Header closeButton>
        <Modal.Title>Bundle {selectedProcesses.length} Processes</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="content-row content-row-padded process-form box-rest">
          <div className="container">
            <Form
              onSubmit={e => {
                e.preventDefault();
                handleCreateBundle();
              }}
            >
              <TextInput
                label="Bundle Name:"
                type="text"
                input={{
                  value: bundleName,
                  onChange: ({ target: { value } }) => setBundleName(value),
                  onBlur: () => {}
                }}
              />
              {bundleError && <Alert message={bundleError} />}
              <div style={{ marginRight: 15 }}>
                <LoadingButton
                  variant="primary"
                  label="Create Bundle"
                  loading={isCreating}
                  className="pull-right"
                  loadingLabel="Creating Bundle"
                  onClick={handleCreateBundle}
                  type="button"
                />
              </div>
            </Form>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

CreateBundleModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  selectedProcesses: PropTypes.arrayOf(PropTypes.string).isRequired,
  currentUser: PropTypes.shape({
    token: PropTypes.string.isRequired
  }).isRequired
};

export default CreateBundleModal;
