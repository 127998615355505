import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import ShowDuoLoginForm from '../../modules/user/ShowDuoLoginForm';
import MarketingFormLayout from '../../modules/shared/Layout/MarketingFormLayout';

function ShowLogin() {
  const navigate = useNavigate();
  const location = useLocation();
  
  const queryParams = new URLSearchParams(location.search);
  const message = queryParams.get('message');
  const redirectURL = queryParams.get('redirectURL')

  return (
    <MarketingFormLayout
      explanation={<h3 style={{ marginTop: '24px', marginBottom: '24px' }}>Welcome back!</h3>}
      content={(
        <ShowDuoLoginForm
          postDuoRedirect="/dashboards/personal"
          navigate={navigate}
          message={message}
          redirectURL={redirectURL}
        />
      )}
    />
  );
}

export default ShowLogin;
