/**
 * Actions
 * ---------
 * Actions can be passed as an array of objects to the table. By default these actions
 * are disabled until a row has been selected. Each object can contain the following
 * attributes.
 * | Attribute | Description |
 * |-------|--------|
 * | key | The key is used as the key for the button component |
 * | style | The bootstrap style to apply to the button |
 * | alwaysEnabled | Boolean that can turn off the enablement only when a row is selected |
 * | display | What to display in the button |
 * | multi | Array of `{ display, value }` options for the dropdown |
 * | onClick | (selectedList, [multi.value]) => {} | A function that is called when the action is clicked |
 */

import _ from 'underscore'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import reject from 'lodash/reject'
import ReactTable from 'react-table'

import { Icon } from 'lib/acromyrmex'
import moment from 'moment'
import exportFromJSON from 'export-from-json'
import {
  Button,
  DropdownButton,
  MenuItem,
  OverlayTrigger,
  Popover,
  Checkbox,
} from '../../../utility/UiComponents'
import {
  getLabelCell,
  getArrayCell,
  getTimeagoCell,
  getCheckboxCell,
  getJsonCell,
  getPercentCell,
  getCellBase,
  getExportedCellValue,
} from './DataTableCells'
import 'react-table/react-table.css'
import './DataTable.css'

class DataTable extends Component {
  wrapperRef = React.createRef()

  static propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    columns: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    totalCount: PropTypes.number,
    idAttribute: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    onSelect: PropTypes.func,
    selectable: PropTypes.bool,
    filterable: PropTypes.bool,
    defaultPageSize: PropTypes.number,
    onFetchData: PropTypes.func,
    manual: PropTypes.bool,
    actions: PropTypes.arrayOf(PropTypes.shape()),
    maxHeight: PropTypes.string,
    smallTitle: PropTypes.node,
    title: PropTypes.node,
    csvTitle: PropTypes.string,
    toolbar: PropTypes.func,
    onToggleColumns: PropTypes.func,
    initialColumnShowList: PropTypes.arrayOf(PropTypes.shape()),
    fetchCSVData: PropTypes.func,
  }

  static defaultProps = {
    idAttribute: '_id',
    selectable: false,
    filterable: false,
    maxHeight: undefined,
    manual: false,
    totalCount: null,
    defaultPageSize: 20,
    actions: [],
    title: null,
    smallTitle: null,
    csvTitle: '',
    toolbar: () => null,
    onFetchData: () => {},
    onSelect: () => {},
    onToggleColumns: () => {},
    initialColumnShowList: null,
    fetchCSVData: () => ({
      items: [],
    }),
  }

  constructor(props) {
    super()

    const initialList = props.initialColumnShowList || []
    props.columns.forEach((c) => {
      if (initialList.find((col) => c.key === col.key || c.id === col.key)) {
        return
      }

      initialList.push({
        key: c.id || c.key,
        hidden: c.hidden,
      })
    })

    this.state = {
      // 0: none, 1: all, 2: some
      selectAll: 0,
      // toggled list because when select all is enabled, it is the unselected rows
      toggledList: [],
      pageSize: 20,
      // this is the format we use
      filter: {},
      // this is the format that the table uses
      filtered: [],

      // eslint-disable-next-line react/destructuring-assignment
      columnShowList:
        props.initialColumnShowList ||
        props.columns.map((c) => ({
          key: c.id || c.key,
          hidden: c.hidden,
        })),

      showFilters: false,
    }
  }

  componentDidUpdate(prevProps, prevState) {
    this.updateHeaderScrollPosition()
    const { data, totalCount } = this.props
    const { toggledList, pageSize } = this.state

    if (
      data.length !== prevProps.data.length ||
      totalCount !== prevProps.totalCount ||
      toggledList.length !== (prevState.toggledList || []).length
    ) {
      const newState = {
        selectAll: this.determineSelectAll(),
        toggledList: this.determineToggledList(),
      }

      // if (data.length < pageSize) {
      //   newState.pageSize = data.length;
      // }

      this.setState(newState)
    }
  }

  getIdValue = (original) => {
    const { idAttribute } = this.props

    if (idAttribute === false) {
      return JSON.stringify(original)
    }

    return original[idAttribute]
  }

  getSelectableColumn() {
    const { selectAll } = this.state
    return {
      id: 'checkbox',
      accessor: '',
      sortable: false,
      filterable: false,
      toggleable: false,
      Cell: ({ original }) => (
        <input
          type="checkbox"
          className="checkbox"
          checked={this.isRowSelected(original)}
          onChange={() => this.toggleRow(original)}
        />
      ),
      Header: () => (
        <input
          type="checkbox"
          className="checkbox"
          checked={selectAll === 1}
          ref={(input) => {
            if (input) {
              input.indeterminate = selectAll === 2
            }
          }}
          onChange={() => this.toggleSelectAll()}
        />
      ),
      width: 45,
    }
  }

  getColumns() {
    const { columns, selectable } = this.props
    const returnColumns = []

    if (selectable) {
      returnColumns.push(this.getSelectableColumn())
    }

    returnColumns.push(
      ...columns
        .filter((c) => {
          const { columnShowList } = this.state
          const thisCol = columnShowList.find(
            (col) => c.key === col.key || c.id === col.key,
          )

          if (!thisCol) {
            return false
          }

          return !thisCol.hidden
        })
        .map((column) => {
          let cell = getCellBase(column)

          if (column.formatter) {
            cell = { ...cell, ...column }
            cell.Cell = column.formatter
            return cell
          }

          switch (column.type) {
            case 'json':
              return getJsonCell(column, cell)
            case 'array':
              return getArrayCell(column, cell)
            case 'percent':
              return getPercentCell(column, cell)
            case 'boolean':
            case 'checkbox':
              return getCheckboxCell(column, cell)
            case 'date':
            case 'timeago':
              return getTimeagoCell(column, cell)
            default:
              return getLabelCell(column, cell)
          }
        }),
    )

    return returnColumns
  }

  getPageSizeOptions() {
    const { data, totalCount } = this.props
    const pageSizeOptions = [5, 10, 20, 25, 50, 100].filter(
      (n) => n < (totalCount || data.length),
    )

    if (
      (totalCount || data.length) < 100 &&
      pageSizeOptions.indexOf(data.length) < 0
    ) {
      pageSizeOptions.push(data.length)
    }

    return pageSizeOptions
  }

  getToolbar() {
    const { actions, toolbar: Toolbar } = this.props
    const { toggledList } = this.state

    return (
      <div style={{ flex: 1 }}>
        {actions.map((action) => (
          <div
            className="pull-left"
            style={{ marginRight: '5px' }}
            key={action.key}
          >
            {!action.multi && (
              <Button
                variant={action.style || 'success'}
                size="xs"
                disabled={
                  action.disabled ||
                  (!action.alwaysEnabled && toggledList.length < 1)
                }
                onClick={() => {
                  action.onClick(toggledList)
                }}
              >
                {action.display}
              </Button>
            )}
            {action.multi && (
              <DropdownButton
                variant="primary"
                size="xs"
                disabled={
                  action.disabled ||
                  (!action.alwaysEnabled && toggledList.length < 1)
                }
                title={action.display}
                id={`${action.key}-action`}
              >
                {action.multi.map((m, i) => (
                  <MenuItem
                    key={m.value}
                    eventKey={i}
                    onClick={() => {
                      action.onClick(toggledList, m.value)
                    }}
                  >
                    {m.display}
                  </MenuItem>
                ))}
              </DropdownButton>
            )}
          </div>
        ))}
        <Toolbar toggledList={toggledList} />
      </div>
    )
  }

  getColumnPopover() {
    const { columns, onToggleColumns } = this.props

    return (
      <Popover id="popover-basic">
        <Popover.Body>
          {columns.map((column) => {
            const { columnShowList } = this.state
            const thisCol = columnShowList.find(
              (col) => column.key === col.key || column.id === col.key,
            )

            if (!thisCol) {
              return ''
            }

            return (
              <Checkbox
                key={thisCol.id || thisCol.key}
                checked={!thisCol.hidden}
                onClick={() => {
                  const { columnShowList: innerList } = this.state
                  const newColList = [...innerList]

                  const myCol = newColList.find(
                    (col) => column.key === col.key || column.id === col.key,
                  )

                  myCol.hidden = !myCol.hidden

                  this.setState({ columnShowList: newColList }, () => {
                    onToggleColumns(newColList)
                  })
                }}
              >
                {column.display || column.key}
              </Checkbox>
            )
          })}
        </Popover.Body>
      </Popover>
    )
  }

  isRowSelected = (original) => {
    const { toggledList } = this.state
    const idValue = this.getIdValue(original)

    return toggledList.indexOf(idValue) > -1
  }

  toggleRow = (original) => {
    const { toggledList } = this.state
    const idValue = this.getIdValue(original)
    let newToggledList = []

    if (this.isRowSelected(original)) {
      newToggledList = [...reject(toggledList, (s) => s === idValue)]
    } else {
      newToggledList = [...toggledList, idValue]
    }

    this.updateToggledList(newToggledList)
  }

  toggleSelectAll = () => {
    const { data } = this.props
    const { selectAll } = this.state

    const newToggledList = selectAll === 1 ? [] : data.map(this.getIdValue)

    this.updateToggledList(newToggledList)
  }

  exportToCSV = async () => {
    const { csvTitle, fetchCSVData } = this.props
    const cols = this.getColumns()
    const { items: data } = await fetchCSVData()
    const exportedData = data.map((row) =>
      cols.reduce((acc, v) => {
        if (v.accessor && v.accessor !== 'actions') {
          acc[v.display] = getExportedCellValue({ col: v, cell: row })
        }

        return acc
      }, {}),
    )

    exportFromJSON({
      data: exportedData,
      fileName: `contuit ${csvTitle} as of ${moment().format('YYYY-MM-DD')}`,
      exportType: 'csv',
    })
  }

  updateToggledList(newToggledList) {
    const { onSelect } = this.props

    this.setState(
      {
        toggledList: newToggledList,
        selectAll: this.determineSelectAll({ newToggledList }),
      },
      () => {
        onSelect(newToggledList)
      },
    )
  }

  determineSelectAll(options = {}) {
    const { data } = this.props
    const { toggledList } = this.state
    const { newToggledList = toggledList, newData = data } = options

    if (newToggledList.length === newData.length) {
      return 1
    }

    if (newToggledList.length > 0) {
      return 2
    }

    return 0
  }

  determineToggledList(options = {}) {
    const { toggledList } = this.state
    const { idAttribute } = this.props

    // allow these to be passed, but default to our state and props
    const { newToggledList = toggledList } = options

    // if we do not have an idList... just pass the new list back
    if (idAttribute === false) {
      return newToggledList
    }

    return newToggledList
  }

  updateHeaderScrollPosition() {
    const { scrollLeft } = this.state
    const { current: wrapper } = this.wrapperRef
    if (!wrapper) {
      return
    }

    const headers = wrapper.getElementsByClassName('rt-thead')

    for (let i = 0; i < headers.length; i++) {
      headers[i].scrollLeft = scrollLeft
    }
  }

  render() {
    const {
      data,
      filterable,
      selectable,
      defaultPageSize,
      totalCount,
      onFetchData,
      title,
      smallTitle,
      ...tableProps
    } = this.props
    const { toggledList, showFilters, pageSize, filter, filtered } = this.state
    const addedProps = {}

    if (totalCount !== null) {
      addedProps.pages = Math.ceil(totalCount / pageSize)
    }

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          overflow: 'auto',
          height: '100%',
        }}
        ref={this.wrapperRef}
      >
        {title !== false && (
          <div
            className="clearfix"
            style={{
              ...(_.isString(title) ? { paddingLeft: '10px' } : {}),
              height: '30px',
            }}
          >
            {_.isString(title) && <h4 style={{ marginBottom: 0 }}>{title}</h4>}
            {!_.isString(title) && title}
          </div>
        )}
        <div className="toolbar clearfix" style={{ display: 'flex' }}>
          {smallTitle}
          {filterable && (
            <div>
              <Button
                variant="primary"
                size="xs"
                active={showFilters}
                onClick={() => this.setState({ showFilters: !showFilters })}
              >
                <Icon filter />
                {' Filter'}
              </Button>
            </div>
          )}
          {Object.keys(filter).length > 0 && (
            <div>
              <Button
                variant="danger"
                size="xs"
                onClick={() => this.setState({ filtered: [] })}
              >
                <Icon filter />
                {' Clear Filter'}
              </Button>
            </div>
          )}
          {this.getToolbar()}
          {selectable && (
            <div>
              <strong>{`${toggledList.length} Selected`}</strong>
            </div>
          )}
          <div>
            <Button variant="primary" size="xs" onClick={this.exportToCSV}>
              Export To CSV
            </Button>
          </div>
          <div>
            <OverlayTrigger
              trigger="click"
              placement="bottom"
              overlay={this.getColumnPopover()}
            >
              <Button variant="primary" size="xs">
                Toggle Columns
              </Button>
            </OverlayTrigger>
          </div>
        </div>
        <ReactTable
          {...tableProps}
          {...addedProps}
          pageSize={Math.min(pageSize, data.length)}
          onPageSizeChange={(newPageSize) =>
            this.setState({ pageSize: newPageSize })
          }
          className="-striped -highlight"
          style={{
            // 30px is the height of the toolbar
            flex: 1,
            overflow: 'auto',
          }}
          data={data}
          columns={this.getColumns()}
          pageSizeOptions={this.getPageSizeOptions()}
          filterable={filterable}
          filtered={filtered}
          showPagination={
            (totalCount !== null && addedProps.pages > 1) ||
            (data && data.length > pageSize)
          }
          onFilteredChange={(newFilter) => {
            this.setState({ filtered: newFilter })
          }}
          onFetchData={(tableState) => {
            const { filtered: tableFilter } = tableState

            const newFilter = {}
            if (tableFilter) {
              tableFilter.forEach((f) => {
                newFilter[f.id] = f.value
              })

              this.setState({ filter: newFilter })
            }

            if (!onFetchData) {
              return
            }

            return onFetchData({
              ...tableState,
              pageSize: Math.max(tableState.pageSize, pageSize),
              filter: newFilter,
            })
          }}
          defaultFilterMethod={(f, row) => {
            // date f
            if (f.value && (f.value.to || f.value.from)) {
              if (f.value.to && !f.value.from) {
                return f.value.to > row[f.id]
              }
              if (f.value.from && !f.value.to) {
                return f.value.from < row[f.id]
              }
              return f.value.to > row[f.id] && f.value.from < row[f.id]
            }

            // normal f
            return String(row[f.id]) === f.value
          }}
          getTbodyProps={() => ({
            // to keep the header and body in sync, we need to track scrolling of the body
            onScroll: (e) => {
              if (e.target === e.currentTarget) {
                this.setState({ scrollLeft: e.target.scrollLeft })
              }
            },
          })}
          getTheadFilterProps={() => ({
            style: showFilters ? null : { display: 'none' },
          })}
        />
      </div>
    )
  }
}

export default DataTable
