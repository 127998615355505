import React from 'react';
import { useParams } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { toast } from 'react-toastify';
import { AuthenticationServer } from '@contuit-otf/sdk';
import { Modal, Button } from '../../utility/UiComponents';
import request from '../../utility/request';
import { historyPush } from '../../history';

const authServer = new AuthenticationServer('/api/auth', request);

class ShowStuckExecution extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      switchingTenants: false,
    };
  }

  handleProceed = () => {
    this.handleTenantSwitch();
  };

  handleCancel = () => {
    if (!this.state.switchingTenants) {
      historyPush('/dashboards/personal');
    }
  };

  handleTenantSwitch = async () => {
    const { currentUser, params } = this.props;
    const { processId, executionId, contuitId } = params;

    try {
      this.setState({ switchingTenants: true });

      await authServer.updateUser({
        token: currentUser.token,
        user: { ...currentUser, contuitId },
      });

      this.setState({ switchingTenants: false });

      window.location.href = `/processes/${processId}/executions/${executionId}`;
    } catch (err) {
      this.setState({ switchingTenants: false });
      toast.error(`Error switching tenants: ${err.message}`);
      historyPush('/dashboards/personal');
    }
  };

  render() {
    const { checkedToken, currentUser, params } = this.props;
    const { contuitId, processId, executionId } = params;

    if (!checkedToken) {
      return <div>Loading...</div>;
    }

    if (!currentUser) {
      const redirectURL = `/tenants/${contuitId}/processes/${processId}/executions/${executionId}`;
      historyPush(`/login?redirectURL=${redirectURL}`);
      return null;
    }

    const tenantIds = currentUser.tenants.map((tenant) => tenant._id);

    if (!tenantIds.includes(contuitId)) {
      historyPush('/dashboards/personal');
      toast.error('You are not authorized to view this execution');
      return null;
    }

    if (currentUser.contuitId === contuitId) {
      historyPush(`/processes/${processId}/executions/${executionId}`);
      return null;
    }

    return (
      <div>
        <Modal show={true} onHide={this.handleCancel}>
          <Modal.Header closeButton>
            <Modal.Title>Switch Tenant</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            You will be switched to another tenant to view the execution.
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="outline-secondary"
              onClick={this.handleCancel}
              disabled={this.state.switchingTenants}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={this.handleProceed}
              disabled={this.state.switchingTenants}
            >
              Proceed
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currentUser: state.users.currentUser,
  checkedToken: state.users.checkedToken,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

const ShowStuckExecutionWithState = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ShowStuckExecution);

const ShowStuckExecutionWrapper = (props) => {
  const params = useParams();

  return <ShowStuckExecutionWithState {...props} params={params} />;
};

export default ShowStuckExecutionWrapper;
