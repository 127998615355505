import get from 'lodash/get';
import _ from 'underscore';
import { LinkContainer } from 'react-router-bootstrap';
import { ProcessServer } from '@contuit-otf/sdk';
import {
  React,
  PropTypes,
  connect,
  bindActionCreators,
} from '../../utility/ReactStuff';
import {
  SButton,
  Button,
  ButtonGroup,
  FormControl,
  Modal,
} from '../../utility/UiComponents';
import ProcessButtons from './ConnectedProcessButtons';
import PageLayout from '../shared/Layout/PageLayout';
import AddButton from '../shared/form/AddButton';
import DataTable from '../shared/DataTable/DataTable';
import request from '../../utility/request';
import CreateBundleModal from './ProcessForm/CreateBundleModal';

const processServer = new ProcessServer('/api/processes', request);

class CancellationToken {
  constructor() {
    this.isCancelled = false;
  }

  cancel() {
    this.isCancelled = true;
  }
}

class ListProcesses extends React.Component {
  constructor() {
    super();

    this.state = {
      filter: {
        type: 'all',
        status: 'active',
      },
      // sort: '-name',
      processes: null,
      processesLoading: true,
      processLoadError: '',
      showBundlingModal: false,
      lastCancelToken: null,
    };
  }

  componentDidMount() {
    document.title = 'Contuit | Playbook';
    this.loadProcesses();
  }

  componentWillUnmount() {
    document.title = 'Contuit';
  }

  getquery() {
    const {
      currentUser,
      currentUser: { showSolutionDetails },
    } = this.props;
    const { filter } = this.state;

    let query = {
      showDrafts: false,
      showActive: true,
      showArchived: false,
      noSolution: !showSolutionDetails,
    };
    _.each(filter, (value, key) => {
      if (value === '') {
        return;
      }

      if (key === 'status') {
        query = {
          ...query,
          showDrafts: value === 'all' || value === 'drafts',
          showActive: value === 'all' || value === 'active',
          showArchived: value === 'all' || value === 'archived',
        };

        return;
      }

      if (key === 'type') {
        if (value !== 'all') {
          query['initiation.initiationType'] = value;
        }

        return;
      }

      query[key] = value;
    });

    return {
      ...query,
    };
  }

  handleTyping = (e) => {
    this.setState(
      { filter: { ...this.state.filter, name: e.target.value } },
      () => {
        this.loadProcesses();
      },
    );
  };

  handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      this.loadProcesses();
      e.preventDefault();
    }

    return false;
  };

  openBundlingModal = () => this.setState({ showBundlingModal: true });

  closeBundlingModal = () => this.setState({ showBundlingModal: false });

  loadProcesses() {
    const {
      currentUser: { token },
    } = this.props;

    const { lastCancelToken } = this.state;
    if (lastCancelToken) {
      lastCancelToken.cancel();
    }

    // Create a new cancellation token and store it in the state,
    // so that we can cancel it if the user types again
    const newCancelToken = new CancellationToken();
    this.setState(
      { lastCancelToken: newCancelToken, processesLoading: true },
      () => {
        processServer
          .findProcesses({ token, query: this.getquery() })
          .then((data) => {
            // Check the cancellation token before performing any update
            if (!newCancelToken.isCancelled) {
              this.setState({ processes: data });
            }
          })
          .catch(() =>
            this.setState({
              processLoadError: 'Unknown error loading processes.',
            }),
          )
          .then(() => this.setState({ processesLoading: false }));
      },
    );
  }

  render() {
    const { routes, currentUser } = this.props;
    const { processes, processesLoading, filter, showBundlingModal } =
      this.state;

    return (
      <PageLayout
        header="Playbook"
        routes={routes}
        headerButtons={[
          <div style={{ marginRight: 5, display: 'inline' }}>
            <LinkContainer to={{ pathname: '/processes/active' }}>
              <Button variant="default" size="sm">
                View Live Executions
              </Button>
            </LinkContainer>
          </div>,
          <LinkContainer to={{ pathname: '/processes/new' }}>
            <AddButton size="sm" label="Add New Process" size="sm" />
          </LinkContainer>,
        ]}
        noContentPadding
        content={
          processes && (
            <DataTable
              selectable
              title={false}
              csvTitle="playbook"
              fetchCSVData={() => ({
                items: processes,
              })}
              data={processes}
              loading={processesLoading}
              totalCount={processes.length}
              columns={[
                {
                  id: 'actions',
                  display: 'Actions',
                  width: 260,
                  formatter: (row) => (
                    <ProcessButtons contuitProcess={row.original} viewDetails />
                  ),
                },
                // combine category and subcategory
                {
                  key: 'category',
                  display: 'Category',
                  width: 150,
                  formatter: (row) =>
                    `${row.original.category}${row.original.subCategory && '/'}${
                      row.original.subCategory
                    }`,
                },
                {
                  key: 'name',
                  display: 'Process Name',
                  width: 350,
                },
                {
                  key: 'initiation.initiationType',
                  display: 'Initiation Type',
                  type: 'withFormatter',
                  width: 150,
                  csvFormatter: (item) =>
                    get(item, 'initiation.initiationType'),
                },
                { key: 'dateUpdated', display: 'Updated', type: 'date' },
              ]}
              toolbar={({ toggledList }) => (
                <div className="clearfix">
                  <div
                    style={{
                      marginRight: '5px',
                      width: '200px',
                      display: 'inline-block',
                      verticalAlign: 'middle',
                    }}
                  >
                    <FormControl
                      type="text"
                      size="sm"
                      autoFocus
                      value={filter.name}
                      placeholder="Search processes"
                      onChange={this.handleTyping}
                      onKeyDown={this.handleKeyDown}
                    />
                  </div>
                  <ButtonGroup style={{ marginRight: '5px' }}>
                    {[
                      { id: 'all', label: 'All' },
                      { id: 'basic', label: 'Basic' },
                      { id: 'trigger', label: 'Trigger' },
                      { id: 'cbd-event', label: 'CBD Event' },
                    ].map(({ id, label }) => (
                      <SButton
                        size="xsmall"
                        key={id}
                        active={filter.type === id}
                        onClick={() => {
                          this.setState(
                            { filter: { ...filter, type: id } },
                            () => this.loadProcesses(),
                          );
                        }}
                      >
                        {label}
                      </SButton>
                    ))}
                  </ButtonGroup>
                  <ButtonGroup style={{ marginRight: '5px' }}>
                    {[
                      { id: 'all', label: 'All' },
                      { id: 'drafts', label: 'Drafts' },
                      { id: 'active', label: 'Live' },
                      { id: 'archived', label: 'Archived' },
                    ].map(({ id, label }) => (
                      <SButton
                        size="xsmall"
                        key={id}
                        active={filter.status === id}
                        onClick={() => {
                          this.setState(
                            { filter: { ...filter, status: id } },
                            () => this.loadProcesses(),
                          );
                        }}
                      >
                        {label}
                      </SButton>
                    ))}
                  </ButtonGroup>
                  <ButtonGroup style={{ marginRight: '5px' }}>
                    <SButton
                      disabled={toggledList.length === 0}
                      onClick={this.openBundlingModal}
                      size="xsmall"
                    >
                      Bundle {toggledList.length} Processes
                    </SButton>
                    <CreateBundleModal
                      showModal={showBundlingModal}
                      onHide={this.closeBundlingModal}
                      selectedProcesses={toggledList}
                      currentUser={currentUser}
                    />
                  </ButtonGroup>
                </div>
              )}
            />
          )
        }
      />
    );
  }
}

ListProcesses.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  currentUser: PropTypes.shape({
    token: PropTypes.string.isRequired,
  }).isRequired,
};

const mapStateToProps = (state) => ({ currentUser: state.users.currentUser });
const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ListProcesses);
