import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import { setPostLoginPage } from '../../../actions/users';

function AuthorizedComponent() {
  const navigate = useNavigate();
  const { checkedToken, currentUser } = useSelector((state) => state.users);
  const dispatch = useDispatch();

  useEffect(() => {
    if (checkedToken && !currentUser && window.location.pathname !== '/login') {
      dispatch(setPostLoginPage(window.location.pathname));

      navigate('/login');

      toast.error('You must be logged in to visit that page');
    }
  }, [checkedToken, currentUser]);

  if (!checkedToken) {
    return (
      <div className="overlay">
        <div className="message">
          <p>Loading...</p>
          <p>Checking user credentials...</p>
        </div>
      </div>
    );
  }

  if (!currentUser) {
    return <Navigate to="/login" />;
  }

  return <Outlet />;
}

export default AuthorizedComponent;
