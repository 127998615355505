import { WEBEX_ACTION_LISTENER_STEP_TYPE } from '../../../constants'
import ActionListenerUiHelper from './_ActionListenerUiHelper'

class WebexActionListenerUiHelper extends ActionListenerUiHelper {
  integrationTitle() {
    return 'Webex'
  }

  integrationName() {
    return 'webex-teams'
  }

  regex() {
    return WEBEX_ACTION_LISTENER_STEP_TYPE
  }
}

export default WebexActionListenerUiHelper
