import React from 'react';
import SolutionSettings from '../../modules/dashboards/Settings';

function SolutionSettingsDashboard() {
  return (
    <SolutionSettings />
  );
}

export default SolutionSettingsDashboard;
