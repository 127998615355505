"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _superagent = _interopRequireDefault(require("superagent"));
class VaultServer {
  static updateEntry(serverUri, token, slug, newEntry, contuitId) {
    var request = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : _superagent.default;
    return request.put("".concat(serverUri, "/structures/").concat(slug, "/").concat(newEntry._id)).query({
      contuitId
    }).send(newEntry).set('x-access-token', token).then(res => res.body);
  }
  static updateEntries(serverUri, _ref) {
    var {
      token,
      slug,
      newEntries,
      contuitId
    } = _ref;
    var request = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _superagent.default;
    if (!slug) {
      throw new Error('VaultServer.updateEntries requires an slug');
    }
    if (!token) {
      throw new Error('VaultServer.updateEntries requires a token');
    }
    if (!newEntries) {
      throw new Error('VaultServer.updateEntries requires a newEntries');
    }
    if (!contuitId) {
      throw new Error('VaultServer.updateEntries requires a contuitId');
    }
    return request.put("".concat(serverUri, "/structures/").concat(slug, "/bulk")).query({
      contuitId
    }).send(newEntries).set('x-access-token', token).then(res => res.body);
  }
  static getEntry(serverUri, _ref2) {
    var {
      token,
      slug,
      entryId
    } = _ref2;
    var request = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _superagent.default;
    return request.get("".concat(serverUri, "/structures/").concat(slug, "/").concat(entryId)).set('x-access-token', token).then(res => res.body);
  }
  static getSimpleEntryList(serverUri, _ref3) {
    var {
      token,
      slug,
      matchList,
      addedField,
      contuitId
    } = _ref3;
    var request = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _superagent.default;
    return request.get("".concat(serverUri, "/structures/").concat(slug, "/simple")).query({
      addedField,
      matchList,
      contuitId
    }).set('x-access-token', token).then(res => res.body);
  }
  static getEntryByExecutionStepId(serverUri, _ref4) {
    var {
      token,
      executionStepId
    } = _ref4;
    var request = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _superagent.default;
    if (!token) {
      throw new Error('VaultServer.getEntryByExecutionStepId requires a token');
    }
    if (!executionStepId) {
      throw new Error('VaultServer.getEntryByExecutionStepId requires an executionStepId');
    }
    return request.get("".concat(serverUri, "/structures/data/").concat(executionStepId)).set('x-access-token', token).then(res => res.body);
  }
  static createEntry(serverUri, _ref5) {
    var {
      token,
      slug,
      entry,
      contuitId,
      executionStepId
    } = _ref5;
    var request = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _superagent.default;
    return request.post("".concat(serverUri, "/structures/").concat(slug)).query({
      contuitId,
      executionStepId
    }).send(entry).set('x-access-token', token).then(res => res.body);
  }
  static bulkDeleteEntries(serverUri, _ref6) {
    var {
      token,
      slug,
      entries,
      contuitId
    } = _ref6;
    var request = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _superagent.default;
    if (!slug) {
      throw new Error('VaultServer.bulkDeleteEntries requires an slug');
    }
    if (!token) {
      throw new Error('VaultServer.bulkDeleteEntries requires a token');
    }
    if (!entries) {
      throw new Error('VaultServer.bulkDeleteEntries requires a entries');
    }
    if (!contuitId) {
      throw new Error('VaultServer.bulkDeleteEntries requires a contuitId');
    }
    return request.delete("".concat(serverUri, "/structures/").concat(slug, "/bulk")).query({
      contuitId
    }).send(entries).set('x-access-token', token).then(res => res.body);
  }
  static deleteEntry(serverUri, _ref7) {
    var {
      token,
      slug,
      entryId,
      contuitId
    } = _ref7;
    var request = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _superagent.default;
    if (!slug) {
      throw new Error('VaultServer.deleteEntry requires an slug');
    }
    if (!token) {
      throw new Error('VaultServer.deleteEntry requires a token');
    }
    if (!entryId) {
      throw new Error('VaultServer.deleteEntry requires a entryId');
    }
    if (!contuitId) {
      throw new Error('VaultServer.deleteEntry requires a contuitId');
    }
    return request.delete("".concat(serverUri, "/structures/").concat(slug, "/").concat(entryId)).query({
      contuitId
    }).set('x-access-token', token).then(res => res.body);
  }
  static deleteStructure(serverUri, _ref8) {
    var {
      token,
      slug
    } = _ref8;
    var request = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _superagent.default;
    if (!slug) {
      throw new Error('VaultServer.deleteStructure requires an slug');
    }
    if (!token) {
      throw new Error('VaultServer.deleteStructure requires a token');
    }
    return request.delete("".concat(serverUri, "/structures/").concat(slug)).set('x-access-token', token).then(res => res.body);
  }
  static updateStructure(serverUri, _ref9) {
    var {
      token,
      slug,
      newStructure,
      contuitId
    } = _ref9;
    var request = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _superagent.default;
    if (!slug) {
      throw new Error('VaultServer.updateStructure requires an slug');
    }
    if (!token) {
      throw new Error('VaultServer.updateStructure requires a token');
    }
    return request.put("".concat(serverUri, "/structures/").concat(slug)).query({
      contuitId
    }).send(newStructure).set('x-access-token', token).then(res => res.body);
  }
  static replaceEntries(serverUri, _ref10) {
    var {
      token,
      slug,
      entries,
      contuitId
    } = _ref10;
    var request = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _superagent.default;
    return request.post("".concat(serverUri, "/structures/").concat(slug, "/replace")).query({
      contuitId
    }).send(entries).set('x-access-token', token).then(res => res.body);
  }
  static loadVaultEntries(serverUri, _ref11) {
    var {
      token,
      slug,
      contuitId,
      query = {},
      regexSanitized,
      skipCount,
      hint,
      caseSensitive = false
    } = _ref11;
    var request = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _superagent.default;
    if (!slug) {
      throw new Error('VaultServer.loadVaultEntries requires an slug');
    }
    if (!token) {
      throw new Error('VaultServer.loadVaultEntries requires a token');
    }
    return request.post("".concat(serverUri, "/structures/").concat(slug, "/data")).query({
      contuitId
    }).send({
      query,
      regexSanitized,
      skipCount,
      hint,
      caseSensitive
    }).set('x-access-token', token).then(res => res.body);
  }
  static loadVaultEntry(serverUri, _ref12) {
    var {
      token,
      slug,
      entryId
    } = _ref12;
    var request = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _superagent.default;
    if (!slug) {
      throw new Error('VaultServer.loadVaultEntry requires an slug');
    }
    if (!token) {
      throw new Error('VaultServer.loadVaultEntry requires a token');
    }
    if (!entryId) {
      throw new Error('VaultServer.loadVaultEntry requires a entryId');
    }
    return request.get("".concat(serverUri, "/structures/").concat(slug, "/").concat(entryId)).set('x-access-token', token).then(res => res.body);
  }
  static loadRecentVaultEntries(serverUri, _ref13) {
    var {
      token
    } = _ref13;
    var request = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _superagent.default;
    if (!token) {
      throw new Error('VaultServer.loadVaultEntries requires a token');
    }
    return request.get("".concat(serverUri, "/structures/data/recent")).set('x-access-token', token).then(res => res.body);
  }
  static loadCustomerCounts(serverUri, _ref14) {
    var {
      token
    } = _ref14;
    var request = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _superagent.default;
    if (!token) {
      throw new Error('VaultServer.loadCustomerCounts requires a token');
    }
    return request.get("".concat(serverUri, "/structures/data/customers/structureCounts")).set('x-access-token', token).then(res => res.body);
  }
  static createVaultStructure(serverUri, _ref15) {
    var {
      token,
      structure,
      contuitId
    } = _ref15;
    var request = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _superagent.default;
    if (!structure) {
      throw new Error('VaultServer.createVaultStructure requires an structure');
    }
    if (!token) {
      throw new Error('VaultServer.createVaultStructure requires a token');
    }
    return request.post("".concat(serverUri, "/structures")).query({
      contuitId
    }).send(structure).set('x-access-token', token).then(res => res.body);
  }
  static loadVaultStructure(serverUri, _ref16) {
    var {
      token,
      slug,
      contuitId
    } = _ref16;
    var request = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _superagent.default;
    if (!slug) {
      throw new Error('VaultServer.loadVaultStructure requires an slug');
    }
    if (!token) {
      throw new Error('VaultServer.loadVaultStructure requires a token');
    }
    return request.get("".concat(serverUri, "/structures/").concat(slug)).query({
      contuitId
    }).set('x-access-token', token).then(res => res.body);
  }
  static loadVaultStructures(serverUri, _ref17) {
    var {
      token,
      contuitId
    } = _ref17;
    var request = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _superagent.default;
    if (!token) {
      throw new Error('VaultServer.loadVaultStructures requires a token');
    }
    return request.get("".concat(serverUri, "/structures")).query({
      contuitId
    }).set('x-access-token', token).then(res => res.body);
  }
  static loadMappings(serverUri, _ref18) {
    var {
      token,
      communityProcessId
    } = _ref18;
    var request = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _superagent.default;
    if (!communityProcessId) {
      throw new Error('VaultServer.loadMappings requires an communityProcessId');
    }
    if (!token) {
      throw new Error('VaultServer.loadMappings requires a token');
    }
    return request.post("".concat(serverUri, "/mappings")).query({
      communityProcessId
    }).set('x-access-token', token).then(res => res.body);
  }
  static seedNewClient(serverUri, _ref19) {
    var {
      token
    } = _ref19;
    var request = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _superagent.default;
    if (!token) {
      throw new Error('VaultServer.seedNewClient requires a token');
    }
    return request.post("".concat(serverUri, "/seed")).set('x-access-token', token).then(res => res.body);
  }
  static getData(serverUri, _ref20) {
    var {
      token,
      slug,
      contuitId
    } = _ref20;
    var request = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _superagent.default;
    if (!slug) {
      throw new Error('VaultServer.getData requires a slug');
    }
    if (!token) {
      throw new Error('VaultServer.getData requires a token');
    }
    if (!contuitId) {
      throw new Error('VaultServer.getData requires a contuitId');
    }
    return request.get("".concat(serverUri, "/structures/").concat(slug, "/data")).set('x-access-token', token).query({
      contuitId
    }).then(res => res.body);
  }
  static clearAllEntries(serverUri, _ref21) {
    var {
      token,
      slug
    } = _ref21;
    var request = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _superagent.default;
    if (!slug) {
      throw new Error('VaultServer.clearAllEntries requires a slug');
    }
    if (!token) {
      throw new Error('VaultServer.clearAllEntries requires a token');
    }
    return request.delete("".concat(serverUri, "/structures/").concat(slug, "/clearAll")).set('x-access-token', token).then(res => res.body).catch(err => {
      var {
        response: {
          body
        }
      } = err;
      var msg = !body.message ? 'Unknown error clearing entries, please try again later.' : body.message;
      throw new Error(msg);
    });
  }
  constructor(serverUri) {
    var request = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : _superagent.default;
    this._serverUri = serverUri;
    this._request = request;
  }
  updateEntry(token, slug, newEntry, contuitId) {
    return VaultServer.updateEntry(this._serverUri, token, slug, newEntry, contuitId, this._request);
  }
  updateEntries(options) {
    return VaultServer.updateEntries(this._serverUri, options, this._request);
  }
  getEntry(options) {
    return VaultServer.getEntry(this._serverUri, options, this._request);
  }
  getSimpleEntryList(options) {
    return VaultServer.getSimpleEntryList(this._serverUri, options, this._request);
  }
  getEntryByExecutionStepId(options) {
    return VaultServer.getEntryByExecutionStepId(this._serverUri, options, this._request);
  }
  createEntry(options) {
    return VaultServer.createEntry(this._serverUri, options, this._request);
  }
  deleteEntry(options) {
    return VaultServer.deleteEntry(this._serverUri, options, this._request);
  }
  bulkDeleteEntries(options) {
    return VaultServer.bulkDeleteEntries(this._serverUri, options, this._request);
  }
  deleteStructure(options) {
    return VaultServer.deleteStructure(this._serverUri, options, this._request);
  }
  updateStructure(options) {
    return VaultServer.updateStructure(this._serverUri, options, this._request);
  }
  replaceEntries(options) {
    return VaultServer.replaceEntries(this._serverUri, options, this._request);
  }
  loadVaultEntries(options) {
    return VaultServer.loadVaultEntries(this._serverUri, options, this._request);
  }
  loadVaultEntry(options) {
    return VaultServer.loadVaultEntry(this._serverUri, options, this._request);
  }
  loadRecentVaultEntries(options) {
    return VaultServer.loadRecentVaultEntries(this._serverUri, options, this._request);
  }
  loadCustomerCounts(options) {
    return VaultServer.loadCustomerCounts(this._serverUri, options, this._request);
  }
  createVaultStructure(options) {
    return VaultServer.createVaultStructure(this._serverUri, options, this._request);
  }
  loadVaultStructure(options) {
    return VaultServer.loadVaultStructure(this._serverUri, options, this._request);
  }
  loadVaultStructures(options) {
    return VaultServer.loadVaultStructures(this._serverUri, options, this._request);
  }
  loadMappings(options) {
    return VaultServer.loadMappings(this._serverUri, options, this._request);
  }
  seedNewClient(options) {
    return VaultServer.seedNewClient(this._serverUri, options, this._request);
  }
  clearAllEntries(options) {
    return VaultServer.clearAllEntries(this._serverUri, options, this._request);
  }
  getData(options) {
    return VaultServer.getData(this._serverUri, options, this._request);
  }
}
var _default = exports.default = VaultServer;