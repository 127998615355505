// import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import { Button, Collapse } from '../../utility/UiComponents';

class Collapsible extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      collapsed: false
    };
  }

  render() {
    const { children, pullLeft, message } = this.props;
    const { collapsed } = this.state;

    return (
      <div>
        <div className="clearfix">
          <div className={pullLeft ? 'pull-left' : 'clearfix'}>
            <Button
              size="xsmall"
              variant="link"
              className="pull-left"
              onClick={() => {
                this.setState({ collapsed: !collapsed });
              }}
            >
              {!collapsed && (
                <span>
                  <i className="fa fa-plus" /> show {message}
                </span>
              )}
              {collapsed && (
                <span>
                  <i className="fa fa-minus" /> hide {message}
                </span>
              )}
            </Button>
          </div>
        </div>
        <div className="clearfix">
          <Collapse in={collapsed} className={pullLeft ? 'pull-left' : ''}>
            {children}
          </Collapse>
        </div>
      </div>
    );
  }
}

Collapsible.propTypes = {
  children: PropTypes.node,
  pullLeft: PropTypes.bool,
  message: PropTypes.string
};

Collapsible.defaultProps = {
  children: null,
  pullLeft: true,
  message: ''
};

export default Collapsible;
