import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { LinkContainer } from 'react-router-bootstrap';
import { LoadingButton } from 'lib/acromyrmex';
import { Button } from '../../utility/UiComponents';
import {
  loadUser as loadUserAction,
  updateUser as updateUserAction,
  openRolesModal as openRolesModalAction
} from '../../actions/users';
import ConfirmModal from '../shared/ConfirmModal';

const isCurrentUser = (currentUser, item) => currentUser._id === item._id;
const isDisabled = item => item.disabled;

const UserButtons = ({
  currentUser,
  item: user,
  pullRight,
  isUpdating,
  loadUser,
  updateUser,
  openRolesModal
}) => {
  const [showConfirmDisable, setShowConfirmDisable] = useState(false);

  const enableDisable = isDisabled(user) ? 'enabl' : 'disabl';
  return (
    <div
      className={classNames('buttons-margin', {
        'pull-right': pullRight
      })}
    >
      <LinkContainer
        to={{
          pathname: `/admin/users/${user._id}`
        }}
      >
        <Button size="xsmall" variant="primary">
          View Profile
        </Button>
      </LinkContainer>

      {!isCurrentUser(currentUser, user) && (
        <Button
          size="xsmall"
          variant="default"
          onClick={() => {
            loadUser(user._id);
            openRolesModal();
          }}
        >
          Manage Roles
        </Button>
      )}

      {
        (!isCurrentUser(currentUser, user),
        isDisabled(user) && (
          <span>
            <LoadingButton
              className="btn-success"
              style={{ marginRight: 5 }}
              size="xsmall"
              loading={isUpdating}
              loadingLabel="Enabling User"
              onClick={() => {
                loadUser(user._id);
                setShowConfirmDisable(true);
              }}
              label={
                <span>
                  <i className="fa fa-plus" /> Enable
                </span>
              }
            />
          </span>
        ))
      }

      {!isCurrentUser(currentUser, user) && !isDisabled(user) && (
        <LoadingButton
          className="btn-danger"
          style={{ marginRight: 5 }}
          size="xsmall"
          loading={isUpdating}
          loadingLabel="Disabling User"
          onClick={() => {
            loadUser(user._id);
            setShowConfirmDisable(true);
          }}
          label={
            <span>
              <i className="fa fa-ban" /> Disable
            </span>
          }
        />
      )}

      <ConfirmModal
        show={showConfirmDisable}
        title={`Confirm ${enableDisable}ing ${user.name}?`}
        message={`Are you sure you want to ${enableDisable}e ${user.name}?`}
        onConfirm={() => {
          updateUser(user._id, {
            ...user,
            disabled: !isDisabled(user),
            dateDisabled: isDisabled(user) ? null : new Date()
          });
          setShowConfirmDisable(false);
        }}
        onCancel={() => {
          setShowConfirmDisable(false);
        }}
      />
    </div>
  );
};

UserButtons.propTypes = {
  pullRight: PropTypes.bool,
  isUpdating: PropTypes.bool.isRequired,
  item: PropTypes.shape().isRequired,
  updateUser: PropTypes.func.isRequired,
  currentUser: PropTypes.shape().isRequired,
  loadUser: PropTypes.func.isRequired,
  openRolesModal: PropTypes.func.isRequired
};

UserButtons.defaultProps = {
  pullRight: false
};

const mapStateToProps = state => ({
  currentUser: state.users.currentUser,
  isUpdating: state.users.user.isUpdating
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      loadUser: loadUserAction,
      updateUser: updateUserAction,
      openRolesModal: openRolesModalAction
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(UserButtons);
