import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { VaultServer } from '@contuit-otf/sdk';
import { LinkContainer } from 'react-router-bootstrap';
import _ from 'underscore';
import get from 'lodash/get';
import Vault from '../../../utility/vault';
import { Button } from '../../../utility/UiComponents';
import request from '../../../utility/request';

const vaultServer = new VaultServer('/api/vault', request);

const VaultEntryMention = ({ slug, id, vault, struct, data, currentUser }) => {
  const [entry, setEntry] = useState(data);

  if (!struct && !data) {
    struct = Vault.getStructure(vault, slug);
  }

  useEffect(() => {
    if (id) {
      vaultServer
        .getEntry({ token: currentUser.token, slug, entryId: id })
        .then(setEntry);
    }
  }, [slug, id]);

  // at first data might not be here
  if (!entry) {
    return <div />;
  }

  return (
    <div>
      <strong>{struct && struct.singular}: </strong>{' '}
      <LinkContainer
        to={{ pathname: `/vault/${struct && struct.slug}/${entry._id}` }}
      >
        <Button size="xsmall" variant="primary" style={{ marginTop: -1 }}>
          {vault
            ? Vault.getDisplay(vault, slug, entry)
            : Vault.getDisplayFromStruct(struct, entry)}
        </Button>
      </LinkContainer>
    </div>
  );
};

// Define property types
VaultEntryMention.propTypes = {
  id: PropTypes.string,
  slug: PropTypes.string,
  vault: PropTypes.shape(),
  data: PropTypes.shape(),
  struct: PropTypes.shape(),
  currentUser: PropTypes.shape({
    token: PropTypes.string.isRequired,
  }).isRequired,
};

VaultEntryMention.defaultProps = {
  id: null,
  slug: null,
  vault: null,
  data: null,
  struct: null,
};

const VaultStructureMention = ({ vaultStructure, vault }) => {
  const struct = Vault.getStructure(vault, vaultStructure);
  //
  return (
    <div>
      <LinkContainer to={{ pathname: `/vault/${vaultStructure}` }}>
        <Button size="xsmall" variant="primary" style={{ marginTop: -1 }}>
          {get(struct, 'singular', 'View Vault Structure')}
        </Button>
      </LinkContainer>
    </div>
  );
};

VaultStructureMention.propTypes = {
  vaultStructure: PropTypes.string.isRequired,
  vault: PropTypes.shape().isRequired,
};

export default VaultEntryMention;

export { VaultStructureMention };
