import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import UserForm from './UserForm';
import {
  submitNewUser as submitNewUserAction,
  logoutCurrentUser as logoutCurrentUserAction
} from '../../actions/users';
import PageLayout from '../shared/Layout/PageLayout';

const checkForLogout = props => {
  if (props.users.currentUser) {
    props.logoutCurrentUser(false);
  }
};

class CreateUser extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hasSubmitted: false
    };
  }

  componentWillMount() {
    const { hasSubmitted } = this.state;
    if (!hasSubmitted) {
      checkForLogout(this.props);
    }
  }

  componentWillUpdate(nextProps, nextState) {
    if (!nextState.hasSubmitted) {
      checkForLogout(nextProps);
    }
  }

  render() {
    const {
      submitNewUser,
      location: { query },
      currentUser,
      routes
    } = this.props;

    return (
      <PageLayout
        header="Create an admin"
        noContentPadding
        content={
          <UserForm
            onSubmit={() => {
              this.setState({ hasSubmitted: true }, () => {
                submitNewUser();
              });
            }}
            code={query.code || false}
            initialValues={{
              code: query.code || ''
            }}
          />
        }
        routes={routes}
        currentUser={currentUser}
      />
    );
  }
}

// Define property types
CreateUser.propTypes = {
  submitNewUser: PropTypes.func.isRequired,
  location: PropTypes.shape().isRequired,
  currentUser: PropTypes.shape().isRequired,
  routes: PropTypes.arrayOf(PropTypes.shape()).isRequired
};

CreateUser.defaultProps = {};

const mapStateToProps = state => ({
  currentUser: state.users.currentUser,
  users: state.users
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      submitNewUser: submitNewUserAction,
      logoutCurrentUser: logoutCurrentUserAction
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CreateUser);
