import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { FieldArray } from 'redux-form';
import PropTypes from 'prop-types';
import { Row, Col, Accordion} from '../../../../../utility/UiComponents';
import ApiParameterList from '../../../ProcessForm/ApiParameterList';
import IntegrationSelect from './integration/IntegrationSelect';

// eslint-disable-next-line
class VaultCreateStepOptions extends React.Component {
  constructor(props) {
    super(props);

    this.renderParams = this.renderParams.bind(this);
  }

  renderParams({ fields }) {
    const {
      outputOptions,
      change,
      fieldText,
      array,
      processStep,
      executionStep,
      integrationOptions
    } = this.props;

    return (
      <ApiParameterList
        fields={fields}
        outputOptions={outputOptions}
        change={change}
        integrationOptions={integrationOptions}
        stepType={processStep.stepType}
        currentOptions={executionStep.stepOptions}
        array={array}
        fieldText={`${fieldText}.stepOptions`}
      />
    );
  }

  render() {
    const { fieldText, processStep, outputOptions, outputOptionsLoaded } = this.props;

    return (
      <Row key={fieldText} style={{ marginBottom: 10 }}>
          <Accordion defaultActiveKey="1">
          <Accordion.Item eventKey="1">
            <Accordion.Header>{`${processStep.name || ''} Step Options`}</Accordion.Header>
            <Accordion.Body>
            <IntegrationSelect
              name={`${fieldText}.stepOptions.integration`}
              stepType={processStep.stepType}
              outputOptions={outputOptions}
              outputOptionsLoaded={outputOptionsLoaded}
            />
            <Row style={{ marginBottom: 10 }}>
              <Col xs={5}>
                <strong>Label</strong>
              </Col>
              <Col xs={6}>
                <strong>Value</strong>
              </Col>
            </Row>
            {outputOptionsLoaded && (
              <FieldArray
                name={`${fieldText}.stepOptions.parameters`}
                component={this.renderParams}
              />
            )}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Row>
    );
  }
}

// Define property types
VaultCreateStepOptions.propTypes = {
  fieldText: PropTypes.string.isRequired,
  processStep: PropTypes.shape().isRequired,
  outputOptionsLoaded: PropTypes.bool.isRequired,

  executionStep: PropTypes.shape().isRequired,
  integrationOptions: PropTypes.shape().isRequired,
  outputOptions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  change: PropTypes.func.isRequired,
  array: PropTypes.shape().isRequired
};

VaultCreateStepOptions.defaultProps = {};

const mapStateToProps = state => ({
  structureList: state.vault.structureList.rows
});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(VaultCreateStepOptions);
