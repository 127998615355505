// import isFunction from 'lodash/isFunction';
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import set from 'lodash/set'
import IntegrationSelect from './integration/IntegrationSelect'
import TableForm from '../../../../shared/form/TableForm'
import {
  Button,
  Row,
  Col,Accordion,
} from '../../../../../utility/UiComponents'
import AddButton from '../../../../shared/form/AddButton'

class StepOptions extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    fieldPrefix: PropTypes.string.isRequired,
    fields: PropTypes.arrayOf(PropTypes.shape()),
    outputOptions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    outputOptionsLoaded: PropTypes.bool.isRequired,
    processStep: PropTypes.shape().isRequired,
    children: PropTypes.node,
    onChangeAttributeNeeded: PropTypes.func.isRequired,
  }

  static defaultProps = {
    children: '',
    fields: [],
  }

  constructor() {
    super()

    this.state = {}
  }

  onFieldChanged(onChangeNeeded, composedFieldName) {
    const { processStep, onChangeAttributeNeeded } = this.props

    // when calling on change needed, we need to drop the steps[x]. portion
    const updateName = composedFieldName.substring(
      composedFieldName.indexOf(']') + 2,
    )

    return (a, value) => {
      // easy exit flag
      if (!onChangeNeeded) {
        return false
      }

      // copy everything currently
      const newValues = {
        ...processStep,
        stepOptions: { ...processStep.stepOptions },
      }

      // safely update the object with our new value
      set(newValues, updateName, value)

      // fire the function passed from the parent
      onChangeAttributeNeeded(newValues)
    }
  }

  tableFormFieldArray = ({ fields, field }) => {
    const { onChangeAttributeNeeded } = this.props
    const rows = fields.map(
      this.renderTableRow(field, fields, onChangeAttributeNeeded),
    )
    return (
      <div>
        {rows}
        <Row className="step-option-buttons">
          <Col xs={12}>
            <AddButton size="sm"
              label="Add Field"
              onClick={() => {
                fields.push({
                  slug: '',
                  type: 'string',
                  label: '',
                })
              }}
            />
          </Col>
        </Row>
      </div>
    )
  }

  renderTableRow(field, fields) {
    const { onChangeAttributeNeeded } = this.props

    return (member, index) => (
      <Row style={{ marginBottom: 10 }} key={member}>
        {/* Render each column as defined by the headers */}
        {field.headers.map((header) => {
          const {
            field: { name, onChangeNeeded, addon, ...columnField },
          } = header

          return this.renderField(member, name, {
            sizing: { xs: header.xs },
            onChangeNeeded,
            extraProps: columnField,
            currentRow: fields.get(index),
            addon,
          })
        })}

        {/* Add the button for deleting this row */}
        <Col xs={1}>
          <Button
            variant="danger"
            onClick={() => fields.remove(index) && onChangeAttributeNeeded()}
            title="Remove this step"
          >
            <i className="fa fa-trash" />
          </Button>
        </Col>
      </Row>
    )
  }

  renderField(fieldPrefix, fieldName, options) {
    const {
      sizing,
      onChangeNeeded = true,
      extraProps,
      addon,
      currentRow,
    } = options
    const composedFieldName = `${fieldPrefix}.${fieldName}`

    // The actual field we are creating. It may be returned within a wrapper
    const baseField = (
      <Field
        name={composedFieldName}
        key={composedFieldName}
        onChange={this.onFieldChanged(onChangeNeeded, composedFieldName)}
        {...extraProps}
      />
    )

    if (addon) {
      const {
        enabled: addonEnabled,
        sizing: addonSizing,
        component: AddonComponent,
        props: addonProps,
      } = addon({ rowPrefix: fieldPrefix })

      if (addonEnabled({ currentRow })) {
        return (
          <Col xs={sizing.xs} key={composedFieldName}>
            <Row>
              <Col xs={12 - addonSizing.xs}>{baseField}</Col>
              <Col xs={addonSizing.xs}>
                <AddonComponent {...addonProps} />
              </Col>
            </Row>
          </Col>
        )
      }
    }

    if (sizing) {
      return (
        <Col xs={sizing.xs} key={composedFieldName}>
          {baseField}
        </Col>
      )
    }

    return baseField
  }

  /**
   * Renders a table of form rows
   * @param {*} fieldPrefix form name prefix for the step
   * @param {*} name name of the array in the form
   * @param {*} field the original field that represents this TableForm
   */
  renderTableForm(fieldPrefix, name, field) {
    return (
      <TableForm
        key={`${fieldPrefix}.stepOptions.${name}`}
        name={`${fieldPrefix}.stepOptions.${name}`}
        headers={field.headers}
        fieldArrayProps={{ field }}
        fieldArrayComponent={this.tableFormFieldArray}
      />
    )
  }

  render() {
    const {
      title,
      children,
      fieldPrefix,
      fields,
      outputOptions,
      outputOptionsLoaded,
    } = this.props

    return (
      <Row key={fieldPrefix} style={{ marginBottom: 10 }}>
        <Accordion defaultActiveKey="1">
          <Accordion.Item eventKey="1">
            <Accordion.Header>{title}</Accordion.Header>
            <Accordion.Body>
              {children}
              {fields.map((field) => {
                const { name, ...fieldProps } = field

                if (field.component === TableForm) {
                  return this.renderTableForm(fieldPrefix, name, field)
                }

                if (field.component === IntegrationSelect) {
                  return (
                    <IntegrationSelect
                      name={`${fieldPrefix}.stepOptions.integration`}
                      stepType={`i(${field.integrationName})[sendMessagetoConversation]`}
                      outputOptions={outputOptions}
                      outputOptionsLoaded={outputOptionsLoaded}
                    />
                  )
                }

                if (field.rawComponent) {
                  return (
                    <field.rawComponent
                      name={`${fieldPrefix}.stepOptions.${name}`}
                      {...fieldProps}
                    />
                  )
                }

                return this.renderField(`${fieldPrefix}.stepOptions`, name, {
                  // onChangeNeeded,
                  extraProps: fieldProps,
                })
              })}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Row>
    )
  }
}

export default StepOptions
