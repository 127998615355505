import React from 'react'
import get from 'lodash/get'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { LinkContainer, IndexLinkContainer } from 'react-router-bootstrap'
import { bindActionCreators } from 'redux'
import { Loading, TextInput } from 'lib/acromyrmex'
import { Row, Col, Alert, Button, Modal } from '../../utility/UiComponents'
import EnableIntegrationForm from './EnableIntegrationForm'
import { AvailableIntegrations as AvailableIntegrationsCopy } from '../copy'
import { loadAvailableIntegrations as loadAvailableIntegrationsAction } from '../../actions/integrations'
import {
  createEnabledIntegration as createEnabledIntegrationAction,
  loadEnabledIntegrations as loadEnabledIntegrationsAction,
} from '../../actions/enabledIntegrations'
import EmptyView from '../shared/EmptyView'
import PageLayout from '../shared/Layout/PageLayout'

class ListAvailableIntegrations extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      showModal: false,
      modalValue: null,
      searchValue: '',
    }

    this.renderIntegration = this.renderIntegration.bind(this)
    this.openModal = this.openModal.bind(this)
    this.closeModal = this.closeModal.bind(this)
    this.onFormSubmit = this.onFormSubmit.bind(this)
  }

  componentWillMount() {
    const { loadAvailableIntegrations, loadEnabledIntegrations } = this.props

    loadAvailableIntegrations()
    loadEnabledIntegrations()
  }

  onFormSubmit() {
    const { createEnabledIntegration, formValues } = this.props
    const { modalValue } = this.state

    createEnabledIntegration({
      integrationName: modalValue['x-integration-slug'],
      ...formValues,
    })
  }

  openModal(modalValue) {
    this.setState({ showModal: true, modalValue })
  }

  closeModal() {
    this.setState({ showModal: false, modalValue: null })
  }

  renderIntegration(item) {
    if (!item) {
      return null
    }

    return (
      <Col xs={12} key={item['x-integration-id']}>
        <Row className="integration-thumb thumbnail">
          <Col xs={12} sm={3}>
            <div
              className="clearfix"
              style={
                item['x-logo-bg']
                  ? {
                      backgroundColor: `#${item['x-logo-bg']}`,
                      margin: '-5px',
                      padding: '5px',
                    }
                  : {}
              }
            >
              <div
                className="img"
                style={{ backgroundImage: `url('${item['x-logo-url']}')` }}
              />
            </div>
          </Col>
          <Col xs={12} sm={6}>
            <h3>{item.info && item.info.title}</h3>
            <p>{item.info && item.info.description}</p>
          </Col>
          <Col xs={12} sm={3}>
            {/* <Row>
              <Col xs={6}> */}
            <Button
              size="sm"
              variant="primary"
              style={{ width: '100%', marginBottom: '5px' }}
              onClick={() => {
                this.openModal(item)
              }}
            >
              Enable
            </Button>

            <div style={{ width: '100%' }}>
              <LinkContainer
                to={{
                  pathname: `/admin/integrations/available/${item['x-integration-id']}`,
                }}
                style={{ width: 'inherit' }}
              >
                <Button size="sm" variant="default">
                  View Details
                </Button>
              </LinkContainer>
            </div>
          </Col>
        </Row>
      </Col>
    )
  }

  render() {
    const {
      integrations,
      isLoading,
      enabledIntegrations,
      currentUser,
      routes,
    } = this.props
    const { modalValue, showModal, searchValue } = this.state

    return (
      <PageLayout
        header="Available Integrations"
        routes={routes}
        currentUser={currentUser}
        help={AvailableIntegrationsCopy}
        headerButtons={
          <LinkContainer to={{ pathname: '/admin/integrations' }}>
            <Button variant="info" size="sm" className="pull-right">
              View Enabled Integrations
            </Button>
          </LinkContainer>
        }
        content={[
          <div style={{ paddingTop: '10px' }}>
            <Col xs={12}>
              <TextInput
                label="Search"
                type="text"
                input={{
                  value: searchValue,
                  onChange: ({ target: { value } }) =>
                    this.setState({ searchValue: value }),
                  onBlur: () => {},
                }}
                meta={{}}
              />
            </Col>
          </div>,
          <div style={{ paddingTop: '10px' }}>
            {isLoading && (
              <Alert variant="info" className="pull-left indented">
                <Loading />
                {' Loading Available Integrations'}
              </Alert>
            )}
            {!isLoading &&
              integrations.length > 0 &&
              integrations
                .filter(
                  (i) =>
                    !get(i, 'x-ignore', false) &&
                    get(i, 'info.title', '')
                      .toLowerCase()
                      .indexOf(searchValue.toLowerCase()) > -1,
                )
                .map(this.renderIntegration)}
            {!isLoading && integrations.length < 1 && (
              <EmptyView
                header="No available integrations found"
                content="It looks like you've already enabled everything!"
                button={{
                  pathname: '/admin/integrations',
                  content: 'View Your Enabled Integrations',
                  style: 'info',
                }}
              />
            )}
            <Modal show={showModal} onHide={this.closeModal} size="large">
              <Modal.Header closeButton>
                <Modal.Title>
                  Enable {modalValue && modalValue.info.title}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <EnableIntegrationForm
                  onSubmit={this.onFormSubmit}
                  integration={modalValue}
                  enabledIntegrations={enabledIntegrations}
                  initialValues={{
                    integrationId: modalValue && modalValue['x-integration-id'],
                  }}
                />
              </Modal.Body>
            </Modal>
          </div>,
        ]}
      />
    )
  }
}

// Define property types
ListAvailableIntegrations.propTypes = {
  loadAvailableIntegrations: PropTypes.func.isRequired,
  currentUser: PropTypes.shape().isRequired,
  routes: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  loadEnabledIntegrations: PropTypes.func.isRequired,
  createEnabledIntegration: PropTypes.func.isRequired,
  integrations: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  enabledIntegrations: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  formValues: PropTypes.shape(),
  isLoading: PropTypes.bool,
}

ListAvailableIntegrations.defaultProps = {
  isLoading: false,
  formValues: {},
}

const mapStateToProps = (state) => ({
  currentUser: state.users.currentUser,
  integrations: state.integrations.availableIntegrations.rows,
  enabledIntegrations: state.enabledIntegrations.enabledIntegrations.rows,
  isLoading: state.integrations.availableIntegrations.isLoading,
  formValues: state.form.enableIntegrationForm
    ? state.form.enableIntegrationForm.values
    : {},
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      loadAvailableIntegrations: loadAvailableIntegrationsAction,
      createEnabledIntegration: createEnabledIntegrationAction,
      loadEnabledIntegrations: loadEnabledIntegrationsAction,
    },
    dispatch,
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ListAvailableIntegrations)
