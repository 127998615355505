export default {
  EQUALS: 0,
  LESS_THAN: 1,
  GREATER_THAN: 2,
  LESS_THAN_EQUAL: 3,
  GREATER_THAN_EQUAL: 4,
  CONTAINS: 5,
  STARTS_WITH: 6,
  ENDS_WITH: 7,
  NOT_EQUALS: 8,
  IS_NULL: 9,
  IS_NOT_NULL: 10,
  DOES_NOT_CONTAIN: 11,
}
