import { useState, useEffect } from 'react'
import request from '../utility/request'
import get from 'lodash/get'

const templateCache = {}

function useTemplateString(
  inputString,
  currentUser,
  execution,
  defaultValue = '',
) {
  const [outputString, setOutputString] = useState(defaultValue)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const cached = get(templateCache, [execution._id, inputString])
    if (cached && cached instanceof Promise) {
      // if we have already started the request, we can just wait for it to finish
      cached
        .then((data) => {
          setOutputString(data.result)
          setOutputString(data)
          setLoading(false)
        })
        .catch((err) => {
          setError(err)
          setOutputString('')
          setLoading(false)
        })
      return
    }
    if (cached) {
      // if we have the data cached, we can just use it
      setOutputString(templateCache[execution._id][inputString])
      setLoading(false)
      return
    }
    // otherwise, we need to make the request
    templateCache[execution._id] = templateCache[execution._id] || {}
    templateCache[execution._id][inputString] = new Promise(
      (resolve, reject) => {
        request
          .post('/api/processes/process-template-values')
          .send({ inputString, execution })
          .set('x-access-token', currentUser.token)
          .then(({ body: data }) => {
            templateCache[execution._id][inputString] = data.result
            setOutputString(data.result)
            setLoading(false)
            resolve(data.result)
          })
          .catch((err) => {
            templateCache[execution._id][inputString] = ''
            setOutputString('')
            setLoading(false)
            reject(err)
          })
      },
    )
  }, [inputString])

  return [outputString, loading, error]
}

export default useTemplateString
