import _ from 'underscore';
import React, { useEffect } from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { Loading } from 'lib/acromyrmex';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { Row, Button, Alert } from '../../utility/UiComponents';
import Vault from '../../utility/vault';
import VaultEntryButtons from './VaultEntryButtons';
import EmptyView from '../shared/EmptyView';
import {
  loadVaultStructureData,
  loadVaultStructure,
  deleteEntry,
} from '../../actions/vault';
import { loadUsers } from '../../actions/users';
import { loadVaultExecutions } from '../../actions/executions';
import PageLayout from '../shared/Layout/PageLayout';
import VaultEntryAttributes from './VaultEntryAttributes';
import ShowProcessExecutionTable from '../processes/ShowProcess/ShowProcessExecutionTable';

function ShowVaultEntry() {
  const dispatch = useDispatch();
  const params = useParams();
  const { vaultStructureId: slug } = useParams();
  const struct = useSelector((state) => state.vault.structures[slug]);
  const vault = useSelector((state) => state.vault);
  const currentUser = useSelector((state) => state.users.currentUser);
  const executionsLoading = useSelector((state) => state.processes.executions.isLoading);
  const { dataId } = params;
  const structLoading = useSelector((state) => (
    state.vault.structures[slug]
      ? state.vault.structures[slug].isLoading
      : false
  ));
  const isDeleting = useSelector((state) => (
    state.vault.data[slug]
      ? state.vault.data[slug].isDeleting
      : false
  ));
  const isLoading = useSelector((state) => (
    state.vault.data[slug]
      ? state.vault.data[slug].isLoading
      : true
  ));

  const renderActions = (struct) => {
    if (!struct || !struct.actions) {
      return [];
    }

    return struct.actions.map((action) => (
      <LinkContainer
        to={{ pathname: action.path }}
        key={action.slug}
        style={{ marginRight: '5px' }}
      >
        <Button className="btn-primary pull-right" size="xsmall">
          {action.display}
        </Button>
      </LinkContainer>
    ));
  };

  const getData = () => {
    const data = Vault.getDataById(vault, slug, dataId);
    return data;
  };

  const renderProcessesTable = () => (
    <ShowProcessExecutionTable
      currentUser={currentUser}
      vaultStructure={slug}
      vaultEntry={dataId}
    />
  );

  const renderHeaderButtons = () => {
    const data = getData();

    return [
      <VaultEntryButtons
        key="entry-buttons"
        entry={data}
        isDeleting={isDeleting}
        deleteEntry={deleteEntry}
        slug={struct.slug}
        pullRight
      />,
      renderActions(struct),
    ];
  };

  // componentDidMount
  useEffect(() => {
    if (!Vault.hasStructureLoaded(vault.data, slug)) dispatch(loadVaultStructure(slug));

    // always want to update data in case it has changed
    dispatch(loadVaultStructureData(slug, { _id: dataId }, true));
    dispatch(loadVaultExecutions(slug, dataId));
    dispatch(loadUsers());
  }, []);

  // componentDidUpdate
  useEffect(() => {
    const data = getData();

    if (struct && data) {
      document.title = `Contuit | ${Vault.getDisplayFromStruct(struct, data)}`;
    }

    return () => {
      document.title = 'Contuit';
    };
  });

  const data = getData();
  const structNotReady = !struct || structLoading || executionsLoading;

  if (structNotReady || (!data && isLoading)) {
    return (
      <div className="container" style={{ height: '100%' }}>
        <div className="box-wrapper">
          <Row className="content-row box-rest" style={{ marginTop: '10px' }}>
            <Alert variant="info" className="pull-left indented">
              <Loading />
              {' '}
              Loading
              {' '}
              {slug}
              {' '}
              structure...
            </Alert>
          </Row>
        </div>
      </div>
    );
  }

  if (!data) {
    return (
      <EmptyView
        header={`No ${slug} found with id: ${dataId}`}
        button={{
          pathname: `/environment/${slug}`,
          content: 'Back to List',
        }}
      />
    );
  }

  return (
    <PageLayout
      header={struct && Vault.getDisplayFromStruct(struct, data)}
      headerButtons={renderHeaderButtons()}
      noContentPadding
      leftSidebar={<VaultEntryAttributes struct={struct} entry={data} />}
      content={renderProcessesTable()}
      currentUser={currentUser}
    />
  );
}

export default ShowVaultEntry;
