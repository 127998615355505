import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import Loading from 'lib/acromyrmex/Loading';
import { Button } from '../../utility/UiComponents';
import ExecuteStep from '../processes/ShowExecution/ExecuteStep';
import {
  updateExecution as updateExecutionAction,
  addExecution as addExecutionAction
} from '../../actions/executions';
import request from '../../utility/request';
import {
  isComplete,
  getProcessStepFromExecutionStep,
  getCurrentExecutionStep
} from '../../shared-helpers/execution';

class ExecuteStepShell extends Component {
  static propTypes = {
    execution: PropTypes.shape().isRequired,

    // redux
    currentUser: PropTypes.shape().isRequired,
    users: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    isUpdating: PropTypes.bool.isRequired,
    updateExecution: PropTypes.func.isRequired,
    addExecution: PropTypes.func.isRequired
  };

  state = {
    stepError: ''
  };

  onExecuteStep = this.onExecuteStep.bind(this);

  onAssignUser = this.onAssignUser.bind(this);

  onAssignUser(user) {
    const { execution, updateExecution } = this.props;

    execution.assignee = user._id;

    updateExecution(execution);
  }

  onExecuteStep(values, reset) {
    const { execution, addExecution, currentUser } = this.props;

    const currentExecutionStep = getCurrentExecutionStep(execution);

    request
      .post(`/api/processes/executions/completeStep/${currentExecutionStep._id}`)
      .send({ stepOutput: values })
      .set('x-access-token', currentUser.token)
      .then(({ body: updatedExecution }) => {
        reset();
        addExecution(updatedExecution);
      })
      .catch(() => {
        this.setState({ stepError: 'error completing step' });
      });
  }

  render() {
    const { execution, currentUser, users, isUpdating } = this.props;
    const { stepError } = this.state;
    const currentExecutionStep = getCurrentExecutionStep(execution);

    if (!currentExecutionStep) {
      return <Loading text="Loading Current Step" />;
    }

    const currentProcessStep = getProcessStepFromExecutionStep(execution, currentExecutionStep);

    if (!currentProcessStep) {
      return [
        <Loading text="Loading Current Step" />,
        <span className="pull-right" style={{ marginRight: '10px' }}>
          {!isComplete(execution) && (
            <Button
              size="xs"
              variant="link"
              onClick={() => {
                window.location.reload(false);
              }}
            >
              Seem stuck? Click here to refresh
            </Button>
          )}
        </span>
      ];
    }

    return (
      <ExecuteStep
        currentStep={currentProcessStep}
        execution={execution}
        currentUser={currentUser}
        onExecute={this.onExecuteStep} // TODO
        onAssignUser={this.onAssignUser} // TODO
        userList={users}
        isUpdating={isUpdating}
        stepError={stepError}
      />
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.users.currentUser,
  users: state.users.users.rows,
  isUpdating: state.processes.execution.isUpdating
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateExecution: updateExecutionAction,
      addExecution: addExecutionAction
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ExecuteStepShell);
