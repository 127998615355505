import _ from 'underscore';
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Field } from 'redux-form';
import { SelectInput } from 'lib/acromyrmex';
import PropTypes from 'prop-types';
import { Row, Col,Accordion} from '../../../../../utility/UiComponents';

// eslint-disable-next-line
class VaultCreateStepOptions extends React.Component {
  render() {
    const { fieldText, processStep, structureList, onChangeAttributeNeeded } = this.props;

    const {
      stepOptions: { slug }
    } = processStep;

    return (
      <Row key={fieldText} style={{ marginBottom: 10 }}>
          <Accordion defaultActiveKey="1">
          <Accordion.Item eventKey="1">
            <Accordion.Header>{`${processStep.name || ''} Step Options`}</Accordion.Header>
            <Accordion.Body>
            <Col xs={12}>
              <div className="select-addon-after">
                <Field
                  name={`${fieldText}.stepOptions.slug`}
                  component={SelectInput}
                  label="Structure"
                  options={_.map(structureList, struct => ({
                    id: struct.slug,
                    name: struct.display
                  }))}
                  onChange={(a, value) => {
                    onChangeAttributeNeeded({
                      ...processStep,
                      stepOptions: {
                        ...processStep.stepOptions,
                        slug: value
                      }
                    });
                  }}
                  addonAfter={
                    slug && (
                      <Link
                        to={{
                          pathname: `/vault/${slug}`
                        }}
                        target="_blank"
                      >
                        <i className="fa fa-external-link" />
                      </Link>
                    )
                  }
                />
              </div>
            </Col>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Row>
    );
  }
}

// Define property types
VaultCreateStepOptions.propTypes = {
  fieldText: PropTypes.string.isRequired,
  processStep: PropTypes.shape().isRequired,
  onChangeAttributeNeeded: PropTypes.func.isRequired,

  // specific to this step type
  structureList: PropTypes.arrayOf(PropTypes.shape()).isRequired
};

VaultCreateStepOptions.defaultProps = {};

const mapStateToProps = state => ({
  structureList: state.vault.structureList.rows
});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(VaultCreateStepOptions);
