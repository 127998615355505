import { TextInput, LoadingButton } from 'lib/acromyrmex';
import { LinkContainer } from 'react-router-bootstrap';
import { Button } from '../../utility/UiComponents';
import { React, PropTypes, connect, reduxForm, Field } from '../../utility/ReactStuff';

class ResetPasswordForm extends React.Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    isSubmitting: PropTypes.bool,

    // These props are given to us by redux-form
    handleSubmit: PropTypes.func.isRequired,
    valid: PropTypes.bool.isRequired
  };

  static defaultProps = {
    isSubmitting: false
  };

  render() {
    const { handleSubmit, onSubmit, isSubmitting, valid } = this.props;

    return (
      <form className="reset-form" onSubmit={handleSubmit(onSubmit)}>
        <Field name="email" component={TextInput} label="Email:" type="email" />
        <Field name="code" component={TextInput} label="Code:" />
        <Field name="newPassword" component={TextInput} label="New Password:" type="password" />
        <div style={{ textAlign: 'right' }}>
          <LinkContainer to={{ pathname: '/login' }}>
            <Button variant="default">Back to login</Button>
          </LinkContainer>
          <LoadingButton
            variant="primary"
            label="Submit"
            loading={isSubmitting}
            disabled={!valid}
            loadingLabel="Submitting.."
            type="submit"
            style={{ marginLeft: '5px' }}
          />
        </div>
      </form>
    );
  }
}

const mapStateToProps = () => ({});

const form = reduxForm({
  form: 'resetForm',
  validate: values => {
    const errors = {};
    if (!values.email) {
      errors.email = 'Required';
    }

    if (!values.code) {
      errors.code = 'Required';
    }

    if (!values.newPassword) {
      errors.newPassword = 'Required';
    }

    return errors;
  }
})(ResetPasswordForm);

export default connect(mapStateToProps)(form);
