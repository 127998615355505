import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Button } from '../../../../../utility/UiComponents';
import DataModalNew from '../../../../shared/DataModalNew';
import StringHelper from '../../../../../shared-helpers/StringHelper';

class ManualSelectStep extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false
    };
    this.handleConfirm = this.handleConfirm.bind(this);
  }

  getSourceStep() {
    const { executionSteps, step } = this.props;
    const sourceStepId = StringHelper.getStepIdFromTemplate(step.stepOptions.sourceData);

    const sourceStep = executionSteps.find(
      item => item.processStep.toString() === sourceStepId.toString()
    );

    return sourceStep;
  }

  handleConfirm(selected) {
    const { completeStep } = this.props;

    completeStep(selected);
  }

  render() {
    const { executionSteps, executeStep } = this.props;
    const { showModal } = this.state;
    if (!executionSteps) {
      return (
        <div>
          <p>Source Data Unavailable</p>
        </div>
      );
    }

    const data = this.getSourceStep().stepOutput;
    const selectedData = executeStep && executeStep.values ? executeStep.values : [];

    return (
      <div>
        <h4>Open the data grid to select entries:</h4>
        <p>
          <Button onClick={() => this.setState({ showModal: true })} variant="success">
            Open Data Grid
          </Button>
        </p>
        {data && (
          <DataModalNew
            show={showModal}
            title="Select Data Entries"
            data={data}
            onConfirm={this.handleConfirm}
            onCancel={() => {
              this.setState({ showModal: false });
            }}
            selectedData={selectedData}
          />
        )}
      </div>
    );
  }
}

ManualSelectStep.propTypes = {
  step: PropTypes.shape().isRequired,
  executionSteps: PropTypes.arrayOf(PropTypes.shape()),
  completeStep: PropTypes.func.isRequired,
  executeStep: PropTypes.shape()
};

ManualSelectStep.defaultProps = {
  executionSteps: null,
  executeStep: null
};

const mapStateToProps = state => ({
  executionSteps: state.processes.execution.value.steps,
  executeStep: state.form.executeStep
});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ManualSelectStep);
