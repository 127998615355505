import { TextInput, SelectInput } from 'lib/acromyrmex'

import TableForm from '../../shared/form/TableForm'
import BasicUiHelper from './BasicUiHelper'
import PickListEditor from '../../vaultStructures/VaultStructureForm/PickListEditor'
import UiAttributeHelper from '../../vaultStructures/attributes/UiAttributeHelper'
import IntegrationSelect from './components/stepOptions/integration/IntegrationSelect'

class ActionListenerUiHelper extends BasicUiHelper {
  name() {
    return `${this.integrationTitle()} Action Listener`
  }

  stepOptions() {
    return {
      title: this.name(),
      fields: () => [
        {
          component: IntegrationSelect,
          integrationName: this.integrationName(),
        },
        {
          name: 'actions',
          component: TableForm,
          headers: [
            {
              title: 'Key',
              field: { name: 'key', component: TextInput, noLabel: true },
              xs: 5,
            },
            {
              title: 'Type',
              field: {
                name: 'type',
                component: SelectInput,
                options: UiAttributeHelper.getAttributeTypes(),
                noLabel: true,
                addon: ({ rowPrefix }) => ({
                  enabled: ({ currentRow }) => currentRow.type === 'pickList',
                  component: PickListEditor,
                  sizing: { xs: 2 },
                  props: {
                    fieldName: rowPrefix,
                  },
                }),
              },
              xs: 5,
            },
          ],
        },
      ],
    }
  }

  // what shows in the result
  getOutputKeys() {
    return {
      schema: {
        '.': {
          type: 'Table',
        },
      },
    }
  }

  // this will be the message data
  getOutputOptions(step) {
    return [
      {
        id: JSON.stringify({ step: step._id, key: 'conversationId' }),
        name: 'conversationId',
        textValue: `{${step._id}.conversationId}`,
        type: 'string',
      },
      ...(step.stepOptions.actions || []).map((action) => ({
        id: JSON.stringify({
          step: step._id,
          field: action.key,
        }),
        name: `${action.key}`,
        textValue: `{${step._id}.${action.key}}`,
        type: action.type,
      })),
    ]
  }
}

export default ActionListenerUiHelper
