import { TemplateInput, TextInput } from 'lib/acromyrmex'
import BasicUiHelper from './BasicUiHelper'
import { JSON_PARSE_STEP_TYPE } from '../../../constants'
import TableForm from '../../shared/form/TableForm'

class JSONParseUiHelper extends BasicUiHelper {
  stepOptions() {
    return {
      title: 'JSON Parse Step Options',
      fields: ({ outputOptions }) => [
        {
          name: 'input',
          component: TemplateInput,
          label: 'String to parse',
          options: outputOptions,
          plainText: true,
        },
        {
          name: 'attributes',
          component: TableForm,
          headers: [
            {
              title: 'Key',
              field: { name: 'key', component: TextInput, noLabel: true },
              xs: 5,
            },
          ],
        },
      ],
    }
  }

  canTryAgain() {
    return false
  }

  regex() {
    return JSON_PARSE_STEP_TYPE
  }

  getOutputKeys() {
    return {}
  }

  getInputOptions() {
    return []
  }

  getOutputOptions(step) {
    return (step.stepOptions.attributes || []).map((attribute) => ({
      id: JSON.stringify({ step: step._id, field: attribute.key }),
      name: `${attribute.key}`,
      textValue: `{${step._id}.${attribute.key}}`,
      type: attribute.type,
    }))
  }

  getOptionSummary() {
    return []
  }
}

export default JSONParseUiHelper
