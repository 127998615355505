import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';
import {
  Loading,
  HoverHelp,
  TextInput,
  SelectInput,
  CheckboxInput,
  LoadingButton
} from 'lib/acromyrmex';
import { Button, Form, Row, Col, OverlayTrigger, Tooltip } from '../../utility/UiComponents';
import AttributeHelper from '../../shared-helpers/AttributeHelper';

// import VaultSelectInput from '../shared/form/VaultSelectInput';
import Vault from '../../utility/vault';
import {
  loadVaultStructure as loadVaultStructureAction,
  loadVaultStructureData as loadVaultStructureDataAction,
  loadStructureList as loadStructureListAction
} from '../../actions/vault';
import { requiredValidator, selectRequiredValidator } from '../../utility/formValidators';
import PickListEditor from './VaultStructureForm/PickListEditor';
import PanelTableForm from './VaultStructureForm/PanelTableForm';
import ComputationFormList from '../shared/Computation/ComputationFormList';
import AddButton from '../shared/form/AddButton';

class VaultStructureForm extends React.Component {
  static renderLoading() {
    return (
      <div className="container">
        <Loading />
        {' Loading structure...'}
      </div>
    );
  }

  static _renderAttributesHeader() {
    return (
      <Row>
        <Col xs={3} md={4} className="text-center">
          <strong>Name</strong>{' '}
          <HoverHelp
            help="What do you want to see the attribute refered to as throughout Contuit?"
            position="top"
          />
        </Col>
        <Col xs={2} md={4} className="text-center">
          <strong>Type</strong>{' '}
          <HoverHelp help="What sort of data do you want to store?" position="top" />
        </Col>
        <Col xs={2} md={1} className="text-center">
          <strong>Primary</strong>{' '}
          <HoverHelp
            help="Do you want this attribute to be shown in lists, overview information?"
            position="top"
          />
        </Col>
        <Col xs={2} md={1} className="text-center">
          <strong>Required</strong>{' '}
          <HoverHelp help="Is this attribute required for every entry?" position="top" />
        </Col>
        <Col xs={2} md={1} className="text-center">
          <strong>Editable</strong>{' '}
          <HoverHelp help="Can this attribute be edited once created?" position="top" />
        </Col>
      </Row>
    );
  }

  renderFields = this.renderFields.bind(this);

  static propTypes = {
    slug: PropTypes.string,
    edit: PropTypes.bool,
    handleSubmit: PropTypes.func.isRequired,
    onSubmit: PropTypes.func,
    isCreating: PropTypes.bool.isRequired,
    vault: PropTypes.shape().isRequired,
    structureList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    isLoading: PropTypes.bool,
    loadVaultStructure: PropTypes.func.isRequired,
    loadStructureList: PropTypes.func.isRequired,
    formValues: PropTypes.shape(),
    browser: PropTypes.shape().isRequired,
    change: PropTypes.func.isRequired
  };

  static defaultProps = {
    edit: false,
    slug: null,
    isLoading: false,
    onSubmit: () => {},
    formValues: {}
  };

  componentWillMount() {
    const { slug, vault, loadVaultStructure, loadStructureList } = this.props;

    if (slug && !Vault.hasStructureLoaded(vault.data, slug)) {
      loadVaultStructure(slug);
    }

    loadStructureList();
  }

  /**
   *
   * @param {boolean} options.select determines whether to leave textValue out
   */
  getAttributeOptions(options = { select: false }) {
    const { formValues } = this.props;

    return formValues && formValues.attributes
      ? formValues.attributes
          .filter(a => !!a.key)
          .map(a => ({
            id: a.key,
            name: a.display,
            textValue: options.select ? undefined : `#${a.key}#`
          }))
      : [];
  }

  renderFields({ fields }) {
    const { edit } = this.props;
    const rows = fields.map((member, index) => this.renderField(member, fields, index));

    return (
      <div>
        {rows}
        <Row>
          <Col xs={12}>
            <AddButton size="sm"
              label="Add Attribute"
              onClick={() =>
                fields.push({
                  type: 'string',
                  editable: true,
                  isPrimary: true,
                  required: false,
                  isNew: edit
                })
              }
            />
          </Col>
        </Row>
      </div>
    );
  }

  renderField(attr, fields, index) {
    const { edit, change, browser } = this.props;
    const attribute = fields.get(index);

    return (
      <Row key={attr} className="struct-form-row">
        <Col xs={12} sm={6} md={4}>
          <Row>
            <Field
              name={`${attr}.display`}
              component={TextInput}
              label="Name"
              autoFocus={!attribute.createdAt}
              validate={requiredValidator}
              disabled={attribute.system}
              onPaste={e => {
                const pastedText = e.clipboardData.getData('Text');
                const rows = pastedText.split('\n');

                if (rows.length > 1) {
                  // don't add the pasted text to the value
                  e.preventDefault();
                  change(`${attr}.display`, rows[0]);
                  // remove the first element
                  rows.shift();

                  // add our other fields
                  rows.forEach(r => {
                    fields.push({
                      display: r,
                      type: 'string',
                      editable: true,
                      isPrimary: true,
                      required: false,
                      isNew: edit
                    });
                  });
                }
              }}
              noLabel={browser.greaterThan.medium}
            />
          </Row>
        </Col>
        <Col xs={12} sm={6} md={4}>
          <Row>
            <Col xs={attribute.type === 'pickList' ? 10 : 12}>
              <Row>
                <Field
                  name={`${attr}.type`}
                  component={SelectInput}
                  label="Type"
                  validate={selectRequiredValidator}
                  disabled={attribute.system}
                  enableEmpty
                  confirmMessage="Warning, contuit will try to parse previously stored data as your new type. This may not always work properly and will show raw data."
                  confirm={edit && !attribute.isNew}
                  noLabel={browser.greaterThan.medium}
                  options={AttributeHelper.getAttributeTypes()}
                />
              </Row>
            </Col>
            {/* TODO: this shouldn't check for a type here.. */}
            {attribute.type === 'pickList' && (
              <Col xs={2}>
                <PickListEditor fieldName={attr} />
              </Col>
            )}
          </Row>
        </Col>
        <Col xs={6} sm={3} md={1}>
          <Row>
            <Field
              name={`${attr}.isPrimary`}
              component={CheckboxInput}
              noLabel={browser.greaterThan.medium}
              label="Primary"
            />
          </Row>
        </Col>
        <Col xs={6} sm={3} md={1}>
          <Row>
            <Field
              name={`${attr}.required`}
              component={CheckboxInput}
              disabled={attribute.system}
              noLabel={browser.greaterThan.medium}
              label="Required"
            />
          </Row>
        </Col>
        <Col xs={6} sm={3} md={1}>
          <Row>
            <Field
              name={`${attr}.editable`}
              component={CheckboxInput}
              disabled={attribute.system}
              noLabel={browser.greaterThan.medium}
              label="Editable"
            />
          </Row>
        </Col>
        <Col xs={6} sm={2} md={1}>
          {attribute.system && (
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id="tooltip">
                  This field is used by the system, and is therefore locked
                </Tooltip>
              }
            >
              <i className="fa fa-lock fa-2x pull-right" />
            </OverlayTrigger>
          )}
          {!attribute.system && (
            <Button className="pull-right" variant="danger" onClick={() => fields.remove(index)}>
              <i className="fa fa-trash" />
            </Button>
          )}
        </Col>
      </Row>
    );
  }

  render() {
    const { formValues, isLoading, handleSubmit, onSubmit, isCreating, edit, browser } = this.props;

    if (isLoading || !formValues) {
      return VaultStructureForm.renderLoading();
    }

    const attributeOptions = this.getAttributeOptions({ select: true });
    const sortOptions = [];
    attributeOptions.forEach(option => {
      sortOptions.push(option);
      sortOptions.push({
        id: `-${option.id}`,
        name: `${option.name} Descending`
      });
    });
    const labelOptions = [];
    attributeOptions.forEach(option => {
      labelOptions.push({
        id: `{${option.id}}`,
        name: `${option.name}`
      });
    });

    return (
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Field
          name="display"
          component={TextInput}
          label="Name (plural)"
          validate={requiredValidator}
          help="Used to identify the structure around Contuit"
          type="text"
          disabled={formValues.system}
        />
        <Field
          name="singular"
          component={TextInput}
          label="Name (singular)"
          validate={requiredValidator}
          help="Used to identify the structure around Contuit"
          type="text"
          disabled={formValues.system}
        />
        <Field
          name="label"
          component={SelectInput}
          label="Entry Label"
          validate={selectRequiredValidator}
          help="Which attribute can be used to identify an entry?"
          options={labelOptions}
        />
        <Field
          name="sortAttribute"
          component={SelectInput}
          label="Sort Order"
          validate={selectRequiredValidator}
          help="Which attribute do you want to sort by?"
          options={sortOptions}
        />
        <PanelTableForm
          title="Attributes"
          name="attributes"
          help="Attributes are the bread and butter of a Vault Structure. This is how you define what sort of information will be stored for each entry."
          headerComponent={VaultStructureForm._renderAttributesHeader()}
          fieldArrayComponent={this.renderFields}
          showHeader={browser.greaterThan.medium}
        />
        <LoadingButton
          variant="primary"
          label={edit ? 'Save Changes' : 'Create Vault Structure'}
          loading={isCreating}
          loadingLabel={`${edit ? 'Editing' : 'Creating'} ${formValues.display}`}
          type="submit"
        />
      </Form>
    );
  }
}

// for the form
const mapStateToProps = (state, props) => ({
  vault: state.vault,
  browser: state.browser,
  structureList: state.vault.structureList.rows,
  isLoading:
    props.slug &&
    state.vault.structures[props.slug] &&
    state.vault.structures[props.slug].isLoading,
  user: state.users.user,
  isCreating: state.users.isCreating,
  formValues: state.form.vaultStructureForm && state.form.vaultStructureForm.values
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      loadVaultStructure: loadVaultStructureAction,
      loadVaultStructureData: loadVaultStructureDataAction,
      loadStructureList: loadStructureListAction
    },
    dispatch
  );

const form = reduxForm({ form: 'vaultStructureForm' })(VaultStructureForm);
export default connect(mapStateToProps, mapDispatchToProps)(form);
