import _ from 'underscore'
import React from 'react'
import { bindActionCreators } from 'redux'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { Field } from 'redux-form'
import PropTypes from 'prop-types'
import { SelectInput } from 'lib/acromyrmex'
import Alert from '../../../../../shared/Alert'
import {
  Row,
  Col,Accordion,
} from '../../../../../../utility/UiComponents'
import StructureAttributeSelectInput from '../../../../../shared/form/StructureAttributeSelectInput'
import { findCollections } from '../../../../../../utility/ProcessFormOptionHelpers'

class VaultReplaceStepOptions extends React.Component {
  getStructOptions() {
    const { structureList } = this.props
    return _.map(structureList, (struct) => ({
      id: struct.slug,
      name: struct.display,
    }))
  }

  render() {
    const {
      fieldText,
      processStep,
      executionStep,
      responseAttributes: { fields, attributes },
      outputOptions,
    } = this.props
    const structOptions = this.getStructOptions()

    if (!executionStep) {
      return null
    }

    const {
      stepOptions: { vaultStructure },
    } = processStep

    return (
      <Row key={fieldText} style={{ marginBottom: 10 }}>
        <Accordion defaultActiveKey="1">
          <Accordion.Item eventKey="1">
            <Accordion.Header>Vault Replace Step Options</Accordion.Header>
            <Accordion.Body>
              <Col xs={12} style={{ marginBottom: 10 }}>
                <Alert
                  message="Warning: this will clear all current data in the selected vault structure. This can lead to unwanted results."
                  warning
                />
                <Field
                  name={`${fieldText}.stepOptions.sourceData`}
                  component={SelectInput}
                  templateOptions={findCollections(outputOptions)}
                />
              </Col>
              <Col xs={12} style={{ marginBottom: 10 }}>
                <Field
                  name={`${fieldText}.stepOptions.vaultStructure`}
                  label="Structure"
                  component={SelectInput}
                  options={structOptions}
                  help="Which vault structure should be replaced?"
                  addonAfter={
                    vaultStructure && (
                      <Link
                        to={{
                          pathname: `/vault/${vaultStructure}`,
                        }}
                        target="_blank"
                      >
                        <i className="fa fa-external-link" />
                      </Link>
                    )
                  }
                />
              </Col>
              {executionStep.stepOptions.vaultStructure !== 'customers' && (
                <Col xs={12} sm={8} style={{ marginBottom: 10 }}>
                  <Field
                    name={`${fieldText}.stepOptions.customerMatcher`}
                    label="Customer Matcher"
                    component={StructureAttributeSelectInput}
                    slug="customers"
                    help="What value can we use to match with a customer?"
                  />
                </Col>
              )}
              {executionStep.stepOptions.vaultStructure !== 'customers' && (
                <Col xs={12} sm={4} style={{ marginBottom: 10 }}>
                  <Field
                    name={`${fieldText}.stepOptions.customerMatcherTo`}
                    label="Customer Matcher"
                    component={SelectInput}
                    options={_.map(attributes, (value, key) => ({
                      id: key,
                      name: key,
                    }))}
                    slug={executionStep.stepOptions.vaultStructure}
                    noLabel
                  />
                </Col>
              )}
              <Col xs={12} style={{ marginBottom: 10 }}>
                <Accordion defaultActiveKey="1">
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      {executionStep.stepOptions.vaultStructure
                        ? `${executionStep.stepOptions.vaultStructure} Attributes`
                        : ''}
                    </Accordion.Header>
                    <Accordion.Body>{fields}</Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Col>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Row>
    )
  }
}

// Define property types
VaultReplaceStepOptions.propTypes = {
  fieldText: PropTypes.string.isRequired,
  executionStep: PropTypes.shape().isRequired,
  responseAttributes: PropTypes.shape().isRequired,
  structureList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  outputOptions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  processStep: PropTypes.shape().isRequired,
}

VaultReplaceStepOptions.defaultProps = {}

const mapStateToProps = (state) => ({
  structureList: state.vault.structureList.rows,
  formValues: state.form.processForm ? state.form.processForm.values : {},
})

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VaultReplaceStepOptions)
