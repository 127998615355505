import React from 'react';
import PropTypes from 'prop-types';
import HoverHelp from 'lib/acromyrmex/HoverHelp';

const SidebarAttribute = ({ value, display, formatter, style, help }) => {
  let val = value;

  if (formatter) {
    val = formatter(val);
  }

  if (!val) {
    return null;
  }

  return (
    <div style={style}>
      <span className="text-muted small">
        {display} {help && <HoverHelp help={help} />}
      </span>
      <h5 style={{ marginTop: '0', textAlign: 'right' }}>{val}</h5>
    </div>
  );
};

SidebarAttribute.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  display: PropTypes.string.isRequired,
  formatter: PropTypes.func,
  style: PropTypes.shape(),
  help: PropTypes.string
};

SidebarAttribute.defaultProps = {
  value: '',
  style: {},
  formatter: val => val,
  help: ''
};

export default SidebarAttribute;
