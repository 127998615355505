import _ from 'underscore'
import React from 'react'
import { bindActionCreators } from 'redux'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { Field, FieldArray } from 'redux-form'
import {
  SelectInput,
  TemplateInput,
  Loading,
  CheckboxInput,
  TextInput,
} from 'lib/acromyrmex'
import PropTypes from 'prop-types'
import {
  Row,
  Col,
  Accordion,
  Button,
} from '../../../../../utility/UiComponents'
import VaultSelectInput from '../../../../shared/form/VaultSelectInput'
import StructureAttributeSelectInput from '../../../../shared/form/StructureAttributeSelectInput'
import { requiredValidator } from '../../../../../utility/formValidators'
import AddButton from '../../../../shared/form/AddButton'
import StringHelper from '../../../../../shared-helpers/StringHelper'
import {
  findCollections,
  filterCollections,
} from '../../../../../utility/ProcessFormOptionHelpers'

class VaultMergeStepOptions extends React.Component {
  constructor(props) {
    super(props)

    this.renderRelationships = this.renderRelationships.bind(this)
    this.renderAttributes = this.renderAttributes.bind(this)
  }

  componentDidUpdate(prevProps) {
    const {
      processStep: {
        stepOptions: { nestedMerge, vaultStructure },
      },
      fieldText,
      array,
    } = this.props

    if (prevProps.processStep.stepOptions.nestedMerge !== nestedMerge) {
      if (vaultStructure === '$c_create') {
        this.calculateCreateAttributes()
      } else {
        array.removeAll(`${fieldText}.stepOptions.attributes`)
      }
    }
  }

  getStructOptions() {
    const { structureList } = this.props
    const options = _.map(structureList, (struct) => ({
      id: struct.slug,
      name: struct.display,
    }))

    // add an option to create a matching structure
    options.unshift({ id: '$c_create', name: '➕ Create matching structure' })

    return options
  }

  getMergeAttrs() {
    const {
      processStep,
      responseAttributes: { attributes },
    } = this.props
    const { nestedMerge } = processStep.stepOptions
    const baseMergeAttrs =
      nestedMerge === 'root'
        ? attributes
        : (attributes[nestedMerge] &&
            attributes[nestedMerge].items &&
            attributes[nestedMerge].items.properties) ||
          (attributes[nestedMerge] && attributes[nestedMerge].properties) ||
          []


    const mergeAttrs = { ...baseMergeAttrs }
    const recursiveFunction = (atts, prefix = null) => {
      _.each(atts, (value, attrKey) => {
        const key = prefix === null ? attrKey : `${prefix}.${attrKey}`
        if (['array', 'object'].includes(value.type)) {
          if (value.type === 'object') {
            recursiveFunction(value.properties, key)
          }

          // if we are still at the root we want to remove these
          if (prefix === null) {
            delete mergeAttrs[attrKey]
          }

          return
        }

        // don't need to add when its already there
        if (prefix === null) {
          return
        }

        // sometimes this will delete and re-add but thats ok?
        mergeAttrs[key] = value
      })
    }

    recursiveFunction(mergeAttrs)

    // now add parent attributes if we are nested
    if (nestedMerge !== 'root') {
      recursiveFunction(attributes, '$c_root')
    }

    return mergeAttrs
  }

  // Gets options based on the source data
  getPropertyOptions() {
    const { processStep, outputOptions } = this.props
    const source = outputOptions.find(
      (o) => o.textValue === processStep.stepOptions.sourceData,
    )
    const propertyOptions =
      source &&
      source.properties &&
      source.properties.filter((p) => p.type !== 'array' && p.type !== 'object')

    // when there is a nested merge, we want to include the properties from that level
    if (propertyOptions && processStep.stepOptions.nestedMerge !== 'root') {
      const nestedProp = source.properties.find(
        (o) => o.name === processStep.stepOptions.nestedMerge,
      )

      if (nestedProp && nestedProp.type === 'collection') {
        propertyOptions.push(
          ...nestedProp.properties.filter(
            (p) => p.type !== 'array' && p.type !== 'object',
          ),
        )
      }
    }

    return propertyOptions
  }

  getLocalProperties() {
    const {
      outputOptions,
      processStep,
      processStep: {
        stepOptions: { nestedMerge },
      },
    } = this.props
    const source = outputOptions.find(
      (o) => o.textValue === processStep.stepOptions.sourceData,
    )

    if (source && nestedMerge !== 'root') {
      const nestedProp = source.properties.find((o) => o.name === nestedMerge)

      if (nestedProp) {
        return nestedProp.properties.filter(
          (p) => p.type !== 'array' && p.type !== 'object',
        )
      }
    } else {
      return this.getPropertyOptions()
    }
  }

  // eslint-disable-next-line react/destructuring-assignment
  getNewStructPropertyOptions(options = {}) {
    const { processStep } = this.props
    const { nestedMerge = processStep.stepOptions.nestedMerge } = options
    const propertyOptions = this.getLocalProperties()

    return (propertyOptions || [])
      .filter(
        (o) =>
          o.type !== 'collection' &&
          (o.name.indexOf('->') === -1 ||
            o.textValue.indexOf(nestedMerge) > -1),
      )
      .map((o) => ({
        name: o.name.substring(
          o.name.lastIndexOf('-> ') > -1 ? o.name.lastIndexOf('-> ') + 3 : 0,
        ),
        textValue: o.textValue,
      }))
  }

  onVaultStructureChanged = (e, value) => {
    // if switching to the create option, we want to fill all the values in by default
    if (value === '$c_create') {
      this.calculateCreateAttributes()
    }
  }

  doesStructExist() {
    const {
      processStep: {
        stepOptions: { vaultStructure },
      },
      structureList,
    } = this.props
    const thisStruct = structureList.find((s) => s.slug === vaultStructure)

    // boolean version of if it exists
    return !!thisStruct
  }

  // eslint-disable-next-line react/destructuring-assignment
  calculateCreateAttributes(options = {}) {
    const { processStep } = this.props
    const { nestedMerge = processStep.stepOptions.nestedMerge } = options
    const { fieldText, array } = this.props
    const newPropertyOptions = this.getNewStructPropertyOptions(nestedMerge)

    array.removeAll(`${fieldText}.stepOptions.attributes`)
    setTimeout(() => {
      newPropertyOptions.forEach((opt) => {
        array.push(`${fieldText}.stepOptions.attributes`, {
          from: opt.textValue,
          toVault: StringHelper.slugify(opt.name),
        })
      })
    }, 1)
  }

  renderRelationship(relationship, fields, index, mergeAttrs) {
    const { processStep, structureList } = this.props
    const field = fields.get(index)
    const struct = structureList.find(
      (s) => s.slug === processStep.stepOptions.vaultStructure,
    )

    if (!struct) {
      return (
        <Row key={relationship}>
          <Loading />
        </Row>
      )
    }

    const rels = struct.relationships.map((r) => ({
      id: r.structure,
      name: r.display,
    }))

    return (
      <Row key={relationship}>
        <Col xs={3}>
          <Field
            name={`${relationship}.relationship`}
            component={SelectInput}
            options={rels}
            noLabel
            label="Label"
            validate={requiredValidator}
          />
        </Col>
        <Col xs={4} style={{ marginBottom: 10 }}>
          {field && field.relationship ? (
            <Field
              name={`${relationship}.matcher`}
              label="Customer Matcher"
              component={StructureAttributeSelectInput}
              noLabel
              slug={field.relationship}
              help="What value can we use to match with a customer?"
            />
          ) : (
            <Loading />
          )}
        </Col>
        <Col xs={4} style={{ marginBottom: 10 }}>
          <Field
            name={`${relationship}.matcherTo`}
            label="Customer Matcher"
            component={SelectInput}
            options={_.map(mergeAttrs, (value, key) => ({
              id: key,
              name: key.replace('$c_root', '(root)'),
            }))}
            slug={processStep.stepOptions.vaultStructure}
            noLabel
          />
        </Col>
        <Col xs={1}>
          <Button
            variant="danger"
            onClick={() => fields.remove(index)}
            title="Remove this conditional"
          >
            <i className="fa fa-trash" />
          </Button>
        </Col>
      </Row>
    )
  }

  renderRelationships({ fields }) {
    const mergeAttrs = this.getMergeAttrs()
    const rows = fields.map((member, index) =>
      this.renderRelationship(member, fields, index, mergeAttrs),
    )
    return (
      <Accordion defaultActiveKey="1">
        <Accordion.Item eventKey="1">
          <Accordion.Header>Relationship Merges</Accordion.Header>
          <Accordion.Body>
            <Row style={{ marginBottom: 10 }}>
              <Col xs={3}>
                <strong>Relationship</strong>
              </Col>
              <Col xs={4}>
                <strong>Matcher</strong>
              </Col>
              <Col xs={4}>
                <strong>Match to</strong>
              </Col>
            </Row>
            {rows}
            <Row>
              <Col xs={12}>
                <AddButton
                  size="sm"
                  onClick={() => {
                    fields.push({})
                  }}
                  label="Add Relationship Merge"
                />
              </Col>
            </Row>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    )
  }

  renderAttribute(
    attribute,
    fields,
    index,
    newPropertyOptions,
    propertyOptions,
  ) {
    const { processStep, outputOptions } = this.props
    const createVaultStructureMode =
      processStep &&
      processStep.stepOptions &&
      processStep.stepOptions.vaultStructure &&
      processStep.stepOptions.vaultStructure === '$c_create'
    const field = fields.get(index)
    const createVaultAttributeMode = field.toVault === '$c_create'
    const createOptions = [{ id: '$c_create', name: '➕ Create attribute' }]

    return (
      <Row key={attribute}>
        <Col xs={4}>
          <Field
            name={`${attribute}.from`}
            component={TemplateInput}
            options={(propertyOptions || []).concat(
              filterCollections(outputOptions),
            )}
            noLabel
            validate={requiredValidator}
          />
        </Col>
        <Col xs={4} style={{ marginBottom: 10 }}>
          {createVaultStructureMode && (
            <Field
              name={`${attribute}.toVault`}
              component={SelectInput}
              help="Which vault attribute would you like to map to?"
              options={[
                ...createOptions,
                ...newPropertyOptions.map((p) => ({
                  id: StringHelper.slugify(p.name),
                  name: p.name,
                })),
              ]}
              noLabel
            />
          )}
          {!createVaultStructureMode && (
            <Field
              name={`${attribute}.toVault`}
              addedOptions={[...createOptions]}
              component={StructureAttributeSelectInput}
              noLabel
              slug={processStep.stepOptions.vaultStructure}
              help="Which vault attribute would you like to map to?"
            />
          )}
        </Col>
        <Col xs={3} style={{ marginBottom: 10 }}>
          {createVaultAttributeMode && (
            <Field
              name={`${attribute}.createAttributeName`}
              component={TextInput}
              label="Attribute Name"
              noLabel
            />
          )}
        </Col>
        <Col xs={1}>
          <Button
            variant="danger"
            onClick={() => fields.remove(index)}
            title="Remove this conditional"
          >
            <i className="fa fa-trash" />
          </Button>
        </Col>
      </Row>
    )
  }

  renderAttributes({ fields }) {
    const newPropertyOptions = this.getNewStructPropertyOptions()
    const propertyOptions = this.getPropertyOptions()
    const rows = fields.map((member, index) =>
      this.renderAttribute(
        member,
        fields,
        index,
        newPropertyOptions,
        propertyOptions,
      ),
    )
    return (
      <Accordion defaultActiveKey="1">
        <Accordion.Item eventKey="1">
          <Accordion.Header>Attribute Merges</Accordion.Header>
          <Accordion.Body>
            <Row style={{ marginBottom: 10 }}>
              <Col xs={4}>
                <strong>Source Attribute</strong>
              </Col>
              <Col xs={4}>
                <strong>Vault Attribute</strong>
              </Col>
              <Col xs={3}>
                <strong>New Attribute Name</strong>
              </Col>
            </Row>
            {rows}
            <Row>
              <Col xs={12}>
                <AddButton
                  size="sm"
                  onClick={() => {
                    fields.push({})
                  }}
                  label="Add Attribute Mapping"
                />
              </Col>
            </Row>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    )
  }

  renderCustomerMatchFields() {
    const { fieldText, processStep, browser } = this.props
    const mergeAttrs = this.getMergeAttrs()

    const fields = []
    if (processStep && processStep.stepOptions.vaultStructure !== 'customers') {
      fields.push(
        <Col
          xs={12}
          sm={6}
          style={{ marginBottom: 10 }}
          key="customerMergeType"
        >
          <Field
            name={`${fieldText}.stepOptions.customerMergeType`}
            component={SelectInput}
            label="Customer Merging"
            help={
              <div>
                <p>
                  <strong>None:</strong> leave customer unassigned
                  <br />
                </p>
                <p>
                  <strong>Assign to customer:</strong> Select a specific
                  customer to assign all entities to
                  <br />
                </p>
                <p>
                  <strong>Merge on attribute:</strong> Find a customer based on
                  a certain matching attribute
                </p>
              </div>
            }
            options={[
              { id: 'none', name: 'None' },
              { id: 'static', name: 'Assign all to customer' },
              { id: 'attribute', name: 'Merge on attribute' },
            ]}
          />
        </Col>,
      )
    }

    if (
      processStep &&
      processStep.stepOptions.customerMergeType === 'attribute' &&
      processStep.stepOptions.vaultStructure !== 'customers'
    ) {
      fields.push(
        <Col xs={12} sm={6} style={{ marginBottom: 10 }} key="customerMatcher">
          <Field
            name={`${fieldText}.stepOptions.customerMatcher`}
            label="Customer Matcher"
            component={StructureAttributeSelectInput}
            slug="customers"
            help="What value can we use to match with a customer?"
          />
        </Col>,
        <Col
          xs={12}
          sm={6}
          style={{
            marginBottom: 10,
            marginTop: browser.lessThan.large ? 25 : 0,
          }}
          key="customerMatcherTo"
        >
          <Field
            name={`${fieldText}.stepOptions.customerMatcherTo`}
            label="Customer Matcher To"
            component={SelectInput}
            options={_.map(mergeAttrs, (value, key) => ({
              id: key,
              name: key.replace('$c_root', '(root)'),
            }))}
            slug={processStep.stepOptions.vaultStructure}
      
          />
        </Col>,
      )
    }

    if (
      processStep &&
      processStep.stepOptions.customerMergeType === 'static' &&
      processStep.stepOptions.vaultStructure !== 'customers'
    ) {
      fields.push(
        <Col xs={12} sm={6} style={{ marginBottom: 10 }} key="staticCustomerId">
          <VaultSelectInput
            name={`${fieldText}.stepOptions.staticCustomerId`}
            slug="customers"
            labelOverride="Choose customer:"
          />
        </Col>,
      )
    }

    return fields
  }

  // eslint-disable-next-line class-methods-use-this
  renderVaultStructureLink(vaultStructure) {
    return (
      <Link
        to={{
          pathname: `/vault/${vaultStructure}`,
        }}
        target="_blank"
      >
        <i className="fa fa-external-link" />
      </Link>
    )
  }

  render() {
    const {
      fieldText,
      processStep,
      responseAttributes: { attributes },
      structureList,
      outputOptions,
    } = this.props
    const structOptions = this.getStructOptions()

    const nestedMergeOptions = [{ id: 'root', name: 'root' }]
    _.each(attributes, (value, key) => {
      if (value.type !== 'array' && value.type !== 'object') {
        return
      }

      nestedMergeOptions.push({ id: key, name: key })
    })

    const {
      stepOptions: { vaultStructure },
    } = processStep

    const newPropertyOptions = this.getNewStructPropertyOptions()
    const createVaultStructureMode =
      processStep && processStep.stepOptions.vaultStructure === '$c_create'

    return (
      <Row key={fieldText} style={{ marginBottom: 10 }}>
        <Accordion defaultActiveKey="1">
          <Accordion.Item eventKey="1">
            <Accordion.Header>Vault Merge Step Options</Accordion.Header>
            <Accordion.Body>
              <div
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  justifyContent: 'space-between',
                  gap: 30
                }}
              >
                <div style={{ flex: '1 1 45%', marginBottom: 10 }}>
                  <Col xs={12}>
                    <Field
                      name={`${fieldText}.stepOptions.sourceData`}
                      component={SelectInput}
                      label="Source Data"
                      templateOptions={findCollections(outputOptions)}
                    />
                  </Col>
                  <Col xs={12}>
                    <div className="select-addon-after">
                      <Field
                        name={`${fieldText}.stepOptions.vaultStructure`}
                        label="Structure"
                        component={SelectInput}
                        options={structOptions}
                        help="Which vault structure should be replaced?"
                        onChange={this.onVaultStructureChanged}
                        addonAfter={
                          vaultStructure &&
                          this.renderVaultStructureLink(vaultStructure)
                        }
                      />
                    </div>
                  </Col>
                  {createVaultStructureMode && (
                    <Col xs={12} style={{ marginBottom: 10 }}>
                      <Field
                        name={`${fieldText}.stepOptions.createStructureName`}
                        component={TextInput}
                        label="Structure Name"
                      />
                    </Col>
                  )}
                  <Col xs={6} style={{ marginBottom: 10 }}>
                    <Field
                      name={`${fieldText}.stepOptions.skipDelete`}
                      component={CheckboxInput}
                      label="Skip Deleting"
                    />
                  </Col>
                </div>

                <div style={{ flex: '1 1 45%', marginBottom: 10 }}>
                  <Col xs={12}>
                    {nestedMergeOptions.length > 0 ? (
                      <Field
                        name={`${fieldText}.stepOptions.nestedMerge`}
                        label="Nested Merge"
                        component={SelectInput}
                        options={nestedMergeOptions}
                        help="Do you want to merge a nested array, or the root array?"
                      />
                    ) : (
                      <Loading />
                    )}
                  </Col>
                  <Col xs={12}>
                    {processStep && !processStep.stepOptions.vaultStructure && (
                      <Loading />
                    )}
                    {createVaultStructureMode && (
                      <Field
                        name={`${fieldText}.stepOptions.mergeField`}
                        component={SelectInput}
                        label="Merge Field"
                        help="Which field to merge on? Contuit will compare using this when deciding what to add, remove or update - it should be something that remains the same over time."
                        options={newPropertyOptions.map((p) => ({
                          id: StringHelper.slugify(p.name),
                          name: p.name,
                        }))}
                      />
                    )}
                    {processStep &&
                      processStep.stepOptions.vaultStructure &&
                      processStep.stepOptions.vaultStructure !==
                        '$c_create' && (
                        <Field
                          name={`${fieldText}.stepOptions.mergeField`}
                          label="Merge Field"
                          component={StructureAttributeSelectInput}
                          slug={processStep.stepOptions.vaultStructure}
                          help="Which field to merge on? Contuit will compare using this when deciding what to add, remove or update - it should be something that remains the same over time."
                        />
                      )}
                  </Col>
                </div>
              </div>

              <div className="clearfix">{this.renderCustomerMatchFields()}</div>

              <Col xs={12}>
                <FieldArray
                  name={`${fieldText}.stepOptions.attributes`}
                  component={this.renderAttributes}
                  props={{ structureList }}
                />
              </Col>
              <Col xs={12}>
                <FieldArray
                  name={`${fieldText}.stepOptions.relationships`}
                  component={this.renderRelationships}
                />
              </Col>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Row>
    )
  }
}

// Define property types
VaultMergeStepOptions.propTypes = {
  fieldText: PropTypes.string.isRequired,
  processStep: PropTypes.shape().isRequired,
  browser: PropTypes.shape().isRequired,
  responseAttributes: PropTypes.shape().isRequired,
  structureList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  // change: PropTypes.func.isRequired,
  array: PropTypes.shape().isRequired,
  outputOptions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
}

VaultMergeStepOptions.defaultProps = {}

const mapStateToProps = (state) => ({
  browser: state.browser,
  structureList: state.vault.structureList.rows,
  formValues: state.form.processForm ? state.form.processForm.values : {},
})

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VaultMergeStepOptions)
