// SearchableSelect.js
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormControl } from 'react-bootstrap';

const SearchableSelect = ({ input, meta, options, placeholder }) => {
  const [showMenu, setShowMenu] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [inputValue, setInputValue] = useState(
    input.value ? input.value.name || input.value : '',
  );
  const wrapperRef = useRef(null);
  useEffect(() => {
    // Close dropdown when clicking outside
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShowMenu(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  const handleInputChange = (e) => {
    const { value } = e.target;
    setInputValue(value);
    // Update the Redux Form value
    input.onChange(value);
    // Filter options based on input
    const newFilteredOptions = options.filter((option) =>
      option.name.toLowerCase().includes(value.toLowerCase()),
    );
    setFilteredOptions(newFilteredOptions);
    // Show the menu when input changes
    setShowMenu(true);
  };
  const handleOptionSelect = (option) => {
    setInputValue(option.name);
    input.onChange(option); // Store the entire option object
    setShowMenu(false);
  };
  const handleCustomOptionSelect = () => {
    const customOption = { id: null, name: inputValue };
    input.onChange(customOption);
    setShowMenu(false);
  };
  const handleInputFocus = () => {
    // Show menu when input is focused
    setShowMenu(true);
    // Reset filtered options to show all options
    setFilteredOptions(options);
  };
  return (
    <div ref={wrapperRef} style={{ position: 'relative' }}>
      <FormControl
        type="text"
        placeholder={placeholder}
        value={inputValue}
        onChange={handleInputChange}
        onFocus={handleInputFocus}
        onBlur={() => input.onBlur(inputValue)}
        autoComplete="off"
      />
      {showMenu && (
        <div
          style={{
            position: 'absolute',
            top: '100%',
            left: 0,
            right: 0,
            zIndex: 1000,
            backgroundColor: '#fff',
            border: '1px solid #ccc',
            maxHeight: '200px',
            overflowY: 'auto',
          }}
        >
          {filteredOptions.length > 0 ? (
            filteredOptions.map((option) => (
              <div
                key={option.id}
                role="button"
                tabIndex={0}
                onMouseDown={() => handleOptionSelect(option)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    handleOptionSelect(option);
                  }
                }}
                style={{
                  padding: '8px',
                  cursor: 'pointer',
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.backgroundColor = '#f5f5f5';
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.backgroundColor = '#fff';
                }}
              >
                {option.name}
              </div>
            ))
          ) : (
            <div
              role="button"
              tabIndex={0}
              onMouseDown={handleCustomOptionSelect}
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  handleCustomOptionSelect();
                }
              }}
              style={{
                padding: '8px',
                cursor: 'pointer',
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.backgroundColor = '#f5f5f5';
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.backgroundColor = '#fff';
              }}
            >
              Add &quot;{inputValue}&quot;
            </div>
          )}
        </div>
      )}
      {meta.touched && meta.error && (
        <div className="text-danger">{meta.error}</div>
      )}
    </div>
  );
};

SearchableSelect.defaultProps = {
  placeholder: 'Search...',
};

SearchableSelect.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
      }),
    ]),
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }).isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  placeholder: PropTypes.string,
};

export default SearchableSelect;
