import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Loading from 'lib/acromyrmex/Loading';
import { ProcessServer } from '@contuit-otf/sdk';
import PageLayout from '../shared/Layout/PageLayout';
import Alert from '../shared/Alert';
import ShowSolutionTile from './ShowSolutionTile';
import request from '../../utility/request';
import { useParams } from 'react-router';

const processServer = new ProcessServer('/api/processes', request);

const ShowSolutionGroup = ({ currentUser }) => {
  const [solutionGroup, setSolutionGroup] = useState(null);
  const [solutionGroupLoading, setSolutionGroupLoading] = useState(false);
  const [solutionGroupError, setSolutionGroupError] = useState('');
  const { solutionGroupId } = useParams();

  useEffect(() => {
    const { token } = currentUser;

    setSolutionGroupLoading(true);
    setSolutionGroupError('');

    processServer
      .getSolutionGroup({ token, solutionGroupId })
      .then((solutionGroupData) => {
        setSolutionGroup(solutionGroupData);
        onSolutionGroupAdded();
      })
      .catch((e) => setSolutionGroupError(`Unknown error: ${e.message}`))
      .finally(() => setSolutionGroupLoading(false));
  }, [currentUser, solutionGroupId]);

  const onSolutionGroupAdded = () => {
    // Custom logic can be added here if needed
  };

  return (
    <PageLayout
      header={solutionGroup && solutionGroup.name}
      description={solutionGroup && solutionGroup.description}
      content={
        <div>
          {solutionGroupError && <Alert danger message={solutionGroupError} />}
          {solutionGroupLoading && <Loading text="Loading solution groups" />}
          {solutionGroup &&
            solutionGroup.solutions &&
            solutionGroup.solutions.map((solution) => (
              <ShowSolutionTile key={solution.id} solution={solution} />
            ))}
        </div>
      }
    />
  );
};

ShowSolutionGroup.propTypes = {
  currentUser: PropTypes.shape().isRequired,
};

const mapStateToProps = (state) => ({
  currentUser: state.users.currentUser,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ShowSolutionGroup);
