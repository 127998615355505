import { BLOCK_END_V2_STEP_TYPE } from '../../../constants'
import BasicUiHelper from './BasicUiHelper'

class BlockEndV2UiHelper extends BasicUiHelper {
  regex() {
    return BLOCK_END_V2_STEP_TYPE
  }

  name() {
    return 'End Block V2'
  }

  canTryAgain() {
    return false
  }

  disableConditions() {
    return true
  }
}

export default BlockEndV2UiHelper
