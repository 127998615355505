import React from 'react';
import PropTypes from 'prop-types';
import Collapsible from 'react-collapsible';
import ExecutionMarkdown from './ExecutionMarkdown';
import useTemplateString from '../../hooks/useTemplateString';

const ShowTemplateValue = ({
  input,
  currentUser,
  execution,
  skipCollapse,
  triggerClassName,
  triggerOpenedClassName
}) => {
  const [outputString] = useTemplateString(input, currentUser, execution, 'Loading..');
  const core = (
    <div className="collapsible-description">
      <ExecutionMarkdown source={outputString} />
    </div>
  );

  if (skipCollapse) {
    return core;
  }

  return (
    <Collapsible
      trigger={<strong>Description:</strong>}
      transitionTime={200}
      triggerClassName={triggerClassName}
      triggerOpenedClassName={triggerOpenedClassName}
      open
    >
      {core}
    </Collapsible>
  );
};

ShowTemplateValue.propTypes = {
  input: PropTypes.string.isRequired,
  currentUser: PropTypes.shape().isRequired,
  execution: PropTypes.shape().isRequired,
  skipCollapse: PropTypes.bool,

  // for overwriting style classes
  triggerClassName: PropTypes.string,
  triggerOpenedClassName: PropTypes.string
};

ShowTemplateValue.defaultProps = {
  skipCollapse: false,
  triggerClassName: 'collapsible-description-open',
  triggerOpenedClassName: 'collapsible-description-open'
};

export default ShowTemplateValue;
