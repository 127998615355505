import { historyPush } from '../../history';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { TextInput, Loading } from 'lib/acromyrmex';
import { Col, Row, Button } from '../../utility/UiComponents';
import Alert from '../shared/Alert';
import request from '../../utility/request';

class ShowDuoMfaForm extends Component {
  static propTypes = {
    currentUser: PropTypes.shape().isRequired,
    onForward: PropTypes.string,
    forwardUrl: PropTypes.string
  };

  static defaultProps = {
    onForward: null,
    forwardUrl: `/partners/duo/mfa-login`
  };

  state = {
    iKey: '',
    sKey: '',
    host: '',

    error: '',
    loading: false
  };

  onSaveClicked = () => {
    const {
      currentUser: { token },
      forwardUrl,
      onForward
    } = this.props;
    const { iKey, sKey, host } = this.state;

    this.setState({ loading: true, error: '' });
    request
      .post('/api/license/onboard/mfa/duo')
      .set('x-access-token', token)
      .send({ iKey, sKey, host })
      .then(() => {
        if (onForward) {
          toast.success(onForward);
        }
        historyPush(`${forwardUrl}?message=Thanks! Now log in again, this time using duo.`);
      })
      .catch(e => this.setState({ error: `Error: ${e.message}` }))
      .then(() => this.setState({ loading: false }));
  };

  render() {
    const { iKey, sKey, host, error, loading } = this.state;
    const buttonsEnabled = !loading && iKey && sKey && host;

    return (
      <form>
        <Row className="content-row">
          <Col xs={12}>
            {/* TODO: something about mandating MFA */}
            <h3 style={{ margin: '5px 0' }}>Protect your Contuit account with Duo MFA.</h3>
          </Col>
          <Col xs={12}>{error && <Alert danger message={error} />}</Col>
          <TextInput
            label="Integration Key"
            type="text"
            input={{
              value: iKey,
              onChange: ({ target: { value } }) => this.setState({ iKey: value }),
              onBlur: () => {}
            }}
            meta={{}}
          />
          <TextInput
            label="Secret Key"
            type="text"
            input={{
              value: sKey,
              onChange: ({ target: { value } }) => this.setState({ sKey: value }),
              onBlur: () => {}
            }}
            meta={{}}
          />
          <TextInput
            label="API hostname"
            type="text"
            input={{
              value: host,
              onChange: ({ target: { value } }) => this.setState({ host: value }),
              onBlur: () => {}
            }}
            meta={{}}
          />
          <Col xs={12} style={{ textAlign: 'right' }}>
            <Button
              type="submit"
              disabled={!buttonsEnabled}
              variant="primary"
              onClick={this.onSaveClicked}
            >
              {loading ? <Loading text="Saving" /> : 'Save'}
            </Button>
          </Col>
        </Row>
      </form>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.users.currentUser
});
const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ShowDuoMfaForm);
