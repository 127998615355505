import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Loading, Icon } from 'lib/acromyrmex';
import moment from 'moment';
import { Button } from '../../../utility/UiComponents';
import request from 'superagent';
import ExecuteProcessModal from '../ExecuteProcessModal';
import {SCHEDULER_SERVER} from "../../../constants"

class ScheduleItem extends React.Component {
  static propTypes = {
    schedule: PropTypes.shape().isRequired,
    processItem: PropTypes.shape().isRequired,
    loadSchedules: PropTypes.func.isRequired,
    buttonsRight: PropTypes.bool,

    // from redux
    currentUser: PropTypes.shape().isRequired
  };

  static defaultProps = {
    buttonsRight: false
  };

  state = {
    schedulesDeleting: false,
    scheduleUpdating: false,
    showModal: false
  };

  deleteSchedule(id) {
    const {
      currentUser: { token },
      loadSchedules
    } = this.props;

    this.setState({ schedulesDeleting: true });
    return request
      .delete(`${SCHEDULER_SERVER}/${id}`)
      .set({ 'x-access-token': token })
      .then(() => {
        loadSchedules();
      })
      .catch()
      .then(() => {
        this.setState({ schedulesDeleting: false });
      });
  }

  updateSchedule(id, values) {
    const {
      currentUser: { token, _id: userId },
      processItem,
      loadSchedules
    } = this.props;

    this.setState({ schedulesDeleting: true });
    return request
      .put(`${SCHEDULER_SERVER}/${id}`)
      .send({ ...values, processId: processItem._id, userId })
      .set({ 'x-access-token': token })
      .then(() => {
        loadSchedules();
      })
      .catch()
      .then(() => {
        this.setState({ schedulesDeleting: false });
      });
  }

  render() {
    const { schedule, processItem, buttonsRight } = this.props;
    const { schedulesDeleting, scheduleUpdating, showModal } = this.state;

    const repeatInterval = schedule.repeatInterval || 'once';
    const frequencyMap = {
      '1 minute': 'minute',
      '10 minute': 'minute10',
      '15 minute': 'minute15',
      '30 minute': 'minute30',
      '1 day': 'daily',
      '1 week': 'weekly',
      '1 month': 'monthly'
    };
    const frequency = frequencyMap[repeatInterval] || 'once';

    return (
      <div className="indented">
        <Button
          variant="danger"
          size="xs"
          style={{ float: buttonsRight ? 'right' : 'left', margin: '2px' }}
          onClick={() => this.deleteSchedule(schedule._id)}
        >
          {schedulesDeleting ? <Loading /> : <Icon remove />}
        </Button>
        <Button
          variant="primary"
          size="xs"
          style={{ float: buttonsRight ? 'right' : 'left', margin: '2px' }}
          onClick={() => this.setState({ showModal: true })}
        >
          {scheduleUpdating ? <Loading /> : <Icon edit />}
        </Button>
        <div style={{ paddingTop: '3px' }}>{` ${moment(schedule.nextRunAt).format(
          'lll'
        )} (${repeatInterval})`}</div>
        <ExecuteProcessModal
          show={showModal}
          onCancel={() => this.setState({ showModal: false })}
          onSchedule={values => this.updateSchedule(schedule._id, values)}
          processItem={processItem}
          initialValues={{
            startDate: schedule.nextRunAt,
            frequency,
            fills: schedule.data.fills || []
          }}
          hideExecute
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.users.currentUser
});
const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ScheduleItem);
