import React, { Component } from 'react';
import { Image } from '../../../utility/UiComponents';

class ShowSchedulerHelp extends Component {
  static propTypes = {};

  static defaultProps = {};

  state = {};

  render() {
    return (
      <div className="content-col clearfix" id="scheduler">
        <h3>Scheduler</h3>
        <hr />
        <p>
          You can schedule a process to run at some point in the future, or on a recurring schedule.
          This can be useful for many things including routine data cleanup as well as device
          maintence checks.
        </p>
        <div className="help-image">
          <Image
            src="https://s3.us-east-2.amazonaws.com/contuit-assets/help/schedule_form.png"
            alt="schedule-form"
            thumbnail
            responsive
            style={{ maxWidth: '600px' }}
          />
        </div>
        <p>
          Scheduling a process is as simple as clicking execute on the playbook and selecting a
          schedule. You can select fill data to start the process with at this point to.
        </p>
        <div className="help-image">
          <Image
            src="https://s3.us-east-2.amazonaws.com/contuit-assets/help/schedule_playbook.png"
            alt="schedule-playbook"
            thumbnail
            responsive
            style={{ maxWidth: '600px' }}
          />
        </div>
        <p>
          Once you have scheduled a process to run, you can see and manage it from the playbook.
        </p>
      </div>
    );
  }
}

export default ShowSchedulerHelp;
