/* eslint-disable react/prop-types */
import { useState } from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { LoadingButton, Loading, Icon } from 'lib/acromyrmex';
import { React } from '../../utility/ReactStuff';
import { Button } from '../../utility/UiComponents';
import ConfirmModal from '../shared/ConfirmModal';
import ExecuteProcessModal from './ExecuteProcessModal';

const SchedulesButtons = ({
  process,
  schedule,
  deleteSchedule,
  isDeleting,
  updateSchedule,
  isUpdating
}) => {
  const [showDelete, setShowDelete] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);

  const frequencyMap = {
    '1 minute': 'minute',
    '10 minute': 'minute10',
    '15 minute': 'minute15',
    '30 minute': 'minute30',
    '1 day': 'daily',
    '1 week': 'weekly',
    '1 month': 'monthly'
  };
  const frequency = frequencyMap[schedule.repeatInterval] || 'once';

  return (
    <div className="buttons-margin pull-right">
      {process._id && (
        <LinkContainer
          to={{
            pathname: `/processes/${process._id}`
          }}
        >
          <Button size="xsmall" variant="default">
            View Process
          </Button>
        </LinkContainer>
      )}
      {process._id && (
        <Button
          variant="primary"
          size="xs"
          style={{ float: 'right', margin: '2px' }}
          onClick={() => setShowUpdate(true)}
        >
          {isUpdating ? <Loading /> : <Icon edit />}
        </Button>
      )}
      <ExecuteProcessModal
        show={showUpdate}
        onCancel={() => setShowUpdate(false)}
        onSchedule={values => {
          updateSchedule(values);
          setShowUpdate(false);
        }}
        processItem={process}
        initialValues={{
          startDate: schedule.nextRunAt,
          frequency,
          fills: schedule.data.fills || []
        }}
        hideExecute
      />
      <LoadingButton
        variant="danger"
        style={{ marginRight: 5 }}
        size="xsmall"
        loading={isDeleting}
        onClick={() => setShowDelete(true)}
        loadingLabel=" "
        label={<i className="fa fa-trash" />}
      />
      <ConfirmModal
        show={showDelete}
        title="Confirm deleting"
        message="Are you sure you want to delete this schedule?"
        onConfirm={() => {
          deleteSchedule();
          setShowDelete(false);
        }}
        onCancel={() => setShowDelete(false)}
      />
    </div>
  );
};

export default SchedulesButtons;
