import React, { Component } from 'react';
import ComputationHelp from './computationHelp/ComputationHelp';
import Collapsible from '../../shared/Collapsible';
import FormatTable from './computationHelp/FormatTable';

const computationHelp = [
  {
    title: 'Arithmetic',
    name: 'arithmetic-1',
    blurb: 'General math is also supported in the formulas.',
    example: '#license-count# + 15'
  },
  {
    title: 'Count',
    name: 'count-1',
    blurb:
      'The `COUNT` formula can be used to count the number of entries in the vault that match a particular set of conditions. These conditions are passed in string form and must all be true for the entry to be counted.',
    example: '$COUNT$("#license-type#={license-type}")'
  },
  {
    title: 'Sum',
    name: 'sum-1',
    blurb: 'The `SUM` formula can be used to total values from a particular attribute.',
    example: '$SUM$("#license-count#")'
  },
  {
    title: 'Average',
    name: 'average-1',
    blurb:
      'The `AVERAGE` formula can be used to find the average of all values of a given attribute in the structure.',
    example: '$AVERAGE$("#license-count#")'
  },
  {
    title: 'Min',
    name: 'min-1',
    blurb:
      'The `MIN` formula can be used to find the minimum value in the structure for a given attribute.',
    example: '$MIN$("#license-count#")'
  },
  {
    title: 'Max',
    name: 'max-1',
    blurb:
      'The `MAX` formula can be given to find the maximum value in the structure for a given attribute.',
    example: '$MAX$("#license-count#")'
  },
  {
    title: 'And',
    name: 'and-1',
    blurb:
      'The `AND` formula can be used to combine two logical conditions to output true or false. All conditions must be true for the result to be true.',
    example: '$AND$("{license-count}=9", "{license-type}=\'E0\'")'
  },
  {
    title: 'Or',
    name: 'or-1',
    blurb:
      'The `OR` formula can be used to combine two logical conditions to output true or false. At least one condition must be true for the result to be true.',
    example: '$OR$("{license-count}=9", "{license-type}=\'E0\'")'
  },
  {
    title: 'Concat',
    name: 'concat-1',
    blurb: 'The `CONCAT` formula can be used to combine strings together into one larger string.',
    example: '$CONCAT$("{license-count}", "{license-type}")'
  },
  {
    title: 'Date Add',
    name: 'date-add-1',
    blurb:
      'The `DATEADD` formula can be used to add units of time to an existing date. Possible units are: year, month, day, week, hour, minute, second, millisecond.',
    example: '$DATEADD$("year", 6, "{create-date}")'
  },
  {
    title: 'Date Subtract',
    name: 'date-subtract-1',
    blurb:
      'The `DATESUBTRACT` formula can be used to subtract units of time to an existing date. Possible units are: year, month, day, week, hour, minute, second, millisecond.',
    example: '$DATESUBTRACT$("year", 6, "{create-date}")'
  },
  {
    title: 'Date Format',
    name: 'date-format-1',
    blurb:
      'The `DATEFORMAT` formula can be used to output a date in the desired format. See below for reference.',
    example: '$DATEFORMAT$("{create-date}", "YYYY-MM-DD")',
    appendMessage: 'Formatting examples',
    append: <FormatTable />
  },
  {
    title: 'Substring',
    name: 'substring-1',
    blurb:
      'The `SUBSTRING` formula can be used to extract parts of a string given the start and optionally the end of the string cut',
    example: '$SUBSTRING$("{part-number}", 0, 6)'
  },
  {
    title: 'Replace',
    name: 'replace-1',
    blurb: 'The `REPLACE` formula can be used to replace parts of a string with other strings',
    example: '$REPLACE$("{phone-number}", "+1", "+23")'
  }
];

class ShowComputationHelp extends Component {
  static propTypes = {};

  static defaultProps = {};

  state = {};

  render() {
    return (
      <div className="content-col clearfix" id="vault-computations">
        <h3>Vault Computations</h3>
        <hr />
        {computationHelp.map(c => (
          <div key={c.name}>
            <ComputationHelp title={c.title} name={c.name} blurb={c.blurb} />
            {c.append && (
              <div style={{ marginLeft: '20px' }}>
                <Collapsible message={c.appendMessage}>{c.append}</Collapsible>
              </div>
            )}
          </div>
        ))}
      </div>
    );
  }
}

export default ShowComputationHelp;
export { computationHelp };
