import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { TemplateInput, SelectInput } from 'lib/acromyrmex';
import { Row, Col, Button } from '../../../utility/UiComponents';
import StepCondtionTypes from '../../../process/helpers/StepConditionTypes';
import { requiredValidator } from '../../../utility/formValidators';
import { filterCollections } from '../../../utility/ProcessFormOptionHelpers';

export const StepConditionOptions = [
  { id: StepCondtionTypes.EQUALS, name: '=' },
  { id: StepCondtionTypes.NOT_EQUALS, name: '!=' },
  { id: StepCondtionTypes.LESS_THAN, name: '<' },
  { id: StepCondtionTypes.GREATER_THAN, name: '>' },
  { id: StepCondtionTypes.LESS_THAN_EQUAL, name: '<=' },
  { id: StepCondtionTypes.GREATER_THAN_EQUAL, name: '>=' },
  { id: StepCondtionTypes.CONTAINS, name: 'contains' },
  { id: StepCondtionTypes.DOES_NOT_CONTAIN, name: 'does not contain' },
  { id: StepCondtionTypes.STARTS_WITH, name: 'starts with' },
  { id: StepCondtionTypes.ENDS_WITH, name: 'ends with' },
  { id: StepCondtionTypes.IS_NULL, name: 'is null' },
  { id: StepCondtionTypes.IS_NOT_NULL, name: 'is not null' }
];

class StepCondition extends Component {
  static propTypes = {
    change: PropTypes.func.isRequired,
    outputOptions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    condition: PropTypes.string.isRequired,
    fields: PropTypes.shape().isRequired,
    index: PropTypes.number.isRequired,
    hideDelete: PropTypes.bool,
    onChangeAction: PropTypes.func
  };

  static defaultProps = {
    hideDelete: false,
    onChangeAction: () => {}
  };

  state = {};

  render() {
    const {
      change,
      outputOptions,
      condition,
      fields,
      index,
      hideDelete,
      onChangeAction
    } = this.props;

    const conditionObj = fields.get(index);

    return (
      <Row style={{ marginBottom: 5 }} key={condition}>
        <Col xs={4}>
          <Row>
            <Field
              name={`${condition}.lhv`}
              component={TemplateInput}
              label="Label"
              noLabel
              validate={requiredValidator}
              options={filterCollections(outputOptions)}
              onTemplateClicked={option => {
                change(`${condition}.lhv`, option.textValue);
              }}
              onChange={(e, value) => onChangeAction(value, `${condition}.lhv`)}
            />
          </Row>
        </Col>
        <Col xs={2}>
          <Row>
            <Field
              name={`${condition}.conditionType`}
              component={SelectInput}
              label="Label"
              noLabel
              validate={requiredValidator}
              onChange={(e, value) => {
                onChangeAction(value.name ? value.id : value, `${condition}.conditionType`);
              }}
              options={StepConditionOptions}
            />
          </Row>
        </Col>
        {conditionObj.conditionType !== 9 && conditionObj.conditionType !== 10 && (
          <Col xs={4}>
            <Row>
              <Field
                name={`${condition}.rhv`}
                component={TemplateInput}
                label="Type"
                noLabel
                validate={requiredValidator}
                options={filterCollections(outputOptions)}
                onChange={(e, value) => onChangeAction(value, `${condition}.rhv`)}
                onTemplateClicked={option => {
                  change(`${condition}.rhv`, option.textValue);
                }}
              />
            </Row>
          </Col>
        )}
        {(conditionObj.conditionType === 9 || conditionObj.conditionType === 10) && <Col xs={4} />}
        <Col xs={2}>
          {!hideDelete && (
            <Button
              className="pull-right"
              variant="danger"
              onClick={() => fields.remove(index)}
              title="Remove this conditional"
            >
              <i className="fa fa-trash" />
            </Button>
          )}
        </Col>
      </Row>
    );
  }
}

export default StepCondition;
