// import _ from 'underscore';
import superagent from '../utility/request';
import get from 'lodash/get';
import { historyPush } from '../history';
import cookie from 'react-cookie';
import { LicenseServer, AuthenticationServer } from '@contuit-otf/sdk';
import { toast } from 'react-toastify';
import request from '../utility/request';

const authServer = new AuthenticationServer('/api/auth', request);
const licenseServer = new LicenseServer('/api/license', request);

const removeAllCookies = () => {
  const options = {
    path: '/',
    ...(process.env.NODE_ENV === 'production' && { domain: '.contuit.com' }),
  };

  // Attempt to remove each cookie
  ['token', 'contuitId'].forEach((key) => {
    cookie.remove(key, options); // Remove from main domain or specific domain in production
    cookie.remove(key, { path: '/' }); // Remove from subdomain if set there
  });
};

const setCookies = (cookies = {}) => {
  const options = {
    path: '/',
    ...(process.env.NODE_ENV === 'production' && { domain: '.contuit.com' }),
  };

  Object.entries(cookies).forEach(([key, value]) => {
    if (value !== undefined) {
      cookie.save(key, value, options);
    }
  });
};

/**
 * This method is used to direct the user to the correct place after authentication
 * @param {User} user Current Contuit User
 * @param {sting} postLoginRedirect url to redirect the user to
 */
export function redirectAfterLogin(user, postLoginRedirect) {
  // first thing, check if we have signed up through Duo but do not have MFA
  if (
    user.contuitClient.partnerPrograms &&
    user.contuitClient.partnerPrograms.includes('duo') &&
    (!user.contuitClient.mfa || !user.contuitClient.mfa.duo) &&
    (!postLoginRedirect || postLoginRedirect.indexOf('docs') === -1)
  ) {
    historyPush('/partners/duo/mfa');
  } else if (
    user.contuitClient.partnerPrograms &&
    user.contuitClient.partnerPrograms.includes('duo') &&
    user.contuitClient.mfa &&
    user.contuitClient.mfa.duo &&
    user.contuitClient.pendingPackages &&
    user.contuitClient.pendingPackages.length > 0 &&
    (!postLoginRedirect || postLoginRedirect.indexOf('docs') === -1)
  ) {
    historyPush('/marketplace/5d9cd31b023bd6a06b0a9d82/import');
  } else if (postLoginRedirect && postLoginRedirect !== '/login') {
    historyPush(postLoginRedirect);
  } else {
    historyPush('/dashboards/personal');
  }
}

export function showUserResult(jsonResult) {
  return {
    type: 'SHOW_USER',
    user: jsonResult,
  };
}

export function showUsersResult(jsonResult) {
  return {
    type: 'SHOW_USERS',
    users: jsonResult,
  };
}

export function addSimpleUsers(users) {
  return {
    type: 'ADD_SIMPLE_USERS',
    users,
  };
}

export function addTenants(tenants) {
  return {
    type: 'ADD_TENANTS',
    tenants,
  };
}

export function updateTenant(tenant) {
  return {
    type: 'UPDATE_TENANT',
    tenant,
  };
}

export function removeUser(id) {
  return {
    type: 'REMOVE_USER',
    id,
  };
}

export function addUser(user) {
  return {
    type: 'ADD_USER',
    user,
  };
}

export function setUserLoading(loading = true) {
  return {
    type: 'USER_LOADING',
    loading,
  };
}

export function setUserUpdating(updating = true) {
  return {
    type: 'USER_UPDATING',
    updating,
  };
}

export function setLoadingUsers(loading = true) {
  return {
    type: 'USERS_LOADING',
    loading,
  };
}

export function setPostLoginPage(location) {
  return {
    type: 'SET_POST_LOGIN_PAGE',
    location,
  };
}

export function setLoadingSimpleUsers(loading = true) {
  return {
    type: 'SIMPLE_USERS_LOADING',
    loading,
  };
}

export function setLoadingTenants(loading = true) {
  return {
    type: 'TENANTS_LOADING',
    loading,
  };
}

export function setCurrentUser(jsonResult) {
  if (jsonResult.user.token) {
    const { token, contuitId } = jsonResult.user;
    setCookies({ token, contuitId });
  }

  return {
    type: 'AUTH_USER',
    user: jsonResult.user,
  };
}

export function setUserToken(token) {
  setCookies({ token });

  return {
    type: 'SET_TOKEN',
    token,
  };
}

export function requestCreateUser() {
  return {
    type: 'REQUEST_CREATE_USER',
  };
}

export function resolveCreateUser() {
  return {
    type: 'RESOLVE_CREATE_USER',
  };
}

export function requestDeleteUser() {
  return {
    type: 'REQUEST_DELETE_USER',
  };
}

export function resolveDeleteUser() {
  return {
    type: 'RESOLVE_DELETE_USER',
  };
}

export function tokenChecked() {
  return {
    type: 'TOKEN_CHECKED',
  };
}

export function setAvailableUserRolesLoading(loading = true) {
  return {
    type: 'AVAILABLE_USER_ROLES_LOADING',
    loading,
  };
}

export function addAvailableUserRoles(roles) {
  return {
    type: 'ADD_AVAILABLE_USER_ROLES',
    roles,
  };
}

export function setRolesModal(open = true) {
  return {
    type: 'SET_ROLES_MODAL',
    open,
  };
}

export function openRolesModal() {
  return (dispatch) => {
    dispatch(setRolesModal(true));
  };
}

export function closeRolesModal() {
  return (dispatch) => {
    dispatch(setRolesModal(false));
  };
}

export function logoutCurrentUser(directHome = true) {
  removeAllCookies();

  if (directHome) {
    // go home
    window.location = window.location.origin;
  }

  // give some visual response
  toast.success('Logged out!');

  return {
    type: 'UNAUTH_USER',
  };
}

export function deleteUser(id) {
  return (dispatch, getState) => {
    const { token } = getState().users.currentUser;
    if (!id) {
      return false;
    }

    dispatch(requestDeleteUser());
    authServer
      .deleteUser({ token, id })
      .then(() => {
        dispatch(removeUser(id));
        toast.success('User removed!');
      })
      .catch(() => {
        dispatch(toast.error('Problem deleting user. Please try again later.'));
      })
      .then(() => {
        dispatch(resolveDeleteUser());
      });
  };
}

export function updateUser(id, newUser) {
  return (dispatch, getState) => {
    const { currentUser } = getState().users;
    const { token } = currentUser;
    dispatch(setUserUpdating());
    authServer
      .updateUser({ token, user: newUser })
      .then((data) => {
        dispatch(addUser(data));
        toast.success('User updated');

        if (data._id === currentUser._id) {
          dispatch(setCurrentUser({ user: data }));
        }
      })
      .catch((err) => {
        toast.error(`Error updating user: ${err.message}`);
      })
      .then(() => {
        dispatch(setUserUpdating(false));
      });
  };
}

export function loadUser(id) {
  return (dispatch, getState) => {
    const { token } = getState().users.currentUser;
    dispatch(setUserLoading(true));

    return authServer
      .getUser({ token, id })
      .then((data) => {
        dispatch(addUser(data));
      })
      .catch(() => {
        // nothing??
      })
      .then(() => {
        dispatch(setUserLoading(false));
      });
  };
}

export function loadUsers() {
  return (dispatch, getState) => {
    const { token } = getState().users.currentUser;
    dispatch(setLoadingUsers(true));
    return authServer
      .getUsers({ token })
      .then((data) => {
        dispatch(showUsersResult(data));
      })
      .catch(() => {
        toast.error('Failed to load user. Please try again later.');
      })
      .finally(() => {
        dispatch(setLoadingUsers(false));
      });
  };
}

export function loadSimpleUsers(ids = []) {
  return (dispatch, getState) => {
    const { token } = getState().users.currentUser;
    dispatch(setLoadingSimpleUsers(true));
    authServer
      .getSimpleUsers({ token, ids })
      .then(data => {
        dispatch(addSimpleUsers(data));
      })
      .catch((err) => {
        toast.error(`Error getting users: ${err.message}`);
      })
      .then(() => {
        dispatch(setLoadingSimpleUsers(false));
      });
  };
}

export function loadTenants(ids = []) {
  return (dispatch, getState) => {
    const { token } = getState().users.currentUser;
    dispatch(setLoadingTenants(true));
    licenseServer
      .getContuitClients({ token, ids })
      .then((data) => {
        dispatch(addTenants(data));
      })
      .catch((err) => {
        toast.error(`Error getting tenants: ${err.message}`);
      })
      .then(() => {
        dispatch(setLoadingTenants(false));
      });
  };
}

// This function will check if the token exists, and return the user if so
export function checkToken(token) {
  return (dispatch) => {
    superagent
      .post(`/api/auth/token/${token}`)
      .then(({ body: data }) => {
        dispatch(setCurrentUser(data));

        // give some visual response
        const msg = `Welcome back, ${data.user.name}!`;
        toast.success(msg);

        const { pathname, search } = window.location;
        const pathsToForwardFrom = ['/', '/login'];

        redirectAfterLogin(
          data.user,
          pathsToForwardFrom.includes(pathname)
            ? `/dashboards/personal`
            : `${pathname}${search}`,
        );
      })
      .catch(() => {
        removeAllCookies();
        dispatch(tokenChecked());
      });
  };
}

export function refreshToken() {
  return (dispatch, getState) => {
    const { token } = getState().users.currentUser;

    request
      .post(`/api/auth/token/refresh`)
      .set({ 'x-access-token': token })
      .then(({ body: data }) => {
        dispatch(setUserToken(data.token));
      });
  };
}

export function sendUserInvite() {
  return (dispatch, getState) => {
    const { currentUser } = getState().users;
    const { token, contuitId } = currentUser;

    const formValues = getState().form.inviteUserForm.values;
    const body = {
      email: formValues.email,
      currentTenant: contuitId,
    };

    dispatch(requestCreateUser());
    request
      .post('/api/auth/inviteUser')
      .set({ 'x-access-token': token })
      .send(body)
      .then(() => {
        toast.success('Invite sent!');
      })
      .catch(() => {
        toast.error('Problem sending invite email. Please try again later.');
      })
      .then(() => {
        dispatch(resolveCreateUser());
      });
  };
}

export function submitNewUser() {
  return (dispatch, getState) => {
    const form = getState().form.userForm;

    request
      .post('/api/auth/newUser')
      .send(form.values)
      .then(({ body: data }) => {
        if (!data.success) {
          dispatch(toast.error(data.message));
          return;
        }
        dispatch(setCurrentUser(data));

        // go home
        historyPush('/dashboards/personal');

        // give some visual response
        const msg = `Welcome, ${data.user.name}!`;
        toast.success(msg);
      })
      .catch((err) => {
        const {
          response: { body },
        } = err;

        toast.error(
          get(
            body,
            'message',
            'Unknown error creating user. Please try again soon.',
          ),
        );
      });
  };
}

export function setFetchingUser(isFetching) {
  return {
    type: 'FETCHING_USER',
    isFetching,
  };
}

export function redirectToLogin() {
  return (dispatch) => {
    // redirect to login if not
    dispatch(setPostLoginPage(window.location.pathname));

    historyPush('/login');

    toast.error('You must be logged in to visit that page');
  };
}

export function authenticate() {
  return (dispatch, getState) => {
    const {
      form: { loginForm: form },
      users: { postLoginRedirect },
    } = getState();

    dispatch(setFetchingUser(true));
    request
      .post('/api/auth/authenticate')
      .send(form.values)
      .then(({ body: data }) => {
        dispatch(setCurrentUser(data));

        // we have now logged in, handle redirection
        redirectAfterLogin(data.user, postLoginRedirect);

        // give some visual response
        const msg = `Welcome back, ${data.user.name}!`;
        toast.success(msg);
      })
      .catch((err) => {
        const msg =
          err.response && err.response.body && err.response.body.message
            ? err.response.body.message
            : 'Unknown authentication error.';

        toast.error(msg);
      })
      .then(() => {
        dispatch(setFetchingUser(false));
      });
  };
}

export function loadAvailableUserRoles() {
  return (dispatch, getState) => {
    const { token } = getState().users.currentUser;

    dispatch(setAvailableUserRolesLoading());
    return authServer
      .getAvailableUserRoles({ token })
      .then((data) => {
        dispatch(addAvailableUserRoles(data));
      })
      .catch((err) => {
        const msg =
          err.responseJSON && err.responseJSON.message
            ? err.responseJSON.message
            : 'Unknown error, please try again later.';

        toast.error(msg);
      })
      .finally(() => {
        dispatch(setAvailableUserRolesLoading(false));
      });
  };
}
