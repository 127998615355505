import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ListRecentVaultUpdates from '../vaultStructures/ListRecentVaultUpdates';
import PageLayout from '../shared/Layout/PageLayout';
import ShowProcessExecutionTable from '../processes/ShowProcess/ShowProcessExecutionTable';

// eslint-disable-next-line react/prefer-stateless-function
class PersonalDashboard extends React.Component {
  render() {
    const { currentUser, routes } = this.props;

    return (
      <PageLayout
        header="Personal Dashboard"
        noContentPadding
        rightSidebar={<ListRecentVaultUpdates />}
        content={<ShowProcessExecutionTable currentUser={currentUser} />}
        routes={routes}
        currentUser={currentUser}
      />
    );
  }
}

PersonalDashboard.propTypes = {
  currentUser: PropTypes.shape().isRequired,
  routes: PropTypes.arrayOf(PropTypes.shape()).isRequired
};

PersonalDashboard.defaultProps = {};

const mapStateToProps = state => ({
  currentUser: state.users.currentUser
});

export default connect(mapStateToProps)(PersonalDashboard);
