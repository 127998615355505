import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Loading } from 'lib/acromyrmex';
import { VaultServer } from '@contuit-otf/sdk';
import { Row } from '../../utility/UiComponents';
import VaultEntryForm from './VaultEntryForm';
import Vault from '../../utility/vault';
import { loadVaultStructure, updateEntry } from '../../actions/vault';
import EmptyView from '../shared/EmptyView';
import request from '../../utility/request';
import PageLayout from '../shared/Layout/PageLayout';

function EditVaultEntry() {
  const dispatch = useDispatch();
  const { vaultStructureId: slug, dataId } = useParams();
  const vault = useSelector((state) => state.vault);
  const data = useSelector((state) => state.vault.data[slug]);
  const entries = data ? data.rows : [];
  const [entry, setEntry] = useState(entries.find((e) => e._id === dataId));
  const [entryLoading, setEntryLoading] = useState(false);
  const [entryLoadError, setEntryLoadError] = useState('');
  const {
    currentUser: { token },
  } = useSelector((state) => state.users);
  const struct = vault.structures[slug];
  const formValues = useSelector((state) =>
    state.form.vaultEntryForm ? state.form.vaultEntryForm.values : {},
  );

  const onFormSubmit = () => {
    dispatch(updateEntry(dataId, slug, formValues));
  };

  const loadVaultStructureData = () => {
    setEntryLoading(true);
    VaultServer.loadVaultEntries(
      '/api/vault',
      { token, slug, query: { _id: dataId, cp_limit: 1 } },
      request,
    )
      .then(({ items: data }) => {
        setEntry(data[0]);
      })
      .catch(() => {
        setEntryLoadError('Unknown error loading vault structure data.');
      })
      .then(() => {
        setEntryLoading(false);
      });
  };

  // Load vault structure if not loaded
  useEffect(() => {
    if (!Vault.hasStructureLoaded(vault.data, slug)) {
      dispatch(loadVaultStructure(slug));
    }

    if (!entry) {
      loadVaultStructureData();
    }
  }, []);

  if (entryLoadError) {
    return (
      <div>
        <Row className="content-row">
          <EmptyView
            header="Error loading entry"
            content={`Please try again soon. Error details: ${entryLoadError}`}
          />
        </Row>
      </div>
    );
  }

  if (entryLoading) {
    return <Loading />;
  }

  return (
    <PageLayout
      header={`Edit ${struct && struct.singular ? struct.singular : '...'}`}
      noContentPadding
      content={
        !entry ? (
          <EmptyView
            header="Cannot find entry"
            content="Please try again soon."
          />
        ) : (
          <VaultEntryForm
            slug={slug}
            initialValues={entry}
            onSubmit={onFormSubmit}
            edit
          />
        )
      }
    />
  );
}

export default EditVaultEntry;
