const windowWidth = window.innerWidth
const UI_INITIAL = {
  tabs: {
    showProcess: 'analytics',
    showVaultStructure: 'data',
    showCommunityProcess: 'analytics',
    showCommunityVaultStructure: 0,
  },
  tables: {},
  navHide: windowWidth < 1300,
  // default date format for use with moment.format()
  dateFormat: 'M/D/YYYY',
  dateTimeFormat: 'M/D/YYYY HH:mm',
}

export default (state = UI_INITIAL, action = {}) => {
  switch (action.type) {
    case 'SET_NAV_STATE': {
      return { ...state, navHide: action.navHide }
    }
    case 'IS_LOADING':
      return { ...state, isLoading: action.isLoading }
    case 'IS_SUBMITTING':
      return { ...state, isSubmitting: action.isSubmitting }
    case 'SET_TAB': {
      const nextState = { ...state }
      nextState.tabs[action.name] = action.tab
      return nextState
    }
    case 'SET_TABLE_FILTER': {
      const nextState = { ...state }

      if (!nextState.tables[action.name]) {
        nextState.tables[action.name] = {
          filters: {},
        }
      }

      if (!nextState.tables[action.name].filters) {
        nextState.tables[action.name].filters = {}
      }

      nextState.tables[action.name].filters[action.filter.key] = action.filter

      return nextState
    }
    case 'CLEAR_TABLE_FILTER': {
      const nextState = { ...state }

      if (!nextState.tables[action.name]) {
        return nextState
      }

      delete nextState.tables[action.name].filters[action.filter.column.key]

      return nextState
    }
    case 'CLEAR_TABLE_FILTERS': {
      const nextState = { ...state }

      if (!nextState.tables[action.name]) {
        return nextState
      }

      nextState.tables[action.name].filters = {}

      return nextState
    }
    case 'SET_TABLE_SORT': {
      const nextState = { ...state }

      if (!nextState.tables[action.name]) {
        nextState.tables[action.name] = {}
      }

      nextState.tables[action.name].sort = {
        sortBy: action.sortBy,
        sortDirection: action.sortDirection,
      }

      return nextState
    }
    case 'SET_TABLE_NEW_DATA': {
      const nextState = { ...state }

      if (!nextState.tables[action.name]) {
        nextState.tables[action.name] = {}
      }

      nextState.tables[action.name].newData = action.newData

      return nextState
    }
    default:
      return state
  }
}
