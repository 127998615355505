import { VAULT_MERGE_STEP_TYPE } from '../../../constants'
import BasicUiHelper from './BasicUiHelper'
import VaultMergeStepOptions from './components/stepOptions/VaultMergeStepOptions'

class VaultMergeUiHelper extends BasicUiHelper {
  regex() {
    return VAULT_MERGE_STEP_TYPE
  }

  name() {
    return 'Vault Merge'
  }

  canTryAgain() {
    return true
  }

  stepOptions() {
    return VaultMergeStepOptions
  }

  getOptionSummary() {
    return [
      {
        key: 'vaultStructure',
        title: 'Vault Structure',
        type: 'VaultStructure',
      },
    ]
  }
}

export default VaultMergeUiHelper
