const attributeTypes = [
  { id: 'string', name: 'String' },
  { id: 'text', name: 'Text', ignoreComputation: true },
  { id: 'dateTime', name: 'Date & Time' },
  { id: 'number', name: 'Number' },
  { id: 'boolean', name: 'True/False' },
  { id: 'list', name: 'List of values', ignoreComputation: true },
  { id: 'pickList', name: 'Pick from list', ignoreComputation: true },
  { id: 'email', name: 'Email', ignoreComputation: true },
  { id: 'tel', name: 'Phone', ignoreComputation: true },
  { id: 'url', name: 'URL', ignoreComputation: true },
  { id: 'date', name: 'Date' },
  { id: 'time', name: 'Time' },
  { id: 'file', name: 'File', ignoreComputation: true },
]

export default class {
  static getAttributeTypes() {
    return attributeTypes
  }

  static getComputationTypes() {
    return attributeTypes.filter((a) => !a.ignoreComputation)
  }
}
