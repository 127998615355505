import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { LoadingButton } from 'lib/acromyrmex';
import TextInput from 'lib/acromyrmex/TextInput';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux'
import get from 'lodash/get';
import request from '../../utility/request';
import Alert from '../shared/Alert';
import { Modal, Form } from '../../utility/UiComponents';

const NewTenantModal = ({ showModal, onHide, currentUser, setCurrentUser }) => {
  const [tenantName, setTenantName] = useState('');
  const [tenantError, setTenantError] = useState(null);
  const [isCreating, setIsCreating] = useState(false);
  const dispatch = useDispatch();

  const handleTenant = async () => {
    try {
      setIsCreating(true);

      const res = await request
        .post('/api/license/addTenant')
        .set('x-access-token', currentUser.token)
        .send({ name: tenantName });

      await dispatch(setCurrentUser(res.body));
      toast.success('Successfully created tenant');
      setIsCreating(false);
      setTenantName('');
      onHide();
    } catch (err) {
      const { response } = err;
      const message = `Error creating tenant: ${get(response, 'body.message', 'Unknown error')}`;

      setTenantError(message);
      setIsCreating(false);
    }
  };

  return (
    <Modal show={showModal} onHide={onHide} size="large">
      <Modal.Header closeButton>
        <Modal.Title>Create New Tenant</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="content-row content-row-padded process-form box-rest">
          <div className="container">
            <Form
              onSubmit={e => {
                e.preventDefault();
                handleTenant();
              }}
            >
              <TextInput
                label="Tenant Name:"
                type="text"
                input={{
                  value: tenantName,
                  onChange: ({ target: { value } }) => setTenantName(value),
                  onBlur: () => {}
                }}
                meta={{}}
              />
              {tenantError && <Alert message={tenantError} />}
              <div style={{ marginRight: 15 }}>
                <LoadingButton
                  variant="primary"
                  label="Create Tenant"
                  loading={isCreating}
                  className="pull-right"
                  loadingLabel="Creating Tenant"
                  onClick={handleTenant}
                  type="button"
                />
              </div>
            </Form>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

NewTenantModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  currentUser: PropTypes.shape({
    token: PropTypes.string.isRequired,
    tenants: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired
      })
    ).isRequired,
    contuitId: PropTypes.string.isRequired
  }).isRequired,
  setCurrentUser: PropTypes.func.isRequired
};

export default NewTenantModal;
