import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import SidebarAttribute from '../shared/Layout/SidebarAttribute';
import AttributeHelper from './attributes/UiAttributeHelper';

const VaultEntryAttributes = ({ struct, entry }) => {

 return get(struct, 'attributes', []).map(attribute => (
    <SidebarAttribute
      key={attribute.key}
      display={attribute.display}
      value={AttributeHelper.getRenderComponent(attribute, get(entry, attribute.key, ''))}
    />
  ));
}

VaultEntryAttributes.propTypes = {
  struct: PropTypes.shape().isRequired,
  entry: PropTypes.shape().isRequired
};

export default VaultEntryAttributes;
