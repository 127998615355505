import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { LicenseServer } from '@contuit-otf/sdk';
import { Button } from '../../utility/UiComponents';
import UpdateTenantModal from './UpdateTenantModal';
import request from '../../utility/request';
import StringHelper from '../../shared-helpers/StringHelper';
import { updateTenant } from '../../actions/users';
import { useDispatch } from 'react-redux';

const licenseServer = new LicenseServer('/api/license', request);

const EditTenantNameSection = ({ tenant, token, onUpdateTenant }) => {
  const [showModal, setShowModal] = useState(false);
  const [tenantName, setTenantName] = useState(tenant.name);
  const dispatch = useDispatch();

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const handleConfirmUpdate = async (newTenantName) => {
    try {
      const updatedTenant = {
        ...tenant,
        name: newTenantName,
        slug: StringHelper.slugify(newTenantName),
      };

      const newTenant = await licenseServer.updateContuitClient({
        token,
        contuitClient: updatedTenant,
        contuitClientId: tenant._id,
      });

      toast.success('Tenant name updated successfully.');
      setTenantName(newTenant.name);
      onUpdateTenant(newTenantName);
      dispatch(updateTenant(newTenant));
      handleCloseModal();
    } catch (error) {
      console.log(error)
      toast.error('Failed to update tenant name.');
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <h2 style={{ marginRight: '10px' }}>{tenantName}</h2>
      <Button variant="primary" size="lg" onClick={handleOpenModal}>
        <i className="fa fa-edit" />
      </Button>

      <UpdateTenantModal
        show={showModal}
        onHide={handleCloseModal}
        onConfirm={handleConfirmUpdate}
        tenant={tenant}
      />
    </div>
  );
};

EditTenantNameSection.propTypes = {
  tenant: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  token: PropTypes.string.isRequired,
};

export default EditTenantNameSection;
