import _ from 'underscore'
import get from 'lodash/get'
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Loading, Icon } from 'lib/acromyrmex'
import { useParams } from 'react-router'

import { getShowKeys } from '../../shared-helpers/ApiHelper'
import {
  Modal,
  Col,
  Alert,
  Button,
  Accordion,
} from '../../utility/UiComponents'
import EmptyView from '../shared/EmptyView'
import DataConfigurationForm from './DataConfigurationForm'
import EnableIntegrationForm from './EnableIntegrationForm'
import {
  loadEnabledIntegration as loadEnabledIntegrationAction,
  updateEnabledIntegration as updateEnabledIntegrationAction,
  createDataConfiguration as createDataConfigurationAction,
  setModalVisible as setModalVisibleAction,
} from '../../actions/enabledIntegrations'
import PageLayout from '../shared/Layout/PageLayout'

class ShowIntegration extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      showModal: false,
    }

    this.openModal = this.openModal.bind(this)
    this.closeModal = this.closeModal.bind(this)
    this.openEditModal = this.openEditModal.bind(this)
    this.closeEditModal = this.closeEditModal.bind(this)
    this.onFormSubmit = this.onFormSubmit.bind(this)
    this.onEditFormSubmit = this.onEditFormSubmit.bind(this)
  }

  componentDidMount() {
    const { loadEnabledIntegration, integrationId } = this.props
    loadEnabledIntegration(integrationId)
  }

  onFormSubmit() {
    const { createDataConfiguration, integration, formValues } = this.props
    createDataConfiguration(integration, formValues)
  }

  onEditFormSubmit(values) {
    const { updateEnabledIntegration, integration } = this.props
    updateEnabledIntegration(integration._id, values)
  }

  openModal() {
    this.setState({ showModal: true })
  }

  closeModal() {
    this.setState({ showModal: false })
  }

  openEditModal() {
    const { setModalVisible } = this.props
    setModalVisible(true)
  }

  closeEditModal() {
    const { setModalVisible } = this.props
    setModalVisible(false)
  }

  renderDataAquisition() {
    const { integration } = this.props

    const rows = []
    _.each(integration.dataConfigurations, (config) => {
      const header = `${config.method} ${config.path} - ${config.vaultSlug} - ${config.frequency}`

      rows.push(
        <Accordion.Item eventKey={rows.length}>
          <Accordion.Header>{header}</Accordion.Header>
          <Accordion.Body>Sample data?</Accordion.Body>
        </Accordion.Item>,
      )
    })

    return <Accordion>{rows}</Accordion>
  }

  renderDetail() {
    const { integration } = this.props

    if (!integration) {
      return ''
    }

    const keys = getShowKeys(integration.spec['x-integration-slug'])

    return (
      <div>
        {keys.map((key) => (
          <Col xs={12} sm={6} lg={4} key={key.key}>
            <strong>{key.display}</strong>:
            {get(integration, `authenticationOptions.${key.key}`)}
          </Col>
        ))}
      </div>
    )
  }

  render() {
    const { integration, isLoading, showEditModal, currentUser, routes } =
      this.props
    const { showModal } = this.state
    let content = null

    if (isLoading) {
      content = (
        <Alert variant="info" className="pull-left">
          <Loading /> Loading integration...
        </Alert>
      )
    } else if (!integration) {
      content = (
        <EmptyView
          header="Integration not found."
          content=""
          button={{
            pathname: '/admin/integrations',
            content: 'View Your Integrations',
            style: 'info',
          }}
        />
      )
    } else {
      content = (
        <div>
          <Col xs={12}>
            <h4>Server information</h4>
          </Col>
          <Col xs={12} sm={6} lg={4}>
            <strong>Server endpoint</strong>:{integration.endpoint}
          </Col>
          {this.renderDetail()}
          <Modal show={showModal} onHide={this.closeModal} size="large">
            <Modal.Header closeButton>
              <Modal.Title>Add new data acquisition configuration</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <DataConfigurationForm
                onSubmit={this.onFormSubmit}
                initialValues={{}}
              />
            </Modal.Body>
          </Modal>
          <Modal
            show={showEditModal}
            onHide={this.closeEditModal}
            size="large"
          >
            <Modal.Header closeButton>
              <Modal.Title>
                Enable{' '}
                {integration && integration.spec && integration.spec.info.title}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <EnableIntegrationForm
                onSubmit={this.onEditFormSubmit}
                integration={integration.spec}
                initialValues={integration}
                edit
              />
            </Modal.Body>
          </Modal>
        </div>
      )
    }

    return (
      <PageLayout
        routes={routes}
        currentUser={currentUser}
        header={integration && integration.name}
        description={
          integration && [
            <strong>{integration.spec.info.title}</strong>,
            ` - ${integration.spec.info.description}`,
          ]
        }
        headerButtons={
          <Button
            size="sm"
            variant="primary"
            className="pull-right"
            onClick={() => {
              this.openEditModal()
            }}
          >
            <Icon edit />
          </Button>
        }
        noContentPadding
        content={content}
      />
    )
  }
}

ShowIntegration.propTypes = {
  integrationId: PropTypes.string.isRequired,
  currentUser: PropTypes.shape().isRequired,
  routes: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  integration: PropTypes.shape(),
  loadEnabledIntegration: PropTypes.func.isRequired,
  updateEnabledIntegration: PropTypes.func.isRequired,
  createDataConfiguration: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  formValues: PropTypes.shape(),
  setModalVisible: PropTypes.func.isRequired,
  showEditModal: PropTypes.bool.isRequired,
}

ShowIntegration.defaultProps = {
  integration: null,
  isLoading: false,
  formValues: {},
}

const mapStateToProps = (state, props) => {
  return {
    currentUser: state.users.currentUser,
    integration: state.enabledIntegrations.enabledIntegration.value,
    isLoading:
      state.enabledIntegrations.enabledIntegration.isLoading ||
      state.enabledIntegrations.enabledIntegration.isCreating,
    formValues: state.form.dataConfigurationForm
      ? state.form.dataConfigurationForm.values
      : {},
    showEditModal: state.enabledIntegrations.enabledIntegration.showModal,
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateEnabledIntegration: updateEnabledIntegrationAction,
      loadEnabledIntegration: loadEnabledIntegrationAction,
      createDataConfiguration: createDataConfigurationAction,
      setModalVisible: setModalVisibleAction,
    },
    dispatch,
  )

const ShowIntegrationWithState = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ShowIntegration)

// Functional component to extract integrationId from useParams
const ShowIntegrationWrapper = (props) => {
  const { integrationId } = useParams()

  return <ShowIntegrationWithState integrationId={integrationId} {...props} />
}

export default ShowIntegrationWrapper
