import _ from 'underscore'
import has from 'lodash/has'
import moment from 'moment'
import { TEMPLATE_REGEX } from '../constants'

class StringHelper {
  static template(string, data, regex) {
    if (!string || !data) {
      return ''
    }

    return string.replace(regex || TEMPLATE_REGEX, (m) => {
      const key = this._getKeyFromTemplateMatch(m)

      return Object.prototype.hasOwnProperty.call(data, key)
        ? data[key]
        : 'undef'
    })
  }

  static _getKeyFromTemplateMatch(match) {
    return match.substring(1, match.length - 1)
  }

  static getTemplateValues(string) {
    const templateValues = []

    // Loop over the matches
    string.replace(TEMPLATE_REGEX, (match) => {
      templateValues.push(this._getKeyFromTemplateMatch(match))

      // we actually don't want to change anything, just looping
      return match
    })

    return templateValues
  }

  /**
   * Takes a string with template values and an object. Finds all template values and tries to
   * get the value from the object provided. NOTE: step id's are ignored, output is taken directly
   * from the provided object.
   */
  static replaceTemplateValuesForObject(string, obj) {
    let newString = string
    const templates = StringHelper.getTemplateValues(string)
    templates.forEach((t) => {
      const dotIndex = t.indexOf('.')
      // if there is no '.' in the template, we just take the whole thing without {}
      const templateKey = t.substring(dotIndex + 1, t.length)

      newString = newString.replace(
        `{${t}}`,
        StringHelper.getAttribute(obj, templateKey) || '',
      )
    })

    return newString
  }

  /**
   * Gets a step id from a string containing a {STEPID.ATTRIBUTE} style template
   * @param {string} string The template you want to find a stepId from
   */
  static getStepIdFromTemplate(string) {
    if (!string) {
      return ''
    }

    return string.substring(1, string.indexOf('.'))
  }

  static getAttributeFromTemplate(string) {
    if (!string) {
      return ''
    }

    return string.substring(string.indexOf('.') + 1, string.length - 1)
  }

  // gets a potentially nested attribute from an object from a string key
  static getAttribute(obj, attrString) {
    if (!obj) {
      return obj
    }

    if (attrString === '.') {
      if (has(obj, '.')) {
        return obj['.']
      }

      return obj
    }

    if (attrString.indexOf('.') < 0) {
      return obj[attrString]
    }

    const split = attrString.split('.')
    let newObj = obj
    split.forEach((key) => {
      if (!newObj) {
        return
      }
      newObj = newObj[key]
    })

    return newObj
  }

  static getInitials(user) {
    if (!user) {
      return ''
    }

    const split = user.name.split(' ')
    let initials = ''

    _.each(split, (name) => {
      if (name.length < 1) {
        return
      }

      initials += name[0]
    })

    return initials.toUpperCase()
  }

  static formatCurrency(input) {
    return `$${input.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`
  }

  static slugify(text) {
    return text
      .toString()
      .toLowerCase()
      .replace(/\s+/g, '-') // Replace spaces with -
      .replace(/[^\w-]+/g, '') // Remove all non-word chars
      .replace(/--+/g, '-') // Replace multiple - with single -
      .replace(/^-+/, '') // Trim - from start of text
      .replace(/-+$/, '') // Trim - from end of text
  }

  static randomCode() {
    const r = Math.random()
    const code = Math.floor(r * 999999)
    const padded = `00000${code}`
    const trimmed = padded.substr(padded.length - 6)

    return `${trimmed.substr(0, 3)}-${trimmed.substr(3)}`
  }

  static regexReplace(s) {
    return s.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&')
  }

  static getTimeText(date, onOrAt = false) {
    const momentDate = moment(date)
    const today = moment().diff(momentDate, 'days') >= 1
    let text = ''

    if (onOrAt) {
      text += today ? 'on ' : 'at '
    }

    text += today
      ? momentDate.format('MMMM D [at] h:mm A')
      : momentDate.format('h:mm A')

    return text
  }
}

export default StringHelper
export { TEMPLATE_REGEX }
