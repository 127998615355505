/* eslint-disable react/prop-types */
import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { SelectInput, Loading } from 'lib/acromyrmex';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { IntegrationServer } from '@contuit-otf/sdk';
import ObjectId from 'bson-objectid';
import { Row, Col, Button } from '../../utility/UiComponents';
import EnableIntegrationForm from '../integrations/EnableIntegrationForm';
import ExecutionMarkdown from '../processes/ExecutionMarkdown';
import request from '../../utility/request';

const integrationServer = new IntegrationServer('/api/integrations', request);

const EnableIntegrationFormWrapper = ({
  integrationsOptions,
  onEditFormSubmit,
  onFormSubmit,
  availableIntegration,
  defaultIntegration,
  integrationName,
  integrationId,
  bundleIntegrationId,
  bundleIntegrationName,
}) => {
  const [currentValue, setCurrentValue] = useState('');
  const formName = useRef(`enable-integrations-${new ObjectId().toString()}`);
  const defaultIntegrationId = defaultIntegration?._id;

  return (
    <div style={{ marginBottom: '20px' }}>
      <Col xs={12}>
        <Col xs={12}>
          <SelectInput
            name="enabled-integration"
            label={`Select an Integration${
              bundleIntegrationName ? ` - (${bundleIntegrationName})` : ''
            }`}
            meta={{}}
            input={{
              onChange: (v) =>
                setCurrentValue((pre) => (pre ? v : defaultIntegrationId || v)),
              value: currentValue,
              onBlur: () => {},
            }}
            options={integrationsOptions}
          />
        </Col>
      </Col>
      {currentValue !== '$c_create' && (
        <Col xs={12} style={{ marginBottom: '20px' }}>
          <Col xs={12}>
            <Col xs={12}>
              <Button
                variant="primary"
                className="pull-right"
                type="submit"
                onClick={() => {
                  onEditFormSubmit(
                    {
                      _id: currentValue,
                      integrationName,
                    },
                    bundleIntegrationId,
                  );
                }}
              >
                Use Existing Integration
              </Button>
            </Col>
          </Col>
        </Col>
      )}
      {currentValue === '$c_create' && (
        <EnableIntegrationForm
          form={formName.current}
          onSubmit={(formValues) =>
            onFormSubmit(formValues, bundleIntegrationId)
          }
          integration={availableIntegration}
          initialValues={{
            integrationId,
            defaultIntegration: integrationsOptions.length === 0,
            name: bundleIntegrationName,
          }}
        />
      )}
    </div>
  );
};
class EnableIntegrationFormRow extends React.Component {
  static propTypes = {
    enabledIntegrations: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    browser: PropTypes.shape().isRequired,
    availableIntegration: PropTypes.shape().isRequired,
    onEditFormSubmit: PropTypes.func.isRequired,
    currentUser: PropTypes.shape().isRequired,
  };

  onFormSubmit = (formValues, bundleIntegrationId) => {
    const {
      currentUser: { token },
      onEditFormSubmit,
      availableIntegration,
    } = this.props;
    const integration = {
      integrationName: availableIntegration['x-integration-slug'],
      ...formValues,
    };

    integrationServer
      .createEnabledIntegration({ enabledIntegration: integration, token })
      .then((data) => {
        onEditFormSubmit(data, bundleIntegrationId);
      })
      .catch(() => {
        // dispatch(addErrorToast(`Error creating enabledIntegration: ${err}`));
      })
      .then(() => {
        // dispatch(setEnabledIntegrationCreating(false));
      });
  };

  render() {
    const {
      availableIntegration,
      browser,
      enabledIntegrations,
      enabledIntegrationsHasLoaded,
      onEditFormSubmit,
      groupedIntegrations,
    } = this.props;

    if (!enabledIntegrationsHasLoaded) {
      return (
        <div style={{ height: '100%' }}>
          <Row className="content-row">
            <Col xs={12}>
              <Loading text="Loading Required Integrations" />
            </Col>
          </Row>
        </div>
      );
    }

    const theseIntegrations = enabledIntegrations.filter(
      (e) => e.integrationName === availableIntegration['x-integration-slug'],
    );

    return (
      <Row
        className="content-row explanation-row"
        style={{
          marginTop: '5px',
          marginBottom: '15px',
          padding: 0,
          display: 'flex',
          flexDirection: browser.lessThan.medium ? 'column' : 'row',
          overflow: 'hidden',
        }}
      >
        <div
          className="explanation"
          style={{
            color: 'white',
            padding: '30px',
            fontWeight: '400',
            fontSize: '1.1em',
            flexBasis: browser.lessThan.medium ? '100%' : '35%',
          }}
        >
          <h4>{availableIntegration.info.title}</h4>
          <ExecutionMarkdown
            source={
              availableIntegration.info.keyDocumentation || 'Docs coming soon!'
            }
          />
          <p>
            For more information, please visit the{' '}
            <a
              href={`/docs/integrations/${availableIntegration['x-integration-slug']}`}
            >
              full documentation
            </a>
            .
          </p>
        </div>
        <div
          className="content"
          style={{ flexBasis: browser.lessThan.medium ? '100%' : '65%' }}
        >
          {groupedIntegrations.map((i) => (
            <EnableIntegrationFormWrapper
              key={i.integrationId || i.slug}
              availableIntegration={availableIntegration}
              integrationsOptions={[
                { id: '$c_create', name: '+ Create New' },
                ...theseIntegrations.map((m) => ({
                  id: m._id,
                  name: m.name,
                })),
              ]}
              defaultIntegration={enabledIntegrations.find(
                (e) =>
                  e.integrationName ===
                    availableIntegration['x-integration-slug'] &&
                  e.defaultIntegration,
              )}
              integrationName={availableIntegration['x-integration-slug']}
              integrationId={availableIntegration['x-integration-id']}
              onEditFormSubmit={onEditFormSubmit}
              onFormSubmit={this.onFormSubmit}
              bundleIntegrationId={i.integrationId}
              bundleIntegrationName={i.integrationName}
            />
          ))}
        </div>
      </Row>
    );
  }
}

const mapStateToProps = (state) => ({
  currentUser: state.users.currentUser,
  enabledIntegrations: state.enabledIntegrations.enabledIntegrations.rows,
  enabledIntegrationsHasLoaded:
    state.enabledIntegrations.enabledIntegrations.hasLoaded,
  browser: state.browser,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EnableIntegrationFormRow);
