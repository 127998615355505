import React, { useState } from 'react'
import PropTypes from 'prop-types'
import includes from 'lodash/includes'
import without from 'lodash/without'
import request from '../../../utility/request'
import DateHelper from '../../../shared-helpers/DateHelper'
import { TimelineMarker } from './TimelineEvent'
import { FileThumbnail } from '../../shared/FileUpload/FileThumbnail'
import {
  Col,
  FormGroup,
  InputGroup,
  FormControl,
  Button,
} from '../../../utility/UiComponents'
import ExecutionMarkdown from '../ExecutionMarkdown'
import TrundleRow from '../../shared/TrundleRow'
import UserThumbnail from '../../user/UserThumbnail'
import useAsyncAction from '../../../hooks/useAsyncAction'

const saveComment = (execution, comment, token) =>
  request
    .put(`/api/processes/executions/${execution._id}/comments/${comment._id}`)
    .set({ 'x-access-token': token })
    .send({ text: comment.text })

const EditCommentForm = ({ execution, comment, onCancel, currentUser }) => {
  const [commentText, setCommentText] = useState(comment.text)
  return (
    <form
      className="update-comment"
      onSubmit={(e) => {
        e.preventDefault()
      }}
    >
      <FormGroup>
        <InputGroup>
          <FormControl
            type="text"
            autoFocus
            value={commentText}
            onChange={({ target: { value } }) => setCommentText(value)}
            onKeyPress={(target) => {
              if (target.key === 'Enter') {
                saveComment(
                  execution,
                  { ...comment, text: commentText },
                  currentUser.token,
                ).then(() => onCancel())
              }
            }}
          />
          <Button
            className="submit-button"
            variant="primary"
            onClick={() => {
              saveComment(
                execution,
                { ...comment, text: commentText },
                currentUser.token,
              ).then(() => onCancel())
            }}
          >
            Save
          </Button>
          <Button className="cancel-button" variant="danger" onClick={onCancel}>
            Cancel
          </Button>
        </InputGroup>
      </FormGroup>
    </form>
  )
}

EditCommentForm.propTypes = {
  currentUser: PropTypes.shape().isRequired,
  execution: PropTypes.shape().isRequired,
  comment: PropTypes.shape().isRequired,
  onCancel: PropTypes.func.isRequired,
}

const ShowCommentEvent = ({ execution, comment, currentUser }) => {
  const [editComment, setEditComment] = useState(false)
  const commentUser = execution.users.find((a) => a._id === comment.user)
  const timestamp = new Date(comment.timestamp).getTime()
  const [, , , deleteComment] = useAsyncAction(() =>
    request
      .delete(
        `/api/processes/executions/${execution._id}/comments/${comment._id}`,
      )
      .set({ 'x-access-token': currentUser.token }),
  )
  const [, , , likeComment] = useAsyncAction(() => {
    const likes = [...comment.likes]
    return request
      .put(
        `/api/processes/executions/${execution._id}/comments/${comment._id}/like`,
      )
      .send({
        likes: includes(likes, currentUser._id)
          ? without(likes, currentUser._id)
          : [currentUser._id, ...likes],
      })
      .set({ 'x-access-token': currentUser.token })
  })

  return (
    <TrundleRow
      className="timeline-event"
      prefix={[
        <div className="timeline-timestamp">
          <div>
            {timestamp && DateHelper.formatDateOnly(timestamp, 'M/d/yy')}
          </div>
          <div>{timestamp && DateHelper.formatTime(timestamp)}</div>
        </div>,
        <TimelineMarker icon="comment" />,
      ]}
      content={
        <h4 className="timeline-title">{`Comment by ${commentUser.name}`}</h4>
      }
      suffix={[<UserThumbnail user={commentUser} inline popover />]}
      actions={[
        {
          icon: 'thumbs-up',
          active: includes(comment.likes, currentUser._id),
          badge: comment.likes.length,
          onClick: () => {
            likeComment()
          },
        },
        {
          icon: 'edit',
          onClick: () => {
            setEditComment(!editComment)
          },
        },
        { icon: 'trash', onClick: () => deleteComment() },
      ]}
      alwaysOn={
        <div className="indented">
          {!editComment && <ExecutionMarkdown source={comment.text} />}
          {editComment && (
            <EditCommentForm
              execution={execution}
              comment={comment}
              currentUser={currentUser}
              onCancel={() => setEditComment(false)}
            />
          )}
          <div className="clearfix">
            {comment.files &&
              comment.files.length > 0 &&
              comment.files.map((file) => (
                <Col xs={12} sm={6} md={4} key={file.name}>
                  <FileThumbnail file={file} />
                </Col>
              ))}
          </div>
        </div>
      }
    />
  )
}

ShowCommentEvent.propTypes = {
  currentUser: PropTypes.shape().isRequired,
  execution: PropTypes.shape().isRequired,
  comment: PropTypes.shape().isRequired,
}

export default ShowCommentEvent
