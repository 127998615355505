import React from 'react';
import { Icon } from 'lib/acromyrmex';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ShowDuoMfaForm from '../../modules/user/ShowDuoMfaForm';
import MarketingFormLayout from '../../modules/shared/Layout/MarketingFormLayout';
import { Alert } from '../../utility/UiComponents';
import { redirectAfterLogin } from '../../actions/users';

function ShowSetupMultiFactorAuth() {
  const currentUser = useSelector((state) => state.users.currentUser);
  const navigate = useNavigate();

  return (
    <MarketingFormLayout
      explanation={(
        <div>
          <h4>Setup Instructions</h4>
          <ol>
            <li>
              Log in to your Duo account (If you do not have one,
              {' '}
              <a
                href="https://duo.com/msp-program-contuit"
                target="_blank"
                rel="noopener noreferrer"
              >
                sign up here
              </a>
              {' '}
              to get your free MSP NFR licenses)
            </li>
            <li>
              Log in to the
              {' '}
              <a href="https://admin.duosecurity.com/" target="_blank" rel="noopener noreferrer">
                Duo Admin Panel
              </a>
              {' '}
              and navigate to your
              {' '}
              <strong>internal use account</strong>
              . Within your internal use
              account, navigate to
              {' '}
              <strong>Applications</strong>
              .
            </li>
            <li>
              Click
              {' '}
              <strong>Protect an Application</strong>
              {' '}
              and locate
              {' '}
              <strong>Web SDK</strong>
              {' '}
              in
              the applications list. Click
              {' '}
              <strong>Protect this Application</strong>
              {' '}
              to get your
              {' '}
              <strong>integration key, secret key, </strong>
              and
              <strong>API hostname.</strong>
              {' '}
              (See
              {' '}
              <a
                href="https://duo.com/docs/getting-started"
                target="_blank"
                rel="noopener noreferrer"
              >
                Getting Started
              </a>
              {' '}
              for help)
            </li>
            <li>
              In the protected application&apos;s settings find
              {' '}
              <strong>Name</strong>
              {' '}
              and set it
              to
              {' '}
              <strong>Contuit MFA</strong>
              .
            </li>
            <li>
              In the protected application&apos;s settings find
              {' '}
              <strong>Username normalization</strong>
              {' '}
              and set it to
              <strong>Simple</strong>
            </li>
            <li>
              Save the application settings and enter the proper information in the fields to the
              right.
            </li>
          </ol>
        </div>
      )}
      content={(
        <div>
          <div style={{ padding: '15px', paddingBottom: '0' }}>
            <Alert info>
              <p>
                <Icon info />
                {' '}
                We take security very seriously at Contuit. To secure your
                account, we require that you use Duo for multi factor authentication. If you
                aren&apos;t already a Duo MSP partner,
                {' '}
                <a
                  href="https://duo.com/msp-program-contuit"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  sign up here
                </a>
                {' '}
                to get your first 50 licenses for Free AND start at the 20% off tier! If
                you&apos;d like you can
                {' '}
                <a
                  href="#"
                  onClick={() => {
                    redirectAfterLogin(currentUser, null, navigate);
                  }}
                >
                  skip this for now
                </a>
                , but this will not always be an option.
              </p>
            </Alert>
          </div>
          <ShowDuoMfaForm forwardUrl="/login" navigate={navigate} currentUser={currentUser} />
        </div>
      )}
    />
  );
}

export default ShowSetupMultiFactorAuth;
