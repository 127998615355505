import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Loading } from 'lib/acromyrmex'
import { SubmissionError } from 'redux-form'
import { useParams } from 'react-router'

import { Row, Alert } from '../../utility/UiComponents'
import ProcessForm from './ProcessForm/ProcessForm'
import {
  loadProcess as loadProcessAction,
  loadProcessValidation as loadProcessValidationAction,
  updateProcess as updateProcessAction,
} from '../../actions/processes'

class EditProcess extends React.Component {
  constructor(props) {
    super(props)

    this.onFormSubmit = this.onFormSubmit.bind(this)
  }

  componentDidMount() {
    const { processId, loadProcess, loadProcessValidation } = this.props

    if (processId) {
      loadProcess(processId)
      loadProcessValidation(processId)
    }
  }

  componentDidUpdate() {
    const { processObject } = this.props

    if (processObject) {
      document.title = `Contuit | Edit ${processObject.name}`
    }
  }

  componentWillUnmount() {
    document.title = 'Contuit'
  }

  onFormSubmit(options = {}) {
    const { processId, formValues, updateProcess } = this.props

    formValues.isDraft = options.isDraft === true
    formValues.isActive = options.isActive === true
    formValues.isArchived = options.isArchived === true

    return updateProcess(processId, formValues).then((err) => {
      if (err) {
        const newErr = new SubmissionError({
          _error: 'invalid process',
          ...err,
        })
        throw newErr
      }
    })
  }

  render() {
    const { isLoading, processObject, routes } = this.props

    if (isLoading) {
      return (
        <div className="container" style={{ height: '100%' }}>
          <div className="box-wrapper">
            <Row className="content-row box-rest" style={{ marginTop: '10px' }}>
              <Alert variant="info" className="pull-left">
                <Loading /> Loading Process Form...
              </Alert>
            </Row>
          </div>
        </div>
      )
    }

    return isLoading ? (
      <Loading />
    ) : (
      <ProcessForm
        initialValues={processObject}
        onSubmit={this.onFormSubmit}
        edit
        routes={routes}
      />
    )
  }
}

// Define property types
EditProcess.propTypes = {
  processId: PropTypes.string.isRequired,
  processObject: PropTypes.shape(),
  isLoading: PropTypes.bool.isRequired,
  loadProcess: PropTypes.func.isRequired,
  updateProcess: PropTypes.func.isRequired,
  loadProcessValidation: PropTypes.func.isRequired,
  routes: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  formValues: PropTypes.shape(),
}

EditProcess.defaultProps = {
  processObject: null,
  formValues: null,
}

const mapStateToProps = (state, props) => {
  return {
    processObject: state.processes.process.value,
    isLoading: state.processes.process.isLoading,
    formValues: state.form.processForm ? state.form.processForm.values : {},
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      loadProcess: loadProcessAction,
      updateProcess: updateProcessAction,
      loadProcessValidation: loadProcessValidationAction,
    },
    dispatch,
  )

const EditProcessWithState = connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditProcess)

const EditProcessWrapper = (props) => {
  const { processId } = useParams()

  return <EditProcessWithState processId={processId} {...props} />
}

export default EditProcessWrapper
