import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Row } from '../../utility/UiComponents';
import CreateTenantEmailForm from './CreateTenantEmailForm';
import CreateTenantCodeForm from './CreateTenantCodeForm';
import CreateTenantUserForm from './CreateTenantUserForm';
import {
  verifyOnboardingEmail as verifyOnboardingEmailAction,
  verifyOnboardingCode as verifyOnboardingCodeAction,
  createFirstUser as createFirstUserAction,
} from '../../actions/onboarding';

class ShowOnboarding extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: null,
    };

    this.onEmailFormSubmit = this.onEmailFormSubmit.bind(this);
    this.onUserFormSubmit = this.onUserFormSubmit.bind(this);
    this.onCodeFormSubmit = this.onCodeFormSubmit.bind(this);
  }

  onEmailFormSubmit() {
    const { emailFormValues, verifyOnboardingEmail } = this.props;

    verifyOnboardingEmail(emailFormValues.email);
    this.setState({ email: emailFormValues.email });
  }

  onCodeFormSubmit() {
    const { codeFormValues, verifyOnboardingCode } = this.props;
    const { email } = this.state;

    verifyOnboardingCode(email, codeFormValues.code);
  }

  onUserFormSubmit() {
    const { userFormValues, createFirstUser } = this.props;

    createFirstUser(userFormValues);
  }

  render() {
    const { stage } = this.props;
    const { email } = this.state;

    let form = null;
    switch (stage) {
      case 1:
        form = <CreateTenantEmailForm onSubmit={this.onEmailFormSubmit} />;
        break;
      case 2:
        form = <CreateTenantCodeForm onSubmit={this.onCodeFormSubmit} />;
        break;
      case 3:
        form = (
          <CreateTenantUserForm
            onSubmit={this.onUserFormSubmit}
            initialValues={{ user: { email } }}
          />
        );
        break;
      default:
        form = 'Oops! Something went wrong.';
        break;
    }

    return (
      <div>
        <div className="container" style={{ height: '100%' }}>
          <div className="box-wrapper">
            <Row className="content-row">
              <h3>Create new Contuit account</h3>
            </Row>
            <Row className="content-row">{form}</Row>
          </div>
        </div>
      </div>
    );
  }
}

// Define property types
ShowOnboarding.propTypes = {
  stage: PropTypes.number,
  emailFormValues: PropTypes.shape(),
  verifyOnboardingEmail: PropTypes.func.isRequired,
  codeFormValues: PropTypes.shape(),
  verifyOnboardingCode: PropTypes.func.isRequired,
  userFormValues: PropTypes.shape(),
  createFirstUser: PropTypes.func.isRequired,
};

ShowOnboarding.defaultProps = {
  stage: 1,
  emailFormValues: {},
  codeFormValues: {},
  userFormValues: {},
};

const mapStateToProps = (state) => ({
  stage: state.onboarding.stage,
  emailFormValues: state.form.onboardingEmailForm ? state.form.onboardingEmailForm.values : {},
  codeFormValues: state.form.onboardingCodeForm ? state.form.onboardingCodeForm.values : {},
  userFormValues: state.form.onboardingUserForm ? state.form.onboardingUserForm.values : {},
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    verifyOnboardingEmail: verifyOnboardingEmailAction,
    verifyOnboardingCode: verifyOnboardingCodeAction,
    createFirstUser: createFirstUserAction,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(ShowOnboarding);
