import React from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { TextInput } from 'lib/acromyrmex';
import { Row, Col, Button } from '../../../utility/UiComponents';
import EndpointInput from './EndpointInput';
import { requiredValidator } from '../../../utility/formValidators';

class OAuthCredentialForm extends React.Component {
  render() {
    const {
      currentUser,
      display,
      integrationKey,
      name,
      formData,
      dynamicEndpoint,
      displayKeys,
      maskedKeys
    } = this.props;

    

    const enabledIntegrationId = formData._id ? `&enabledIntegrationId=${formData._id}` : '';

    return (
      <Col xs={12}>
        <Row>{dynamicEndpoint && <EndpointInput />}</Row>
        {displayKeys.map(key => (
          <Field
            key={key.key}
            name={`authenticationOptions.${key.key}`}
            validate={requiredValidator}
            component={TextInput}
            label={key.display}
            help={key.help}
            type="text"
          />
        ))}
        {maskedKeys.map(key => (
          <Field
            key={key.key}
            name={`authenticationOptions.${key.key}`}
            validate={requiredValidator}
            component={TextInput}
            label={key.display}
            help={key.help}
            type="password"
          />
        ))}
        <Button
          href={`/api/integrations/auth/${integrationKey}?token=${
            currentUser.token
          }&name=${name}&completedMessage=true&clientId=${get(
            formData,
            'authenticationOptions.clientId'
          )}&appId=${get(formData, 'authenticationOptions.appId')}&clientSecret=${get(
            formData,
            'authenticationOptions.clientSecret'
          )}&endpoint=${formData.endpoint}&defaultIntegration=${
            formData.defaultIntegration
          }${enabledIntegrationId}`}
          target="_blank"
          className="pull-right"
          disabled={!formData.name || (dynamicEndpoint && !formData.endpoint)}
        >
          Authorize with {display}
        </Button>
      </Col>
    );
  }
}

OAuthCredentialForm.propTypes = {
  dynamicEndpoint: PropTypes.bool,
  displayKeys: PropTypes.arrayOf(PropTypes.shape()),
  maskedKeys: PropTypes.arrayOf(PropTypes.shape()),
  formData: PropTypes.shape(),
  currentUser: PropTypes.shape().isRequired,
  integrationKey: PropTypes.string.isRequired,
  display: PropTypes.string.isRequired,
  name: PropTypes.string
};

OAuthCredentialForm.defaultProps = {
  formData: {},
  name: undefined,
  dynamicEndpoint: false,
  displayKeys: [],
  maskedKeys: []
};

export default OAuthCredentialForm;
