import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PageLayout from '../shared/Layout/PageLayout';
import DeleteTenantSection from './DeleteTenantSection';
import EditTenantNameSection from './EditTenantNameSection';

class ShowSystemSettings extends Component {
  static propTypes = {
    currentUser: PropTypes.shape().isRequired
  };

  static defaultProps = {};

  constructor(props) {
    super(props);
    this.state = {
      tenant: this.props.currentUser.tenants.find(t => t._id === this.props.currentUser.contuitId)
    };
  }

  handleDeleteSuccess = () => {
    window.location.href = '/dashboards/personal';
  };

  handleUpdateTenant = newTenantName => {
    this.setState(prevState => ({
      tenant: {
        ...prevState.tenant,
        name: newTenantName
      }
    }));
  };

  render() {
    const {
      currentUser: { token }
    } = this.props;
    const { tenant } = this.state;

    return (
      <PageLayout
        header="System Settings"
        content={
          <div style={{ margin: '5px 20px' }}>
            <EditTenantNameSection
              tenant={tenant}
              token={token}
              onUpdateTenant={this.handleUpdateTenant}
            />
            <hr />
            <DeleteTenantSection
              tenant={tenant}
              token={token}
              onDeleteSuccess={this.handleDeleteSuccess}
            />
          </div>
        }
      />
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.users.currentUser
});
const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ShowSystemSettings);
