const LOG_INITIAL = {
  isLoading: false,
  stage: 1,
}

export default (state = LOG_INITIAL, action = {}) => {
  switch (action.type) {
    case 'ONBOARDING_LOADING':
      return Object.assign({}, state, {
        isLoading: action.loading,
      })
    case 'ONBOARDING_STAGE':
      return Object.assign({}, state, {
        stage: action.stage,
      })
    default:
      return state
  }
}
