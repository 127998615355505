import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, FormControl } from 'react-bootstrap';
import { toast } from 'react-toastify';

const UpdateTenantModal = ({ show, onHide, onConfirm, tenant }) => {
  const [newTenantName, setNewTenantName] = useState(tenant.name);

  const handleConfirm = () => {
    if (newTenantName.trim() === '') {
      toast.error('Tenant name cannot be empty.');
    } else {
      onConfirm(newTenantName);
    }
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Update Tenant Name</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Enter the new name for the tenant: <strong>{tenant.name}</strong>
        </p>
        <FormControl
          type="text"
          value={newTenantName}
          onChange={e => setNewTenantName(e.target.value)}
          placeholder="Enter new tenant name"
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Cancel
        </Button>
        <Button
          variant="success"
          onClick={handleConfirm}
          disabled={newTenantName === tenant.name || newTenantName.trim() === ''}
        >
          Update
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

UpdateTenantModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  tenant: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  }).isRequired
};

export default UpdateTenantModal;
