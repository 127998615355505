import React from 'react';
import PropTypes from 'prop-types';
import { FieldArray } from 'redux-form';
import {
  Col, Card, Accordion,
} from '../../../utility/UiComponents';
import PanelTitle from './PanelTitle';

function PanelTableForm(props) {
  const {
    xs,
    showHeader,
    headerComponent,
    fieldArrayComponent,
    help,
    title,
    fieldArrayProps,
    name,
  } = props;

  return (
    <Col xs={xs} className="panel-group">
      <Accordion defaultActiveKey="1">
        <Card>
          <Accordion.Header eventKey="1">
            <PanelTitle title={title} help={help} />
          </Accordion.Header>
          <Accordion.Collapse eventKey="1">
            <Card.Body>
              <div>
                {showHeader && headerComponent}
                <FieldArray name={name} component={fieldArrayComponent} props={fieldArrayProps} />
              </div>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </Col>
  );
}

PanelTableForm.propTypes = {
  xs: PropTypes.number,
  title: PropTypes.string,
  name: PropTypes.string.isRequired,
  help: PropTypes.string,
  showHeader: PropTypes.bool,
  headerComponent: PropTypes.node.isRequired,
  fieldArrayComponent: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  fieldArrayProps: PropTypes.shape(),
};

PanelTableForm.defaultProps = {
  xs: 12,
  title: '',
  help: '',
  showHeader: true,
  fieldArrayProps: {},
};

export default PanelTableForm;
