import React from 'react';
import { Field } from 'redux-form';
import { TextInput } from 'lib/acromyrmex';
import { Col } from '../../../utility/UiComponents';
import { requiredValidator } from '../../../utility/formValidators';
import EndpointInput from './EndpointInput';

const ConnectwiseCredentialForm = () => (
  <div>
    <EndpointInput />
    <Col xs={12}>
      <Field
        name="authenticationOptions.companyId"
        validate={requiredValidator}
        component={TextInput}
        label="Company ID"
        help="This user will be used for system level API calls. We recommend setting up a specific user for Contuit."
        type="text"
      />
      <Field
        name="authenticationOptions.publicKey"
        validate={requiredValidator}
        component={TextInput}
        label="Public Key"
        help="This user will be used for system level API calls. We recommend setting up a specific user for Contuit."
        type="text"
      />
      <Field
        name="authenticationOptions.privateKey"
        validate={requiredValidator}
        component={TextInput}
        label="Private Key"
        type="password"
      />
    </Col>
  </div>
);

ConnectwiseCredentialForm.propTypes = {};

ConnectwiseCredentialForm.defaultProps = {};

export default ConnectwiseCredentialForm;
