/* eslint-disable class-methods-use-this */
import {VAULT_DELETE_STEP_TYPE} from '../../../constants';
import VaultDeleteStepOptions from './components/stepOptions/VaultDeleteStepOptions';
import BasicUiHelper from './BasicUiHelper';

class VaultDeleteUiHelper extends BasicUiHelper {
  regex() {
    return VAULT_DELETE_STEP_TYPE;
  }

  name() {
    return 'Vault Delete';
  }

  canTryAgain() {
    return true;
  }

  stepOptions() {
    return VaultDeleteStepOptions;
  }
}

export default VaultDeleteUiHelper;
