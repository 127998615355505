import _ from 'underscore';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ProcessServer } from '@contuit-otf/sdk';
import { Button } from '../../../utility/UiComponents';
import UserThumbnail from '../../user/UserThumbnail';
import AssignAdmin from './AssignAdmin';
import request from '../../../utility/request';
import { getProjectedAssignee } from '../../../shared-helpers/execution';
import ShowProcessStep from '../ShowProcess/ShowProcessStep';

const processServer = new ProcessServer('/api/processes', request);

class ExecutionOverviewStep extends Component {
  onAssignUser = (user) => {
    const {
      execution,
      currentUser: { token },
      step,
    } = this.props;

    processServer
      .updateExecution({
        token,
        execution: {
          ...execution,
          assignedUsers: { ...execution.assignedUsers, [step._id]: user._id },
        },
      })
      .then(() => toast.success('Successfully set assignee'))
      .catch(() => toast.error('Error setting assignee'));
  };

  render() {
    const { steps, step, stepIndex, users, execution } = this.props;
    const assignee = getProjectedAssignee(execution, stepIndex);

    return (
      <div
        key={step._id}
        className="overview-step clearfix"
        style={{ display: 'flex' }}
      >
        {assignee && (
          <div>
            <AssignAdmin
              userList={users}
              onAssignUser={this.onAssignUser}
              placement="bottom"
            >
              <Button variant="link" size="xs">
                <UserThumbnail user={assignee} radius="30px" />
              </Button>
            </AssignAdmin>
          </div>
        )}
        <div style={{ paddingTop: '5px', flex: 1 }}>
          <ShowProcessStep
            processStep={step}
            steps={steps}
            index={stepIndex}
            key={step._id}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  users: state.users.users.rows,
  currentUser: state.users.currentUser,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

ExecutionOverviewStep.propTypes = {
  execution: PropTypes.shape().isRequired,
  steps: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  step: PropTypes.shape().isRequired,
  stepIndex: PropTypes.number.isRequired,

  // from redux
  users: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  currentUser: PropTypes.shape().isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ExecutionOverviewStep);
