import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field, getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import { Form } from 'react-bootstrap';
import { TextInput, LoadingButton } from 'lib/acromyrmex';

const validate = (email) => {
  if (!email) {
    return false;
  }
  // verifies @ and .
  const regex = /.+@.+\..+/i;
  if (!regex.exec(email)) {
    return false;
  }

  return true;
};

function InviteUserForm(props) {
  const {
    handleSubmit, onSubmit, isCreating, formValues,
  } = props;

  const { email } = formValues;

  return (
    <Form inline onSubmit={handleSubmit(onSubmit)}>
      <Field
        name="email"
        component={TextInput}
        vertical
        label="Invite User"
        type="email"
        addonBefore="Email:"
        addonCustomAfter={(
          <LoadingButton
            className="input-group-btn"
            variant="primary"
            label="Invite"
            loading={isCreating}
            disabled={!validate(email)}
            loadingLabel="Inviting User"
            type="submit"
          />
        )}
      />
    </Form>
  );
}

// Define property types
InviteUserForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  formValues: PropTypes.shape(),
  isCreating: PropTypes.bool.isRequired,
};

InviteUserForm.defaultProps = {
  formValues: {},
};

// for the form
const mapStateToProps = (state) => ({
  formValues: getFormValues('inviteUserForm')(state),
  user: state.users.user,
  isCreating: state.users.isCreating,
});

export default connect(mapStateToProps)(
  reduxForm({
    form: 'inviteUserForm',
  })(InviteUserForm),
);
