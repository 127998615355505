import { MS_TEAMS_ACTION_LISTENER_STEP_TYPE } from '../../../constants'
import ActionListenerUiHelper from './_ActionListenerUiHelper'

/**
 * ActionListenerUiHelper brings in:
 *  - stepOptions
 *  - getOutputKeys
 *  - name based on the integrationTitle method
 */
class MsTeamsActionListenerUiHelper extends ActionListenerUiHelper {
  integrationTitle() {
    return 'MS Teams'
  }

  integrationName() {
    return 'msteams'
  }

  regex() {
    return MS_TEAMS_ACTION_LISTENER_STEP_TYPE
  }
}

export default MsTeamsActionListenerUiHelper
