import _ from 'underscore';
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { ProcessServer } from '@contuit-otf/sdk';
import { Field } from 'redux-form';
import { SelectInput, Loading, CheckboxInput, Icon } from 'lib/acromyrmex';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Accordion,
  Alert,
} from '../../../../../utility/UiComponents';
import FillModal from './FillModal';
import request from '../../../../../utility/request';

const processServer = new ProcessServer('/api/processes', request);

// eslint-disable-next-line
class ExecuteProcessStepOptions extends React.Component {
  static getquery() {
    return { showDrafts: false, showActive: true, showArchived: false };
  }

  static propTypes = {
    fieldText: PropTypes.string.isRequired,
    processStep: PropTypes.shape().isRequired,
    change: PropTypes.func.isRequired,

    // specific to this step type
    outputOptions: PropTypes.arrayOf(PropTypes.shape()).isRequired,

    // from redux
    currentUser: PropTypes.shape({
      token: PropTypes.string.isRequired,
    }).isRequired,
  };

  static defaultProps = {};

  state = {
    processes: null,
    processesLoading: false,
    processesLoadError: '',
  };

  componentWillMount() {
    this.loadProcesses();
  }

  loadProcesses() {
    const {
      currentUser: { token },
    } = this.props;

    this.setState({ processesLoading: true });
    processServer
      .findProcesses({
        token,
        query: ExecuteProcessStepOptions.getquery(),
      })
      .then((data) => {
        // also comes with totalItemCount
        this.setState({ processes: data });
      })
      .catch(() => {
        this.setState({
          processesLoadError: 'Unknown error loading processes.',
        });
      })
      .then(() => {
        this.setState({ processesLoading: false });
      });
  }

  render() {
    const { fieldText, processStep, change, outputOptions } = this.props;

    const { processes, processesLoading, processesLoadError } = this.state;
    const selectedProcess =
      processes &&
      processes.find((p) => p._id === processStep.stepOptions.processId);

    return (
      <Row style={{ marginBottom: 10 }}>
        <Accordion defaultActiveKey="1">
          <Accordion.Item eventKey="1">
            <Accordion.Header>{`${processStep.name || ''} Step Options`}</Accordion.Header>
            <Accordion.Body>
              <div
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  alignItems: 'center',
                  marginBottom: 10,
                  gap: 15,
                }}
              >
                <Col xs={8} style={{ flex: 1 }}>
                  {processesLoading && <Loading text="loading processes" />}
                  {processesLoadError && (
                    <Alert variant="error" className="pull-left indented">
                      <Icon error />
                      {` Error loading processes: ${processesLoadError}`}
                    </Alert>
                  )}
                  {!processesLoading && !processesLoadError && (
                    <Field
                      name={`${fieldText}.stepOptions.processId`}
                      component={SelectInput}
                      label="Process"
                      options={_.map(processes, (proc) => ({
                        id: proc._id,
                        name: proc.name,
                      }))}
                      addonAfter={
                        selectedProcess && (
                          <Link
                            to={{
                              pathname: `/processes/${selectedProcess._id}/edit`,
                            }}
                            target="_blank"
                          >
                            <i className="fa fa-external-link" />
                          </Link>
                        )
                      }
                    />
                  )}
                </Col>
                <Col xs={4} style={{ flex: 1 }}>
                  <FillModal
                    fieldText={fieldText}
                    change={change}
                    processStep={processStep}
                    outputOptions={outputOptions}
                    processes={processes}
                  />
                </Col>
              </div>

              <Col xs={12} style={{ marginBottom: 10 }}>
                <Field
                  name={`${fieldText}.stepOptions.dontWait`}
                  component={CheckboxInput}
                  label="Don't wait for completion"
                />
              </Col>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Row>
    );
  }
}

const mapStateToProps = (state) => ({
  currentUser: state.users.currentUser,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ExecuteProcessStepOptions);
