import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from '../../../../../../utility/UiComponents';
import VaultSaveField from './vaultSaveField';
import AddButton from '../../../../../shared/form/AddButton';

const VaultSaveFieldArray = ({ fields, outputOptions, change }) => {
  const rows = fields.map((member, index) => (
    <VaultSaveField
      fieldName={member}
      fields={fields}
      index={index}
      key={member}
      outputOptions={outputOptions}
      change={change}
    />
  ));

  return [
    ...rows,
    <Row className="step-option-buttons">
      <Col xs={12}>
        <AddButton size="sm"
          onClick={() => {
            fields.push({});
          }}
          label="Add Field"
        />
      </Col>
    </Row>
  ];
};

VaultSaveFieldArray.propTypes = {
  fields: PropTypes.shape().isRequired,
  outputOptions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  change: PropTypes.func.isRequired
};

export default VaultSaveFieldArray;
