import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Modal, Form, FormGroup, Button, FormCheck } from 'react-bootstrap'
import without from 'lodash/without'

import PageLayout from '../shared/Layout/PageLayout'
import ImportCommunityIntegrations from '../marketplace/ImportCommunityIntegrations'

class ListBundles extends Component {
  constructor() {
    super()

    const duplicateProcessActionOptions = [
      {
        value: 'modify-previous',
        label: 'Modify previous process name and create new process',
      },
      {
        value: 'override-previous',
        label: 'Override previous process with new process',
      },
      {
        value: 'archive-previous',
        label: 'Archive previous process and create new process',
      },
    ]

    this.state = {
      showDialog: false,
      duplicateProcessAction: duplicateProcessActionOptions[0].value,
      solutionGroup: null,
      integrations: [],
    }

    this.duplicateProcessActionOptions = duplicateProcessActionOptions

    this.fileInputRef = React.createRef()
  }

  handleSelectBundle = (e) => {
    if (e.target) {
      const bundle = e.target.files[0]
      const reader = new FileReader()

      reader.onload = (event) => {
        let uploadedBundle = JSON.parse(event.target.result)
        uploadedBundle = uploadedBundle.noSolution
          ? uploadedBundle
          : { ...uploadedBundle, noSolution: true }

        this.setState({ solutionGroup: uploadedBundle })
        this.handleDialogClose()
      }
      reader.readAsText(bundle)
    }
  }

  handleDialogOpen = () => {
    this.setState({ showDialog: true })
  }

  handleDialogClose = () => {
    this.setState({ showDialog: false })
  }

  handleDuplicateProcessActionChange = (e) => {
    this.setState({ duplicateProcessAction: e.target.value })
  }

  handleOpenFileHandler = () => {
    this.fileInputRef.current.click()
  }

  onIntegrationAdded = (enabledIntegration, bundleIntegrationId) => {
    const { integrations } = this.state

    const existing = integrations.find((i) => {
      if (bundleIntegrationId) {
        return (
          i.importKey === enabledIntegration.integrationName &&
          i.bundleIntegrationId === bundleIntegrationId
        )
      }
      return i.importKey === enabledIntegration.integrationName
    })

    if (enabledIntegration._id === '$c_create') {
      if (existing) {
        this.setState({ integrations: without(integrations, existing) })
      }

      return
    }

    if (existing) {
      return
    }

    if (bundleIntegrationId) {
      return this.setState({
        integrations: [
          ...integrations,
          {
            importKey: enabledIntegration.integrationName,
            integrationId: enabledIntegration._id,
            bundleIntegrationId,
          },
        ],
      })
    }

    this.setState({
      integrations: [
        ...integrations,
        {
          importKey: enabledIntegration.integrationName,
          integrationId: enabledIntegration._id,
        },
      ],
    })
  }

  renderHeaderButtons = () => {
    const { solutionGroup } = this.state

    if (!solutionGroup) {
      return (
        <div style={{ height: '100%' }}>
          <label
            htmlFor="uploadBundle"
            className="pull-right"
            style={{
              padding: '5px 10px',
              fontSize: '12px',
              lineHeight: 1.5,
              borderRadius: '3px',
              color: '#fff',
              background: '#5cb85c',
              borderColor: '#4cae4c',
              cursor: 'pointer',
            }}
          >
            <input
              id="uploadBundle"
              type="button"
              onClick={this.handleDialogOpen}
              style={{
                display: 'none',
              }}
            />
            <i className="fa fa-plus" style={{ marginRight: '3px' }} />
            Select Bundle
          </label>
        </div>
      )
    }
  }

  renderModalBody = () => {
    const { duplicateProcessAction } = this.state

    const renderRadioButtons = () =>
      this.duplicateProcessActionOptions.map((option) => (
        <FormCheck
          type="radio"
          name="duplicateProcessAction"
          value={option.value}
          onChange={this.handleDuplicateProcessActionChange}
          checked={duplicateProcessAction === option.value}
          label={option.label}
        />
      ))

    return (
      <Modal.Body>
        <Form>
          <FormGroup style={{ fontSize: '1.2rem' }}>
            <Form>
              <FormGroup style={{ fontSize: '1.2rem' }}>
                {renderRadioButtons()}
              </FormGroup>
            </Form>
          </FormGroup>
        </Form>
      </Modal.Body>
    )
  }

  renderModalFooter = () => (
    <Modal.Footer>
      <Button variant="secondary" onClick={this.handleDialogClose}>
        Cancel
      </Button>
      <Button
          variant="primary"
          onClick={this.handleOpenFileHandler}
          style={{
            marginLeft: '0.5rem',
            color: '#fff',
            background: '#5cb85c',
            borderColor: '#4cae4c',
          }}
        >
          <i className="fa fa-plus" style={{ marginRight: '3px' }} /> Upload
          <input
            id="uploadBundle"
            type="file"
            onChange={this.handleSelectBundle}
            accept="application/JSON"
            style={{ display: 'none' }}
            ref={this.fileInputRef}
          />
        </Button>
    </Modal.Footer>
  )

  render() {
    const { currentUser, routes } = this.props
    const { showDialog, duplicateProcessAction, solutionGroup, integrations } =
      this.state

    return (
      <PageLayout
        routes={routes}
        currentUser={currentUser}
        header="AD HOC Bundles"
        headerButtons={this.renderHeaderButtons()}
        noContentPadding
        content={
          <div>
            <Modal show={showDialog} onHide={this.handleDialogClose}>
              <Modal.Header closeButton>
                <Modal.Title>
                  How would you like us to handle duplicate processes in your
                  bundle with previous Contuit processes?
                </Modal.Title>
              </Modal.Header>
              {this.renderModalBody()}
              {this.renderModalFooter()}
            </Modal>
            {solutionGroup && (
              <ImportCommunityIntegrations
                addedIntegrations={integrations}
                onIntegrationEnabled={this.onIntegrationAdded}
                solutionGroup={solutionGroup}
                duplicateProcessAction={duplicateProcessAction}
              />
            )}
          </div>
        }
      />
    )
  }
}

ListBundles.propTypes = {
  currentUser: PropTypes.shape().isRequired,
  routes: PropTypes.arrayOf(PropTypes.shape()).isRequired,
}

const mapStateToProps = (state) => ({
  currentUser: state.users.currentUser,
})

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(ListBundles)
