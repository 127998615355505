import each from 'lodash/each'
import find from 'lodash/find'

const integrations = [
  {
    key: 'autotask',
    id: '4a1b8d4f-8850-4f32-8f38-37c6a21d2a63',
    dynamicEndpoint: true,
    oauth: false,
    dynamicClientId: false,
    showKeys: [
      {
        key: 'UserName',
        display: 'Username',
        help: 'This user will be used for system level API calls. We recommend setting up a specific user for Contuit.',
      },
    ],
    maskKeys: [
      {
        key: 'Secret',
        display: 'Secret Key',
      },
      {
        key: 'ApiIntegrationCode',
        display: 'API Integration Code',
      },
    ],
    deleteKeys: [
      {
        key: 'Secret',
        display: 'Secret Key',
      },
      {
        key: 'ApiIntegrationCode',
        display: 'API Integration Code',
      },
    ],
  },
  {
    key: 'kaseya',
    defaultName: 'Kaseya',
    id: '0a596062-5cba-4a91-8b75-20db5f61cc82',
    dynamicEndpoint: true,
    oauth: true,
    dynamicClientId: true,
    showKeys: [],
    maskKeys: [
      { key: 'clientId', display: 'Client ID' },
      { key: 'clientSecret', display: 'Client Secret' },
    ],
    deleteKeys: [
      { key: 'clientId', display: 'Client ID' },
      { key: 'clientSecret', display: 'Client Secret' },
      { key: 'refreshToken', display: 'Refresh Token' },
    ],
  },
  {
    key: 'kaseya-bms',
    id: 'e093ec51-7df5-4d0d-9c1c-a36b34b4c2d7',
    defaultName: 'Kaseya BMS',
    dynamicEndpoint: true,
    showKeys: [
      {
        key: 'username',
        display: 'Username',
        help: 'This user will be used for system level API calls. We recommend setting up a specific user for Contuit.',
      },
      {
        key: 'tenant',
        display: 'Company Name',
      },
    ],
    maskKeys: [{ key: 'password', display: 'Password' }],
    deleteKeys: [{ key: 'password', display: 'Password' }],
  },
  {
    key: 'logic-monitor',
    id: '2d5b90ba-b86f-4142-a63e-d4753e1f27f0',
    defaultName: 'Logic Monitor',
    dynamicEndpoint: true,
    showKeys: [],
    maskKeys: [
      { key: 'accessId', display: 'Access Id' },
      { key: 'accessKey', display: 'Access Key' },
    ],
    deleteKeys: [
      { key: 'accessId', display: 'Access Id' },
      { key: 'accessKey', display: 'Access Key' },
    ],
  },
  {
    key: 'connectwise',
    id: 'da5380d5-ff35-4c98-b269-63e5cdfd8bde',
    defaultName: 'ConnectWise',
    dynamicEndpoint: true,
    showKeys: [{ key: 'companyId', display: 'Company ID' }],
    maskKeys: [
      { key: 'publicKey', display: 'Public Key' },
      { key: 'privateKey', display: 'Private Key' },
    ],
    deleteKeys: [
      { key: 'publicKey', display: 'Public Key' },
      { key: 'privateKey', display: 'Private Key' },
    ],
  },
  {
    key: 'cw-automate',
    id: 'eb3b3b49-ada5-4fc3-a162-bb6c8e936549',
    defaultName: 'ConnectWise Automate',
    dynamicEndpoint: true,
    showKeys: [
      {
        key: 'username',
        display: 'Username',
        help: 'This user will be used for system level API calls. We recommend setting up a specific user for Contuit.',
      },
    ],
    maskKeys: [{ key: 'password', display: 'Password' }],
    deleteKeys: [{ key: 'password', display: 'Password' }],
  },
  {
    key: 'confluence',
    id: '4cf99a1d-8212-478f-8292-a739be04e0f9',
    defaultName: 'Confluence',
    dynamicEndpoint: true,
    showKeys: [
      {
        key: 'username',
        display: 'Username',
        help: 'This user will be used for system level API calls. We recommend setting up a specific user for Contuit.',
      },
    ],
    maskKeys: [{ key: 'password', display: 'Password' }],
    deleteKeys: [{ key: 'password', display: 'Password' }],
  },
  {
    key: 'msgraph',
    id: '8e7bb368-4350-4f45-bf4b-1f936bcd7d0a',
    defaultName: 'MS Graph',
    showKeys: [],
    maskKeys: [],
    deleteKeys: [{ key: 'tenant', display: 'Tenant' }],
    oauth: true,
  },
  {
    key: 'netsapiens',
    id: 'a41fbea5-76cd-47be-88eb-3c8eaaba076c',
    defaultName: 'NetSapiens',
    showKeys: [],
    maskKeys: [
      { key: 'clientId', display: 'Client ID' },
      {
        key: 'clientSecret',
        display: 'Client Secret',
      },
      {
        key: 'username',
        display: 'Username',
      },
      {
        key: 'password',
        display: 'Password',
      },
    ],
    deleteKeys: [
      { key: 'client_id', display: 'Client ID' },
      {
        key: 'client_secret',
        display: 'Client Secret',
      },
      {
        key: 'username',
        display: 'Username',
      },
      {
        key: 'password',
        display: 'Password',
      },
    ],
    oauth: false,
  },
  {
    key: 'stripe',
    id: '196769ca-9e3c-4233-9d28-791f38adc28e',
    defaultName: 'Stripe',
    showKeys: [],
    maskKeys: [{ key: 'apiKey', display: 'API Key' }],
    deleteKeys: [{ key: 'apiKey', display: 'API Key' }],
  },
  {
    key: 'itglue',
    id: '27fbe8b6-a85a-4b19-af64-a3eafda04569',
    defaultName: 'IT Glue',
    showKeys: [],
    dynamicEndpoint: true,
    maskKeys: [{ key: 'apiKey', display: 'API Key' }],
    deleteKeys: [{ key: 'apiKey', display: 'API Key' }],
  },
  {
    key: 'hudu',
    id: '29028701f-e83f-4d15-8d24-53a9eafeb69c',
    defaultName: 'Hudu',
    showKeys: [],
    dynamicEndpoint: true,
    maskKeys: [{ key: 'apiKey', display: 'API Key' }],
    deleteKeys: [{ key: 'apiKey', display: 'API Key' }],
  },
  {
    key: 'azure',
    id: 'd5afca99-f873-4439-9c9e-d0d6778d77e8',
    defaultName: 'Azure',
    showKeys: [],
    maskKeys: [],
    deleteKeys: [{ key: 'tenant', display: 'Tenant' }],
    oauth: {
      clientId: '6d749c69-a7e4-4f5c-bc0a-77b15061b605',
      consentUrl: 'https://login.microsoftonline.com/common/adminconsent',
    },
  },
  {
    key: 'msteams',
    id: '284defc5-61bd-460a-96f9-0beb1d99b535',
    defaultName: 'MS Teams',
    showKeys: [{ key: 'clientId', display: 'Client ID' }],
    deleteKeys: [{ key: 'clientSecret', display: 'Client Secret' }],
  },
  {
    key: 'crushbank',
    id: 'f4a87d1e-2e22-4c1c-8b3e-1fabb06e5a9d',
    defaultName: 'CrushBank',
    showKeys: [{ key: 'clientId', display: 'Client ID' }],
    deleteKeys: [{ key: 'clientSecret', display: 'Client Secret' }],
  },
  {
    key: 'hubspot',
    id: 'c672b4bb-831f-54b0-a69a-43b97a5974b8',
    defaultName: 'HubSpot',
    dynamicEndpoint: true,
    showKeys: [],
    maskKeys: [{ key: 'accessToken', display: 'Access Token' }],
    deleteKeys: [{ key: 'accessToken', display: 'Access Token' }],
  },
  {
    key: 'slack',
    id: 'ce7cf289-85e9-4ac9-be2f-5c3d5d3d0370',
    defaultName: 'Slack',
    showKeys: [{ key: 'appId', display: 'App ID' }],
    maskKeys: [{ key: 'botToken', display: 'Bot Token' }],
    deleteKeys: [{ key: 'botToken', display: 'Bot Token' }],
  },
  {
    key: 'webex-teams',
    id: '343d210d-75fe-4cb6-9eaa-119b741dfcc8',
    defaultName: 'Webex Teams',
    showKeys: [{ key: 'appId', display: 'App Id' }],
    // showKeys: [],
    maskKeys: [{ key: 'accessToken', display: 'Access Token' }],
    deleteKeys: [{ key: 'accessToken', display: 'Access Token' }],
  },
  {
    key: 'salesforce',
    id: 'ce7cf289-85e9-4ac9-be2f-5c3d5d435',
    defaultName: 'Salesforce',
    showKeys: [],
    deleteKeys: [],
    maskKeys: [],
    oauth: true,
  },
  {
    key: 'liongard',
    id: '435aca87-4e3f-4484-ae6f-a602535a1a0e',
    defaultName: 'Liongard',
    dynamicEndpoint: true,
    showKeys: [{ key: 'accessKey', display: 'Access Key' }],
    maskKeys: [{ key: 'accessSecret', display: 'Access Secret' }],
    deleteKeys: [{ key: 'accessSecret', display: 'Access Secret' }],
  },
  {
    key: 'open-ai',
    id: 'ac62e3b8-8709-47c7-9a6b-ecfb4795b258',
    defaultName: 'Open AI',
    showKeys: [],
    maskKeys: [{ key: 'apiKey', display: 'API Key' }],
    deleteKeys: [{ key: 'apiKey', display: 'API Key' }],
  },
  {
    key: 'cisco-find-it',
    id: 'be6306f9-fc55-4ab1-b389-bf68def8de6d',
    defaultName: 'Cisco Find IT',
    dynamicEndpoint: true,
    showKeys: [],
    maskKeys: [],
    deleteKeys: [],
  },
  {
    key: 'find-it-2',
    id: 'ca79d792-063d-4b34-9600-0d37a98a8465',
    defaultName: 'Cisco Business Dashboard',
    dynamicEndpoint: true,
    showKeys: [],
    maskKeys: [
      { key: 'accessKey', display: 'Access Key' },
      { key: 'secretKey', display: 'Secret Key' },
    ],
    deleteKeys: [
      { key: 'accessKey', display: 'Access Key' },
      { key: 'secretKey', display: 'Secret Key' },
    ],
  },
  {
    key: 'umbrella',
    id: '6f7adb2d-09b3-4584-b604-18c1b1ac3e7f',
    defaultName: 'Umbrella',
    showKeys: [
      {
        key: 'mspId',
        display: 'MSP ID',
        help: 'This value can be found in the url of your umbrella portal.',
      },
    ],
    maskKeys: [
      { key: 'apiKey', display: 'API Key' },
      { key: 'apiSecret', display: 'API Secret' },
    ],
    deleteKeys: [
      { key: 'apiKey', display: 'API Key' },
      { key: 'apiSecret', display: 'API Secret' },
    ],
  },
  {
    key: 'stealthwatch',
    id: 'd2491a00-b612-4dad-a072-1d58813b6680',
    defaultName: 'StealthWatch',
    dynamicEndpoint: true,
    showKeys: [
      {
        key: 'username',
        display: 'Username',
        help: 'This user will be used for system level API calls. We recommend setting up a specific user for Contuit.',
      },
    ],
    maskKeys: [{ key: 'apiKey', display: 'API Key' }],
    deleteKeys: [{ key: 'apiKey', display: 'API Key' }],
  },
  {
    key: 'meraki',
    id: '71dc1c09-d3de-4470-b7c1-692ab8e0d8ab',
    defaultName: 'Meraki',
    dynamicEndpoint: true,
    showKeys: [],
    maskKeys: [
      {
        key: 'apiKey',
        display: 'API Key',
        help: 'This value can be obtained on your profile page from within Meraki.',
      },
    ],
    deleteKeys: [{ key: 'apiKey', display: 'API Key' }],
  },
  {
    key: 'amp',
    id: '304d94dd-5c12-4d43-92ac-36d5c318eaba',
    defaultName: 'Amp',
    showKeys: [{ key: 'clientId', display: 'Client ID' }],
    maskKeys: [{ key: 'apiKey', display: 'API Key' }],
    deleteKeys: [{ key: 'apiKey', display: 'API Key' }],
  },
  {
    key: 'duo',
    id: '501dcf2d-3c52-4c00-b97e-7ebb075e06f7',
    defaultName: 'Duo',
    retired: true,
    dynamicEndpoint: true,
    showKeys: [],
    maskKeys: [
      { key: 'integrationKey', display: 'Integration Key' },
      { key: 'secretKey', display: 'Secret Key' },
    ],
    deleteKeys: [
      { key: 'integrationKey', display: 'Integration Key' },
      { key: 'secretKey', display: 'Secret Key' },
    ],
  },
  {
    key: 'duo-accounts',
    id: 'a60604e4-0c1a-4168-9f48-01295ebf18d2',
    defaultName: 'Duo Accounts',
    dynamicEndpoint: true,
    showKeys: [],
    maskKeys: [
      { key: 'integrationKey', display: 'Integration Key' },
      { key: 'secretKey', display: 'Secret Key' },
    ],
    deleteKeys: [
      { key: 'integrationKey', display: 'Integration Key' },
      { key: 'secretKey', display: 'Secret Key' },
    ],
  },
  {
    key: 'ui-path',
    id: '2c20e68d-c58e-4d83-b691-3d9f440ce3c8',
    defaultName: 'UI Path',
    showKeys: [],
    deleteKeys: ['apiKey'],
  },
  {
    key: 'appian',
    id: '29cba36d-5731-47bb-af7c-054ac9c3e306',
    defaultName: 'Appian',
    showKeys: [],
    deleteKeys: ['apiKey'],
  },
]

const getIntegration = (keyOrId) =>
  integrations.find((i) => i.key === keyOrId || i.id === keyOrId)

const getFactory = (keyToReturn) => (keyOrId) => {
  const int = getIntegration(keyOrId)
  return int ? int[keyToReturn] : undefined
}

const getApiId = getFactory('id')
const getApiDefaultName = getFactory('defaultName')
const getKey = getFactory('key')
const getDeleteKeys = getFactory('deleteKeys')
const getShowKeys = getFactory('showKeys')
const getMaskKeys = getFactory('maskKeys')
const isDynamicEndpoint = getFactory('dynamicEndpoint')
const isDynamicClientId = getFactory('dynamicClientId')
const isOauth = !!getFactory('oauth')
const getOauth = getFactory('oauth')

const getSpec = (keyOrId) => {
  const int = getIntegration(keyOrId)
  return int ? int.api.GetSpec() : undefined
}

const getApiKeys = () => integrations.map((i) => i.key)
const getApiSpecs = () => integrations.map((i) => i.api.GetSpec())

const getOperation = (spec, operationId) => {
  let foundOperation = null

  each(spec.paths, (pathObj) => {
    each(pathObj, (actionObj) => {
      if (actionObj.operationId === operationId) {
        foundOperation = actionObj
      }
    })
  })

  each(spec['x-triggers'], (triggerObj) => {
    if (triggerObj.operationId === operationId) {
      foundOperation = triggerObj
    }
  })

  return foundOperation
}

const getSuccessResponseAttributes = (operation) => {
  const successResponse =
    operation.responses &&
    (operation.responses[200] || operation.responses[201])

  if (!successResponse) {
    return null
  }

  const { schema } = successResponse

  let result = null
  if (schema['x-result-content']) {
    result = schema
  } else {
    result = find(schema.properties, (val) => val['x-result-content'])
  }

  // go one level deeper
  if (!result) {
    Object.keys(schema.properties).forEach((propKey) => {
      if (result) return

      const prop = schema.properties[propKey]

      if (!prop.properties) return

      result = find(prop.properties, (val) => val['x-result-content'])
    })
  }

  let attrs = []
  if (!result) {
    return attrs
  }

  switch (result.type) {
    case 'array':
      attrs = {
        '.': {
          type: 'collection',
          display: 'data',
          properties:
            result.items.type === 'object'
              ? result.items.properties
              : {
                  '.': result.items,
                },
        },
      }
      break
    case 'object':
      attrs = result.properties
      break
    case 'file':
      attrs = result.properties
      break
    default:
      attrs = { '.': result }
      break
  }

  return attrs
}

// alias'
const getApiIdFromKey = getApiId

export {
  integrations,
  getApiIdFromKey,
  isDynamicEndpoint,
  isDynamicClientId,
  isOauth,
  getOauth,
  getKey,
  getApiId,
  getApiDefaultName,
  getShowKeys,
  getMaskKeys,
  getDeleteKeys,
  getSpec,
  getApiKeys,
  getApiSpecs,
  getOperation,
  getSuccessResponseAttributes,
  getIntegration,
}
