/* eslint-disable class-methods-use-this */
import React from 'react';
import map from 'lodash/map';
import get from 'lodash/get';
import { SelectInput, TemplateInput } from 'lib/acromyrmex';
import {SPECIAL_KASEYA_STEP_TYPE} from '../../../constants';
import BasicUiHelper from './BasicUiHelper';
import IntegrationSelect from './components/stepOptions/integration/IntegrationSelect';

class SpecialKaseyaUiHelper extends BasicUiHelper {
  regex() {
    return SPECIAL_KASEYA_STEP_TYPE;
  }

  name() {
    return 'Get Org Summary Data';
  }

  canTryAgain() {
    return true;
  }

  // stepComplete() {
  //   // eslint-disable-next-line react/prop-types
  //   return ({ currentExecutionStep }) => {
  //     return (
  //       <div>
  //         <hr />
  //         <strong>Org Information</strong>
  //         <div className="indented">
  //           Windows Patches Installed:{' '}
  //           {get(currentExecutionStep, 'stepOutput.windows-patches-installed')}
  //         </div>
  //       </div>
  //     );
  //   };
  // }

  getOutputOptions(step) {
    const actionOutputOptions = [
      {
        id: JSON.stringify({ step: step._id, key: 'windows-patches-installed' }),
        name: 'Windows Patches Installed',
        textValue: `{${step._id}.windows-patches-installed}`,
        type: 'string'
      },
      {
        id: JSON.stringify({ step: step._id, key: 'missing-1-2-patches' }),
        name: 'Missing 1-2 Patches',
        textValue: `{${step._id}.missing-1-2-patches}`,
        type: 'string'
      },
      {
        id: JSON.stringify({ step: step._id, key: 'missing-2-3-patches' }),
        name: 'Missing 2-3 Patches',
        textValue: `{${step._id}.missing-2-3-patches}`,
        type: 'string'
      },
      {
        id: JSON.stringify({ step: step._id, key: 'missing-5-patches' }),
        name: 'Missing 5+ Patches',
        textValue: `{${step._id}.missing-5-patches}`,
        type: 'string'
      },
      {
        id: JSON.stringify({ step: step._id, key: 'unscanned' }),
        name: 'Unscanned',
        textValue: `{${step._id}.unscanned}`,
        type: 'string'
      }
    ];

    return actionOutputOptions;
  }

  stepOptions() {
    return {
      title: 'Get Org Summary Data Step Options',
      fields: ({ structureList, outputOptions, outputOptionsLoaded }) => [
        {
          name: 'slug',
          component: SelectInput,
          label: 'Structure',
          options: map(structureList, struct => ({
            id: struct.slug,
            name: struct.display
          }))
        },
        {
          name: 'organizationDisplayName',
          component: TemplateInput,
          label: 'Organization Display Name',
          options: outputOptions
        },
        {
          name: 'integration',
          rawComponent: IntegrationSelect,
          stepType: 'i(kaseya)[test]',
          outputOptions,
          outputOptionsLoaded
        }
      ]
    };
  }
}

export default SpecialKaseyaUiHelper;
