import React from 'react';
import PropTypes from 'prop-types';
import UserThumbnail from './UserThumbnail';
import AssignAdmin from '../processes/ShowExecution/AssignAdmin';
import AddButton from '../shared/form/AddButton';

const UserThumbnailList = ({ users, addButton, userList, onUserSelected, radius, float }) => {
  if (!users || !users.length) {
    return null;
  }

  const thumbList = users.map(user => {
    if (!user) {
      return null;
    }
    return (
      <UserThumbnail user={user} float={float} radius={`${radius}px`} key={user._id} popover />
    );
  });

  return (
    <div className="admin-list">
      {addButton && (
        <div className="invite-user pull-right">
          <AssignAdmin userList={userList} onAssignUser={onUserSelected} placement="bottom">
            <AddButton size="sm" title="Invite user to process" />
          </AssignAdmin>
        </div>
      )}
      {thumbList}
    </div>
  );
};

UserThumbnailList.propTypes = {
  users: PropTypes.arrayOf(PropTypes.shape()),
  userList: PropTypes.arrayOf(PropTypes.shape()),
  addButton: PropTypes.bool,
  onUserSelected: PropTypes.func,
  radius: PropTypes.number,
  float: PropTypes.string
};

UserThumbnailList.defaultProps = {
  users: [],
  addButton: false,
  radius: 30,
  userList: [],
  onUserSelected: () => {},
  float: 'right'
};

export default UserThumbnailList;
