import React from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { reduxForm, Field } from 'redux-form'
import { connect } from 'react-redux'
import { Loading, LoadingButton, SelectInput } from 'lib/acromyrmex'
import {
  Form,
  Row,
  Col,
  Tooltip,
  OverlayTrigger,
} from '../../utility/UiComponents'
import EmptyView from '../shared/EmptyView'
import VaultSelectInput from '../shared/form/VaultSelectInput'
import Vault from '../../utility/vault'
import AttributeHelper from './attributes/UiAttributeHelper'
import {
  loadVaultStructure as loadVaultStructureAction,
  loadVaultStructureData as loadVaultStructureDataAction,
} from '../../actions/vault'
import { loadEnabledIntegrations as loadEnabledIntegrationsAction } from '../../actions/enabledIntegrations'

// const requiredValidator = value => (value ? undefined : 'Required');
const selectRequiredValidator = (value) =>
  value && value !== 'none' ? undefined : 'Required'

class VaultEntryForm extends React.Component {
  static renderLoading(slug) {
    return (
      <div className="container">
        <Loading />
        {` Loading ${slug} structure...`}
      </div>
    )
  }

  static renderMissing() {
    return (
      <EmptyView
        header="Structure not found."
        content="You might need to create it."
        button={{
          pathname: '/vault',
          content: 'View All Vault Structures',
        }}
      />
    )
  }

  static renderPrefix(attr) {
    let prefix = null

    if (attr.isPrimary) {
      const tooltip = <Tooltip id="tooltip">Primary field</Tooltip>

      prefix = (
        <span>
          <OverlayTrigger placement="right" overlay={tooltip}>
            <i className="fa fa-bookmark" />
          </OverlayTrigger>
        </span>
      )
    }

    return prefix
  }

  static renderField(attr) {
    // if it is undefined this won't trip (default true)
    if (attr.editable === false) {
      return null
    }

    const label = attr.display
    const prefix = VaultEntryForm.renderPrefix(attr)

    const field = AttributeHelper.getEditField(attr, label, prefix)

    return (
      <Col xs={{ span: 12, offset: attr.type === "boolean" ? 4 : 0 }} key={attr.key} style={{padding: attr.type === "boolean" ? 8 : 0 }}>
        {field}
      </Col>
    )
  }

  componentWillMount() {
    const { slug, vault, loadVaultStructure, loadEnabledIntegrations } =
      this.props

    if (!Vault.hasStructureLoaded(vault.data, slug)) {
      loadVaultStructure(slug)
    }

    loadEnabledIntegrations()
  }

  render() {
    const {
      isLoading,
      handleSubmit,
      onSubmit,
      isCreating,
      struct,
      slug,
      edit,
      formValues,
      enabledIntegrations,
    } = this.props

    const { integrations = [] } = formValues

    if (isLoading) {
      return VaultEntryForm.renderLoading(slug)
    }

    if (!struct || !struct.attributes) {
      return VaultEntryForm.renderMissing()
    }

    const fields = struct.attributes.map(VaultEntryForm.renderField)

    return (
      <div className="container">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Col xs={12}>
            <h3>Set up attributes</h3>
          </Col>
          {fields}
          <Col xs={12}>
            <hr />
          </Col>
          <div>
            <Col xs={12}>
              <h3>Associate integrations</h3>
            </Col>
            <Col xs={12}>
              <Field
                name="integrations"
                component={SelectInput}
                label="Integrations"
                multi
                options={enabledIntegrations.map((i) => {
                  const disabled =
                    integrations.map &&
                    integrations
                      .map((int) => {
                        const found = enabledIntegrations.find(
                          (e) => e._id === int,
                        )

                        return found && found.integrationId
                      })
                      .includes(i.integrationId)
                  const selected = integrations.includes(i._id)

                  return {
                    id: i._id,
                    name:
                      selected || !disabled
                        ? i.name
                        : `${i.name} (matching integration selected)`,
                    disabled,
                  }
                })}
              />
            </Col>
          </div>
          <Row>
            <Col xs={12} style={{ marginTop: 10 }}>
              <LoadingButton
                variant="primary"
                label={edit ? 'Save' : 'Create'}
                loading={isCreating}
                loadingLabel={`${edit ? 'Saving' : 'Creating'} ${struct.display}`}
                type="submit"
              />
            </Col>
          </Row>
        </Form>
      </div>
    )
  }
}

// Define property types
VaultEntryForm.propTypes = {
  slug: PropTypes.string.isRequired,
  edit: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  isCreating: PropTypes.bool.isRequired,
  vault: PropTypes.shape().isRequired,
  struct: PropTypes.shape(),
  formValues: PropTypes.shape(),
  isLoading: PropTypes.bool,
  enabledIntegrations: PropTypes.arrayOf(PropTypes.shape()),
  loadVaultStructure: PropTypes.func.isRequired,
  loadEnabledIntegrations: PropTypes.func.isRequired,
  // loadVaultStructureData: PropTypes.func.isRequired,
}

VaultEntryForm.defaultProps = {
  struct: null,
  edit: false,
  isLoading: false,
  onSubmit: () => {},
  formValues: {},
  enabledIntegrations: [],
}

// for the form
const mapStateToProps = (state, props) => ({
  vault: state.vault,
  struct: state.vault.structures[props.slug],
  isLoading:
    state.vault.structures[props.slug] &&
    state.vault.structures[props.slug].isLoading,
  user: state.users.user,
  isCreating: state.users.isCreating,
  formValues: state.form.vaultEntryForm && state.form.vaultEntryForm.values,
  enabledIntegrations: state.enabledIntegrations.enabledIntegrations.rows,
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      loadVaultStructure: loadVaultStructureAction,
      loadVaultStructureData: loadVaultStructureDataAction,
      loadEnabledIntegrations: loadEnabledIntegrationsAction,
    },
    dispatch,
  )

const form = reduxForm({ form: 'vaultEntryForm' })(VaultEntryForm)
export default connect(mapStateToProps, mapDispatchToProps)(form)
