import { TemplateInput } from 'lib/acromyrmex'
import { MS_TEAMS_LISTENER_STEP_TYPE } from '../../../constants'
import BasicUiHelper from './BasicUiHelper'
import IntegrationUiHelper from './IntegrationUiHelper'
import IntegrationSelect from './components/stepOptions/integration/IntegrationSelect'

class InputUiHelper extends BasicUiHelper {
  regex() {
    return MS_TEAMS_LISTENER_STEP_TYPE
  }

  name() {
    return 'MS Teams Listener'
  }

  stepOptions() {
    return {
      title: 'MS Teams Listener Step Options',
      fields: ({ outputOptions }) => [
        {
          component: IntegrationSelect,
          integrationName: 'msteams',
        },
        {
          name: 'conversationId',
          component: TemplateInput,
          label: 'Conversation ID',
          options: outputOptions,
        },
      ],
    }
  }

  // what shows in the result
  getOutputKeys() {
    return {
      schema: {
        '.': {
          type: 'Table',
        },
      },
    }
  }

  // this will be the message data
  getOutputOptions(step, { integrations }) {
    // we can use this because it will give the same results as this API
    return IntegrationUiHelper.getOutputOptions(
      { ...step, stepType: 'i(msteams)[sendMessagetoConversation]' },
      { integrations },
    )
  }
}

export default InputUiHelper
