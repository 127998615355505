import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { TextInput } from 'lib/acromyrmex';
import { Row, Col, Button } from '../../../utility/UiComponents';

const PickListItem = ({ item, fields, index }) => (
  <Row>
    <Col xs={11}>
      <Row key={item}>
        <Field name={item} component={TextInput} noLabel />
      </Row>
    </Col>
    <Col xs={1}>
      <Row>
        <Button variant="danger" onClick={() => fields.remove(index)} tabIndex="-1">
          <i className="fa fa-trash" />
        </Button>
      </Row>
    </Col>
  </Row>
);

PickListItem.propTypes = {
  item: PropTypes.string,
  fields: PropTypes.shape(),
  index: PropTypes.number
};

PickListItem.defaultProps = {
  item: '',
  fields: {},
  index: 0
};

export default PickListItem;
