import _ from 'underscore'

const propertiesToArray = (
  stepId,
  properties,
  prefix = '',
  namePrefix = '',
) => {
  const array = []

  _.each(properties, (prop, k) => {
    // since we will use this more than once, default the display to the key here
    const display = `${namePrefix}${prop.display || k}`

    // construct the base of the option
    const opt = {
      id: JSON.stringify({ step: stepId, field: prop, k: `${prefix}.${k}` }),
      name: display,
      textValue: `{${stepId}${prefix}.${k}}`,
      type: prop.type,
    }

    // for arrays, we want to add the nested properties to this option
    if (prop.type === 'array' && prop.items) {
      opt.type = 'collection'
      opt.properties = propertiesToArray(
        stepId,
        prop.items.properties,
        `${prefix}.${k}`,
        `${display} -> `,
      )
    }

    if (prop.type === 'object' && prop.properties) {
      opt.properties = propertiesToArray(
        stepId,
        prop.properties,
        `${prefix}.${k}`,
        `${display} -> `,
      )
    }

    // for arrays, we want to add the nested properties to this option
    if (prop.type === 'collection' && prop.properties) {
      opt.type = 'collection'
      opt.properties = propertiesToArray(
        stepId,
        prop.properties,
        '',
        `${display} -> `,
      )
    }

    // add the option we have created
    array.push(opt)

    // for objects, we want to add the nested properties as accessible options
    if (prop.type === 'object' && prop.properties) {
      array.push(
        ...propertiesToArray(
          stepId,
          prop.properties,
          `${prefix}.${k}`,
          `${display} -> `,
        ),
      )
    }
  })

  return array
}

const other = () => {}

export { propertiesToArray, other }
