import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { LinkContainer } from 'react-router-bootstrap';
import {
  Jumbotron, Row, Col, Button,
} from '../../utility/UiComponents';

function ShowDuoJumbotron({ browser, currentUser }) {
  return (
    <Jumbotron
      id="duo-jumbotron"
      style={{
        backgroundColor: '#272a2f',
        color: 'white',
        marginBottom: '10px',
      }}
    >
      <Row>
        <Col xs={12} sm={6}>
          <Row>
            <Col xs={12}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  float: 'left',
                }}
              >
                <div
                  style={{
                    float: 'left',
                  }}
                >
                  <LinkContainer to={{ pathname: '/' }}>
                    <img
                      src="/images/logo_white.png"
                      alt="Contuit Logo"
                      style={{
                        height: browser.lessThan.large ? '20px' : '50px',
                      }}
                    />
                  </LinkContainer>
                </div>
                <div
                  style={{
                    float: 'left',
                    fontSize: '2em',
                    padding: '0 10px',
                  }}
                >
                  {' '}
                  +
                  {' '}
                </div>
                <div
                  style={{
                    float: 'left',
                  }}
                >
                  <img
                    src="https://duo.com/assets/img/duoLogo-web.png"
                    alt="Duo Partner Badge"
                    style={{
                      height: browser.lessThan.large ? '20px' : '50px',
                    }}
                  />
                </div>
              </div>
            </Col>
            <Col
              xs={12}
              style={{
                color: 'rgba(239, 0, 127, 1)',
                fontSize: '3em',
              }}
            >
              Welcome Duo Partners
            </Col>
          </Row>
        </Col>
        <Col
          xs={12}
          sm={6}
          style={{
            marginTop: '20px',
            fontWeight: 'bold',
          }}
        >
          <p>Utilize prebuilt solutions to automate onboarding, billing, and issuing bypass codes.</p>
        </Col>
        {currentUser && (
        <LinkContainer to={{ pathname: '/logout' }}>
          <Button variant="link" size="sm" className="duo-logout-button signout">
            <i className="fa fa-sign-out" />
            <span className="sign-out-text"> Sign Out</span>
          </Button>
        </LinkContainer>
        )}
      </Row>
    </Jumbotron>
  );
}
// Define property types
ShowDuoJumbotron.propTypes = {
  currentUser: PropTypes.shape().isRequired,
  browser: PropTypes.shape().isRequired,
};

const mapStateToProps = (state) => ({
  currentUser: state.users.currentUser,
  browser: state.browser,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ShowDuoJumbotron);
