import isNull from 'lodash/isNull'
import isUndefined from 'lodash/isUndefined'
import React from 'react'
import { Field } from 'redux-form'
import { TextInput, SelectInput } from 'lib/acromyrmex'
import { Col } from '../../../utility/UiComponents'

const validate = (value) => {
  if (isNull(value) || isUndefined(value) || value === '') {
    return 'Required'
  }

  if (value.indexOf('http://') > -1 || value.indexOf('https://') > -1) {
    return 'Do not include http:// or https://'
  }

  return undefined
}

const EndpointInput = () => (
  <Col xs={12}>
    <div className="select-addon-before">
      <Field
        name="endpoint"
        component={TextInput}
        label="API Hostname"
        validate={validate}
        type="text"
        addonCustomBefore={
          <div>
            <Field
              name="scheme"
              component={SelectInput}
              options={[
                { id: 'https', name: 'https://' },
                { id: 'http', name: 'http://' },
              ]}
              addon
            />
          </div>
        }
      />
    </div>
  </Col>
)

EndpointInput.propTypes = {}

EndpointInput.defaultProps = {}

export default EndpointInput
