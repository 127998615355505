import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { FormulaInput } from 'lib/acromyrmex';
import { formulaOptions } from '../../../shared/Computation/ComputationFormRow';
import ExecutionMarkdown from '../../../processes/ExecutionMarkdown';

const templateOptions = [
  {
    id: 'license-type',
    name: 'License Type',
    textValue: '{license-type}'
  },
  {
    id: 'license-count',
    name: 'License Count',
    textValue: '{license-count}'
  },
  {
    id: 'create-date',
    name: 'Create Date',
    textValue: '{create-date}'
  }
];

const attributeOptions = [
  {
    id: 'license-type',
    name: 'License Type',
    textValue: '#license-type#'
  },
  {
    id: 'license-count',
    name: 'License Count',
    textValue: '#license-count#'
  },
  {
    id: 'create-date',
    name: 'Create Date',
    textValue: '#create-date#'
  }
];

const ComputationHelp = ({ title, blurb, name }) => (
  <div>
    <span className="control-label">{title}</span>
    <ExecutionMarkdown source={blurb} />
    <Field
      name={name}
      component={FormulaInput}
      label="COUNT"
      enableEmpty
      noLabel
      options={templateOptions}
      attributes={attributeOptions}
      formulas={formulaOptions}
    />
  </div>
);

ComputationHelp.propTypes = {
  title: PropTypes.string.isRequired,
  blurb: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  name: PropTypes.string.isRequired
};

export default ComputationHelp;
