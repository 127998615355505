/* eslint-disable react/prop-types */
/* eslint-disable class-methods-use-this */
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Loading } from 'lib/acromyrmex';
import { loadSettings as loadSettingsAction } from '../../actions/processes';
import PageLayout from '../shared/Layout/PageLayout';
import SolutionSettingsForm from './SolutionSettingsForm';
import EmptyView from '../shared/EmptyView';

class SettingsDashboardWrapper extends React.Component {
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    const { loadSettings } = this.props;
    loadSettings();
  }

  render() {
    const {
      settingsLoading,
      settings,
      currentUser,
      routes,
      params,
      settingsError,
    } = this.props;

    if (settingsLoading) {
      return <Loading />;
    }

    if (settingsError) {
      const errorMessage = 'Error Loading Settings';
      const errorContent = "";

      return (
        <PageLayout
          currentUser={currentUser}
          routes={routes}
          params={params}
          header="Solution Settings"
          content={
            <EmptyView
              header={errorMessage}
              content={errorContent}
              button={{
                pathname: '/dashboards/personal',
                content: 'Go to Dashboard',
                style: 'info',settings
              }}
            />
          }
        />
      );
    }

    // Add system defined settings
    if (!settings['pause-executions']) {
      settings['pause-executions'] = {
        isSystemDefinedSetting: true,
        slug: 'pause-executions',
        type: 'boolean',
        name: 'Pause Executions',
        value: false,
        description: 'Pauses all current and future executions.',
      };
    }
    // Convert settings object to array and sort
    const sortedSettings = Object.values(settings).sort((a) => {
      return a.isSystemDefinedSetting ? -1 : 1;
    });

    return (
      <PageLayout
        currentUser={currentUser}
        routes={routes}
        header="Solution Settings"
        content={
          <SolutionSettingsForm
            initialValues={{ attributes: sortedSettings }}
          />
        }
      />
    );
  }
}

const mapStateToProps = (state) => ({
  currentUser: state.users.currentUser,
  settings: state.processes.settings.value,
  settingsLoading: state.processes.settings.isLoading,
  settingsUpdating: state.processes.settings.isUpdating,
  browser: state.browser,
  settingsError: state.processes.settings.error,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      loadSettings: loadSettingsAction,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SettingsDashboardWrapper);
