import { historyPush } from '../history';
import { LicenseServer } from '@contuit-otf/sdk';
import { toast } from 'react-toastify';
import { setCurrentUser } from './users';
import request from '../utility/request';

const licenseServer = new LicenseServer('/api/license', request);

export function setLoading(loading = true) {
  return {
    type: 'ONBOARDING_LOADING',
    loading,
  };
}

export function setStage(stage = 1) {
  return {
    type: 'ONBOARDING_STAGE',
    stage,
  };
}

export function startOnboarding() {
  return (dispatch) => {
    dispatch(setStage(1));
  };
}

export function verifyOnboardingEmail(email) {
  return (dispatch) => {
    dispatch(setLoading());
    licenseServer
      .verifyOnboardingEmail({ email })
      .then(() => {
        dispatch(setStage(2));
      })
      .catch(({ response }) => {
        let message = 'Error sending verification email';
        if (response.body.message) {
          message += `: ${response.body.message}`;
        }

        toast.error(message);
      })
      .then(() => {
        dispatch(setLoading(false));
      });
  };
}

export function verifyOnboardingCode(email, code) {
  return (dispatch) => {
    dispatch(setLoading());
    licenseServer
      .verifyOnboardingCode({ email, code: code.trim() })
      .then(() => {
        dispatch(setStage(3));
      })
      .catch(({ response }) => {
        let message = 'Error checking verification code';
        if (response.body.message) {
          message += `: ${response.body.message}`;
        }

        toast.error(message);
      })
      .then(() => {
        dispatch(setLoading(false));
      });
  };
}

export function createFirstUser(options) {
  return (dispatch) => {
    dispatch(setLoading());
    licenseServer
      .createFirstUser(options)
      .then((response) => {
        dispatch(setCurrentUser(response));
        historyPush('/dashboards/personal');
      })
      .catch(() => {
        toast.error('Error creating first user.');
      })
      .then(() => {
        dispatch(setLoading(false));
      });
  };
}
