import React, { Component } from 'react';
import PropTypes from 'prop-types';
import each from 'lodash/each';
import isDate from 'lodash/isDate';
import isBoolean from 'lodash/isBoolean';
import isNull from 'lodash/isNull';
import isObject from 'lodash/isObject';
import isArray from 'lodash/isArray';
import isUndefined from 'lodash/isUndefined';
import DataTable from './DataTable';

class AutoDataTable extends Component {
  getColumns = () => {
    const { data, ignoreColumns } = this.props;
    const columns = [];

    data.forEach(row => {
      each(row, (value, key) => {
        // if we have already added a column for this key, skip it
        if (ignoreColumns.find(c => c === key) || columns.find(c => c.key === key)) {
          return;
        }

        let type;
        if (isDate(value)) {
          type = 'date';
        } else if (isBoolean(value)) {
          type = 'checkbox';
        } else if (isObject(value) || isArray(value)) {
          type = 'json';
        }

        columns.push({ key, type });
      });
    });

    // go through string columns to check for certain conditions
    columns
      .filter(c => !c.type)
      .forEach(column => {
        let allBoolStrings = true;

        data.forEach(row => {
          let val = '';

          if (isNull(row[column.key])) {
            val = '<null>';
          } else if (!isUndefined(row[column.key])) {
            val = row[column.key].toString();
          }

          if (val !== 'true' && val !== 'false') {
            allBoolStrings = false;
          }
        });

        if (allBoolStrings) {
          column.type = 'checkbox';
        }
      });

    return columns;
  };

  render() {
    const { data, selectable, idAttribute, onSelect, title, smallTitle, maxHeight } = this.props;

    return (
      <DataTable
        data={data}
        title={title}
        smallTitle={smallTitle}
        columns={this.getColumns()}
        selectable={selectable}
        idAttribute={idAttribute}
        onSelect={onSelect}
        maxHeight={maxHeight}
      />
    );
  }
}

AutoDataTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  ignoreColumns: PropTypes.arrayOf(PropTypes.string),
  idAttribute: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.bool]),
  smallTitle: PropTypes.oneOfType([PropTypes.node, PropTypes.bool]),
  onSelect: PropTypes.func,
  maxHeight: PropTypes.string,
  selectable: PropTypes.bool
};

AutoDataTable.defaultProps = {
  idAttribute: '_id',
  ignoreColumns: [],
  title: false,
  smallTitle: false,
  onSelect: () => {},
  maxHeight: undefined,
  selectable: false
};

export default AutoDataTable;
