import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { LinkContainer } from 'react-router-bootstrap';
import { LoadingButton } from 'lib/acromyrmex';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button } from '../../utility/UiComponents';
import ConfirmModal from '../shared/ConfirmModal';
import { deleteEnabledIntegration as deleteEnabledIntegrationAction } from '../../actions/enabledIntegrations';

class IntegrationButtons extends React.Component {
  constructor(props) {
    super(props);

    this.state = { showDelete: false };
  }

  render() {
    const {
      integration,
      pullRight,
      viewDetails,
      isDeleting,
      deleteEnabledIntegration,
    } = this.props;
    const { showDelete } = this.state;

    return (
      <div
        className={classnames('buttons-margin', {
          'pull-right': pullRight,
        })}
      >
        {viewDetails && (
          <LinkContainer
            to={{
              pathname: `/admin/integrations/${integration._id}`,
            }}
          >
            <Button size="xsmall" variant="default">
              View Details
            </Button>
          </LinkContainer>
        )}
        <LoadingButton
          variant="danger"
          style={{ marginRight: 5 }}
          size="xsmall"
          loading={isDeleting}
          loadingLabel="Deleting"
          onClick={() => {
            this.setState({ showDelete: true });
          }}
          label={<i className="fa fa-trash" />}
        />
        <ConfirmModal
          show={showDelete}
          title="Confirm deleting this integration"
          message="Are you sure you want to delete this integration?"
          onConfirm={() => {
            deleteEnabledIntegration(integration._id);
            this.setState({ showDelete: false });
          }}
          onCancel={() => {
            this.setState({ showDelete: false });
          }}
        />
      </div>
    );
  }
}

// Define property types
IntegrationButtons.propTypes = {
  integration: PropTypes.shape().isRequired,
  isDeleting: PropTypes.bool,
  deleteEnabledIntegration: PropTypes.func.isRequired,
  pullRight: PropTypes.bool,
  viewDetails: PropTypes.bool,
};

IntegrationButtons.defaultProps = {
  isDeleting: false,
  pullRight: false,
  viewDetails: true,
};

const mapStateToProps = (state, ownProps) => ({
  isDeleting:
    state.enabledIntegrations.enabledIntegrations.rows.find(
      (integration) => integration._id === ownProps.integration._id,
    )?.isDeleting || false,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      deleteEnabledIntegration: deleteEnabledIntegrationAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(IntegrationButtons);
