import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import Dropzone, { useDropzone } from 'react-dropzone';
import { Loading, Icon, LoadingButton } from 'lib/acromyrmex';
import { Row, Col, Button, Modal } from '../../../utility/UiComponents';
import { FileRemoveThumbnail } from './FileThumbnail';
import {
  uploadFile as uploadFileAction,
  deleteFile as deleteFileAction,
} from '../../../actions/upload';

const FileUploadModal = ({ show, onHide, onSubmitFiles }) => {
  const [files, setFiles] = useState([]);
  const dropzoneRef = useRef(null);

  const uploadedFiles = useSelector((state) => state.upload.uploadedFiles);
  const attaching = useSelector((state) => state.upload.attaching);
  const uploading = useSelector((state) => state.upload.uploading);
  const dispatch = useDispatch();

  useEffect(() => {
    const newFiles = files.filter(
      (file) =>
        !uploadedFiles.find((uploadedFile) => uploadedFile.name === file.name),
    );
    setFiles(newFiles);
  }, [uploadedFiles]);

  const onDrop = (acceptedFiles) => {
    setFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
    acceptedFiles.forEach((file) => dispatch(uploadFileAction(file)));
  };

  const onRemoveFile = async (file, index) => {
    await onRemoveUploadedFile(file.s3Key)
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const onRemoveUploadedFile = (s3Key) => {
    dispatch(deleteFileAction(s3Key));
  };

  const onCancel = async () => {
    await Promise.all(
      uploadedFiles.map((file) => onRemoveUploadedFile(file.s3Key)),
    );
    setFiles([]);
    onHide();
  };

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
  });

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="large"
      className="file-upload-modal"
    >
      <div {...getRootProps({ onClick: (event) => event.stopPropagation() })}>
        <div>
          <Modal.Header closeButton>
            <Modal.Title>Upload file</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              <input {...getInputProps()} />
              Please drag and drop a file to upload, or{' '}
              <Button size="xs" variant="primary" onClick={open}>
                select a file
              </Button>
            </p>
            <Row style={{minHeight: 180}}>
              {!isDragActive &&
                files.length > 0 &&
                files.map((file, i) => (
                  <Col xs={12} sm={6} md={4} key={file.name}>
                    <div className="upload-preview">
                      <div className="upload-loading">
                        <Loading /> Loading..
                      </div>
                      <p>
                        <Icon info /> {file.name}
                      </p>
                      <Button variant="danger" onClick={() => onRemoveFile(file, i)}>
                        <Icon close />
                      </Button>
                    </div>
                  </Col>
                ))}
              {!isDragActive &&
                uploadedFiles.length > 0 &&
                uploadedFiles.map((file) => (
                  <Col xs={12} sm={6} md={4} key={file.name}>
                    <FileRemoveThumbnail
                      file={file}
                      removeFile={() => onRemoveUploadedFile(file.s3Key)}
                    />
                  </Col>
                ))}
              {!isDragActive && files.length < 1 && (
                <div className="upload-spacer clearfix" />
              )}
              {isDragActive && (
                <Col className="drag-indicator">
                  <Icon upload /> Drop to upload
                </Col>
              )}
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={onCancel}>
              Cancel
            </Button>
            <LoadingButton
              variant="primary"
              label="Submit"
              loading={attaching}
              disabled={
                uploading || (files.length < 1 && uploadedFiles.length < 1)
              }
              loadingLabel="Attaching..."
              onClick={() => {
                onSubmitFiles(uploadedFiles);
                onHide();
              }}
            />
          </Modal.Footer>
        </div>
      </div>
    </Modal>
  );
};

FileUploadModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onSubmitFiles: PropTypes.func,
};

FileUploadModal.defaultProps = {
  onSubmitFiles: () => {},
};

export default FileUploadModal;
