/* eslint-disable react/static-property-placement */
import React from 'react';
import { useParams } from 'react-router';
import ShowMarketplaceImport from '../../modules/marketplace/ShowMarketplaceImport';

function ShowMarketplaceImportPage() {
  const params = useParams();

  return <ShowMarketplaceImport params={params} />;
}

export default ShowMarketplaceImportPage;
