import _ from 'underscore'
import React from 'react'
import PropTypes from 'prop-types'
import { reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { LoadingButton } from 'lib/acromyrmex'
import get from 'lodash/get'
import { Form, Button } from '../../../utility/UiComponents'
import AssignAdmin from './AssignAdmin'
import ShowTemplateValue from '../ShowTemplateValue'
import StepHelper from '../steps/UiStepHelper'
import { tryStepAgain as tryStepAgainAction } from '../../../actions/executions'
import Alert from '../../shared/Alert'
import { getExecutionStepByProcessStep } from '../../../shared-helpers/execution'
import DateHelper from '../../../shared-helpers/DateHelper'
import { ToggleIcon } from '../ProcessForm/StepToolbar'

/**
 * Renders the current step on the show execution page. Takes into account
 * the step is assigned to, what the step type is, and delegates events.
 */
class ExecuteStep extends React.Component {
  static renderAutomating(
    currentStep,
    currentExecutionStep,
    isUpdating,
    tryStepAgain,
    execution,
  ) {
    const ONE_MIN = 60 * 1000
    let stepTimeout = false

    if (currentExecutionStep) {
      const now = new Date()
      const timeSinceStart = now - new Date(currentExecutionStep.timeStarted)
      const dur = currentExecutionStep.lastRetryTime
        ? Math.min(
            timeSinceStart,
            now - new Date(currentExecutionStep.lastRetryTime),
          )
        : timeSinceStart

      stepTimeout = dur > ONE_MIN
    }

    const AutomatingExtra = StepHelper.getStepAutomating(currentStep.stepType)

    const isError = currentExecutionStep && currentExecutionStep.error
    return (
      <div>
        {isError && (
          <div className="clearfix">
            <div>
              <Alert danger message={currentExecutionStep.error} pullLeft />
            </div>
            <div className="pull-right">
              Retry count: {currentExecutionStep.retryCount || 0}
              <br />
              Last Retry:{' '}
              {currentExecutionStep.lastRetry
                ? DateHelper.timeAgo(currentExecutionStep.lastRetry)
                : ''}
            </div>
          </div>
        )}
        <div className="pull-right">
          <Button
            variant="link"
            size="xs"
            onClick={() => {
              const encodedLauncher = btoa(
                JSON.stringify({
                  isEntitled: true,
                  query: currentExecutionStep._id,
                  eventTypes: ['Log'],
                }),
              )

              window.open(
                `https://one.newrelic.com/launcher/logger.log-launcher?platform[accountId]=1&launcher=${encodedLauncher}`,
                '_blank',
              )
            }}
            title="logs"
          >
            <ToggleIcon show icon="fa-list" badge="list" />
          </Button>
        </div>
        {!isError && !ExecuteStep.isSubProcessStep(currentStep) && (
          <h4 className="current-step-header">Step currently Automating..</h4>
        )}
        {!isError && AutomatingExtra && (
          <AutomatingExtra
            currentExecutionStep={currentExecutionStep}
            execution={execution}
          />
        )}
        {StepHelper.canTryAgain(currentStep.stepType) &&
          (stepTimeout || isError) && (
            <div>
              <LoadingButton
                variant="primary"
                size="xsmall"
                onClick={tryStepAgain}
                loading={isUpdating}
                loadingLabel="Attempting to start again.."
                label="Try again"
              />
            </div>
          )}
      </div>
    )
  }

  static isSubProcessStep(currentStep) {
    return (
      currentStep.stepType === 'loop-process' ||
      currentStep.stepType === 'execute-process'
    )
  }

  constructor(props) {
    super(props)

    this.tick = this.tick.bind(this)
    this.state = {
      secondsElapsed: 0,
    }
  }

  componentDidMount() {
    this.interval = setInterval(this.tick, 1000)
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  tick() {
    this.setState((state) => ({ secondsElapsed: state.secondsElapsed + 1 }))
  }

  /**
   * Determines, based on stepType, what needs to be shown to complete the step.
   * @param  {ProcessStep} currentStep Server ProcessStep object
   * @return {Node}             React node to render to DOM
   */
  renderCompletionView(currentStep) {
    const { change, onExecute, reset, currentUser, execution } = this.props
    let editElement = null

    const onChange = (values) => {
      _.each(values, (value, key) => {
        change(key, value)
      })
    }

    const Type = StepHelper.getStepExecution(currentStep.stepType)
    if (Type) {
      editElement = (
        <Type
          key={currentStep._id}
          step={currentStep}
          onChange={onChange}
          completeStep={(values) => onExecute(values, reset)}
        />
      )
    }

    return (
      <div className="execute-row">
        {currentStep.stepOptions.description && (
          <ShowTemplateValue
            currentUser={currentUser}
            input={currentStep.stepOptions.description}
            execution={execution}
          />
        )}
        {editElement}
      </div>
    )
  }

  render() {
    const {
      execution,
      currentStep,
      currentUser,
      stepError,
      onExecute,
      isUpdating,
      userList,
      onAssignUser,
      valid,
      handleSubmit,
      tryStepAgain,
      reset,
    } = this.props
    const { secondsElapsed } = this.state

    const assignee =
      execution.assignee &&
      execution.users.find &&
      execution.users.find((a) => a._id === execution.assignee)

    const assignedToMe =
      currentUser && assignee && currentUser._id === assignee._id

    const manager =
      currentUser.userRoles.indexOf('manager') > -1 ||
      currentUser.userRoles.indexOf('admin') > -1

    const currentExecutionStep = getExecutionStepByProcessStep(
      execution,
      currentStep,
    )

    let buttons = [...StepHelper.getStepButtons(currentStep.stepType)]

    buttons = buttons.map((button) => {
      if (button === 'submit') {
        return assignedToMe ? (
          <LoadingButton
            key="submit"
            variant="primary"
            size="xs"
            type="submit"
            loading={isUpdating}
            disabled={!valid}
            loadingLabel="loading.."
            className="pull-right"
            label={
              <span>
                <i className="fa fa-check-square-o" />
                Proceed
              </span>
            }
          />
        ) : (
          ''
        )
      }

      return button
    })

    if (assignedToMe || manager) {
      buttons.push(
        <span className="pull-right" key="assign">
          <AssignAdmin
            userList={userList}
            onAssignUser={onAssignUser}
            isUpdating={isUpdating}
          />
        </span>,
      )
    }

    // seconds should always be true. this is to make it re-render?
    if (
      StepHelper.isAutomatedStep(currentStep.stepType) &&
      secondsElapsed > -1
    ) {
      return ExecuteStep.renderAutomating(
        currentStep,
        currentExecutionStep,
        isUpdating,
        () => {
          tryStepAgain(execution)
        },
        execution,
      )
    }

    const isWebexActionEnabled = get(
      currentStep,
      'stepOptions.webexActionEnabled',
      false,
    )

    return (
      <div>
        <Form onSubmit={handleSubmit((values) => onExecute(values, reset))}>
          {assignedToMe && this.renderCompletionView(currentStep)}
          {stepError && (
            <Alert variant="danger">
              <i className="fa fa-exclamation-circle" /> {stepError}
            </Alert>
          )}
          <div className="execute-row">
            {isWebexActionEnabled && (
              <span>*listening for a webex button action</span>
            )}
            {buttons}
            {assignedToMe && !valid && (
              <span
                className="text-danger pull-right"
                style={{ marginRight: '5px', marginTop: '5px' }}
              >
                Check form validity!
              </span>
            )}
          </div>
        </Form>
      </div>
    )
  }
}

ExecuteStep.propTypes = {
  currentStep: PropTypes.shape().isRequired,
  execution: PropTypes.shape().isRequired,
  currentUser: PropTypes.shape().isRequired,
  userList: PropTypes.arrayOf(PropTypes.shape()),
  tryStepAgain: PropTypes.func.isRequired,
  onExecute: PropTypes.func.isRequired,
  onAssignUser: PropTypes.func.isRequired,
  isUpdating: PropTypes.bool,
  stepError: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  valid: PropTypes.bool,
}

ExecuteStep.defaultProps = {
  isUpdating: false,
  valid: false,
  userList: [],
  stepError: '',
}

const mapStateToProps = () => ({})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      tryStepAgain: tryStepAgainAction,
    },
    dispatch,
  )

const form = reduxForm({
  form: 'executeStep',
  enableReinitialize: true,
  validate: ExecuteStep.validate,
})(ExecuteStep)

export default connect(mapStateToProps, mapDispatchToProps)(form)
