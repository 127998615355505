import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { LinkContainer } from 'react-router-bootstrap';
import { Well, Button } from '../../utility/UiComponents';
import PageLayout from '../shared/Layout/PageLayout';
import marketplace from '../../config/marketplace.json';

class ShowMarketplace extends React.Component {
  static propTypes = {
    browser: PropTypes.shape().isRequired
  };

  componentDidMount() {
    document.title = 'Contuit | Playbook';
  }

  componentWillUnmount() {
    document.title = 'Contuit';
  }

  render() {
    const { browser } = this.props;

    return (
      <PageLayout
        header="Contuit Marketplace"
        description="Welcome to the Contuit Marketplace. Here you can find add-ons built by third parties to help you solve common problems quickly. Select an add-on to see more details on the solutions it provides."
        content={
          <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
            {marketplace.map((solution, i) => (
              <Well style={{ maxWidth: '500px', marginBottom: '0px', float: 'left' }}>
                <div className="clearfix" style={{ display: 'flex' }}>
                  <div style={{ float: 'left', flex: 0 }}>
                    <img
                      src={solution.logo}
                      alt={`${solution.name} Partner Badge`}
                      style={{ width: browser.lessThan.large ? '50px' : '100px' }}
                    />
                  </div>
                  <div style={{ flex: 1, paddingLeft: '10px' }}>
                    <h4 style={{ marginTop: 0 }}>{solution.name}</h4>
                    <ul style={{ listStyleType: 'none', paddingLeft: '10px' }}>
                      <li style={{ fontSize: '0.9em' }}>{solution.description}</li>
                      <li style={{ paddingTop: '5px' }}>
                        <LinkContainer to={{ pathname: `/marketplace/${solution.id}` }}>
                          <Button variant="primary" size="xs">
                            More Details
                          </Button>
                        </LinkContainer>
                      </li>
                    </ul>
                  </div>
                </div>
              </Well>
            ))}
          </div>
        }
      />
    );
  }
}
const mapStateToProps = state => ({
  browser: state.browser
});
const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ShowMarketplace);
