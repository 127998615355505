import _ from 'underscore';
import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { Field } from 'redux-form';
import { connect } from 'react-redux';
import { TemplateInput } from 'lib/acromyrmex';
import { Button, Row, Col, Alert } from '../../../../../../utility/UiComponents';
import StructureAttributeSelectInput from '../../../../../shared/form/StructureAttributeSelectInput';
import StringHelper from '../../../../../../shared-helpers/StringHelper';
import { filterCollections } from '../../../../../../utility/ProcessFormOptionHelpers';

class VaultSaveField extends React.Component {
  getStepObjectFromStepText(stepText) {
    // gets the index based on field name. Not the best way but works?
    const stepIndex = stepText.substring(stepText.indexOf('[') + 1, stepText.indexOf(']'));
    const {
      formValues: { steps }
    } = this.props;

    if (!steps) {
      return null;
    }

    return steps[stepIndex];
  }

  render() {
    const {
      change,
      fieldName,
      fields,
      index,
      outputOptions,
      formValues: { steps }
    } = this.props;
    const thisStep = this.getStepObjectFromStepText(fieldName);

    let slug = '';
    let destStep = null;
    if (thisStep.stepOptions.destination) {
      const { destination } = thisStep.stepOptions;
      if (_.isString(destination)) {
        const destinationStepId = StringHelper.getStepIdFromTemplate(destination);
        destStep = _.findWhere(steps, {
          _id: destinationStepId
        });
      } else {
        // This is the legacy way to save step
        destStep = _.findWhere(steps, {
          step: thisStep.stepOptions.destination.step
        });
      }

      slug = destStep.stepOptions.vaultStructure || destStep.stepOptions.slug;
    }

    const name = `${fieldName}.source`;
    return (
      <Row style={{ marginBottom: 10 }} key={fieldName}>
        <Col xs={5}>
          <Row>
            <Field
              name={name}
              component={TemplateInput}
              noLabel
              label="Source Data"
              plainText
              options={filterCollections(outputOptions)}
              onTemplateClicked={option => {
                change(name, option.textValue);
              }}
            />
          </Row>
        </Col>
        <Col xs={5}>
          {!destStep ? (
            <Alert variant="danger">
              <i className="fa fa-exclamation-circle" />
              {" Must select which step's structure to save to!"}
            </Alert>
          ) : (
            <Row>
              <Field
                name={`${fieldName}.destination`}
                component={StructureAttributeSelectInput}
                noLabel
                label="Destination field"
                slug={slug}
              />
            </Row>
          )}
        </Col>
        <Col xs={2}>
          <Button
            className="pull-right"
            variant="danger"
            onClick={() => fields.remove(index)}
            title="Remove this step"
          >
            <i className="fa fa-trash" />
          </Button>
        </Col>
      </Row>
    );
  }
}

VaultSaveField.propTypes = {
  fieldName: PropTypes.string.isRequired,
  fields: PropTypes.shape().isRequired,
  index: PropTypes.number.isRequired,
  formValues: PropTypes.shape(),
  change: PropTypes.func.isRequired,
  outputOptions: PropTypes.arrayOf(PropTypes.shape()).isRequired
};

VaultSaveField.defaultProps = {
  formValues: {}
};

const mapStateToProps = state => ({
  formValues: state.form.processForm ? state.form.processForm.values : {},
  structureList: state.vault.structureList.rows
});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(VaultSaveField);
