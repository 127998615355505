const LOG_INITIAL = {
  logs: {
    isLoading: false,
    rows: [],
  },
}

export default (state = LOG_INITIAL, action = {}) => {
  switch (action.type) {
    case 'ADD_LOGS':
      return Object.assign({}, state, {
        logs: Object.assign({}, state.logs, {
          rows: action.logs,
        }),
      })
    case 'LOGS_LOADING':
      return Object.assign({}, state, {
        logs: Object.assign({}, state.logs, {
          isLoading: action.loading,
        }),
      })
    default:
      return state
  }
}
