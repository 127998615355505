import _ from 'underscore';
import { toast } from 'react-toastify';
import { UploadServer } from '@contuit-otf/sdk';
import { API_BASE_URL } from '../constants';

const uploadServer = new UploadServer(`${API_BASE_URL}/api/upload`);

export function fileUploading(loading = true) {
  return {
    type: 'FILE_UPLOADING',
    loading,
  };
}

export function uploadAttaching(loading = true) {
  return { type: 'UPLOAD_ATTACHING', loading };
}

export function setUploadModal(open = true) {
  return {
    type: 'SET_UPLOAD_MODAL',
    open,
  };
}

export function openModal() {
  return (dispatch) => {
    dispatch(setUploadModal(true));
  };
}

export function closeModal() {
  return (dispatch) => {
    dispatch(setUploadModal(false));
  };
}

export function addUploadedFile(file) {
  return {
    type: 'ADD_UPLOADED_FILE',
    file,
  };
}

export function clearUploadedFiles() {
  return {
    type: 'CLEAR_UPLOADED_FILES',
  };
}

export function removeUploadedFile(file) {
  return {
    type: 'REMOVE_UPLOADED_FILE',
    file,
  };
}

export function uploadFile(fileObj) {
  return (dispatch, getState) => {
    const { token } = getState().users.currentUser;
    dispatch(fileUploading());
    uploadServer
      .uploadFile({ token, file: fileObj })
      .then((response) => {
        dispatch(addUploadedFile({ ...response, name: fileObj.name }));
      })
      .catch((err) => {
        toast.error(`Error uploading file: ${err}`);
      })
      .then(() => {
        dispatch(fileUploading(false));
      });
  };
}

export function deleteFile(s3Key) {
  return (dispatch, getState) => {
    // support passing both a file obj or an s3Key
    const _key = _.isObject(s3Key) && s3Key.s3Key ? s3Key.s3Key : s3Key;
    const { token } = getState().users.currentUser;
    dispatch(fileUploading());
    uploadServer
      .deleteFile({ token, s3Key: _key })
      .then(() => {
        dispatch(removeUploadedFile(_key));
      })
      .catch((err) => {
        toast.error(`Error deleting file: ${err}`);
      })
      .then(() => {
        dispatch(fileUploading(false));
      });
  };
}
