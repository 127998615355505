import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Icon, LoadingButton } from 'lib/acromyrmex'
import { LinkContainer } from 'react-router-bootstrap'
import { Button } from '../../utility/UiComponents'
import ConfirmModal from '../shared/ConfirmModal'
import { deleteStructure as deleteStructureAction } from '../../actions/vault'

class VaultStructureButtons extends React.Component {
  constructor(props) {
    super(props)

    this.state = { showDelete: false }
  }

  render() {
    const {
      isDeleting,
      deleteStructure,
      onDelete,
      struct,
      viewStructure,
      viewData,
      createNew,
      pullRight,
    } = this.props
    const { showDelete } = this.state
    return (
      <div
        className={classNames({
          'pull-right': pullRight,
        })}
      >
        {viewStructure && (
          <div style={{ marginRight: 5, display: 'inline' }}>
            <LinkContainer
              to={{
                pathname: `/vault/${struct.slug}`,
              }}
            >
              <Button variant="default" size="xsmall">
                View Details
              </Button>
            </LinkContainer>
          </div>
        )}
        {viewData && (
          <div style={{ marginRight: 5, display: 'inline' }}>
            <LinkContainer
              to={{
                pathname: `/vault/${struct.slug}/data`,
              }}
            >
              <Button variant="default" size="xsmall">
                View Data
              </Button>
            </LinkContainer>
          </div>
        )}
        <div style={{ marginRight: 5, display: 'inline' }}>
          <LinkContainer
            to={{
              pathname: `/vault/${struct.slug}/edit`,
            }}
          >
            <Button variant="primary" size="xsmall">
              <Icon edit />
            </Button>
          </LinkContainer>
        </div>
        {createNew && (
          <div style={{ marginRight: 5, display: 'inline' }}>
            <LinkContainer
              to={{
                pathname: `/vault/${struct.slug}/new`,
              }}
            >
              <Button variant="success" size="xsmall">
                Create {struct && struct.singular}
              </Button>
            </LinkContainer>
          </div>
        )}
        {!struct.system && (
          <LoadingButton
            variant="danger"
            style={{ marginRight: 5 }}
            size="xsmall"
            loading={isDeleting}
            loadingLabel=""
            onClick={() => {
              this.setState({ showDelete: true })
            }}
            label={<i className="fa fa-trash" />}
          />
        )}
        <ConfirmModal
          show={showDelete}
          title="Confirm Deleting Vault Structure"
          message={`Are you sure you want to delete the ${struct.singular} Vault Structure?`}
          onConfirm={() => {
            deleteStructure(struct.slug).then(() => {
              onDelete()
            })
            this.setState({ showDelete: false })
          }}
          onCancel={() => {
            this.setState({ showDelete: false })
          }}
        />
      </div>
    )
  }
}

VaultStructureButtons.propTypes = {
  struct: PropTypes.shape().isRequired,
  isDeleting: PropTypes.bool,
  deleteStructure: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  viewStructure: PropTypes.bool,
  viewData: PropTypes.bool,
  createNew: PropTypes.bool,
  pullRight: PropTypes.bool,
}

VaultStructureButtons.defaultProps = {
  onDelete: () => {},
  isDeleting: false,
  viewStructure: false,
  viewData: false,
  createNew: false,
  pullRight: false,
}

const mapStateToProps = (state, props) => {
  const { slug } = props
  return {
    isDeleting: state.vault.data[slug]
      ? state.vault.data[slug].isDeleting
      : false,
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      deleteStructure: deleteStructureAction,
    },
    dispatch,
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VaultStructureButtons)
