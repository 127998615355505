import React, { useState } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { Button } from '../../../utility/UiComponents'
import ConfirmModal from '../../shared/ConfirmModal'

const ToggleIcon = ({ show, icon, badge }) => (
  <span className="fa-stack fa-sm">
    {show && <i className="fa fa-circle fa-stack-2x" />}
    <i
      className={classnames('fa', 'fa-stack-1x', icon, { 'fa-inverse': show })}
    />
    {badge > 0 && (
      <span className="badge badge-notify" style={{ padding: '1px 5px', borderRadius: 10 }}>
        {badge}
      </span>
    )}
  </span>
)

ToggleIcon.propTypes = {
  icon: PropTypes.string.isRequired,
  badge: PropTypes.number,
  show: PropTypes.bool,
}

ToggleIcon.defaultProps = {
  badge: 0,
  show: false,
}

const StepToolbar = ({
  removeStep,
  fields,
  index,
  addStep,
  stepType,
  copyStep,
  onToggleResults,
  conditionCount,
  onToggleDescription,
  onToggleConditions,
  onToggleStepOptions,
}) => {
  const [showDescription, setShowDescription] = useState(false)
  const [showConditions, setShowConditions] = useState(false)
  const [showStepOptions, setShowStepOptions] = useState(false)
  const [showResultOptions, setShowResultOptions] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [stepIndexToDelete, setStepIndexToDelete] = useState(null)

  return [
    <Button
      variant="link"
      size="xs"
      key="result"
      onClick={() => {
        onToggleResults(!showResultOptions)
        setShowResultOptions(!showResultOptions)
      }}
      title="Results"
    >
      <ToggleIcon icon="fa-list text-info" />
    </Button>,
    <Button
      variant="link"
      size="xs"
      key="description"
      onClick={() => {
        onToggleDescription(!showDescription)
        setShowDescription(!showDescription)
      }}
    >
      <ToggleIcon show={showDescription} icon="fa-info" />
    </Button>,
    <Button
      variant="link"
      size="xs"
      key="conditions"
      disabled={stepType === 'block-end' || stepType === 'block-end-v2'}
      onClick={() => {
        onToggleConditions(!showConditions)
        setShowConditions(!showConditions)
      }}
    >
      <ToggleIcon
        show={showConditions}
        icon="fa-code-fork"
        badge={conditionCount}
      />
    </Button>,
    <Button
      variant="link"
      size="xs"
      key="options"
      onClick={() => {
        onToggleStepOptions(!showStepOptions)
        setShowStepOptions(!showStepOptions)
      }}
    >
      <ToggleIcon show={showStepOptions} icon="fa-cog" />
    </Button>,
    <Button
      variant="link"
      size="xs"
      key="delete"
      onClick={() => {
        setStepIndexToDelete(index)
        setShowDeleteModal(true)
      }}
      title="Remove this step"
      className="delete-step-button"
    >
      <ToggleIcon icon="fa-trash text-danger" />
    </Button>,
    <Button
      variant="link"
      size="xs"
      key="new"
      onClick={() => addStep(fields, index + 1)}
      title="Add a step below"
      className="add-step-button"
    >
      <ToggleIcon icon="fa-plus text-success" />
    </Button>,
    <Button
      variant="link"
      size="xs"
      key="copy"
      onClick={() => copyStep(fields, index)}
      title="Copy this step"
      className="copy-step-button"
    >
      <ToggleIcon icon="fa-copy text-success" />
    </Button>,
    <ConfirmModal
      show={showDeleteModal}
      key="confirmDelete"
      title={`Confirm Deleting Step ${index + 1}`}
      message={`Are you sure you want to delete Step ${index + 1}?`}
      onConfirm={() => {
        removeStep(fields, stepIndexToDelete)
        setShowDeleteModal(false)
      }}
      onCancel={() => setShowDeleteModal(false)}
    />,
  ]
}

StepToolbar.propTypes = {
  removeStep: PropTypes.func.isRequired,
  fields: PropTypes.shape().isRequired,
  index: PropTypes.number.isRequired,
  conditionCount: PropTypes.number.isRequired,
  addStep: PropTypes.func.isRequired,
  copyStep: PropTypes.func.isRequired,
  onToggleDescription: PropTypes.func.isRequired,
  onToggleConditions: PropTypes.func.isRequired,
  onToggleResults: PropTypes.func.isRequired,
}

export default StepToolbar
export { ToggleIcon }
