import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Loading } from 'lib/acromyrmex';
import { LinkContainer } from 'react-router-bootstrap';
import PropTypes from 'prop-types';
import { Col, Alert, ProgressBar, Button } from '../../../../../utility/UiComponents';
import { loadExecution as loadExecutionAction } from '../../../../../actions/executions';

class ExecuteProcessAutomating extends Component {
  componentWillMount() {
    this.componentWillUpdate(this.props, true);
  }

  componentWillUpdate(nextProps, force = false) {
    const { childExecutions, currentExecutionStep, loadExecution } = nextProps;
    const executionId = this._getChildExecutionId(currentExecutionStep);
    if (!executionId) {
      return;
    }

    // have we already got this one?
    if (!force && childExecutions && childExecutions[executionId]) {
      return;
    }

    loadExecution(executionId, { child: true });
  }

  // eslint-disable-next-line
  _getChildExecutionId(currentExecutionStep) {
    if (!currentExecutionStep || !currentExecutionStep.stepOutput) {
      return false;
    }

    return currentExecutionStep.stepOutput.executionId;
  }

  // eslint-disable-next-line
  _renderExecutionMissing() {
    return (
      <Alert variant="danger" className="pull-left">
        <i className="fa fa-exclamation-circle" />
        {' Cannot find child execution. Has it been deleted?'}
      </Alert>
    );
  }

  // eslint-disable-next-line
  _renderExecutionWaiting() {
    return (
      <Alert variant="info" className="pull-left">
        <Loading text="creating execution..." />
      </Alert>
    );
  }

  render() {
    const { childExecutions, currentExecutionStep, childLoading } = this.props;
    const executionId = this._getChildExecutionId(currentExecutionStep);

    if (!executionId) {
      return this._renderExecutionWaiting();
    }

    if (!childLoading && !childExecutions) {
      return this._renderExecutionMissing();
    }

    const thisChild = childExecutions[executionId];

    if (!childLoading && !thisChild) {
      return this._renderExecutionMissing();
    }

    return (
      <div>
        {childLoading && <Loading text="Loading child execution..." />}
        {!childLoading && (
          <div>
            <Col xs={6}>
              <ProgressBar
                now={thisChild.progress * 100}
                label={`${Math.floor(thisChild.progress * 100)}%`}
              />
            </Col>
            <Col xs={6}>
              <LinkContainer
                to={{
                  pathname: `/processes/${thisChild.process._id}/executions/${thisChild._id}`
                }}
              >
                <Button size="xsmall" variant="primary">
                  View Execution
                </Button>
              </LinkContainer>
            </Col>
          </div>
        )}
      </div>
    );
  }
}

ExecuteProcessAutomating.propTypes = {
  loadExecution: PropTypes.func.isRequired,
  currentExecutionStep: PropTypes.shape().isRequired,
  childExecutions: PropTypes.shape().isRequired,
  childLoading: PropTypes.bool
};

ExecuteProcessAutomating.defaultProps = {
  childLoading: false
};

const mapStateToProps = state => ({
  childExecutions: state.processes.execution.children,
  childLoading: state.processes.execution.childLoading
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      loadExecution: loadExecutionAction
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ExecuteProcessAutomating);
