import React from 'react';
import PropTypes from 'prop-types';
import { HoverHelp } from 'lib/acromyrmex';

const PanelTitle = ({ title, help }) => (
  <span>
    <strong>{title}</strong> {help && <HoverHelp help={help} />}
  </span>
);

PanelTitle.propTypes = {
  title: PropTypes.string.isRequired,
  help: PropTypes.string
};

PanelTitle.defaultProps = {
  help: ''
};

export default PanelTitle;
