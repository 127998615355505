import { historyPush } from '../../history';
import get from 'lodash/get';
import DuoWebSDK from 'duo_web_sdk';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { TextInput, LoadingButton } from 'lib/acromyrmex';
import { AuthenticationServer } from '@contuit-otf/sdk';
import { Col, Row, Button } from '../../utility/UiComponents';
import {
  setCurrentUser as setCurrentUserAction,
  redirectAfterLogin as redirectAfterLoginAction,
} from '../../actions/users';
import Alert from '../shared/Alert';
import request from '../../utility/request';

const authServer = new AuthenticationServer('/api/auth', request);

class ShowDuoMfaForm extends Component {
  static propTypes = {
    message: PropTypes.string,
    redirectURL: PropTypes.string,
    setCurrentUser: PropTypes.func.isRequired,
    redirectAfterLogin: PropTypes.func.isRequired,
    postDuoRedirect: PropTypes.string,
  };

  static defaultProps = {
    message: null,
    redirectURL: null,
    postDuoRedirect: '/marketplace/5d9cd31b023bd6a06b0a9d82/import',
  };

  state = {
    email: '',
    password: '',
    contuitConfirmed: false,
    user: null,

    passwordResetting: false,

    error: '',
    loading: false,
  };

  submitPostAction = (form) => {
    const { setCurrentUser, postDuoRedirect, redirectAfterLogin } = this.props;
    const { user } = this.state;

    this.setState({ loading: true, error: '' });
    request
      .post('/api/license/onboard/mfa/duo/verify')
      .set('x-access-token', user.token)
      .send({ signedResponse: form.sig_response.value })
      .then(({ body }) => {
        if (body.success) {
          const { redirectURL } = this.props;
          setCurrentUser({ user });

          if (redirectURL) {
            window.location.href = decodeURIComponent(redirectURL);
          } else {
            redirectAfterLogin(user, postDuoRedirect);
          }
        } else {
          this.setState({ error: `Error: Duo Verification unsuccessful` });
        }
      })
      .catch((e) => this.setState({ error: `Error: ${e.message}` }))
      .then(() => this.setState({ loading: false }));
  };

  onSaveClicked = () => {
    const { setCurrentUser } = this.props;
    const { email, password } = this.state;

    this.setState({ loading: true, error: '' });
    request
      .post('/api/auth/authenticate')
      .send({ email, password })
      .then(({ body: data }) => {
        const duoMfa = get(data, 'user.contuitClient.mfa.duo');
        if (duoMfa) {
          this.setState({ contuitConfirmed: true, user: data.user });

          setTimeout(() => {
            try {
              DuoWebSDK.init({
                iframe: 'duo-frame',
                host: duoMfa.host,
                sig_request: data.sigRequest,
                submit_callback: this.submitPostAction,
              });
            } catch (err) {
              this.setState({ error: err.message });
            }
          }, 0);
        } else {
          const { redirectURL } = this.props;
          setCurrentUser(data);

          if (redirectURL) {
            window.location.href = decodeURIComponent(redirectURL);
          } else {
            historyPush('/setup-mfa');
          }
        }
      })
      .catch((e) => {
        const msg =
          e.response && e.response.body && e.response.body.message
            ? e.response.body.message
            : 'Unknown authentication error.';
        return this.setState({ error: `Error: ${msg}` });
      })
      .then(() => this.setState({ loading: false }));
  };

  onResetpasswordClicked = () => {
    const { email } = this.state;

    this.setState({ passwordResetting: true });
    authServer
      .resetPassword({ email })
      .then(({ success }) => {
        if (success) {
          historyPush('/resetPassword');
        }
      })
      .catch(() => {
        // idk
      })
      .then(() => {
        this.setState({ passwordResetting: false });
      });
  };

  render() {
    const { message } = this.props;
    const {
      email,
      password,
      contuitConfirmed,
      loading,
      error,
      passwordResetting,
    } = this.state;
    const buttonsEnabled = !loading && email && password;

    return (
      <form>
        <Row className="content-row">
          <Col xs={12}>
            <h3 style={{ margin: '5px 0' }}>
              {contuitConfirmed
                ? 'Verify using Duo'
                : 'Please enter your Contuit credentials.'}
            </h3>
          </Col>
          <Col xs={12}>{error && <Alert danger message={error} />}</Col>
          {message && (
            <Col xs={12}>
              <Alert info message={message} />
            </Col>
          )}
          {!contuitConfirmed ? (
            <div>
              <TextInput
                label="Email"
                type="email"
                input={{
                  value: email,
                  onChange: ({ target: { value } }) =>
                    this.setState({ email: value }),
                  onBlur: () => {},
                }}
                meta={{}}
              />
              <TextInput
                label="Password"
                type="password"
                input={{
                  value: password,
                  onChange: ({ target: { value } }) =>
                    this.setState({ password: value }),
                  onBlur: () => {},
                }}
                meta={{}}
              />
              <Col xs={12} style={{ textAlign: 'right' }}>
                <LoadingButton
                  variant="default"
                  label="Reset Password"
                  loading={passwordResetting}
                  loadingLabel="Sending Code.."
                  disabled={!email}
                  type="button"
                  onClick={this.onResetpasswordClicked}
                  style={{ marginRight: '5px' }}
                />
                <Button
                  type="submit"
                  disabled={!buttonsEnabled}
                  variant="primary"
                  onClick={this.onSaveClicked}
                >
                  Login
                </Button>
              </Col>
            </div>
          ) : (
            <iframe
              title="duo-mfa"
              id="duo-frame"
              style={{ width: '100%', height: '350px' }}
            />
          )}
        </Row>
      </form>
    );
  }
}

const mapStateToProps = () => ({});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setCurrentUser: setCurrentUserAction,
      redirectAfterLogin: redirectAfterLoginAction,
    },
    dispatch,
  );
export default connect(mapStateToProps, mapDispatchToProps)(ShowDuoMfaForm);
