// from: https://gist.github.com/insin/bbf116e8ea10ef38447b
import _ from 'underscore';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field } from 'redux-form';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { SelectInput } from 'lib/acromyrmex';
import { VaultServer } from '@contuit-otf/sdk';
import Vault from '../../../utility/vault';
import { loadVaultStructure as loadVaultStructureAction } from '../../../actions/vault';
import request from '../../../utility/request';

class VaultSelectInput extends React.Component {
  static propTypes = {
    enableAll: PropTypes.bool,
    multi: PropTypes.bool,
    enableEmpty: PropTypes.bool,
    name: PropTypes.string,
    slug: PropTypes.string.isRequired,
    vault: PropTypes.shape().isRequired,
    vertical: PropTypes.bool,
    help: PropTypes.string,
    onChangeAction: PropTypes.func,
    columns: PropTypes.number,
    query: PropTypes.shape(),
    normalize: PropTypes.func,
    validate: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.func),
      PropTypes.func,
    ]),
    noLabel: PropTypes.bool,
    labelOverride: PropTypes.string,
    disabled: PropTypes.bool,
    extraOptions: PropTypes.arrayOf(PropTypes.shape()),
    ignoreIds: PropTypes.arrayOf(PropTypes.string),
    currentUser: PropTypes.shape({
      token: PropTypes.string.isRequired,
    }).isRequired,

    loadVaultStructure: PropTypes.func.isRequired,
  };

  static defaultProps = {
    name: null,
    help: '',
    columns: 6,
    enableAll: false,
    multi: false,
    vertical: false,
    enableEmpty: false,
    disabled: false,
    validate: null,
    noLabel: false,
    extraOptions: [],
    ignoreIds: [],
    onChangeAction: () => {},
    normalize: (value) => value,

    query: {},
    labelOverride: null,
  };

  constructor() {
    super();
    this.state = {
      entries: [],
      entriesLoading: false,
      entriesLoadError: '',
    };
  }

  componentDidMount() {
    const { slug, loadVaultStructure } = this.props;

    loadVaultStructure(slug);
    this.loadVaultEntries();
  }

  componentDidUpdate(nextProps) {
    const { query } = nextProps;
    const { query: thisQuery } = this.props;

    if (JSON.stringify(query) !== JSON.stringify(thisQuery)) {
      this.loadVaultEntries();
    }
  }

  getOptions() {
    const { extraOptions, vault, slug, ignoreIds } = this.props;
    const { entries } = this.state;

    const options = Vault.getIdNamePairs(vault, slug, entries);
    return [...extraOptions, ...options.filter((o) => ignoreIds.indexOf(o.id))];
  }

  loadVaultEntries() {
    const {
      slug,
      query,
      currentUser: { token },
    } = this.props;

    this.setState({ entriesLoading: true });
    VaultServer.loadVaultEntries(
      '/api/vault',
      {
        token,
        slug,
        query: { ...query, cp_limit: -1 },
      },
      request,
    )
      .then(({ items: data }) => {
        //
        this.setState({ entries: data });
      })
      .catch(() => {
        this.setState({
          entriesLoadError: 'Unknown error loading vault structure data.',
        });
      })
      .then(() => {
        this.setState({ entriesLoading: false });
      });
  }

  render() {
    const {
      slug,
      name,
      vault,
      help,
      enableAll,
      enableEmpty,
      onChangeAction,
      columns,
      validate,
      normalize,
      multi,
      vertical,
      disabled,
      noLabel,
      labelOverride,
    } = this.props;

    const finalOptions = this.getOptions();
    const { entriesLoading, entriesLoadError } = this.state;

    if (entriesLoadError) {
      return <span>{entriesLoadError}</span>;
    }

    return (
      <Field
        {...this.props}
        component={SelectInput}
        label={
          labelOverride || (
            <span>
              {`${Vault.getStructDisplay(vault, slug)} `}(
              <Link
                to={{
                  pathname: `/vault/${slug}`,
                }}
              >
                <i className="fa fa-external-link" />
              </Link>
              ):
            </span>
          )
        }
        name={name || slug}
        options={finalOptions}
        validate={validate}
        normalize={normalize}
        vertical={vertical}
        disabled={disabled || finalOptions.length === 0}
        help={help}
        loading={entriesLoading}
        inputCols={columns}
        labelCols={columns}
        enableAll={enableAll}
        multi={multi}
        enableEmpty={enableEmpty}
        noLabel={noLabel}
        onChangeAction={(newVal, fieldName) => {
          onChangeAction(newVal, fieldName, _.pluck(finalOptions, 'id'));
        }}
      />
    );
  }
}

const mapStateToProps = (state, props) => ({
  ui: state.ui,
  vault: state.vault,
  struct: state.vault.structures[props.slug],
  currentUser: state.users.currentUser,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      loadVaultStructure: loadVaultStructureAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(VaultSelectInput);
