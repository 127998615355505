import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import PageLayout from '../shared/Layout/PageLayout';
import ShowComputationHelp, { computationHelp } from '../help/sections/ShowComputationHelp';

class ShowDocsBuildComputation extends Component {
  static propTypes = {};

  static defaultProps = {};

  state = {};

  render() {
    return (
      <PageLayout
        header="Computations"
        content={
          <form>
            <ShowComputationHelp />
          </form>
        }
      />
    );
  }
}

const values = {};
computationHelp.forEach(c => {
  values[c.name] = c.example;
});

export default reduxForm({ form: 'sampleForm', initialValues: values })(ShowDocsBuildComputation);
