import { AUTO_VAULT_CREATE_STEP_TYPE } from '../../../constants'
import AutoVaultCreateStepOptions from './components/stepOptions/AutoVaultCreateStepOptions'
// import VaultCreateStep from './components/stepExecutions/vaultCreate';
import VaultInputUiHelper from './VaultInputUiHelper'
import BasicUiHelper from './BasicUiHelper'

class AutoVaultCreateUiHelper extends BasicUiHelper {
  regex() {
    return AUTO_VAULT_CREATE_STEP_TYPE
  }

  name() {
    return 'Auto Vault Create'
  }

  canTryAgain() {
    return true
  }

  liveVault() {
    return true
  }

  getOptionSummary() {
    return [{ key: 'slug', title: 'Vault Structure', type: 'VaultStructure' }]
  }

  stepOptions() {
    return AutoVaultCreateStepOptions
  }

  getOutputKeys() {
    return {
      schema: {
        id: { type: 'LiveVault', vaultStructureStepOption: 'slug' },
      },
    }
  }

  getInputOptions(step, { structureList }) {
    return VaultInputUiHelper.getInputOptions(step, { structureList })
  }

  getOutputOptions(step, { structureList }) {
    return VaultInputUiHelper.getOutputOptions(step, { structureList })
  }
}

export default AutoVaultCreateUiHelper
