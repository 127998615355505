import React, { Component } from 'react';
import PageLayout from '../shared/Layout/PageLayout';
import ShowSchedulerHelp from '../help/sections/ShowSchedulerHelp';

class ShowDocsScheduler extends Component {
  static propTypes = {};

  static defaultProps = {};

  state = {};

  render() {
    return <PageLayout header="Process Scheduler" content={<ShowSchedulerHelp />} />;
  }
}

export default ShowDocsScheduler;
