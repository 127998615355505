import isString from 'lodash/isString';
import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import { Alert as BootStrapAlert } from '../../utility/UiComponents';

const Alert = ({ warning, message, success, info, pullLeft, pullRight }) => {
  let color = 'danger';
  let icon = 'exclamation-circle';

  if (warning) {
    color = 'warning';
    icon = 'warning';
  } else if (success) {
    color = 'success';
    icon = 'check';
  } else if (info) {
    color = 'info';
    icon = 'info';
  }

  return (
    <BootStrapAlert
      variant={color}
      className={classnames({ 'pull-left': pullLeft, 'pull-right': pullRight })}
    >
      {isString(message) && <i className={`fa fa-${icon}`} />}
      {isString(message) ? ` ${message}` : message}
    </BootStrapAlert>
  );
};

// Define property types
Alert.propTypes = {
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  warning: PropTypes.bool,
  success: PropTypes.bool,
  info: PropTypes.bool,
  pullLeft: PropTypes.bool,
  pullRight: PropTypes.bool
};

Alert.defaultProps = {
  warning: false,
  success: false,
  info: false,
  pullLeft: false,
  pullRight: false
};

export default Alert;
