import get from 'lodash/get'
import set from 'lodash/set'
import { DATE_FUNCTION_STEP_TYPE } from '../../../constants'
import DateFunctionStepOptions from './components/stepOptions/DateFunctionStepOptions'
import BasicUiHelper from './BasicUiHelper'
import StringHelper from '../../../shared-helpers/StringHelper'

class DateFunctionUiHelper extends BasicUiHelper {
  regex() {
    return DATE_FUNCTION_STEP_TYPE
  }

  name() {
    return 'Date Function'
  }

  canTryAgain() {
    return true
  }

  stepOptions() {
    return DateFunctionStepOptions
  }

  getOutputKeys({ stepOptions }) {
    const functions = get(stepOptions, 'functions', [])
    const schema = {}

    // We need to loop over the functions defined and add them to our data
    functions.forEach((func) => {
      set(schema, func.key, { type: 'Label', title: func.display })
    })

    return { schema }
  }

  getOutputOptions(step) {
    return (step.stepOptions.functions || []).map((field) => ({
      id: JSON.stringify({
        step: step._id,
        field: field.key ? field.key : StringHelper.slugify(field.display),
      }),
      name: `${field.display}`,
      textValue: `{${step._id}.${field.key ? field.key : StringHelper.slugify(field.display)}}`,
      type: field.type,
    }))
  }
}

export default DateFunctionUiHelper
