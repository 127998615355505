const ENABLED_INTEGRATION_INITIAL = {
  enabledIntegrations: {
    rows: [],
    hasLoaded: false,
    isLoading: false,
    isCreating: false,
    isUpdating: false,
    isDeleting: false,
  },
  enabledIntegration: {
    value: null,
    isLoading: false,
    showModal: false,
  },
  integrationOptions: {},
  simpleEnabledIntegrations: {
    rows: [],
    hasLoaded: false,
    isLoading: false,
  },
}

export default (state = ENABLED_INTEGRATION_INITIAL, action = {}) => {
  switch (action.type) {
    case 'SET_MODAL_VISIBLE': {
      const nextState = { ...state }

      nextState.enabledIntegration.showModal = action.show

      return nextState
    }
    case 'ADD_ENABLED_INTEGRATIONS': {
      const nextState = { ...state }

      nextState.enabledIntegrations.rows = action.data

      return nextState
    }
    case 'ADD_INTEGRATION_OPTIONS': {
      const nextState = { ...state }

      nextState.integrationOptions = action.data
      nextState.enabledIntegrations.hasLoaded = true

      return nextState
    }
    case 'ADD_SIMPLE_ENABLED_INTEGRATIONS': {
      const nextState = { ...state }

      nextState.simpleEnabledIntegrations.rows = action.data
      nextState.simpleEnabledIntegrations.hasLoaded = true

      return nextState
    }
    case 'ADD_ENABLED_INTEGRATION': {
      const nextState = { ...state }

      nextState.enabledIntegration.value = action.data

      return nextState
    }
    case 'ENABLED_INTEGRATIONS_LOADING': {
      const nextState = { ...state }

      nextState.enabledIntegrations.isLoading = action.loading

      return nextState
    }
    case 'SIMPLE_ENABLED_INTEGRATIONS_LOADING': {
      const nextState = { ...state }

      nextState.simpleEnabledIntegrations.isLoading = action.loading

      return nextState
    }
    case 'ENABLED_INTEGRATION_LOADING': {
      const nextState = { ...state }

      nextState.enabledIntegration.isLoading = action.loading

      return nextState
    }
    case 'REMOVE_ENABLED_INTEGRATION': {
      const { rows } = state.enabledIntegrations;
      const { id } = action;
    
      // Filter out the integration with the matching ID
      const updatedRows = rows.filter(row => row._id !== id);
    
      return {
        ...state,
        enabledIntegrations: {
          ...state.enabledIntegrations,
          rows: updatedRows,
          hasLoaded: updatedRows.length > 0, // Update hasLoaded based on remaining rows
        },
      };
    }    
    case 'ENABLED_INTEGRATION_DELETING': {
      const nextState = { ...state }

      for (let i = 0; i < nextState.enabledIntegrations.rows.length; i++) {
        if (nextState.enabledIntegrations.rows[i]._id === action.id) {
          nextState.enabledIntegrations.rows[i] = {
            ...nextState.enabledIntegrations.rows[i],
            isDeleting: action.deleting
          };
          break;
        }
      }

      return nextState
    }
    default:
      return state
  }
}
