import _ from 'underscore'

const PROCESSES_INITIAL = {
  executions: {
    hasLoaded: false,
    isLoading: false,
    rows: [],
    totalItemCount: 0,
  },
  execution: {
    isLoading: false,
    parentLoading: false,
    childLoading: false,
    isCreating: false,
    isUpdating: false,
    error: '',
    value: null,
    parent: null,
    // dictionary of child executions with their ID as a key
    children: {},
    socketOpen: false,
    commentBoxText: '',
    overviewShow: false,
  },

  processes: {
    isLoading: false,
    isCreating: false,
    rows: [],
  },
  process: {
    isLoading: false,
    isCreating: false,
    isUpdating: false,
    disableModalOpen: false,
    enableModalOpen: false,
    error: '',
    value: null,
    durations: null,
    validationLoading: false,
    validation: null,
  },
  settings: {
    isLoading: true,
    isUpdating: false,
    value: null,
    error: null
  },
}

export default (state = PROCESSES_INITIAL, action = {}) => {
  switch (action.type) {
    case 'EXECUTIONS_LOADING': {
      const nextState = Object.assign({}, state)

      nextState.executions.isLoading = action.loading
      nextState.executions.hasLoaded =
        state.executions.hasLoaded || !action.loading
      return nextState
    }
    case 'EXECUTION_LOADING': {
      const nextState = Object.assign({}, state)

      nextState.execution.isLoading = action.loading
      return nextState
    }
    case 'EXECUTION_PARENT_LOADING': {
      const nextState = Object.assign({}, state)

      nextState.execution.parentLoading = action.loading
      return nextState
    }
    case 'EXECUTION_CHILD_LOADING': {
      const nextState = Object.assign({}, state)

      nextState.execution.childLoading = action.loading
      return nextState
    }
    case 'EXECUTION_UPDATING': {
      const nextState = Object.assign({}, state)

      nextState.execution.isUpdating = action.updating
      return nextState
    }
    case 'EXECUTION_DELETING': {
      const nextState = Object.assign({}, state)

      nextState.execution.isDeleting = action.deleting
      return nextState
    }
    case 'PROCESSES_LOADING': {
      const nextState = Object.assign({}, state)

      nextState.processes.isLoading = action.loading
      return nextState
    }
    case 'PROCESSES_VALIDATION_LOADING': {
      const nextState = Object.assign({}, state)

      nextState.process.validationLoading = action.loading
      return nextState
    }
    case 'PROCESSES_CREATING': {
      const nextState = Object.assign({}, state)

      nextState.processes.isCreating = action.creatings
      return nextState
    }
    case 'PROCESS_LOADING': {
      const nextState = Object.assign({}, state)

      nextState.process.isLoading = action.loading
      return nextState
    }
    case 'PROCESS_UPDATING': {
      const nextState = Object.assign({}, state)

      nextState.process.isUpdating = action.updating
      return nextState
    }
    case 'PROCESS_DELETING': {
      const nextState = Object.assign({}, state)

      nextState.process.isDeleting = action.deleting
      return nextState
    }
    case 'SET_PROCESS_ERROR': {
      const nextState = Object.assign({}, state);

      nextState.process.isLoading = false;
      nextState.process.error = action.error;

      return nextState;
    }
    case 'SET_SETTINGS_LOADING': {
      const nextState = Object.assign({}, state)

      nextState.settings.isLoading = action.loading
      return nextState
    }
    case 'SET_SETTINGS_UPDATING': {
      const nextState = Object.assign({}, state)

      nextState.settings.isUpdating = action.loading
      return nextState
    }
    case 'SET_SETTINGS_ERROR': {
      const nextState = Object.assign({}, state);

      nextState.settings.error = action.error;
      nextState.settings.isLoading = action.loading;

      return nextState;
    }
    case 'ADD_EXECUTION_ERROR': {
      const nextState = Object.assign({}, state)

      nextState.execution.error = action.error
      return nextState
    }
    case 'ADD_EXECUTIONS': {
      const nextState = Object.assign({}, state)

      nextState.executions.rows = action.executions
      nextState.executions.totalItemCount = action.totalItemCount
      return nextState
    }
    case 'ADD_EXECUTION': {
      const nextState = Object.assign({}, state)

      nextState.execution.value = action.execution
      return nextState
    }
    case 'ADD_PARENT_EXECUTION': {
      return {
        ...state,
        execution: { ...state.execution, parent: action.execution },
      }
    }
    case 'ADD_CHILD_EXECUTION': {
      return {
        ...state,
        execution: {
          ...state.execution,
          children: {
            ...state.execution.children,
            [action.child]: action.execution,
          },
        },
      }
    }
    case 'ADD_EXECUTION_OUTPUT': {
      const nextState = Object.assign({}, state)

      // only update if we have the step
      if (!nextState.execution.step) {
        return
      }

      // when updating and we have an id, push
      let newId
      if (
        nextState.execution.step &&
        nextState.execution.step.id &&
        action.output.id
      ) {
        newId = [...nextState.execution.step.id, action.output.id]
      }

      nextState.execution.step = {
        ...nextState.execution.step,
        ...action.output,
      }

      if (newId) {
        nextState.execution.step = {
          ...nextState.execution.step,
          id: newId,
        }
      }

      if (_.isString(nextState.execution.step.id)) {
        nextState.execution.step.id = [nextState.execution.step.id]
      }

      return nextState
    }

    case 'GET_DURATIONS': {
      const nextState = Object.assign({}, state)

      nextState.process.durations = action.durations
      return nextState
    }

    case 'REMOVE_EXECUTION': {
      const nextState = Object.assign({}, state)

      for (let i = 0; i < nextState.executions.rows.length; i++) {
        if (nextState.executions.rows[i]._id === action.id) {
          nextState.executions.rows.splice(i, 1)
          break
        }
      }

      // clear our single data if it matches
      if (
        nextState.execution.value &&
        nextState.execution.value._id === action.id
      ) {
        nextState.execution.value = null
      }

      return nextState
    }
    case 'ADD_PROCESSES': {
      const nextState = Object.assign({}, state)

      nextState.processes.rows = action.processes
      return nextState
    }
    case 'ADD_PROCESS': {
      const nextState = Object.assign({}, state)

      nextState.process.value = action.process

      for (let i = 0; i < nextState.processes.rows.length; i++) {
        if (nextState.processes.rows[i]._id === action.process._id) {
          nextState.processes.rows.splice(i, 1, action.process)
          break
        }
      }

      return nextState
    }
    case 'ADD_PROCESS_VALIDATION': {
      const nextState = Object.assign({}, state)

      nextState.process.validation = action.validation

      return nextState
    }
    case 'REMOVE_PROCESS': {
      const nextState = Object.assign({}, state)

      for (let i = 0; i < nextState.processes.rows.length; i++) {
        if (nextState.processes.rows[i]._id === action.id) {
          nextState.processes.rows.splice(i, 1)
          break
        }
      }

      // clear our single data if it matches
      if (
        nextState.process.value &&
        nextState.process.value._id === action.id
      ) {
        nextState.process.value = null
      }

      return nextState
    }
    case 'EXECUTION_CREATING': {
      const nextState = Object.assign({}, state)

      nextState.execution.isCreating = action.creating
      return nextState
    }
    case 'ADD_SETTINGS': {
      const nextState = Object.assign({}, state)

      nextState.settings.value = action.settings

      return nextState
    }
    case 'EXECUTION_SOCKET_OPEN': {
      const nextState = Object.assign({}, state)

      nextState.execution.socket = action.socket
      nextState.execution.socketOpen = action.open
      return nextState
    }
    case 'CLEAR_COMMENT_BOX': {
      const nextState = Object.assign({}, state)

      nextState.execution.commentBoxText = ''
      return nextState
    }
    case 'UPDATE_COMMENT_BOX': {
      const nextState = Object.assign({}, state)

      nextState.execution.commentBoxText = action.text
      return nextState
    }
    case 'SET_EXECUTION_OVERVIEW_STATE': {
      const nextState = Object.assign({}, state)

      nextState.execution.overviewShow = action.overviewShow
      return nextState
    }
    case 'SET_DISABLE_PROCESS_MODAL': {
      return { ...state, process: { disableModalOpen: action.open } }
    }
    case 'SET_ENABLE_PROCESS_MODAL': {
      return { ...state, process: { enableModalOpen: action.open } }
    }
    default:
      return state
  }
}
