import { NARRATIVE_STEP_TYPE } from '../../../constants'
import BasicUiHelper from './BasicUiHelper'
import NarrativeStepOptions from '../steps/components/stepOptions/narrative/NarrativeStepOptions'

class NarrativeUiHelper extends BasicUiHelper {
  regex() {
    return NARRATIVE_STEP_TYPE
  }

  name() {
    return 'Narrative'
  }

  stepExecution() {
    return null
  }

  canTryAgain() {
    return true
  }

  stepOptions() {
    return NarrativeStepOptions
  }

  getOutputKeys() {
    return {
      title: 'Narrative',
      schema: {
        narrative: {
          type: 'Label',
        },
      },
    }
  }
  getOutputOptions(step) {
    return [
      {
        id: JSON.stringify({ step: step._id, key: 'narrative' }),
        name: 'narrative',
        textValue: `{${step._id}.narrative}`,
        type: 'string',
      },
    ]
  }
}

export default NarrativeUiHelper
