// TODO: move the current user/checkedToken to a 'auth' action and reducer
const USER_INITIAL = {
  currentUser: false,
  postLoginRedirect: null,
  disableModalOpen: false,
  enableModalOpen: false,
  deleteModalOpen: false,
  rolesModalOpen: false,

  // Use this for knowing initial state
  checkedToken: false,

  isFetching: false,
  isCreating: false,
  isDeleting: false,

  // used for listing users
  users: {
    isLoading: false,
    rows: [],
    count: 0,
    page: 1,
  },

  availableUserRoles: {
    isLoading: false,
    rows: [],
  },

  user: {
    isLoading: false,
    isCreating: false,
    isUpdating: false,
    error: '',
    value: null,
  },

  simpleUsers: {
    isLoading: false,
    rows: [],
  },

  tenants: {
    isLoading: false,
    rows: [],
  },
}

export default (state = USER_INITIAL, action = {}) => {
  switch (action.type) {
    case 'USER_LOADING': {
      const nextState = Object.assign({}, state)

      nextState.user.isLoading = action.loading
      return nextState
    }
    case 'USER_UPDATING': {
      const nextState = Object.assign({}, state)

      nextState.user.isUpdating = action.updating
      return nextState
    }
    case 'ADD_USER': {
      const nextState = Object.assign({}, state)

      nextState.user.value = action.user

      for (let i = 0; i < nextState.users.rows.length; i++) {
        if (nextState.users.rows[i]._id === action.user._id) {
          nextState.users.rows.splice(i, 1, action.user)
          break
        }
      }

      return nextState
    }
    case 'FETCHING_USER':
      return Object.assign({}, state, {
        isFetching: action.isFetching,
      })
    case 'SHOW_USER':
      return Object.assign({}, state, {
        user: action.user,
      })
    case 'USERS_LOADING': {
      const nextState = Object.assign({}, state)

      nextState.users.isLoading = action.loading
      return nextState
    }
    case 'SET_POST_LOGIN_PAGE': {
      return { ...state, postLoginRedirect: action.location }
    }
    case 'SIMPLE_USERS_LOADING': {
      const nextState = Object.assign({}, state)

      nextState.simpleUsers.isLoading = action.loading
      return nextState
    }
    case 'TENANTS_LOADING': {
      const nextState = Object.assign({}, state)

      nextState.tenants.isLoading = action.loading
      return nextState
    }
    case 'SHOW_USERS':
      return Object.assign({}, state, {
        users: {
          rows: action.users,
        },
      })
    case 'ADD_SIMPLE_USERS':
      return Object.assign({}, state, {
        simpleUsers: {
          rows: action.users,
        },
      })
    case 'ADD_TENANTS':
      return Object.assign({}, state, {
        tenants: {
          rows: action.tenants,
        },
      })
    case 'UPDATE_TENANT': {
      const nextState = {
        ...state,
        currentUser: {
          ...state.currentUser,
          tenants: state.currentUser.tenants.map(tenant =>
            tenant._id === action.tenant._id ? { ...action.tenant } : tenant
          )
        }
      };

      nextState.currentUser.contuitClient = action.tenant;

      return nextState;
    }
    case 'REMOVE_USER': {
      const nextState = Object.assign({}, state)

      for (let i = 0; i < nextState.users.rows.length; i++) {
        if (nextState.users.rows[i]._id === action.id) {
          nextState.users.rows.splice(i, 1)
          break
        }
      }

      return nextState
    }
    case 'REQUEST_CREATE_USER':
      return Object.assign({}, state, {
        isCreating: true,
      })
    case 'RESOLVE_CREATE_USER':
      return Object.assign({}, state, {
        isCreating: false,
      })
    case 'REQUEST_DELETE_USER':
      return Object.assign({}, state, {
        isDeleting: true,
      })
    case 'RESOLVE_DELETE_USER':
      return Object.assign({}, state, {
        isDeleting: false,
      })
    case 'SET_TOKEN': {
      const nextState = Object.assign({}, state)
      nextState.currentUser.token = action.token
      return nextState
    }
    case 'AUTH_USER': {
      const nextState = Object.assign({}, state)

      const oldToken = nextState.currentUser.token

      nextState.currentUser = action.user
      nextState.checkedToken = true

      if (!nextState.currentUser.token) {
        nextState.currentUser.token = oldToken
      }

      return nextState
    }
    case 'UNAUTH_USER':
      return Object.assign({}, state, {
        currentUser: false,
      })
    case 'TOKEN_CHECKED':
      return Object.assign({}, state, {
        checkedToken: true,
      })
    case 'AVAILABLE_USER_ROLES_LOADING': {
      const nextState = Object.assign({}, state)

      nextState.availableUserRoles.isLoading = action.loading
      return nextState
    }
    case 'ADD_AVAILABLE_USER_ROLES': {
      const nextState = Object.assign({}, state)

      nextState.availableUserRoles.rows = action.roles
      return nextState
    }
    case 'SET_DISABLE_MODAL': {
      return { ...state, disableModalOpen: action.open }
    }
    case 'SET_ENABLE_MODAL': {
      return { ...state, enableModalOpen: action.open }
    }
    case 'SET_DELETE_MODAL': {
      return { ...state, deleteModalOpen: action.open }
    }
    case 'SET_ROLES_MODAL': {
      return { ...state, rolesModalOpen: action.open }
    }
    default:
      return state
  }
}
