import React, { Component } from 'react';
import PageLayout from '../shared/Layout/PageLayout';
import ExecutionMarkdown from '../processes/ExecutionMarkdown';

const markdown = `
 1. Do this
 2. Do that
`;

class ShowDocsGettingStarted extends Component {
  static propTypes = {};

  static defaultProps = {};

  state = {};

  render() {
    return (
      <PageLayout
        header="Getting Started with Contuit"
        content={<ExecutionMarkdown source={markdown} />}
      />
    );
  }
}

export default ShowDocsGettingStarted;
