import React, { useEffect, useMemo } from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { LinkContainer } from 'react-router-bootstrap';
import { bindActionCreators } from 'redux';
import { Loading } from 'lib/acromyrmex';
import DataTable from '../shared/DataTable/DataTable';
import { Col, Alert } from '../../utility/UiComponents';
import IntegrationButtons from './IntegrationButtons';
import {
  loadEnabledIntegrations as loadEnabledIntegrationsAction,
  deleteEnabledIntegration as deleteEnabledIntegrationAction
} from '../../actions/enabledIntegrations';
import { loadVaultStructureData as loadVaultStructureDataAction } from '../../actions/vault';
import EmptyView from '../shared/EmptyView';
import AddButton from '../shared/form/AddButton';
import PageLayout from '../shared/Layout/PageLayout';
import { useLocation } from 'react-router-dom';

const ListIntegrations = ({
  loadEnabledIntegrations,
  loadVaultStructureData,
  integrations,
  isLoading,
  isDeleting,
  deleteEnabledIntegration,
  currentUser,
  routes,
}) => {
  const location = useLocation();
  const completedMessage = useMemo(() => location?.query?.completedMessage, [location]);

  useEffect(() => {
    loadVaultStructureData('customers', {}, false, { limit: -1 });
    loadEnabledIntegrations();

    document.title = 'Contuit | My Integrations';

    return () => {
      document.title = 'Contuit';
    };
  }, [loadVaultStructureData, loadEnabledIntegrations]);

  const getColumns = () => [
    {
      display: 'Name',
      key: 'name',
      isPrimary: true,
    },
    {
      display: 'Product',
      key: 'spec.info',
      formatter: (item) => get(item, 'original.spec.info.title'),
      csvFormatter: (item) => get(item, 'spec.info.title'),
      isPrimary: true,
      type: 'withFormatter',
    },
    {
      display: 'Endpoint',
      key: 'endpoint',
      isPrimary: true,
    },
    {
      display: 'Default Integration',
      key: 'defaultIntegration',
      isPrimary: true,
      type: 'boolean',
      width: 150,
    },
    {
      display: 'Actions',
      key: 'actions',
      width: 180,
      formatter: (item) => (
        <IntegrationButtons
          integration={get(item, 'original')}
          deleteEnabledIntegration={deleteEnabledIntegration}
          isDeleting={isDeleting}
          viewDetails
        />
      ),
      isPrimary: true,
    },
  ];

  const getRows = () => (!integrations ? [] : integrations);

  const hasRows = () => getRows().length > 0;

  return (
    <PageLayout
      routes={routes}
      currentUser={currentUser}
      header="Enabled Integrations"
      headerButtons={
        <LinkContainer to={{ pathname: '/admin/integrations/available' }}>
          <AddButton size="sm" label="Add an Available Integration" size="sm" />
        </LinkContainer>
      }
      noContentPadding
      content={
        <div style={{ height: '100%' }}>
          {isLoading && (
            <Alert variant="info" className="pull-left indented">
              <Loading /> Loading Integrations
            </Alert>
          )}
          {completedMessage && (
            <Col xs={12}>
              <Alert variant="success" className="pull-left indented" style={{ marginBottom: 0 }}>
                <i className="fa fa-check" />
                Integration Successfully added! You may close this window and return.
              </Alert>
            </Col>
          )}
          {!isLoading && !hasRows() && (
            <EmptyView
              header="No enabled integrations found."
              content="It looks like you haven't enabled any integrations yet!"
              button={{
                pathname: '/admin/integrations/available',
                content: 'See All Available Integrations',
                style: 'info',
              }}
            />
          )}
          {!isLoading && hasRows() && (
            <DataTable
              title={false}
              csvTitle="enabled integrations"
              fetchCSVData={() => ({
                items: getRows(),
              })}
              data={getRows()}
              columns={getColumns()}
            />
          )}
        </div>
      }
    />
  );
};

ListIntegrations.propTypes = {
  loadEnabledIntegrations: PropTypes.func.isRequired,
  currentUser: PropTypes.shape().isRequired,
  routes: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  loadVaultStructureData: PropTypes.func.isRequired,
  integrations: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  isLoading: PropTypes.bool.isRequired,
  deleteEnabledIntegration: PropTypes.func.isRequired,
  isDeleting: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  currentUser: state.users.currentUser,
  integrations: state.enabledIntegrations.enabledIntegrations.rows,
  isLoading: state.enabledIntegrations.enabledIntegrations.isLoading,
  isDeleting: state.enabledIntegrations.enabledIntegrations.isDeleting,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      loadEnabledIntegrations: loadEnabledIntegrationsAction,
      deleteEnabledIntegration: deleteEnabledIntegrationAction,
      loadVaultStructureData: loadVaultStructureDataAction,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ListIntegrations);
