import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Field } from 'redux-form'
import { SelectInput } from 'lib/acromyrmex'
import PropTypes from 'prop-types'
import {
  Row,
  Col,Accordion,
} from '../../../../../utility/UiComponents'
import { findCollections } from '../../../../../utility/ProcessFormOptionHelpers'

class ManualSelectStepOptions extends React.Component {
  render() {
    const { fieldText, onChangeAttributeNeeded, outputOptions } = this.props

    return (
      <Row key={fieldText} style={{ marginBottom: 10 }}>
        <Accordion defaultActiveKey="1">
          <Accordion.Item eventKey="1">
            <Accordion.Header>Manual Select Step Options</Accordion.Header>
            <Accordion.Body>
              <Col xs={12} style={{ marginBottom: 10 }}>
                <Field
                  name={`${fieldText}.stepOptions.sourceData`}
                  component={SelectInput}
                  label="Which data to use as a source"
                  templateOptions={findCollections(outputOptions)}
                  onChange={() => onChangeAttributeNeeded(null)}
                />
              </Col>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Row>
    )
  }
}

ManualSelectStepOptions.propTypes = {
  fieldText: PropTypes.string.isRequired,
  onChangeAttributeNeeded: PropTypes.func.isRequired,
  outputOptions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
}

ManualSelectStepOptions.defaultProps = {}

const mapStateToProps = (state) => ({
  structureList: state.vault.structureList.rows,
  formValues: state.form.processForm ? state.form.processForm.values : {},
})

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ManualSelectStepOptions)
