import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import VaultStructureForm from './VaultStructureForm';
import { createStructure as createStructureAction } from '../../actions/vault';
import PageLayout from '../shared/Layout/PageLayout';

class CreateVaultStructure extends React.Component {
  onFormSubmit = this.onFormSubmit.bind(this);

  static propTypes = {
    // vault: PropTypes.shape().isRequired,
    formValues: PropTypes.shape(),
    createStructure: PropTypes.func.isRequired,
    currentUser: PropTypes.shape().isRequired,
    routes: PropTypes.arrayOf(PropTypes.shape()).isRequired
  };

  static defaultProps = {
    formValues: {}
  };

  onFormSubmit() {
    const { formValues, createStructure } = this.props;

    createStructure(formValues);
  }

  render() {
    const { currentUser, routes } = this.props;
    const struct = {
      label: '{name}',
      sortAttribute: 'name',
      attributes: [
        {
          display: 'Name',
          key: 'name',
          type: 'string',
          isPrimary: true,
          required: true,
          editable: true
        }
      ]
    };

    return (
      <PageLayout
        currentUser={currentUser}
        routes={routes}
        header="Create Structure"
        content={<VaultStructureForm initialValues={struct} onSubmit={this.onFormSubmit} />}
      />
    );
  }
}

const mapStateToProps = state => ({
  vault: state.vault,
  currentUser: state.users.currentUser,
  formValues: state.form.vaultStructureForm ? state.form.vaultStructureForm.values : {}
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      createStructure: createStructureAction
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CreateVaultStructure);
