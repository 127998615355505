import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import last from 'lodash/last';
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';
import { LinkContainer } from 'react-router-bootstrap';
import ConditionalHelper from '../../../process/helpers/ConditionalHelper';
import TimelineEvent, { TimelineTimestamp, TimelineMarker } from './TimelineEvent';
import ExecuteStepShell from '../../dashboards/ExecuteStepShell';
import { Button } from '../../../utility/UiComponents';
import ShowStepOutput from './ShowStepOutput';
import {
  wasStepSkipped,
  getCurrentExecutionStep,
  getProcessStepFromExecutionStep
} from '../../../shared-helpers/execution';
import StepHelper from '../steps/UiStepHelper';
import { FlexRow } from '../../shared/Flex';
import { VaultStructureMention } from './VaultEntryMention';
import TrundleRow from '../../shared/TrundleRow';
import UserThumbnail from '../../user/UserThumbnail';
import AutoDataTable from '../../shared/DataTable/AutoDataTable';
import { StepConditionOptions } from '../ProcessForm/StepCondition';
import useTemplateString from '../../../hooks/useTemplateString';

const ShowCurrentStepEvent = ({ execution }) => {
  const currentExecutionStep = getCurrentExecutionStep(execution);
  const currentProcessStep =
    currentExecutionStep && getProcessStepFromExecutionStep(execution, currentExecutionStep);

  return (
    <TimelineEvent
      timestamp={new Date().getTime()}
      execution={execution}
      icon="terminal"
      name={`Current Step: ${get(currentProcessStep, 'name', '')}`}
      content={<ExecuteStepShell execution={execution} />}
    />
  );
};

ShowCurrentStepEvent.propTypes = {
  execution: PropTypes.shape().isRequired
};

const TimelineSpacer = () => <div className="timeline-event timeline-event-spacer" />;

const ShowProcessCompletedEvent = ({ execution, parentExecution }) => (
  <TimelineEvent
    timestamp={last(execution.steps)?.timeCompleted || 'Invalid Date'}
    name="Process Completed"
    icon="check"
    content={
      execution.parent &&
      parentExecution && (
        <LinkContainer
          to={{
            pathname: `/processes/${parentExecution.process._id}/executions/${parentExecution._id}`
          }}
          className="pull-left"
        >
          <Button variant="primary" size="xsmall">
            Back to parent
          </Button>
        </LinkContainer>
      )
    }
  />
);

ShowProcessCompletedEvent.propTypes = {
  execution: PropTypes.shape().isRequired,
  parentExecution: PropTypes.shape()
};

ShowProcessCompletedEvent.defaultProps = {
  parentExecution: null
};

const ShowProcessInitiationEvent = ({ execution }) => {
  const initiationType = get(execution, 'process.initiation.initiationType', 'basic');
  const isTriggerInitiation = initiationType === 'trigger';
  let initiationData = get(execution, 'initiationData');

  // parse if needed
  if (isTriggerInitiation && !isObject(initiationData)) {
    initiationData = JSON.parse(initiationData);
  }

  // turn into an array
  if (isTriggerInitiation && !isArray(initiationData)) {
    initiationData = [initiationData];
  }

  return (
    <TimelineEvent
      execution={execution}
      timestamp={execution.dateCreated}
      icon="plus"
      name={`Process ${initiationType === 'trigger' ? 'Triggered' : 'Started'}`}
      content={
        (initiationType === 'trigger' || initiationType === 'cbd-event') &&
        execution.initiationData && (
          <AutoDataTable title={false} data={initiationData} maxHeight="300px" />
        )
      }
    />
  );
};

ShowProcessInitiationEvent.propTypes = {
  execution: PropTypes.shape().isRequired
};

const ConditionsView = ({ processStep, executionStep, execution, currentUser }) => {
  const SkippedType = StepHelper.getStepSkipped(processStep.stepType);
  const stepSkipped = wasStepSkipped(executionStep);

  const allConditions = processStep.conditions || [];

  if (!allConditions.length) {
    return null;
  }

  return (
    <div>
      <strong>Step Conditions</strong>
      {allConditions.map(condition => {
        const conditionType = StepConditionOptions.find(c => c.id === condition.conditionType);

        const [lhv, lhvError] = useTemplateString(
          condition.lhv,
          currentUser,
          execution,
          'loading...'
        );

        const [rhv, rhvError] = useTemplateString(
          condition.rhv,
          currentUser,
          execution,
          'loading...'
        );

        return (
          <div key={condition.name}>
            {ConditionalHelper.doesConditionPass(lhv, condition.conditionType, rhv) ? '✅' : '❌'}{' '}
            {lhv || lhvError} {conditionType.name} {rhv || rhvError}
          </div>
        );
      })}
      {stepSkipped && SkippedType && (
        <div>
          <SkippedType execution={execution} currentExecutionStep={executionStep} />
        </div>
      )}
    </div>
  );
};

ConditionsView.propTypes = {
  execution: PropTypes.shape().isRequired,
  executionStep: PropTypes.shape().isRequired,
  processStep: PropTypes.shape().isRequired,
  currentUser: PropTypes.shape().isRequired
};

const ShowPastStepEvent = ({
  execution,
  executionStep,
  vault,
  currentUser,
  integrationOptions
}) => {
  const processStep = executionStep && getProcessStepFromExecutionStep(execution, executionStep);
  const options = StepHelper.getOptionSummary(processStep);
  const outputKeys = StepHelper.getOutputKeys(processStep, {
    stepOptions: processStep.stepOptions
  });

  const stepOptions = [];
  // Loop over the option summary for this step type and build its output. This section takes
  // the step options for the step and presents them in a relevant way to the user
  options.forEach(option => {
    // if the key is `$c_stepType` then we want to show the step type name
    let value;
    if (option.key === '$c_stepType') {
      const integrationOption = integrationOptions && integrationOptions[processStep.stepType];
      value =
        (integrationOption && `${integrationOption.brand} - ${integrationOption.name}`) ||
        processStep.stepType;
    } else if (option.key === 'parameters') {
      const parameters = get(processStep.stepOptions, option.key);
      const dynamicParameters = get(processStep.stepOptions, 'dynamicParameters');
      value =
        parameters || dynamicParameters
          ? [...(parameters || []), ...(dynamicParameters || [])]
          : null;
    } else {
      value = get(processStep.stepOptions, option.key);
    }

    if (!value) {
      return;
    }

    let output = value;
    if (option.formatter) {
      output = option.formatter(value, currentUser, execution);
    } else if (option.type === 'VaultStructure') {
      output = <VaultStructureMention vaultStructure={value} vault={vault} />;
    }

    stepOptions.push(
      <FlexRow style={{ marginBottom: '1px' }}>
        <div style={{ marginRight: '5px' }}>
          <strong>{option.title}</strong>
        </div>
        <div>{output}</div>
      </FlexRow>
    );
  });

  const stepTypeName = StepHelper.getStepTypeName(processStep.stepType);
  const stepSkipped = wasStepSkipped(executionStep);

  return (
    <TimelineEvent
      execution={execution}
      faded={stepSkipped}
      executionStepId={executionStep._id}
      timestamp={new Date(executionStep.timeCompleted).getTime()}
      icon={stepSkipped ? 'code-fork' : 'check-square-o'}
      name={
        <>
          <div className="title-label" style={{ flex: 1 }}>
            {`${stepTypeName} Step ${stepSkipped ? 'Skipped' : 'Completed'}: ${processStep.name}`}
          </div>
        </>
      }
      description={get(processStep, 'stepOptions.description')}
      showLogLink={!executionStep.skipped}
      content={[
        <ConditionsView
          execution={execution}
          conditions={executionStep.skipped}
          executionStep={executionStep}
          currentUser={currentUser}
          processStep={processStep}
        />,
        !stepSkipped && stepOptions,
        outputKeys.schema && !stepSkipped && (
          <ShowStepOutput execution={execution} executionStep={executionStep} />
        )
      ]}
      user={
        Number(executionStep.userCompleted) === -1
          ? { name: 'Contuit' }
          : execution.users.find(a => a._id === executionStep.userCompleted)
      }
    />
  );
};

ShowPastStepEvent.propTypes = {
  execution: PropTypes.shape().isRequired,
  currentUser: PropTypes.shape().isRequired,
  executionStep: PropTypes.shape().isRequired,
  integrationOptions: PropTypes.shape().isRequired,
  vault: PropTypes.shape().isRequired
};

const getLogText = (execution, logItem) => {
  let toVal = logItem.to;
  let verbText;
  const toUser = execution.users.find(u => u._id === logItem.to);
  const userThumbnail = (
    <span style={{ paddingTop: '3px' }}>
      <UserThumbnail user={toUser} radius="20px" inline small popover /> {toUser.name}
    </span>
  );
  if (logItem.field === 'assignee') {
    toVal = toUser ? userThumbnail : toVal;
  } else if (logItem.field === 'users') {
    const added = logItem.from === 'add';

    if (added) {
      toVal = toUser ? userThumbnail : toVal;

      verbText = (
        <span>
          added <strong>{toVal}</strong> as a subscriber
        </span>
      );
    }
  } else if (logItem.field === 'log') {
    verbText = <span>{toVal}</span>;
  }

  if (!verbText) {
    verbText = (
      <span>
        {` changed ${logItem.field} to `}
        <strong>{toVal}</strong>
      </span>
    );
  }

  return verbText;
};

const ShowLogEvent = ({ execution, logItem }) => {
  const logUser = execution.users.find(u => u._id === logItem.user);
  return (
    <TrundleRow
      className="timeline-event"
      prefix={[
        <TimelineTimestamp timestamp={new Date(logItem.createdAt).getTime()} />,
        <TimelineMarker icon="edit" />
      ]}
      content={<h4 className="timeline-title comment">{getLogText(execution, logItem)}</h4>}
      suffix={[<UserThumbnail user={logUser} popover />]}
    />
  );
};

ShowLogEvent.propTypes = {
  execution: PropTypes.shape().isRequired,
  logItem: PropTypes.shape().isRequired
};

export {
  ShowCurrentStepEvent,
  ShowPastStepEvent,
  ShowProcessCompletedEvent,
  ShowProcessInitiationEvent,
  TimelineSpacer,
  ShowLogEvent
};
