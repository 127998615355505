import _ from 'underscore';
import moment from 'moment';
import StringHelper from 'shared-helpers/StringHelper';
import {
  ProcessServer,
  AuthenticationServer,
  VaultServer,
} from '@contuit-otf/sdk';
import request from '../utility/request';
import {
  HOST,
  AUTH_SERVER,
  PROCESS_SERVER,
  VAULT_INPUT_STEP_TYPE,
  VAULT_CREATE_STEP_TYPE,
  AUTO_VAULT_CREATE_STEP_TYPE,
  VAULT_SERVER,
} from '../constants';

const vaultServer = new VaultServer(VAULT_SERVER, request);
const processServer = new ProcessServer(PROCESS_SERVER, request);

export const getIntegrationFromStepType = (stepType) => {
  const stopPoint = stepType.indexOf(')[');

  return stepType.substring(2, stopPoint);
};

export const getActionFromStepType = (stepType) => {
  const stopPoint = stepType.indexOf(')[');

  return stepType.substring(stopPoint + 2, stepType.length - 1);
};

export const getExecutionStepById = (execution, executionStepId = '') =>
  execution.steps.find((s) => s._id.toString() === executionStepId.toString());

export const getProcessStep = (execution, where) =>
  _.isFunction(where)
    ? _.find(execution.process.steps, where)
    : _.findWhere(execution.process.steps, where);

export const getProcessStepFromExecutionStep = (execution, executionStep) => {
  if (!executionStep) {
    return null;
  }

  return getProcessStep(
    execution,
    (p) =>
      p._id &&
      executionStep.processStep &&
      p._id.toString() === executionStep.processStep.toString(),
  );
};

export const getStep = (execution, where) => {
  if (!execution) return {};

  let step = null;
  if (_.isString(where)) {
    step = getExecutionStepById(execution, where);
  } else if (_.isFunction(where)) {
    step = _.find(execution.steps, where);
  } else {
    step = _.findWhere(execution.steps, where);
  }

  return {
    ...step,
    processStep: getProcessStepFromExecutionStep(execution, step),
  };
};

export const processTemplateValues = async (
  value,
  execution,
  options,
  i = 0,
) => {
  if (execution && execution.toObject) {
    execution = execution.toObject();
  }

  // default options
  options = {
    ...options,
  };
  const promises = [];
  let newValue = value;
  const templateMatches = _.isString(value)
    ? StringHelper.getTemplateValues(value)
    : [];

  templateMatches.forEach((templateMatch) => {
    const split = templateMatch.split('.');
    const dotLocation = templateMatch.indexOf('.');
    if (split[0] === 'initiation') {
      let data = {};
      try {
        data = _.isString(execution.initiationData)
          ? JSON.parse(execution.initiationData)
          : execution.initiationData;
      } catch (e) {
        // couldn't parse
      }

      const arrayProofValue = _.isArray(data)
        ? StringHelper.getAttribute(
            data[i],
            templateMatch.substr(dotLocation + 1),
          )
        : StringHelper.getAttribute(
            data,
            templateMatch.substr(dotLocation + 1),
          );
      newValue = newValue.replace(`{${templateMatch}}`, arrayProofValue || '');

      return;
    } // end initiation

    if (split[0] === 'execution') {
      if (split[1] === '$c_executionUrl') {
        newValue = newValue.replace(
          `{${templateMatch}}`,
          `${HOST}/processes/${execution.process._id}/executions/${execution._id}`,
        );
        return;
      }
      if (split[1] === '$c_currentTime') {
        newValue = newValue.replace(
          `{${templateMatch}}`,
          moment().format('lll (Z)'),
        );
        return;
      }
      if (split[1] === '$c_currentUser') {
        const p1 = AuthenticationServer.getUsers(
          AUTH_SERVER,
          {
            ids: execution.assignee,
            token: options.token,
          },
          request,
        ).then((users) => {
          if (!users || !users.length) {
            return;
          }

          newValue = newValue.replace(`{${templateMatch}}`, users[0].name);
        });

        promises.push(p1);

        return;
      }
    }

    if (split[0] === 'settings') {
      return promises.push(
        processServer
          .getSettings({
            token: options.token,
            contuitId: execution.process.contuitId,
          })
          .then((settings) => {
            const option = settings[split[1]];

            if (!option) {
              throw new Error(`${split[1]} is missing from settings.`);
            }

            newValue = newValue.replace(`{${templateMatch}}`, option.value);
          }),
      );
    }

    const step = getStep(
      execution,
      (ex) => ex.processStep.toString() === split[0],
    );

    if (!step) {
      return;
    }

    if (
      (new RegExp(VAULT_INPUT_STEP_TYPE).test(step.processStep.stepType) ||
        new RegExp(VAULT_CREATE_STEP_TYPE).test(step.processStep.stepType) ||
        new RegExp(AUTO_VAULT_CREATE_STEP_TYPE).test(
          step.processStep.stepType,
        )) &&
      step.stepOutput
    ) {
      promises.push(
        // default to the overload server if need be..
        (options.vaultServerOverload || vaultServer)
          .getEntry({
            token: options.token,
            slug: step.processStep.stepOptions.slug,
            entryId: step.stepOutput.id,
          })
          .then((entry) => {
            if (entry === null) {
              throw new Error('Vault entry selected was not found');
            }

            const valFromEntry = entry[split[1]];
            if (_.isObject(valFromEntry) && valFromEntry.s3Key) {
              newValue = valFromEntry;
            } else {
              newValue = newValue.replace(
                `{${templateMatch}}`,
                valFromEntry || '',
              );
            }
          })
          .catch(() => {
            newValue = newValue.replace(`{${templateMatch}}`, '');
          }),
      );
      return;
    }

    // check if it is an array length
    if (split[1] === '$c_length') {
      if (!_.isArray(step.stepOutput)) {
        newValue = newValue.replace(`{${templateMatch}}`, 0);
        return;
      }
      newValue = newValue.replace(`{${templateMatch}}`, step.stepOutput.length);
      return;
    }
    if (split[1] === '$c_executionUrl' && step.stepOutput) {
      newValue = newValue.replace(
        `{${templateMatch}}`,
        `${HOST}/processes/${step.processStep.stepOptions.processId}/executions/${step.stepOutput.executionId}`,
      );
      return;
    }
    if (split[1] === '$c_errorStatus') {
      newValue = newValue.replace(`{${templateMatch}}`, step.errorStatus || '');
      return;
    }
    if (split[1] === '$c_errorMessage') {
      newValue = newValue.replace(`{${templateMatch}}`, step.error || '');
      return;
    }
    if (split[1] === '$c_errorDetail') {
      newValue = newValue.replace(`{${templateMatch}}`, step.errorDetail || '');
      return;
    }
    if (split[1] === '$c_timedOut') {
      newValue = newValue.replace(`{${templateMatch}}`, step.timedOut || '');
      return;
    }

    // ONLY TAKES THE FIRST VALUE IF OUTPUT IS AN ARRAY
    const arrayProofValue = _.isArray(step.stepOutput)
      ? step.stepOutput[i] &&
        StringHelper.getAttribute(
          step.stepOutput[i],
          templateMatch.substr(dotLocation + 1),
        )
      : step.stepOutput &&
        StringHelper.getAttribute(
          step.stepOutput,
          templateMatch.substr(dotLocation + 1),
        );

    // replace unless we have a file
    newValue =
      _.isArray(arrayProofValue) &&
      arrayProofValue[i] &&
      arrayProofValue[i].s3Key
        ? arrayProofValue
        : newValue.replace(`{${templateMatch}}`, arrayProofValue || '');
  });

  return Promise.all(promises).then(() =>
    newValue && newValue.trim ? newValue.trim() : '',
  );
};
