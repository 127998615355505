import { IntegrationServer } from '@contuit-otf/sdk';
import { toast } from 'react-toastify';
import request from '../utility/request';

const availableIntegrationServer = new IntegrationServer(
  '/api/integrations',
  request,
);

export function addAvailableIntegrations(data) {
  return {
    type: 'ADD_AVAILABLE_INTEGRATIONS',
    data,
  };
}

export function setAvailableIntegrationsLoading(loading = true) {
  return {
    type: 'AVAILABLE_INTEGRATIONS_LOADING',
    loading,
  };
}

export function addAvailableIntegration(data) {
  return {
    type: 'ADD_AVAILABLE_INTEGRATION',
    data,
  };
}

export function setAvailableIntegrationLoading(loading = true) {
  return {
    type: 'AVAILABLE_INTEGRATION_LOADING',
    loading,
  };
}

export function loadAvailableIntegrations() {
  return (dispatch, getState) => {
    const { token } = getState().users.currentUser;
    dispatch(setAvailableIntegrationsLoading());
    availableIntegrationServer
      .getAvailableIntegrations({ token })
      .then((availableIntegrations) => {
        dispatch(addAvailableIntegrations(availableIntegrations));
      })
      .catch((err) => {
        toast.error(`Error loading availableIntegrations: ${err}`);
      })
      .then(() => {
        dispatch(setAvailableIntegrationsLoading(false));
      });
  };
}

export function loadAvailableIntegration(id) {
  return (dispatch, getState) => {
    const { token } = getState().users.currentUser;
    dispatch(setAvailableIntegrationLoading());
    availableIntegrationServer
      .getAvailableIntegration({ token, availableIntegrationId: id })
      .then((availableIntegrations) => {
        dispatch(addAvailableIntegration(availableIntegrations));
      })
      .catch((err) => {
        toast.error(`Error loading availableIntegration: ${err}`);
      })
      .then(() => {
        dispatch(setAvailableIntegrationLoading(false));
      });
  };
}
