const breadcrumbsConfig = [
  {
    path: 'processes',
    children: [
      {
        path: ':processId',
        breadcrumb: 'Show Process',
      },
      {
        path: ':processId/executions',
        breadcrumb: null,
      },
    ],
  },
  {
    path: 'solutions',
    breadcrumb: null,
  },
  {
    path: 'vault',
    breadcrumb: 'The Vault',
    children: [
      {
        path: ':vaultStructureId',
        breadcrumb: 'Show Vault Structure',
      },
      {
        path: ':vaultStructureId/:id',
        breadcrumb: 'Show Vault Structure',
      },
      {
        path: ':vaultStructureId/:id/edit',
        breadcrumb: null,
      },
    ],
  },
  {
    path: 'marketplace',
    breadcrumb: null,
    children: [
      {
        path: ":id",
        breadcrumb: null,
      },
      {
        path: ":id/import",
        breadcrumb: null
      }
    ]
  },
  {
    path: 'admin',
    breadcrumb: null,
    children: [
      {
        path: 'integrations',
        breadcrumb: 'My Integrations',
        children: [
          {
            path: 'available',
            breadcrumb: 'Available Integrations',
          },
        ],
      },
      {
        path: 'system-settings',
        breadcrumb: null,
      },
    ],
  },
  {
    path: 'processes',
    breadcrumb: 'Processes',
    children: [
      {
        path: ':processId',
        breadcrumb: 'Show Process',
      },
    ],
  },
]

export default breadcrumbsConfig
