import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LinkContainer } from 'react-router-bootstrap';
import { useParams } from 'react-router';
import { Loading } from '../../lib/acromyrmex';
import {
  Col, Row, Button, Nav, Tab, NavItem, Alert,
} from '../../utility/UiComponents';
import UserSettingsForm from './UserSettingsForm';
import { updateUser, loadUser } from '../../actions/users';
import ShowApiKeys from './apiKeys/ShowApiKeys';
import PageLayout from '../shared/Layout/PageLayout';
import UserThumbnail from './UserThumbnail';
import UserDetails from './UserDetails';

function ShowUser(props) {
  const { userId } = useParams();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.users.user.value);
  const currentUser = useSelector((state) => state.users.currentUser);
  const isLoading = useSelector((state) => state.users.user.isLoading);
  const formValues = useSelector((state) => (state.form.userSettingsForm ? state.form.userSettingsForm.values : {}));

  const renderLoading = () => (
    <div className="container">
      <Alert variant="info" className="pull-left">
        <Loading />
        {' Loading user...'}
      </Alert>
    </div>
  );

  const renderEmpty = () => (
    <div className="container">
      <Alert variant="danger" className="pull-left">
        <i className="fa fa-exclamation-circle" />
        {' No user found! Back to '}
        <LinkContainer to={{ pathname: '/admin/users' }}>
          <Button size="sm" variant="primary">
            All Users
          </Button>
        </LinkContainer>
      </Alert>
    </div>
  );

  useEffect(() => {
    dispatch(loadUser(userId));
  }, []);

  const onUserSettingsFormSubmit = () => {
    dispatch(updateUser(currentUser._id, formValues));
  };

  if (!user && isLoading) {
    return renderLoading();
  }

  if (!user) {
    return renderEmpty();
  }

  const myUser = user._id === currentUser._id;

  return (
    <PageLayout
      header={user.name}
      noContentPadding
      content={(
        <div>
          {!myUser && (
          <Row className="content-row" style={{ marginTop: '10px' }}>
            <Col className="portrait user-portrait" xs={12} sm={4}>
              <Row className="user-portrait-wrapper">
                <UserThumbnail user={user} radius="150px" />
              </Row>
            </Col>
            <Col className="portrait" xs={12} sm={8}>
              <UserDetails user={user} />
            </Col>
          </Row>
          )}
          {myUser && (
          <Tab.Container id="left-tabs-example" defaultActiveKey="first">
            <Row
              className="content-row-translucent box-rest box-row-wrapper"
              style={{ marginTop: '10px' }}
            >
              <Col xs={12} sm={4} md={3}>
                <div className="content-col clearfix">
                  <Nav variant="pills" className="flex-column">
                    <Nav.Item>
                      <Nav.Link eventKey="first">User Settings</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">API Keys</Nav.Link>
                    </Nav.Item>
                  </Nav>
                </div>
              </Col>
              <Col xs={12} sm={8} md={9} className="box-rest">
                <div className="content-col clearfix" style={{ height: '100%' }}>
                  <Tab.Content animation style={{ height: '100%' }}>
                    <Tab.Pane eventKey="first">
                      <h4>User Settings</h4>
                      <hr />
                      <UserSettingsForm
                        initialValues={user}
                        user={user}
                        onSubmit={onUserSettingsFormSubmit}
                      />
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                      <ShowApiKeys user={user} />
                    </Tab.Pane>
                  </Tab.Content>
                </div>
              </Col>
            </Row>
          </Tab.Container>
          )}
        </div>
        )}
      currentUser={currentUser}
    />
  );
}

export default ShowUser;
