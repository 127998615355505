const filterCollections = (outputOptions) =>
  outputOptions.filter(
    (o) => o.type !== 'collection' && o.type !== 'vault-collection',
  )

const findCollections = (
  outputOptions,
  { loopProcessOnly = false } = { loopProcessOnly: false },
) =>
  outputOptions.filter(
    (o) =>
      (!loopProcessOnly && o.type === 'collection') ||
      o.type === 'vault-collection' ||
      o.type === 'list',
  )

export { filterCollections, findCollections }
