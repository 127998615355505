import React from 'react';
import { Navigate, Link } from 'react-router-dom';
import {
  ShowLogin,
  ShowSetupMultiFactorAuth,
  ShowLogout,
  ShowResetPassword,
  PersonalDashboard,
  ShowMarketplace,
  ShowMarketplaceImport,
  ShowMarketplaceSolution,
  ShowMiniImport,
  ShowVault,
  ShowVaultEntry,
  EditVaultEntry,
  CreateVaultStructure,
  CreateVaultEntry,
  EditVaultStructure,
  ShowSolutionGroupsPage,
  ShowSolutionGroupPage,
  ShowDocs,
  ShowDocsBuildComputation,
  ShowDocsBuildConditional,
  ShowDocsGettingStarted,
  ShowDocsRestApi,
  ShowDocsScheduler,
  ShowDuoGuide,
  ShowPrivacyPolicy,
  ShowRemoteDocsPage,
  ShowSaasLicense,
  ShowSecurityPolicy,
  ShowDuoMarketing,
  ShowDuoMultiFactorAuth,
  ShowDuoMultiFactorAuthFirstLogin,
  ShowDuoSyncVideo,
  ShowDuoThankYou,
  ShowOnboarding,
  ShowTerms,
  ShowHelp,
  CreateUser,
  ShowSystemSettings,
  ListUsers,
  ShowUser,
  ListIntegrations,
  ListAvailableIntegrations,
  ShowAvailableIntegration,
  ShowIntegration,
  ListProcesses,
  ListBundles,
  ListSchedules,
  ShowActiveExecutions,
  SolutionSettingsDashboard,
  ShowProcess,
  ShowExecution,
  EditProcess,
  CreateProcess,
  ShowStuckExecution
} from './pages';

import Layout from './modules/app/Layout';

import AuthorizationGateway from './modules/shared/helpers/AuthorizationGateway';

function NoMatch() {
  return (
    <div>
      <h2>No routes match</h2>
      <Link to="/">Home</Link>
    </div>
  );
}

export const ThemeRoutes = [
  { path: '/', element: <Navigate to="/login" /> },
  {
    path: 'partners/duo',
    element: (
      <ShowDuoMarketing />
    ),
  },
  {
    path: 'partners/duo',
    element: <AuthorizationGateway />,
    children: [
      { path: 'thank-you', element: <ShowDuoThankYou /> },
      { path: 'mfa', element: <ShowDuoMultiFactorAuth /> },
    ],
  },
  { path: '/partners/duo/mfa-login', element: <ShowDuoMultiFactorAuthFirstLogin /> },
  { path: '/videos/duo-cw-manage-sync', element: <ShowDuoSyncVideo /> },
  { path: '/login', element: <ShowLogin /> },
  { path: '/resetPassword', element: <ShowResetPassword /> },
  { path: '/setup-mfa', element: <ShowSetupMultiFactorAuth /> },
  {
    path: 'docs',
    element: <Layout />,
    children: [
      { index: true, element: <ShowDocs /> },
      { path: 'getting-started', element: <ShowDocsGettingStarted /> },
      { path: 'security-policy', element: <ShowSecurityPolicy /> },
      { path: 'saas-license', element: <ShowSaasLicense /> },
      { path: 'privacy-policy', element: <ShowPrivacyPolicy /> },
      {
        path: 'duo-sign-up',
        element: <ShowRemoteDocsPage path="duo-sign-up" header="Contuit Sign Up for Duo Partners" />,
      },
      { path: 'scheduler', element: <ShowDocsScheduler /> },
      { path: 'api', element: <ShowDocsRestApi /> },
      {
        path: 'integrations/duo-accounts',
        element: (
          <ShowRemoteDocsPage path="integrations/duo" header="Configuring Duo for Contuit" />
        ),
      },
      {
        path: 'integrations/connectwise',
        element: (
          <ShowRemoteDocsPage
            path="integrations/connectwise"
            header="Configuring Connectwise for Contuit"
          />
        ),
      },
      { path: 'solutions/duo', element: <ShowDuoGuide /> },
      { path: 'build/conditional', element: <ShowDocsBuildConditional /> },
      { path: 'build/computation', element: <ShowDocsBuildComputation /> },
    ],
  },
  { path: 'users/new', element: <CreateUser /> },
  { path: 'signup', element: <ShowOnboarding /> },
  { path: 'help', element: <ShowHelp /> },
  { path: 'terms-and-conditions', element: <ShowTerms /> },
  {
    path: '/',
    element: <AuthorizationGateway />,
    children: [{
      path: '/',
      element: <Layout />,
      children: [
        {
          path: 'dashboards',
          children: [
            { path: 'personal', element: <PersonalDashboard /> },
            { path: 'solution-settings', element: <SolutionSettingsDashboard /> },
          ],
        },
        // { path: 'blockDebug', element: <ShowBlockDebug /> },
        // { path: 'diagnostic', element: <ShowDiagnostic /> },
        {
          path: 'solutions',
          children: [
            { index: true, element: <ShowSolutionGroupsPage /> },
            {
              path: ':solutionGroupId',
              element: <ShowSolutionGroupPage />,
            },
          ],
        },
        {
          path: 'processes',
          children: [
            { index: true, element: <ListProcesses /> },
            { path: 'bundles', element: <ListBundles /> },
            { path: 'active', element: <ShowActiveExecutions /> },
            { path: 'schedules', element: <ListSchedules /> },
            { path: 'new', element: <CreateProcess /> },
            {
              path: ':processId',
              children: [
                {
                  index: true,
                  element: <ShowProcess />,
                },
                {
                  path: 'edit',
                  element: <EditProcess />,
                },
                {
                  path: 'executions/:executionId',
                  element: <ShowExecution />,
                },
              ],
            },
          ],
        },
        {
          path: 'vault',
          children: [
            { index: true, element: <ShowVault /> },
            { path: 'new', element: <CreateVaultStructure /> },
            {
              path: ':vaultStructureId',
              children: [
                { index: true, element: <ShowVault /> },
                { path: 'edit', element: <EditVaultStructure /> },
                { path: 'new', element: <CreateVaultEntry /> },
                {
                  path: ':dataId',
                  children: [
                    { index: true, element: <ShowVaultEntry /> },
                    { path: 'edit', element: <EditVaultEntry /> },
                  ],
                },
              ],
            },
          ],
        },
        {
          path: 'marketplace',
          children: [
            { index: true, element: <ShowMarketplace /> },
            { path: 'import/:miniBundle', element: <ShowMiniImport /> },
            {
              path: ':solutionId',
              children: [
                {
                  index: true, element: <ShowMarketplaceSolution />,
                },
                {
                  path: 'import', element: <ShowMarketplaceImport />,
                },
              ],
            },
          ],
        },

        {
          path: 'admin',
          children: [
            {
              path: 'system-settings',
              element: <ShowSystemSettings />,
            },
            {
              path: 'users',
              children: [
                { index: true, element: <ListUsers /> },
                { path: ':userId', element: <ShowUser /> },
              ],
            },
            {
              path: 'integrations',
              children: [
                { index: true, element: <ListIntegrations /> },
                {
                  path: 'available',
                  children: [
                    { index: true, element: <ListAvailableIntegrations /> },
                    { path: ':availableIntegrationId', element: <ShowAvailableIntegration /> },
                  ],
                },
                { path: ':integrationId', element: <ShowIntegration /> },
              ],
            },
          ],
        },
        { path: 'logout', element: <ShowLogout /> },
      ],
    }],
  },
  {path: "/tenants/:contuitId/processes/:processId/executions/:executionId", element: <ShowStuckExecution />},
  { path: '*', element: <NoMatch /> },
];
