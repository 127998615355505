import cookie from 'react-cookie'
import store from './Store'
import { checkToken, tokenChecked, refreshToken } from './actions/users'

// In this file we can set up scheduled actions.
// It is also useful for startup actions (like checking for authentication)
export default () => {
  // check for a token when the pages loaded
  let token = cookie.load('token')

  if (token && token !== 'undefined') {
    store.dispatch(checkToken(token))
  } else {
    store.dispatch(tokenChecked())
  }

  setInterval(
    () => {
      token = cookie.load('token')
      if (token && token !== 'undefined') {
        store.dispatch(refreshToken())
      }
    },
    15 * 60 * 1000,
  )
}
