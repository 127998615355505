import get from 'lodash/get'
import set from 'lodash/set'
import { TextInput, CheckboxInput, SelectInput } from 'lib/acromyrmex'
import { INPUT_STEP_TYPE } from '../../../constants'
import InputStep from './components/stepExecutions/InputStep'
import BasicUiHelper from './BasicUiHelper'
import StringHelper from '../../../shared-helpers/StringHelper'
import TableForm from '../../shared/form/TableForm'
import UiAttributeHelper from '../../vaultStructures/attributes/UiAttributeHelper'
import PickListEditor from '../../vaultStructures/VaultStructureForm/PickListEditor'

class InputUiHelper extends BasicUiHelper {
  regex() {
    return INPUT_STEP_TYPE
  }

  name() {
    return 'Input'
  }

  canTryAgain() {
    return false
  }

  stepOptions() {
    return {
      title: 'Input Step Options',
      fields: () => [
        {
          name: 'fields',
          component: TableForm,
          headers: [
            {
              title: 'Label',
              field: { name: 'label', component: TextInput, noLabel: true },
              xs: 5,
            },
            {
              title: 'Type',
              field: {
                name: 'type',
                component: SelectInput,
                options: UiAttributeHelper.getAttributeTypes(),
                noLabel: true,
                addon: ({ rowPrefix }) => ({
                  enabled: ({ currentRow }) => currentRow.type === 'pickList',
                  component: PickListEditor,
                  sizing: { xs: 2 },
                  props: {
                    fieldName: rowPrefix,
                  },
                }),
              },
              xs: 4,
            },
            {
              title: 'Required',
              field: {
                name: 'required',
                component: CheckboxInput,
                noLabel: true,
                onChangeNeeded: false,
              },
              xs: 2,
            },
          ],
        },
      ],
    }
  }

  getOutputKeys({ stepOptions }) {
    const fields = get(stepOptions, 'fields', [])
    const schema = {}

    // We need to loop over the fields defined and add them to our data
    fields.forEach((field) => {
      set(schema, field.slug, {
        type: field.type === 'file' ? 'File' : 'Label',
        title: field.label,
      })
    })

    return { schema }
  }

  stepExecution() {
    return InputStep
  }

  getInputOptions(step) {
    return (step.stepOptions.fields || []).map((field) => ({
      id: JSON.stringify({ step: step._id, field: field.slug }),
      name: `${step.name} - ${field.label}`,
      textValue: `{${step._id}.${field.slug}}`,
      type: field.type,
      options: field.options || [],
    }))
  }

  getOutputOptions(step) {
    return (step.stepOptions.fields || []).map((field) => ({
      id: JSON.stringify({
        step: step._id,
        field: field.slug ? field.slug : StringHelper.slugify(field.label),
      }),
      name: `${field.label}`,
      textValue: `{${step._id}.${field.slug ? field.slug : StringHelper.slugify(field.label)}}`,
      type: field.type,
    }))
  }
}

export default InputUiHelper
